import { useCallback, useMemo } from "react";
import useStyles from "./Style.styles";

interface IStatusStylesParams {
  status: string;
  disabled?: boolean;
}

export const useStatusStyle = ({ status, disabled }: IStatusStylesParams) => {
  const classes = useStyles();

  const statusClassName = useMemo(() => {
    if (disabled) return classes.disabledChip;
    switch (status) {
      case "on":
        return classes.green;
      case "flex":
        return classes.yellow;
      case "off":
        return classes.default;
      default:
        return classes.default;
    }
  }, [
    classes.default,
    classes.disabledChip,
    classes.green,
    classes.yellow,
    disabled,
    status,
  ]);

  const selectableStatusClassName = useMemo(() => {
    if (disabled) return classes.disabledSelect;
    switch (status) {
      case "on":
        return classes.greenSelect;
      case "flex":
        return classes.yellowSelect;
      case "off":
        return classes.defaultSelect;
      default:
        return classes.defaultSelect;
    }
  }, [
    classes.defaultSelect,
    classes.disabledSelect,
    classes.greenSelect,
    classes.yellowSelect,
    disabled,
    status,
  ]);

  const statusTextColor = useMemo(() => {
    if (disabled) return classes.disabledChipText;
    switch (status) {
      case "off":
        return classes.textDefault;
      default:
        return classes.textWhite;
    }
  }, [
    classes.disabledChipText,
    classes.textDefault,
    classes.textWhite,
    disabled,
    status,
  ]);

  const getStatusClass = useCallback(
    (status: string) => {
      if (disabled) return classes.disabledChip;
      switch (status) {
        case "on":
          return classes.green;
        case "flex":
          return classes.yellow;
        case "off":
          return classes.default;
        default:
          return classes.default;
      }
    },
    [
      classes.default,
      classes.disabledChip,
      classes.green,
      classes.yellow,
      disabled,
    ]
  );

  return {
    statusTextColor,
    statusClassName,
    selectableStatusClassName,
    getStatusClass,
  };
};
