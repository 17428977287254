import {
  Datepicker,
  MpFilterType,
  MpTableDataArguments,
} from "@mp-react/table";

export enum CustomRendererType {
  dateEndPill = "dateEndPill",
  statusPill = "statusPill",
  implementation = "implementation",
  timeRemaining = "timeRemaining",
  yesNo = "yesNo",
  employeeGroup = "employeeGroup",
  entries = "entries",
  statusToggle = "statusToggle",
  empty = "empty",
  terms = "terms",
  dateTime = "dateTime",
  log = "log",
  pdf = "pdf",
  dateFormat = "dateFormat",
  benefitBalanceDetails = "benefitBalanceDetails",
  groups = "groups",
  includedExcludedSwitch = "includedExcludedSwitch",
  stringArray = "stringArray",
  simpleStringArray = "simpleStringArray",
  shortMonthDate = "shortMonthDate",
  currency = "currency",
  currencyWithFraction = "currencyWithFraction",
  checked = "checked",
  switch = "switch",
  benefitTypeTranslation = "benefitTypeTranslation",
  allocationFrequency = "allocationFrequency",
  progressBar = "progressBar",
  benefitAssignmentStatus = "benefitAssignmentStatus",
  keyLabelMap = "keyLabelMap",
  basicStatusPill = "basicStatusPill",
}

export interface TableVariables extends MpTableDataArguments, Datepicker {}

export interface SearchSelectFilterItem {
  value: string;
  label: string;
}

export interface SearchSelectFilterType {
  type: MpFilterType.searchSelect;
  items?: SearchSelectFilterItem[];
  asyncGet: string;
}

export interface DateFilterValue {
  from: string;
  to: string;
}

export interface InvestmentFilterValue {
  max: number;
  min: number;
}

export interface CountFilterValue {
  max: number;
  min: number;
}

export interface FilterItem {
  id: string;
  value:
    | string
    | string[]
    | InvestmentFilterValue
    | DateFilterValue
    | CountFilterValue;
}

export interface MpAsyncGetMethodArguments {
  search?: string;
}

export interface ITableContext {
  id?: string;
  tableQueryParam?: string;
}

export interface TableDataResponse<T, F = Partial<T>> {
  data: T[];
  count: number;
  pageSize: number;
  footer?: F;
}
