import React, { useCallback, useState } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddNewButton from "../../../common/AddNewButton/AddNewButton";
import FormDialog from "../../../dialogs/FormDialog/FormDialog";
import { useIntegrationTypeForm } from "../../../../configs/Forms/IntegrationTypeForm";
import { useIntegrationTypes } from "../../../../state/Integrations";
import { IntegrationTypeCreationRequest } from "../../../../types/Integrations";

const IntegrationsHeader = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const integrationTypeForm = useIntegrationTypeForm();
  const { create, creating } = useIntegrationTypes();

  const handleOpenDialog = useCallback(() => setOpen(true), []);
  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleSubmit = useCallback(
    async (data: IntegrationTypeCreationRequest) => {
      await create(data);
      handleCloseDialog();
    },
    [create, handleCloseDialog]
  );

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="flex-end"
      minHeight="44px"
    >
      <AddNewButton onClick={handleOpenDialog}>
        {t("integrations.newIntegration")}
      </AddNewButton>
      <FormDialog
        actionLabel={t("common.confirm")}
        onCancelClick={handleCloseDialog}
        onSubmitForm={handleSubmit}
        id="create-integration-form-dialog"
        title={t("integrations.newIntegration")}
        form={integrationTypeForm}
        open={open}
        loading={creating}
      />
    </Box>
  );
};

export default IntegrationsHeader;
