import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    dateInput: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: Colors.TextPrimary,
      },
      "& .MuiInput-underline:before": {
        display: "none",
      },
    },
    inactive: {
      color: Colors.LightGray,
    },
  })
);
