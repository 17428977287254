import { Box, Grid } from "@material-ui/core";
import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  MpControlProps,
  controlRegisterOptions,
  MpFormControl,
} from "@mp-react/form";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import { TooltipControl } from "../../../types/Common";
import useTooltip from "../../../utils/Tooltip";

const defaultSpan = { xs: 12 };

export function GroupView({
  control,
  size,
  layout,
  variant,
  locale,
  register,
  errors,
  defaultMessages,
  hookFormControl,
  overridables,
}: MpControlProps & {
  value: any;
  onChange: (...event: any[]) => void;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  return (
    <Grid
      container={true}
      spacing={2}
      component={Box}
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      {control.children?.map((control: TooltipControl, i: number) => (
        <Grid
          item={true}
          key={i}
          xs={12}
          {...((control.span ?? defaultSpan) as any)}
        >
          <MpFormControl
            register={register}
            control={control}
            defaultMessages={defaultMessages}
            error={errors?.[control?.key ?? ""]}
            size={size}
            locale={locale}
            hookFormControl={hookFormControl}
            noLabel={true}
            layout={layout}
            variant={variant}
            overridables={overridables}
          />
        </Grid>
      ))}
      <FormTooltip
        tooltip={tooltip}
        anchorEl={anchorEl}
        margin="8px 0 8px 60px"
      />
    </Grid>
  );
}

export default function Group(props: MpControlProps) {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  const key = useMemo(() => `group-${Math.random()}`, []);
  rules.required = false;
  return (
    <Controller
      name={key}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <GroupView {...props} onChange={onChange} value={value} />
      )}
    />
  );
}
