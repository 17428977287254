import { useMemo } from "react";

export const useUsersDialogActions = () => useMemo(() => ["sendReminder"], []);

export enum UsersFilterNames {
  FULL_USER_NAME = "fullName",
  USER_COMPANY_NAME = "companyName",
  USER_EMAIL = "email",
  USER_PHONE_NUMBER = "phone",
  USER_GENDER = "gender",
  USER_PERSONAL_CODE = "personalCode",
}
