import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loader from "../../components/common/Loader/Loader";
import { useRoutes } from "../../constants/Routes";
import PrivateRoute from "./PrivateRoute";

export default function Routes() {
  const routes = useRoutes();

  const routeMap = routes.map((route) => {
    if (route.public) {
      return (
        <Route path={route.path} exact={true} key={route.path}>
          <route.component />
        </Route>
      );
    }

    return (
      <PrivateRoute
        roles={route.roles}
        path={route.path}
        exact={true}
        key={route.path}
        permissionLevel={route.permissionLevel}
        module={route.module}
      >
        <route.component />
      </PrivateRoute>
    );
  });

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routeMap}
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
}
