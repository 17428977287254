import React, { useMemo } from "react";
import { MpColumn, MpTable } from "@mp-react/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DownloadExcelButton from "../../../../../components/table/DownloadExcelButton/DownloadExcelButton";
import { useDefaultHighlights } from "../../../../../constants/Table";
import {
  useBenefitUsageSummary,
  useBenefitUsageSummaryAsyncMethods,
  useBenefitUsageSummaryXLSExport,
} from "../../../../../state/BenefitExpenses";
import { CustomRendererType } from "../../../../../types/Table";
import useTableUtils, { useTableFilterTypes } from "../../../../../utils/Table";
import { useCurrency } from "../../../../../utils/useCurrency";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  row: {
    verticalAlign: "initial",
    "& td": {
      whiteSpace: "break-spaces",
    },
  },
}));

interface Props {
  employeeId: string;
  renderHeader: (
    reload: () => Promise<any>,
    xlsExportElement: React.ReactNode
  ) => React.ReactNode;
}

const BenefitUsageSummaryTable = ({ employeeId, renderHeader }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const highlights = useDefaultHighlights();

  const { getSearchSelectFilter, getCurrencyRangeFilter } =
    useTableFilterTypes();

  const benefitTitleFilter = useMemo(
    () => getSearchSelectFilter("getBenefitTitles"),
    [getSearchSelectFilter]
  );

  const asyncGetMethods = useBenefitUsageSummaryAsyncMethods(employeeId);

  const {
    handleGetData,
    overridables,
    overrideClasses,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
    translations,
  } = useTableUtils("common");

  const columns = useMemo(() => {
    return [
      {
        key: "assignmentStatus",
        label: t("benefitExpenses.benefitType"),
        render: CustomRendererType.benefitAssignmentStatus,
        sort: false,
      },
      {
        key: "name",
        label: t("benefitExpenses.benefitTitle"),
        filter: benefitTitleFilter,
        width: 180,
        fixedHeadWidth: false,
        sort: false,
      },
      {
        key: "value",
        label: t("benefitExpenses.benefitValue"),
        render: CustomRendererType.currencyWithFraction,
        filter: getCurrencyRangeFilter(),
        totals: true,
      },
      {
        key: "expenses",
        label: t("benefitExpenses.expense"),
        render: CustomRendererType.currencyWithFraction,
        filter: getCurrencyRangeFilter(),
        totals: true,
      },
      {
        key: "percentage",
        label: "",
        render: CustomRendererType.progressBar,
        width: 180,
        fixedHeadWidth: true,
        sort: false,
      },
      {
        key: "remaining",
        label: t("benefitExpenses.remaining"),
        render: CustomRendererType.currencyWithFraction,
        filter: getCurrencyRangeFilter(),
        totals: true,
      },
    ] as MpColumn[];
  }, [benefitTitleFilter, getCurrencyRangeFilter, t]);

  const { summary, loading, reload } = useBenefitUsageSummary(
    employeeId,
    tableQueryParam
  );

  const { exportToXLS, exporting } = useBenefitUsageSummaryXLSExport(
    employeeId,
    tableQueryParam
  );

  const xlsExportElement = (
    <DownloadExcelButton
      listLength={summary?.count ?? 0}
      loading={exporting}
      exportToExcel={exportToXLS}
    />
  );

  const { getDefaultCurrencyFormat } = useCurrency();
  const totalsData = {
    value: getDefaultCurrencyFormat(summary?.footer?.value) ?? "",
    expenses: getDefaultCurrencyFormat(summary?.footer?.expenses) ?? "",
    remaining: getDefaultCurrencyFormat(summary?.footer?.remaining) ?? "",
  };

  return (
    <>
      {renderHeader(reload, xlsExportElement)}
      <MpTable
        columns={columns}
        rowIdKey="id"
        highlights={highlights}
        data={summary?.data}
        pageSize={summary?.pageSize}
        totalsData={totalsData}
        loading={loading}
        onGetData={handleGetData}
        overridables={overridables}
        classes={{
          ...overrideClasses,
          row: clsx(overrideClasses.row, classes.row),
        }}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
        disablePagination
        enableGlobalActions
        stickyTotals
        totals={!!summary?.data.length}
        asyncGetMethods={asyncGetMethods}
        translations={translations}
        onRowClick={(row) => {
          history.push(`/benefits/view/${row?.id}`);
        }}
      />
    </>
  );
};

export default BenefitUsageSummaryTable;
