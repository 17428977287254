export enum FeatureKey {
  integrations = "integrations",
  users = "users",
  benefitUsage = "benefitUsage",
  marketplace = "marketplace",
  benefitTemplates = "benefitTemplates",
}

export enum Env {
  production = "production",
  development = "development",
  uat = "uat",
}

export interface FeatureToggleManager {
  /**
   * Lists enabled features.
   */
  getEnabledFeatureKeys: () => FeatureKey[];
  /**
   * Disables all features.
   */
  disableAll: () => void;
  /**
   * Checks whether a given feature is enabled.
   */
  isFeatureEnabled: (key: FeatureKey) => boolean;
  /**
   * Enables a given feature.
   */
  enableFeature: (key: FeatureKey) => void;
  /**
   * Disables a given feature.
   */
  disableFeature: (key: FeatureKey) => void;
  /**
   * Lists all features with feature toggle.
   */
  availableFeatures: () => FeatureKey[];
  /**
   * Forces feature toggles to behave the same as for specified environment.
   * It's useful for developers to test how feature toggle will behave in
   * production environment.
   */
  setEnv: (env: Env) => void;
}
