import { Dialog, DialogProps } from "@material-ui/core";
import React, { KeyboardEvent, useCallback } from "react";

interface Props extends DialogProps {
  children: React.ReactNode;
  onEnterPress?: () => void;
}

export default function DialogExtended(props: Props) {
  const { children, onEnterPress, onClose, ...rest } = props;

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const { key } = event;
      const isEnter = key === "Enter";
      const isEscape = key === "Escape";
      if (isEnter && !!onEnterPress) onEnterPress();
      if (isEscape && !!onClose) onClose(event, "escapeKeyDown");
    },
    [onClose, onEnterPress]
  );

  return (
    <Dialog onClose={onClose} onKeyUp={handleKeyPress} {...rest}>
      {children}
    </Dialog>
  );
}
