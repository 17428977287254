import moment from "moment";
import { Status } from "../types/Common";
import { EmployeeResponse } from "../types/Employees";
import { StatusAction } from "../types/Status";
import {
  isInFuture,
  isInPast,
  isAfterTwoWeeks,
  useDateFormat,
  getTimezoneFromDate,
} from "./Common";
import { useCallback } from "react";

export const useEmployeesUtils = () => {
  const { getDateWithTimezone } = useDateFormat();

  const parseCategories = useCallback((employeeData?: EmployeeResponse) => {
    return (
      employeeData?.employeeToCategories?.reduce((obj, category) => {
        return {
          ...obj,
          [category.employeeCategoryId]: category.employeeCategoryValueId,
        };
      }, {}) ?? {}
    );
  }, []);

  const parseEmployeeResponseData = useCallback(
    (employeeData: EmployeeResponse) => {
      if (!employeeData) return {};
      return {
        ...employeeData,
        categories: parseCategories(employeeData),
        capacity: !employeeData.capacity ? "fullTime" : employeeData.capacity,
        startDate: getDateWithTimezone(
          employeeData.startDate,
          employeeData.startDateTZ
        ),
        startTime: getDateWithTimezone(
          employeeData.startDate,
          employeeData.startDateTZ
        ),
        endDate: getDateWithTimezone(
          employeeData.endDate,
          employeeData.endDateTZ
        ),
        endTime: getDateWithTimezone(
          employeeData.endDate,
          employeeData.endDateTZ
        ),
        employmentDate: getDateWithTimezone(
          employeeData.employmentDate,
          employeeData.employmentDateTZ
        ),
        employmentTime: getDateWithTimezone(
          employeeData.employmentDate,
          employeeData.employmentDateTZ
        ),
        email: employeeData.email || "",
      };
    },
    [getDateWithTimezone, parseCategories]
  );

  const parseStatusDates = useCallback(
    (
      startDate?: string | null,
      endDate?: string | null,
      status?: "active" | "inactive",
      endDateTZ?: number,
      startDateTZ?: number
    ): Status => {
      if (startDate && isInFuture(startDate)) {
        return {
          name: "will_be_active_on",
          date: startDate,
          tz: startDateTZ,
        };
      }

      if (endDate && isInFuture(endDate) && !isAfterTwoWeeks(endDate)) {
        return {
          name: "will_be_inactive_on",
          date: endDate,
          tz: endDateTZ,
        };
      }

      if (startDate && endDate && isInPast(endDate) && isInPast(startDate)) {
        return {
          name: "inactive",
        };
      }

      if (!!startDate) {
        return {
          name: "active",
        };
      }

      return {
        name: status as StatusAction,
      };
    },
    []
  );

  const parseRequestDates = useCallback(
    (status: Status): Partial<EmployeeResponse> => {
      const { name } = status;
      const date =
        typeof status?.date === "string"
          ? status?.date
          : moment(status?.date).toISOString(true);
      const now = moment().toISOString(true);

      if (name === "inactive") {
        return {
          endDate: now,
          endDateTZ: getTimezoneFromDate(now),
          startDate: null,
        };
      }

      if (name === "active") {
        return {
          startDate: now,
          startDateTZ: getTimezoneFromDate(now),
          endDate: null,
        };
      }

      if (name === "will_be_active_on") {
        return {
          startDate: date,
          startDateTZ: getTimezoneFromDate(date),
          endDate: null,
        };
      }

      if (name === "will_be_inactive_on") {
        return {
          endDate: date,
          endDateTZ: getTimezoneFromDate(date),
          startDate: null,
        };
      }

      return {};
    },
    []
  );

  return {
    parseEmployeeResponseData,
    parseStatusDates,
    parseRequestDates,
  };
};
