import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MpControlType, MpFormType } from "@mp-react/form";
import { TooltipControl } from "../../types/Common";

export const useNewsForm = (): MpFormType => {
  const { t } = useTranslation();

  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: MpControlType.text,
        key: "name",
        placeholder: t("common.title"),
        validations: {
          minLength: 2,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        required: true,
        tooltip: "tooltip.add_news_title",
      },
    ],
    [t]
  );

  return {
    controls,
    size: "small",
    layout: "combined",
    variant: "outlined",
  };
};
