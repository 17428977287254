import axios from "axios";
import languageTranslationsEN from "../locales/languagesEN.json";
import languageTranslationsLT from "../locales/languagesLT.json";
import languageTranslationsPL from "../locales/languagesPL.json";
import languageTranslationsRU from "../locales/languagesRU.json";
import languageTranslationsLV from "../locales/languagesLV.json";
import languageTranslationsAU from "../locales/languagesAU.json";
import languageTranslationsBG from "../locales/languagesBG.json";
import originalLanguageNames from "../locales/originalLang.json";
import localTranslations from "../locales/dev.json";
import { Language } from "../types/Language";
import _ from "lodash";
import { SupportedLanguages } from "../types/Common";

export const languageTranslationMap: Record<
  Language,
  { language?: Record<SupportedLanguages, string> }
> = {
  en: languageTranslationsEN,
  lt: languageTranslationsLT,
  ru: languageTranslationsRU,
  pl: languageTranslationsPL,
  lv: languageTranslationsLV,
  au: languageTranslationsAU,
  bg: languageTranslationsBG,
  none: {},
};

export const parseLanguageResponse = (data: object) => {
  const entries = Object.entries(data);
  const parsedEntries = entries.map(([key, entryValue]) => {
    const innerEntries = Object.entries(entryValue);
    const innerEntriesMapped = innerEntries.map(([key, value]) => {
      return [
        key,
        !!(value as any)?.translation ? (value as any)?.translation : undefined,
      ];
    });
    const innerEntryObject = Object.fromEntries(innerEntriesMapped);
    return [key, innerEntryObject];
  });
  return Object.fromEntries(parsedEntries);
};

const toLocale = (language: Language) => {
  const localeMap: Partial<Record<Language, string>> = {
    au: "en_AU",
    bg: "bg_BG",
  };
  const locale = localeMap[language];
  return locale ?? language;
};

export const loadLanguageResource = async (locale: string) => {
  return await axios
    .get(`https://translations.services.melp.com/locale/${locale}.json`)
    .then((response) => {
      const translationsResponse = parseLanguageResponse(response.data);
      if (process.env.NODE_ENV === "development") {
        return _.defaultsDeep(localTranslations, translationsResponse);
      }
      return translationsResponse;
    })
    .catch((error) => {
      //eslint-disable-next-line
      console.log(error);
    });
};

export const languageRequest = (
  options: any,
  url: any,
  payload: any,
  callback: any
) => {
  try {
    const [lng] = url.split("|");
    if (lng === "none") {
      callback(null, {
        data: {},
        status: 200,
      });
      return;
    }
    const langTranslations = languageTranslationMap[lng as Language];
    loadLanguageResource(toLocale(lng)).then((response) => {
      callback(null, {
        data: { ...response, ...langTranslations, ...originalLanguageNames },
        status: 200,
      });
    });
  } catch (e) {
    //eslint-disable-next-line
    console.error(e);
    callback(null, {
      status: 500,
    });
  }
};

/**
 * Converts language code to country code.
 * @param languageCode ISO 639-1 language code
 * @returns ISO 3166-1 Alpha-2 country code
 */
export const languageToCountry = (languageCode: string): string | undefined => {
  const langToCountryMap: Record<string, string> = {
    be: "BY",
    ja: "JP",
    lt: "LT",
    bg: "BG",
    id: "ID",
    et: "EE",
    pl: "PL",
    fi: "FI",
    ru: "RU",
    ka: "GE",
    no: "NO",
    el: "GR",
    ga: "IE",
    de: "DE",
    ko: "KR",
    sv: "SE",
    pt: "PT",
    it: "IT",
    ro: "RO",
    sl: "SI",
    sk: "SK",
    lv: "LV",
    tr: "TR",
    uk: "UA",
    uz: "UZ",
    fr: "FR",
    tk: "TM",
    da: "DK",
    nl: "NL",
    is: "IS",
  };
  return langToCountryMap[languageCode];
};
