import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Typography, Button } from "@melp-design/components";
import { Close } from "@melp-design/icons";
import { useTranslation } from "react-i18next";
import { isDefined } from "../../utils/isDefined";

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    flexWrap: "wrap",
  },
  filtersTitle: {
    "&::after": {
      content: "':'",
    },
  },
}));

interface FiltersRenderProps<F> {
  value: F;
  setFilter: <K extends keyof F>(filterName: K, newValue?: F[K]) => void;
}

interface Props<F> {
  value: F;
  onChange: (value: F) => void;
  hiddenLabel?: boolean;
  children: (props: FiltersRenderProps<F>) => React.ReactNode;
}

const Filters = <F extends {}>({
  hiddenLabel = false,
  value,
  ...props
}: Props<F>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const activeFilterExists = !!Object.keys(value).some((key) =>
    isDefined(value[key as keyof F])
  );

  const setFilter = <K extends keyof F>(filterName: K, newValue?: F[K]) => {
    const newFilters = {
      ...value,
      [filterName]: newValue,
    };
    if (!isDefined(newFilters[filterName])) {
      delete newFilters[filterName];
    }
    props.onChange(newFilters);
  };

  return (
    <div className={classes.filtersContainer}>
      {!hiddenLabel && (
        <Typography
          variant="p1"
          color="textSecondary"
          className={classes.filtersTitle}
        >
          {t("marketplace.filters")}
        </Typography>
      )}
      {props.children({ value, setFilter })}
      {activeFilterExists && (
        <Tooltip title={<span>{t("marketplace.clearFilters")}</span>}>
          <Button
            variant="outlined"
            size="small"
            textColor="red"
            iconOnly
            onClick={() => props.onChange({} as F)}
          >
            <Close />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default Filters;
