import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: Colors.Default,
      color: Colors.TextSecondary,
    },
    green: {
      backgroundColor: Colors.StatusGreen,
      color: Colors.White,
    },
    yellow: {
      backgroundColor: Colors.StatusYellow,
      color: Colors.White,
    },
    defaultSelect: {
      "&::before": {
        backgroundColor: Colors.Default,
      },
    },
    greenSelect: {
      "&::before": {
        backgroundColor: Colors.StatusGreen,
      },
    },
    yellowSelect: {
      "&::before": {
        backgroundColor: Colors.StatusYellow,
      },
    },
    textWhite: {
      color: Colors.White,
    },
    textDefault: {
      color: Colors.TextSecondary,
    },
    disabledChipText: {
      color: Colors.TextSecondary,
      opacity: 1,
    },
    disabledChip: {
      color: "rgba(105, 113, 125, 1)",
      backgroundColor: Colors.Inactive,
      opacity: 0.7,
    },
    disabledSelect: {
      "&::before": {
        backgroundColor: Colors.Inactive,
        border: `${Colors.BorderOutlinedInput} 1px solid`,
      },
    },
  })
);
