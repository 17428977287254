import useSWR from "swr";
import { Endpoints } from "../api/constants";
import {
  BenefitUsageSummaryResponse,
  BenefitUsageSummaryFilters,
} from "../types/BenefitExpenses";
import { useCallback } from "react";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { MpAsyncGetMethod } from "@mp-react/table";
import { mutateData } from "../api/api";
import { AxiosResponse } from "axios";
import { useLoading } from "../utils/Loading";
import { BenefitExpense, BenefitStatus } from "../types/BenefitExpenses";
import { BenefitDetailsResponse } from "../types/Benefits";
import { useXLSExport } from "../utils/Api";
import {
  BenefitExpenseCreationRequestBody,
  BenefitUsageTableData,
} from "../types/BenefitExpenses";
import {
  BenefitExpensesResponse,
  BenefitExpensesFilters,
} from "../types/BenefitExpenses";

const buildBenefitExpensesUrl = (employeeId: string) =>
  `${Endpoints.benefitExpenses}/${employeeId}`;

const buildBenefitUsageSummaryUrl = (employeeId: string) =>
  `${buildBenefitExpensesUrl(employeeId)}/summary`;

const buildQueryParams = (query?: string) => (query ? `?${query}` : "");

export const useBenefitUsageSummary = (employeeId: string, query?: string) => {
  const { data, error, mutate } = useSWR<BenefitUsageSummaryResponse>(
    `${buildBenefitUsageSummaryUrl(employeeId)}${buildQueryParams(query)}`
  );
  let summary: BenefitUsageTableData | undefined;
  if (data?.data) {
    summary = {
      ...data,
      data: data.data.map((benefit) => ({
        ...benefit,
        inactive: benefit.order === BenefitStatus.inactive,
      })),
    };
  }
  return { summary, loading: !data && !error, reload: mutate };
};

export const useBenefitUsageSummaryAsyncMethods = (employeeId: string) => {
  const baseUrl = `${buildBenefitUsageSummaryUrl(employeeId)}/filterValues`;

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: BenefitUsageSummaryFilters
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const apiUrl = `${baseUrl}/${filterName}${searchParam}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getFilterItems = useCallback(
    (filterName: BenefitUsageSummaryFilters) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getBenefitTitles = useCallback<MpAsyncGetMethod>(
    (args) => {
      return getAsyncFilterItems(args, BenefitUsageSummaryFilters.name);
    },
    [getAsyncFilterItems]
  );

  const getBenefitAssignmentStatuses = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitUsageSummaryFilters.assignmentStatus),
    [getFilterItems]
  );

  return {
    getBenefitTitles,
    getBenefitAssignmentStatuses,
  };
};

export const useBenefitExpenses = (employeeId: string, query?: string) => {
  const { data, error, mutate } = useSWR<BenefitExpensesResponse>(
    `${buildBenefitExpensesUrl(employeeId)}${buildQueryParams(query)}`
  );
  return { expenses: data, loading: !data && !error, reload: mutate };
};

export const useBenefitExpensesAsyncMethods = (employeeId: string) => {
  const baseUrl = `${buildBenefitExpensesUrl(employeeId)}/filterValues`;

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: BenefitExpensesFilters
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const apiUrl = `${baseUrl}/${filterName}${searchParam}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getFilterItems = useCallback(
    (filterName: BenefitExpensesFilters) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getBenefitTitles = useCallback<MpAsyncGetMethod>(
    (args) => {
      return getAsyncFilterItems(args, BenefitExpensesFilters.name);
    },
    [getAsyncFilterItems]
  );

  const getExpenseDescriptions = useCallback<MpAsyncGetMethod>(
    (args) => {
      return getAsyncFilterItems(args, BenefitExpensesFilters.description);
    },
    [getAsyncFilterItems]
  );

  const getBenefitAssignmentStatuses = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitExpensesFilters.assignmentStatus),
    [getFilterItems]
  );

  return {
    getBenefitTitles,
    getBenefitAssignmentStatuses,
    getExpenseDescriptions,
  };
};

export const useCreateBenefitExpense = () => {
  const loadingState = useLoading();
  const create = async (data: BenefitExpenseCreationRequestBody) => {
    loadingState.startLoading();
    try {
      const response = await mutateData(
        "post",
        Endpoints.benefitExpenses,
        data
      );
      return response.data as BenefitExpense;
    } finally {
      loadingState.stopLoading();
    }
  };
  return { create, creating: loadingState.loading };
};

export const useExpenseUpdate = () => {
  const loadingState = useLoading();
  const update = async (
    id: string,
    data: BenefitExpenseCreationRequestBody
  ) => {
    loadingState.startLoading();
    try {
      const response = await mutateData(
        "patch",
        `${Endpoints.benefitExpenses}/${id}`,
        data
      );
      return response.data as BenefitExpense;
    } finally {
      loadingState.stopLoading();
    }
  };
  return { update, updating: loadingState.loading };
};

export const useBenefitUsageSummaryXLSExport = (
  employeeId: string,
  query?: string
) => {
  const url = `${buildBenefitUsageSummaryUrl(
    employeeId
  )}/exportToExcel${buildQueryParams(query)}`;
  return useXLSExport(url);
};

export const useBenefitExpensesXLSExport = (
  employeeId: string,
  query?: string
) => {
  const url = `${buildBenefitExpensesUrl(
    employeeId
  )}/exportToExcel${buildQueryParams(query)}`;
  return useXLSExport(url);
};

export const useEmployeesBenefitsForExpenses = (employeeIds: string[]) => {
  const query = employeeIds.length
    ? `?employeeIds=${employeeIds.join(",")}`
    : "";
  const url = `${Endpoints.employeesBenefitsForExpenses}${query}`;
  const { data, error } = useSWR<BenefitDetailsResponse[]>(url);
  return { benefits: data, loading: !data && !error };
};
