export enum StorageKeys {
  customColumn = "MELP-custom-column",
  breadcrumbs = "melp-breadcrumbs-state",
  lang = "melp-lang",
  compareModeRow = "MELP-compare-mode-row",
  compareModeColumn = "MELP-compare-mode-column",
  adminTable = "MELP-admin-table",
  assignGroupsTable = "MELP-assignGroups-table",
  benefitAssignEmployeesTable = "MELP-benefitAssignEmployees-table",
  benefitChoicesTable = "MELP-benefitChoices-table",
  benefitsTable = "MELP-benefits-table",
  benefitsTermsTable = "MELP-benefitsTerms-table",
  choicesTable = "MELP-choices-table",
  clientsTable = "MELP-clients-table",
  companiesTable = "MELP-companies-table",
  companyGroupsTable = "MELP-companyGroups-table",
  customCategoriesTable = "MELP-customCategories-table",
  employeeAssignBenefitsTable = "MELP-employeeAssignBenefits-table",
  employeeAssignGroupsTable = "MELP-employeeAssignGroups-table",
  employeeChoicesTable = "MELP-employeeChoices-table",
  employeeAllocationsTable = "MELP-employeeAllocations-table",
  employeeBalanceTable = "MELP-employeeBalance-table",
  employeeLogsTable = "MELP-employeeLogs-table",
  employeeTermsTable = "MELP-employeeTerms-table",
  employeesTable = "MELP-employees-table",
  groupsAssignBenefitsTable = "MELP-groupsAssignBenefits-table",
  groupsAssignEmployeesTable = "MELP-groupsAssignEmployees-table",
  groupsTable = "MELP-groups-table",
  logsTable = "MELP-logs-table",
  termsTable = "MELP-terms-table",
  newsTable = "MELP-news-table",
  recipientsTable = "MELP-recipients-table",
  usersTable = "MELP-users-table",
}
