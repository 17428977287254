import { Typography, Switch, Box } from "@material-ui/core";
import { CellProps } from "react-table";
import {
  getRawValue,
  handleRowAction,
  MetaContext,
  getColumnAction,
  MpRowActionMethods,
  RtMpColumnInstance,
} from "@mp-react/table";
import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from "react";
import moment from "moment";
import useStyles from "./CustomRenderers.styles";
import { browserLanguage } from "../../../utils/Common";

export default function Enum(props: CellProps<{}>) {
  const classes = useStyles();
  const { data, row, column } = props;
  const rowIndex = useMemo(() => row.index, [row.index]);
  const { rowMethods } = useContext(MetaContext);
  const action = useMemo(
    () => getColumnAction(column as RtMpColumnInstance),
    [column]
  );
  const id = useMemo(() => "inactive", []);
  const flexImplementationDateid = useMemo(() => "flexImplementationDate", []);
  const value = useMemo(
    () => getRawValue(data[rowIndex], flexImplementationDateid),
    [data, rowIndex, flexImplementationDateid]
  );
  const inactive = useMemo(
    () => getRawValue(data[rowIndex], id),
    [data, rowIndex, id]
  );
  const valueDate = useMemo(
    () => moment(value).locale(browserLanguage).format("L"),
    [value]
  );
  const [implementationDate, setImplementationDate] = useState(
    typeof value === "string" ? valueDate : null
  );
  const [switchStatus, setSwitchStatus] = useState<boolean>(
    typeof value === "string" ? true : false
  );

  useEffect(() => {
    if (typeof value === "string") {
      setSwitchStatus(true);
      setImplementationDate(valueDate);
    } else if (value === null) {
      setSwitchStatus(false);
      setImplementationDate(null);
    }
  }, [value, setSwitchStatus, setImplementationDate, valueDate]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSwitchStatus((prevState) => !prevState);
      setImplementationDate((prevState) => {
        if (!!switchStatus) return null;
        return moment().locale(browserLanguage).format("L");
      });
      handleRowAction({
        methods: rowMethods as MpRowActionMethods,
        slug: action.slug,
        value: switchStatus === false ? moment().toISOString() : false,
        row: row,
        rowId: row.id,
      });
    },
    [
      setSwitchStatus,
      setImplementationDate,
      switchStatus,
      action.slug,
      row,
      rowMethods,
    ]
  );

  const disabled = useMemo(() => {
    const flexStatus = (row?.original as any)?.flexStatus;
    if (!!flexStatus || flexStatus === null) return flexStatus !== "selected";

    return inactive === true ? true : false;
  }, [inactive, row?.original]);

  const display = useMemo(() => {
    if (disabled) {
      return "none";
    } else {
      return "flex";
    }
  }, [disabled]);

  return (
    <>
      <Box
        display={display}
        minWidth="130px"
        paddingY="14px"
        paddingX="19px"
        onClick={(e) => e.stopPropagation()}
      >
        <Switch
          classes={{ root: classes.implementationToggle }}
          color="primary"
          disabled={disabled}
          onChange={handleChange}
          checked={switchStatus}
        />
        <Typography variant="body2">{implementationDate}</Typography>
      </Box>
    </>
  );
}
