import { Currency } from "../types/Currency";
import { browserLanguage } from "./Common";
import { isDefined } from "./isDefined";

const currencyFractionalUnitMap: Record<Currency, number> = {
  [Currency.EUR]: 100,
  [Currency.USD]: 100,
  [Currency.PLN]: 100,
  [Currency.RUB]: 100,
  [Currency.GBP]: 100,
};

const toMainUnitAmount = (currency: Currency, fractionalUnitAmount: number) => {
  if (!Number.isInteger(fractionalUnitAmount)) {
    throw Error("Only integer numbers are allowed");
  }
  const currencyDivisor = currencyFractionalUnitMap[currency];
  return fractionalUnitAmount / currencyDivisor;
};

const toFractionalUnitAmount = (currency: Currency, mainUnitAmount: number) => {
  const currencyMultiplier = currencyFractionalUnitMap[currency];
  return Math.round(mainUnitAmount * currencyMultiplier);
};

export const currencyConverters = { toMainUnitAmount, toFractionalUnitAmount };

const formatMainUnitAmount = (amount?: number | null, currency?: Currency) => {
  const locale = browserLanguage;
  if (!isDefined(amount)) {
    return "";
  }
  const options = currency
    ? { style: "currency", currency, currencyDisplay: "code" }
    : { minimumFractionDigits: 2 };
  return new Intl.NumberFormat(locale, options).format(amount);
};

const formatFractionalUnitAmount = (
  fractionalUnitAmount?: number | null,
  currency?: Currency,
  skipCurrency?: boolean
) => {
  if (!isDefined(fractionalUnitAmount) || !currency) {
    return "";
  }
  const mainUnitAmount = currencyConverters.toMainUnitAmount(
    currency,
    fractionalUnitAmount
  );
  return formatMainUnitAmount(
    mainUnitAmount,
    skipCurrency ? undefined : currency
  );
};

export const currencyFormatter = {
  format: formatMainUnitAmount,
  formatFractionalUnitAmount,
};
