import { useLoading } from "./../utils/Loading";
import { Endpoints } from "./../api/constants";
import { useMemo } from "react";
import { UseEmployeeLog } from "./../types/EmployeeLogs";
import useSWR from "swr";

export const useEmployeeActionLog = (
  employeeId?: string,
  query?: string
): UseEmployeeLog => {
  const { loading } = useLoading();
  const queryParam = useMemo(() => (!!query ? `&${query}` : ""), [query]);
  const { data: employeeActionLog, error: employeeActionLogError } = useSWR(
    `${Endpoints.actionLog}?filter[employeeId]=${employeeId}${queryParam}`
  );

  const apiLoading = useMemo(() => {
    return !employeeActionLog && !employeeActionLogError;
  }, [employeeActionLog, employeeActionLogError]);

  return {
    loading: loading || apiLoading,
    employeeActionLog,
    employeeActionLogError,
  };
};
