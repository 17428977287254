import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceByElement } from "../../../../../utils/Logs";

interface IProps {
  benefitPlan: string;
}

export default function BenefitDeactivated(props: IProps) {
  const { benefitPlan } = props;
  const { t } = useTranslation();

  return useMemo(
    () =>
      replaceByElement(
        t("log.benefit_was_deactivated"),
        "{benefitName}",
        benefitPlan
      ),
    [benefitPlan, t]
  );
}
