import React, { useMemo } from "react";
import {
  MpControlProps,
  controlRegisterOptions,
  useErrorMessages,
} from "@mp-react/form";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Box, FormControl, FormHelperText } from "@material-ui/core";
import useStyles from "./Phone.styles";
import clsx from "clsx";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import { useTranslation } from "react-i18next";

function PhoneView({
  control,
  variant,
  onChange,
  value,
  error,
  defaultMessages,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <FormControl
        className={clsx({
          [classes.root]: true,
          [classes.error]: !!error,
        })}
        fullWidth
      >
        <PhoneInput
          prefix="+"
          placeholder={t("placeholder.phone_number")}
          value={`+${value}` ?? "+"}
          onChange={(phone) => onChange(phone)}
          disableDropdown
        />
        <FormHelperText margin="dense" variant="outlined" error={!!error}>
          {firstMessage}
        </FormHelperText>
      </FormControl>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function Phone({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      control={hookFormControl}
      defaultValue={""}
      render={({ onChange, value }) => (
        <PhoneView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
