import { Typography } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import { useMemo } from "react";

export default function SimpleStringArrray(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const joinedValue = useMemo(() => value?.join(", ") ?? "", [value]);

  return <Typography variant="body2">{joinedValue}</Typography>;
}
