import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: "30px",
      paddingBottom: "100px",
    },
    maxWidthLg: {
      maxWidth: 1260,
    },
  })
);
