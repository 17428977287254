import {
  Box,
  Fade,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { MpControlProps } from "@mp-react/form/build/types/Form";
import React, { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import { controlRegisterOptions } from "@mp-react/form";
import MuiSwitch from "@material-ui/core/Switch";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useStyles from "./ToggleGroup.styles";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useTooltip from "../../../utils/Tooltip";
import { browserLanguage } from "../../../utils/Common";

function ToggleTermsView({
  control,
  layout,
  onChange,
  value,
  size,
}: MpControlProps & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const today = useMemo(() => moment().locale(browserLanguage).format("L"), []);
  const { t } = useTranslation();
  const classes = useStyles();
  const { tooltip, closeTooltip, openTooltip, anchorEl } = useTooltip(control);

  const parsedValue = useMemo(() => {
    const momentDate = moment(value);
    const isValidDate = momentDate.isValid();
    return isValidDate ? momentDate.locale(browserLanguage).format("L") : value;
  }, [value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (control.key === "termsConditionsRequiredFrom") {
        onChange(event.target.checked ? today : null);
      } else {
        onChange(event.target.checked);
      }
    },
    [onChange, today, control.key]
  );

  const content = useMemo(() => {
    switch (control.key) {
      case "temporaryEmployment":
        return { textPrimary: t("status.active"), value: null };
      case "termsConditionsRequiredFrom":
        return { textPrimary: `${t("form.required_from")}:`, value: today };
      default:
        return;
    }
  }, [control.key, t, today]);

  return (
    <Box
      display="flex"
      alignItems="center"
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <FormControlLabel
        control={
          <MuiSwitch
            name={control.key}
            color="primary"
            onChange={handleChange}
            checked={!!value}
          />
        }
        label={
          !value && (
            <Typography variant="body2" className={classes.inactive}>
              {t("status.inactive")}
            </Typography>
          )
        }
      />
      <Fade in={!!value}>
        <Box paddingTop="13px" display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="body2" noWrap>
              {content?.textPrimary}
            </Typography>
          </Box>
          {!!content?.value && (
            <TextField
              value={parsedValue ?? content?.value}
              onChange={onChange}
              name={control.key}
              disabled
              fullWidth
              size={size}
              margin="dense"
              className={classes.dateInput}
            />
          )}
        </Box>
      </Fade>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function ToggleGroup({
  control,
  size,
  layout,
  variant,
  register,
  locale,
  errors,
  hookFormControl,
  defaultMessages,
  overridables,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value }) => (
        <ToggleTermsView
          onChange={onChange}
          value={value}
          control={control}
          defaultMessages={defaultMessages}
          register={register}
          error={errors?.[control?.key ?? ""]}
          size={size}
          locale={locale}
          hookFormControl={hookFormControl}
          layout={layout}
          variant={variant}
          overridables={overridables}
        />
      )}
    />
  );
}
