import {
  Box,
  Divider,
  InputBase,
  TextFieldProps,
  Typography,
} from "@material-ui/core";
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import useStyles from "./DateRange.styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MpFilterProps } from "@mp-react/table/build/types/Filter";
import { MetaContext, useFilterOptions } from "@mp-react/table";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import {
  useDatePlaceholder,
  browserLanguage,
  useDateFormat,
} from "../../../../utils/Common";
import moment from "moment";

moment.locale(browserLanguage);

function InputBaseWithProps({
  InputProps,
  margin,
  onKeyDown,
  onKeyUp,
  helperText,
  ...props
}: TextFieldProps) {
  return <InputBase {...InputProps} {...props} />;
}

export default function DateRange({
  value,
  setValue,
  doFiltering,
  column,
}: MpFilterProps) {
  const classes = useStyles();
  const { mpColumn } = column;
  const filter = useFilterOptions(mpColumn);
  const { translations } = useContext(MetaContext);
  const { datePlaceholder } = useDatePlaceholder();
  const { keyboardDatePickerFormat } = useDateFormat();
  const [minPickerStatus, setMinPickerStatus] = useState<boolean>(false);
  const [maxPickerStatus, setMaxPickerStatus] = useState<boolean>(false);

  const handleChangeMin = useCallback(
    (date: MaterialUiPickersDate) => {
      const evVal = moment(date).startOf("day");
      if (evVal != null) {
        setValue((val: any) => ({
          ...(val ?? {}),
          from: evVal.toISOString(),
        }));
      } else {
        setValue((val: any) =>
          val?.max != null && val?.max !== "" ? { to: val?.max } : null
        );
      }
    },
    [setValue]
  );

  const handleChangeMax = useCallback(
    (date: MaterialUiPickersDate) => {
      const evVal = moment(date).endOf("day");
      if (evVal != null) {
        setValue((val: any) => ({
          ...(val ?? {}),
          to: evVal.toISOString(),
        }));
      } else {
        setValue((val: any) =>
          val?.min != null && val?.min !== "" ? { from: val?.from } : null
        );
      }
    },
    [setValue]
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering]
  );

  const valueFrom = useMemo(
    () => (!!value?.from ? moment(value?.from).locale(browserLanguage) : null),
    [value?.from]
  );

  const valueTo = useMemo(
    () => (!!value?.to ? moment(value?.to).locale(browserLanguage) : null),
    [value?.to]
  );

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={browserLanguage}
    >
      <Box display="flex" flexDirection="column">
        <Box
          paddingLeft={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2">{filter?.label ?? ""}</Typography>
          <Box paddingX={2} paddingY={1.5}>
            <Typography
              variant="body2"
              color="textSecondary"
              onClick={handleClear}
              className={classes.emptyAnchor}
            >
              {translations?.clear ?? "Clear"}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box paddingY={1} paddingX={2}>
          <KeyboardDatePicker
            autoOk={true}
            disableToolbar
            variant="inline"
            format={keyboardDatePickerFormat}
            label="Date picker inline"
            placeholder={datePlaceholder}
            value={valueFrom}
            onChange={handleChangeMin}
            TextFieldComponent={InputBaseWithProps}
            fullWidth
            keyboardIcon={<CalendarIcon />}
            className={classes.keyboardDatePicker}
            maxDate={value?.to}
            InputProps={{ readOnly: true }}
            onClick={() => setMinPickerStatus(true)}
            onClose={() => setMinPickerStatus(false)}
            open={minPickerStatus}
          />
        </Box>
        <Divider />
        <Box paddingY={1} paddingX={2}>
          <KeyboardDatePicker
            autoOk={true}
            disableToolbar
            variant="inline"
            format={keyboardDatePickerFormat}
            label="Date picker inline"
            placeholder={datePlaceholder}
            value={valueTo}
            onChange={handleChangeMax}
            TextFieldComponent={InputBaseWithProps}
            fullWidth
            keyboardIcon={<CalendarIcon />}
            className={classes.keyboardDatePicker}
            minDate={value?.from}
            InputProps={{ readOnly: true }}
            onClick={() => setMaxPickerStatus(true)}
            onClose={() => setMaxPickerStatus(false)}
            open={maxPickerStatus}
          />
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
}
