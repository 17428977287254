import { IconButton, TextField, TextFieldProps } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import useStyles from "./TableSearch.styles";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTableStore } from "../../../state/Table";
import { useDebounce } from "@mp-react/table";
import { ReactComponent as Cross } from "../../../assets/icons/cross.svg";

export default function TableSearch(props: TextFieldProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const debounce = useDebounce();
  const { setSearch, searchQuery } = useTableStore();
  const [searchState, setSearchState] = useState<string>(searchQuery);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setSearchState(value);
      debounce(() => setSearch(value));
    },
    [debounce, setSearch]
  );

  const clearSearch = useCallback(() => {
    setSearchState("");
    debounce(() => setSearch(""));
  }, [debounce, setSearch]);

  const EndAdornment = useMemo(() => {
    if (!!searchState) {
      return (
        <IconButton onClick={clearSearch} className={classes.clearButton}>
          <Cross />
        </IconButton>
      );
    }
    return (
      <IconButton className={classes.searchButton}>
        <SearchIcon />
      </IconButton>
    );
  }, [classes.clearButton, classes.searchButton, clearSearch, searchState]);

  return (
    <TextField
      InputProps={{ endAdornment: EndAdornment }}
      variant="outlined"
      classes={{ root: classes.root }}
      margin="dense"
      placeholder={t("common.search")}
      onChange={handleSearch}
      value={searchState}
      {...props}
    />
  );
}
