import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import React from "react";

export default function InnerEmployeeChoicesHeaders() {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5">{t("employees.employee_log")}</Typography>
      <TableDatepicker />
    </Box>
  );
}
