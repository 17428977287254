import React from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddBenefits from "../../../common/AddBenefits/AddBenefits";
import { useBenefits } from "../../../../state/Benefits";
import useTableUtils from "../../../../utils/Table";
import DownloadExcelButton from "../../../table/DownloadExcelButton/DownloadExcelButton";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";

export default function BenefitsHeader() {
  const { t } = useTranslation();
  const { tableQueryParam } = useTableUtils("benefits");
  const { exportToExcel, loading, benefitPlans } = useBenefits(
    undefined,
    tableQueryParam
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <DownloadExcelButton
        listLength={benefitPlans?.count}
        loading={loading}
        exportToExcel={exportToExcel}
        marginRight="20px"
      />
      <TableDatepicker marginRight="20px" />
      <AddBenefits label={t("buttons.new_benefit")} />
    </Box>
  );
}
