import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Chip,
  // IconButton,
  // Tooltip,
  // Typography,
} from "@material-ui/core";
import useStyles from "./AssignGroupsFooter.styles";
import { useTranslation } from "react-i18next";
import useStatus from "../../../utils/Status";
// import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import ActionsPopper from "../../common/ActionsPopper/ActionsPopper";
import InvestmentTooltip from "../../common/InvestmentTooltip/InvestmentTooltip";
import { useInvestmentCalculationsStore } from "../../../state/InvestmentCalculations";
// import FooterTooltipContent from "../../common/FooterTooltipContent/FooterTooltipContent";
// import { formatDecimal } from "../../../utils/Common";

interface IProps {
  onCancel: () => void;
  onSaveDraft?: () => void;
  onPublish: (date?: string) => void;
}

export default function AssignGroupsFooter(props: IProps) {
  const { onCancel, onPublish, onSaveDraft } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { actions } = useStatus();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const popperActions = useMemo(
    () => actions.benefits?.table ?? [],
    [actions.benefits?.table]
  );

  const changeCount = useInvestmentCalculationsStore(
    (state) => state.changeCount
  );

  const handleOpenActions = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseActions = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleActionChange = useCallback(
    (action: string, date?: string) => {
      handleCloseActions();
      const parsedDate =
        !!date && action === "on_specific_date"
          ? `${date.split("T")[0]}T12:00:00Z`
          : date;
      onPublish(parsedDate);
    },
    [handleCloseActions, onPublish]
  );

  const ChangeChip = useMemo(() => {
    if (changeCount && changeCount > 0)
      return (
        <Chip
          label={`${changeCount} ${t("common.changed")}`}
          className={classes.changesChip}
        />
      );
    return null;
  }, [changeCount, classes.changesChip, t]);

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <InvestmentTooltip />
      <Box>
        <Button disableElevation className={classes.button} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
        {onSaveDraft && (
          <Button
            type="submit"
            name="draft"
            variant="contained"
            disableElevation
            className={classes.draftButton}
            onClick={onSaveDraft}
          >
            {t("buttons.save_as_draft")}
          </Button>
        )}
        <Button
          name="publish"
          color="secondary"
          className={classes.button}
          variant="contained"
          disableElevation
          onClick={handleOpenActions}
        >
          {t("common.confirm_changes")}
        </Button>
        {ChangeChip}
      </Box>
      <ActionsPopper
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseActions}
        placement="bottom-start"
        actions={popperActions ?? []}
        onActionClick={handleActionChange}
        hideTimePicker={true}
      />
    </Box>
  );
}
