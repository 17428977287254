import React from "react";
import {
  makeStyles,
  Typography as MuiTypography,
  TypographyProps,
} from "@material-ui/core";
import clsx from "clsx";
import { Colors } from "../../style/Colors";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: "Inter",
  },
  header1: {
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.1",
  },
  header2: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.4",
  },
  header3: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.4",
  },
  header4: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.4",
  },
  paragraph1: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "1.4",
  },
  paragraph2: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "1.4",
  },
  textPrimary: {
    color: Colors.black,
  },
  textSecondary: {
    color: Colors.grey,
  },
  errorText: {
    color: Colors.red,
  },
  warningText: {
    color: Colors.yellow,
  },
  successText: {
    color: Colors.green,
  },
  primaryText: {
    color: Colors.blue,
  },
}));

type TypographyVariant = "h1" | "h2" | "h3" | "h4" | "p1" | "p2";
type MuiTypographyVariant = TypographyProps["variant"];
type TypographyColor =
  | "textPrimary"
  | "textSecondary"
  | "primary"
  | "error"
  | "warning"
  | "success";

interface Props
  extends Omit<TypographyProps, "variant" | "color" | "component"> {
  variant?: TypographyVariant;
  color?: TypographyColor;
  component?: "span";
}

const Typography = ({
  variant = "p1",
  color = "textPrimary",
  className,
  ...props
}: Props) => {
  const classes = useStyles();
  const styleMap: Record<TypographyVariant, string> = {
    h1: classes.header1,
    h2: classes.header2,
    h3: classes.header3,
    h4: classes.header4,
    p1: classes.paragraph1,
    p2: classes.paragraph2,
  };
  const muiVariantMap: Record<TypographyVariant, MuiTypographyVariant> = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    p1: "body1",
    p2: "body2",
  };
  const colorStyleMap: Record<TypographyColor, string> = {
    textPrimary: classes.textPrimary,
    textSecondary: classes.textSecondary,
    error: classes.errorText,
    warning: classes.warningText,
    success: classes.successText,
    primary: classes.primaryText,
  };
  return (
    <MuiTypography
      variant={muiVariantMap[variant]}
      className={clsx(
        classes.typography,
        styleMap[variant],
        colorStyleMap[color],
        className
      )}
      {...props}
    />
  );
};

export default Typography;
