import { Colors } from "./../../../constants/Style";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: "32px 45px 40px 45px",
      textAlign: "center",
    },
    dialogActionsRoot: {
      padding: 0,
      marginTop: 23,
      justifyContent: "center",
    },
    errorButton: {
      background: Colors.Error,
      color: Colors.White,
    },
    subtitle: {
      whiteSpace: "break-spaces",
    },
  })
);
