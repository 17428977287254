import { Button, ButtonProps } from "@material-ui/core";
import { ReactComponent as AddIcon } from "../../../assets/icons/add-icon.svg";
import React from "react";
import useStyles from "./AddNewButton.styles";

const AddNewButton = (props: ButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      endIcon={<AddIcon />}
      className={classes.button}
      {...props}
    />
  );
};

export default AddNewButton;
