import { useLoading } from "../utils/Loading";
import { useTranslation } from "react-i18next";
import { Endpoints } from "../api/constants";
import useSWR, { mutate } from "swr";
import { useMemo, useCallback, useState } from "react";
import {
  UseAssignEmployeeGroups,
  EmployeeGroupsAssignEmployeeDataItem,
  GroupsAssignEmployeeRequest,
} from "../types/EmployeeGroups";
import { mutateData } from "../api/api";
import { toast } from "react-toastify";
import { useInvestmentCalculationsStore } from "./InvestmentCalculations";

const useEmployeeAssignGroups = (
  employeeId?: string
): UseAssignEmployeeGroups => {
  const { t } = useTranslation();
  const {
    data: employeeAssignGroupsList,
    error: employeeAssignGroupsListError,
  } = useSWR(
    !!employeeId
      ? `${Endpoints.employee}/${employeeId}/employeeGroupAssignments`
      : null
  );
  const { stopLoading, startLoading, loading } = useLoading();
  const { resetStatusChanges, resetAllChanges } =
    useInvestmentCalculationsStore();
  const [switchValues, setSwitchValues] = useState<Record<string, boolean>>({});

  const apiLoading = useMemo(
    () => !employeeAssignGroupsList && !employeeAssignGroupsListError,
    [employeeAssignGroupsList, employeeAssignGroupsListError]
  );

  const parsedEmployeeAssignGroups: EmployeeGroupsAssignEmployeeDataItem[] =
    useMemo(
      () =>
        employeeAssignGroupsList?.data?.map((data: any) => ({
          ...data,
          benefitTypes: data.benefitPlans,
          inactive: !Number(data.included),
          included: !!Number(data.included),
        })),
      [employeeAssignGroupsList?.data]
    );

  const employeeGroupIdDefaultValues: string[] = useMemo(
    () =>
      employeeAssignGroupsList?.data
        ?.filter((item: any) => item.included === 1)
        .map((item: any) => item.id),
    [employeeAssignGroupsList]
  );

  const parsedEmployeeGroupsTotals = useMemo(() => {
    const initialTotals = {
      name: "",
      included: "",
    };
    if (!employeeAssignGroupsList?.footer) return initialTotals;
    const { name, included } = employeeAssignGroupsList.footer;
    return {
      name: t("totals.employee_group", { count: Number(name) }),
      included: t("totals.included", { count: Number(included) }),
    };
  }, [employeeAssignGroupsList, t]);

  const mapAssignEmployeesRequest =
    useCallback((): GroupsAssignEmployeeRequest => {
      const switchEntries = Object.entries(switchValues);
      const assignToEmployee = switchEntries
        .filter(([, value]) => value === true)
        .map(([key]) => key);
      const removeFromEmployee = switchEntries
        .filter(([, value]) => value === false)
        .map(([key]) => key);

      return {
        assignToEmployee,
        removeFromEmployee,
      };
    }, [switchValues]);

  const updateAssignGroups = useCallback(
    (date: string) => {
      if (!employeeId) return;
      startLoading();
      const groupsData = mapAssignEmployeesRequest();
      const requestData = {
        ...groupsData,
        fromDate: date,
        removedDate: date,
      };
      mutateData(
        "patch",
        `${Endpoints.employee}/${employeeId}/assignEmployeeGroups`,
        requestData
      )
        .then(() => {
          toast(t("common.assigned_succesfully"), { type: "success" });
          mutate(
            `${Endpoints.employee}/${employeeId}/employeeGroupAssignments`
          );
        })
        .then(() => {
          resetStatusChanges();
          resetAllChanges();
        })
        .finally(() => stopLoading());
    },
    [
      employeeId,
      mapAssignEmployeesRequest,
      resetAllChanges,
      startLoading,
      stopLoading,
      resetStatusChanges,
      t,
    ]
  );

  const addSwitchValue = useCallback(
    (rowId: string, value: boolean) =>
      setSwitchValues((prevState) => ({ ...prevState, [rowId]: value })),
    []
  );

  const clearSwitchValues = useCallback(() => setSwitchValues({}), []);

  const createEmployeeGroup = useCallback(
    (name: string) => {
      startLoading();
      mutateData("post", Endpoints.employeeGroup, {
        name: name,
      })
        .then(() => {
          resetStatusChanges();
          resetAllChanges();
        })
        .then(() => {
          toast(t("common.added_succesfully"), { type: "success" });
          mutate(
            `${Endpoints.employee}/${employeeId}/employeeGroupAssignments`
          );
        })
        .finally(() => {
          stopLoading();
        });
    },
    [
      startLoading,
      stopLoading,
      t,
      employeeId,
      resetStatusChanges,
      resetAllChanges,
    ]
  );

  return {
    loading: loading || apiLoading,
    employeeAssignGroupsList,
    employeeAssignGroupsListError,
    parsedEmployeeAssignGroups,
    parsedEmployeeGroupsTotals,
    clearSwitchValues,
    addSwitchValue,
    employeeGroupIdDefaultValues,
    updateAssignGroups,
    createEmployeeGroup,
  };
};

export default useEmployeeAssignGroups;
