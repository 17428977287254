import { Typography } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { truncate } from "../../../utils/Common";
import { useTableStore } from "../../../state/Table";

export default function EmployeeGroup(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { t } = useTranslation();
  const currentTableVariant = useTableStore((state) => state.variant);

  const isBenefitAssignEmployeesTable =
    currentTableVariant === "benefitsAssignEmployees";

  const text = useMemo(() => {
    if (!!value && value?.length > 0 && isBenefitAssignEmployeesTable)
      return value?.length;

    if (value === null) {
      return t("common.dash");
    } else if (!!value?.length && value?.length > 1) {
      return `${value.length} ${t("menu.groups").toLowerCase()}`;
    } else if (value?.length === 0) {
      return t("common.dash");
    } else {
      if (!!value?.[0]) return truncate(value[0], 20);
      return value;
    }
  }, [value, isBenefitAssignEmployeesTable, t]);

  return (
    <Typography
      variant="body2"
      style={{ textAlign: isBenefitAssignEmployeesTable ? "center" : "left" }}
    >
      {text}
    </Typography>
  );
}
