import React from "react";
import ReactCountryFlag from "react-country-flag";
import useStyles from "./CountryFlag.styles";
import { ReactComponent as WorldFlag } from "../../../assets/flags/world.svg";
import { alpha3ToAlpha2 } from "i18n-iso-countries";

interface Props {
  /**
   * ISO 3166-1 Alpha-2 or Alpha-3 country code
   */
  countryCode?: string;
}

const CountryFlag = ({ countryCode }: Props) => {
  const styles = useStyles();
  if (!countryCode) {
    return null;
  }
  const renderFlag = (flagElement: React.ReactNode) => {
    return (
      <span className={styles.container}>
        <span className={styles.frame} />
        {flagElement}
      </span>
    );
  };
  if (countryCode === "WORLD") {
    return renderFlag(<WorldFlag />);
  }
  const alpha2Code =
    countryCode.length === 3 ? alpha3ToAlpha2(countryCode) : countryCode;
  return renderFlag(
    <ReactCountryFlag svg countryCode={alpha2Code} className={styles.flag} />
  );
};

export default CountryFlag;
