import React, { useMemo } from "react";
import { Tab, Tabs, Box, Typography, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./FormTabs.styles";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import ActionsPopper from "../../common/ActionsPopper/ActionsPopper";
import { FormTab } from "../../../types/Common";
import clsx from "clsx";

interface IProps {
  value: number;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  moreButton: boolean;
  onActionChange: (action: any) => void;
  tabs: FormTab[];
  unfilledTabs: string[];
}

export default function FormTabs(props: IProps) {
  const {
    value,
    handleChange,
    onActionChange,
    tabs,
    moreButton,
    unfilledTabs,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const visibleTabs = useMemo(() => 3, []);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const isMoreActive = useMemo(
    () => value >= visibleTabs,
    [value, visibleTabs]
  );
  const unfilledMoreTabs = useMemo(
    () => tabs.slice(visibleTabs).some((tab) => unfilledTabs.includes(tab.key)),
    [tabs, unfilledTabs, visibleTabs]
  );

  const handleOpenActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleActionChange = (action: any) => {
    handleCloseActions();
    onActionChange(tabs.indexOf(action));
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      marginBottom="30px"
      maxHeight={44}
      className={classes.tabsWrapper}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ children: <span /> }}
        classes={{ root: classes.tabs, indicator: classes.indicator }}
      >
        {tabs.slice(0, visibleTabs).map((tab, i) => (
          <Tab
            key={`FormTab${i}`}
            label={
              <Typography variant="body1" color="textSecondary">
                {t(tab.label)}
              </Typography>
            }
            className={clsx({
              [classes.unfilledTab]: tab.key && unfilledTabs.includes(tab.key),
              [classes.tabsDivider]:
                value !== i &&
                value - 1 !== i &&
                (isMoreActive ? i + 1 !== visibleTabs : true),
            })}
          />
        ))}
        {tabs.slice(visibleTabs).map((tab, i) => (
          <Tab key={`HiddenFormTab${i}`} label="" className={classes.hidden} />
        ))}
      </Tabs>
      {moreButton && (
        <IconButton
          className={clsx({
            [classes.moreBtn]: true,
            [classes.activeMoreBtn]: isMoreActive,
            [classes.unfilledMoreBtn]: unfilledMoreTabs,
          })}
          onClick={handleOpenActions}
        >
          <ChevronDown />
        </IconButton>
      )}
      <ActionsPopper
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseActions}
        placement="bottom-start"
        actions={tabs.slice(visibleTabs)}
        onActionClick={handleActionChange}
        indicators={unfilledTabs}
        activeItem={value - 3}
      />
    </Box>
  );
}
