import { Grid, Box, GridProps, BoxProps } from "@material-ui/core";
import useStyles from "./SidebarContainer.styles";
import React from "react";

const SidebarContainer = ({ children, ...rest }: GridProps & BoxProps) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.disableScrollbar}
      classes={{
        "grid-xs-12": classes.sidebar,
      }}
      component={Box}
      xs={12}
      item
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default SidebarContainer;
