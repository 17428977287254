import {
  Box,
  BoxProps,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EditableTitle from "../../employee_groups/EditableTitle/EditableTitle";
import useStyles from "./Title.styles";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-outlined.svg";
import { useLoading } from "../../../utils/Loading";
import clsx from "clsx";

interface IProps extends BoxProps {
  title?: string;
  subtitle?: string;
  avatar?: boolean;
  firstName?: string;
  lastName?: string;
  editable?: boolean;
  onEditComplete?: (value: string) => Promise<boolean>;
}

const Title = ({
  title,
  avatar,
  firstName,
  lastName,
  editable,
  onEditComplete,
  subtitle,
  ...rest
}: IProps) => {
  const classes = useStyles({ avatar, editable, withSubtitle: !!subtitle });
  const inputRef = useRef<HTMLInputElement>();
  const { loading, startLoading, stopLoading } = useLoading();

  const [editedTitle, setEditedTitle] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const titleText = useMemo(() => {
    if (!!title) return title;

    const firstNameString = !!firstName ? firstName : "";
    const lastNameString = !!lastName ? lastName : "";
    const space = !!firstNameString ? " " : "";

    return `${firstNameString}${space}${lastNameString}`;
  }, [firstName, lastName, title]);

  const TitleComponent = useMemo(() => {
    if (!title && !firstName && !lastName) return <Skeleton width={40} />;
    return titleText;
  }, [firstName, lastName, title, titleText]);

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (error) setError(false);
      const value = event.target.value;
      setEditedTitle(value);
    },
    [error]
  );

  const handleTitleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (editedTitle === titleText) {
        setIsEditing(false);
        return;
      }

      startLoading();

      const isSuccess = await onEditComplete?.(editedTitle);

      setTimeout(() => {
        stopLoading();
        if (isSuccess) setIsEditing(false);
        else {
          setError(true);
          inputRef?.current?.focus();
        }
      }, 1000);
    },
    [editedTitle, onEditComplete, startLoading, stopLoading, titleText]
  );

  const enableEditing = useCallback(() => setIsEditing(true), []);

  const handleTitleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const { key } = e;
      if (key === "Escape") {
        setIsEditing(false);
      }
    },
    []
  );

  useEffect(() => {
    if (!!titleText && titleText !== editedTitle && !isEditing) {
      setEditedTitle(titleText);
    }
  }, [editedTitle, isEditing, titleText]);

  useEffect(() => {
    if (isEditing) inputRef?.current?.focus();
  }, [isEditing]);

  return (
    <Box
      className={clsx(classes.titleContainer, {
        [classes.editTitleContainer]: editable,
      })}
      {...rest}
    >
      {avatar && (
        <Box className={classes.avatarContainer}>
          <Typography
            classes={{ h5: classes.title }}
            className={classes.avatar}
            variant="h5"
            style={{ textTransform: "capitalize" }}
          >
            {firstName?.substring(0, 1)}
            {lastName?.substring(0, 1)}
          </Typography>
        </Box>
      )}
      <Box mt={subtitle && avatar ? "15px" : 0}>
        <Collapse
          in={editable && isEditing}
          className={classes.editableCollapse}
        >
          <form onSubmit={handleTitleSubmit} className={classes.formWrapper}>
            <EditableTitle
              inputRef={inputRef}
              value={editedTitle}
              onChange={handleTitleChange}
              className={classes.editableTitle}
              disabled={loading}
              onKeyUp={handleTitleKeyUp}
              error={error}
              autoFocus={true}
            />
            <IconButton
              disabled={loading}
              size="small"
              type="submit"
              className={classes.editButton}
            >
              {loading ? (
                <CircularProgress
                  size={25}
                  classes={{ root: classes.progress }}
                />
              ) : (
                <EditIcon className={classes.editIcon} />
              )}
            </IconButton>
          </form>
        </Collapse>
        <Collapse in={!isEditing}>
          <Box className={classes.titleInnerContainer}>
            <Typography
              classes={{ h5: classes.title }}
              className={editable ? classes.titleWithEdit : ""}
              variant="h5"
              onClick={editable ? enableEditing : undefined}
            >
              {TitleComponent}
            </Typography>
            {editable && (
              <IconButton
                disabled={loading}
                size="small"
                className={classes.editButton}
                onClick={enableEditing}
              >
                {loading ? (
                  <CircularProgress
                    size={25}
                    classes={{ root: classes.progress }}
                  />
                ) : (
                  <EditIcon className={classes.editIcon} />
                )}
              </IconButton>
            )}
          </Box>
        </Collapse>
        {!!subtitle && (
          <Box>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Title;
