import React, { useMemo } from "react";
import {
  EmployeeGroupCreated,
  EmployeeGroupDeleted,
  BenefitRemovedFromGroup,
  BenefitAssignedToGroup,
  EmployeeAssignedToGroup,
  EmployeeRemovedFromGroup,
  EmployeeCreated,
  EmployeeSuccessfullyLinkedWithAnAppUser,
  InvitationReminder,
  EmployeeStatusChangedToInactive,
  EmployeeFlexLimitAllocationModified,
  BenefitCreated,
  BenefitDisabled,
  BenefitExpired,
  UserSelectedFlexChoice,
  UserDeclinedFlexChoice,
  FlexVoteIsOver,
  BenefitRemovedFromUser,
  FlexRemovedFromUser,
  HrAdminCreatedByMelp,
  AdminCreated,
  HrUserLoggedIn,
  NewsCreated,
  FailedToSendEmail,
  ChoiceReminderSent,
  BenefitDeactivated,
  TermsReminderSent,
} from "./variants";
import { LogActions, Log } from "../../../../types/Logs";
import { CellProps } from "react-table";
import { getRawValue, useRendererOptions } from "@mp-react/table";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../../utils/Currency";
import moment from "moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  messageContainer: {
    "& h6": {
      display: "inherit",
    },
  },
}));

const newApproachKeys = [
  "employeeOrderedMarketplaceItem",
  "melpChangedMarketplaceOrderStatus",
  "deletedEmployeeWithOpenMarketplaceOrder",
  "expenseCreatedFromMarketplaceItem",
  "flexAssignedToUser",
  "benefitAssignedToUser",
];

const toMessageElement = (template: string, values: Record<string, any>) => {
  const elements = template
    .replaceAll("{", "{$")
    .split(/{|}/)
    .map((token, index) => {
      // is variable
      if (token.startsWith("$")) {
        const parameterName = token.slice(1);
        const value = values[parameterName];
        // OK to use index as content is not dynamic
        return <b key={index}>{value}</b>;
      }
      // is text
      return token;
    });
  return <>{elements}</>;
};

// todo: refactor old logs to use the new approach in scope of MIN-879
export default function LogVariantMap(props: CellProps<{}>) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { value } = useRendererOptions(props);
  const { data, row } = props;
  const rowIndex = useMemo(() => row.index, [row.index]);
  const employeeIdKey = useMemo(() => "employeeId", []);
  const employeeKey = useMemo(() => "employee", []);
  const employeeEmailKey = useMemo(() => "employeeEmail", []);
  const benefitKey = useMemo(() => "benefitPlan", []);
  const benefitTypeKey = useMemo(() => "benefitPlanType", []);
  const employeeGroupKey = useMemo(() => "employeeGroup", []);
  const employeeGroupName = useMemo(() => "employeeGroupName", []);
  const doneByNameKey = useMemo(() => "doneByName", []);
  const allocatedAmountKey = useMemo(() => "allocatedAmount", []);
  const allocationStartDateKey = useMemo(() => "allocationStartDate", []);
  const adminKey = useMemo(() => "admin", []);
  const newsKey = useMemo(() => "news", []);
  const benefitAssignmentStatusKey = useMemo(
    () => "benefitAssignmentStatus",
    []
  );

  const employeeId = useMemo(
    () => getRawValue(data[rowIndex], employeeIdKey),
    [data, rowIndex, employeeIdKey]
  );

  const employee = useMemo(
    () => getRawValue(data[rowIndex], employeeKey),
    [data, rowIndex, employeeKey]
  );

  const employeeEmail = useMemo(
    () => getRawValue(data[rowIndex], employeeEmailKey),
    [data, rowIndex, employeeEmailKey]
  );

  const benefit = useMemo(
    () => getRawValue(data[rowIndex], benefitKey),
    [data, rowIndex, benefitKey]
  );

  const benefitType = useMemo(
    () => getRawValue(data[rowIndex], benefitTypeKey),
    [data, rowIndex, benefitTypeKey]
  );

  const doneByName = useMemo(
    () => getRawValue(data[rowIndex], doneByNameKey),
    [data, rowIndex, doneByNameKey]
  );

  const employeeGroup = useMemo(
    () => getRawValue(data[rowIndex], employeeGroupKey),
    [data, rowIndex, employeeGroupKey]
  );

  const groupName = useMemo(
    () => getRawValue(data[rowIndex], employeeGroupName),
    [data, rowIndex, employeeGroupName]
  );

  const allocatedAmount = useMemo(
    () => getRawValue(data[rowIndex], allocatedAmountKey),
    [data, rowIndex, allocatedAmountKey]
  );

  const allocationStartDate = useMemo(
    () => getRawValue(data[rowIndex], allocationStartDateKey),
    [data, rowIndex, allocationStartDateKey]
  );

  const admin = useMemo(
    () => getRawValue(data[rowIndex], adminKey),
    [data, rowIndex, adminKey]
  );

  const news = useMemo(
    () => getRawValue(data[rowIndex], newsKey),
    [data, rowIndex, newsKey]
  );

  const benefitAssignmentStatus = useMemo(
    () => getRawValue(data[rowIndex], benefitAssignmentStatusKey),
    [data, rowIndex, benefitAssignmentStatusKey]
  );

  const actionsVariant = useMemo(() => value as LogActions, [value]);

  const actions = useMemo(
    () => ({
      employeeGroupCreated: (
        <EmployeeGroupCreated
          doneByName={doneByName}
          employeeGroup={groupName}
        />
      ),
      employeeGroupDeleted: (
        <EmployeeGroupDeleted
          doneByName={doneByName}
          employeeGroup={groupName}
        />
      ),
      benefitRemovedFromGroup: (
        <BenefitRemovedFromGroup
          doneByName={doneByName}
          employeeGroup={employeeGroup}
          benefitPlan={benefit}
        />
      ),
      benefitAssignedToGroup: (
        <BenefitAssignedToGroup
          doneByName={doneByName}
          employeeGroup={employeeGroup}
          benefitPlan={benefit}
          benefitAssignmentStatus={benefitAssignmentStatus}
        />
      ),
      employeeAssignedToGroup: (
        <EmployeeAssignedToGroup
          doneByName={doneByName}
          employeeGroup={employeeGroup}
          employeeId={employeeId}
          employee={employee}
        />
      ),
      employeeRemovedFromGroup: (
        <EmployeeRemovedFromGroup
          doneByName={doneByName}
          employeeGroup={employeeGroup}
          employeeId={employeeId}
          employee={employee}
        />
      ),
      employeeCreated: (
        <EmployeeCreated employeeId={employeeId} employee={employee} />
      ),
      employeeSuccessfullyLinkedWithAnAppUser: (
        <EmployeeSuccessfullyLinkedWithAnAppUser
          employeeId={employeeId}
          employee={employee}
        />
      ),
      invitationReminder: (
        <InvitationReminder
          employeeId={employeeId}
          employee={employee}
          employeeEmail={employeeEmail}
        />
      ),
      employeeStatusChangedToInactive: (
        <EmployeeStatusChangedToInactive
          employeeId={employeeId}
          employee={employee}
        />
      ),
      employeeFlexLimitAllocationModified: (
        <EmployeeFlexLimitAllocationModified
          employeeId={employeeId}
          employee={employee}
          allocationStartDate={allocationStartDate}
          allocatedAmount={allocatedAmount}
        />
      ),
      benefitCreated: <BenefitCreated benefitPlan={benefit} />,
      benefitDisabled: <BenefitDisabled benefitPlan={benefit} />,
      benefitExpired: <BenefitExpired benefitPlan={benefit} />,
      userSelectedFlexChoice: (
        <UserSelectedFlexChoice doneByName={doneByName} benefitPlan={benefit} />
      ),
      userDeclinedFlexChoice: (
        <UserDeclinedFlexChoice doneByName={doneByName} benefitPlan={benefit} />
      ),
      flexVoteIsOver: <FlexVoteIsOver benefitPlan={benefit} />,
      benefitRemovedFromUser: (
        <BenefitRemovedFromUser
          employeeId={employeeId}
          employee={employee}
          benefitPlan={benefit}
        />
      ),
      flexRemovedFromUser: (
        <FlexRemovedFromUser
          employeeId={employeeId}
          employee={employee}
          benefitPlan={benefit}
          benefitPlanType={benefitType}
        />
      ),
      hrAdminCreatedByMelp: <HrAdminCreatedByMelp admin={admin} />,
      adminCreated: <AdminCreated admin={admin} />,
      hrUserLoggedIn: <HrUserLoggedIn admin={admin} />,
      newsCreated: <NewsCreated news={news} />,
      failedToSendEmailToEmployeeBecauseEmployeeMissingEmail: (
        <FailedToSendEmail employeeId={employeeId} employee={employee} />
      ),
      choiceReminderSent: (
        <ChoiceReminderSent employeeId={employeeId} employee={employee} />
      ),
      benefitDeactivated: <BenefitDeactivated benefitPlan={benefit} />,
      termsReminderSent: (
        <TermsReminderSent employeeId={employeeId} employee={employee} />
      ),
      // Added these just to avoid compilation issues
      employeeOrderedMarketplaceItem: "",
      melpChangedMarketplaceOrderStatus: "",
      deletedEmployeeWithOpenMarketplaceOrder: "",
      expenseCreatedFromMarketplaceItem: "",
      flexAssignedToUser: "",
      benefitAssignedToUser: "",
    }),
    [
      doneByName,
      groupName,
      employeeGroup,
      benefit,
      benefitAssignmentStatus,
      employeeId,
      employee,
      employeeEmail,
      allocationStartDate,
      allocatedAmount,
      benefitType,
      admin,
      news,
    ]
  );

  if (!actionsVariant) return null;

  // New approach for rendering log messages. It should be used for every log message from now on.
  if (newApproachKeys.includes(actionsVariant)) {
    const actionName = props.value as LogActions;
    const logData = props.row.original as Log;
    const {
      marketplaceOrderCreationDate,
      marketplaceOrderStatus,
      marketplaceItemStatus,
      benefitAssignmentDate,
      benefitAssignmentStatus,
    } = logData;
    const messagePayload = {
      ...logData,
      marketplaceOrderCreationDate:
        marketplaceOrderCreationDate &&
        moment(marketplaceOrderCreationDate).format("L LT"),
      marketplaceOrderStatus:
        marketplaceOrderStatus &&
        t(`marketplaceOrders.status-${marketplaceOrderStatus}`),
      marketplaceItemStatus:
        marketplaceItemStatus && t(`status.${marketplaceItemStatus}`),
      marketplaceItemPrice: currencyFormatter.formatFractionalUnitAmount(
        logData.marketplaceItemPrice,
        logData.marketplaceItemCurrency,
        true
      ),
      benefitAssignmentDate:
        benefitAssignmentDate && moment(benefitAssignmentDate).format("L"),
      benefitAssignmentStatus:
        benefitAssignmentStatus && t(`status.${benefitAssignmentStatus}`),
    };

    // Mapping for message keys other than action name
    const benefitAssignmentMessageKey = logData.employeeGroup
      ? "benefitAssignedToUserPerGroup"
      : "benefitAssignedToUser";
    const logMessageKeys: Partial<Record<LogActions, string>> = {
      flexAssignedToUser: benefitAssignmentMessageKey,
      benefitAssignedToUser: benefitAssignmentMessageKey,
    };

    const messageKey = logMessageKeys[actionName] ?? actionName;
    const translationKey = `log.${messageKey}`;
    if (!i18n.exists(translationKey)) {
      return null;
    }
    const messageTemplate = t(translationKey);
    return toMessageElement(messageTemplate, messagePayload);
  }

  return (
    <span className={classes.messageContainer}>
      {actions[actionsVariant as LogActions]}
    </span>
  );
}
