import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      margin: 0,
      width: "100%",
      "& > .MuiGrid-item": {
        padding: 15,
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
  })
);
