import React, { useState } from "react";
import { Paper, Divider, Link, Tooltip, makeStyles } from "@material-ui/core";
import DialogExtended from "../../common/DialogExtended/DialogExtended";
import RichText from "../../common/RichText/RichText";
import { useCountryCodes } from "../../../constants/Countries";
import { useBenefitTypes } from "../../../constants/Benefits";
import { BenefitCategory } from "../../../types/Benefits";
import { useTranslation } from "react-i18next";
import { useMarketplaceItemCategories } from "../../../constants/MarketplaceItems";
import clsx from "clsx";
import { MarketplaceItem } from "../../../types/MarketplaceItems";
import { Location, Categories, Benefits, Tag, Close } from "@melp-design/icons";
import { Typography, Button, Label } from "@melp-design/components";
import { Colors } from "@melp-design/style";
import { currencyFormatter } from "../../../utils/Currency";
import { isDefined } from "../../../utils/isDefined";
import { ArrayElement } from "../../../types/Common";
import { useItemTranslation } from "../../../utils/MarketplaceItems";
import { ReactComponent as ItemImageClipPath } from "./item-image-clip-path.svg";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 620,
    width: 620,
    borderRadius: 20,
  },
  dialogContent: {
    padding: 0,
    position: "relative",
    overflowY: "auto",
  },
  topContainer: {
    backgroundColor: theme.palette.common.white,
  },
  topImageContainer: {
    height: 300,
  },
  topImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    // Shape exported from designs
    clipPath: "url(#svgClip)",
  },
  topImagePlaceholder: {
    opacity: 0.3,
  },
  closeDialogButtonContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
    height: "calc(100% - 40px)", // to enable sticky button
    margin: 20,
  },
  closeDialogButton: {
    position: "sticky",
    top: 20,
    right: 20,
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: 10,
    minWidth: 0, // override default button minimal width to reduce size to 30
    width: 30,
    height: 30,
    boxShadow: theme.shadows[6],
    "& svg": {
      height: 12,
      width: "auto",
    },
  },
  supplierLogoContainer: {
    width: 80,
    height: 80,
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -60%)",
    border: "1px solid #E0E5EC",
    borderRadius: 10,
    background: theme.palette.common.white,
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    padding: 2,
  },
  supplierLogo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  content: {
    padding: theme.spacing(7, 11, 4),
  },
  title: {
    fontSize: 30,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: "center",
    marginBottom: 20,
  },
  infoContainer: {
    padding: 20,
    marginBottom: 20,
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    rowGap: 25,
    columnGap: 20,
    borderRadius: 20,
  },
  infoItemContainer: {},
  infoItemTitleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  infoItemTitle: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  infoItemIcon: {
    color: Colors.grey,
    lineHeight: 1,
    height: 14,
    marginRight: 5,
    "& > svg": {
      height: "inherit",
      width: "auto",
    },
  },
  infoItemContent: {
    fontSize: 14,
    lineHeight: 1.5,
    textAlign: "center",
  },
  divider: {
    gridRow: "1 / 3",
    gridColumn: "2 / 3",
    placeSelf: "center",
    height: "80%",
  },
  descriptionContainer: {
    padding: 20,
    borderRadius: 20,
  },
  actions: {
    padding: theme.spacing(3, 11),
    borderTop: "1px solid #E0E5EC",
    display: "flex",
    gap: 20,
    justifyContent: "space-between",
    "& > *": {
      width: "50%",
    },
  },
  prices: {
    margin: 20,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
  pricesLabel: {
    marginLeft: 10,
    "&::after": {
      content: '":"',
    },
  },
  priceLabelWithDescription: {
    width: "100%",
    background: Colors.white,
    border: `1px solid ${Colors.elementsGrey}`,
    borderRadius: 8,
    padding: "10px 20px",
    display: "flex",
    gap: 25,
    alignItems: "center",

    "& > *:first-child": {
      flexGrow: 1,
    },
  },
  fullWidthExpander: {
    width: "100%",
    textAlign: "center",
  },
}));

interface Props<T> {
  item: T;
  isSelected?: boolean;
  open: boolean;
  onClose: () => void;
  onSelect: () => void;
  onRemove: () => void;
  removeDisabled?: boolean;
}

const ItemDetailsDialog = <T extends MarketplaceItem>({
  item,
  ...props
}: Props<T>) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { image, supplier } = item;

  const content = useItemTranslation(item);

  let topImage = (
    <img
      className={clsx(classes.topImage, classes.topImagePlaceholder)}
      src={"/no-item-image-placeholder.svg"}
      alt={content.title}
    />
  );
  if (image?.signedUrl) {
    topImage = (
      <img
        className={classes.topImage}
        src={image.signedUrl}
        alt={image.fileName}
      />
    );
  }

  let supplierLogo = null;
  if (supplier.logo?.signedUrl) {
    supplierLogo = (
      <img
        src={supplier.logo.signedUrl}
        alt={`${supplier.name} logo`}
        className={classes.supplierLogo}
      />
    );
  }

  const countries = useCountryCodes();
  const itemCountries = countries
    .filter((country) => item.countries.includes(country.alpha3))
    .map((country) => country.name)
    .join(", ");

  const benefitCategories = useBenefitTypes();
  const itemBenefitCategories = benefitCategories
    .filter((category) =>
      item.benefitPlanTypes.includes(category.value as BenefitCategory)
    )
    .map((category) => t(category.name))
    .join(", ");

  const marketplaceItemsCategories = useMarketplaceItemCategories();
  const itemCategories = marketplaceItemsCategories
    .filter((category) => item.categories.includes(category.key))
    .map((category) => category.label)
    .join(", ");

  const formatPrice = (fractionUnitAmount?: number) => {
    return currencyFormatter.formatFractionalUnitAmount(
      fractionUnitAmount,
      item.currency
    );
  };

  const [allPricesDisplayed, setAllPricesDisplayed] = useState(false);
  const numOfPricesDisplayed = 2;

  const renderPrices = () => {
    const { prices } = item;

    if (!prices?.length) {
      return null;
    }

    const getPriceLabel = ({
      priceType,
      price,
      priceRangeFrom,
      priceRangeTo,
      translations,
    }: ArrayElement<typeof prices>) => {
      switch (priceType) {
        case "fixedPrice":
          return isDefined(price) ? formatPrice(price) : "";
        case "priceRange":
          if (!isDefined(priceRangeFrom) || !isDefined(priceRangeTo)) {
            return "";
          }
          const min = formatPrice(priceRangeFrom);
          const max = formatPrice(priceRangeTo);
          const key = translations?.length
            ? "compactPriceRangeTag"
            : "priceRangeTag";
          return t(`marketplace.${key}`, { min, max });
        default:
          return "";
      }
    };

    const resolvePriceOptionTranslation = ({
      translations,
    }: ArrayElement<typeof prices>) => {
      if (!translations?.length) {
        return { description: "" };
      }
      const findTranslationByLang = (lang: string) =>
        translations.find(({ language }) => language === lang);
      const currentLanguage = i18n.language.toUpperCase();
      const userLangTranslation = findTranslationByLang(currentLanguage);
      if (userLangTranslation) {
        return userLangTranslation;
      }
      const enTranslation = findTranslationByLang("EN");
      return enTranslation ?? translations[0];
    };

    const sortedPrices = [...prices].sort((a, b) => {
      if (a.priceType === "priceRange") {
        return 1;
      }
      if (b.priceType === "priceRange") {
        return -1;
      }
      if (a.price === b.price) {
        return 0;
      }
      if (!isDefined(a.price)) {
        return -1;
      }
      if (!isDefined(b.price)) {
        return 1;
      }
      return a.price - b.price;
    });
    const priceOptionLabels = sortedPrices.map((priceOption) => {
      return {
        price: getPriceLabel(priceOption),
        description: resolvePriceOptionTranslation(priceOption).description,
      };
    });

    const hasDescription = priceOptionLabels.some(
      (priceOption) => priceOption.description.length
    );

    return (
      <>
        {priceOptionLabels
          .filter(
            (_, index) => index < numOfPricesDisplayed || allPricesDisplayed
          )
          .map(({ price, description }) => {
            if (hasDescription) {
              return (
                <div key={price} className={classes.priceLabelWithDescription}>
                  <Typography variant="p1">{description}</Typography>
                  <Typography
                    variant="p1"
                    align="right"
                    style={{ whiteSpace: "pre" }}
                  >
                    {price}
                  </Typography>
                </div>
              );
            }
            return <Label key={price}>{price}</Label>;
          })}
        {priceOptionLabels.length > numOfPricesDisplayed && (
          <Label
            textColor="primary"
            onClick={() =>
              setAllPricesDisplayed((currentValue) => !currentValue)
            }
            className={clsx(hasDescription && classes.fullWidthExpander)}
          >
            {t(
              `marketplace.${
                allPricesDisplayed ? "showLessPrices" : "showMorePrices"
              }`
            )}
          </Label>
        )}
      </>
    );
  };

  return (
    <DialogExtended
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.dialogContent}>
        <div className={classes.closeDialogButtonContainer}>
          <Tooltip title={t("common.close") ?? ""}>
            <Button
              variant="contained"
              className={classes.closeDialogButton}
              onClick={props.onClose}
            >
              <Close />
            </Button>
          </Tooltip>
        </div>
        <div className={classes.topContainer}>
          <div className={classes.topImageContainer}>
            {topImage}
            <ItemImageClipPath />
          </div>
          <div className={classes.supplierLogoContainer}>{supplierLogo}</div>
        </div>
        <div className={classes.content}>
          <Typography variant="h1" className={classes.title}>
            {content.title}
          </Typography>
          <div className={classes.prices}>
            <Typography
              variant="p1"
              color="textSecondary"
              className={classes.pricesLabel}
            >
              {t("marketplace.prices")}
            </Typography>
            {renderPrices()}
          </div>
          <Paper variant="outlined" className={classes.infoContainer}>
            <InfoItem
              icon={<Location />}
              title={t("marketplace.filtersCountry")}
            >
              {itemCountries}
            </InfoItem>
            <InfoItem
              icon={<Benefits />}
              title={t("marketplace.filtersBenefitCategory")}
            >
              {itemBenefitCategories}
            </InfoItem>
            <InfoItem
              icon={<Categories />}
              title={t("marketplace.filtersItemCategory")}
            >
              {itemCategories}
            </InfoItem>
            <InfoItem icon={<Tag />} title={t("marketplace.filtersSupplier")}>
              {supplier.name}
              <br />
              {!!supplier.termsAndConditionsLink && (
                <Link href={supplier.termsAndConditionsLink} target="_blank">
                  {t("marketplace.supplierTerms")}
                </Link>
              )}
            </InfoItem>
            <Divider orientation="vertical" className={classes.divider} />
          </Paper>
          {!!content.description && (
            <Paper variant="outlined" className={classes.descriptionContainer}>
              <RichText value={content.description} />
            </Paper>
          )}
        </div>
        <div className={classes.actions}>
          <Button variant="outlined" size="large" onClick={props.onClose}>
            {t("common.close")}
          </Button>
          {props.isSelected ? (
            <Tooltip
              title={
                props.removeDisabled
                  ? t("marketplace.autoAssignedNotRemovable") ?? ""
                  : ""
              }
            >
              <div>
                <Button
                  variant="contained"
                  size="large"
                  color="danger"
                  onClick={props.onRemove}
                  disabled={props.removeDisabled}
                  fullWidth
                >
                  {t("marketplace.removeItem")}
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={props.onSelect}
            >
              {t("marketplace.selectItem")}
            </Button>
          )}
        </div>
      </div>
    </DialogExtended>
  );
};

const InfoItem = (props: {
  icon: React.ReactNode;
  title: string;
  children?: React.ReactNode;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.infoItemContainer}>
      <span className={classes.infoItemTitleContainer}>
        <span className={classes.infoItemIcon}>{props.icon}</span>
        <Typography
          variant="p2"
          color="textSecondary"
          className={classes.infoItemTitle}
          component="span"
        >
          {props.title}
        </Typography>
      </span>
      <Typography variant="p1" className={classes.infoItemContent}>
        {props.children}
      </Typography>
    </div>
  );
};

export default ItemDetailsDialog;
