import { Typography } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { pull } from "lodash";

export const replaceByElement = (
  text: string,
  find: string,
  replaceBy: string
) => {
  const splitText = text.split(find);
  if (splitText?.length === 2) {
    return (
      <>
        {splitText[0]}
        <Typography variant="subtitle2" key={`find-${Math.random() * 1000}`}>
          {replaceBy}
        </Typography>
        {splitText[1]}
      </>
    );
  }
  return (
    <>
      {text.split(" ")?.map((line) => {
        switch (line) {
          case find:
            return (
              <Typography
                variant="subtitle2"
                key={`find-${Math.random() * 1000}`}
              >{`${replaceBy} `}</Typography>
            );
          default:
            return `${line} `;
        }
      })}
    </>
  );
};

export const useLogUtil = () => {
  const { t } = useTranslation();

  const logActionsTranslationObject = useMemo(
    () => ({
      employeeGroupCreated: t("log_filter.employee_created_group"),
      employeeGroupDeleted: t("log_filter.employee_deleted_group"),
      benefitRemovedFromGroup: t("log_filter.removed_benefit_plan_from_group"),
      benefitAssignedToGroup: t("log_filter.assigned_benefit_to_group"),
      employeeAssignedToGroup: t("log_filter.assigned_employee_to_groups"),
      employeeRemovedFromGroup: t("log_filter.removed_from_group"),
      employeeCreated: t("log_filter.employee_was_created"),
      employeeSuccessfullyLinkedWithAnAppUser: t(
        "log_filter.successfully_logged_into_app"
      ),
      invitationReminder: t("log_filter.invitation_sent_to"),
      employeeStatusChangedToInactive: t(
        "log_filter.employee_status_changed_inactive"
      ),
      employeeFlexLimitAllocationModified: t(
        "log_filter.flex_allocation_has_been_changed"
      ),
      benefitCreated: t("log_filter.new_benefit_was_created"),
      benefitDeactivated: t("log_filter.benefit_was_deactivated"),
      benefitExpired: t("log_filter.benefit_has_expired"),
      userSelectedFlexChoice: t("log_filter.flex_benefit_was_selected"),
      userDeclinedFlexChoice: t("log_filter.flex_benefit_was_declined"),
      flexVoteIsOver: t("log_filter.flex_benefit_voting_expired"),
      benefitAssignedToUser: t("log_filter.benefit_assigned_to"),
      benefitRemovedFromUser: t("log_filter.benefit_detached_from"),
      flexAssignedToUser: t("log_filter.was_added_to_benefit"),
      flexRemovedFromUser: t("log_filter.flex_removed_from_user"),
      adminCreated: t("log_filter.system_administrator_was_created"),
      hrUserLoggedIn: t("log_filter.hr_user_logged_in"),
      newsCreated: t("log_filter.news_item_was_created"),
      failedToSendEmailToEmployeeBecauseEmployeeMissingEmail: t(
        "log_filter.failed_email_employee_missing_email"
      ),
      employeeOrderedMarketplaceItem: t(
        "log_filter.employeeOrderedMarketplaceItem"
      ),
      melpChangedMarketplaceOrderStatus: t(
        "log_filter.melpChangedMarketplaceOrderStatus"
      ),
      deletedEmployeeWithOpenMarketplaceOrder: t(
        "log_filter.deletedEmployeeWithOpenMarketplaceOrder"
      ),
      expenseCreatedFromMarketplaceItem: t(
        "log_filter.expenseCreatedFromMarketplaceItem"
      ),
    }),
    [t]
  );

  const logActionTranslations = useMemo(
    () => Object.values(logActionsTranslationObject),
    [logActionsTranslationObject]
  );

  const logActionEntries = useMemo(
    () => Object.entries(logActionsTranslationObject),
    [logActionsTranslationObject]
  );

  const parseLogsFilterItems = useCallback(
    (filterItems: string[]) => {
      const selectedValues = [...filterItems];
      let feFilterItems: string[] = [];
      logActionEntries?.forEach(([key, val]) => {
        if (selectedValues?.includes(val)) {
          feFilterItems = [...feFilterItems, key];
          pull(selectedValues, val);
        }
      });
      return [...selectedValues, ...feFilterItems];
    },
    [logActionEntries]
  );

  return {
    parseLogsFilterItems,
    logActionTranslations,
  };
};
