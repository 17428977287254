import { useCallback, useState, useMemo } from "react";
import { MpBulkActionCallback } from "@mp-react/table";
import {
  ReminderRequest,
  ReminderResponse,
  ReminderStatusesResponse,
} from "../types/NotificationService";
import { Row } from "react-table";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useNotificationService from "../state/NotificationService";
import { useDialog } from "./Dialog";

export const useChoicesNotifications = (id?: string) => {
  const { t } = useTranslation();
  const { sendChoicesReminder } = useNotificationService();
  const {
    closeDialog,
    open: openDialog,
    openDialog: handleOpenDialog,
  } = useDialog();
  const [successEmployeeId, setSuccessEmployeeId] =
    useState<ReminderStatusesResponse[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const statusesError = useMemo(
    () => [
      "noEmailAndUser",
      "employeeNotFound",
      "missingPermissions",
      "choiceNotFound",
      "choiceAlreadyMade",
      "employeeIsInactive",
      "benefitNotFound",
      "missingBenefitPlanTranslation",
      "flexVotingIsOver",
    ],
    []
  );

  const statusesWarning = useMemo(
    () => ["noUser", "noEmail", "userMissingToken"],
    []
  );

  const handleCloseDialog = useCallback(() => {
    setSuccessEmployeeId([]);
    closeDialog();
  }, [setSuccessEmployeeId, closeDialog]);

  const getEmployeeAndBenefitIds = useCallback(
    (selectedRows: Row<{}>[] | undefined) => {
      const employeeAndBenefitIds: ReminderRequest[] =
        selectedRows?.map((item) => {
          const { original } = item as any;
          return {
            employeeId: id ? id : original.employeeId,
            benefitPlanId: original.benefitPlanId,
          };
        }) ?? [];
      return employeeAndBenefitIds;
    },
    [id]
  );

  const checkReminderStatus = useCallback(
    (reminderStatusesResponse: ReminderStatusesResponse[]) => {
      reminderStatusesResponse?.forEach((item: any) => {
        if (statusesError.includes(item.status)) {
          if (item.status === "choiceNotFound") {
            toast(t(`choices.${item.status}`), { type: "error" });
          } else if (item.status === "benefitNotFound") {
            toast(
              t("choices.benefit_not_found")?.replace(
                "{benefitName}",
                item.benefitName ? item.benefitName : item.benefitId
              ),
              { type: "error" }
            );
          } else if (item.status === "missingBenefitPlanTranslation") {
            toast(
              t("choices.missing_benefit_translation")?.replace(
                "{benefitName}",
                item.benefitName ? item.benefitName : item.benefitId
              ),
              { type: "error" }
            );
          } else if (item.status === "flexVotingIsOver") {
            const name = item.benefitName ? item.benefitName : item.benefitId;
            toast(t(`choices.${item.status}`).replace("{name}", name), {
              type: "error",
            });
          } else {
            toast(
              t(`notification.${item.status}`)?.replace(
                "{name}",
                item.employeeName ? item.employeeName : item.employeeId
              ),
              { type: "error" }
            );
          }
        }

        if (statusesWarning.includes(item.status)) {
          toast(
            t(`choices.${item.status}ReminderSent`)?.replace(
              "{name}",
              item.employeeName ? item.employeeName : item.employeeId
            ),
            { type: "warning" }
          );
        }

        if (item.status === "succeeded") {
          toast(`${t("common.reminder_sent_successfully")}`, {
            type: "success",
          });
        }
      });
    },
    [statusesError, statusesWarning, t]
  );

  const sendReminders: MpBulkActionCallback = useCallback(
    async (selected) => {
      const { selectedRows } = selected;
      const employeeAndBenefitIds: ReminderRequest[] =
        getEmployeeAndBenefitIds(selectedRows);

      const responseData = await sendChoicesReminder(
        employeeAndBenefitIds as ReminderRequest[],
        false
      );

      const reminderStatusesResponse = (
        responseData as unknown as ReminderResponse
      )?.reminderStatuses;

      checkReminderStatus(reminderStatusesResponse);

      const reminderSentEmployees = reminderStatusesResponse?.filter(
        (item: ReminderStatusesResponse) => {
          return item.status === "reminderAlreadySentInLast24Hours";
        }
      );

      if (reminderSentEmployees?.length > 0) {
        handleOpenDialog();
        setSuccessEmployeeId(reminderSentEmployees);
      }
    },
    [
      sendChoicesReminder,
      handleOpenDialog,
      checkReminderStatus,
      getEmployeeAndBenefitIds,
    ]
  );

  const sendReminderAgain = useCallback(
    async (employeeAndBenefitIds: ReminderRequest[]) => {
      setLoading(true);
      if (employeeAndBenefitIds.length > 0) {
        const responseData = await sendChoicesReminder(
          employeeAndBenefitIds as ReminderRequest[],
          true
        );
        const reminderStatusesResponse = (
          responseData as unknown as ReminderResponse
        )?.reminderStatuses;
        checkReminderStatus(reminderStatusesResponse);
        setLoading(false);
        return true;
      }
      setLoading(false);
      return false;
    },
    [checkReminderStatus, sendChoicesReminder]
  );

  const getIdsForRequest = useCallback(
    (successIds?: ReminderStatusesResponse[]) => {
      return (
        successIds?.map((item) => {
          return {
            employeeId: item?.employeeId,
            benefitPlanId: item?.benefitId,
          };
        }) ?? []
      );
    },
    []
  );

  return {
    sendReminders,
    successEmployeeId,
    handleCloseDialog,
    openDialog,
    handleOpenDialog,
    checkReminderStatus,
    loadingNotifications: loading,
    setLoading,
    getIdsForRequest,
    sendReminderAgain,
  };
};
