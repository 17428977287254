import { Box } from "@material-ui/core";
import React from "react";
import CustomizeColumnButton from "../../CustomizeColumnButton/CustomizeColumnButton";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";
// import TableSearch from "../../TableSearch/TableSearch";

export default function BenefitsAssignEmployeeHeader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <CustomizeColumnButton />
      {/*
      <Box marginLeft="8px">
        <TableSearch />
      </Box> */}
      <TableDatepicker ml="10px" />
    </Box>
  );
}
