import React, { useMemo } from "react";
import { Button, Box, CircularProgress } from "@material-ui/core";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import ActionsPopper from "../ActionsPopper/ActionsPopper";
import { useTranslation } from "react-i18next";
import useStatus from "../../../utils/Status";
import useStyles from "./StatusButton.styles";
import { Status } from "../../../types/Common";
import {
  StatusAction,
  StatusActionKey,
  StatusType,
} from "../../../types/Status";
import clsx from "clsx";
import { useDateFormat } from "../../../utils/Common";

interface IProps {
  status: Status;
  onActionChange: (action: StatusActionKey, date?: string, tz?: number) => void;
  id: StatusType;
  disabled?: boolean;
  loading?: boolean;
  draft?: boolean;
  disablePast?: boolean;
  values?: string;
}

export default function StatusButton(props: IProps) {
  const {
    onActionChange,
    status,
    id,
    disabled,
    loading,
    draft,
    disablePast,
    values,
  } = props;
  const { actions } = useStatus();
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const { dateTime } = useDateFormat(status?.date);

  const parseStatusDate = useMemo(() => {
    if (status.name === "active" || status.name === "inactive") return null;

    return dateTime;
  }, [dateTime, status.name]);

  const handleOpenActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleActionChange = (
    action: StatusActionKey,
    date?: string,
    tz?: number
  ) => {
    handleCloseActions();
    onActionChange(action, date, tz);
  };

  const getStatusClass = (status: StatusAction) => {
    switch (status) {
      case "active":
        return classes.active;
      case "inactive":
        return classes.inactive;
      case "will_be_inactive_on":
        return classes.deactivate;
      case "archived":
        return classes.archived;
      default:
        return classes.inactive;
    }
  };

  const actionId = useMemo(
    () => actions[id][status.name] ?? [],
    [actions, id, status.name]
  );

  const tzString = useMemo(
    () =>
      !!status?.tz
        ? ` (GMT ${status.tz > 0 ? `+${status.tz}` : status.tz})`
        : "",
    [status.tz]
  );

  const buttonLabel = useMemo(() => {
    if (draft) return t("status.draft");
    if (loading) return <CircularProgress size={20} />;
    return `${t(`status.${status.name}`)} ${parseStatusDate ?? ""}${tzString}`;
  }, [draft, loading, parseStatusDate, status.name, t, tzString]);

  return (
    <Box>
      <Box minWidth={107} display="flex" justifyContent="flex-end">
        <Button
          endIcon={disabled ? null : <ChevronDown />}
          variant="contained"
          disableElevation
          className={clsx({
            [classes.button]: true,
            [getStatusClass(status.name)]: !!status.name,
          })}
          onClick={handleOpenActions}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      </Box>
      <ActionsPopper
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseActions}
        placement="bottom-end"
        actions={actionId}
        onActionClick={handleActionChange}
        disablePast={disablePast}
        values={values}
        style={{
          marginTop: 10,
        }}
      />
    </Box>
  );
}
