import { useMemo } from "react";
import EmployeeInformation from "../containers/Employees/EmployeesInner/views/EmployeeInformation/EmployeeInformation";
import Choices from "../containers/Employees/EmployeesInner/views/EmployeeChoices/EmployeeChoices";
import FlexBenefitAllocations from "../containers/Employees/EmployeesInner/views/FlexBenefitAllocations/FlexBenefitAllocations";
import AssignBenefits from "../containers/Employees/EmployeesInner/views/AssignBenefits/AssignBenefits";
import AssignGroups from "../containers/Employees/EmployeesInner/views/AssignGroups/AssignGroups";
import FlexBenefitBalance from "../containers/Employees/EmployeesInner/views/FlexBenefitBalance/FlexBenefitBalance";
import Log from "../containers/Employees/EmployeesInner/views/Log/Log";
import TermsAcceptance from "../containers/Employees/EmployeesInner/views/TermsAcceptance/TermsAcceptance";
import { Tab } from "../types/Common";
import { StatusActionKey } from "../types/Status";
import {
  GlobalPermissionModules,
  LocalPermissionModules,
} from "./Administrators";
import moment from "moment";
import BenefitUsage from "../containers/Employees/EmployeesInner/views/BenefitUsage/BenefitUsage";
import { useFeatureToggle } from "../utils/FeatureToggle/index";
import { FeatureKey } from "../utils/FeatureToggle/Types";

export const useEmployeeTabs = () => {
  const ft = useFeatureToggle(FeatureKey.benefitUsage);
  const isActive = ft.isFeatureEnabled();
  return useMemo(
    (): Tab[] =>
      [
        {
          key: "innerEmployeeEmployeeInformation",
          component: EmployeeInformation,
          module: LocalPermissionModules.Employees,
        },
        {
          key: "innerEmployeeAssignBenefits",
          translationKey: "assignBenefits",
          component: AssignBenefits,
          module: GlobalPermissionModules.Benefits,
        },
        {
          key: "innerEmployeeAssignGroups",
          translationKey: "assignGroups",
          component: AssignGroups,
          module: GlobalPermissionModules.EmployeeGroups,
        },
        {
          key: "innerEmployeeChoices",
          translationKey: "choices",
          component: Choices,
          module: GlobalPermissionModules.FlexBenefit,
        },
        {
          key: "innerEmployeeFlexAllocation",
          translationKey: "flexBenefitAllocations",
          component: FlexBenefitAllocations,
          module: GlobalPermissionModules.FlexBenefit,
        },
        {
          key: "innerEmployeeFlexBalance",
          translationKey: "flexBenefitBalance",
          component: FlexBenefitBalance,
          module: GlobalPermissionModules.FlexBenefit,
        },
        {
          key: "employeeBenefitUsage",
          translationKey: "employeeBenefitUsage",
          component: BenefitUsage,
          module: GlobalPermissionModules.Benefits,
        },
        {
          key: "innerEmployeeLog",
          translationKey: "log",
          component: Log,
          module: LocalPermissionModules.History,
        },
        {
          key: "innerEmployeeTermsAcceptance",
          translationKey: "termsAcceptance",
          component: TermsAcceptance,
          module: GlobalPermissionModules.Benefits,
        },
      ].filter(({ key }) => key !== "employeeBenefitUsage" || isActive),
    [isActive]
  );
};

export const useEmployeeSidebarActions = (): StatusActionKey[] =>
  useMemo(() => ["invite_again", "change_org_unit", "delete"], []);

export const useStatusArray = (): string[] =>
  useMemo(() => ["on", "flex", "off"], []);

export enum EmployeeFilterNames {
  FULL_NAME = "fullName",
  EMPLOYEE_GROUP = "employeeGroup",
  JOB_TITLE = "jobTitle",
  CUSTOM_COLUMN = "customColumn",
}

export enum BenefitPlanAssignmentFilterNames {
  TYPE = "type",
  NAME = "name",
}

export const useDefaultEndTime = () =>
  useMemo(() => moment().set({ hour: 17, minute: 0, seconds: 0 }).format(), []);

export const useDefaultStartTime = () =>
  useMemo(() => moment().set({ hour: 9, minute: 0, seconds: 0 }).format(), []);
