import {
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Box,
  Grow,
  Popover,
} from "@material-ui/core";
import { Close, Menu } from "@material-ui/icons";
import React, {
  useRef,
  useState,
  MouseEvent,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import useStyles from "./BulkActions.styles";
import {
  handleBulkAction,
  MpTableBulkActionProps,
  MpBulkActionClickParams,
  MpBulkActionParameters,
} from "@mp-react/table";
import { ActionDialog } from "../../dialogs/ActionDialog/ActionDialog";
import { useTableDialogActions } from "../../../constants/Table";
import useStatus from "../../../utils/Status";
import DateTimePicker from "../../common/DateTimePicker/DateTimePicker";
import useEmployeesBulkActions from "../../../state/EmployeesBulkActions";
import { useBenefits } from "../../../state/Benefits";
import clsx from "clsx";
import useTableUtils from "../../../utils/Table";
import { HeaderVariant, useTableStore } from "../../../state/Table";

export default function BulkActions({
  selectedRowIds,
  selectedRows,
  actions,
  methods,
  translations,
}: MpTableBulkActionProps) {
  const classes = useStyles();
  const hasSelectedRows = useMemo(
    () => selectedRows.length > 0,
    [selectedRows]
  );
  const dialogSlugs = useTableDialogActions();
  const { actionsWithDatepicker } = useStatus();
  const [open, setOpen] = useState<boolean>(false);
  const [dialogs, setDialogs] = useState<string[]>([]);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [dateTimeAnchor, setDateTimeAnchor] = useState<HTMLLIElement | null>(
    null
  );
  const headerVariant =
    useTableStore((state) => state.variant as HeaderVariant) ?? "";
  const { tableQueryParam } = useTableUtils(headerVariant);
  const benefitsQuery = useMemo(
    () => (headerVariant === "benefits" ? tableQueryParam : ""),
    [headerVariant, tableQueryParam]
  );
  const employeesQuery = useMemo(
    () => (headerVariant === "employees" ? tableQueryParam : ""),
    [headerVariant, tableQueryParam]
  );
  const { deactivateOnSpecificDate, activateOnSpecificDate } =
    useEmployeesBulkActions(
      undefined,
      employeesQuery,
      headerVariant !== "employees"
    );
  const {
    publish: publishBenefit,
    deactivate: deactivateBenefit,
    changeBenefitType,
  } = useBenefits(undefined, benefitsQuery, headerVariant !== "benefits");
  const [selectedAction, setSelectedAction] = useState<string>("");

  useEffect(() => {
    if (!hasSelectedRows) {
      setOpen(false);
    }
  }, [hasSelectedRows]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleClick = useCallback(
    (slug: string) => {
      const hasDialog = dialogSlugs.includes(slug);
      if (hasDialog) {
        setDialogs((prevState) => [...prevState, slug]);
      } else {
        const bulkActionParams: MpBulkActionClickParams = {
          selectedRowIds,
          selectedRows,
          slug,
          methods,
        };
        handleBulkAction(bulkActionParams);
      }
    },
    [dialogSlugs, selectedRowIds, selectedRows, methods]
  );

  const handleCloseDialog = useCallback(
    (slug: string): void =>
      setDialogs((prevState) => [
        ...prevState.filter((state) => state !== slug),
      ]),
    []
  );

  const handleDialogAction = useCallback(
    (value: any, slug: string) => {
      if (slug === "dateTime") {
        const selectedRowIds = selectedRows?.map((item) => {
          const { original } = item as any;
          return original.id;
        });
        if (selectedAction === "deactivate_specific_date_employee") {
          deactivateOnSpecificDate(value, selectedRowIds);
        } else if (selectedAction === "activate_specific_date_employee") {
          activateOnSpecificDate(value, selectedRowIds);
        } else if (selectedAction === "deactivate_specific_date") {
          deactivateBenefit(selectedRows as MpBulkActionParameters, value);
        } else if (selectedAction === "activate_specific_date") {
          publishBenefit(selectedRows as MpBulkActionParameters, value);
        }
        setDateTimeAnchor(null);
      } else if (slug === "changeBenefitType") {
        changeBenefitType(selectedRows, value);
        handleCloseDialog(slug);
      } else {
        handleCloseDialog(slug);
      }
    },
    [
      selectedRows,
      selectedAction,
      deactivateOnSpecificDate,
      activateOnSpecificDate,
      deactivateBenefit,
      publishBenefit,
      changeBenefitType,
      handleCloseDialog,
    ]
  );

  const actionsMap = actions.map((action, i) => (
    <MenuItem
      classes={{ root: classes.menuItem }}
      onClick={(e) => {
        if (actionsWithDatepicker.includes(action.slug)) {
          setSelectedAction(action.slug);
          setDateTimeAnchor(e.currentTarget);
        } else {
          handleClose(e);
          handleClick(action.slug);
        }
      }}
      key={action.slug}
      id={action.slug}
    >
      <Box>{action.label}</Box>
    </MenuItem>
  ));

  return (
    <Box mr="20px">
      <Button
        ref={anchorRef}
        aria-controls={open ? "bulk-actions-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        variant="contained"
        className={clsx({
          [classes.disabled]: !hasSelectedRows,
        })}
        disabled={!hasSelectedRows}
      >
        {open ? (
          <Close className={classes.iconSpacer} />
        ) : (
          <Menu className={classes.iconSpacer} />
        )}
        {translations?.total ?? "Actions"}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.overlay}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "left top" : "left bottom",
            }}
          >
            <Paper classes={{ root: classes.menuPaper }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="bulk-actions-list-grow"
                  onKeyDown={handleListKeyDown}
                  disablePadding
                >
                  {actionsMap}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {dialogSlugs.map((slug) => (
        <ActionDialog
          slug={slug}
          key={slug}
          open={dialogs.includes(slug)}
          onCancelClick={() => handleCloseDialog(slug)}
          onActionClick={(value: string) => handleDialogAction(value, slug)}
        />
      ))}
      <Popover
        open={Boolean(dateTimeAnchor)}
        anchorEl={dateTimeAnchor}
        onClose={() => setDateTimeAnchor(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DateTimePicker
          onSubmit={(date: string) => handleDialogAction(date, "dateTime")}
        />
      </Popover>
    </Box>
  );
}
