import React, { useCallback, useEffect, useMemo, useState } from "react";
import AddNewButton from "../../../common/AddNewButton/AddNewButton";
import FormDialog from "../../../../components/dialogs/FormDialog/FormDialog";
import { useTranslation } from "react-i18next";
import { UnpackNestedValue } from "react-hook-form";
import { useEmployeeForm } from "../../../../configs/Forms/AddEmployeeForm";
import { useEmployees } from "../../../../state/Employees";
import {
  CreateEmployeeRequest,
  EmployeeResponse,
} from "../../../../types/Employees";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import CustomizeColumnButton from "../../CustomizeColumnButton/CustomizeColumnButton";
import { useHistory } from "react-router";
import UserAlreadyExistsDialog from "../../../employees/UserAlreadyExistsDialog/UserAlreadyExistsDialog";
import { Box, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { useMe } from "../../../../state/Administrators";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import useTableUtils from "../../../../utils/Table";
import useStyles from "../HeaderVariantMap.styles";
import moment from "moment";

export default function EmployeesHeader() {
  const { canEditEmployees } = useMe();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openUserExists, setOpenUserExists] = useState<boolean>(false);
  const employeeForm = useEmployeeForm();
  const { tableQueryParam } = useTableUtils("employees");
  const { createEmployee, userExist, loading, exportToExcel, employeeList } =
    useEmployees(undefined, tableQueryParam);

  const tableDataCount = useMemo(
    () => employeeList?.count,
    [employeeList?.count]
  );
  const now = useMemo(() => moment().format(), []);
  const formDefaultValues = useMemo(() => {
    return { startDate: now, startTime: now };
  }, [now]);

  const handleOpenFormDialog = useCallback(() => setOpenForm(true), []);

  const handleCloseFormDialog = useCallback(() => setOpenForm(false), []);

  const handleOpenUserExistsDialog = useCallback(
    () => setOpenUserExists(true),
    []
  );

  const handleCloseUserExistsDialog = useCallback(
    () => setOpenUserExists(false),
    []
  );

  useEffect(() => {
    if (userExist) {
      handleOpenUserExistsDialog();
    }
  }, [userExist, handleOpenUserExistsDialog]);

  const handleResponse = useCallback(
    (id: string | undefined) => {
      if (!!id) {
        history.push(
          `/employees/view/${id}?tab=innerEmployeeEmployeeInformation`
        );
      } else {
        return null;
      }
    },
    [history]
  );

  const handleAdd = useCallback(
    async (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      const date = (data as any).startDate;
      const time = (data as any).startTime;
      const parsedDateTime = `${moment(date).format().split("T")[0]}T${
        moment(time).format().split("T")[1]
      }`;
      (data as CreateEmployeeRequest).startDate = parsedDateTime;

      const responseData = (await createEmployee(
        data as CreateEmployeeRequest
      )) as unknown;
      const id = (responseData as EmployeeResponse)?.id;
      if (!!id) {
        handleCloseFormDialog();
        handleResponse(id);
      }
    },
    [handleCloseFormDialog, createEmployee, handleResponse]
  );

  const handleDownload = useCallback(() => {
    exportToExcel();
  }, [exportToExcel]);

  const DownloadButton = useMemo(() => {
    if (loading)
      return (
        <Box px="10px" display="flex">
          <CircularProgress size={19} />
        </Box>
      );
    return (
      <Tooltip title={t("dashboard.download_xls") || "XLS"}>
        <IconButton
          onClick={handleDownload}
          className={classes.iconButton}
          disabled={!tableDataCount}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    );
  }, [classes.iconButton, handleDownload, loading, tableDataCount, t]);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      <CustomizeColumnButton />
      {DownloadButton}
      <TableDatepicker marginRight="20px" marginLeft="10px" />
      <AddNewButton disabled={!canEditEmployees} onClick={handleOpenFormDialog}>
        {t("employees.new_employee")}
      </AddNewButton>
      <FormDialog
        onCancelClick={handleCloseFormDialog}
        onSubmitForm={handleAdd}
        id="create-add-employee-dialog"
        open={openForm}
        title={t("employees.add_employee")}
        actionLabel={t("common.confirm")}
        form={employeeForm}
        loading={loading}
        defaultValues={formDefaultValues}
      />
      <UserAlreadyExistsDialog
        open={openUserExists}
        onCancelClick={handleCloseUserExistsDialog}
        id="user-already-exists"
      />
    </Box>
  );
}
