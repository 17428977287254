import { useInvestmentCalculationsStore } from "./InvestmentCalculations";
import { mutateData } from "../api/api";
import { useLoading } from "../utils/Loading";
import { useCallback, useState, useMemo } from "react";
import {
  UseGroupsInnerAssignEmployees,
  GroupsAssignEmployeeRequest,
} from "../types/Groups";
import { useTranslation } from "react-i18next";
import {
  MpAsyncGetMethod,
  MpBulkActionCallback,
  MpBulkActionMethods,
} from "@mp-react/table";
import useTableUtils from "../utils/Table";
import { CustomColumn, ParsedEmployeeListItem } from "../types/Employees";
import { Endpoints } from "../api/constants";
import useSWR, { mutate } from "swr";
import { toast } from "react-toastify";
import { useCurrency } from "../utils/useCurrency";
import {
  EmployeeGroupsAssignEmployeeDataItem,
  EmployeeGroupsAssignEmployeeResponse,
} from "../types/EmployeeGroups";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { AxiosResponse } from "axios";
import { GroupsAssignEmployeesFilterNames } from "../constants/Groups";
import { useTableStore } from "./Table";
import { useTableSearch } from "../utils/TableSearch";

export const useInnerGroupAssignEmployee = (
  employeeGroupId?: string,
  query?: string
): UseGroupsInnerAssignEmployees => {
  const [tooltipText, setTooltipText] = useState<any[]>([]);
  const { t } = useTranslation();
  const { getRowInvestmentSwitch } = useTableUtils();
  const { loading, startLoading, stopLoading } = useLoading();
  const { resetStatusChanges, resetAllChanges, increaseChangeCount } =
    useInvestmentCalculationsStore();
  const { getDefaultCurrencyFormat } = useCurrency();
  const {
    filterGroupsAssignEmployeeTable: filterBySearch,
    filterGroupsAssignEmployeeTableFooter: filterFooterBySearch,
  } = useTableSearch();
  const searchQuery = useTableStore((state) => state.searchQuery);

  const url = useMemo(() => {
    if (employeeGroupId) {
      return !!query
        ? `${Endpoints.employeeGroup}/${employeeGroupId}/employeeAssignments?${query}`
        : `${Endpoints.employeeGroup}/${employeeGroupId}/employeeAssignments`;
    }
    return null;
  }, [employeeGroupId, query]);
  const {
    data: assignEmployeesData,
    error: assignEmployeesError,
    mutate: mutateAssignEmployees,
  } = useSWR<EmployeeGroupsAssignEmployeeResponse, any>(url);

  const [switchValues, setSwitchValues] = useState<Record<string, boolean>>({});

  const apiLoading = useMemo(
    () => !assignEmployeesData && !assignEmployeesError,
    [assignEmployeesData, assignEmployeesError]
  );

  const setStatus = useInvestmentCalculationsStore(
    useCallback((state) => state.setStatus, [])
  );

  const removeChange = useInvestmentCalculationsStore(
    useCallback((state) => state.removeChange, [])
  );

  const computedAssignEmployeesData: EmployeeGroupsAssignEmployeeDataItem[] =
    useMemo(() => {
      const data =
        assignEmployeesData?.data?.map((item) => ({
          ...item,
          inactive: !Number(item.included),
          included: !!Number(item.included),
          customColumn: (item?.customColumn as CustomColumn)?.name,
        })) ?? [];
      return filterBySearch(data);
    }, [assignEmployeesData?.data, filterBySearch]);

  const filteredFooterBySearch = useMemo(
    () => filterFooterBySearch(computedAssignEmployeesData),
    [computedAssignEmployeesData, filterFooterBySearch]
  );

  const parsedGroupsAssignEmployeesTotals = useMemo(() => {
    const initialTotals = {
      fullName: "",
      investment: "",
      employeeGroup: "",
      included: "",
      jobTitle: "",
    };
    if (!assignEmployeesData?.footer) return initialTotals;

    const footerData =
      !!searchQuery && !!filteredFooterBySearch
        ? filteredFooterBySearch
        : assignEmployeesData.footer;

    const {
      fullName,
      excluded,
      employeeGroup,
      investment,
      jobTitle,
      customColumn,
    } = footerData;

    return {
      fullName: t("totals.employee", { count: Number(fullName) }),
      employeeGroup: t("totals.employee_group", {
        count: Number(employeeGroup),
      }),
      jobTitle: t("totals.job_title", { count: Number(jobTitle) }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
      included: `${excluded} ${t("assign_employees.excluded")}`,
      customColumn: t("totals.custom_category", {
        count: Number(customColumn ?? 0),
      }),
    };
  }, [
    assignEmployeesData?.footer,
    t,
    getDefaultCurrencyFormat,
    searchQuery,
    filteredFooterBySearch,
  ]);

  const defaultIncludedStatus = useMemo(
    () =>
      computedAssignEmployeesData
        .filter((item: any) => item.included === true)
        .map((item: any) => item.id),
    [computedAssignEmployeesData]
  );

  const addSwitchValue = useCallback(
    (rowId: string, value: boolean) =>
      setSwitchValues((prevState) => ({ ...prevState, [rowId]: value })),
    []
  );

  const clearSwitchValues = useCallback(() => setSwitchValues({}), []);
  const mapAssignEmployeesRequest =
    useCallback((): GroupsAssignEmployeeRequest => {
      const switchEntries = Object.entries(switchValues);
      const assignToGroup = switchEntries
        .filter(([, value]) => value === true)
        .map(([key]) => key);
      const removeFromGroup = switchEntries
        .filter(([, value]) => value === false)
        .map(([key]) => key);

      return {
        assignToGroup,
        removeFromGroup,
      };
    }, [switchValues]);

  const updateAssignEmployees = useCallback(() => {
    if (!employeeGroupId) return;

    startLoading();
    const requestData = mapAssignEmployeesRequest();
    mutateData(
      "patch",
      `${Endpoints.employeeGroup}/${employeeGroupId}/assignEmployee`,
      requestData
    )
      .then(() => {
        toast(t("common.assigned_succesfully"), { type: "success" });
        mutateAssignEmployees();
        mutate(Endpoints.employeeGroup);
      })
      .then(() => {
        resetStatusChanges();
        resetAllChanges();
        clearSwitchValues();
      })
      .finally(() => stopLoading());
  }, [
    employeeGroupId,
    mapAssignEmployeesRequest,
    resetAllChanges,
    resetStatusChanges,
    startLoading,
    stopLoading,
    clearSwitchValues,
    t,
    mutateAssignEmployees,
  ]);

  const removeAllEmployees = useCallback(
    (employeeGroupId: string, query?: string) => {
      startLoading();
      mutateData(
        "patch",
        `${Endpoints.employeeGroup}/${employeeGroupId}/removeAllEmployees`
      )
        .then(() => {
          toast(t("common.removed_successfuly"), { type: "success" });
          mutate(
            `${Endpoints.employeeGroup}/${employeeGroupId}/employeeAssignments${
              query ? `?${query}` : ""
            }`
          );
          mutate(Endpoints.employeeGroup);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t]
  );

  const currentInvestment = useCallback(
    (
      value: string | number | undefined | null,
      item?: ParsedEmployeeListItem,
      rowId?: string | number | undefined
    ) => {
      const selectedRowData = computedAssignEmployeesData.find(
        (item) => item.id === rowId
      );

      const investmentAmount = Number(
        getRowInvestmentSwitch(
          value,
          selectedRowData?.included as boolean,
          selectedRowData?.investment
        )
      );

      const tooltipItem = {
        label: `${t("entity_type.employee")} ${item?.fullName} was added`,
        cost: investmentAmount,
      };
      const isIncluded = tooltipText.find(
        (tooltip) => tooltip.label === tooltipItem.label
      );
      if (value === null) {
        setTooltipText([]);
      } else {
        if (!isIncluded) {
          setTooltipText((prevState) => [...prevState, tooltipItem]);
        } else {
          setTooltipText((prevState) => [
            ...prevState.filter((state) => state.label !== tooltipItem.label),
          ]);
        }
      }
    },
    [tooltipText, t, computedAssignEmployeesData, getRowInvestmentSwitch]
  );

  const newInvestment = useMemo(
    () =>
      tooltipText.reduce((prev, cur) => (cur.cost ? prev + cur.cost : prev), 0),
    [tooltipText]
  );

  const defaultInvestment = useMemo(
    () =>
      computedAssignEmployeesData.reduce(
        (prev, cur) => (cur.investment ? prev + cur.investment : prev),
        0
      ),
    [computedAssignEmployeesData]
  );

  const statusIncluded: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      return selectedRows?.forEach((item) => {
        const { original } = item as any;
        const hasValue = defaultIncludedStatus.includes(original.id as string);
        if (!hasValue) {
          increaseChangeCount(original.id);
        } else {
          removeChange(original.id);
        }
        currentInvestment(original.inactive, original, original.id);
        addSwitchValue(original.id, true);
        setStatus({ included: { [original.id]: { value: true } } });
      });
    },
    [
      addSwitchValue,
      increaseChangeCount,
      currentInvestment,
      setStatus,
      removeChange,
      defaultIncludedStatus,
    ]
  );

  const statusExcluded: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      return selectedRows?.forEach((item) => {
        const { original } = item as any;
        const hasValue = defaultIncludedStatus.includes(original.id as string);
        if (hasValue) {
          increaseChangeCount(original.id);
        } else {
          removeChange(original.id);
        }
        currentInvestment(original.inactive, original, original.id);
        addSwitchValue(original.id, false);
        setStatus({ included: { [original.id]: { value: false } } });
      });
    },
    [
      addSwitchValue,
      increaseChangeCount,
      currentInvestment,
      setStatus,
      removeChange,
      defaultIncludedStatus,
    ]
  );

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      statusIncluded,
      statusExcluded,
    }),
    [statusIncluded, statusExcluded]
  );

  return {
    updateAssignEmployees,
    currentInvestment,
    newInvestment,
    tooltipText,
    defaultInvestment,
    computedAssignEmployeesData,
    bulkMethods,
    loading: loading || apiLoading,
    addSwitchValue,
    clearSwitchValues,
    parsedGroupsAssignEmployeesTotals,
    removeAllEmployees,
  };
};

export const useGroupsAssignEmployeesAsyncMethods = (
  query?: string,
  employeeGroupId?: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () =>
      !!employeeGroupId
        ? `${Endpoints.employeeGroup}/${employeeGroupId}/employeeAssignments/filterValues`
        : "",
    [employeeGroupId]
  );

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: GroupsAssignEmployeesFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const queryParams = `${
        !!query && !!searchParam ? `&${query}` : !!query ? `?${query}` : ""
      }`;
      const apiUrl = `${baseUrl}/${filterName}${searchParam}${queryParams}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, query]
  );

  const getFilterItems = useCallback(
    (filterName: GroupsAssignEmployeesFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}${!!query ? `?${query}` : ``}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, query]
  );

  const getFullNames = useCallback<MpAsyncGetMethod>(
    (args) =>
      getAsyncFilterItems(args, GroupsAssignEmployeesFilterNames.FULL_NAME),
    [getAsyncFilterItems]
  );

  const getJobTitles = useCallback<MpAsyncGetMethod>(
    (args) => getFilterItems(GroupsAssignEmployeesFilterNames.JOB_TITLE),
    [getFilterItems]
  );

  const getCustomColumnCategories = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(GroupsAssignEmployeesFilterNames.CUSTOM_COLUMN),
    [getFilterItems]
  );

  return {
    getFullNames,
    getJobTitles,
    getCustomColumnCategories,
  };
};
