import { StatusAction } from "./Status";
import { CheckAssignBaseResponse, Status } from "./Common";
import {
  MpBulkActionMethods,
  MpBulkActionParameters,
  MpRowActionMethods,
} from "@mp-react/table";
import { Row } from "react-table";
import {
  GlobalPermissionModules,
  LocalPermissionModules,
} from "../constants/Administrators";
import { CustomColumn } from "./Employees";

export enum BenefitTypes {
  wellness = "wellness",
  insurances = "insurances",
  perks = "perks",
  pensions = "pensions",
  timeOff = "timeOff",
  education = "education",
  generalEmployeeWelfare = "generalEmployeeWellfare",
  companyDiscounts = "companyDiscounts",
  partnerDiscounts = "partnerDiscounts",
}

export interface UseBenefits {
  benefitPlans: BenefitPlansResponse;
  benefitPlanDetails: BenefitDetailsResponse;
  parsedBenefitPlan: TransformedBenefitResponse;
  parsedBenefitStatus: Status;
  benefitPlansError?: any;
  benefitPlanDetailsError?: any;
  createBenefit: (
    data: CreateBenefitRequest,
    skipNavigation?: boolean
  ) => Promise<BenefitDetailsResponse>;
  updateBenefit: (
    data: UpdateBenefitRequest | UpdateBenefitName | UpdateBenefitType,
    cb?: () => void,
    noRedirect?: boolean
  ) => Promise<BenefitDetailsResponse>;
  deleteBenefit: () => void;
  copyBenefit: () => void;
  bulkMethods: MpBulkActionMethods;
  loading: boolean;
  hasFlexSettings: boolean;
  hasAllRequiredInfo: boolean;
  updateBenefitStatus: (status: Status) => void;
  removeAllEmployees: () => void;
  parsedBenefitTotals: BenefitsTotals;
  publish: (selected: MpBulkActionParameters, selectedDate?: string) => void;
  deactivate: (selected: MpBulkActionParameters, selectedDate?: string) => void;
  changeBenefitType: (selectedRows: Array<Row<{}>>, type: string) => void;
  apiLoading: boolean;
  renameBenefit: (data: UpdateBenefitName) => Promise<boolean>;
  exportToExcel: () => void;
  benefitName: string;
  getBenefitPlan: (id: string) => Promise<BenefitDetailsResponse>;
  checkBenefitPlanSettings: (id: string) => Promise<boolean>;
}

export interface BenefitsTotals {
  employeeCount: string;
  investment: string;
  name: string;
  type: string;
  status: string;
}

export interface BenefitPlansResponse {
  data: BenefitPlan[];
  count: number;
  pageSize: number;
}

export interface BenefitPlan {
  id: string;
  type: string;
  name: string;
  benefitPlanId: string;
  deactivationDate: string;
  employeeCount: number;
  investment: number;
  status: string;
}

export interface BenefitDetailsResponse {
  id: string;
  type: string;
  name: string;
  investmentAmount: number;
  investmentPeriod: string;
  value: number;
  employeeCopayment: boolean;
  employeeInvestmentAmount: number;
  creationDate: string;
  publishDate: string;
  publishDateTZ: number;
  unpublishDate: string;
  unpublishDateTZ: number;
  activationDate: string;
  activationDateTZ: number;
  deactivationDate: string;
  deactivationDateTZ: number;
  termsConditionsRequiredFrom: boolean;
  termsConditionsFileId: number;
  termsConditionsFile: FileResponse;
  internalComment: string;
  votingStartDate: string;
  votingStartDateTZ: number;
  votingEndDate: string;
  votingEndDateTZ: number;
  limitedQuantity: number | null;
  translations: TranslationsResponse[];
  parentCompanyId: string;
  categories: CategoriesResponse[];
  status: StatusAction;
  draft: boolean;
  trackBalance: boolean;
}

export interface TransformedBenefitResponse extends BenefitDetailsResponse {
  employerInvestmentAmount: number;
  employerInvestmentPercent: number;
  employeeInvestmentPercent: number;
  publishTime: string;
  unpublishTime: string;
  activationTime: string;
  deactivationTime: string;
  deletable: boolean;
}

export interface FileResponse {
  id: string;
  mime: string;
  fileName: string;
  signedUrl: string;
  parentCompanyId: string;
}

export interface CreateBenefitRequest {
  type: string;
  name: string;
  templateId?: string;
}

export interface UpdateBenefitRequest {
  type: string;
  name: string;
  investmentAmount: number;
  investmentPeriod: string;
  value: number;
  employeeCopayment: boolean;
  employeeInvestmentAmount: number;
  publishDate: string;
  publishDateTZ: number;
  unpublishDate: string;
  unpublishDateTZ: number;
  activationDate: string;
  activationDateTZ: number;
  deactivationDate: string;
  deactivationDateTZ: number;
  termsConditionsRequiredFrom: boolean;
  termsConditionsFileId: number;
  internalComment: string;
  votingStartDate: string | null;
  votingStartDateTZ: number;
  votingEndDate: string | null;
  votingEndDateTZ: number;
  limitedQuantity: number;
  translations: TranslationsRequest[];
  categories: CategoriesRequest[];
  draft?: boolean;
  trackBalance: boolean;
}

export interface UpdateBenefitName {
  name: string;
}

export interface UpdateBenefitType {
  type: string;
}

export interface TranslationsResponse {
  id: string;
  language: string;
  title: string;
  description: string;
  imageId: string;
  image: FileResponse;
  links: Links[];
  creationDate: string;
  benefitPlanId: string;
  faq: FAQ[];
  attachments: AttachmentsResponse[];
  categories: TranslationCategories[];
}

export interface TranslationsRequest {
  id: string;
  language: string;
  title: string;
  description: string;
  imageId: string;
  links: Links[];
  faq: FAQ[];
  attachments: Attachments[];
  categories: TranslationCategories[];
}

export interface Links {
  index: number;
  name: string;
  url: string;
}

export interface FAQ {
  index: number;
  question: string;
  answer: string;
}

export interface Attachments {
  id?: string | null;
  name: string;
  fileId: string;
}

export interface AttachmentsResponse {
  id: string;
  name: string;
  fileId: string;
  file: FileResponse;
  creationDate: string;
  benefitPlanTranslationId: string;
}

export interface CategoriesRequest {
  index?: number;
  id?: string;
  name: string;
  limitAmount: number;
  compensationPercent: number;
}

export interface CategoriesResponse {
  index: number;
  id: string;
  name: string;
  limitAmount: number;
  compensationPercent: number;
  benefitPlanId: string;
}

export interface TranslationCategories {
  index?: number;
  id: string;
  name: string;
}

export interface BenefitTab {
  component: () => JSX.Element;
  key: string;
  translationKey?: string;
  module?: GlobalPermissionModules | LocalPermissionModules;
}

export type UseBenefitStore = {
  status: Status;
  setStatus: (obj: Status) => void;
  formIsResetting: boolean;
  resetForm: () => void;
  publishForm: boolean;
  setPublishForm: (publish: boolean) => void;
  activationDate: string | null;
  setActivationDate: (dateToSet: string | null, type?: "date" | "time") => void;
  deactivationDate: string | null;
  publishDate: string | null;
  votingStartDate: string | null;
  votingEndDate: string | null;
  setDeactivationDate: (
    deactivationDate: string | null,
    type?: "date" | "time"
  ) => void;
  setPublishDate: (publishDate: string | null, type?: "date" | "time") => void;
  setVotingEndDate: (
    votingEndDate: string | null,
    type?: "date" | "time"
  ) => void;
  setVotingStartDate: (
    votingStartDate: string | null,
    type?: "date" | "time"
  ) => void;
};

export interface UseBenefitUtils {
  transformBenefitData: (
    benefitPlanData: BenefitDetailsResponse
  ) => TransformedBenefitResponse;
  getDataForRequest: (formData: any) => UpdateBenefitRequest;
  parseChoicesDateForRequest: (
    formData: Partial<UpdateBenefitRequest>
  ) => Partial<UpdateBenefitRequest>;
  parseStatusDates: (benefitPlan: TransformedBenefitResponse) => Status;
  parseRequestStatus: (status: Status) => {
    publishDate?: string | null;
    publishDateTZ?: number;
    deactivationDate?: string | null;
    deactivationDateTZ?: number;
  };
  loading: boolean;
}

export interface BenefitsAssignEmployeesStatus {
  id: string;
  fromDate: string;
  toDate: string;
  status: "flex" | "on" | "off";
  flexStatus: "notSelected" | "selected" | "declined";
  flexUpdateDate: string;
  flexImplementationDate: string;
  strength: number;
  employeeId: string;
  benefitPlanId: string;
}

export interface BenefitsAssignEmployee {
  id: string;
  fullName: string;
  employeeGroup: string[];
  employeeGroupIds: string[];
  startDate: string;
  investment: number;
  terms: "accepted" | "notAccepted";
  statuses: BenefitsAssignEmployeesStatus[];
  currentStatus: "flex" | "on" | "off";
  customColumn: CustomColumn | string;
}

export interface BenefitsAssignEmployeeTable {
  data: BenefitsAssignEmployee[];
  count: number;
  pageSize: number;
  footer: BenefitsAssignEmployeeTotals;
}

export interface BenefitsAssignEmployeeTotals {
  fullName: string;
  investment: string;
  employeeGroup: string;
  currentStatus: string;
  customColumn: string;
}

export interface BenefitsAssignGroupStatus {
  id: string;
  fromDate: string;
  toDate: string;
  status: "off" | "on" | "flex";
  benefitPlanId: string;
  employeeGroupId: string;
}

export interface BenefitsAssignGroupsTableItem {
  id: string;
  name: string;
  statuses: BenefitsAssignGroupStatus[];
  currentStatus: "off" | "on" | "flex";
  investment: number;
  employeeCount: number;
}

export interface BenefitsAssignGroupsTable {
  data: BenefitsAssignGroupsTableItem[];
  count: number;
  pageSize: number;
  footer: BenefitsAssignGroupsTotals;
}

export interface BenefitsAssignGroupsTotals {
  name: string;
  investment: string;
  currentStatus: string;
}

export interface UseBenefitsEmployeeGroups {
  assignEmployeeGroups: (
    date?: string,
    data?: AssignToEmployeeGroupRequest[]
  ) => void;
  rowMethods: MpRowActionMethods;
  benefitAssignGroups?: BenefitsAssignGroupsTable;
  benefitAssignGroupsData: BenefitsAssignGroupsTableItem[];
  loading: boolean;
  parsedBenefitAssignGroupsTotals: BenefitsAssignGroupsTotals;
  isFlexFormOpen: boolean;
  closeFlexForm: () => void;
  selectedRowData: BenefitsAssignGroupsTableItem | undefined;
  changeResponse?: CheckAssignBaseResponse;
  checkBenefitToGroupChanges: ({
    newStatus,
    employeeGroupId,
    benefitPlanId,
  }: {
    newStatus: "on" | "off" | "flex";
    employeeGroupId: string;
    benefitPlanId: string;
  }) => Promise<CheckAssignBaseResponse>;
}

export interface AssignToEmployeeGroupRequest {
  id?: string;
  benefitPlanId: string;
  employeeGroupId: string;
  fromDate: string;
  status: string;
  toDelete: boolean;
}

export interface BenefitsChoicesListItem {
  id: string;
  parentCompanyId: string;
  employeeId: string;
  employeeName: string;
  benefitPlanId: string;
  benefitPlanType: string;
  benefitPlanName: string;
  investment: number;
  votingEndDate: string;
  flexStatus: string;
  flexUpdateDate: string;
  flexImplementationDate: string | null;
}

export interface BenefitsChoicesList {
  data: BenefitsChoicesListItem[];
  count: number;
  pageSize: number;
  footer: BenefitChoiceTotals;
}

export type UseBenefitsChoices = {
  benefitsChoices?: BenefitsChoicesList;
  loading: boolean;
  benefitsChoicesError: any;
  updateImplementationDate: (data: UpdateImplementationDate[]) => void;
  mappedBenefitsChoicesTableData: BenefitsChoicesList[];
  bulkMethods: MpBulkActionMethods;
  parsedBenefitChoicesTotals: BenefitChoiceTotals;
};

export interface UpdateImplementationDate {
  id: string | number | undefined;
  flexImplementationDate: string | number | null;
}

export interface BenefitChoiceTotals {
  flexImplementationDate: string;
  flexStatus: string;
  investment: string;
  employeeName: string;
}

export interface AssignEmployeesRequest {
  id?: string;
  benefitPlanId: string;
  employeeId: string | null;
  fromDate: string;
  status: "off" | "on" | "flex";
  toDelete: boolean;
}

export interface BenefitBulkActionsRequest {
  id: string;
  benefitPlan: Partial<BenefitDetailsResponse>;
}

export interface ToggleAssignmentStatus {
  value: "on" | "off" | "flex";
  assignmentId?: string | null;
  currentRowStatuses?: BenefitsAssignGroupStatus[];
}
export enum BenefitStatus {
  flex = "flex",
  on = "on",
  off = "off",
}

export enum BenefitCategory {
  INSURANCES = "insurances",
  WELLNESS = "wellness",
  PERKS = "perks",
  PENSIONS = "pensions",
  TIME_OFF = "timeOff",
  EDUCATION = "education",
  GENERAL_EMPLOYEE_WELFARE = "generalEmployeeWelfare",
  COMPANY_DISCOUNTS = "companyDiscounts",
  PARTNERS_DISCOUNTS = "partnerDiscounts",
}
