import React from "react";
import { Colors } from "../../style/Colors";
import clsx from "clsx";
import {
  Button as MuiButton,
  makeStyles,
  ButtonProps,
  CircularProgress,
} from "@material-ui/core";

interface StyleParams {
  textColor?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 20px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5",
    borderRadius: 6,
    "& svg:not(.MuiCircularProgress-svg)": {
      height: 12,
      width: "auto",
    },
    minWidth: 0,
    height: "auto",
    color: Colors.black,
  },
  small: {
    padding: "5px 10px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "1.5",
  },
  large: {
    padding: "15px 30px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5",
    "& svg:not(.MuiCircularProgress-svg)": {
      height: 14,
      width: "auto",
    },
  },
  containedPrimary: {
    color: Colors.white,
    backgroundColor: Colors.primary,
  },
  outlinedPrimary: {
    borderColor: Colors.primary,
    color: Colors.primary,
    "&:hover": {
      borderColor: Colors.blueHover,
      color: Colors.blueHover,
      backgroundColor: Colors.primaryLight,
    },
  },
  iconOnly: {
    padding: 8,
  },
  containedDanger: {
    color: Colors.white,
    backgroundColor: Colors.red,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  outlinedDanger: {
    borderColor: Colors.red,
    color: Colors.red,
  },
  customTextColor: (params: StyleParams) => ({
    color: params.textColor,
  }),
  outlined: {},
  outlinedSizeSmall: {},
  outlinedSizeLarge: {
    padding: "14px 30px",
  },
}));

interface Props extends Omit<ButtonProps, "color"> {
  iconOnly?: boolean;
  color?: ButtonProps["color"] | "danger";
  textColor?: keyof typeof Colors;
  loading?: boolean;
}

const Button = React.forwardRef(
  (
    {
      variant = "outlined",
      iconOnly = false,
      color,
      textColor,
      loading,
      disabled,
      children,
      ...props
    }: Props,
    ref: any
  ) => {
    const classes = useStyles({ textColor: textColor && Colors[textColor] });
    const getSpinnerSize = () => {
      if (iconOnly) {
        return props.size === "large" ? 14 : 12;
      }
      return props.size === "large" ? 21 : 18;
    };
    return (
      <MuiButton
        {...props}
        color={color !== "danger" ? color : undefined}
        disabled={disabled || loading}
        ref={ref}
        variant={variant}
        classes={{
          root: clsx(classes.root, {
            [classes.iconOnly]: iconOnly,
            [classes.containedDanger]:
              variant === "contained" && color === "danger",
            [classes.outlinedDanger]:
              variant === "outlined" && color === "danger",
            [classes.customTextColor]: !!textColor,
          }),
          sizeSmall: classes.small,
          sizeLarge: classes.large,
          containedPrimary: classes.containedPrimary,
          outlinedPrimary: classes.outlinedPrimary,
          outlined: classes.outlined,
          outlinedSizeSmall: classes.outlinedSizeSmall,
          outlinedSizeLarge: classes.outlinedSizeLarge,
        }}
      >
        {loading ? <CircularProgress size={getSpinnerSize()} /> : children}
      </MuiButton>
    );
  }
);

export default Button;
