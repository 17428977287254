import React from "react";
import { CellProps } from "react-table";
import { LinearProgress, withStyles, Box, Typography } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.light,
  },
}))(LinearProgress);

const ProgressBarCell = (props: CellProps<{}, number | string>) => {
  const valueAsNumber = Number(props.value);
  const progressValue = Math.min(valueAsNumber, 100);
  const progressLabel = `${Math.round(valueAsNumber)}%`;
  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={35} mr={1} textAlign="right">
        <Typography variant="body2" color="textSecondary">
          {progressLabel}
        </Typography>
      </Box>
      <Box width="100%">
        <BorderLinearProgress value={progressValue} variant="determinate" />
      </Box>
    </Box>
  );
};

export default ProgressBarCell;
