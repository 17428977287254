const blue = "#19B0F2";
const lightBlue = "#EFF8FF";

export const Colors = {
  black: "#1E1F23",
  grey: "#828896",
  greyHover: "#B9BDC4",
  elementsGrey: "#E0E5EC",
  screenBackgroundGrey: "#F4F5F6",
  lightBackgroundGrey: "#FAFAFA",
  white: "#FFFFFF",
  white80: "rgba(255, 255, 255, 0.8)",
  blue,
  blueHover: "#038BC6",
  blueSelected: "#016C9A",
  lightBlue,
  green: "#52CE3E",
  red: "#FF141D",
  yellow: "#F3AF00",
  primary: blue,
  primaryLight: lightBlue,
};
