import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { getBorder } from "../../../../../constants/Style";
import { Colors } from "@melp-design/style";

export default makeStyles((theme: Theme) =>
  createStyles({
    hiddenColumn: {
      "& .MuiTableRow-root::after": {
        right: 126,
      },
      "& .MuiTableRow-root > .MuiTableCell-body:nth-child(5)": {
        width: 1,
        border: "none !important",
        background: Colors.screenBackgroundGrey,
      },
      "& .MuiTableCell-body:nth-child(4)": {
        borderRight: getBorder(Colors.elementsGrey),
        paddingRight: 20,
      },
      "& .MuiTableRow-root:first-child > .MuiTableCell-body:nth-child(4)": {
        borderTopRightRadius: 20,
      },
      "& .MuiTableRow-root:last-child > .MuiTableCell-body:nth-child(4)": {
        borderBottomRightRadius: 20,
      },
    },
    disableTable: {
      "& .MuiTableCell-root": {
        pointerEvents: "none",
      },
    },
  })
);
