import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

const DIVIDER_WIDTH = "calc(100% - 30px)";

export default makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",
      padding: 0,
      position: "relative",
      "&:hover": {
        background: "transparent",
      },
      "&:not(:last-child)::after": {
        content: "''",
        background: Colors.Divider,
        height: 1,
        position: "absolute",
        bottom: "-1px",
        right: 0,
        width: DIVIDER_WIDTH,
      },
      "&.Mui-selected > $labelWrapper": {
        color: Colors.TextPrimary,
        background: "white",
      },
    },
    labelWrapper: {
      height: 50,
      width: "100%",
      paddingLeft: 30,
      paddingRight: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      transition: theme.transitions.create("background", { duration: 200 }),
      color: Colors.TextSecondary,
      "&:hover": {
        background: Colors.White,
        color: Colors.TextPrimary,
      },
    },
  })
);
