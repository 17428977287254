export const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
};

export enum AuthChallenges {
  newPasswordRequired = "NEW_PASSWORD_REQUIRED",
  smsMFA = "SMS_MFA",
  softwareTokenMFA = "SOFTWARE_TOKEN_MFA",
}
