import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    fixed: {
      [theme.breakpoints.up("md")]: {
        position: "fixed",
        top: 45,
      },
    },
  })
);
