import React, { useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Chip,
  // IconButton,
  // Tooltip,
  // Typography,
} from "@material-ui/core";
import useStyles from "./AssignEmployeesFooterButtons.styles";
import { useTranslation } from "react-i18next";
// import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
// import FooterTooltipContent from "../../common/FooterTooltipContent/FooterTooltipContent";
// import { formatDecimal, getChipInvestment } from "../../../utils/Common";

interface IProps {
  onCancel: () => void;
  onPublish: () => void;
  currentInvestment: number;
  newInvestment: number;
  difference: number;
  tooltipText: any[];
  changes?: number;
}

export default function AssignEmployeesFooterButtons(props: IProps) {
  const {
    onCancel,
    onPublish,
    // currentInvestment,
    // newInvestment,
    // tooltipText,
    // difference,
    changes,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  // const chipContent = useMemo(() => getChipInvestment(difference), [
  //   difference,
  // ]);

  const handleActionChange = useCallback(() => {
    onPublish();
    onCancel();
  }, [onPublish, onCancel]);

  // const formatCurrentInvestment = useMemo(
  //   () => formatDecimal(currentInvestment),
  //   [currentInvestment]
  // );

  const ChangeChip = useMemo(() => {
    if (changes && changes > 0)
      return (
        <Chip
          label={`${changes} ${t("common.changed")}`}
          className={classes.changesChip}
        />
      );
    return null;
  }, [changes, classes.changesChip, t]);

  return (
    <Box position="relative" width="100%" display="flex" alignItems="center">
      {/* <Box display="flex" alignItems="center">
        <Typography component={Box} paddingRight={1}>
          {t("common.current_investment")}:
        </Typography>
        <Typography component={Box} paddingRight={2} variant="subtitle1">
          {formatCurrentInvestment}
        </Typography>
        <Chip component={Box} label={chipContent} className={classes.chip} />
        <Tooltip
          title={
            <FooterTooltipContent
              currentCost={currentInvestment}
              newCost={newInvestment}
              history={tooltipText}
            />
          }
          classes={{ tooltip: classes.infoTooltip }}
        >
          <IconButton className={classes.infoButton}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box> */}
      <Box className={classes.buttonsContainer}>
        <Button disableElevation className={classes.button} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
        <Button
          name="confirm-changes"
          color="secondary"
          className={classes.button}
          variant="contained"
          disableElevation
          onClick={handleActionChange}
        >
          {t("common.confirm_changes")}
        </Button>
        {ChangeChip}
      </Box>
    </Box>
  );
}
