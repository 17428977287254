import { useCallback } from "react";
import { useLoading } from "../utils/Loading";
import {
  MarketplaceItemCreationRequestBody,
  MarketplaceItem,
  MarketplaceItemsResponse,
} from "../types/MarketplaceItems";
import { mutateData } from "../api/api";
import { Endpoints } from "../api/constants";
import useSWR, { mutate } from "swr";
import {
  MarketplaceItemUpdateRequestBody,
  MarketplaceItemTranslationCreationRequestBody,
} from "../types/MarketplaceItems";
import { useFilterValues } from "../utils/Table";
import { MpAsyncGetMethod } from "@mp-react/table";
import {
  MarketplaceItemTranslationUpdateRequestBody,
  MarketplaceItemPricesResponse,
} from "../types/MarketplaceItems";
import { useMutation } from "../utils/Api";
import {
  PriceTranslation,
  PriceTranslationUpdateRequest,
} from "../types/MarketplaceItems";
import {
  PriceTranslationsResponse,
  PriceTranslationCreationRequest,
} from "../types/MarketplaceItems";
import {
  MarketplaceItemPriceCreationRequest,
  MarketplaceItemPriceUpdateRequest,
} from "../types/MarketplaceItems";

export const useCreateMarketplaceItem = () => {
  const loadingState = useLoading();
  const create = async (
    supplierId: string,
    data: MarketplaceItemCreationRequestBody
  ) => {
    loadingState.startLoading();
    try {
      const response = await mutateData(
        "post",
        Endpoints.marketplaceSupplierItems(supplierId),
        data
      );
      return response.data as MarketplaceItem;
    } finally {
      loadingState.stopLoading();
    }
  };
  return { create, creating: loadingState.loading };
};

export const useMarketplaceItems = (query?: string) => {
  const { data, error } = useSWR<MarketplaceItemsResponse>(
    `${Endpoints.melpAdmin.marketplaceItems.root}${!!query ? `?${query}` : ""}`
  );
  const marketplaceItems = data?.data.map((item) => ({
    ...item,
    supplier: item.supplier.name,
  }));
  return { marketplaceItems, pageSize: data?.pageSize, error };
};

export const useMarketplaceItemsAsyncMethods = () => {
  const baseUrl = Endpoints.melpAdmin.marketplaceItems.filterValues;
  const { getAsyncFilterItems, getFilterItems } = useFilterValues(baseUrl);

  const getMarketplaceItemSKUs = useCallback<MpAsyncGetMethod>(
    (args) => getAsyncFilterItems(args, "sku"),
    [getAsyncFilterItems]
  );

  const getMarketplaceItemSuppliers = useCallback<MpAsyncGetMethod>(
    (args) => getAsyncFilterItems(args, "supplier"),
    [getAsyncFilterItems]
  );

  const getMarketplaceItemNames = useCallback<MpAsyncGetMethod>(
    (args) => getAsyncFilterItems(args, "name"),
    [getAsyncFilterItems]
  );

  const getBenefitCategories = useCallback<MpAsyncGetMethod>(
    () => getFilterItems("benefitPlanTypes"),
    [getFilterItems]
  );

  const getMarketplaceItemCategories = useCallback<MpAsyncGetMethod>(
    () => getFilterItems("categories"),
    [getFilterItems]
  );

  return {
    getMarketplaceItemSKUs,
    getMarketplaceItemSuppliers,
    getMarketplaceItemNames,
    getBenefitCategories,
    getMarketplaceItemCategories,
  };
};

export const useMarketplaceItem = (id: string) => {
  const url = Endpoints.melpAdmin.v2.marketplaceItems.byId(id).root;
  const { data, error, mutate } = useSWR<MarketplaceItem>(url);

  const [update, updateState] = useMutation<
    MarketplaceItemUpdateRequestBody,
    MarketplaceItem
  >(url, "patch", { onSuccess: (response) => mutate(response) });

  const [remove, removeState] = useMutation<void>(url, "delete");

  return {
    marketplaceItem: data,
    loading: !data && !error,
    update,
    updating: updateState.loading,
    remove,
    removing: removeState.loading,
    mutate,
  };
};

export const useMarketplaceItemTranslations = (itemId: string) => {
  const { marketplaceItem, loading, mutate } = useMarketplaceItem(itemId);

  const creationState = useLoading();
  const create = async (
    data: MarketplaceItemTranslationCreationRequestBody
  ) => {
    creationState.startLoading();
    try {
      const response = await mutateData(
        "post",
        Endpoints.melpAdmin.marketplaceItems.byId(itemId).translations,
        data
      );
      mutate();
      return response.data as MarketplaceItem;
    } finally {
      creationState.stopLoading();
    }
  };

  const updateState = useLoading();
  const update = async (
    id: string,
    data: MarketplaceItemTranslationUpdateRequestBody
  ) => {
    updateState.startLoading();
    try {
      const response = await mutateData(
        "put",
        Endpoints.melpAdmin.marketplaceItems.translations.byId(id).root,
        data
      );
      mutate();
      return response.data;
    } finally {
      updateState.stopLoading();
    }
  };

  const removeState = useLoading();
  const remove = async (id: string) => {
    removeState.startLoading();
    try {
      const response = await mutateData(
        "delete",
        Endpoints.melpAdmin.marketplaceItems.translations.byId(id).root
      );
      mutate();
      return response.data;
    } finally {
      removeState.stopLoading();
    }
  };

  return {
    translations: marketplaceItem?.translations,
    loading,
    create,
    creating: creationState.loading,
    update,
    updating: updateState.loading,
    remove,
    removing: removeState.loading,
  };
};

export const useItemRequest = () => {
  return useMutation(
    Endpoints.clientAdmin.marketplaceItems.requestItem,
    "post"
  );
};

export const useItemPrices = (itemId: string) => {
  const url = Endpoints.melpAdmin.marketplaceItems.byId(itemId).prices.root;
  const { data, error, mutate } = useSWR<MarketplaceItemPricesResponse>(url);

  const [create, creatData] = useMutation<
    MarketplaceItemPriceCreationRequest,
    void
  >(url, "post", {
    onSuccess: () => mutate(),
  });

  return {
    data,
    error,
    create,
    creating: creatData.loading,
  };
};

export const useItemPrice = (itemId: string, priceId: string) => {
  const pricesUrls = Endpoints.melpAdmin.marketplaceItems.byId(itemId).prices;
  const pricesUrl = pricesUrls.root;
  const priceUrl = pricesUrls.byId(priceId).root;

  const commonOpts = {
    onSuccess: () => {
      mutate(pricesUrl);
    },
  };

  const [update, updateData] = useMutation<
    MarketplaceItemPriceUpdateRequest,
    void
  >(priceUrl, "patch", commonOpts);

  const [remove, removeData] = useMutation<void, void>(
    priceUrl,
    "delete",
    commonOpts
  );

  return {
    update,
    updating: updateData.loading,
    remove,
    removing: removeData.loading,
  };
};

export const usePriceTranslations = (itemId: string, priceId: string) => {
  const url = Endpoints.melpAdmin.marketplaceItems
    .byId(itemId)
    .prices.byId(priceId).translations.root;

  const { data, error, mutate } = useSWR<PriceTranslationsResponse>(url);

  const [create, creatData] = useMutation<
    PriceTranslationCreationRequest,
    PriceTranslation
  >(url, "post", {
    onSuccess: (response) => mutate([...(data ?? []), response]),
  });

  return {
    data,
    error,
    create,
    creating: creatData.loading,
  };
};

export const usePriceTranslation = (
  itemId: string,
  priceId: string,
  translationId: string
) => {
  const translationsUrls = Endpoints.melpAdmin.marketplaceItems
    .byId(itemId)
    .prices.byId(priceId).translations;
  const translationsUrl = translationsUrls.root;
  const translationUrl = translationsUrls.byId(translationId).root;

  const commonOpts = {
    onSuccess: () => {
      mutate(translationsUrl);
    },
  };

  const [update, updateData] = useMutation<PriceTranslationUpdateRequest, void>(
    translationUrl,
    "patch",
    commonOpts
  );

  const [remove, removeData] = useMutation<void, void>(
    translationUrl,
    "delete",
    commonOpts
  );

  return {
    update,
    updating: updateData.loading,
    remove,
    removing: removeData.loading,
  };
};
