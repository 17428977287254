import { useMemo } from "react";
import { Route, RouteProps } from "react-router";
import { Redirect } from "react-router-dom";
import Loader from "../../components/common/Loader/Loader";
import {
  LocalPermissionModules,
  PermissionLevels,
  GlobalPermissionModules,
} from "../../constants/Administrators";
import { useMe } from "../../state/Administrators";
import { useAuth } from "../../state/Auth";
import { AdminRoles, Permission } from "../../types/Administrators";

interface Props extends RouteProps {
  roles: AdminRoles[] | undefined;
  module?:
    | LocalPermissionModules
    | GlobalPermissionModules
    | Array<LocalPermissionModules | GlobalPermissionModules>;
  permissionLevel?: PermissionLevels;
}

export default function PrivateRoute({
  roles,
  permissionLevel,
  module,
  ...rest
}: Props) {
  const { isLoggedIn, isLoading, hasToken } = useAuth();
  const { me, loading: meLoading, getPermissionsByModule, isRoot } = useMe();

  const currentRole = useMemo(() => me?.role, [me?.role]);

  const permissions = useMemo<Permission[]>(() => {
    if (!module) return [];
    return getPermissionsByModule(module);
  }, [getPermissionsByModule, module]);

  const hasCorrectPermissions = useMemo<boolean>(() => {
    if (isRoot) return true;
    if (!permissionLevel) return true;

    return permissions.some(
      (permission) =>
        permission.permission === "edit" || permission.permission === "view"
    );
  }, [isRoot, permissionLevel, permissions]);

  const isMelpAdmin = useMemo<boolean>(
    () => currentRole === "melpAdmin",
    [currentRole]
  );

  const loading = useMemo<boolean>(
    () => (isLoading || meLoading) && !!currentRole,
    [currentRole, isLoading, meLoading]
  );

  const isAuthenticated = useMemo<boolean>(
    () => isLoggedIn && hasToken,
    [hasToken, isLoggedIn]
  );

  const hasCorrectRole = useMemo<boolean>(
    () => !!currentRole && !!roles && roles?.includes(currentRole),
    [currentRole, roles]
  );

  const shouldShowRoute = useMemo<boolean>(
    () => isAuthenticated && hasCorrectRole && hasCorrectPermissions,
    [hasCorrectPermissions, hasCorrectRole, isAuthenticated]
  );

  if (loading) return <Loader />;

  if (!!currentRole && !hasCorrectRole)
    return <Redirect to={isMelpAdmin ? "/clients" : "/"} />;

  if (shouldShowRoute) return <Route {...rest} />;

  return null;
}
