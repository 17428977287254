import React, { useCallback, useMemo, useState } from "react";
import AddNewButton from "../../../common/AddNewButton/AddNewButton";
import FormDialog from "../../../dialogs/FormDialog/FormDialog";
import { useTranslation } from "react-i18next";
import { UnpackNestedValue } from "react-hook-form";
import { useFlexBenefitBalanceForm } from "../../../../configs/Forms/AddFlexBenefitBalanceForm";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import useFlexBenefitBalance from "../../../../state/FlexBenefitBalance";
import {
  AddFlexBenefitBalanceRequest,
  FlexBenefitBalanceForm,
} from "../../../../types/FlexBenefitBalance";
import { useParams } from "react-router-dom";
import { useMe } from "../../../../state/Administrators";
import { toast } from "react-toastify";

export default function InnerEmployeesFlexBalance() {
  const { id } = useParams() as any;
  const { canEditChoices } = useMe();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const flexBenefitBalanceForm = useFlexBenefitBalanceForm();
  const { addFlexBenefitBalance, loading } = useFlexBenefitBalance(id);

  const buttonLabel = useMemo(
    () =>
      loading ? (
        <CircularProgress size={20} />
      ) : (
        t("employees.add_manual_adjustment")
      ),
    [loading, t]
  );

  const handleOpenDialog = useCallback(() => setOpen(true), []);

  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleAdd = useCallback(
    (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      const allocatedAmount = (data as FlexBenefitBalanceForm).allocated;
      if (allocatedAmount !== "-" && allocatedAmount !== 0) {
        handleCloseDialog();
        addFlexBenefitBalance(data as AddFlexBenefitBalanceRequest);
      } else toast(t("errors.allocated_amount"), { type: "error" });
    },
    [t, handleCloseDialog, addFlexBenefitBalance]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="h5">{t("sidebar.flexBenefitBalance")}</Typography>
        <AddNewButton
          disabled={loading || !canEditChoices}
          onClick={handleOpenDialog}
        >
          {buttonLabel}
        </AddNewButton>
      </Box>
      <FormDialog
        onCancelClick={handleCloseDialog}
        onSubmitForm={handleAdd}
        id="add_manual_adjustment"
        open={open}
        title={t("employees.create_manual_adjustment")}
        actionLabel={t("common.confirm")}
        form={flexBenefitBalanceForm}
        loading={loading}
      />
    </>
  );
}
