type SupportedParamValues = string | number | boolean;

export interface BaseParams {
  sort?: string | null;
  page?: number | null;
  pageSize?: number | null;
}

export const convertToParamsTuples = <T extends {}>(params?: T) => {
  if (!params || !Object.keys(params).length) {
    return [];
  }
  const paramsKeys = Object.keys(params);
  return paramsKeys.reduce((acc, key) => {
    const paramValue = (params as Record<string, any>)[key];
    if (paramValue !== null && paramValue !== undefined) {
      if (Array.isArray(paramValue)) {
        if (paramValue.length) {
          paramValue.forEach((item) => {
            if (item !== null && item !== undefined) {
              acc.push([key, item]);
            }
          });
        }
      } else {
        acc.push([key, paramValue]);
      }
    }
    return acc;
  }, [] as Array<[string, SupportedParamValues]>);
};

const convertToQueryString = (
  paramsTuples: [string, SupportedParamValues][],
  serializer: (key: string, value: SupportedParamValues) => string
) => {
  if (!paramsTuples.length) {
    return "";
  }
  const queryValue = paramsTuples
    .map((paramsTuples) => serializer(...paramsTuples))
    .join("&");
  return "?" + queryValue;
};

const convertToFilterQueryValue = (
  key: string,
  value: SupportedParamValues
) => {
  return `filter[${key}]=${encodeURIComponent(value)}`;
};

/**
 * Does the same thing as @link convertToFilterQueryParams but does not apply
 * the filter pattern for "sort", "page" and "pageSize" parameters.
 *
 * @param params Parameters object
 * @returns query string to attach to URL. It already contains "?" sign.
 */
export const convertToQueryParams = <T extends BaseParams>(params?: T) => {
  const paramsTuples = convertToParamsTuples(params);
  return convertToQueryString(paramsTuples, (key, value) => {
    if (["sort", "page", "pageSize"].includes(key)) {
      return `${key}=${encodeURIComponent(value)}`;
    }
    return convertToFilterQueryValue(key, value);
  });
};

/**
 * Converts given parameters object to filter query parameters. It converts all
 * parameters according to the following pattern:
 *
 *             filter[<parameter key>]=<parameter value>
 *
 * If parameter value is an array, then each array item will be presented as a
 * filter parameter (e.g., object { foo: ["bar", "baz"]} will be converted to
 * string "?filter[foo]=bar&filter[foo]=baz").
 *
 * @param params Parameters object
 * @returns query string to attach to URL. It already contains "?" sign.
 */
export const convertToFilterQueryParams = <T extends {}>(params?: T) => {
  const paramsTuples = convertToParamsTuples(params);
  return convertToQueryString(paramsTuples, convertToFilterQueryValue);
};
