import { useTranslation } from "react-i18next";
import { MpControlType, MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { CustomFormControl } from "../../types/Common";

export const useAdministratorForm = (disabledEmail?: boolean): MpFormType => {
  const { t } = useTranslation();

  const controls = useMemo(
    (): CustomFormControl[] => [
      {
        type: MpControlType.text,
        key: "firstName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.first_name"),
        required: true,
        tooltip: "tooltip.administrator_first_name",
      },
      {
        type: MpControlType.text,
        key: "lastName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.last_name"),
        required: true,
        tooltip: "tooltip.administrator_last_name",
      },
      {
        type: "phone" as MpControlType,
        key: "phone",
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.mobile_number"),
        required: true,
        tooltip: "tooltip.administrator_phone",
      },
      {
        type: MpControlType.text,
        key: "email",
        validations: {
          minLength: 2,
          pattern:
            "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])",
        },
        messages: {
          required: t("form.required"),
          pattern: t("form.pattern"),
          minLength: t("form.min_length"),
        },
        label: t("common.email"),
        required: true,
        tooltip: "tooltip.administrator_email",
        disabled: !!disabledEmail,
      },
    ],
    [disabledEmail, t]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
