import React, { useCallback, useState, useMemo } from "react";
import AddNewButton from "../../../common/AddNewButton/AddNewButton";
import FormDialog from "../../../dialogs/FormDialog/FormDialog";
import { useTranslation } from "react-i18next";
import { UnpackNestedValue } from "react-hook-form";
import { useFlexBenefitAllocationsForm } from "../../../../configs/Forms/AddFlexBenefitAllocationsForms";
import { Typography, Box } from "@material-ui/core";
import useFlexBenefitAllocation from "../../../../state/FlexBenefitAllocation";
import { useParams } from "react-router-dom";
import { FlexBenefitAllocationRequest } from "../../../../types/FlexBenefitAllocation";
import { useMe } from "../../../../state/Administrators";

export default function InnerEmployeesFlexAllocations() {
  const { canEditChoices } = useMe();
  const { id } = useParams() as any;
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const flexBenefitAllocationsForm = useFlexBenefitAllocationsForm();
  const { createFlexBenefitAllocation, parsedEmployeeAllocationList, loading } =
    useFlexBenefitAllocation(id);

  const noData = useMemo(
    () => parsedEmployeeAllocationList.length > 0,
    [parsedEmployeeAllocationList]
  );

  const handleOpenDialog = useCallback(() => setOpen(true), []);

  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleAdd = useCallback(
    (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      handleCloseDialog();
      createFlexBenefitAllocation(
        data as FlexBenefitAllocationRequest,
        [id] as string[]
      );
    },
    [handleCloseDialog, createFlexBenefitAllocation, id]
  );

  const defaultValues = useMemo(
    () => ({
      accrual: "",
      allocationFrequency: "",
      allocatedAmount: "",
    }),
    []
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={noData ? "calc(100% - 103px)" : "100%"}
      >
        <Typography variant="h5">
          {t("sidebar.flexBenefitAllocations")}
        </Typography>
        <AddNewButton disabled={!canEditChoices} onClick={handleOpenDialog}>
          {t("employees.new_limit")}
        </AddNewButton>
      </Box>
      <FormDialog
        onCancelClick={handleCloseDialog}
        onSubmitForm={handleAdd}
        id="set-flex-benefit-allocation"
        open={open}
        title={t("actions.set_flex_benefit_allocation")}
        actionLabel={t("common.confirm")}
        form={flexBenefitAllocationsForm}
        loading={loading}
        defaultValues={defaultValues}
      />
    </>
  );
}
