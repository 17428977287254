import { useCallback, useMemo } from "react";
import { useLoading } from "../utils/Loading";
import { UseUsers, UserItem } from "../types/Users";
import { Endpoints } from "../api/constants";
import useSWR from "swr";
import { AxiosResponse } from "axios";
import { mutateData } from "../api/api";
import { useTranslation } from "react-i18next";
import { MpAsyncGetMethod } from "@mp-react/table";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { UsersFilterNames } from "../constants/Users";
import fileDownloader from "js-file-download";
import moment from "moment";

export const useUsers = (query?: string): UseUsers => {
  const { t } = useTranslation();
  const { data: usersData, error: usersError } = useSWR(
    `${Endpoints.usersList}${!!query ? `?${query}` : ""}`
  );

  const { stopLoading, startLoading, loading } = useLoading();

  const apiLoading = useMemo(
    () => !usersError && !usersData,
    [usersData, usersError]
  );

  const parsedUsersList = useMemo(
    () =>
      usersData?.data?.map((data: UserItem) => ({
        ...data,
      })),
    [usersData]
  );

  const parsedUsersTotals = useMemo(() => {
    const initialTotals = {
      fullName: "",
      companyName: "",
      phone: "",
      email: "",
    };
    if (!usersData?.footer) return initialTotals;
    const { employeeId, companyId, phone, email } = usersData.footer[0];
    return {
      fullName: t("totals.employee", { count: Number(employeeId) }),
      companyName: t("totals.company", { count: Number(companyId) }),
      phone: t("totals.phone_number", { count: Number(phone) }),
      email: t("totals.email", { count: Number(email) }),
    };
  }, [usersData, t]);

  const exportToExcel = useCallback(() => {
    startLoading();
    const url = `${Endpoints.usersExportToExcel}${!!query ? `?${query}` : ""}`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    return mutateData("get", url, null, headers, "arraybuffer")
      .then((res: any) => {
        const now = moment().format("L");
        const disposition = res?.headers["content-disposition"];
        const headersFilename = disposition?.split("filename=")?.[1];
        const filename = headersFilename ?? `Users_${now}.xlsx`;
        fileDownloader(res.data, filename);
      })
      .finally(() => {
        stopLoading();
      });
  }, [query, startLoading, stopLoading]);

  return {
    users: usersData,
    loading: loading || apiLoading,
    parsedUsersList,
    parsedUsersTotals,
    exportToExcel,
  };
};

export const useUsersAsyncMethods = (): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(() => `${Endpoints.usersFilterValues}`, []);

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: UsersFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const apiUrl = `${baseUrl}/${filterName}${searchParam}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getFilterItems = useCallback(
    (filterName: UsersFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getUserFullNames = useCallback<MpAsyncGetMethod>(
    (args) => getAsyncFilterItems(args, UsersFilterNames.FULL_USER_NAME),
    [getAsyncFilterItems]
  );

  const getUserCompanies = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(UsersFilterNames.USER_COMPANY_NAME),
    [getFilterItems]
  );

  const getUserEmail = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(UsersFilterNames.USER_EMAIL),
    [getFilterItems]
  );

  const getUserPhoneNumber = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(UsersFilterNames.USER_PHONE_NUMBER),
    [getFilterItems]
  );

  const getUserGender = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(UsersFilterNames.USER_GENDER),
    [getFilterItems]
  );

  const getUserPersonalCode = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(UsersFilterNames.USER_PERSONAL_CODE),
    [getFilterItems]
  );

  return {
    getUserFullNames,
    getUserCompanies,
    getUserEmail,
    getUserPhoneNumber,
    getUserGender,
    getUserPersonalCode,
  };
};
