import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Typography,
} from "@material-ui/core";
import { Search, TabUnselected } from "@material-ui/icons";
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import useStyles from "./StringArrayFilter.styles";
import {
  MetaContext,
  MpFilterProps,
  useFilterOptions,
  useFilterItems,
  useStoredState,
} from "@mp-react/table";
import { ReactComponent as CleanIcon } from "../../../../assets/icons/cross.svg";
import { useTranslation } from "react-i18next";

const empty: any[] = [];

export default function StringArrayFilter({
  column,
  value = empty,
  setValue,
  doFiltering,
}: MpFilterProps) {
  const { t } = useTranslation();
  const { mpColumn, filteredRows } = column;
  const { translations } = useContext(MetaContext);
  const [searchText, setSearchText] = useStoredState<string>(
    `custom-search-select-${column.id}`,
    ""
  );
  const classes = useStyles();
  const key = useMemo(() => mpColumn.key, [mpColumn]);
  const filteredRowsValues = useMemo(
    () => filteredRows?.map((i) => i.values),
    [filteredRows]
  );
  const filter = useFilterOptions(mpColumn);
  const {
    loading,
    items: asyncItems,
    getItems,
  } = useFilterItems(column, filter);

  const itemValues = useMemo(() => {
    return Array.from(
      new Set(
        filteredRowsValues
          .map((i) => {
            return i[key];
          })
          .flat()
      )
    );
  }, [filteredRowsValues, key]);

  const items = useMemo(() => {
    if (!!asyncItems) {
      return asyncItems;
    }
    if (!!searchText) {
      return itemValues.filter((item) =>
        item.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return itemValues.filter((item) => item !== undefined);
  }, [itemValues, asyncItems, searchText]);

  const allSelected = useMemo(
    () => JSON.stringify(value?.sort()) === JSON.stringify(items?.sort()),
    [items, value]
  );

  const handleChange = useCallback(
    (event: any) => {
      if (event.target.checked) {
        if (event.target.name === "all") {
          setValue(items);
        } else if (!value?.includes(event.target.name)) {
          setValue([...(value ?? []), event.target.name]);
        }
      } else {
        if (event.target.name === "all") {
          setValue(null);
        } else {
          setValue(value?.filter((val: any) => val !== event.target.name));
        }
      }
    },
    [value, items, setValue]
  );

  const handleSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [setSearchText]
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering]
  );

  const label = useCallback(
    (item: any) => {
      if (key === "benefitPlanType" || key === "type") {
        return t(
          `menu.${item?.replace(/([a-z0-9])([A-Z])/g, "$1_$2").toLowerCase()}`
        );
      } else {
        return item;
      }
    },
    [key, t]
  );

  useEffect(() => {
    getItems(searchText);
  }, [getItems, searchText]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        paddingY={1}
        paddingX={2}
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        height={50}
      >
        <InputBase
          startAdornment={
            <Search
              className={`${classes.searchIcon} ${
                searchText.length > 0 && classes.activeSearchIcon
              }`}
              color="action"
            />
          }
          value={searchText}
          onChange={handleSearchText}
          placeholder={translations?.search ?? "Search"}
          autoFocus={true}
          className={classes.naked}
        />
        <Box>
          {searchText.length > 0 ? (
            <IconButton onClick={() => setSearchText("")}>
              <CleanIcon />
            </IconButton>
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              onClick={handleClear}
              className={classes.emptyAnchor}
            >
              {translations?.clear ?? "Clear"}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
      {!loading && (items?.length ?? 0) > 0 && (
        <Box
          paddingY="10px"
          maxHeight={300}
          overflow="auto"
          className={classes.selectWrapper}
        >
          <FormControl
            component="fieldset"
            color="primary"
            className={classes.fullWidth}
          >
            <FormGroup className={classes.fullWidth}>
              {!searchText && (
                <FormControlLabel
                  key="all-items"
                  onChange={handleChange}
                  name="all"
                  className={`${classes.listItem} ${
                    allSelected && classes.activeRow
                  }`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={allSelected}
                      className={classes.checkbox}
                    />
                  }
                  label={`${translations?.all ?? "All"} (${items?.length})`}
                />
              )}
              {items?.map((item, i) => (
                <FormControlLabel
                  key={i}
                  onChange={handleChange}
                  name={item + ""}
                  className={`${classes.listItem} ${
                    !!value?.includes(item + "") && classes.activeRow
                  }`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={!!value?.includes(item + "")}
                      className={classes.checkbox}
                    />
                  }
                  label={label(item)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
      {loading && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (items?.length ?? 0) < 1 && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <TabUnselected fontSize="large" color="disabled" />
        </Box>
      )}
    </Box>
  );
}
