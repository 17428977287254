import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MpControlProps,
  controlRegisterOptions,
  useErrorMessages,
} from "@mp-react/form";
import { Controller } from "react-hook-form";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { Box, Button, Popover, Typography } from "@material-ui/core";
import useStyles from "./Time.styles";
import moment from "moment";
import { CustomFormControl, Timezone } from "../../../types/Common";
import { useBenefitStore } from "../../../state/Benefits";
import { useEmployeeStore } from "../../../state/Employees";
import { useTranslation } from "react-i18next";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useTooltip from "../../../utils/Tooltip";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { getTimezoneFromDate } from "../../../utils/Common";
import clsx from "clsx";
import TimezoneMenu from "../../common/DateTimePicker/TimezoneMenu/TimezoneMenu";

const inputLabelProps = { shrink: true };

function TimeView({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  onChange,
  value,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const classes = useStyles();
  const { t } = useTranslation();
  const defaultTZ = useMemo(() => moment().utcOffset() / 60, []);
  const tz = useMemo(
    () => (!!value ? getTimezoneFromDate(value) : defaultTZ),
    [defaultTZ, value]
  );
  const tzSign = useMemo(() => (tz >= 0 ? "+" : ""), [tz]);
  const [selectedZone, setSelectedZone] = useState<Timezone>({
    value: tz,
    label: `GMT${tzSign}${tz}`,
  });
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const errorText = useMemo(
    () => (firstMessage ?? hasError ? t("form.pattern") : undefined),
    [firstMessage, hasError, t]
  );
  const readonly = useMemo(
    () => !!(control as CustomFormControl)?.readonly,
    [control]
  );
  const disabled = useMemo(
    () =>
      !!(control as CustomFormControl)?.readonly ||
      !!(control as CustomFormControl)?.disabled,
    [control]
  );
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const {
    setDeactivationDate,
    setPublishDate,
    setVotingEndDate,
    setVotingStartDate,
    setActivationDate,
  } = useBenefitStore();
  const { setStartDate, setEndDate } = useEmployeeStore((state) => state);

  const valueIsValid = useMemo(() => moment(value).isValid(), [value]);

  const timeValue = useMemo(() => {
    if (!value) return value;
    return moment.parseZone(value);
  }, [value]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleTimezoneChange = useCallback(
    (timezone: Timezone) => {
      if (valueIsValid) {
        setSelectedZone(timezone);
        const tzTime = moment
          .parseZone(value)
          .utcOffset(timezone.value, true)
          .format();
        onChange(tzTime);
        switch (control?.key) {
          case "publishTime":
            setPublishDate(tzTime, "time");
            break;
          case "activationTime":
            setActivationDate(tzTime, "time");
            break;
          case "deactivationTime":
            setDeactivationDate(tzTime, "time");
            break;
          case "startTime":
            setStartDate(tzTime, "time");
            break;
          case "endTime":
            setEndDate(tzTime, "time");
            break;
          case "votingStartTime":
            setVotingStartDate(tzTime, "time");
            break;
          case "votingEndTime":
            setVotingEndDate(tzTime, "time");
            break;
        }
        setOpen(false);
      }
    },
    [
      valueIsValid,
      value,
      onChange,
      control?.key,
      setPublishDate,
      setActivationDate,
      setDeactivationDate,
      setStartDate,
      setEndDate,
      setVotingStartDate,
      setVotingEndDate,
    ]
  );

  const handleTimeChange = useCallback(
    (time: MaterialUiPickersDate, value?: string | null) => {
      const timeString = moment(time).isValid()
        ? moment(time).utcOffset(selectedZone.value, true).format()
        : null;
      onChange(timeString);
      if (moment(time).isValid()) {
        setHasError(false);
        switch (control?.key) {
          case "publishTime":
            setPublishDate(timeString, "time");
            break;
          case "activationTime":
            setActivationDate(timeString, "time");
            break;
          case "deactivationTime":
            setDeactivationDate(timeString, "time");
            break;
          case "startTime":
            setStartDate(timeString, "time");
            break;
          case "endTime":
            setEndDate(timeString, "time");
            break;
          case "votingStartTime":
            setVotingStartDate(timeString, "time");
            break;
          case "votingEndTime":
            setVotingEndDate(timeString, "time");
            break;
        }
      } else {
        if (!value?.includes("_") && value !== null) {
          setHasError(true);
        } else {
          setHasError(false);
        }
      }
    },
    [
      selectedZone.value,
      onChange,
      control?.key,
      setPublishDate,
      setActivationDate,
      setDeactivationDate,
      setStartDate,
      setEndDate,
      setVotingStartDate,
      setVotingEndDate,
    ]
  );

  const TimezoneButton = useMemo(() => {
    return (
      <Button
        onClick={handleToggle}
        disabled={!valueIsValid}
        className={classes.tzButton}
        disableRipple
        ref={anchorRef}
      >
        <Typography variant="body2">{selectedZone.label}</Typography>
      </Button>
    );
  }, [classes.tzButton, handleToggle, selectedZone.label, valueIsValid]);

  useEffect(() => {
    if (!!value && typeof value === "string") {
      setSelectedZone({
        value: tz,
        label: `GMT${tzSign}${tz}`,
      });
    }
  }, [tz, tzSign, value]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box position="relative">
        <KeyboardTimePicker
          ampm={false}
          value={timeValue}
          mask="__:__"
          error={!!error || hasError}
          helperText={errorText}
          name={control.key}
          required={control.required}
          placeholder={control.placeholder}
          label={layout === "separated" ? "" : control.label}
          size={size}
          fullWidth={true}
          InputLabelProps={inputLabelProps}
          inputVariant={variant}
          variant="inline"
          onChange={handleTimeChange}
          keyboardIcon=""
          className={clsx({
            [classes.input]: true,
            [classes.readonly]: readonly,
          })}
          disabled={disabled}
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        />
        {TimezoneButton}
        <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        PaperProps={{
          style: { width: 100, maxHeight: 270 },
        }}
        onClose={handleToggle}
        anchorOrigin={{
          vertical: "bottom" as "bottom",
          horizontal: "right" as "right",
        }}
        transformOrigin={{
          vertical: "top" as "top",
          horizontal: "right" as "right",
        }}
      >
        <TimezoneMenu
          selectedZone={selectedZone}
          handleTimezoneChange={handleTimezoneChange}
        />
      </Popover>
    </MuiPickersUtilsProvider>
  );
}

export default function Time({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value }) => (
        <TimeView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
