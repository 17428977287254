import { useMemo } from "react";

export const useChoicesDialogActions = () =>
  useMemo(() => ["sendReminder"], []);

export enum ChoicesFilterNames {
  EMPLOYEE_NAME = "employeeName",
  BENEFIT_PLAN_TYPE = "benefitPlanType",
  BENEFIT_PLAN_NAME = "benefitPlanName",
}
