import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceByElement } from "../../../../../utils/Logs";
import { Log } from "../../../../../types/Logs";

export default function HrUserLoggedIn(props: Partial<Log>) {
  const { admin, adminEmail } = props;
  const { t } = useTranslation();

  const adminName = useMemo(
    () => admin ?? adminEmail ?? "",
    [admin, adminEmail]
  );

  return useMemo(
    () => replaceByElement(t("log.hr_user_logged_in"), "{name}", adminName),
    [adminName, t]
  );
}
