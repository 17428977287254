import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      marginTop: 20,
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
    },
    title: {
      fontSize: 22,
      lineHeight: "24px",
      paddingTop: 6,
      paddingBottom: 36,
    },
    editContainer: {
      display: "flex",
      alignItems: "center",
      "& :first-child": {
        paddingRight: 13,
      },
    },
    gridContainer: {
      paddingTop: 8,
    },
    collapse: {
      paddingTop: 8,
    },
    categoriesTitle: {
      fontSize: 22,
      lineHeight: "24px",
      paddingTop: 6,
      paddingBottom: 24,
    },
  })
);
