import { useMemo } from "react";

export const useSupportedLanguages = () =>
  useMemo(
    () => [
      "EN",
      "JA",
      "BE",
      "LT",
      "BG",
      "ID",
      "ET",
      "PL",
      "FI",
      "RU",
      "KA",
      "NO",
      "EL",
      "GA",
      "DA",
      "NL",
      "KO",
      "SV",
      "PT",
      "IT",
      "RO",
      "SL",
      "SK", // slovak
      "LV",
      "TR",
      "UK",
      "UZ", // uzbek
      "FR",
      "TK",
      "IS",
      "DE",
    ],
    []
  );

export enum CompaniesFilterNames {
  NAME = "name",
  COMPANY_GROUPS = "companyGroups",
}
