import { Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Log } from "../../../../../types/Logs";

export default function EmployeeRemovedFromGroup(props: Partial<Log>) {
  const { doneByName, employeeGroup, employee, employeeId } = props;
  const { t } = useTranslation();

  return useMemo(() => {
    return (
      <>
        {t("log.removed_from_group")
          .split(" ")
          ?.map((text) => {
            switch (text) {
              case "{doneBy}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${doneByName} `}
                  </Typography>
                );
              case "{employee}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {employee ? `${employee}  ` : `${employeeId} `}
                  </Typography>
                );
              case "{group}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${employeeGroup} `}
                  </Typography>
                );
              default:
                return `${text} `;
            }
          })}
      </>
    );
  }, [doneByName, employee, employeeGroup, employeeId, t]);
}
