import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& tr": {
        verticalAlign: "initial",
      },
      "& td": {
        whiteSpace: "pre-line",
        paddingTop: "13px",
        paddingBottom: "13px",
      },
    },
  })
);
