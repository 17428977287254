import { TableDataResponse } from "./Table";

type BenefitAssignmentStatus = "on" | "off" | "flex";

export enum BenefitStatus {
  active = "0",
  inactive = "1",
}

interface BenefitUsageSummary {
  /**
   * Benefit ID
   */
  id: string;
  assignmentStatus: BenefitAssignmentStatus;
  /**
   * Benefit title
   */
  name: string;
  value: number;
  expenses: number;
  percentage: number;
  remaining: number;
  order: BenefitStatus;
  employeeId: string;
}

export type BenefitUsageSummaryResponse =
  TableDataResponse<BenefitUsageSummary>;

interface BenefitUsageTableRow extends BenefitUsageSummary {
  inactive: boolean;
}

export type BenefitUsageTableData = TableDataResponse<BenefitUsageTableRow>;

export enum BenefitUsageSummaryFilters {
  name = "name",
  assignmentStatus = "assignmentStatus",
}

export type ExpenseStatus = "Reserved" | "Accepted" | "Rejected";

export interface BenefitExpensesItem {
  id: string;
  creationDate: string;
  assignmentStatus: BenefitAssignmentStatus;
  name: string;
  description: string;
  expense: string;
  employeeId: string;
  order: BenefitStatus;
  benefitPlanId: string;
  marketplaceOrderNr?: string;
  marketplaceItemId?: string;
  commentForEmployee?: string | null;
  status?: ExpenseStatus | null;
}

export type BenefitExpensesResponse = TableDataResponse<
  BenefitExpensesItem,
  { expenses?: string }
>;

export enum BenefitExpensesFilters {
  name = "name",
  assignmentStatus = "assignmentStatus",
  description = "description",
}

export interface BenefitExpenseCreationRequestBody {
  benefitPlanId: string;
  employeeIds: string[];
  description: string;
  expense: number;
}

export type BenefitExpenseFormData = Omit<
  BenefitExpenseCreationRequestBody,
  "employeeIds"
>;
export interface BenefitExpense {
  id: string;
  benefitPlanId: string;
  employeeId: string;
  description: string;
  expense: number;
  creationDate: string;
}
