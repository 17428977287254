import React, { useMemo } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { useFiles } from "../../../../state/Files";
import { MpControlProps } from "@mp-react/form";
import { Attachments } from "../../../../types/Benefits";

interface IProps extends Partial<MpControlProps> {
  value?: string;
  removeFileHandler: () => void;
  name?: string;
}

export default function FileField(props: IProps) {
  const { value, size, removeFileHandler, name } = props;
  const id = useMemo(
    () =>
      value && typeof value === "object"
        ? (value as Attachments).fileId
        : value,
    [value]
  );
  const { file, loading } = useFiles(id ?? "");

  if (!!value) if (!file || loading) return null;

  return (
    <TextField
      value={name ?? file?.fileName}
      variant="outlined"
      fullWidth
      disabled
      size={size}
      onClick={() => window.open(file?.signedUrl, "_blank")}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                removeFileHandler();
              }}
            >
              <CrossIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
