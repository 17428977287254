import { MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TooltipControl } from "../../../types/Common";

export const useEmployeeInformationCategories = (): MpFormType => {
  const { t } = useTranslation();
  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: "employeeCategoriesDropdown" as any,
        key: "categories",
        label: "",
        placeholder: t("employees.select"),
        tooltip: "tooltip.employee_info_categories",
      },
    ],
    [t]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
