import { Colors } from "./../../../constants/Style";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Shadows } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    iconSpacer: {
      marginRight: 10,
    },
    menuPaper: {
      padding: "10px 0",
      marginTop: 4,
      boxShadow: Shadows.BulkAction,
    },
    menuItem: {
      paddingTop: 14,
      paddingBottom: 0,
      paddingRight: 0,
      minWidth: 150,
    },
    overlay: {
      zIndex: 2,
    },
    itemDivider: {
      borderBottom: "rgba(0, 0, 0, 0.1) solid 1px",
      marginLeft: 20,
      paddingRight: 20,
      width: "100%",
      paddingBottom: 14,
    },
    noBorder: {
      borderBottom: 0,
    },
    disabled: {
      "&.Mui-disabled": {
        backgroundColor: Colors.Default,
        color: Colors.TextElement,
      },
    },
  })
);
