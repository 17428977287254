import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceByElement } from "../../../../../utils/Logs";

interface IProps {
  admin: string;
}

export default function HrAdminCreatedByMelp(props: IProps) {
  const { admin } = props;
  const { t } = useTranslation();

  return useMemo(
    () => replaceByElement(t("log.hr_admin_created_by_melp"), "{name}", admin),
    [admin, t]
  );
}
