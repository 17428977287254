import { useMemo } from "react";
import { Tab } from "../types/Common";
import AssignEmployees from "../containers/Groups/GroupsInner/views/AssignEmployees/AssignEmployees";
import AssignBenefits from "../containers/Groups/GroupsInner/views/AssignBenefits/AssignBenefits";
import {
  GlobalPermissionModules,
  LocalPermissionModules,
} from "./Administrators";

export const useGroupsTabs = () =>
  useMemo(
    (): Tab[] => [
      {
        key: "innerGroupAssignBenefits",
        translationKey: "assignBenefits",
        component: AssignBenefits,
        module: GlobalPermissionModules.Benefits,
      },
      {
        key: "innerGroupAssignEmployees",
        translationKey: "assignEmployees",
        component: AssignEmployees,
        module: LocalPermissionModules.Employees,
      },
    ],
    []
  );

export const useGroupSidebarActions = (): string[] =>
  useMemo(
    () => ["rename_group", "make_copy", "remove_all_employees", "delete"],
    []
  );

export enum GroupsFilterNames {
  GROUP_NAME = "name",
  BENEFIT_PLANS = "benefitPlans",
}

export enum GroupsAssignEmployeesFilterNames {
  FULL_NAME = "fullName",
  EMPLOYEE_GROUP = "employeeGroup",
  JOB_TITLE = "jobTitle",
  CUSTOM_COLUMN = "customColumn",
}
