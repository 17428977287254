import { Box, TextField } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  useErrorMessages,
  MpControlProps,
  controlRegisterOptions,
} from "@mp-react/form";
import { useDebouncedCallback } from "use-debounce";
import { CustomFormControl, TooltipControl } from "../../../types/Common";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useTooltip from "../../../utils/Tooltip";
import FormSufleris from "../../common/FormSufleris/FormSufleris";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useStyles from "../../../styles/Form.styles";

const inputLabelProps = { shrink: true };

export function MultilineView({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  value,
  onChange,
}: MpControlProps & {
  value: any;
  onChange: (...event: any[]) => void;
}) {
  const classes = useStyles();
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const [inputValue, setInputValue] = React.useState(value ?? "");
  const { tooltip, closeTooltip, openTooltip, anchorEl } = useTooltip(control);
  const sufleris = useMemo(
    () => (control as TooltipControl)?.sufleris,
    [control]
  );
  const readonly = useMemo(
    () => !!(control as CustomFormControl)?.readonly,
    [control]
  );
  const disabled = useMemo(
    () =>
      !!(control as CustomFormControl)?.readonly ||
      !!(control as CustomFormControl)?.disabled,
    [control]
  );
  const debounced = useDebouncedCallback((val) => {
    onChange(val);
  }, 300);
  const { t } = useTranslation();

  useEffect(() => {
    if (value !== null && value !== undefined && value !== inputValue)
      setInputValue(value);
    // eslint-disable-next-line
  }, [value]);

  const isNews = useMemo(() => {
    if (control.label === `${t("form.news_text")}`) return true;
    return false;
  }, [control.label, t]);

  return (
    <Box position="relative">
      <TextField
        error={!!error}
        helperText={firstMessage}
        multiline={true}
        minRows={isNews ? 12 : 4}
        maxRows={isNews ? 16 : 8}
        name={control.key}
        required={control.required}
        placeholder={control.placeholder}
        label={layout === "separated" ? "" : control.label}
        size={size}
        fullWidth={true}
        InputLabelProps={inputLabelProps}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          debounced(e.target.value);
        }}
        variant={variant}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        className={clsx({
          [classes.readonly]: readonly,
        })}
        disabled={disabled}
      />
      {sufleris && <FormSufleris sufleris={sufleris} multiline={true} />}
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function Multiline(props: MpControlProps) {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <MultilineView {...props} onChange={onChange} value={value} />
      )}
    />
  );
}
