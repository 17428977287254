import React from "react";
import { MarketplaceItem } from "../../../types/MarketplaceItems";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
} from "@melp-design/components";
import { Tooltip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { isDefined } from "../../../utils/isDefined";
import RichText from "../../common/RichText/RichText";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../utils/Currency";
import { Colors, Effects } from "@melp-design/style";
import { useItemTranslation } from "../../../utils/MarketplaceItems";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 250,
  },
  imagesContainer: {
    width: "100%",
    position: "relative",
    backgroundColor: theme.palette.common.white,
  },
  itemImagePlaceholder: {
    opacity: 0.3,
  },
  supplierLogoContainer: {
    width: 34,
    height: 34,
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: `1px solid ${Colors.elementsGrey}`,
    borderRadius: 6,
    padding: 1,
    background: theme.palette.common.white,
    boxShadow: Effects.smallElementsShadow,
  },
  supplierLogo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  itemDetails: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 15px 5px",
    textAlign: "center",
  },
  verticalTextLimit: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
  },
  itemTitle: {
    WebkitLineClamp: 2,
    marginBottom: "5px",
  },
  itemDescriptionContainer: {
    flexGrow: 1,
  },
  itemDescription: {
    WebkitLineClamp: 3,
    marginBottom: "5px",
  },
  itemAction: {
    width: "100%",
  },
}));

interface Props<T> {
  item: T;
  onClick?: () => void;
  isSelected?: boolean;
  onSelect?: (itemId: string) => void;
  onRemove?: (itemId: string) => void;
  actionDisabled?: boolean;
  actionHint?: string;
  loading?: boolean;
}

const ItemCard = <T extends MarketplaceItem>({
  item,
  isSelected = false,
  onSelect = () => {},
  onRemove = () => {},
  loading,
  ...props
}: Props<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { image: itemImageFile, supplier, id } = item;

  let itemImage = (
    <CardMedia
      className={classes.itemImagePlaceholder}
      image={"/no-item-image-placeholder.svg"}
    />
  );
  if (itemImageFile?.signedUrl) {
    itemImage = <CardMedia image={itemImageFile.signedUrl} />;
  }

  const { logo: supplierLogoFile } = supplier;
  let supplierLogo = null;
  if (supplierLogoFile?.signedUrl) {
    supplierLogo = (
      <img
        src={supplierLogoFile.signedUrl}
        alt={`${supplier.name} logo`}
        className={classes.supplierLogo}
      />
    );
  }

  const itemDetails = useItemTranslation(item);

  const handleItemSelection = () => {
    onSelect(id);
  };

  const handleItemRemoval = () => {
    onRemove(id);
  };

  const formatPrice = (fractionUnitAmount?: number) => {
    return currencyFormatter.formatFractionalUnitAmount(
      fractionUnitAmount,
      item.currency
    );
  };

  const renderPrice = () => {
    const { prices } = item;
    if (!prices?.length) {
      return "";
    }

    const amounts = prices
      .reduce((acc, price) => {
        if (price.priceType === "fixedPrice") {
          acc.push(price.price);
        }
        if (price.priceType === "priceRange") {
          acc.push(price.priceRangeFrom);
          acc.push(price.priceRangeTo);
        }
        return acc;
      }, [] as (number | null)[])
      .filter((amount): amount is number => isDefined(amount));

    if (amounts.length === 1) {
      return formatPrice(amounts[0]);
    } else {
      const min = formatPrice(Math.min(...amounts));
      const max = formatPrice(Math.max(...amounts));
      return `${min} - ${max}`;
    }
  };

  return (
    <Card className={classes.root} highlighted={isSelected}>
      <CardActionArea onClick={props.onClick}>
        <div className={classes.imagesContainer}>
          {itemImage}
          <Tooltip title={supplier.name} placement="top">
            <div className={classes.supplierLogoContainer}>{supplierLogo}</div>
          </Tooltip>
        </div>
        <CardContent className={classes.itemDetails}>
          <Typography
            variant="h3"
            className={clsx(classes.verticalTextLimit, classes.itemTitle)}
          >
            {itemDetails?.title}
          </Typography>
          <div className={classes.itemDescriptionContainer}>
            <Typography
              variant="p2"
              color="textSecondary"
              className={clsx(
                classes.verticalTextLimit,
                classes.itemDescription
              )}
            >
              <RichText value={itemDetails?.description} plainText />
            </Typography>
          </div>
          <Typography variant="h3">{renderPrice()}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Tooltip title={props.actionHint ?? ""}>
          <div className={classes.itemAction}>
            {!isSelected ? (
              <Button
                variant="outlined"
                color="primary"
                className={classes.itemAction}
                onClick={handleItemSelection}
                disabled={props.actionDisabled}
                loading={loading}
                fullWidth
              >
                {t("marketplace.selectItem")}
              </Button>
            ) : (
              <Button
                variant="outlined"
                textColor="red"
                onClick={handleItemRemoval}
                disabled={props.actionDisabled}
                loading={loading}
                fullWidth
              >
                {t("marketplace.removeItem")}
              </Button>
            )}
          </div>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default ItemCard;
