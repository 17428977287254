import React, { useMemo, useEffect, useCallback } from "react";
import { MpControlProps } from "@mp-react/form";
import {
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Box,
  InputLabel,
} from "@material-ui/core";
import useStyles from "./CompanyDropdownView.styles";
import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/arrow-right.svg";
import { HierarchyRequest } from "../../../../types/ParentCompany";
import { useTranslation } from "react-i18next";
import { useHierarchy } from "../../../../state/Hierarchy";
import { HierarchyLevels } from "../../../../constants/ParentCompany";
import useTooltip from "../../../../utils/Tooltip";
import FormTooltip from "../../../common/FormTooltip/FormTooltip";
import {
  LocalPermissionModules,
  PermissionOptions,
} from "../../../../constants/Administrators";
import { useMe } from "../../../../state/Administrators";

export default function CompanyDropdownView({
  control,
  variant,
  onChange,
  value,
  inputRef,
  name,
  handleCompanyId,
  error,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
  name: string;
  inputRef: React.MutableRefObject<any>;
  handleCompanyId: (companyId: string) => void;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const classes = useStyles();
  const { t } = useTranslation();
  const { canEditEmployees, canEditCompanies } = useMe();

  const hierarchyRequest = useMemo<HierarchyRequest>(
    () => ({
      level: HierarchyLevels.COMPANY_AND_DEPARTMENT,
      module: LocalPermissionModules.Employees,
      permissions: canEditEmployees
        ? PermissionOptions.Edit
        : PermissionOptions.View,
    }),
    [canEditEmployees]
  );

  const { hierarchy, loading } = useHierarchy(hierarchyRequest);

  const defaultCompany = useMemo(
    () => hierarchy?.companies && hierarchy?.companies[0],
    [hierarchy]
  );

  const companies = useMemo(() => {
    return hierarchy?.companies?.map((data) => ({
      name: data.name,
      id: data.id,
    }));
  }, [hierarchy]);

  useEffect(() => {
    if (value === null) {
      if (!!defaultCompany?.id) {
        handleCompanyId(defaultCompany?.id);
        onChange(defaultCompany?.id);
      }
    } else {
      handleCompanyId(value);
    }
  }, [handleCompanyId, value, defaultCompany, onChange]);

  const handleChange = useCallback(
    (e: any) => {
      onChange(e.target.value);
      handleCompanyId(e.target.value);
    },
    [onChange, handleCompanyId]
  );

  const ManageOption = useMemo(() => {
    if (!canEditCompanies) return null;
    return (
      <MenuItem
        component={Link}
        to={"/more/company-settings?tab=companySettingsCompanies"}
        className={classes.link}
      >
        {t("form.manage")}
        <LinkIcon />
      </MenuItem>
    );
  }, [canEditCompanies, classes.link, t]);

  if (loading) return <CircularProgress size={20} />;

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <InputLabel
        className={`${classes.label} ${error && classes.error}`}
        required={true}
      >
        {t("employees.company")}
      </InputLabel>
      <FormControl
        id="company-dropdown"
        variant={variant ?? "outlined"}
        fullWidth={true}
        required={true}
        className={classes.formControl}
      >
        <Select
          value={value ?? defaultCompany?.id}
          onChange={(e) => handleChange(e)}
          inputRef={inputRef}
          name={name}
        >
          {companies?.map((company: { name?: string; id?: string }) => (
            <MenuItem
              key={company.id}
              value={company?.id ?? defaultCompany?.id}
            >
              {company.name}
            </MenuItem>
          ))}
          {ManageOption}
        </Select>
      </FormControl>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} marginTop="22px" />
    </Box>
  );
}
