import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Box, Button, Popover, Typography } from "@material-ui/core";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import TimezoneMenu from "./TimezoneMenu/TimezoneMenu";
import { Timezone } from "../../../types/Common";
import {
  useDatePlaceholder,
  getTimePlaceholder,
  browserLanguage,
  getTimezoneFromDate,
  mergeDateTime,
  useDateFormat,
} from "../../../utils/Common";
import useStyles from "./DateTimePicker.styles";

interface IProps {
  onSubmit: (dateTime: string, tz?: number) => void;
  defaultDate?: string | null;
  disablePast?: boolean;
  hideTimePicker?: boolean;
}

export default function DateTimePicker(props: IProps) {
  const classes = useStyles();
  const { onSubmit, defaultDate, disablePast, hideTimePicker } = props;
  const { datePlaceholder } = useDatePlaceholder();
  const { keyboardDatePickerFormat } = useDateFormat();

  const { t } = useTranslation();
  const now = useMemo(() => moment().locale(browserLanguage), []);
  const defaultTZ = useMemo(() => (moment().utcOffset() as number) / 60, []);
  const tz = useMemo(
    () => (!!defaultDate ? getTimezoneFromDate(defaultDate) : defaultTZ),
    [defaultDate, defaultTZ]
  );
  const tzSign = useMemo(() => (tz >= 0 ? "+" : ""), [tz]);
  const [selectedZone, setSelectedZone] = useState<Timezone>({
    value: tz,
    label: `GMT${tzSign}${tz}`,
  });
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<MaterialUiPickersDate>(
    !!defaultDate ? moment.parseZone(defaultDate).locale(browserLanguage) : now
  );
  const [time, setTime] = useState<MaterialUiPickersDate>(
    !!defaultDate ? moment.parseZone(defaultDate) : now
  );
  const anchorRef = useRef<HTMLButtonElement>(null);

  const PaperProps = useMemo(
    () => [{ style: { width: 100, maxHeight: 270 } }][0],
    []
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleTimezoneChange = useCallback(
    (timezone: Timezone) => {
      setSelectedZone(timezone);
      setTime(moment(time).utcOffset(timezone.value, true));
      setOpen(false);
    },
    [time, setTime]
  );

  const handleTimeChange = useCallback(
    (time: any) => {
      setTime(time ? time?.utcOffset(selectedZone.value, true) : null);
    },
    [setTime, selectedZone]
  );

  const submitHandler = useCallback(() => {
    const parsedDate = moment(date).toISOString(true);
    const parsedTime = moment(time).toISOString(true);
    const dateTime = mergeDateTime(parsedDate, parsedTime) ?? "";
    const tz = getTimezoneFromDate(dateTime);
    onSubmit(dateTime, tz);
  }, [date, time, onSubmit]);

  const disableButton = useMemo(() => {
    const validTime = moment(time).isValid();
    const validDate = moment(date).isValid();
    if (validDate && validTime) {
      return false;
    }
    return true;
  }, [time, date]);

  const TimePicker = useMemo(() => {
    if (hideTimePicker) return null;
    return (
      <Box maxWidth={160} marginRight={2} position="relative">
        <KeyboardTimePicker
          ampm={false}
          name="actionTime"
          value={time}
          placeholder={getTimePlaceholder()}
          fullWidth={true}
          InputLabelProps={{ shrink: true }}
          variant="inline"
          inputVariant="outlined"
          onChange={handleTimeChange}
          size="small"
          keyboardIcon=""
          className={classes.timeField}
        />
        <Button
          onClick={handleToggle}
          className={classes.tzButton}
          disableRipple
          ref={anchorRef}
        >
          <Typography variant="body2">{selectedZone.label}</Typography>
        </Button>
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          PaperProps={PaperProps}
          onClose={handleToggle}
        >
          <TimezoneMenu
            selectedZone={selectedZone}
            handleTimezoneChange={handleTimezoneChange}
          />
        </Popover>
      </Box>
    );
  }, [
    hideTimePicker,
    time,
    handleTimeChange,
    classes.timeField,
    classes.tzButton,
    handleToggle,
    selectedZone,
    open,
    PaperProps,
    handleTimezoneChange,
  ]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={browserLanguage}>
      <Box display="flex" padding={2}>
        <Box maxWidth={160} marginRight={2}>
          <KeyboardDatePicker
            value={date}
            name="actionDate"
            onChange={setDate}
            placeholder={datePlaceholder}
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            variant="inline"
            inputVariant="outlined"
            autoOk={true}
            size="small"
            format={keyboardDatePickerFormat}
            keyboardIcon={<CalendarIcon />}
            disablePast={disablePast ?? false}
          />
        </Box>
        {TimePicker}
        <Button
          variant="contained"
          color="secondary"
          onClick={submitHandler}
          disabled={disableButton}
        >
          {t("common.confirm")}
        </Button>
      </Box>
    </MuiPickersUtilsProvider>
  );
}
