import { Typography } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function YesNo(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { value } = useRendererOptions(props);

  const text = useMemo(() => {
    if (value === true) {
      return t("common.yes");
    } else if (value === false) {
      return t("common.no");
    }
  }, [value, t]);

  return (
    <Typography variant="body2" align="center">
      {text}
    </Typography>
  );
}
