import React, { useMemo } from "react";
import { Box, BoxProps, Container, Grid, GridProps } from "@material-ui/core";
import useStyles from "./FormFooter.styles";
import { useInvestmentCalculationsStore } from "../../../state/InvestmentCalculations";
import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps extends BoxProps {
  containerProps?: BoxProps;
  spacing?: GridProps["spacing"];
  withChanges?: boolean;
}

export default function FormFooter({
  children,
  containerProps,
  spacing,
  withChanges,
  ...rest
}: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const changeCount = useInvestmentCalculationsStore(
    (state) => state.changeCount
  );

  const containerStyle = useMemo(() => {
    if (!withChanges) return {};
    const transformString = `translateY(${changeCount > 0 ? "0" : "70px"})`;
    return {
      transform: transformString,
    };
  }, [changeCount, withChanges]);

  return (
    <Box
      className={classes.container}
      bgcolor="#fff"
      display="flex"
      alignItems="center"
      style={containerStyle}
      {...containerProps}
    >
      <Prompt
        when={withChanges ? changeCount > 0 : false}
        message={t("errors.user_leaving_edited_page")}
      />
      <Container maxWidth="lg">
        <Grid spacing={spacing ?? 5} container>
          <Grid xs={3} component={Box} maxWidth="300px !important" item />
          <Grid xs={9} component={Box} item>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              margin="auto"
              {...rest}
            >
              {children}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
