import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddBenefits from "../../../common/AddBenefits/AddBenefits";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";

export default function GroupsAssignBenefitsHeader() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <TableDatepicker mr="20px" />
      <AddBenefits label={t("employees.add_benefits")} />
    </Box>
  );
}
