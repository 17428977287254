import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      width: 80,
      position: "relative",
      zIndex: 10,
      "&::before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        height: 28,
        width: 50,
        borderRadius: 20,
        zIndex: -1,
      },
      "& .MuiOutlinedInput-input": {
        padding: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
      "& path": {
        fill: Colors.TextElement,
      },
      "& .MuiSelect-iconOutlined": {
        position: "relative",
      },
      "& .text": {
        color: "red",
      },
      "&.Mui-disabled": {
        background: "transparent",
      },
    },
    text: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 50,
      height: 28,
      textTransform: "uppercase",
      borderRadius: 50,
    },
    menuItem: {
      padding: "8px 10px !important",
      justifyContent: "center",
      "& $text": {
        fontWeight: "bold",
      },
    },
    selectMenu: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  })
);
