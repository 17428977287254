import React, { useState, useEffect } from "react";
import {
  IconButton,
  makeStyles,
  Tooltip,
  DialogContent,
  DialogActions,
  Badge,
} from "@material-ui/core";
import { Settings, Close } from "@melp-design/icons";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../../../utils/Dialog";
import DialogExtended from "../../../../../components/common/DialogExtended/DialogExtended";
import { Typography, Button, Alert } from "@melp-design/components";
import { MarketplaceItemsFilters } from "./Types";
import { Colors } from "@melp-design/style";
import ItemsFilters from "./ItemsFilters";
import {
  useLazyBenefitMarketplaceItems,
  useAssignmentFilter,
} from "../../../../../state/MarketplaceItems.clientAdmin";
import { useLoading } from "../../../../../utils/Loading";
import { filtersToParams, paramsToFilters } from "./Utils";
import { toast } from "react-toastify";
import FilterButton from "../../../../../components/filters/FilterButton";
import Filters from "../../../../../components/filters/Filters";
import { MarketplaceItemsParams } from "../../../../../types/MarketplaceItems";
import { convertToFilterQueryParams } from "../../../../../utils/Filters";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  settingsButton: {
    marginRight: 10,
  },
  dialog: {
    transform: "translateY(-100px)",
    width: "100%",
    maxWidth: 650,
  },
  dialogTitle: {
    padding: "30px 30px 10px",
    display: "flex",
    justifyContent: "space-between",
  },
  closeButton: {
    alignSelf: "start",
    marginTop: -10,
    marginRight: -10,
  },
  dialogContent: {
    padding: "0 30px 30px",
    "& > *:not(:last-child)": {
      marginBottom: 20,
    },
  },
  dialogActions: {
    padding: "0 30px 30px",
    "& > *:not(:last-child)": {
      marginRight: 10,
    },
  },
  dialogAction: {
    minWidth: 140,
  },
  settingsIndicator: {
    top: 3,
    right: 3,
    backgroundColor: Colors.primary,
  },
  filtersSection: {
    display: "flex",
    gap: 10,
  },
}));

interface MarketplaceSettingsFilters extends MarketplaceItemsFilters {
  filterAll?: boolean;
}

const toSettingsFilters = (
  params?: MarketplaceItemsParams
): MarketplaceSettingsFilters | undefined => {
  if (!params) {
    return undefined;
  }
  if (Object.keys(params).length === 0) {
    return { filterAll: true };
  }
  return paramsToFilters(params);
};

interface Props {
  benefitId: string;
  onSubmitCompleted: () => void;
  onFilterCreated: () => void;
}

const MarketplaceSettings = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const settingsDialog = useDialog();

  const assignmentFilter = useAssignmentFilter(props.benefitId);

  const paramsFromServer = assignmentFilter.data?.assignmentFilter;
  const filtersFromServer = toSettingsFilters(paramsFromServer);

  const [localFilters, setLocalFilters] = useState<MarketplaceSettingsFilters>(
    {}
  );
  const [hasChanges, setHasChanges] = useState(false);
  const { fetchItems } = useLazyBenefitMarketplaceItems(props.benefitId);
  const [filteredItemsCount, setFilteredItemsCount] = useState<number>();

  useEffect(() => {
    setLocalFilters(filtersFromServer ?? {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFromServer]);

  const resetState = () => {
    setHasChanges(false);
    setFilteredItemsCount(undefined);
  };

  const itemsRequestState = useLoading();
  const handleFiltersChange = async (
    newFilters: MarketplaceSettingsFilters
  ) => {
    // filters were cleared
    if (Object.keys(newFilters).length === 0) {
      setLocalFilters({});
      setHasChanges(!!filtersFromServer);
      setFilteredItemsCount(undefined);
    } else {
      const { filterAll, ...otherFilters } = newFilters;
      let filtersToSet: MarketplaceSettingsFilters;
      if (filterAll !== localFilters.filterAll && filterAll) {
        filtersToSet = { filterAll };
      } else {
        filtersToSet = otherFilters;
      }
      setLocalFilters(filtersToSet);
      setHasChanges(true);
      try {
        itemsRequestState.startLoading();
        const params = filtersToParams(
          filtersToSet.filterAll ? {} : otherFilters
        );
        const response = await fetchItems(params);
        setFilteredItemsCount(response.count);
      } finally {
        itemsRequestState.stopLoading();
      }
    }
  };

  const handleCancel = () => {
    settingsDialog.closeDialog();
    setLocalFilters(filtersFromServer ?? {});
    resetState();
  };

  const handleSubmit = async () => {
    if (!filtersFromServer && localFilters) {
      // create
      const { filterAll, ...otherFilters } = localFilters;
      const paramsToSave = filtersToParams(filterAll ? {} : otherFilters);
      const query = convertToFilterQueryParams(paramsToSave);
      await assignmentFilter.create(undefined, query);
      props.onFilterCreated();
      toast.success(t("marketplace.configCreateSuccess"));
    } else if (filtersFromServer && Object.keys(localFilters).length) {
      // update
      const { filterAll, ...otherFilters } = localFilters;
      const paramsToSave = filtersToParams(filterAll ? {} : otherFilters);
      const query = convertToFilterQueryParams(paramsToSave);
      await assignmentFilter.update(undefined, query);
      toast.success(t("marketplace.configChangeSuccess"), { autoClose: 10000 });
    } else if (filtersFromServer && !Object.keys(localFilters).length) {
      // remove
      await assignmentFilter.remove();
      toast.success(t("marketplace.configRemoveSuccess"), { autoClose: 10000 });
    } else {
      throw Error("Unhandled state");
    }
    settingsDialog.closeDialog();
    resetState();
    props.onSubmitCompleted();
  };

  return (
    <>
      <Tooltip title={t("marketplace.configuration") ?? ""}>
        <span>
          <IconButton
            className={classes.settingsButton}
            onClick={settingsDialog.openDialog}
            disabled={assignmentFilter.loading}
          >
            <Badge
              variant="dot"
              color="primary"
              classes={{
                colorPrimary: classes.settingsIndicator,
              }}
              invisible={!filtersFromServer}
            >
              {assignmentFilter.loading ? (
                <CircularProgress size={20} />
              ) : (
                <Settings />
              )}
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <DialogExtended
        open={settingsDialog.open}
        classes={{ paper: classes.dialog }}
        disableEnforceFocus
      >
        <div className={classes.dialogTitle}>
          <Typography variant="h2">
            {t("marketplace.autoAssignment")}
          </Typography>
          <IconButton className={classes.closeButton} onClick={handleCancel}>
            <Close />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="p1" color="textSecondary">
            {t("marketplace.autoAssignmentDescription")}
          </Typography>
          <div className={classes.filtersSection}>
            <Filters
              value={localFilters}
              onChange={handleFiltersChange}
              hiddenLabel
            >
              {({ value, setFilter }) => (
                <>
                  <FilterButton
                    label={t("marketplace.allItemsFilter")}
                    value={value.filterAll}
                    onChange={(newValue) => setFilter("filterAll", newValue)}
                  />
                  <ItemsFilters
                    filters={localFilters}
                    onFilterChange={setFilter}
                  />
                </>
              )}
            </Filters>
          </div>
          {filteredItemsCount === 0 && !itemsRequestState.loading && (
            <Alert severity="warning">
              {t("marketplace.noItemsFoundForCombination")}
            </Alert>
          )}
        </DialogContent>
        {hasChanges && (
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              textColor="red"
              className={classes.dialogAction}
              onClick={handleCancel}
            >
              {t("marketplace.discardSettings")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.dialogAction}
              onClick={handleSubmit}
              loading={
                itemsRequestState.loading || assignmentFilter.actionInProgress
              }
            >
              {filteredItemsCount
                ? t("marketplace.confirmSettings", {
                    count: filteredItemsCount,
                  })
                : t("common.confirm")}
            </Button>
          </DialogActions>
        )}
      </DialogExtended>
    </>
  );
};

export default MarketplaceSettings;
