import React, { useCallback, useState, useMemo, useContext } from "react";
import { Select, MenuItem, Typography, Box } from "@material-ui/core";
import useStyles from "./StatusChipSelector.styles";
import { useStatusArray } from "../../../constants/Employees";
import { useStatusStyle } from "../../../utils/Style/Style";
import {
  Control,
  Controller,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { useBenefitUtils } from "../../../utils/Benefits";
import moment from "moment";
import FormDialog from "../../dialogs/FormDialog/FormDialog";
import { UpdateBenefitRequest } from "../../../types/Benefits";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../utils/Dialog";
import { useChoicesDeadlineForm } from "../../../configs/Forms/BenefitsForms/Settings/ChoicesDeadlineForm";
import { FlexBenefitContext } from "../../dialogs/StatusDialog/StatusDialogContent/StatusDialogContent";

interface IProps {
  value: string;
  disabled?: boolean;
  control: Control<FieldValues>;
  index: number;
}

export default function StatusChipSelector({
  value,
  disabled,
  control,
  index,
}: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setValue } = useFormContext();

  // Status part hooks
  const [status, setStatus] = useState<string>(value);
  const statusArray = useStatusArray();
  const { selectableStatusClassName, getStatusClass } = useStatusStyle({
    status,
    disabled,
  });

  // Flex setting check hooks
  const {
    updateBenefit,
    benefitPlanLoading,
    benefitPlanDetails,
    hasFlexSettings,
  } = useContext(FlexBenefitContext);
  const { parseChoicesDateForRequest } = useBenefitUtils();
  const flexForm = useChoicesDeadlineForm(true);
  const {
    open: isFlexFormOpen,
    openDialog: openFlexForm,
    closeDialog: closeFlexForm,
  } = useDialog();

  const selectorName = useMemo(() => `statuses[${index}].status`, [index]);

  const handleChange = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
      onChange: (...event: any[]) => void
    ) => {
      const selectValue = e.target.value as string;
      if (selectValue === "flex" && !hasFlexSettings) {
        openFlexForm();
        return;
      }
      onChange(e);
      setStatus(selectValue);
    },
    [hasFlexSettings, openFlexForm]
  );

  const benefitUpdateCallback = useCallback(() => {
    closeFlexForm();
    setValue(selectorName, "flex", { shouldDirty: true });
  }, [closeFlexForm, selectorName, setValue]);

  const handleFlexSettingSubmit = useCallback(
    (data: Partial<UpdateBenefitRequest>) => {
      const parsedData = parseChoicesDateForRequest(data);

      if (!!parsedData.votingStartDate && !!parsedData.votingEndDate) {
        if (
          moment(parsedData.votingEndDate).isBefore(parsedData.votingStartDate)
        ) {
          toast(t("errors.voting_end_date_before_voting_start_date"), {
            type: "error",
          });
          return;
        }
      }

      updateBenefit?.(
        parsedData as UpdateBenefitRequest,
        benefitUpdateCallback,
        true
      );
    },
    [benefitUpdateCallback, parseChoicesDateForRequest, t, updateBenefit]
  );

  const handleFlexFormCancel = useCallback(() => {
    closeFlexForm();
    setValue(selectorName, status, { shouldDirty: true });
  }, [closeFlexForm, selectorName, setValue, status]);

  const benefitName = useMemo(
    () =>
      t("assign_employees.benefit_choices_deadline_not_set")?.replace(
        "{name}",
        benefitPlanDetails?.name ?? ""
      ),
    [benefitPlanDetails?.name, t]
  );

  return (
    <>
      <Box>
        <Controller
          name={selectorName}
          control={control}
          defaultValue={status ?? "on"}
          render={({ value, onChange }) => (
            <Select
              value={value}
              key="status"
              onChange={(e) => handleChange(e, onChange)}
              className={`${classes.chip} ${selectableStatusClassName}`}
              variant="outlined"
              IconComponent={() => null}
              disabled={disabled}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                style: {
                  marginTop: 10,
                },
                classes: {
                  list: classes.selectMenu,
                },
              }}
            >
              {statusArray.map((item, index) => (
                <MenuItem key={index} value={item} className={classes.menuItem}>
                  <Typography
                    variant="subtitle2"
                    className={`${classes.text} ${getStatusClass(item)}`}
                  >
                    {item}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>
      <FormDialog
        onCancelClick={handleFlexFormCancel}
        onSubmitForm={handleFlexSettingSubmit}
        id="change-choices-deadline-form-in-previous-status"
        open={isFlexFormOpen}
        title={benefitName}
        actionLabel={t("common.confirm")}
        form={flexForm}
        subtitle={t("assign_employees.fill_in_fields_to_change_status")}
        loading={benefitPlanLoading ?? false}
      />
    </>
  );
}
