import { useLoading } from "../utils/Loading";
import { Endpoints } from "../api/constants";
import { useMemo, useCallback } from "react";
import { UseTermsAcceptance } from "../types/More";
import useSWR, { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { MpAsyncGetMethod } from "@mp-react/table";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { AxiosResponse } from "axios";
import { TermsAcceptanceFilterNames } from "../constants/TermsAcceptance";
import { mutateData } from "../api/api";
import { MpBulkActionCallback, MpBulkActionMethods } from "@mp-react/table";
import { toast } from "react-toastify";
import { browserLanguage } from "../utils/Common";
import moment from "moment";
import fileDownloader from "js-file-download";

export const useTermsAcceptance = (query?: string): UseTermsAcceptance => {
  const { t } = useTranslation();
  const { loading, startLoading, stopLoading } = useLoading();
  const { data: termsAcceptance, error: termsAcceptanceError } = useSWR(
    `${Endpoints.termsAcceptance}${!!query ? `?${query}` : ""}`
  );

  const apiLoading = useMemo(() => {
    return !termsAcceptance && !termsAcceptanceError;
  }, [termsAcceptance, termsAcceptanceError]);

  const parsedTermsAcceptanceList = useMemo(
    () =>
      termsAcceptance?.data?.map((data: any) => ({
        ...data,
        id: `${data.employeeId}${data.benefitPlanId}`,
      })),
    [termsAcceptance]
  );

  const parsedTermsAcceptanceTotals = useMemo(() => {
    const initialTotals = {
      employeeName: "",
      benefitPlanName: "",
      benefitPlanType: "",
    };
    if (!termsAcceptance?.footer) return initialTotals;
    const { employeeName, benefitPlanName, benefitPlanType } =
      termsAcceptance.footer;
    return {
      employeeName: `${employeeName} ${
        Number(employeeName) > 1
          ? t("permissions.employeeModule")
          : t("choices.employee")
      }`,
      benefitPlanName: `${benefitPlanName} ${
        Number(benefitPlanName) > 1
          ? t("menu.benefits")
          : t("entity_type.benefitPlan")
      }`,
      benefitPlanType: `${benefitPlanType} ${t(
        `benefits.${
          Number(benefitPlanType) > 1 ? "benefit_types" : "benefit_type"
        }`
      )}`,
    };
  }, [termsAcceptance, t]);

  const updateStatus = useCallback(
    (status: boolean, benefitPlanTCAcceptances) => {
      startLoading();
      const requestData = {
        status: status,
        benefitPlanTCAcceptances: benefitPlanTCAcceptances,
      };
      mutateData(
        "patch",
        `${Endpoints.termsAcceptance}/bulkAction`,
        requestData
      )
        .then(() => {
          toast(t("common.assigned_succesfully"), { type: "success" });
          mutate(Endpoints.termsAcceptance);
        })
        .finally(() => stopLoading());
    },
    [startLoading, stopLoading, t]
  );

  const statusAccepted: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const benefitPlanTCAcceptances = selectedRows?.map((item) => {
        const { original } = item as any;
        return {
          employeeId: original.employeeId,
          benefitPlanId: original.benefitPlanId,
        };
      });
      updateStatus(true, benefitPlanTCAcceptances);
    },
    [updateStatus]
  );

  const statusNotAccepted: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const benefitPlanTCAcceptances = selectedRows?.map((item) => {
        const { original } = item as any;
        return {
          employeeId: original.employeeId,
          benefitPlanId: original.benefitPlanId,
        };
      });
      updateStatus(false, benefitPlanTCAcceptances);
    },
    [updateStatus]
  );

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      statusAccepted,
      statusNotAccepted,
    }),
    [statusAccepted, statusNotAccepted]
  );

  const exportToExcel = useCallback(() => {
    startLoading();
    const url = `${Endpoints.termsAcceptanceExportToExcel}${
      !!query ? `?${query}` : ""
    }`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    return mutateData("get", url, null, headers, "arraybuffer")
      .then((res: any) => {
        const now = moment().locale(browserLanguage).format("L");
        const disposition = res?.headers["content-disposition"];
        const headersFilename = disposition?.split("filename=")?.[1];
        const filename = headersFilename ?? `Terms_acceptance_${now}.xlsx`;
        fileDownloader(res.data, filename);
      })
      .finally(() => {
        stopLoading();
      });
  }, [query, startLoading, stopLoading]);

  return {
    loading: loading || apiLoading,
    termsAcceptance,
    termsAcceptanceError,
    parsedTermsAcceptanceList,
    parsedTermsAcceptanceTotals,
    bulkMethods,
    exportToExcel,
  };
};

export const useTermsAcceptanceAsyncMethods = (
  employeeId?: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(() => `${Endpoints.termsAcceptanceFilterValues}`, []);

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: TermsAcceptanceFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const filterById = !!employeeId
        ? `&filter[employeeId]=${employeeId}`
        : "";
      const apiUrl = `${baseUrl}/${filterName}${searchParam}${filterById}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, employeeId]
  );

  const getFilterItems = useCallback(
    (filterName: TermsAcceptanceFilterNames) => {
      const filterById = !!employeeId
        ? `?filter[employeeId]=${employeeId}`
        : "";
      const apiUrl = `${baseUrl}/${filterName}${filterById}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, employeeId]
  );

  const getEmployeeNames = useCallback<MpAsyncGetMethod>(
    (args) =>
      getAsyncFilterItems(args, TermsAcceptanceFilterNames.EMPLOYEE_NAME),
    [getAsyncFilterItems]
  );

  const getBenefitPlanName = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(TermsAcceptanceFilterNames.BENEFIT_PLAN_NAME),
    [getFilterItems]
  );

  const getBenefitPlanType = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(TermsAcceptanceFilterNames.BENEFIT_PLAN_TYPE),
    [getFilterItems]
  );

  return {
    getEmployeeNames,
    getBenefitPlanName,
    getBenefitPlanType,
  };
};
