import { Box } from "@material-ui/core";
import { MpTable } from "@mp-react/table";
import React, { useCallback, useState, useMemo, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, UseRowSelectRowProps } from "react-table";
import { useEmployeeInnerAssignBenefitsTable } from "../../../../../configs/Tables/EmployeeInnerAssignBenefits";
import { bulkMethods } from "../../../../../utils/TableMethods";
import useTableUtils from "../../../../../utils/Table";
import { MpRowActionParameters } from "@mp-react/table";
import CurrentStatusDialog from "../../../../../components/dialogs/CurrentStatusDialog/CurrentStatusDialog";
import FormFooter from "../../../../../components/layouts/FormFooter/FormFooter";
import AssignBenefitsFooterButtons from "../../../../../components/employees/AssignBenefitsFooterButtons/AssignBenefitsFooterButtons";
import { useTranslation } from "react-i18next";
import { UnpackNestedValue } from "react-hook-form";
import {
  useEmployeeAssignBenefits,
  useEmployeeAssignBenefitsAsyncMethods,
} from "../../../../../state/EmployeeAssignBenefits";
import { InnerEmployeeContext } from "../../EmployeesInner";
import useEmployeeGroups /*, {
  useEmployeeGroupStore,
}*/ from "../../../../../state/EmployeeGroups";
import {
  BenefitStatus,
  BenefitTypes,
  UpdateBenefitRequest,
} from "../../../../../types/Benefits";
import { toast } from "react-toastify";
import { useBenefits, useBenefitStore } from "../../../../../state/Benefits";
import { useDialog } from "../../../../../utils/Dialog";
import { useChoicesDeadlineForm } from "../../../../../configs/Forms/BenefitsForms/Settings/ChoicesDeadlineForm";
import FormDialog from "../../../../../components/dialogs/FormDialog/FormDialog";
import moment from "moment";
import { useInvestmentCalculationsStore } from "../../../../../state/InvestmentCalculations";
import {
  CheckAssignBaseResponse,
  CheckAssignConflictsResponse,
  Conflict,
  Investment,
  InvestmentTypes,
} from "../../../../../types/Common";
import { EmployeeAssignBenefitsTableItem } from "../../../../../types/Employees";
import useStyles from "../../../../../styles/Table.styles";
import { useMe } from "../../../../../state/Administrators";
import { useBenefitUtils } from "../../../../../utils/Benefits";
import { TableContext } from "../../../../../state/Table";
// import { useAssignGroupToEmployee } from "../../../../../utils/AssignGroupToEmployee";

// TODO: remove useAssignGroupToEmployee and useEmployeeGroupStore if InnerEmployeeAssignBenefits > EmployeeGroup is not needed anymore

export default function AssignBenefits() {
  const classes = useStyles();
  const { canEditBenefits, hasBenefitPermissions, canEditEmployees } = useMe();
  const { id } = useParams() as any;
  const employeeData = useContext(InnerEmployeeContext);
  const {
    overridables,
    overrideClasses,
    translations,
    getCurrentStatusBoolean,
    handleGetData,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
    isRowInvestmentAdded,
  } = useTableUtils("innerEmployeeAssignBenefits");
  const [open, setOpen] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<string>("");
  const [changeResponse, setChangeResponse] =
    useState<CheckAssignConflictsResponse>();
  const [value, setValue] = useState<string>("");
  const { t } = useTranslation();
  const history = useHistory();
  const AssignBenefitsTableType = useEmployeeInnerAssignBenefitsTable();
  const { removeEmployeeFromGroups /* addRemoveGroupstoEmployee */ } =
    useEmployeeGroups();
  const [isCurrentStatusBoolean, setCurrentStatusBoolean] = useState<
    boolean | null
  >(false);
  const {
    assignBenefitsData,
    mapAssignBenefitsRequest,
    assignBenefits,
    parsedEmployeeAssignBenefitsTotals,
    loading,
    checkAssignEmployeeChanges,
    mutateAssignBenefitsTable,
  } = useEmployeeAssignBenefits(id, tableQueryParam);
  const {
    updateBenefit,
    loading: loadingBenefits,
    checkBenefitPlanSettings,
  } = useBenefits(selectedRow);
  const {
    closeDialog: closeFlexForm,
    open: isFlexFormOpen,
    openDialog: openFlexForm,
  } = useDialog();
  const asyncGetMethods = useEmployeeAssignBenefitsAsyncMethods(id);
  const flexForm = useChoicesDeadlineForm(true);
  // const { assignEmployeeGroupIds, removeEmployeeGroupIds, setReset } =
  //   useEmployeeGroupStore();

  // const { resetEmployeeGroupId } = useAssignGroupToEmployee();
  const { parseChoicesDateForRequest } = useBenefitUtils();

  const { votingStartDate, votingEndDate } = useBenefitStore(
    useCallback(({ votingEndDate, votingStartDate }) => {
      return { votingEndDate, votingStartDate };
    }, [])
  );
  const { statuses } = useInvestmentCalculationsStore();

  const resetAllChanges = useInvestmentCalculationsStore(
    useCallback((state) => state.resetAllChanges, [])
  );
  const setStatus = useInvestmentCalculationsStore(
    useCallback((state) => state.setStatus, [])
  );
  const increaseChangeCount = useInvestmentCalculationsStore(
    useCallback((state) => state.increaseChangeCount, [])
  );
  const setInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.setInvestments, [])
  );
  const removeChange = useInvestmentCalculationsStore(
    useCallback((state) => state.removeChange, [])
  );
  const resetInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.resetInvestments, [])
  );

  const getSelectedRowData = useCallback(
    (selectedRow: string) => {
      const data =
        assignBenefitsData?.find((item) => item.id === selectedRow) ??
        ({} as EmployeeAssignBenefitsTableItem);
      return data;
    },
    [assignBenefitsData]
  );

  const selectedRowData = useMemo(
    () => assignBenefitsData.filter((item) => item.id === selectedRow)[0],
    [assignBenefitsData, selectedRow]
  );

  const selectedBenefitName = useMemo(
    () => selectedRowData?.fullName ?? "",
    [selectedRowData?.fullName]
  );

  const employeeId = useMemo(() => employeeData?.id ?? "", [employeeData?.id]);

  const employeeName = useMemo(
    () =>
      employeeData?.firstName && employeeData?.lastName
        ? `${employeeData?.firstName} ${employeeData?.lastName}`
        : "",
    [employeeData?.firstName, employeeData?.lastName]
  );

  const handleRowClick = useCallback(
    (current: Row<{}> & UseRowSelectRowProps<{}>) => {
      if (hasBenefitPermissions) history.push(`/benefits/view/${current.id}`);
    },
    [hasBenefitPermissions, history]
  );

  const toggleStatus = useCallback(
    async (current: MpRowActionParameters) => {
      const { value: newStatus, rowId: benefitPlanId } = current;
      const selectedRowData = getSelectedRowData(benefitPlanId as string);
      const initialStatus = selectedRowData?.currentStatus as BenefitStatus;
      if (initialStatus === newStatus || !newStatus || !benefitPlanId) return;

      setSelectedRow(benefitPlanId as string);
      setValue(newStatus as string);

      const assignmentData = await checkAssignEmployeeChanges({
        newStatus: newStatus as "flex" | "on" | "off",
        employeeId: id as string,
        benefitPlanId: benefitPlanId as string,
      });
      setChangeResponse(assignmentData);

      if (newStatus === "flex") {
        const hasFlexSettings = await checkBenefitPlanSettings(
          benefitPlanId as string
        );
        if (!hasFlexSettings) {
          openFlexForm();
          setStatus(null, benefitPlanId);
          return;
        }
      }

      const statusBoolean = getCurrentStatusBoolean(newStatus, initialStatus);
      setCurrentStatusBoolean(statusBoolean);

      if (assignmentData.conflicts.length === 0) {
        const investmentData: CheckAssignBaseResponse & Investment = {
          id: benefitPlanId as string,
          totalInvestmentAmount: assignmentData.totalInvestmentAmount,
          investmentAmountChange: assignmentData.investmentAmountChange,
          added: isRowInvestmentAdded(
            initialStatus,
            newStatus as BenefitStatus
          ),
          investment: assignmentData.investmentAmountChange,
          name: selectedRowData?.name ?? "",
          type: InvestmentTypes.benefits,
          benefitType: selectedRowData?.type as BenefitTypes,
        };
        setInvestments(investmentData);
        increaseChangeCount(benefitPlanId);
      } else {
        setOpen(true);
      }
    },
    [
      checkAssignEmployeeChanges,
      checkBenefitPlanSettings,
      getCurrentStatusBoolean,
      getSelectedRowData,
      id,
      increaseChangeCount,
      isRowInvestmentAdded,
      openFlexForm,
      setInvestments,
      setStatus,
    ]
  );

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    setStatus(null, selectedRow);
    removeChange(selectedRow);
  }, [removeChange, selectedRow, setStatus]);

  const handleCancelFooter = useCallback(() => {
    resetAllChanges();
    setStatus(null);
    resetInvestments();
    // setReset(true);
    // resetEmployeeGroupId();
  }, [
    resetAllChanges,
    resetInvestments,
    setStatus,
    // setReset,
    // resetEmployeeGroupId,
  ]);

  const handlePublishFooter = useCallback(
    (date?: string) => {
      if (Object.keys(statuses).length !== 0) {
        const requestData = mapAssignBenefitsRequest(date);
        assignBenefits(requestData);
      }
      // assignEmployeeGroupIds?.map((groupId) =>
      //   addRemoveGroupstoEmployee(groupId, id, "assignToGroup")
      // );
      // removeEmployeeGroupIds?.map((groupId) =>
      //   addRemoveGroupstoEmployee(groupId, id, "removeFromGroup")
      // );
      resetAllChanges();
    },
    [
      assignBenefits,
      mapAssignBenefitsRequest,
      // assignEmployeeGroupIds,
      // removeEmployeeGroupIds,
      // id,
      // addRemoveGroupstoEmployee,
      statuses,
      resetAllChanges,
    ]
  );

  const handleGroupChange = useCallback(
    (isChanged: boolean) => {
      setOpen(false);
      if (!isChanged) {
        removeChange(selectedRow);
        setStatus(null, selectedRow);
        const toastText = t(
          "assign_employees.did_not_remove_from_groups"
        )?.replace("{name}", employeeName);
        toast(toastText, { type: "error" });
        return;
      }
    },
    [removeChange, selectedRow, setStatus, t, employeeName]
  );

  const handleFlexSettingSubmit = useCallback(
    (data: Partial<UpdateBenefitRequest>) => {
      if (!!votingStartDate && !!votingEndDate) {
        if (moment(votingEndDate).isBefore(votingStartDate)) {
          toast(t("errors.voting_end_date_before_voting_start_date"), {
            type: "error",
          });
          return;
        }
        data.votingStartDate = votingStartDate;
        data.votingEndDate = votingEndDate;
      }

      const parsedData = parseChoicesDateForRequest(data);
      const initialStatus = selectedRowData?.currentStatus as BenefitStatus;

      updateBenefit(parsedData as UpdateBenefitRequest, () => {
        closeFlexForm();
        increaseChangeCount(selectedRowData?.id ?? "");
        setValue("flex");
        const statusValues = {
          currentStatus: { [selectedRowData?.id ?? ""]: { value: "flex" } },
        };
        setStatus(statusValues);
        if (!changeResponse) return;
        const investmentData: CheckAssignBaseResponse & Investment = {
          id: selectedRow as string,
          totalInvestmentAmount: changeResponse?.totalInvestmentAmount,
          investmentAmountChange: changeResponse?.investmentAmountChange,
          added: isRowInvestmentAdded(initialStatus, BenefitStatus.flex),
          investment: changeResponse?.investmentAmountChange,
          name: selectedRowData?.name ?? "",
          type: InvestmentTypes.benefits,
          benefitType: selectedRowData?.type as BenefitTypes,
        };
        setInvestments(investmentData);
      });
    },
    [
      changeResponse,
      closeFlexForm,
      increaseChangeCount,
      isRowInvestmentAdded,
      parseChoicesDateForRequest,
      selectedRow,
      selectedRowData?.currentStatus,
      selectedRowData?.id,
      selectedRowData?.name,
      selectedRowData?.type,
      setInvestments,
      setStatus,
      t,
      updateBenefit,
      votingEndDate,
      votingStartDate,
    ]
  );

  const handleFlexFormCancel = useCallback(() => {
    closeFlexForm();
    setStatus(null, selectedRow);
    removeChange(selectedRow);
  }, [closeFlexForm, removeChange, selectedRow, setStatus]);

  const removeConflictedGroups = useCallback(
    async (conflicts: Conflict[]) => {
      const employeeIds = [employeeId];
      let count = 0;
      conflicts?.map(async (conflict, index) => {
        const result: unknown = await removeEmployeeFromGroups(
          conflict.employeeGroupId,
          employeeIds
        );
        count++;
        if (!(result as boolean)) {
          setTableLoading(false);
          handleFlexFormCancel();
        } else if (count === conflicts?.length) {
          await mutateAssignBenefitsTable();
          setTableLoading(false);
        }
      });
    },
    [
      employeeId,
      handleFlexFormCancel,
      mutateAssignBenefitsTable,
      removeEmployeeFromGroups,
    ]
  );

  const handleAdd = useCallback(
    async (
      data: UnpackNestedValue<Partial<CheckAssignConflictsResponse>>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      if (!data?.conflicts || data?.conflicts.length === 0) return;
      setTableLoading(true);
      await removeConflictedGroups(data.conflicts);
      toast(t("common.updated_succesfully"), { type: "success" });
      increaseChangeCount(selectedRowData?.id ?? "");
      if (!changeResponse) return;
      const investmentData: CheckAssignBaseResponse & Investment = {
        id: selectedRow as string,
        totalInvestmentAmount: changeResponse?.totalInvestmentAmount,
        investmentAmountChange: changeResponse?.investmentAmountChange,
        added: changeResponse?.investmentAmountChange > 0 ?? false,
        investment: changeResponse?.investmentAmountChange,
        name: selectedRowData?.name ?? "",
        type: InvestmentTypes.benefits,
        benefitType: selectedRowData?.type as BenefitTypes,
      };
      setInvestments(investmentData);
    },
    [
      changeResponse,
      increaseChangeCount,
      selectedRow,
      selectedRowData?.id,
      selectedRowData?.name,
      selectedRowData?.type,
      setInvestments,
      t,
      removeConflictedGroups,
    ]
  );

  return (
    <TableContext.Provider value={{ id, tableQueryParam }}>
      <Box
        className={
          canEditBenefits && canEditEmployees ? "" : classes.disableTable
        }
      >
        <MpTable
          {...AssignBenefitsTableType}
          data={assignBenefitsData}
          onGetData={handleGetData}
          bulkMethods={bulkMethods}
          overridables={overridables}
          totalsData={parsedEmployeeAssignBenefitsTotals}
          totals={assignBenefitsData?.length > 0}
          stickyTotals={true}
          disablePagination={true}
          classes={overrideClasses}
          pageSize={20}
          loading={loading || tableLoading}
          enableGlobalActions={true}
          rowMethods={{ toggleStatus }}
          translations={translations}
          onRowClick={handleRowClick as any}
          loadingComponent={loadingComponent}
          emptyChildren={noDataComponent}
          asyncGetMethods={asyncGetMethods}
        />
        <CurrentStatusDialog
          onCancelClick={handleCloseDialog}
          onSubmitForm={handleAdd}
          id="set-current-status"
          open={open}
          title={t(
            "assign_employees.remove_from_groups_to_make_changes"
          )?.replace("{employee}", employeeName)}
          actionLabel={t("common.confirm")}
          statusValue={value}
          statusBoolean={isCurrentStatusBoolean as any}
          onGroupsChange={handleGroupChange}
          benefitName={selectedBenefitName}
          employeeName={employeeName}
          changeResponse={changeResponse}
        />
        <FormDialog
          onCancelClick={handleFlexFormCancel}
          onSubmitForm={handleFlexSettingSubmit}
          id="change-choices-deadline-form"
          open={isFlexFormOpen}
          title={t(
            "assign_employees.benefit_choices_deadline_not_set"
          )?.replace("{name}", selectedBenefitName)}
          actionLabel={t("common.confirm")}
          form={flexForm}
          subtitle={t("assign_employees.fill_in_fields_to_change_status")}
          loading={loadingBenefits}
        />
        <FormFooter withChanges>
          <AssignBenefitsFooterButtons
            onCancel={handleCancelFooter}
            onPublish={handlePublishFooter}
          />
        </FormFooter>
      </Box>
    </TableContext.Provider>
  );
}
