import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { BorderRadius, Colors, Shadows } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "capitalize",
      padding: "7px 20px",
      borderRadius: BorderRadius.MainRadius,
      background: theme.palette.primary.dark,
      color: Colors.White,
    },
    buttonInner: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "pre",
    },
    buttonIcon: {
      marginLeft: theme.spacing(1),
    },
    vAlignCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "14px 22px 12px 20px",
      height: 49,
    },
    paperRoot: {
      minWidth: 220,
      boxShadow: Shadows.PaperElevation8,
      paddingTop: 8,
      paddingBottom: 8,
      marginTop: 10,
    },
  })
);
