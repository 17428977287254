import { useMemo } from "react";
import AssignGroups from "../containers/Benefits/BenefitsInner/views/AssignGroups/AssignGroups";
import BenefitSettings from "../containers/Benefits/BenefitsInner/views/BenefitSettings/BenefitSettings";
import { FormTab, SelectOption } from "../types/Common";
import { BenefitTab } from "../types/Benefits";
import { StatusActionKey } from "../types/Status";
import AssignEmployees from "../containers/Benefits/BenefitsInner/views/AssignEmployees/AssignEmployees";
import BenefitsChoices from "../containers/Benefits/BenefitsInner/views/BenefitsChoices/BenefitsChoices";
import TermsAcceptance from "../containers/Benefits/BenefitsInner/views/TermsAcceptance/TermsAcceptance";
import { useMe } from "../state/Administrators";
import Marketplace from "../containers/Benefits/BenefitsInner/views/Marketplace/Marketplace";
import { useFeatureToggle } from "../utils/FeatureToggle/index";
import { FeatureKey } from "../utils/FeatureToggle/Types";
import {
  GlobalPermissionModules,
  LocalPermissionModules,
} from "./Administrators";

export const useBenefitSidebarActions = (
  hasInvestment: boolean
): StatusActionKey[] => {
  const requiredActions: StatusActionKey[] = useMemo(() => {
    if (!hasInvestment) return [];
    return ["change_benefit_type", "make_copy", "rename_benefit"];
  }, [hasInvestment]);

  return useMemo(
    () => [...requiredActions, "remove_all_employees", "delete"],
    [requiredActions]
  );
};

export const useBenefitTabs = (): BenefitTab[] => {
  const ft = useFeatureToggle(FeatureKey.marketplace);
  const isActive = ft.isFeatureEnabled();
  return useMemo(
    () =>
      [
        {
          key: "benefitsSettings",
          component: BenefitSettings,
          module: GlobalPermissionModules.Benefits,
        },
        {
          key: "assignGroups",
          component: AssignGroups,
          module: GlobalPermissionModules.EmployeeGroups,
        },
        {
          key: "benefitsAssignEmployees",
          translationKey: "assignEmployees",
          component: AssignEmployees,
          module: LocalPermissionModules.Employees,
        },
        {
          key: "marketplace",
          translationKey: "marketplace",
          component: Marketplace,
        },
        {
          key: "benefitsChoices",
          translationKey: "choices",
          component: BenefitsChoices,
          module: GlobalPermissionModules.FlexBenefit,
        },
        {
          key: "benefitsTermsAcceptance",
          translationKey: "termsAcceptance",
          component: TermsAcceptance,
          module: LocalPermissionModules.Employees,
        },
      ].filter(({ key }) => key !== "marketplace" || isActive),
    [isActive]
  );
};

export const useBenefitFormTabs = (): FormTab[] => {
  const { me } = useMe();

  const supportedLanguages = useMemo(
    () =>
      me?.parentCompany.supportedLanguages.map((lang) => ({
        key: lang,
        label: `language.${lang}`,
      })) ?? [],
    [me?.parentCompany.supportedLanguages]
  );

  return useMemo(
    () => [
      { key: "settings", label: "common.settings" },
      ...supportedLanguages,
    ],
    [supportedLanguages]
  );
};

export const useBenefitTypes = (): SelectOption[] =>
  useMemo(
    () => [
      { value: "wellness", name: "menu.wellness" },
      { value: "insurances", name: "menu.insurances" },
      { value: "perks", name: "menu.perks" },
      { value: "pensions", name: "menu.pensions" },
      { value: "timeOff", name: "menu.time_off" },
      { value: "education", name: "menu.education" },
      {
        value: "generalEmployeeWelfare",
        name: "menu.general_employee_welfare",
      },
      { value: "companyDiscounts", name: "menu.company_discounts" },
      { value: "partnerDiscounts", name: "menu.partner_discounts" },
    ],
    []
  );

export const useAddBenefits = (): string[] =>
  useMemo(
    () => [
      "insurances",
      "wellness",
      "perks",
      "pensions",
      "timeOff",
      "education",
      "generalEmployeeWelfare",
      "companyDiscounts",
      "partnerDiscounts",
    ],
    []
  );

export enum BenefitFilterNames {
  BENEFIT_PLAN_NAME = "name",
  TYPE = "type",
}

export enum BenefitTermsAcceptanceFilterNames {
  EMPLOYEE_NAME = "employeeName",
}

export enum BenefitAssignEmployeesFilterNames {
  FULL_NAME = "fullName",
  EMPLOYEE_GROUPS = "employeeGroup",
  CUSTOM_COLUMN = "customColumn",
}

export enum BenefitChoisesFilterNames {
  EMPLOYEE_NAME = "employeeName",
}

export enum BenefitAssignGroupsFilterNames {
  EMPLOYEE_GROUP_NAME = "name",
}
