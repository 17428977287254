import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceByElement } from "../../../../../utils/Logs";
import { Log } from "../../../../../types/Logs";

export default function BenefitCreated(props: Partial<Log>) {
  const { benefitPlan } = props;
  const { t } = useTranslation();

  return useMemo(
    () =>
      replaceByElement(
        t("log.new_benefit_was_created"),
        "{benefitName}",
        benefitPlan ?? ""
      ),
    [benefitPlan, t]
  );
}
