import React, { createContext, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import TabView from "../../../components/common/TabView/TabView";
import SidebarLayout from "../../../components/layouts/Sidebar/SidebarLayout";
import SidebarContainer from "../../../components/layouts/Sidebar/SidebarContainer/SidebarContainer";
import ContentContainer from "../../../components/layouts/Sidebar/SidebarContent/SidebarContent";
import {
  useGroupsTabs,
  useGroupSidebarActions,
} from "../../../constants/Groups";
import SidebarInner from "../../../components/layouts/Sidebar/SidebarInner/SidebarInner";
import Title from "../../../components/sidebar/Title/Title";
import Tabs from "../../../components/sidebar/Tabs/Tabs";
import Tab from "../../../components/sidebar/Tab/Tab";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Actions from "../../../components/sidebar/Actions/Actions";
import useTabs from "../../../utils/Tabs";
import useEmployeeGroups from "../../../state/EmployeeGroups";
import { useInnerGroupAssignEmployee } from "../../../state/InnerGroupAssignEmployee";
import { EmployeeGroups } from "../../../types/EmployeeGroups";
import { useDialog } from "../../../utils/Dialog";
import SubmitActionDialog from "../../../components/dialogs/SubmitActionDialog/SubmitActionDialog";
import AlertDialog from "../../../components/dialogs/AlertDialog/AlertDialog";
import SingleFieldDialog from "../../../components/dialogs/SingleFieldDialog/SingleFieldDialog";
import { useMe } from "../../../state/Administrators";
import { toast } from "react-toastify";
import useTableUtils from "../../../utils/Table";
import { HeaderVariant } from "../../../state/Table";

export const GroupContext = createContext<Partial<EmployeeGroups | undefined>>(
  {}
);

interface IProps {}

export default function GroupsInner(props: IProps) {
  const { canEditGroups } = useMe();
  const { id } = useParams() as any;
  const tabs = useGroupsTabs();
  const actions = useGroupSidebarActions();
  const { t } = useTranslation();

  const {
    employeeGroups,
    deleteEmployeeGroup,
    updateEmployeeGroup,
    copyEmployeeGroup,
  } = useEmployeeGroups();

  const { changeTab, currentTab } = useTabs(tabs[0].key as string);

  const { tableQueryParam: employeesTableQuery } = useTableUtils(
    currentTab as HeaderVariant
  );
  const { removeAllEmployees } = useInnerGroupAssignEmployee();

  const {
    closeDialog: closeActiveEmployeesDialog,
    open: activeEmployeesOpen,
    openDialog: openActiveEmployeesDialog,
  } = useDialog();
  const {
    closeDialog: closeDeleteGroupDialog,
    open: deleteGroupOpen,
    openDialog: openDeleteGroupDialog,
  } = useDialog();
  const {
    closeDialog: closeRenameGroupDialog,
    open: renameGroupOpen,
    openDialog: openRenameGroupDialog,
  } = useDialog();
  const {
    closeDialog: closeCopyGroupDialog,
    open: copyGroupOpen,
    openDialog: openCopyGroupDialog,
  } = useDialog();
  const {
    closeDialog: closeRemoveAllEmployeesDialog,
    open: removeAllEmployeesOpen,
    openDialog: openRemoveAllEmployeesDialog,
  } = useDialog();

  const selectedRowData: EmployeeGroups | undefined = useMemo(
    () => employeeGroups?.data.find((item: EmployeeGroups) => item.id === id),
    [id, employeeGroups]
  );

  const hasActiveEmployees: boolean = useMemo(() => {
    if (!selectedRowData?.employeeCount) return false;
    return selectedRowData.employeeCount > 0;
  }, [selectedRowData?.employeeCount]);

  const currentTabData = useMemo(
    () => tabs.filter((tab) => tab.key === currentTab)[0],
    [tabs, currentTab]
  );

  const defaultNameValue = useMemo(
    () => selectedRowData?.name,
    [selectedRowData?.name]
  );

  const allEmployeesGroup = useMemo(
    () => selectedRowData?.order,
    [selectedRowData?.order]
  );

  const handleEmployeeGroupDelete = useCallback(() => {
    if (!id) return;
    deleteEmployeeGroup(id);
  }, [deleteEmployeeGroup, id]);

  const handleRenameEmployeeGroup = useCallback(
    (value: string) => {
      if (!id) return;
      updateEmployeeGroup(id, value);
      closeRenameGroupDialog();
    },
    [updateEmployeeGroup, id, closeRenameGroupDialog]
  );

  const handleRemoveAllEmployees = useCallback(() => {
    removeAllEmployees(id, employeesTableQuery);
    closeRemoveAllEmployeesDialog();
  }, [
    employeesTableQuery,
    removeAllEmployees,
    id,
    closeRemoveAllEmployeesDialog,
  ]);

  const handleCopyEmployeeGroup = useCallback(
    (value: string) => {
      if (!id) return;
      copyEmployeeGroup(id, value);
      closeCopyGroupDialog();
    },
    [id, copyEmployeeGroup, closeCopyGroupDialog]
  );

  const handleSidebarAction = useCallback(
    (action: string) => {
      switch (action) {
        case "delete":
          if (hasActiveEmployees) {
            openActiveEmployeesDialog();
          } else {
            openDeleteGroupDialog();
          }
          break;
        case "rename_group":
          return openRenameGroupDialog();
        case "remove_all_employees":
          return openRemoveAllEmployeesDialog();
        case "make_copy":
          return openCopyGroupDialog();
        default:
          break;
      }
    },
    [
      hasActiveEmployees,
      openRenameGroupDialog,
      openRemoveAllEmployeesDialog,
      openCopyGroupDialog,
      openActiveEmployeesDialog,
      openDeleteGroupDialog,
    ]
  );

  const handleTitleEdit = useCallback(
    async (value: string) => {
      if (!id) {
        toast("Group ID missing", { type: "error" });
        return false;
      }
      const isSuccess = await updateEmployeeGroup(id, value);
      return isSuccess;
    },
    [updateEmployeeGroup, id]
  );

  return (
    <SidebarLayout>
      <GroupContext.Provider value={selectedRowData}>
        <SidebarContainer>
          <SidebarInner>
            <Title
              title={selectedRowData?.name}
              onEditComplete={handleTitleEdit}
              editable={canEditGroups}
            />
            <Tabs onChange={changeTab} value={currentTab}>
              {!allEmployeesGroup ? (
                <Tab
                  key={`tab-${tabs[0].key}`}
                  label={
                    <Typography variant="body2" color="textSecondary">
                      {t(`sidebar.${tabs[0]?.translationKey ?? tabs[0].key}`)}
                    </Typography>
                  }
                  value={tabs[0].key}
                  module={tabs[0]?.module}
                />
              ) : (
                tabs.map((tab) => (
                  <Tab
                    key={`tab-${tab.key}`}
                    label={
                      <Typography variant="body2" color="textSecondary">
                        {t(`sidebar.${tab?.translationKey ?? tab.key}`)}
                      </Typography>
                    }
                    value={tab.key}
                    module={tab?.module}
                  />
                ))
              )}
            </Tabs>
            {canEditGroups && !!allEmployeesGroup && (
              <Actions onActionClick={handleSidebarAction} actions={actions} />
            )}
          </SidebarInner>
        </SidebarContainer>
        <ContentContainer>
          <TabView
            key={currentTabData.key}
            index={currentTabData.key}
            value={currentTab}
          >
            <currentTabData.component />
          </TabView>
        </ContentContainer>
        <SubmitActionDialog
          open={deleteGroupOpen}
          id="delete-employee-group-dialog"
          title={t("company_groups.sure_to_delete_group")?.replace(
            "{name}",
            selectedRowData?.name ?? ""
          )}
          onSubmit={handleEmployeeGroupDelete}
          actionLabel={t("actions.delete")}
          onCancelClick={closeDeleteGroupDialog}
          error
        />
        <AlertDialog
          onSubmit={closeActiveEmployeesDialog}
          id="cannot-delete-dialog"
          title={t("groups.group_cannot_be_deleted")}
          open={activeEmployeesOpen}
        />
        <SingleFieldDialog
          onCancelClick={closeRenameGroupDialog}
          onActionClick={handleRenameEmployeeGroup}
          id="rename_group_dialog"
          open={renameGroupOpen}
          title={t("groups.edit_group_name")}
          actionLabel={t("common.confirm")}
          placeholder={t("groups.group_name")}
          defaultValue={defaultNameValue ?? ""}
        />
        <SingleFieldDialog
          onCancelClick={closeCopyGroupDialog}
          onActionClick={handleCopyEmployeeGroup}
          id="make_copy"
          open={copyGroupOpen}
          title={t("groups.create_group_name")}
          actionLabel={t("common.confirm")}
          placeholder={t("groups.group_name")}
          defaultValue={`${defaultNameValue} ${t("common.copy")}`}
        />
        <SubmitActionDialog
          onCancelClick={closeRemoveAllEmployeesDialog}
          title={t("groups.remove_all_employees_from_group")}
          subtitle={t("common.action_cannot_be_undone")}
          id="removeAllEmployees"
          open={removeAllEmployeesOpen}
          onSubmit={handleRemoveAllEmployees}
          actionLabel={t("common.remove")}
          error
        />
      </GroupContext.Provider>
    </SidebarLayout>
  );
}
