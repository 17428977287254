import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: 90,
      paddingRight: 90,
      minHeight: 80,
      display: "flex",
      justifyContent: "center",
    },
    itemContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: 1260,
    },
    vAlignCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  })
);
