import React from "react";
import { Typography, Link } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import { getUploadedFile } from "../../../state/Files";
import { useCallback } from "react";

export default function Pdf(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);

  const handleClick = useCallback(async () => {
    const file = await getUploadedFile(value);
    window.open(file.signedUrl, "_blank");
  }, [value]);

  return (
    <Link onClick={handleClick} style={{ cursor: "pointer" }}>
      <Typography
        variant="body2"
        style={{ textDecoration: "underline" }}
        color="textPrimary"
      >
        PDF
      </Typography>
    </Link>
  );
}
