import { Box } from "@material-ui/core";
import CustomizeColumnButton from "../../CustomizeColumnButton/CustomizeColumnButton";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import TableSearch from "../../TableSearch/TableSearch";

export default function InnerGroupAssignEmployeesHeaders() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
      height="46px"
    >
      <CustomizeColumnButton />
      <Box width={200} ml="10px">
        <TableSearch />
      </Box>
      <TableDatepicker ml="20px" />
    </Box>
  );
}
