import { useState, useEffect } from "react";
import { TableDataResponse } from "../../../../../types/Table";
import {
  MarketplaceItemsSort,
  MarketplaceItemsFilterParams,
} from "../../../../../types/MarketplaceItems";
import { MarketplaceItemsFilters } from "./Types";
import { filtersToParams } from "./Utils";
import {
  MarketplaceItemsParams,
  MarketplaceItem,
} from "../../../../../types/MarketplaceItems";

export const useLazyItems = <T extends MarketplaceItem>(api: {
  fetchItems: (params: MarketplaceItemsParams) => Promise<TableDataResponse<T>>;
  bulkLink?: (params: MarketplaceItemsFilterParams) => Promise<void>;
  bulkUnlink?: (params: MarketplaceItemsFilterParams) => Promise<boolean>;
}) => {
  const [items, setItems] = useState<T[]>();
  // Items count when filters are applied
  const [filteredItemsCount, setFilteredItemsCount] = useState<number>();
  // Items count when no filters are applied
  const [itemsCount, setItemsCount] = useState<number>();
  const [itemsPageSize, setItemsPageSize] = useState<number>();
  const [itemsPage, setItemsPage] = useState<number>(0);
  const [filters, setFilters] = useState<MarketplaceItemsFilters>({});
  const [sort, setSort] = useState<MarketplaceItemsSort>();

  const fetchPageAndSetMetaData = async (page: number) => {
    const params = filtersToParams(filters);
    const response = await api.fetchItems({ ...params, sort, page });
    if (page) {
      setItemsPage(page);
    }
    setFilteredItemsCount(response.count);
    if (itemsCount === undefined) {
      setItemsCount(response.count);
    }
    setItemsPageSize(response.pageSize);
    return response.data;
  };

  const fetchNextItems = async () => {
    const nextPage = itemsPage + 1;
    const nextItems = await fetchPageAndSetMetaData(nextPage);
    setItems((currentItems) => (currentItems ?? []).concat(nextItems));
  };

  const [initialItemsLoading, setInitialItemsLoading] = useState(true);

  const fetchFirstPage = async () => {
    setInitialItemsLoading(true);
    try {
      const newItems = await fetchPageAndSetMetaData(1);
      setItems(newItems);
    } finally {
      setInitialItemsLoading(false);
    }
  };

  useEffect(() => {
    fetchFirstPage();
    // Load initial items only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLocalItem = (id: string, newItemData: Partial<Omit<T, "id">>) => {
    if (!items) {
      return;
    }
    const itemIndex = items.findIndex((item) => item.id === id);
    if (itemIndex === -1) {
      return;
    }
    const currentItem = items[itemIndex];
    const newItems = [...items];
    newItems[itemIndex] = { ...currentItem, ...newItemData };
    setItems(newItems);
  };

  const removeLocalItem = (id: string) => {
    if (
      !items ||
      filteredItemsCount === undefined ||
      itemsCount === undefined
    ) {
      return;
    }
    setItems(items.filter((item) => item.id !== id));
    setFilteredItemsCount(filteredItemsCount - 1);
    setItemsCount(itemsCount - 1);
  };

  useEffect(() => {
    fetchFirstPage();
    // React only to filters changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  const selectFiltered = async () => {
    return api.bulkLink?.(filtersToParams(filters));
  };

  const removeFiltered = async () => {
    return api.bulkUnlink?.(filtersToParams(filters));
  };

  const goToFirstPage = () => {
    fetchFirstPage();
  };

  const updateCounter = async () => {
    const response = await api.fetchItems({});
    setItemsCount(response.count);
    return response;
  };

  return {
    items,
    filteredItemsCount,
    itemsCount,
    itemsPageSize,
    itemsPage,
    initialItemsLoading,
    fetchNextItems,
    updateLocalItem,
    removeLocalItem,
    filters,
    setFilters,
    sort,
    setSort,
    selectFiltered,
    removeFiltered,
    goToFirstPage,
    updateCounter,
  };
};
