import { useLoading } from "../utils/Loading";
import { Endpoints } from "../api/constants";
import { useCallback, useMemo } from "react";
import { UseLog } from "../types/Logs";
import useSWR from "swr";
import { MpAsyncGetMethod } from "@mp-react/table";
import { LogFilterNames } from "../constants/Logs";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { mutateData } from "../api/api";
import { AxiosResponse } from "axios";
import { useLogUtil } from "../utils/Logs";

export const useActionLog = (query?: string): UseLog => {
  const { loading } = useLoading();
  const { data: actionLog, error: actionLogError } = useSWR(
    `${Endpoints.actionLog}${!!query ? `?${query}` : ""}`
  );

  const apiLoading = useMemo(() => {
    return !actionLog && !actionLogError;
  }, [actionLog, actionLogError]);

  return {
    loading: loading || apiLoading,
    actionLog,
    actionLogError,
  };
};

export const useActionLogAsyncMethods = (
  employeeId?: string,
  query?: string
): Record<string, MpAsyncGetMethod> => {
  const { logActionTranslations } = useLogUtil();
  const baseUrl = useMemo(() => `${Endpoints.actionLogFilterValues}`, []);

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: LogFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const filterById = !!employeeId
        ? `&filter[employeeId]=${employeeId}`
        : "";
      const apiUrl = `${baseUrl}/${filterName}${searchParam}${filterById}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, employeeId]
  );

  const getActionFilterItems = useCallback(
    (filterName: LogFilterNames) => {
      const queryParam = !!query ? `?${query}` : "";
      const apiUrl = `${baseUrl}/${filterName}${queryParam}`;
      return mutateData("get", apiUrl).then((res: AxiosResponse<string[]>) => [
        ...res.data,
        ...logActionTranslations,
      ]);
    },
    [baseUrl, logActionTranslations, query]
  );

  const getDoneByName = useCallback<MpAsyncGetMethod>(
    (args) => getAsyncFilterItems(args, LogFilterNames.DONE_BY_NAME),
    [getAsyncFilterItems]
  );

  const getActions = useCallback<MpAsyncGetMethod>(
    () => getActionFilterItems(LogFilterNames.ACTION),
    [getActionFilterItems]
  );

  return {
    getDoneByName,
    getActions,
  };
};
