import { Box } from "@material-ui/core";
import {
  MpRowActionParameters,
  MpTable,
  useClearFilters,
} from "@mp-react/table";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { UnpackNestedValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Row, UseRowSelectRowProps } from "react-table";
import { toast } from "react-toastify";
import CurrentStatusDialog from "../../../../../components/dialogs/CurrentStatusDialog/CurrentStatusDialog";
import FormDialog from "../../../../../components/dialogs/FormDialog/FormDialog";
import AssignBenefitsFooterButtons from "../../../../../components/employees/AssignBenefitsFooterButtons/AssignBenefitsFooterButtons";
import FormFooter from "../../../../../components/layouts/FormFooter/FormFooter";
import { useChoicesDeadlineForm } from "../../../../../configs/Forms/BenefitsForms/Settings/ChoicesDeadlineForm";
import { useBenefitsAssignEmployeesTableType } from "../../../../../configs/Tables/BenefitsAssignEmployeesTableType";
import { useBenefits, useBenefitStore } from "../../../../../state/Benefits";
import {
  useBenefitsAssignEmployees,
  useBenefitAssignEmployeesAsyncMethods,
} from "../../../../../state/BenefitsAssignEmployees";
import useEmployeeGroups from "../../../../../state/EmployeeGroups";
import { useInvestmentCalculationsStore } from "../../../../../state/InvestmentCalculations";
import {
  TableContext,
  useTable,
  useTableStore,
} from "../../../../../state/Table";
import {
  BenefitsAssignEmployee,
  BenefitTypes,
  UpdateBenefitRequest,
  BenefitStatus,
} from "../../../../../types/Benefits";
import {
  CheckAssignBaseResponse,
  CheckAssignConflictsResponse,
  Investment,
  InvestmentTypes,
} from "../../../../../types/Common";
import { useDialog } from "../../../../../utils/Dialog";
import useTableUtils from "../../../../../utils/Table";
import { BenefitPlanContext } from "../../BenefitsInner";
import useStyles from "../../../../../styles/Table.styles";
import { useMe } from "../../../../../state/Administrators";
import { useBenefitUtils } from "../../../../../utils/Benefits";
import CustomColumnArrow from "../../../../../components/table/CustomColumnArrow/CustomColumnArrow";

export default function AssignEmployees() {
  const classes = useStyles();
  const { canEditEmployees, canEditBenefits } = useMe();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    closeDialog: closeFlexForm,
    open: isFlexFormOpen,
    openDialog: openFlexForm,
  } = useDialog();

  const [selectedRow, setSelectedRow] = useState<string>("");
  const [changeResponse, setChangeResponse] =
    useState<CheckAssignConflictsResponse>();
  const [value, setValue] = useState<string>("");
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isCurrentStatusBoolean, setCurrentStatusBoolean] = useState<
    boolean | null
  >(false);

  const { id, name, type: benefitType } = useContext(BenefitPlanContext);
  const { removeEmployeeFromGroups } = useEmployeeGroups();
  const {
    overridables,
    translations,
    overrideClasses,
    handleGetData,
    getCurrentStatusBoolean,
    isRowInvestmentAdded,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
    customColumnQueryParam,
  } = useTableUtils("benefitsAssignEmployees");
  const {
    assignEmployeesData,
    loading,
    mapAssignEmployeesRequest,
    assignEmployees,
    checkAssignEmployeeChanges,
    parsedBenefitAssignEmployeesTotals,
    mutateAssignEmployees,
  } = useBenefitsAssignEmployees(id, tableQueryParam);
  const asyncGetMethods = useBenefitAssignEmployeesAsyncMethods(
    customColumnQueryParam,
    id
  );
  const { clearFiltersEvent, clearFilters } = useClearFilters();
  const { parseChoicesDateForRequest } = useBenefitUtils();

  const { votingStartDate, votingEndDate } = useBenefitStore(
    useCallback(({ votingEndDate, votingStartDate }) => {
      return { votingEndDate, votingStartDate };
    }, [])
  );
  const customColumn = useTableStore((state) => state.customColumn);
  const resetAllChanges = useInvestmentCalculationsStore(
    useCallback((state) => state.resetAllChanges, [])
  );
  const setStatus = useInvestmentCalculationsStore(
    useCallback((state) => state.setStatus, [])
  );
  const increaseChangeCount = useInvestmentCalculationsStore(
    useCallback((state) => state.increaseChangeCount, [])
  );
  const setInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.setInvestments, [])
  );
  const removeChange = useInvestmentCalculationsStore(
    useCallback((state) => state.removeChange, [])
  );
  const resetInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.resetInvestments, [])
  );

  const assignEmployeesTableType = useBenefitsAssignEmployeesTableType();

  const {
    hasFlexSettings,
    updateBenefit,
    loading: loadingBenefits,
  } = useBenefits(id);
  const flexForm = useChoicesDeadlineForm(true);
  const { bulkMethods } = useTable();

  const getSelectedRowData = useCallback(
    (selectedRow: string) => {
      const data =
        assignEmployeesData?.find((item) => item.id === selectedRow) ??
        ({} as BenefitsAssignEmployee);
      return data;
    },
    [assignEmployeesData]
  );

  const selectedRowData: BenefitsAssignEmployee = useMemo(() => {
    const selectedData = getSelectedRowData(selectedRow);
    const innerCurrentStatus =
      selectedData?.employeeGroup?.map((group, index) => ({
        name: group,
        id: selectedData?.employeeGroupIds[index],
        included: true,
        benefitStatus: selectedData?.currentStatus,
      })) ?? [];

    return {
      ...selectedData,
      innerCurrentStatus,
    };
  }, [getSelectedRowData, selectedRow]);

  const selectedEmployeeName = useMemo(
    () => selectedRowData?.fullName ?? "",
    [selectedRowData?.fullName]
  );

  const dialogTitle = useMemo(
    () =>
      t("assign_employees.remove_from_groups_to_make_changes")?.replace(
        "{employee}",
        selectedRowData?.fullName
      ),
    [selectedRowData?.fullName, t]
  );

  const handleRowClick = useCallback(
    (current: (Row<{}> & UseRowSelectRowProps<{}>) | undefined) => {
      history.push(
        `/employees/view/${current?.id}?tab=innerEmployeeEmployeeInformation`
      );
    },
    [history]
  );

  const toggleStatus = useCallback(
    async (current: MpRowActionParameters) => {
      const { value: newStatus, rowId: employeeId } = current;
      const selectedRowData = getSelectedRowData(employeeId as string);
      const initialStatus = selectedRowData?.currentStatus as BenefitStatus;
      if (initialStatus === newStatus || !newStatus || !employeeId) return;

      setSelectedRow(employeeId as string);
      setValue(newStatus as string);

      const statusBoolean = getCurrentStatusBoolean(newStatus, initialStatus);
      const assignmentData = await checkAssignEmployeeChanges({
        benefitPlanId: id as string,
        newStatus: newStatus as BenefitStatus,
        employeeId: employeeId as string,
      });
      setChangeResponse(assignmentData);

      if (newStatus === "flex" && !hasFlexSettings) {
        openFlexForm();
        return;
      }

      setCurrentStatusBoolean(statusBoolean);
      if (assignmentData.conflicts.length === 0) {
        const investmentData: CheckAssignBaseResponse & Investment = {
          id: employeeId as string,
          totalInvestmentAmount: assignmentData.totalInvestmentAmount,
          investmentAmountChange: assignmentData.investmentAmountChange,
          added: isRowInvestmentAdded(
            initialStatus,
            newStatus as BenefitStatus
          ),
          investment: assignmentData.investmentAmountChange,
          name: selectedRowData.fullName,
          type: InvestmentTypes.employee,
          benefitType: benefitType as BenefitTypes,
        };
        setInvestments(investmentData);
        increaseChangeCount(employeeId);
      } else {
        setOpen(true);
      }
    },
    [
      benefitType,
      checkAssignEmployeeChanges,
      getCurrentStatusBoolean,
      getSelectedRowData,
      hasFlexSettings,
      id,
      increaseChangeCount,
      isRowInvestmentAdded,
      openFlexForm,
      setInvestments,
    ]
  );

  const handleAdd = useCallback(
    async (
      data: UnpackNestedValue<Partial<CheckAssignConflictsResponse>>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      if (!data?.conflicts || data?.conflicts.length === 0) return;
      const employeeIds = [selectedRow];
      let count = 0;
      setTableLoading(true);
      await data.conflicts.map(async (conflict) => {
        const result: unknown = await removeEmployeeFromGroups(
          conflict.employeeGroupId,
          employeeIds
        );
        count++;
        if (!(result as boolean)) {
          setTableLoading(false);
          setStatus(null, selectedRow);
          removeChange(selectedRow);
        } else if (count === data.conflicts?.length) {
          await mutateAssignEmployees();
          setTableLoading(false);
        }
      });
      toast(t("common.updated_succesfully"), { type: "success" });
      increaseChangeCount(selectedRowData?.id ?? "");
      if (!changeResponse) return;
      const investmentData: CheckAssignBaseResponse & Investment = {
        id: selectedRow as string,
        totalInvestmentAmount: changeResponse?.totalInvestmentAmount,
        investmentAmountChange: changeResponse?.investmentAmountChange,
        added: changeResponse?.investmentAmountChange > 0 ?? false,
        investment: changeResponse?.investmentAmountChange,
        name: selectedRowData.fullName,
        type: InvestmentTypes.employee,
        benefitType: benefitType as BenefitTypes,
      };
      setInvestments(investmentData);
    },
    [
      benefitType,
      changeResponse,
      increaseChangeCount,
      mutateAssignEmployees,
      removeChange,
      removeEmployeeFromGroups,
      selectedRow,
      selectedRowData.fullName,
      selectedRowData?.id,
      setInvestments,
      setStatus,
      t,
    ]
  );

  const handleCloseDialog = useCallback(() => {
    setStatus(null, selectedRow);
    removeChange(selectedRow);
    setOpen(false);
  }, [removeChange, selectedRow, setStatus]);

  const handleCancelFooter = useCallback(() => {
    resetAllChanges();
    setStatus(null);
    resetInvestments();
  }, [resetAllChanges, resetInvestments, setStatus]);

  const handlePublishFooter = useCallback(
    (date?: string) => {
      const data = mapAssignEmployeesRequest(date);
      assignEmployees(data);
    },
    [assignEmployees, mapAssignEmployeesRequest]
  );

  const handleGroupChange = useCallback(
    (isChanged: boolean) => {
      if (!isChanged) {
        const toastText = t(
          "assign_employees.did_not_remove_from_groups"
        )?.replace("{name}", selectedEmployeeName);
        toast(toastText, { type: "error" });
        return;
      }

      setOpen(false);
    },
    [t, selectedEmployeeName]
  );

  const handleFlexSettingSubmit = useCallback(
    (data: Partial<UpdateBenefitRequest>) => {
      if (!!votingStartDate && !!votingEndDate) {
        if (moment(votingEndDate).isBefore(votingStartDate)) {
          toast(t("errors.voting_end_date_before_voting_start_date"), {
            type: "error",
          });
          return;
        }
        data.votingStartDate = votingStartDate;
        data.votingEndDate = votingEndDate;
      }

      const parsedData = parseChoicesDateForRequest(data);
      const initialStatus = selectedRowData?.currentStatus as BenefitStatus;

      updateBenefit(parsedData as UpdateBenefitRequest, () => {
        closeFlexForm();
        increaseChangeCount(selectedRowData?.id ?? "");
        setValue("flex");
        const statusValues = {
          currentStatus: { [selectedRowData?.id ?? ""]: { value: "flex" } },
        };
        setStatus(statusValues);
        if (!changeResponse) return;
        const investmentData: CheckAssignBaseResponse & Investment = {
          id: selectedRow as string,
          totalInvestmentAmount: changeResponse?.totalInvestmentAmount,
          investmentAmountChange: changeResponse?.investmentAmountChange,
          added: isRowInvestmentAdded(initialStatus, BenefitStatus.flex),
          investment: changeResponse?.investmentAmountChange,
          name: selectedRowData.fullName,
          type: InvestmentTypes.employee,
          benefitType: benefitType as BenefitTypes,
        };
        setInvestments(investmentData);
      });
    },
    [
      benefitType,
      changeResponse,
      closeFlexForm,
      increaseChangeCount,
      isRowInvestmentAdded,
      parseChoicesDateForRequest,
      selectedRow,
      selectedRowData?.currentStatus,
      selectedRowData.fullName,
      selectedRowData?.id,
      setInvestments,
      setStatus,
      t,
      updateBenefit,
      votingEndDate,
      votingStartDate,
    ]
  );

  const handleFlexFormCancel = useCallback(() => {
    closeFlexForm();
    setStatus(null, selectedRow);
    removeChange(selectedRow);
  }, [closeFlexForm, removeChange, selectedRow, setStatus]);

  useEffect(() => {
    if (!!customColumn) clearFilters(["customColumn"]);
  }, [clearFilters, customColumn]);

  return (
    <TableContext.Provider value={{ id, tableQueryParam }}>
      <Box
        position="relative"
        className={
          canEditEmployees && canEditBenefits ? "" : classes.disableTable
        }
      >
        <CustomColumnArrow tableType={assignEmployeesTableType} />
        <MpTable
          {...assignEmployeesTableType}
          data={assignEmployeesData}
          onGetData={handleGetData}
          overridables={overridables}
          translations={translations}
          bulkMethods={bulkMethods}
          classes={overrideClasses}
          totalsData={parsedBenefitAssignEmployeesTotals}
          totals={assignEmployeesData?.length > 0}
          loading={loading || tableLoading}
          onRowClick={handleRowClick}
          rowMethods={{ toggleStatus }}
          loadingComponent={loadingComponent}
          emptyChildren={noDataComponent}
          asyncGetMethods={asyncGetMethods}
          clearFiltersEvent={clearFiltersEvent}
        />
        <CurrentStatusDialog
          onCancelClick={handleCloseDialog}
          onSubmitForm={handleAdd}
          id="set-current-status"
          open={open}
          title={dialogTitle}
          actionLabel={t("common.confirm")}
          statusValue={value}
          statusBoolean={isCurrentStatusBoolean as any}
          onGroupsChange={handleGroupChange}
          changeResponse={changeResponse}
          benefitName={name ?? ""}
          employeeName={selectedEmployeeName}
        />
        <FormDialog
          onCancelClick={handleFlexFormCancel}
          onSubmitForm={handleFlexSettingSubmit}
          id="change-choices-deadline-form"
          open={isFlexFormOpen}
          title={t(
            "assign_employees.benefit_choices_deadline_not_set"
          )?.replace("{name}", name ?? "")}
          actionLabel={t("common.confirm")}
          form={flexForm}
          subtitle={t("assign_employees.fill_in_fields_to_change_status")}
          loading={loadingBenefits}
        />
        <FormFooter withChanges>
          <AssignBenefitsFooterButtons
            onCancel={handleCancelFooter}
            onPublish={handlePublishFooter}
          />
        </FormFooter>
      </Box>
    </TableContext.Provider>
  );
}
