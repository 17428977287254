import { MpTableType } from "@mp-react/table";
import { useMemo } from "react";
import { useLogsDefaultDatepickerValue } from "./Logs";

export const useTableDialogActions = () =>
  useMemo(() => ["changeBenefitType"], []);

export const useDefaultHighlights = () =>
  useMemo(
    () => ({
      inactive: "inactive",
    }),
    []
  );

export const useDefaultDatepickerValues = () => {
  const logsDefaultDate = useLogsDefaultDatepickerValue();

  const values: { [key: string]: any } = useMemo(
    () => ({
      log: logsDefaultDate,
    }),
    [logsDefaultDate]
  );

  return values;
};

export const usePointingArrowPosition = (EmployeesTableType: MpTableType) =>
  useMemo(() => {
    const { columns, selection } = EmployeesTableType;
    let columnsWidthSum = !!selection ? 25 : 0;
    let customColumnWidth = 0;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].key === "customColumn") {
        customColumnWidth = Number(columns[i]?.width) ?? 124;
        break;
      }
      columnsWidthSum += Number(columns[i]?.width) ?? 0;
    }
    return columnsWidthSum + customColumnWidth / 2;
  }, [EmployeesTableType]);
