import React, { useMemo } from "react";
import {
  MpControlProps,
  controlRegisterOptions,
  useErrorMessages,
} from "@mp-react/form";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText } from "@material-ui/core";
import useTooltip from "../../../utils/Tooltip";
import { CustomFormControl } from "../../../types/Common";
import FormSufleris from "../../common/FormSufleris/FormSufleris";
import RichTextEditor from "../../common/RichTextEditor/RichTextEditor";
import FormTooltip from "../../common/FormTooltip/FormTooltip";

const RichTextFormControl = ({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  value,
  onChange,
}: MpControlProps & {
  value: any;
  onChange: (...event: any[]) => void;
}) => {
  // const classes = useStyles();
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const { sufleris, readonly, disabled } = control as CustomFormControl;
  const inputDisabled = !!readonly || !!disabled;
  const inputId = control.key;

  return (
    <FormControl
      error={!!error}
      required={control.required}
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
      fullWidth
      variant={variant}
      size={size}
    >
      <RichTextEditor
        id={inputId}
        value={value}
        onChange={onChange}
        placeholder={control.placeholder}
        disabled={inputDisabled}
      />
      <FormHelperText id={`${inputId}-helper-text`}>
        {firstMessage}
      </FormHelperText>
      {sufleris && <FormSufleris sufleris={sufleris} />}
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </FormControl>
  );
};

const RichTextEditorControl = (props: MpControlProps) => {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <RichTextFormControl {...props} onChange={onChange} value={value} />
      )}
    />
  );
};

export default RichTextEditorControl;
