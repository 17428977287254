import React, { useMemo } from "react";
import useStyles from "./Actions.styles";
import { useTranslation } from "react-i18next";
import { Typography, Box, BoxProps } from "@material-ui/core";
import ActionsPopper from "../../common/ActionsPopper/ActionsPopper";
import { ReactComponent as Menu } from "../../../assets/icons/menu.svg";
import { StatusActionKey } from "../../../types/Status";

interface IProps extends BoxProps {
  actions: any[];
  onActionClick: (action: StatusActionKey, date?: string) => void;
  disablePast?: boolean;
}

const Actions = ({ onActionClick, actions, disablePast, ...rest }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpenActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action: StatusActionKey, date?: string) => {
    handleCloseActions();
    onActionClick(action, date);
  };

  return (
    <>
      <Box className={classes.button} onClick={handleOpenActions} {...rest}>
        <Typography color="textSecondary" variant="body2">
          <Menu className={classes.icon} />
          {t("sidebar.more")}
        </Typography>
      </Box>
      <ActionsPopper
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseActions}
        placement="bottom-start"
        actions={actions}
        onActionClick={handleActionClick}
        disablePast={disablePast}
        style={{ marginTop: 10 }}
      />
    </>
  );
};

export default Actions;
