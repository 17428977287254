import React, {
  useEffect,
  useState,
  createContext,
  useCallback,
  useMemo,
} from "react";
import SidebarLayout from "../../../components/layouts/Sidebar/SidebarLayout";
import SidebarContainer from "../../../components/layouts/Sidebar/SidebarContainer/SidebarContainer";
import ContentContainer from "../../../components/layouts/Sidebar/SidebarContent/SidebarContent";
import {
  useBenefitSidebarActions,
  useBenefitTabs,
  useBenefitTypes,
} from "../../../constants/Benefits";
import SidebarInner from "../../../components/layouts/Sidebar/SidebarInner/SidebarInner";
import Title from "../../../components/sidebar/Title/Title";
import Tabs from "../../../components/sidebar/Tabs/Tabs";
import Tab from "../../../components/sidebar/Tab/Tab";
import { Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Actions from "../../../components/sidebar/Actions/Actions";
import { useBenefits, useBenefitStore } from "../../../state/Benefits";
import { BenefitDetailsResponse } from "../../../types/Benefits";
import useStatus from "../../../utils/Status";
import SingleFieldDialog from "../../../components/dialogs/SingleFieldDialog/SingleFieldDialog";
import SubmitActionDialog from "../../../components/dialogs/SubmitActionDialog/SubmitActionDialog";
import { StatusActionKey } from "../../../types/Status";
import useTabs from "../../../utils/Tabs";
import { Redirect, useLocation, useParams } from "react-router-dom";
import TabView from "../../../components/common/TabView/TabView";
import Loader from "../../../components/common/Loader/Loader";
import { useMe } from "../../../state/Administrators";
import { useDialog } from "../../../utils/Dialog";

const useStyles = makeStyles((theme) => ({
  deleteWarningText: {
    textAlign: "left",
    marginTop: 20,
  },
}));

interface LocationState {
  id?: string;
  employeeCount?: string;
}

export const BenefitPlanContext = createContext<
  Partial<BenefitDetailsResponse>
>({});

export default function BenefitsInner() {
  const classes = useStyles();
  const { id } = useParams() as any;
  const { t } = useTranslation();
  const { actions, getStatus } = useStatus();
  const tabs = useBenefitTabs();
  const { currentTab, changeTab } = useTabs(tabs[0].key as string);
  const { canEditBenefits } = useMe();
  const location = useLocation();
  const employeeCount = (location.state as LocationState)?.employeeCount;

  const {
    parsedBenefitPlan: benefitPlanData,
    parsedBenefitStatus,
    copyBenefit,
    deleteBenefit,
    updateBenefit,
    apiLoading,
    updateBenefitStatus,
    removeAllEmployees,
    renameBenefit,
    benefitName,
    benefitPlanDetailsError,
  } = useBenefits(id);
  const { setStatus, status } = useBenefitStore((state) => state);
  const benefitTypes = useBenefitTypes();

  const hasInvestment = useMemo(() => {
    if (benefitPlanData?.investmentAmount === 0) return true;
    return !!benefitPlanData?.investmentAmount;
  }, [benefitPlanData?.investmentAmount]);

  const sidebarActions = useBenefitSidebarActions(hasInvestment);

  const publishActions = useMemo(
    () => (hasInvestment ? actions.benefits[status.name] ?? [] : []),
    [actions.benefits, hasInvestment, status.name]
  );
  const allActions = useMemo(
    () => publishActions.concat(sidebarActions),
    [publishActions, sidebarActions]
  );

  const [openSingleFieldDialog, setOpenSingleFieldDialog] =
    useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>("");
  const {
    open: isDeleteEmployeesDialogOpen,
    openDialog: openDeleteEmployeesDialog,
    closeDialog: closeDeleteEmployeesDialog,
  } = useDialog();

  const dialogTitle = useMemo(() => {
    switch (dialogType) {
      case "make_copy":
        return t("benefits.create_benefit_name");
      case "rename_benefit":
        return t("benefits.edit_benefit_name");
      case "change_benefit_type":
        return t("benefits.benefit_type");
      case "delete":
        return t("benefits.to_delete_benefit");
      case "remove_all_employees":
        return t("benefits.to_remove_employees").replace(
          "{count}",
          employeeCount ?? ""
        );
      default:
        return "";
    }
  }, [dialogType, employeeCount, t]);

  const isDeletable = useMemo(() => {
    return benefitPlanData?.deletable === true;
  }, [benefitPlanData?.deletable]);

  const dialogDefaultValue = useMemo(() => {
    switch (dialogType) {
      case "rename_benefit":
        return benefitPlanData?.name;
      case "change_benefit_type":
        return benefitPlanData?.type;
      default:
        return "";
    }
  }, [benefitPlanData?.name, benefitPlanData?.type, dialogType]);

  useEffect(() => {
    setStatus(parsedBenefitStatus);
  }, [benefitPlanData?.status, setStatus, parsedBenefitStatus]);

  const handleSidebarAction = useCallback(
    (action: StatusActionKey, date?: string) => {
      const status = getStatus(action);
      const isSingleFieldAction =
        // action === "make_copy" ||
        action === "change_benefit_type" || action === "rename_benefit";

      if (status) {
        updateBenefitStatus({ name: status, date: date });
      } else if (isSingleFieldAction) {
        setOpenSingleFieldDialog(true);
      } else if (action === "delete") {
        setOpenDeleteDialog(true);
      } else if (action === "remove_all_employees") {
        openDeleteEmployeesDialog();
      } else if (action === "make_copy") {
        copyBenefit();
      }
      setDialogType(action);
    },
    [copyBenefit, getStatus, openDeleteEmployeesDialog, updateBenefitStatus]
  );

  const handleCloseDialog = useCallback(() => {
    if (openSingleFieldDialog) setOpenSingleFieldDialog(false);
    if (openDeleteDialog) setOpenDeleteDialog(false);
    if (isDeleteEmployeesDialogOpen) closeDeleteEmployeesDialog();
    setDialogType("");
  }, [
    closeDeleteEmployeesDialog,
    isDeleteEmployeesDialogOpen,
    openDeleteDialog,
    openSingleFieldDialog,
  ]);

  const handleDialogAction = useCallback(
    (value: string) => {
      if (dialogType === "make_copy") {
        copyBenefit();
      } else if (dialogType === "rename_benefit") {
        renameBenefit({ name: value });
      } else if (dialogType === "change_benefit_type") {
        updateBenefit({ type: value });
      }
      handleCloseDialog();
    },
    [copyBenefit, dialogType, handleCloseDialog, renameBenefit, updateBenefit]
  );

  const handleTitleEdit = useCallback(
    async (value: string) => {
      const isSuccess = await renameBenefit({ name: value });
      return isSuccess;
    },
    [renameBenefit]
  );

  const handleDeleteDialogAction = useCallback(() => {
    if (dialogType === "delete") deleteBenefit();
    if (dialogType === "remove_all_employees") removeAllEmployees();
    handleCloseDialog();
  }, [deleteBenefit, dialogType, handleCloseDialog, removeAllEmployees]);

  const deleteActionLabel = useMemo(() => {
    if (dialogType === "delete" && isDeletable) {
      return t("actions.delete");
    }
    if (dialogType === "delete" && !isDeletable) {
      return t("common.confirm");
    }
    if (dialogType === "remove_all_employees") {
      return t("common.remove");
    }
    return "";
  }, [dialogType, isDeletable, t]);

  if (!!benefitPlanDetailsError) return <Redirect to="/" />;

  if (apiLoading) return <Loader />;

  if (!benefitPlanData) return null;

  return (
    <>
      <SidebarLayout>
        <BenefitPlanContext.Provider value={benefitPlanData}>
          <SidebarContainer>
            <SidebarInner width={270}>
              <Title
                title={benefitName}
                onEditComplete={handleTitleEdit}
                editable={canEditBenefits}
              />
              <Tabs onChange={changeTab} value={currentTab}>
                {tabs.map((tab) => (
                  <Tab
                    key={`tab-${tab.key}`}
                    label={
                      <Typography variant="body2" color="textSecondary">
                        {t(`sidebar.${tab?.translationKey ?? tab.key}`)}
                      </Typography>
                    }
                    value={tab.key}
                    module={tab?.module}
                  />
                ))}
              </Tabs>
              {canEditBenefits && (
                <Actions
                  onActionClick={handleSidebarAction}
                  actions={allActions}
                />
              )}
            </SidebarInner>
          </SidebarContainer>
          <ContentContainer>
            {tabs.map((tab) => (
              <TabView key={tab.key} index={tab.key} value={currentTab}>
                <tab.component />
              </TabView>
            ))}
          </ContentContainer>
        </BenefitPlanContext.Provider>
      </SidebarLayout>
      <SingleFieldDialog
        onCancelClick={handleCloseDialog}
        onActionClick={handleDialogAction}
        id={`${dialogType}_dialog`}
        open={openSingleFieldDialog}
        title={dialogTitle}
        actionLabel={t("common.confirm")}
        placeholder={`${t("benefits.benefit_name")}`}
        defaultValue={dialogDefaultValue}
        type={dialogType === "change_benefit_type" ? "select" : "input"}
        options={dialogType === "change_benefit_type" ? benefitTypes : []}
        tooltip={`tooltip.${dialogType}`}
      />
      <SubmitActionDialog
        onCancelClick={handleCloseDialog}
        title={
          isDeletable ? dialogTitle : t("benefits.benefit_cannot_be_deleted")
        }
        subtitle={isDeletable ? t("benefits.deleteWarning") : undefined}
        subtitleClassName={isDeletable ? classes.deleteWarningText : undefined}
        id={dialogType}
        open={openDeleteDialog}
        onSubmit={isDeletable ? handleDeleteDialogAction : handleCloseDialog}
        actionLabel={deleteActionLabel}
        error={dialogType === "delete" && isDeletable ? true : false}
        disableCancel={!isDeletable}
      />
      <SubmitActionDialog
        onCancelClick={closeDeleteEmployeesDialog}
        title={dialogTitle}
        id={"remove_all_employees"}
        open={isDeleteEmployeesDialogOpen}
        onSubmit={handleDeleteDialogAction}
        actionLabel={deleteActionLabel}
        error
      />
    </>
  );
}
