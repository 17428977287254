import { Box } from "@material-ui/core";
// import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import { useTranslation } from "react-i18next";
import AddNewButton from "../../../common/AddNewButton/AddNewButton";
import React, { useCallback, useState } from "react";
import { useCreateClientForm } from "../../../../configs/Forms/CreateClientForm";
import FormDialog from "../../../dialogs/FormDialog/FormDialog";
import { UnpackNestedValue } from "react-hook-form";
import { useClients } from "../../../../state/Clients";
import { CreateClientRequest } from "../../../../types/Clients";

const defaultFormValues = {
  companyCountry: "LTU",
};

export default function ClientsHeader() {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const clientForm = useCreateClientForm();
  const { createClient, loading } = useClients();

  const handleOpenDialog = useCallback(() => setOpen(true), []);
  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleSubmit = useCallback(
    (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      const response = createClient(data as CreateClientRequest);
      response.then(() => {
        handleCloseDialog();
      });
    },
    [createClient, handleCloseDialog]
  );

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="flex-end"
      minHeight="44px"
    >
      {/*<TableDatepicker marginRight="20px" />*/}
      <AddNewButton onClick={handleOpenDialog}>
        {t("client.new_client")}
      </AddNewButton>
      <FormDialog
        actionLabel={t("common.confirm")}
        onCancelClick={handleCloseDialog}
        onSubmitForm={handleSubmit}
        id="create-client-form-dialog"
        title={t("client.add_client")}
        form={clientForm}
        open={open}
        defaultValues={defaultFormValues}
        loading={loading}
      />
    </Box>
  );
}
