import { Box } from "@material-ui/core";
import useStyles from "./FlexBenefitBalance.styles";
import { MpTable } from "@mp-react/table";
import React, { useCallback, useMemo, useState } from "react";
import { useEmployeeInnerFlexBalanceTableType } from "../../../../../configs/Tables/EmployeeInnerFlexBalanceTableType";
import { rowMethods } from "../../../../../utils/TableMethods";
import useTableUtils from "../../../../../utils/Table";
import FormDialog from "../../../../../components/dialogs/FormDialog/FormDialog";
import { useTranslation } from "react-i18next";
import { UnpackNestedValue } from "react-hook-form";
import { useFlexBenefitBalanceForm } from "../../../../../configs/Forms/AddFlexBenefitBalanceForm";
import { Row, UseRowSelectRowProps } from "react-table";
import useFlexBenefitBalance from "../../../../../state/FlexBenefitBalance";
import { useParams } from "react-router-dom";
import {
  FlexBenefitBalanceForm,
  UpdateFlexBenefitBalanceRequest,
} from "../../../../../types/FlexBenefitBalance";
import { useMe } from "../../../../../state/Administrators";
import { toast } from "react-toastify";
import clsx from "clsx";

export default function FlexBenefitBalance() {
  const classes = useStyles();
  const { id } = useParams() as any;
  const {
    overridables,
    overrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
  } = useTableUtils("innerEmployeeFlexBalance");
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<string>("");
  const FlexBenefitBalanceTableType = useEmployeeInnerFlexBalanceTableType();
  const FlexBenefitBalanceForm = useFlexBenefitBalanceForm();
  const {
    employeeBalance,
    loading,
    updateFlexBenefitBalance,
    parsedFlexBenefitBalanceTotals,
  } = useFlexBenefitBalance(id);
  const { canEditChoices } = useMe();

  const flexBenefitBalanceData = useMemo(
    () => employeeBalance?.data ?? [],
    [employeeBalance?.data]
  );

  const selectedRowData = useMemo(
    () => employeeBalance?.data.filter((item) => item.id === selectedRow)[0],
    [employeeBalance, selectedRow]
  );

  const formDefaultValues = useMemo(() => {
    const amount = !!selectedRowData?.reserved
      ? `-${selectedRowData?.reserved}`
      : selectedRowData?.allocated;
    return {
      details: selectedRowData?.details,
      date: selectedRowData?.date,
      allocated: amount,
    };
  }, [
    selectedRowData?.allocated,
    selectedRowData?.date,
    selectedRowData?.details,
    selectedRowData?.reserved,
  ]);

  const noData = useMemo(
    () => flexBenefitBalanceData?.length > 0,
    [flexBenefitBalanceData]
  );

  const handleOpenDialog = useCallback(() => setOpen(true), []);
  const handleCloseDialog = useCallback(() => setOpen(false), []);
  const handleAdd = useCallback(
    (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      const allocatedAmount = (data as FlexBenefitBalanceForm).allocated;
      if (allocatedAmount !== "-" && allocatedAmount !== 0) {
        handleCloseDialog();
        updateFlexBenefitBalance(
          data as UpdateFlexBenefitBalanceRequest,
          selectedRowData?.id as string
        );
      } else toast(t("errors.allocated_amount"), { type: "error" });
    },
    [handleCloseDialog, selectedRowData?.id, t, updateFlexBenefitBalance]
  );

  const handleRowClick = useCallback(
    (current: Row<{}> & UseRowSelectRowProps<{}>) => {
      if (canEditChoices) {
        setSelectedRow(current.id);
        return handleOpenDialog();
      }
    },
    [canEditChoices, handleOpenDialog]
  );

  return (
    <Box
      className={clsx({
        [classes.hiddenColumn]: noData,
        [classes.disableTable]: !canEditChoices,
      })}
    >
      <MpTable
        {...FlexBenefitBalanceTableType}
        data={flexBenefitBalanceData}
        onGetData={handleGetData}
        overridables={overridables}
        totalsData={parsedFlexBenefitBalanceTotals}
        totals={flexBenefitBalanceData.length > 0}
        disablePagination={true}
        classes={overrideClasses}
        pageSize={20}
        loading={loading}
        enableGlobalActions={true}
        rowMethods={rowMethods}
        translations={translations}
        onRowClick={handleRowClick as any}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
      />
      <FormDialog
        onCancelClick={handleCloseDialog}
        onSubmitForm={handleAdd}
        id="edit-manual-adjustment"
        open={open}
        title={t("employees.edit_manual_adjustment")}
        actionLabel={t("common.confirm")}
        form={FlexBenefitBalanceForm}
        defaultValues={formDefaultValues}
        loading={loading}
      />
    </Box>
  );
}
