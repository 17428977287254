import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors, getBorder } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    dividerBottom: {
      borderBottom: getBorder(Colors.Divider),
      padding: "12px 0px",
    },
    switchRoot: {
      margin: "1px 7px",
    },
    chip: {
      height: 28,
      borderRadius: 20,
      minWidth: 60,
      textTransform: "uppercase",
    },
    default: {
      backgroundColor: Colors.Default,
      color: Colors.TextSecondary,
    },
    green: {
      backgroundColor: Colors.StatusGreen,
      color: Colors.White,
    },
    yellow: {
      backgroundColor: Colors.StatusYellow,
      color: Colors.White,
    },
    groupsChip: {
      color: Colors.White,
      backgroundColor: Colors.Dark,
      textTransform: "capitalize",
    },
  })
);
