import { Box } from "@material-ui/core";
import { MpTable } from "@mp-react/table";
import React, { useMemo } from "react";
import { useEmployeeInnerLogTableType } from "../../../../../configs/Tables/EmployeeInnerLogTableType";
import { bulkMethods, rowMethods } from "../../../../../utils/TableMethods";
import useTableUtils from "../../../../../utils/Table";
import { useEmployeeActionLog } from "../../../../../state/InnerEmployeeLog";
import { useParams } from "react-router-dom";
import useStyles from "./Log.styles";
import { useActionLogAsyncMethods } from "../../../../../state/Log";

export default function EmployeeLog() {
  const classes = useStyles();
  const {
    overridables,
    overrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
  } = useTableUtils("innerEmployeeLog");
  const LogTableType = useEmployeeInnerLogTableType();
  const { id } = useParams() as any;
  const { loading, employeeActionLog } = useEmployeeActionLog(
    id,
    tableQueryParam
  );
  const asyncGetMethods = useActionLogAsyncMethods(id);

  const employeeActionLogData = useMemo(
    () => employeeActionLog?.data ?? [],
    [employeeActionLog?.data]
  );

  return (
    <Box className={classes.table}>
      <MpTable
        {...LogTableType}
        data={employeeActionLogData}
        onGetData={handleGetData}
        bulkMethods={bulkMethods}
        overridables={overridables}
        disablePagination={true}
        classes={overrideClasses}
        pageSize={employeeActionLog?.pageSize ?? 20}
        loading={loading}
        enableGlobalActions={true}
        rowMethods={rowMethods}
        translations={translations}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
        asyncGetMethods={asyncGetMethods}
      />
    </Box>
  );
}
