import { useDefaultHighlights } from "./../../constants/Table";
import { MpColumn, MpTableType } from "@mp-react/table";
import { CustomRendererType } from "../../types/Table";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Colors } from "../../constants/Style";
import { useTableFilterTypes } from "../../utils/Table";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useEmployeeInnerAssignBenefitsTable = (): MpTableType => {
  const { t } = useTranslation();
  const highlights = useDefaultHighlights();
  const { keyWithSuffix } = useLocalStorage();

  const {
    getSelectFilter,
    getFilterNameSearchSelectFilter,
    numberRangeFilter,
  } = useTableFilterTypes();

  const benefitPlanNameFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getBenefitPlanName"),
    [getFilterNameSearchSelectFilter]
  );

  const benefitPlanTypeFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getBenefitPlanType"),
    [getFilterNameSearchSelectFilter]
  );

  const currentStatusFilter = useMemo(
    () =>
      getSelectFilter("currentStatus", [
        {
          label: t("status.status_off"),
          value: "off",
        },
        {
          label: t("status.status_flex"),
          value: "flex",
        },
        {
          label: t("status.status_on"),
          value: "on",
        },
      ]),
    [getSelectFilter, t]
  );

  const columns = useMemo(
    (): MpColumn[] => [
      {
        label: t("common.plan_name"),
        key: "name",
        filter: benefitPlanNameFilter,
      },
      {
        label: t("benefits.benefit_type"),
        key: "type",
        render: CustomRendererType.benefitTypeTranslation as any,
        filter: benefitPlanTypeFilter,
      },
      {
        label: t("common.investment"),
        key: "investment",
        render: CustomRendererType.currency as any,
        filter: numberRangeFilter,
        sort: false,
      },
      {
        label: t("table.previous_status"),
        key: "statuses",
        sort: false,
        render: CustomRendererType.entries as any,
      },
      {
        label: t("common.current_status"),
        key: "currentStatus",
        render: "statusToggle" as any,
        sort: false,
        totals: false,
        action: {
          slug: "toggleStatus",
          items: [
            {
              label: t("status.off"),
              value: "off",
              color: Colors.Default,
              textColor: Colors.TextSecondary,
            },
            {
              label: t("status.flex"),
              value: "flex",
              color: Colors.StatusYellow,
              textColor: Colors.White,
            },
            {
              label: t("status.on"),
              value: "on",
              color: Colors.StatusGreen,
              textColor: Colors.White,
            },
          ],
        },
        filter: currentStatusFilter,
      },
    ],
    [
      benefitPlanNameFilter,
      benefitPlanTypeFilter,
      currentStatusFilter,
      numberRangeFilter,
      t,
    ]
  );

  return {
    columns,
    rowIdKey: "id",
    filterVariant: "head",
    disablePagination: true,
    totals: true,
    stickyTotals: true,
    highlights: highlights,
    storageIdentifier: keyWithSuffix(StorageKeys.employeeAssignBenefitsTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
