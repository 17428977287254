import { useLoading } from "../utils/Loading";
import { useTranslation } from "react-i18next";
import { Endpoints } from "../api/constants";
import { toast } from "react-toastify";
import { useCallback } from "react";
import {
  UseNotificationService,
  ReminderRequest,
  ReminderResponse,
} from "../types/NotificationService";
import { mutateData } from "../api/api";

const useNotificationService = (): UseNotificationService => {
  const { t } = useTranslation();
  const { stopLoading, startLoading, loading } = useLoading();

  const invitationToastText = useCallback(
    (employeeIds: any) => {
      return `${employeeIds.length} ${t(
        `common.${
          employeeIds.length > 1
            ? "reminders_sent_successfully"
            : "reminder_sent_successfully"
        }`
      )}`;
    },
    [t]
  );

  const sendInvitation = useCallback(
    async (employeeIds: string[]) => {
      startLoading();
      await mutateData("post", Endpoints.notificationInvitation, {
        employeeIds: employeeIds,
      })
        .then(() => {
          toast(invitationToastText(employeeIds), { type: "success" });
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, invitationToastText]
  );

  const sendChoicesReminder = useCallback(
    async (
      employeeAndBenefitIds: ReminderRequest[],
      ignoreLastNotification: boolean
    ) => {
      startLoading();
      return await mutateData("post", Endpoints.choicesReminder, {
        combinations: employeeAndBenefitIds,
        ignoreLastNotification: ignoreLastNotification,
      })
        .then((res) => {
          return res.data as ReminderResponse;
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading]
  );

  const sendTermsAcceptanceReminder = useCallback(
    async (
      employeeAndBenefitIds: ReminderRequest[],
      ignoreLastNotification: boolean
    ) => {
      startLoading();
      return await mutateData("post", Endpoints.termsReminder, {
        acceptances: employeeAndBenefitIds,
        ignoreLastNotification: ignoreLastNotification,
      })
        .then((res) => {
          return res.data as ReminderResponse;
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading]
  );

  return {
    loading: loading,
    sendInvitation,
    sendChoicesReminder,
    sendTermsAcceptanceReminder,
  };
};

export default useNotificationService;
