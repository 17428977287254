import { IconButton, IconButtonProps } from "@material-ui/core";
import { ReactComponent as Settings } from "../../../assets/icons/settings.svg";
import React, { useCallback, useMemo, useState } from "react";
import CustomizeColumnDialog from "../../dialogs/CustomizeColumnDialog/CustomizeColumnDialog";
import { useTranslation } from "react-i18next";
import { useEmployeeInnerCategories } from "../../../state/CustomEmployeeCategories";
import { useTableStore } from "../../../state/Table";
import useStyles from "../HeaderVariantMap/HeaderVariantMap.styles";
import useTableUtils from "../../../utils/Table";
import { Prompt } from "react-router-dom";

export default function CustomizeColumnButton(props: IconButtonProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { updateCustomColumn } = useTableUtils();

  const { employeeInnerCategories: employeeCategories } =
    useEmployeeInnerCategories();
  const {
    customColumn,
    setIsOpenCustomColumnDialog,
    isOpenCustomColumnDialog,
  } = useTableStore(
    ({
      customColumn,
      setIsOpenCustomColumnDialog,
      isOpenCustomColumnDialog,
    }) => {
      return {
        customColumn,
        setIsOpenCustomColumnDialog,
        isOpenCustomColumnDialog,
      };
    }
  );

  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | null>(customColumn ?? null);

  const options: string[] = useMemo(
    () => employeeCategories?.map((cat) => cat.name),
    [employeeCategories]
  );

  const value = useMemo(() => {
    if (!!selected) return selected;
    if (!!customColumn) return customColumn;
    return options?.[0] ?? null;
  }, [customColumn, options, selected]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    setIsOpenCustomColumnDialog(true);
  }, [setIsOpenCustomColumnDialog]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setIsOpenCustomColumnDialog(false);
  }, [setIsOpenCustomColumnDialog]);

  const handleChange = useCallback((value: string) => {
    setSelected(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!!selected) {
      updateCustomColumn(selected);
    }
    handleClose();
  }, [handleClose, selected, updateCustomColumn]);

  const handleLeavingPage = useCallback(() => {
    handleClose();
    return true;
  }, [handleClose]);

  return (
    <>
      <Prompt when={isOpenCustomColumnDialog} message={handleLeavingPage} />
      <IconButton
        {...props}
        onClick={handleOpen}
        className={classes.iconButton}
      >
        <Settings />
      </IconButton>
      <CustomizeColumnDialog
        actionLabel={t("common.confirm")}
        onCancelClick={handleClose}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        title={t("employees.choose_custom_column")}
        value={value}
        open={open}
        options={options}
      />
    </>
  );
}
