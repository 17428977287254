import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import moment from "moment";
import useLocalStyles from "./EmployeeInformation.styles";
import useStyles from "../../../../../styles/Form.styles";
import { Box, Typography, Paper, Grid } from "@material-ui/core";
import { MpForm } from "@mp-react/form";
import { useForm, SubmitErrorHandler, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, Prompt } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { UnpackNestedValue } from "react-hook-form";
import { useEmployeeInformationContact } from "../../../../../configs/Forms/EmployeeInformationForms/EmployeeContactDetailsForm";
import { useEmployeeInformationInsideCompany } from "../../../../../configs/Forms/EmployeeInformationForms/EmployeeInsideCompanyForm";
import { useEmployeeInformationCategories } from "../../../../../configs/Forms/EmployeeInformationForms/EmployeeCategoriesForm";
import { useEmployeeInformationAuthentication } from "../../../../../configs/Forms/EmployeeInformationForms/AuthenticationDetailsForm";
import { useFormUtils } from "../../../../../utils/Form";
import useStatus from "../../../../../utils/Status";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit.svg";
import Toolbar from "../../../../../components/layouts/Toolbar/Toolbar";
import Form from "../../../../../components/layouts/Form/Form";
import StatusButton from "../../../../../components/common/StatusButton/StatusButton";
import EmployeeInformationFooterButtons from "../../../../../components/employees/EmployeeInformationFooterButtons/EmployeeInformationFooterButtons";
import AddFormButton from "../../../../../components/form/AddFormButton/AddFormButton";
import SingleFieldDialog from "../../../../../components/dialogs/SingleFieldDialog/SingleFieldDialog";
import { StatusActionKey } from "../../../../../types/Status";
import { InnerEmployeeContext } from "../../EmployeesInner";
import { useParams } from "react-router-dom";
import { useEmployees, useEmployeeStore } from "../../../../../state/Employees";
import {
  EmployeeResponse,
  UpdateInnerEmployeeRequest,
} from "../../../../../types/Employees";
import FormFooter from "../../../../../components/layouts/FormFooter/FormFooter";
import Loader from "../../../../../components/common/Loader/Loader";
import { useEmployeeInnerCategories } from "../../../../../state/CustomEmployeeCategories";
import { useEmployeesUtils } from "../../../../../utils/Employees";
import { toast } from "react-toastify";
import { PermissionOptions } from "../../../../../constants/Administrators";
import { useMe } from "../../../../../state/Administrators";
import {
  useDefaultEndTime,
  useDefaultStartTime,
} from "../../../../../constants/Employees";
import {
  getTimezoneFromDate,
  mergeDateTime,
  useDateFormat,
} from "../../../../../utils/Common";

export default function EmployeeInformation() {
  const formClasses = useStyles();
  const classes = useLocalStyles();
  const employeeData = useContext(InnerEmployeeContext);
  const useFormMethods = useForm({ defaultValues: employeeData });
  const { getStatus } = useStatus();
  const history = useHistory();
  const location = useLocation();
  const { handleSubmit, watch, register, reset, formState } = useFormMethods;
  const { t } = useTranslation();
  const { me, canEditCustomEmployeeCategory, canEditEmployees } = useMe();
  const AuthenticationDetailsFormType = useEmployeeInformationAuthentication();
  const ContactDetailsFormType = useEmployeeInformationContact(
    !canEditEmployees
  );
  const InsideTheCompanyFormType = useEmployeeInformationInsideCompany(
    !canEditEmployees
  );
  const CategoriesFormType = useEmployeeInformationCategories();
  const temporaryEmployment = watch("temporaryEmployment");
  const { id } = useParams() as any;
  const { updateInnerEmployee, parsedEmployeeStatus, loading } =
    useEmployees(id);
  const [open, setOpen] = useState<boolean>(false);
  const { createEmployeeCategory } = useEmployeeInnerCategories();
  const { setStatus, status, setStartDate, startDate, setEndDate, endDate } =
    useEmployeeStore((state) => state);
  const defaultEndTime = useDefaultEndTime();
  const defaultStartTime = useDefaultStartTime();
  const { localeLanguage } = useDateFormat();
  const { parseRequestDates } = useEmployeesUtils();

  useEffect(() => {
    if (!!employeeData) {
      reset(employeeData);
      setStatus(parsedEmployeeStatus);
    }
  }, [employeeData, reset, parsedEmployeeStatus, setStatus]);

  const statusChanged = useMemo(
    () => status.name !== parsedEmployeeStatus?.name,
    [parsedEmployeeStatus?.name, status.name]
  );

  const hasChanges: boolean = useMemo(
    () => formState.isDirty || statusChanged,
    [formState.isDirty, statusChanged]
  );

  const footerTransform = useMemo(
    () => `translateY(${hasChanges ? "0" : "70px"})`,
    [hasChanges]
  );

  const { overridables } = useFormUtils();

  const handleStatusChange = useCallback(
    (action: StatusActionKey, date?: string, tz?: number) => {
      const status = getStatus(action);
      const isInPast = (date: string) => {
        return moment(date).isBefore();
      };
      if (status && date) {
        if (status === "will_be_active_on" && isInPast(date)) {
          setStatus({
            name: "active",
          });
        } else if (status === "will_be_inactive_on" && isInPast(date)) {
          setStatus({
            name: "inactive",
          });
        } else {
          setStatus({
            name: status,
            date: date,
            tz: tz,
          });
        }
      } else if (status) {
        setStatus({
          name: status,
        });
      }
    },
    [getStatus, setStatus]
  );

  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleOpenDialog = useCallback(() => setOpen(true), []);

  const handleCreateEmployeeCategory = useCallback(
    async (value: string) => {
      handleCloseDialog();
      const res = await createEmployeeCategory(value);
      if (!!res?.id)
        history.push({
          pathname: "/more/company-settings",
          search: "tab=companySettingsCustomEmployeeCategory",
          state: { createdCategory: res },
        });
    },
    [handleCloseDialog, createEmployeeCategory, history]
  );

  const resetStateDates = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
  }, [setStartDate, setEndDate]);

  useEffect(() => {
    setStartDate(
      !!employeeData?.startDate ? employeeData.startDate : defaultStartTime
    );
    setEndDate(!!employeeData?.endDate ? employeeData.endDate : defaultEndTime);
  }, [
    setStartDate,
    setEndDate,
    defaultEndTime,
    defaultStartTime,
    employeeData.startDate,
    employeeData.endDate,
  ]);

  const onSubmit = useCallback(
    (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      const categoriesList = [];
      if ((data as UpdateInnerEmployeeRequest).categories) {
        for (const [key, value] of Object.entries(
          (data as UpdateInnerEmployeeRequest).categories
        )) {
          categoriesList.push({
            employeeCategoryId: key,
            employeeCategoryValueId: value,
          });
        }
      }
      const statusData = parseRequestDates(status);

      const dataEndTime = (data as UpdateInnerEmployeeRequest).endTime ?? "";
      const dataEndDate = (data as UpdateInnerEmployeeRequest).endDate ?? "";
      const dataEndDateTime = mergeDateTime(dataEndDate, dataEndTime);

      const dataStartTime =
        (data as UpdateInnerEmployeeRequest).startTime ?? "";
      const dataStartDate =
        (data as UpdateInnerEmployeeRequest).startDate ?? "";
      const dataStartDateTime = mergeDateTime(dataStartDate, dataStartTime);

      const formEndDate = !!(data as UpdateInnerEmployeeRequest).endDate
        ? endDate ?? dataEndDateTime
        : null;

      const formStartDate = !!(data as UpdateInnerEmployeeRequest).startDate
        ? startDate ?? dataStartDateTime
        : null;

      const formEmail = !!(data as UpdateInnerEmployeeRequest).email
        ? (data as UpdateInnerEmployeeRequest).email
        : null;

      const startDateTime =
        statusChanged && !!statusData.startDate
          ? statusData.startDate
          : formStartDate;

      const employmentDate = mergeDateTime(
        (data as EmployeeResponse).employmentDate,
        (data as EmployeeResponse).employmentTime
      );
      const endDateTime = statusChanged ? statusData.endDate : formEndDate;
      const startDateTZ = getTimezoneFromDate(startDateTime);
      const endDateTZ = getTimezoneFromDate(endDateTime);
      const employmentDateTZ = getTimezoneFromDate(employmentDate);
      data = {
        ...data,
        categories: categoriesList,
        startDate: startDateTime,
        endDate: endDateTime,
        email: formEmail,
        startDateTZ,
        endDateTZ,
        employmentDate,
        employmentDateTZ,
      };

      resetStateDates();
      const dateOfBirthBoolean: Boolean =
        moment((data as any).dateOfBirth).isBefore() &&
        moment((data as any).dateOfBirth).isValid();
      const dateOfBirthEmpty: Boolean = (data as any).dateOfBirth === null;
      if (dateOfBirthEmpty || dateOfBirthBoolean) {
        updateInnerEmployee(data as UpdateInnerEmployeeRequest);
      } else {
        toast(t("form.pattern"), { type: "error" });
      }
      setStatus({ name: parsedEmployeeStatus?.name });
      reset(undefined, { isDirty: false });
    },
    [
      parseRequestDates,
      status,
      endDate,
      statusChanged,
      startDate,
      resetStateDates,
      setStatus,
      parsedEmployeeStatus?.name,
      reset,
      updateInnerEmployee,
      t,
    ]
  );

  const handleCancel = useCallback(() => {
    reset(employeeData);
    setStatus(parsedEmployeeStatus);
    resetStateDates();
    history.push({
      pathname: location?.pathname,
      search: `tab=innerEmployeeEmployeeInformation`,
    });
  }, [
    history,
    employeeData,
    location?.pathname,
    reset,
    parsedEmployeeStatus,
    setStatus,
    resetStateDates,
  ]);

  const handleError: SubmitErrorHandler<Record<string, any>> = useCallback(
    (errors, e) => {},
    []
  );

  const handleConfirm = useCallback(() => {
    handleSubmit((data: UnpackNestedValue<{}>) => {
      onSubmit(data);
    }, handleError)();
  }, [handleSubmit, handleError, onSubmit]);

  useEffect(() => {
    if (!temporaryEmployment) {
      register("termsFile", { required: false });
    }
    // eslint-disable-next-line
  }, [temporaryEmployment]);

  if (loading) return <Loader />;

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={(e) => e.preventDefault()} noValidate>
        <Prompt
          when={hasChanges}
          message={t("errors.user_leaving_edited_page")}
        />
        <Box display="flex">
          <Form
            className={
              employeeData?.permission === PermissionOptions.Edit ||
              me?.root === true
                ? ""
                : formClasses.disableFormFields
            }
          >
            <Paper>
              <Box padding="30px">
                <Typography classes={{ h5: classes.title }} variant="h5">
                  {t("form.authentication_details")}
                </Typography>
                <MpForm
                  {...AuthenticationDetailsFormType}
                  useFormMethods={useFormMethods}
                  overridables={overridables}
                />
              </Box>
            </Paper>
            <Paper className={classes.formContainer}>
              <Box padding="30px">
                <Typography classes={{ h5: classes.title }} variant="h5">
                  {t("common.contact_details")}
                </Typography>
                <MpForm
                  {...ContactDetailsFormType}
                  useFormMethods={useFormMethods}
                  overridables={overridables}
                />
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item sm={4}>
                    <Typography variant="body2">
                      {t("employees.app_user")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {!!employeeData?.user
                        ? `${t("employees.last_action")} ${moment(
                            employeeData?.user?.lastActionDate
                          )
                            .locale(localeLanguage)
                            .format("LL")}`
                        : t("common.no")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Paper className={classes.formContainer}>
              <Box padding="30px">
                <Typography classes={{ h5: classes.title }} variant="h5">
                  {t("form.inside_company")}
                </Typography>
                <MpForm
                  {...InsideTheCompanyFormType}
                  useFormMethods={useFormMethods}
                  overridables={overridables}
                />
              </Box>
            </Paper>
            <Paper className={classes.formContainer}>
              <Box padding="30px">
                <Box className={classes.titleContainer}>
                  <Typography
                    classes={{ h5: classes.categoriesTitle }}
                    variant="h5"
                  >
                    {t("form.categories")}
                  </Typography>
                  {canEditCustomEmployeeCategory && (
                    <Link
                      className={classes.editContainer}
                      to="/more/company-settings?tab=companySettingsCustomEmployeeCategory"
                    >
                      <Typography variant="body2" color="primary">
                        {t("common.edit")}
                      </Typography>
                      <EditIcon />
                    </Link>
                  )}
                </Box>
                <MpForm
                  {...CategoriesFormType}
                  useFormMethods={useFormMethods}
                  overridables={overridables}
                />
                {canEditCustomEmployeeCategory && canEditEmployees && (
                  <AddFormButton
                    onClick={handleOpenDialog}
                    label={t("buttons.new_category")}
                  />
                )}
              </Box>
            </Paper>

            <FormFooter
              justifyContent="flex-end"
              containerProps={{
                style: {
                  transform: footerTransform,
                },
              }}
              paddingRight="20px"
            >
              <EmployeeInformationFooterButtons
                onCancel={handleCancel}
                onConfirm={handleConfirm}
              />
            </FormFooter>
          </Form>

          <Box marginLeft="30px">
            <Toolbar
              className={
                employeeData?.permission === PermissionOptions.Edit ||
                me?.root === true
                  ? ""
                  : formClasses.disableFormFields
              }
            >
              <StatusButton
                status={status}
                onActionChange={handleStatusChange}
                id="employee"
                disablePast={true}
              />
            </Toolbar>
          </Box>
        </Box>

        <SingleFieldDialog
          onCancelClick={handleCloseDialog}
          onActionClick={handleCreateEmployeeCategory}
          id="employee-category-dialog"
          open={open}
          title={t("custom_employee_category.create_employee_category")}
          actionLabel={t("custom_employee_category.create_category")}
          placeholder={`${t("custom_employee_category.category_name")}...`}
          tooltip="tooltip.custom_employee_create_category_name"
        />
      </form>
    </FormProvider>
  );
}
