import {
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Popper,
  MenuItem,
  PopperProps,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import useStyles from "./ActionsPopper.styles";
import { useTranslation } from "react-i18next";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import { StatusActionKey } from "../../../types/Status";
import clsx from "clsx";
import useStatus from "../../../utils/Status";
import { useBenefitStore } from "../../../state/Benefits";
import { useLocation } from "react-router";
import moment from "moment";
import { useEmployeeStore } from "../../../state/Employees";
import { browserLanguage } from "../../../utils/Common";

interface IProps extends Partial<PopperProps> {
  onClose: () => void;
  onActionClick: (action: StatusActionKey, date?: string, tz?: number) => void;
  actions: any[];
  indicators?: string[];
  values?: string;
  activeItem?: number;
  disablePast?: boolean;
  hideTimePicker?: boolean;
}

export default function ActionsPopper(props: IProps) {
  const {
    open,
    anchorEl,
    onClose,
    placement,
    actions,
    onActionClick,
    indicators,
    style,
    values,
    activeItem,
    disablePast,
    hideTimePicker,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [dateAnchor, setDateAnchor] = useState<HTMLLIElement | null>(null);
  const [defaultDate, setDefaultDate] = useState<string | null>(null);
  const [activeAction, setActiveAction] = useState<StatusActionKey | null>(
    null
  );
  const openDatepicker = useMemo(() => Boolean(dateAnchor), [dateAnchor]);
  const { actionsWithDatepicker } = useStatus();
  const { deactivationDate, publishDate } = useBenefitStore();
  const { startDate, endDate } = useEmployeeStore((state) => state);
  const location = useLocation();
  const isBenefit = useMemo(
    () => location?.pathname?.includes("/benefits/view"),
    [location?.pathname]
  );
  const isEmployee = useMemo(
    () => location?.pathname?.includes("/employees/view"),
    [location?.pathname]
  );

  const parsedPublishFromValue = useMemo(
    () =>
      !!values &&
      values !== "default" &&
      moment.parseZone(values).locale(browserLanguage).format("L"),
    [values]
  );

  const handleClose = useCallback(() => {
    setDateAnchor(null);
  }, []);

  const dateSubmitHandler = useCallback(
    (date: string, tz?: number) => {
      handleClose();
      if (activeAction) onActionClick(activeAction, date, tz);
      setActiveAction(null);
    },
    [activeAction, onActionClick, handleClose]
  );

  const handleActionClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement>, action: StatusActionKey) => {
      if (actionsWithDatepicker.includes(action)) {
        if (isBenefit)
          setDefaultDate(
            action === "save_deactivate_on_date"
              ? deactivationDate
              : action === "save_publish_on_date"
              ? publishDate
              : null
          );
        if (isEmployee)
          setDefaultDate(
            action === "deactivate_specific_date"
              ? endDate
              : action === "activate_specific_date"
              ? startDate
              : null
          );
        setDateAnchor(event.currentTarget);
        setActiveAction(action);
      } else if (
        typeof action === "string" &&
        action.includes("save_publish_on")
      ) {
        onActionClick(
          action,
          moment(values).locale(browserLanguage).format("L")
        );
      } else if (typeof action === "string" && action.includes("now")) {
        onActionClick(action, moment().format());
      } else onActionClick(action);
    },
    [
      actionsWithDatepicker,
      deactivationDate,
      endDate,
      isBenefit,
      isEmployee,
      onActionClick,
      publishDate,
      startDate,
      values,
    ]
  );

  const menuItemValues: any = useCallback(
    (item: any) => {
      if (item.label) {
        return t(item.label);
      } else {
        if (item === "save_publish_on") {
          return `${t(`actions.${item}`)} ${parsedPublishFromValue}`;
        } else {
          return t(`actions.${item.key ?? item}`);
        }
      }
    },
    [t, parsedPublishFromValue]
  );

  const MenuItemComponent = useMemo(() => {
    return actions.map((item, i) => {
      if (item === "save_publish_on" && !parsedPublishFromValue) return null;
      return (
        <MenuItem
          key={`${item}${i}`}
          onClick={(e) => handleActionClick(e, item)}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            className={clsx({
              [classes.unfilledIndicator]:
                item.key && indicators?.includes(item.key),
              [classes.green]: item === "now",
              [classes.activeItem]: i === activeItem,
            })}
          >
            {menuItemValues(item)}
          </Typography>
        </MenuItem>
      );
    });
  }, [
    actions,
    parsedPublishFromValue,
    classes.unfilledIndicator,
    classes.green,
    classes.activeItem,
    indicators,
    activeItem,
    menuItemValues,
    handleActionClick,
  ]);

  return (
    <Popper
      open={!!open}
      anchorEl={anchorEl}
      role={undefined}
      placement={placement}
      transition
      disablePortal
      className={classes.popper}
      style={style}
    >
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps}>
          <Paper classes={{ root: classes.popoverPaper }}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList autoFocusItem={open} id="user-dropdown" disablePadding>
                {MenuItemComponent}
                <Popover
                  open={openDatepicker}
                  anchorEl={dateAnchor}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <DateTimePicker
                    onSubmit={dateSubmitHandler}
                    defaultDate={defaultDate}
                    disablePast={disablePast}
                    hideTimePicker={hideTimePicker}
                  />
                </Popover>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
