import { makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "../constants/Style";
import { Colors as MelpColors } from "@melp-design/style";

const common = {
  "& .MuiTableCell-head": {
    "& > div": {
      whiteSpace: "normal",
      lineHeight: 1.4,
    },
  },
  "& .MuiTableCell-root": {
    "& .MuiToggleButtonGroup-root": {
      "& .MuiToggleButton-root": {
        fontSize: 14,
        border: "none",
        width: 50,
        height: 28,
        color: Colors.LightGray,
      },
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)": {
      borderRadius: 30,
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      borderRadius: 30,
      marginLeft: 5,
    },
  },
  "& .MuiTableCell-footer": {
    textAlign: "right",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};

export default makeStyles((theme: Theme) => ({
  inactiveRow: {
    color: `${MelpColors.greyHover} !important`,
  },
  table: {
    ...common,
    "& .MuiTableFooter-root": {
      backgroundColor: "transparent !important",
    },
    "& .MuiTableRow-footer": {
      clipPath: "inset(0px 0px 0px round 0px 0px 8px 8px)",
      background: Colors.White,
    },
  },
  tableWithStatus: {
    ...common,
    "& .MuiTableHead-root": {
      background: "transparent",
    },
    "& .MuiTableFooter-root": {
      backgroundColor: "transparent !important",
      position: "relative",
      zIndex: 200,
    },
    "& .MuiTableRow-footer": {
      clipPath: "inset(0px 0px 0px round 0px 0px 8px 8px)",
      background: Colors.White,
    },
    "& .MuiTableCell-root.MuiTableCell-body:last-child:not(:first-child)": {
      width: 209,
      padding: 0,
    },
  },
  tableWithImplementation: {
    ...common,
    "& .MuiTableHead-root": {
      background: "transparent",
    },
    "& .MuiTableFooter-root": {
      backgroundColor: "transparent !important",
    },
    "& .MuiTableRow-footer": {
      clipPath: "inset(0px 0px 0px round 0px 0px 8px 8px)",
      background: Colors.White,
    },
    "& .MuiTableCell-root.MuiTableCell-body:last-child:not(:first-child)": {
      width: 166,
      padding: 0,
    },
  },
  tableWrapper: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    borderRadius: "8px",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  yScrollableTableWrapper: {
    overflowY: "auto",
    maxHeight: 490,
  },
  rowSelected: {
    backgroundColor: `${Colors.LightPrimary} !important`,
  },
  assignEmployeesRow: {
    "& .MuiTableCell-root:last-child": {
      width: 115,
    },
  },
  disableTable: {
    "& .MuiTableCell-root.MuiTableCell-body:last-child:not(:first-child)": {
      pointerEvents: "none",
    },
  },
  disableFullTable: {
    "& .MuiTableCell-root.MuiTableCell-body": {
      pointerEvents: "none",
    },
  },
  hoveredCellWithFilters: {
    background: Colors.Default,
  },
}));
