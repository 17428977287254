import { RouteItem } from "../types/Common";
import { lazy, useMemo } from "react";
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionLevels,
} from "./Administrators";

const Home = lazy(() => import("../containers/Home/Home"));
const BenefitsTable = lazy(
  () => import("../containers/Benefits/BenefitsTable/BenefitsTable")
);
const BenefitsInner = lazy(
  () => import("../containers/Benefits/BenefitsInner/BenefitsInner")
);
const CompanySettings = lazy(
  () => import("../containers/CompanySettings/CompanySettings")
);
const ChoicesTable = lazy(() => import("../containers/Choices/Choices"));
const AdministratorsInner = lazy(
  () =>
    import(
      "../containers/CompanySettings/views/Administrators/AdministratorsInner/AdministratorsInner"
    )
);
const EmployeesTable = lazy(
  () => import("../containers/Employees/EmployeesTable/EmployeesTable")
);
const EmployeesInner = lazy(
  () => import("../containers/Employees/EmployeesInner/EmployeesInner")
);
const NewsTable = lazy(() => import("../containers/News/NewsTable/NewsTable"));
const NewsInner = lazy(() => import("../containers/News/NewsInner/NewsInner"));

const ChangePassword = lazy(
  () => import("../containers/ChangePassword/ChangePassword")
);

const TermsAcceptanceTable = lazy(
  () => import("../containers/TermsAcceptance/TermsAcceptance")
);

const Dashboard = lazy(
  () => import("../containers/Analytics/Dashboard/Dashboard")
);

const CompareMode = lazy(
  () => import("../containers/Analytics/CompareMode/CompareMode")
);

const Login = lazy(() => import("../containers/Login/Login"));

const NewPassword = lazy(() => import("../containers/NewPassword/NewPassword"));

const Log = lazy(() => import("../containers/Log/Log"));

const GroupsTable = lazy(
  () => import("../containers/Groups/GroupsTable/GroupsTable")
);

const GroupsInner = lazy(
  () => import("../containers/Groups/GroupsInner/GroupsInner")
);

const TwoFactor = lazy(() => import("../containers/TwoFactor/TwoFactor"));

const ForgotPassword = lazy(
  () => import("../containers/ForgotPassword/ForgotPassword")
);

const ResetPassword = lazy(
  () => import("../containers/ResetPassword/ResetPassword")
);

const Clients = lazy(
  () => import("../containers/Clients/ClientsTable/ClientsTable")
);

const ClientsInner = lazy(
  () => import("../containers/Clients/ClientsInner/ClientsInner")
);

const ClientsAdministrator = lazy(
  () => import("../containers/ClientsAdministrators/ClientsAdministrators")
);

const Users = lazy(() => import("../containers/Users/UsersTable/UsersTable"));

const IntegrationTypes = lazy(
  () => import("../containers/Integrations/IntegrationTypes/IntegrationTypes")
);

const IntegrationType = lazy(
  () => import("../containers/Integrations/IntegrationType/IntegrationType")
);

const BenefitTemplates = lazy(
  () =>
    import("../containers/BenefitTemplate/BenefitTemplates/BenefitTemplates")
);

const BenefitTemplate = lazy(
  () => import("../containers/BenefitTemplate/BenefitTemplate/BenefitTemplate")
);

const SupplierPage = lazy(
  () => import("../containers/Suppliers/Supplier/SupplierPage")
);
const SuppliersPage = lazy(
  () => import("../containers/Suppliers/Suppliers/SuppliersPage")
);
const MarketplaceItemsPage = lazy(
  () =>
    import(
      "../containers/MarketplaceItems/MarketplaceItems/MarketplaceItemsPage"
    )
);
const MarketplaceItemPage = lazy(
  () =>
    import("../containers/MarketplaceItems/MarketplaceItem/MarketplaceItemPage")
);
const OrdersPage = lazy(() => import("../containers/Orders/Orders/OrdersPage"));
const OrderPage = lazy(() => import("../containers/Orders/Order/OrderPage"));
const IntegrationLogsPage = lazy(
  () => import("../containers/Integrations/IntegrationLogs/IntegrationLogsPage")
);
const MarketplacePage = lazy(
  () => import("../containers/Marketplace/MarketplaceDeals/MarketplacePage")
);
const MarketplaceOrdersPage = lazy(
  () =>
    import("../containers/Marketplace/MarketplaceOrders/MarketplaceOrdersPage")
);

export const useRoutes = () =>
  useMemo(
    () =>
      [
        {
          path: "/",
          component: Home,
          roles: ["hrAdmin"],
        },
        {
          path: ["/benefits/all", "/benefits/:category"],
          component: BenefitsTable,
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.Benefits,
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/benefits/view/:id",
          component: BenefitsInner,
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.Benefits,
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/more/company-settings",
          component: CompanySettings,
          roles: ["hrAdmin"],
          module: [
            GlobalPermissionModules.Administrators,
            GlobalPermissionModules.OrganisationStructure,
          ],
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/more/company-settings/integrations/:id/logs",
          component: IntegrationLogsPage,
          roles: ["hrAdmin"],
          module: [
            GlobalPermissionModules.Administrators,
            GlobalPermissionModules.OrganisationStructure,
          ],
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: ["/choices"],
          component: ChoicesTable,
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.FlexBenefit,
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/administrators/:id",
          component: AdministratorsInner,
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.Administrators,
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/employees",
          component: EmployeesTable,
          roles: ["hrAdmin"],
          module: LocalPermissionModules.Employees,
          permissionLevel: PermissionLevels.Local,
        },
        {
          path: "/employees/view/:id",
          component: EmployeesInner,
          roles: ["hrAdmin"],
          module: LocalPermissionModules.Employees,
          permissionLevel: PermissionLevels.Local,
        },
        {
          path: "/change-password",
          component: ChangePassword,
          roles: ["hrAdmin", "melpAdmin"],
        },
        {
          path: "/news",
          component: NewsTable,
          roles: ["hrAdmin"],
          module: LocalPermissionModules.News,
          permissionLevel: PermissionLevels.Local,
        },
        {
          path: "/more/terms-acceptance",
          component: TermsAcceptanceTable,
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.Benefits,
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/analytics/dashboard",
          component: Dashboard,
          roles: ["hrAdmin"],
          module: LocalPermissionModules.Dashboard,
          permissionLevel: PermissionLevels.Local,
        },
        {
          path: "/analytics/compare",
          component: CompareMode,
          roles: ["hrAdmin"],
          module: LocalPermissionModules.Dashboard,
          permissionLevel: PermissionLevels.Local,
        },
        {
          path: "/more/log",
          component: Log,
          roles: ["hrAdmin"],
          module: LocalPermissionModules.History,
          permissionLevel: PermissionLevels.Local,
        },
        {
          path: "/login",
          component: Login,
          public: true,
        },
        {
          path: "/new-password",
          component: NewPassword,
          public: true,
        },
        {
          path: "/groups",
          component: GroupsTable,
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.EmployeeGroups,
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/groups/view/:id",
          component: GroupsInner,
          roles: ["hrAdmin"],
          module: [
            GlobalPermissionModules.Benefits,
            LocalPermissionModules.Employees,
          ],
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/news/view/:id",
          component: NewsInner,
          roles: ["hrAdmin"],
          module: LocalPermissionModules.News,
          permissionLevel: PermissionLevels.Local,
        },
        {
          path: "/two-factor",
          component: TwoFactor,
          public: true,
        },
        {
          path: "/forgot-password",
          component: ForgotPassword,
          public: true,
        },
        {
          path: "/reset-password",
          component: ResetPassword,
          public: true,
        },
        {
          path: "/clients",
          component: Clients,
          roles: ["melpAdmin"],
        },
        {
          path: "/clients/view/:id",
          component: ClientsInner,
          roles: ["melpAdmin"],
        },
        {
          path: "/clients/view/:clientId/administrators/:adminId",
          component: ClientsAdministrator,
          roles: ["melpAdmin"],
        },
        {
          path: "/integration-types",
          component: IntegrationTypes,
          roles: ["melpAdmin"],
        },
        {
          path: "/integration-types/:id",
          component: IntegrationType,
          roles: ["melpAdmin"],
        },
        {
          path: "/users",
          component: Users,
          roles: ["melpAdmin"],
        },
        {
          path: "/benefit-templates",
          component: BenefitTemplates,
          roles: ["melpAdmin"],
        },
        {
          path: "/benefit-templates/:id",
          component: BenefitTemplate,
          roles: ["melpAdmin"],
        },
        {
          path: "/admin/marketplace/suppliers",
          component: SuppliersPage,
          roles: ["melpAdmin"],
        },
        {
          path: "/admin/marketplace/suppliers/:id",
          component: SupplierPage,
          roles: ["melpAdmin"],
        },
        {
          path: "/admin/marketplace/items",
          component: MarketplaceItemsPage,
          roles: ["melpAdmin"],
        },
        {
          path: "/admin/marketplace/items/:id",
          component: MarketplaceItemPage,
          roles: ["melpAdmin"],
        },
        {
          path: "/admin/marketplace/orders",
          component: OrdersPage,
          roles: ["melpAdmin"],
        },
        {
          path: "/admin/marketplace/orders/:id",
          component: OrderPage,
          roles: ["melpAdmin"],
        },
        {
          path: "/marketplace/deals",
          component: MarketplacePage,
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.Benefits,
          permissionLevel: PermissionLevels.Global,
        },
        {
          path: "/marketplace/orders",
          component: MarketplaceOrdersPage,
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.Benefits,
          permissionLevel: PermissionLevels.Global,
        },
      ] as RouteItem[],
    []
  );

export const usePublicRoutes = () => {
  const routes = useRoutes();
  return useMemo(
    () => routes.filter((route) => route.public).map((route) => route.path),
    [routes]
  );
};
