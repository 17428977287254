import { Box, BoxProps, Paper, PaperProps } from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import useStyles from "./SidebarInner.styles";

const SidebarInner = ({
  children,
  width = 285,
  position,
  ...rest
}: PaperProps & BoxProps) => {
  const classes = useStyles();
  const [y, setY] = useState<number>(window.scrollY);
  const [topPos, setTopPos] = useState<number>(0);
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const isFixed = useMemo(() => {
    const windowHeight = window.innerHeight;
    const sidebarHeight = Number(ref?.current?.offsetHeight);
    if (windowHeight < sidebarHeight || !!position) return false;

    return !!topPos && y > topPos - 45;
  }, [position, topPos, y]);

  const handleScrollPosition = useCallback((e: Event) => {
    const window = e.currentTarget as Window;
    setY(window.scrollY);
  }, []);

  useLayoutEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleScrollPosition);

    return () => {
      window.removeEventListener("scroll", handleScrollPosition);
    };
  }, [handleScrollPosition]);

  useEffect(() => {
    const pos = Number(ref?.current?.offsetTop);
    if (!isNaN(pos)) setTopPos(pos);
  }, []);

  return (
    <Paper
      component={Box}
      ref={ref}
      sx={{ width: width, position: position }}
      className={clsx({
        [classes.fixed]: isFixed,
      })}
      {...rest}
    >
      {children}
    </Paper>
  );
};

export default SidebarInner;
