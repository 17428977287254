import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import { ReactComponent as MelpLogo } from "../../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import { useMe } from "../../../state/Administrators";

export default function Logo() {
  const { isMelpAdmin } = useMe();

  const navTo = useMemo(() => (isMelpAdmin ? "/clients" : "/"), [isMelpAdmin]);

  return (
    <NavLink to={navTo}>
      <Box marginRight={4} style={{ height: 27 }}>
        <MelpLogo />
      </Box>
    </NavLink>
  );
}
