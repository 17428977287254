import React, { useCallback, useMemo, useRef } from "react";
import {
  MpControlProps,
  controlRegisterOptions,
  ValidationKeys,
} from "@mp-react/form";
import { Controller } from "react-hook-form";
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { ReactComponent as AddPhotoIcon } from "../../../assets/icons/add-photo.svg";
import useStyles from "./ImageUpload.styles";
import { useFiles } from "../../../state/Files";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import { FormHelperText } from "@material-ui/core";

function ImageView({
  value,
  onChange,
  control,
  error,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const hiddenFileInput = useRef(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const { uploadFile, file, loading } = useFiles((value as string) ?? "");
  const preview = useMemo(() => file?.signedUrl ?? null, [file?.signedUrl]);
  const { tooltip, closeTooltip, openTooltip, anchorEl } = useTooltip(control);

  const handleBrowse = useCallback(() => {
    if (!preview) {
      const el = hiddenFileInput.current as any;
      el.click();
    }
  }, [preview]);

  const handleUploadFile = useCallback(
    async (e: any) => {
      e.stopPropagation();
      const { files } = e.target;
      if (files?.length > 0) {
        const file = files[0];
        const uploadedFile = await uploadFile(file);
        const uploadedFileId = uploadedFile?.id;
        onChange(uploadedFileId);
      }
      closeTooltip();
    },
    [onChange, closeTooltip, uploadFile]
  );

  const clearImageInput = useCallback(() => {
    onChange(null);
    (hiddenFileInput.current as any).value = "";
    closeTooltip();
  }, [onChange, closeTooltip]);

  if (loading) return <CircularProgress size={40} />;

  return (
    <Box position="relative">
      <Box
        width={160}
        height={120}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        className={clsx({ [classes.emptyImageBox]: !preview })}
        onClick={handleBrowse}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
      >
        {!preview && <AddPhotoIcon />}
        {preview && (
          <Box position="relative" display="flex" width="100%" height="100%">
            <IconButton onClick={clearImageInput} className={classes.clearIcon}>
              <CrossIcon />
            </IconButton>
            <Box
              className={classes.img}
              style={{ backgroundImage: `url(${preview ?? "/"})` }}
            />
          </Box>
        )}
      </Box>
      <input
        accept="image/png, image/jpeg"
        type="file"
        ref={hiddenFileInput}
        onChange={handleUploadFile}
        style={{ display: "none" }}
      />
      {!preview && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          paddingTop={1}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 11 }}
          >
            {t("common.max_upload_size")} 25Mb
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 11 }}
          >
            {t("common.accepted_formats")} jpeg, png
          </Typography>
        </Box>
      )}
      <FormHelperText error>
        {error?.type
          ? control?.messages?.[error.type as ValidationKeys]
          : undefined}
      </FormHelperText>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function ImageUpload({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value }) => (
        <ImageView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
