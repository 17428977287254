import { useTranslation } from "react-i18next";
import { MpControlType, MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { CustomFormControl } from "../../types/Common";
import { useDatePlaceholder } from "../../utils/Common";
import { useCurrency } from "../../utils/useCurrency";

export const useFlexBenefitBalanceForm = (): MpFormType => {
  const { t } = useTranslation();
  const { datePlaceholder } = useDatePlaceholder();
  const { currency } = useCurrency();

  const controls = useMemo(
    (): CustomFormControl[] => [
      {
        type: "date" as any,
        key: "date",
        messages: {
          required: t("form.required"),
        },
        tooltip: "tooltip.flex_benefit_balance_date",
        placeholder: datePlaceholder,
        label: t("common.date"),
        required: true,
        disablePast: true,
      },
      {
        type: MpControlType.number,
        key: "allocated",
        placeholder: "0",
        negative: true,
        messages: {
          required: t("form.required"),
          pattern: t("errors.incorrect_value"),
        },
        validations: {
          pattern: "^\\-?[1-9]\\d*$",
        },
        label: t("employees.amount"),
        endAdornment: currency,
        required: true,
        tooltip: "tooltip.flex_benefit_balance_allocated",
      },
      {
        type: MpControlType.multiline,
        key: "details",
        label: t("employees.details"),
        required: true,
        validations: {
          minLength: 2,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        placeholder: t("common.comments"),
        tooltip: "tooltip.flex_benefit_balance_details",
      },
    ],
    [t, datePlaceholder, currency]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
