import { MpColumn, MpTableType, MpColumnFormat } from "@mp-react/table";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { CustomRendererType } from "../../types/Table";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useEmployeeInnerFlexBalanceTableType = (): MpTableType => {
  const { t } = useTranslation();
  const { keyWithSuffix } = useLocalStorage();

  const columns = useMemo(
    (): MpColumn[] => [
      {
        label: t("common.date"),
        key: "date",
        format: MpColumnFormat.date,
        sort: false,
        // width: 110,
        // fixedHeadWidth: true,
      },
      {
        label: t("employees.details"),
        key: "details",
        sort: false,
        render: CustomRendererType.benefitBalanceDetails as any,
        // width: 380,
        // fixedHeadWidth: true,
      },
      {
        label: t("employees.allocated"),
        key: "allocated",
        sort: false,
        render: CustomRendererType.currency as any,
        // width: 154,
        // fixedHeadWidth: true,
      },
      {
        label: t("employees.reserved"),
        key: "reserved",
        sort: false,
        render: CustomRendererType.currency as any,
        // width: 152,
        // fixedHeadWidth: true,
      },
      {
        label: "",
        key: "additionalTotal",
        sort: false,
        // width: 105,
        // fixedHeadWidth: true,
      },
    ],
    [t]
  );

  return {
    columns,
    rowIdKey: "id",
    totals: true,
    stickyTotals: true,
    disablePagination: true,
    localFiltering: true,
    storageIdentifier: keyWithSuffix(StorageKeys.employeeBalanceTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
