import { useTranslation } from "react-i18next";
import { useLoading } from "../utils/Loading";
import { Languages } from "../types/Common";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Endpoints } from "../api/constants";
import useSWR, { mutate } from "swr";
import { UseParentCompany } from "../types/ParentCompany";
import { toast } from "react-toastify";
import { mutateData } from "../api/api";
import { useMe } from "./Administrators";
import { useAuth } from "./Auth";

const initialUseParentCompanyValues: UseParentCompany = {
  parentCompanyLoading: false,
  parentCompanyError: false,
  companyId: "",
  updateDefaultLanguage: () => {},
};

const parentCompanyContext = createContext(initialUseParentCompanyValues);

export const useProvideParentCompany = (): UseParentCompany => {
  const { isMelpAdmin } = useMe();
  const { isLoggedIn, hasToken } = useAuth();
  const canGetParentCompany = useMemo(
    () => isLoggedIn && hasToken && !isMelpAdmin,
    [hasToken, isLoggedIn, isMelpAdmin]
  );
  const url = useMemo(
    () => (canGetParentCompany ? Endpoints.getOwnCompany : null),
    [canGetParentCompany]
  );
  const { data: parentCompany, error } = useSWR(url);
  const { startLoading, stopLoading, loading } = useLoading();
  const { t } = useTranslation();

  const parentCompanyLoading = useMemo(
    () => (!parentCompany && !error) || loading,
    [error, loading, parentCompany]
  );

  const updateDefaultLanguage = useCallback(
    async (language: Languages) => {
      startLoading();
      const parsedData = { defaultLanguage: language };
      await mutateData(
        "patch",
        `${Endpoints.updateDefaultLanguage}`,
        parsedData
      )
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(Endpoints.getOwnCompany);
          mutate(Endpoints.me);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t]
  );

  return {
    parentCompany,
    parentCompanyLoading,
    parentCompanyError: error,
    companyId: parentCompany?.id ?? "",
    updateDefaultLanguage,
  };
};

export const ParentCompanyProvider = ({ children }: PropsWithChildren<{}>) => {
  const parentCompany = useProvideParentCompany();
  return (
    <parentCompanyContext.Provider value={parentCompany}>
      {children}
    </parentCompanyContext.Provider>
  );
};

export const useParentCompany = (): UseParentCompany => {
  return useContext(parentCompanyContext);
};
