import React from "react";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  plainTextContainer: {
    whiteSpace: "pre-line",
  },
}));

interface Props {
  /**
   * Text to display. Should be HTML string.
   */
  value?: string;
  /**
   * Removes text styling except new lines.
   */
  plainText?: boolean;
}

/**
 * Component for displaying a rich text created with RichTextEditor component.
 */
const RichText = (props: Props) => {
  const classes = useStyles();
  if (!props.value) {
    return null;
  }
  if (props.plainText) {
    const container = document.createElement("div");
    container.innerHTML = props.value.replace("<br>", "\n");
    const plainText = container.textContent || container.innerText || "";
    return <span className={classes.plainTextContainer}>{plainText}</span>;
  }
  return <RichTextEditor value={props.value} displayMode />;
};

export default RichText;
