import { makeStyles } from "@material-ui/core/styles";
import { getBorder, Colors } from "../../../../constants/Style";

export default makeStyles(() => ({
  container: {
    minWidth: 358,
  },
  naked: {
    width: "100%",
  },
  emptyAnchor: {
    cursor: "pointer",
    userSelect: "none",
  },
  keyboardDatePicker: {
    "& button": {
      padding: 0,
    },
  },
  keyboardDatePickerContainer: {
    padding: "8px 16px",
    maxWidth: 179,
  },
  keyboardTimePickerContainer: {
    borderLeft: getBorder(Colors.Divider),
    maxWidth: 179,
  },
  keyboardTimePicker: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 16,
      paddingTop: 14,
      paddingLeft: 16,
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .PrivateNotchedOutline-root-65": {
        borderWidth: 0,
      },
      "& .MuiOutlinedInput-input": {
        padding: 0,
      },
      "& .MuiIconButton-root": {
        padding: "12px 4px",
        marginRight: -4,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
      },
    },
  },
}));
