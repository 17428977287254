import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      "& .MuiInputBase-root": {
        minHeight: 44,
      },
    },
    placeholder: {
      top: -6,
      "&.MuiInputLabel-shrink": {
        display: "none",
      },
    },
  })
);
