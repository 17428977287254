import { CheckAssignConflictsResponse } from "../types/Common";
import { useInvestmentCalculationsStore } from "./InvestmentCalculations";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutateData } from "../api/api";
import { useLoading } from "../utils/Loading";
import { useCallback, useMemo } from "react";
import {
  BenefitsAssignEmployeeTable,
  AssignEmployeesRequest,
  ToggleAssignmentStatus,
} from "../types/Benefits";
import useSWR, { mutate } from "swr";
import { Endpoints } from "../api/constants";
import { useCurrency } from "../utils/useCurrency";
import { useMe } from "./Administrators";
import { MpAsyncGetMethod } from "@mp-react/table";
import { BenefitAssignEmployeesFilterNames } from "../constants/Benefits";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { AxiosResponse } from "axios";
import { CustomColumn } from "../types/Employees";
import { getFetchUrlWithId } from "../utils/FetchUrl";

export const useBenefitsAssignEmployees = (
  benefitPlanId?: string,
  query?: string
) => {
  const { hasEmployeePermissions } = useMe();
  const { t } = useTranslation();
  const { loading, startLoading, stopLoading } = useLoading();
  const statuses = useInvestmentCalculationsStore((state) => state.statuses);
  const resetStatusChanges = useInvestmentCalculationsStore(
    (state) => state.resetStatusChanges
  );
  const resetAllChanges = useInvestmentCalculationsStore(
    (state) => state.resetAllChanges
  );
  const { getDefaultCurrencyFormat } = useCurrency();

  const url = useMemo(() => {
    return getFetchUrlWithId(
      `${Endpoints.benefitPlans}/${benefitPlanId}/employeeAssignments`,
      query,
      benefitPlanId
    );
  }, [benefitPlanId, query]);

  const {
    data: assignEmployeesResponse,
    error: assignEmployeesError,
    mutate: mutateAssignEmployees,
  } = useSWR<BenefitsAssignEmployeeTable, any>(
    hasEmployeePermissions ? url : null
  );

  const assignEmployeesData = useMemo(
    () =>
      assignEmployeesResponse?.data?.map((item) => ({
        ...item,
        inactive: item.currentStatus === "off",
        customColumn: (item?.customColumn as CustomColumn)?.name,
      })) ?? [],
    [assignEmployeesResponse?.data]
  );

  const apiLoading = useMemo(
    () => !assignEmployeesResponse && !assignEmployeesError,
    [assignEmployeesError, assignEmployeesResponse]
  );

  const parsedBenefitAssignEmployeesTotals = useMemo(() => {
    const initialTotals = {
      fullName: "",
      investment: "",
      employeeGroup: "",
      currentStatus: "",
    };
    if (!assignEmployeesResponse?.footer) return initialTotals;
    const { fullName, investment, employeeGroup, currentStatus, customColumn } =
      assignEmployeesResponse.footer;
    return {
      fullName: t("totals.employee", { count: Number(fullName) }),
      employeeGroup: t("totals.employee_group", {
        count: Number(employeeGroup),
      }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
      currentStatus: `${currentStatus} ${t("status.off")}`,
      customColumn: t("totals.custom_category", {
        count: Number(customColumn ?? 0),
      }),
    };
  }, [assignEmployeesResponse, t, getDefaultCurrencyFormat]);

  const mapAssignEmployeesRequest = useCallback(
    (date?: string): AssignEmployeesRequest[] => {
      const statusEntries: [string, ToggleAssignmentStatus][] = Object.entries(
        statuses?.currentStatus
      );
      const fromDate = date ?? moment().toISOString();
      return statusEntries.map(([employeeId, statusObject]) => {
        const statusEntryObj: AssignEmployeesRequest = {
          benefitPlanId: benefitPlanId as string,
          fromDate,
          toDelete: false,
          employeeId,
          status: statusObject?.value ?? "on",
        };
        const isToday = moment(fromDate).isSame(moment(), "day");
        if (!isToday) {
          const dateStatus = statusObject?.currentRowStatuses?.find((status) =>
            moment(status.fromDate).isSame(moment(fromDate), "day")
          );
          if (!!dateStatus) statusEntryObj.id = dateStatus?.id;
        }
        if (isToday && !!statusObject.assignmentId)
          statusEntryObj.id = statusObject.assignmentId;
        return statusEntryObj;
      });
    },
    [benefitPlanId, statuses?.currentStatus]
  );

  const assignEmployees = useCallback(
    async (data: AssignEmployeesRequest[]) => {
      startLoading();
      await mutateData("post", Endpoints.assignEmployees, data)
        .then(async () => {
          toast(t("common.assigned_succesfully"), { type: "success" });
          await mutate(url);
        })
        .then(() => {
          resetStatusChanges();
          resetAllChanges();
        })
        .then(
          async () =>
            !!benefitPlanId &&
            (await mutate(`${Endpoints.benefitPlans}/${benefitPlanId}`))
        )
        .finally(() => stopLoading());
    },
    [
      startLoading,
      t,
      url,
      resetStatusChanges,
      resetAllChanges,
      benefitPlanId,
      stopLoading,
    ]
  );

  const checkAssignEmployeeChanges = useCallback(
    async ({
      newStatus,
      employeeId,
      benefitPlanId,
    }: {
      newStatus: "on" | "off" | "flex";
      employeeId: string;
      benefitPlanId: string;
    }): Promise<CheckAssignConflictsResponse> => {
      return mutateData("post", Endpoints.checkBenefitPlanToEmployeeChanges, {
        benefitPlanId,
        employeeId,
        newStatus,
      }).then((res) => res.data as CheckAssignConflictsResponse);
    },
    []
  );

  return {
    loading: loading || (!!benefitPlanId && apiLoading),
    assignEmployeesResponse,
    assignEmployeesData,
    assignEmployees,
    mapAssignEmployeesRequest,
    checkAssignEmployeeChanges,
    parsedBenefitAssignEmployeesTotals,
    mutateAssignEmployees,
  };
};

export const useBenefitAssignEmployeesAsyncMethods = (
  query?: string,
  benefitPlanId?: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () =>
      !!benefitPlanId
        ? `${Endpoints.benefitPlans}/${benefitPlanId}/employeeAssignments/filterValues`
        : "",
    [benefitPlanId]
  );

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: BenefitAssignEmployeesFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const queryParams = `${
        !!query && !!searchParam ? `&${query}` : !!query ? `?${query}` : ""
      }`;
      const apiUrl = `${baseUrl}/${filterName}${searchParam}${queryParams}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, query]
  );

  const getFilterItems = useCallback(
    (filterName: BenefitAssignEmployeesFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}${!!query ? `?${query}` : ``}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, query]
  );

  const getFullNames = useCallback<MpAsyncGetMethod>(
    (args) =>
      getAsyncFilterItems(args, BenefitAssignEmployeesFilterNames.FULL_NAME),
    [getAsyncFilterItems]
  );

  const getEmployeeGroups = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitAssignEmployeesFilterNames.EMPLOYEE_GROUPS),
    [getFilterItems]
  );

  const getCustomColumnCategories = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitAssignEmployeesFilterNames.CUSTOM_COLUMN),
    [getFilterItems]
  );

  return {
    getFullNames,
    getEmployeeGroups,
    getCustomColumnCategories,
  };
};
