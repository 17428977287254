import { TableDataResponse } from "./Table";
import { Currency } from "./Currency";
import { SupportedLanguages } from "./Common";
import { BenefitCategory } from "./Benefits";
import { UploadedFile } from "./Files";

interface ItemPriceTranslation {
  description: string;
  id: string;
  language: string;
}
export interface ItemPrice {
  discountPercentage: number;
  id: string;
  price: number | null;
  priceRangeFrom: number | null;
  priceRangeTo: number | null;
  priceType: PriceOptionType;
  translations?: ItemPriceTranslation[];
}

type MarketplaceItemStatus = "active" | "inactive" | "archived";

export interface MarketplaceItem {
  id: string;
  name: string;
  image?: UploadedFile;
  sku: string;
  prices?: ItemPrice[];
  currency: Currency;
  countries: string[];
  benefitPlanTypes: BenefitCategory[];
  categories: MarketplaceItemCategory[];
  status: MarketplaceItemStatus;
  creationDate: string;
  supplier: {
    id: string;
    name: string;
    logo?: UploadedFile;
    termsAndConditionsLink?: string;
  };
  translations: MarketplaceItemTranslation[];
}

export enum MarketplaceItemCategory {
  LEISURE = "leisure",
  ENTERTAINMENT = "entertainment",
  FOOD_AND_GROCERY = "food_and_grocery",
  ELECTRONICS = "electronics",
  MEDICAL = "medical",
  HEALTH_AND_BEAUTY = "health_and_beauty",
  FURNITURE_AND_HOME_DECOR = "furniture_and_home_decor",
  EVENTS_AND_CONFERENCES = "events_and_conferences",
  SPORT_ACTIVITIES = "sport_activities",
  SPORTS_ACCESSORIES = "sports_accessories",
  TRAVELLING_AND_TRANSPORTATION = "travelling_and_transportation",
  EDUCATION = "education",
  ONLINE_SUPERMARKETS = "online_supermarkets",
  INSURANCE = "insurance",
}

interface SingleMarketplaceItemResponse extends MarketplaceItem {
  autoAssigned?: boolean;
}

export type MarketplaceItemsResponse =
  TableDataResponse<SingleMarketplaceItemResponse>;

export interface MarketplaceItemCreationRequestBody {
  name: string;
  imageId?: string;
  currency: Currency;
  vatRate: number;
  countries: string[];
  benefitPlanTypes: BenefitCategory[];
  categories: MarketplaceItemCategory[];
  status: MarketplaceItemStatus;
  price: number;
}

export interface MarketplaceItemUpdateRequestBody
  extends Partial<MarketplaceItemCreationRequestBody> {}

export interface MarketplaceItemTranslation {
  id: string;
  language: string;
  title: string;
  description: string;
  creationDate: string;
  lastModificationDate: string;
}

export interface MarketplaceItemTranslationCreationRequestBody {
  language: string;
  title: string;
  description: string;
}

export interface MarketplaceItemTranslationUpdateRequestBody
  extends MarketplaceItemTranslationCreationRequestBody {}

interface BenefitMarketplaceItem extends MarketplaceItem {
  assignment?: {
    autoAssigned?: boolean;
    assignmentDate?: string;
  };
}

export type BenefitMarketplaceItemsResponse =
  TableDataResponse<BenefitMarketplaceItem>;

export interface MarketplaceItemsFilterParams {
  name?: string;
  priceFrom?: number;
  priceTo?: number;
  countries?: string[];
  benefitPlanTypes?: BenefitCategory[];
  categories?: MarketplaceItemCategory[];
  supplier?: string;
}
export interface MarketplaceItemsParams extends MarketplaceItemsFilterParams {
  sort?: MarketplaceItemsSort;
  page?: number;
}

export type MarketplaceItemsSort = "price" | "-price";

interface MarketplaceItemWithSelection extends MarketplaceItem {
  inSelection: boolean;
}

export type MarketplaceItemsWithSelectionResponse =
  TableDataResponse<MarketplaceItemWithSelection>;

export interface AutoAssignmentFilterResponse {
  assignmentFilter: MarketplaceItemsFilterParams;
  benefitPlanId: string;
  creationDate: string;
  id: string;
  lastModificationDate: string;
}

export type PriceOptionType = "fixedPrice" | "priceRange";
export interface MarketplaceItemPrice {
  creationDate: string;
  discountPercentage: number;
  id: string;
  lastModificationDate: string;
  marketplaceItemId: string;
  price: number | null;
  priceRangeFrom: number | null;
  priceRangeTo: number | null;
  priceType: PriceOptionType;
}

export type MarketplaceItemPricesResponse = MarketplaceItemPrice[];
export type MarketplaceItemPriceResponse = MarketplaceItemPrice;

export interface MarketplaceItemPriceCreationRequest {
  priceType: PriceOptionType;
  price: number | null;
  priceRangeFrom: number | null;
  priceRangeTo: number | null;
  discountPercentage: number | null;
}

export type MarketplaceItemPriceUpdateRequest =
  MarketplaceItemPriceCreationRequest;

export interface PriceTranslation {
  creationDate: string;
  description: string;
  id: string;
  language: SupportedLanguages;
  lastModificationDate: string;
  marketplaceItemPriceId: string;
}

export type PriceTranslationsResponse = PriceTranslation[];

export interface PriceTranslationCreationRequest {
  language: string;
  description: string;
}

export type PriceTranslationUpdateRequest = PriceTranslationCreationRequest;
