import { useMemo } from "react";
import moment from "moment";

export enum LogFilterNames {
  DONE_BY_NAME = "doneByName",
  ACTION = "action",
}

export const useLogsDefaultDatepickerValue = (): string =>
  useMemo(() => {
    const from = moment().startOf("month").format("YYYY-MM-DD");
    const to = moment().endOf("month").format("YYYY-MM-DD");
    return `filter[dateFrom]=${from}&filter[dateTo]=${to}`;
  }, []);
