import { Typography, Button, Box } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import React, { useMemo, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getBorder, Colors } from "../../../constants/Style";
import StatusDialog from "../../dialogs/StatusDialog/StatusDialog";
import { UnpackNestedValue } from "react-hook-form";
import { differenceBy, isArray } from "lodash";
import { BenefitPlanContext } from "../../../containers/Benefits/BenefitsInner/BenefitsInner";
import { TableContext, useTableStore } from "../../../state/Table";
import { sanitizeFalseyValues } from "../../../utils/Common";
import {
  AssignEmployeesRequest,
  AssignToEmployeeGroupRequest,
} from "../../../types/Benefits";
import { useBenefitsEmployeeGroups } from "../../../state/BenefitsAssignGroups";
import moment from "moment";
import { useBenefitsAssignEmployees } from "../../../state/BenefitsAssignEmployees";
import { InnerEmployeeContext } from "../../../containers/Employees/EmployeesInner/EmployeesInner";
import { useEmployeeAssignBenefits } from "../../../state/EmployeeAssignBenefits";
import { GroupContext } from "../../../containers/Groups/GroupsInner/GroupsInner";
import { useGroupsAssignBenefits } from "../../../state/GroupsAssignBenefits";
import { useMe } from "../../../state/Administrators";

export default function Entries(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { value } = useRendererOptions(props);
  const { data, row } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<string>("");
  const benefitData = useContext(BenefitPlanContext);
  const employeeData = useContext(InnerEmployeeContext);
  const groupData = useContext(GroupContext);
  const { id: tableId, tableQueryParam } = useContext(TableContext);
  const currentTableVariant = useTableStore((state) => state.variant);
  const { canEditBenefits, canEditGroups, canEditEmployees } = useMe();

  const { assignEmployeeGroups, loading: assignGroupsLoading } =
    useBenefitsEmployeeGroups(
      currentTableVariant === "benefitsAssignGroups" ? tableId : undefined,
      currentTableVariant === "benefitsAssignGroups"
        ? tableQueryParam
        : undefined
    );
  const { assignEmployees, loading: assignEmployeesLoading } =
    useBenefitsAssignEmployees(
      currentTableVariant === "benefitsAssignEmployees" ? tableId : undefined,
      currentTableVariant === "benefitsAssignEmployees"
        ? tableQueryParam
        : undefined
    );
  const {
    assignBenefits: employeeAssignBenefits,
    loading: employeeAssignBenefitsLoading,
  } = useEmployeeAssignBenefits(
    currentTableVariant === "innerEmployeeAssignBenefits" ? tableId : undefined,
    currentTableVariant === "innerEmployeeAssignBenefits"
      ? tableQueryParam
      : undefined
  );
  const {
    loading: groupAssignBenefitsLoading,
    assignBenefitsToGroup: groupAssignBenefits,
  } = useGroupsAssignBenefits(
    currentTableVariant === "innerGroupAssignBenefits" ? tableId : undefined,
    currentTableVariant === "innerGroupAssignBenefits"
      ? tableQueryParam
      : undefined
  );

  const canEdit = useMemo(() => {
    switch (currentTableVariant) {
      case "benefitsAssignGroups":
        return canEditGroups && canEditBenefits;
      case "benefitsAssignEmployees":
        return canEditEmployees && canEditBenefits;
      case "innerEmployeeAssignBenefits":
        return canEditBenefits && canEditEmployees;
      case "innerGroupAssignBenefits":
        return canEditBenefits && canEditGroups;
      default:
        return false;
    }
  }, [canEditBenefits, canEditEmployees, canEditGroups, currentTableVariant]);

  const isEntries = useMemo(() => isArray(value), [value]);

  const rowId = useMemo(() => row.id, [row.id]);

  const entryLength = value?.length ?? 0;

  const onlyNumberLabelVariants = useMemo(
    () => ["benefitsAssignEmployees"],
    []
  );

  const label = useMemo(() => {
    if (onlyNumberLabelVariants.includes(currentTableVariant))
      return entryLength;
    return `${entryLength} ${t(`table.entries`, { count: entryLength })}`;
  }, [currentTableVariant, entryLength, onlyNumberLabelVariants, t]);

  const selectedRowData: any = useMemo(
    () => data?.find((item: any) => item.id === selectedRow),
    [data, selectedRow]
  );

  const title = useMemo(() => {
    if (currentTableVariant === "innerEmployeeAssignBenefits") {
      return `${employeeData.firstName} ${employeeData.lastName} + "${selectedRowData?.name}"`;
    }

    if (currentTableVariant === "innerGroupAssignBenefits") {
      return `${groupData?.name} + "${selectedRowData?.name}"`;
    }

    if (!!selectedRowData?.fullName)
      return `${selectedRowData?.fullName} + "${benefitData?.name}"`;

    if (!!selectedRowData?.name)
      return `${selectedRowData?.name} + "${benefitData?.name}"`;

    return "";
  }, [
    benefitData?.name,
    currentTableVariant,
    employeeData.firstName,
    employeeData.lastName,
    groupData?.name,
    selectedRowData?.fullName,
    selectedRowData?.name,
  ]);

  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleOpenDialog = useCallback(
    (e: any) => {
      e.stopPropagation();
      setSelectedRow(rowId);
      setOpen(true);
    },
    [rowId]
  );

  const parseAssignRequest = useCallback(
    (
      formData: Array<AssignToEmployeeGroupRequest | AssignEmployeesRequest>
    ) => {
      if (!isArray(value))
        throw new Error(
          "Statuses is not an array, check the key and try again"
        );

      const data = formData ?? [];

      const statusesTyped = !!value
        ? ([...value] as Array<
            AssignToEmployeeGroupRequest | AssignEmployeesRequest
          >)
        : ([] as Array<AssignToEmployeeGroupRequest | AssignEmployeesRequest>);

      const statusesWithISOformDates = statusesTyped.map((statusItem) => ({
        ...statusItem,
        fromDate: moment(statusItem.fromDate).endOf("day").toISOString(),
      }));

      const addedStatusItems = data.filter((statusItem) =>
        statusItem.id?.includes("newItem")
      );

      const statusItemsToAdd = addedStatusItems.map((statusItem) => {
        const statusItemCopy = { ...statusItem };
        delete statusItemCopy["id"];
        return {
          ...statusItemCopy,
        };
      });

      const existingStatusItems = data.filter(
        (statusItem) => !statusItem.id?.includes("newItem")
      );

      const removedStatusItems = differenceBy(
        statusesWithISOformDates,
        existingStatusItems,
        "id"
      );

      const statusItemsToRemove = removedStatusItems.map((statusItem) => {
        const statusItemEntries = Object.entries(statusItem);
        const filteredStatusItemEntries = statusItemEntries.filter(
          ([key]) => key !== "strength" && key !== "toDate"
        );
        const statusItemObject = Object.fromEntries(filteredStatusItemEntries);
        return {
          ...statusItemObject,
          toDelete: true,
        };
      });

      const statusItemsToChange = existingStatusItems.filter((statusItem) => {
        const foundStatusItem = statusesWithISOformDates.find(
          (apiStatusItem) => apiStatusItem.id === statusItem.id
        );
        const statusChanged = foundStatusItem?.status !== statusItem.status;
        const fromDateChanged =
          foundStatusItem?.fromDate !== statusItem.fromDate;
        return statusChanged || fromDateChanged;
      });

      return [
        ...statusItemsToAdd,
        ...statusItemsToRemove,
        ...statusItemsToChange,
      ] as Array<AssignToEmployeeGroupRequest | AssignEmployeesRequest>;
    },
    [value]
  );

  const handleBenefitsAssignGroups = useCallback(
    async (data: AssignToEmployeeGroupRequest[]) => {
      const parsedFormData = parseAssignRequest(
        data
      ) as AssignToEmployeeGroupRequest[];
      await assignEmployeeGroups(undefined, parsedFormData);
      handleCloseDialog();
    },
    [assignEmployeeGroups, handleCloseDialog, parseAssignRequest]
  );

  const handleBenefitsAssignEmployees = useCallback(
    async (data: AssignEmployeesRequest[]) => {
      const parsedFormData = parseAssignRequest(
        data
      ) as AssignEmployeesRequest[];
      await assignEmployees(parsedFormData);
      handleCloseDialog();
    },
    [assignEmployees, handleCloseDialog, parseAssignRequest]
  );

  const handleEmployeeAssignBenefits = useCallback(
    async (data: AssignEmployeesRequest[]) => {
      const parsedFormData = parseAssignRequest(
        data
      ) as AssignEmployeesRequest[];
      await employeeAssignBenefits(parsedFormData);
      handleCloseDialog();
    },
    [employeeAssignBenefits, handleCloseDialog, parseAssignRequest]
  );

  const handleGroupAssignBenefits = useCallback(
    async (data: AssignToEmployeeGroupRequest[]) => {
      const parsedFormData = parseAssignRequest(
        data
      ) as AssignToEmployeeGroupRequest[];
      await groupAssignBenefits(parsedFormData);
      handleCloseDialog();
    },
    [groupAssignBenefits, handleCloseDialog, parseAssignRequest]
  );

  const handleSubmit = useCallback(
    async (
      data: UnpackNestedValue<{
        statuses: Array<AssignToEmployeeGroupRequest | AssignEmployeesRequest>;
      }>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      const sanitizedData = sanitizeFalseyValues(data?.statuses);
      switch (currentTableVariant) {
        case "benefitsAssignGroups":
          await handleBenefitsAssignGroups(
            sanitizedData as AssignToEmployeeGroupRequest[]
          );
          break;
        case "benefitsAssignEmployees":
          await handleBenefitsAssignEmployees(
            sanitizedData as AssignEmployeesRequest[]
          );
          break;
        case "innerEmployeeAssignBenefits":
          await handleEmployeeAssignBenefits(
            sanitizedData as AssignEmployeesRequest[]
          );
          break;
        case "innerGroupAssignBenefits":
          await handleGroupAssignBenefits(
            sanitizedData as AssignToEmployeeGroupRequest[]
          );
          break;
      }
    },
    [
      currentTableVariant,
      handleBenefitsAssignEmployees,
      handleBenefitsAssignGroups,
      handleEmployeeAssignBenefits,
      handleGroupAssignBenefits,
    ]
  );

  const loading = useMemo(
    () =>
      assignGroupsLoading ||
      assignEmployeesLoading ||
      employeeAssignBenefitsLoading ||
      groupAssignBenefitsLoading,
    [
      assignEmployeesLoading,
      assignGroupsLoading,
      employeeAssignBenefitsLoading,
      groupAssignBenefitsLoading,
    ]
  );

  if (!isEntries || entryLength === 0) return null;

  return (
    <Box display="flex" justifyContent="center">
      <Button
        onClick={handleOpenDialog as any}
        style={{
          height: 28,
          borderRadius: 300,
          border: getBorder(Colors.BorderOutlinedInput),
          padding: 0,
          minWidth: onlyNumberLabelVariants.includes(currentTableVariant)
            ? 35
            : 84,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </Button>
      <StatusDialog
        onCancelClick={handleCloseDialog}
        onSubmitForm={handleSubmit}
        id="set-previous-status"
        open={open}
        title={title}
        actionLabel={t("common.confirm")}
        defaultValues={selectedRowData as any}
        disabled={loading || !canEdit}
        loading={loading}
      />
    </Box>
  );
}
