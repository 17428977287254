import { TabProps, Tab as MUITab } from "@material-ui/core";
import { useMemo } from "react";
import useStyles from "./Tab.styles";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import clsx from "clsx";
import {
  LocalPermissionModules,
  GlobalPermissionModules,
} from "../../../constants/Administrators";
import { useMe } from "../../../state/Administrators";
import { Permission } from "../../../types/Administrators";

interface IProps extends TabProps {
  hidden?: boolean;
  showTopBorder?: boolean;
  module?: LocalPermissionModules | GlobalPermissionModules;
}

const Tab = ({ hidden, showTopBorder, module, ...rest }: IProps) => {
  const classes = useStyles();
  const { getPermissionsByModule, isRoot } = useMe();

  const permissions = useMemo<Permission[]>(() => {
    if (!module) return [];
    return getPermissionsByModule(module);
  }, [getPermissionsByModule, module]);

  const hasCorrectPermissions = useMemo<boolean>(() => {
    if (isRoot) return true;
    if (permissions.length === 0) return true;

    return permissions.some(
      (permission) =>
        permission.permission === "edit" || permission.permission === "view"
    );
  }, [isRoot, permissions]);

  if (!hasCorrectPermissions) return null;

  return (
    <MUITab
      disableTouchRipple
      classes={{
        root: `${hidden && classes.hiddenTab} ${classes.tab}`,
        wrapper: classes.tabWrapper,
        selected: classes.selectedTab,
      }}
      className={clsx({
        [classes.tabBorderTop]: showTopBorder ?? true,
      })}
      icon={<ChevronRight />}
      {...rest}
    />
  );
};

export default Tab;
