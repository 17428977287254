import React, { useMemo } from "react";
import {
  Box,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { MpTablePaginatorProps } from "@mp-react/table";

export default function Paginator({
  pageIndex,
  previousPage,
  itemCount,
  nextPage,
  pageCount,
  pageSize,
  loading,
  translations,
  selectedCount,
}: MpTablePaginatorProps) {
  const hasPrevious = useMemo(() => pageIndex > 0, [pageIndex]);
  const hasNext = useMemo(
    () => (pageCount == null ? true : pageIndex < pageCount - 1),
    [pageIndex, pageCount]
  );
  // Temporarily disable as it appears to be unused
  // const infoText = useMemo(
  //   () =>
  //     [
  //       itemCount != null
  //         ? `${itemCount} ${translations?.total ?? "total"}`
  //         : null,
  //       selectedCount != null && selectedCount > 0
  //         ? `${selectedCount} ${translations?.selected ?? "selected"}`
  //         : null,
  //     ]
  //       .filter((item) => item != null)
  //       .join(" • "),
  //   [itemCount, selectedCount, translations]
  // );

  const lastPageItem = (pageIndex + 1) * pageSize;
  const lastItem = itemCount ? Math.min(lastPageItem, itemCount) : lastPageItem;

  if (itemCount === 0 || (itemCount ?? 0) <= pageSize) {
    return null;
  }

  return (
    <Box
      paddingY={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      {/* <Typography color="textSecondary" variant="body2">
        {infoText}
      </Typography> */}
      {loading && (
        <Box
          paddingX={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size="1.5em" data-qa="TableProgress" />
        </Box>
      )}
      <Box />
      <Box paddingX={1}>
        <Typography color="textSecondary" variant="body2">
          {pageIndex * pageSize + 1} - {lastItem}
          {pageCount != null ? ` ${translations?.of ?? "of"} ${itemCount}` : ""}
        </Typography>
      </Box>
      <Tooltip title={translations?.previousPage ?? "Previous"}>
        <div>
          <IconButton
            size="small"
            onClick={previousPage}
            disabled={!hasPrevious}
          >
            <ChevronLeft />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title={translations?.nextPage ?? "Next"}>
        <div>
          <IconButton size="small" onClick={nextPage} disabled={!hasNext}>
            <ChevronRight />
          </IconButton>
        </div>
      </Tooltip>
    </Box>
  );
}
