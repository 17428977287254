import { Box, Grid } from "@material-ui/core";
import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  MpControlProps,
  controlRegisterOptions,
  MpFormControl,
  MpControl,
} from "@mp-react/form";
import useStyles from "./MergedGroup.styles";
import clsx from "clsx";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useTooltip from "../../../utils/Tooltip";
import { v4 } from "uuid";
import { getEndAdornment } from "../EndAdornments";
import { FormEndAdornmentTypes } from "../../../constants/Form";

const defaultSpan = { xs: 12 };

export function MergedGroupView({
  control,
  size,
  layout,
  variant,
  locale,
  register,
  errors,
  defaultMessages,
  hookFormControl,
  overridables,
}: MpControlProps & {
  value: any;
  onChange: (...event: any[]) => void;
}) {
  const classes = useStyles();
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);

  const endAdornmentType = useMemo(
    () => control?.endAdornment,
    [control?.endAdornment]
  );

  const EndAdornment = useMemo(
    () => getEndAdornment(endAdornmentType as FormEndAdornmentTypes),
    [endAdornmentType]
  );

  return (
    <Box position="relative" display="flex" alignItems="center">
      <Grid
        container={true}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        onMouseDown={closeTooltip}
      >
        {control.children?.map((control: MpControl, i: number) => (
          <Grid
            item={true}
            key={i}
            xs={12}
            {...((control.span ?? defaultSpan) as any)}
            className={clsx({
              [classes.leftField]: i === 0,
              [classes.rightField]: i === 1,
            })}
          >
            <MpFormControl
              register={register}
              control={control}
              defaultMessages={defaultMessages}
              error={errors?.[control?.key ?? ""]}
              size={size}
              locale={locale}
              hookFormControl={hookFormControl}
              noLabel={true}
              layout={layout}
              variant={variant}
              overridables={overridables}
            />
          </Grid>
        ))}
      </Grid>
      {!!endAdornmentType && <EndAdornment control={control} />}
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function MergedGroup(props: MpControlProps) {
  const { control, hookFormControl } = props;
  const rules = useMemo(
    () => ({
      ...controlRegisterOptions(control),
      required: false,
    }),
    [control]
  );
  const key = useMemo(() => `mergeGroup-${v4()}`, []);

  return (
    <Controller
      name={key}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <MergedGroupView {...props} onChange={onChange} value={value} />
      )}
    />
  );
}
