import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 300,
      width: "100%",
    },
  })
);
