import React, { Suspense } from "react";
import { CssBaseline, StylesProvider, ThemeProvider } from "@material-ui/core";
import Melp from "../../styles/themes/Melp";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../Routes/Routes";
import Base from "../../components/layouts/Base/Base";
import { SWRConfig } from "swr";
import { SWROptions } from "../../api/config";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import { AuthProvider } from "../../state/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/common/Loader/Loader";
import { ParentCompanyProvider } from "../../state/ParentCompany";
import { MeProvider } from "../../state/Administrators";
import ScrollToTop from "../../utils/ScrollToTop";
import "../../utils/FeatureToggle";
import ErrorBoundary from "../../components/common/ErrorBoundary/ErrorBoundary";

function App() {
  return (
    <ThemeProvider theme={Melp} key="theme-key">
      <StylesProvider injectFirst>
        <I18nextProvider i18n={i18n}>
          <SWRConfig value={SWROptions}>
            <Suspense fallback={<Loader />}>
              <CssBaseline key="theme-key" />
              <Router>
                <ScrollToTop />
                <AuthProvider>
                  <MeProvider>
                    <ParentCompanyProvider>
                      <Base>
                        <ErrorBoundary>
                          <Routes />
                        </ErrorBoundary>
                      </Base>
                    </ParentCompanyProvider>
                  </MeProvider>
                </AuthProvider>
              </Router>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
              />
            </Suspense>
          </SWRConfig>
        </I18nextProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
