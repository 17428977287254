import { Box, BoxProps, Paper, PaperProps } from "@material-ui/core";
import React from "react";
import useStyles from "./FormPaper.styles";

interface IProps extends BoxProps {
  squareTopBorders?: boolean;
}

export default function FormPaper({
  children,
  squareTopBorders,
  ...rest
}: IProps & PaperProps) {
  const classes = useStyles();
  return (
    <Paper
      component={Box}
      elevation={0}
      height="100%"
      paddingX="30px"
      paddingTop="35px"
      paddingBottom="40px"
      maxWidth="600px"
      marginTop="30px"
      {...rest}
      className={squareTopBorders ? classes.bottomBorders : ""}
    >
      {children}
    </Paper>
  );
}
