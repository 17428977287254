import React from "react";
import { MarketplaceItem } from "../../../../../types/MarketplaceItems";
import { useTranslation } from "react-i18next";
import { useBenefitMarketplaceItemActions } from "../../../../../state/MarketplaceItems.clientAdmin";
import { useDialog } from "../../../../../utils/Dialog";
import ItemDetailsDialog from "../../../../../components/marketplace/ItemDetailsDialog/ItemDetailsDialog";
import BaseItemCard from "../../../../../components/marketplace/ItemCard/ItemCard";

interface Props<T> {
  item: T;
  isSelected?: boolean;
  benefitId: string;
  onSelectionCompleted?: (itemId: string) => void;
  onRemovalCompleted?: (itemId: string) => void;
  removeDisabled?: boolean;
}

const ItemCard = <T extends MarketplaceItem>({
  item,
  isSelected = false,
  benefitId,
  onSelectionCompleted = () => {},
  onRemovalCompleted = () => {},
  ...props
}: Props<T>) => {
  const { t } = useTranslation();

  const { id } = item;

  const { selectItem, selectItemData, removeItem, removeItemData } =
    useBenefitMarketplaceItemActions(benefitId, id);

  const handleItemSelection = async () => {
    await selectItem();
    onSelectionCompleted(id);
  };

  const handleItemRemoval = async () => {
    await removeItem();
    onRemovalCompleted(id);
  };

  const itemDetailsDialog = useDialog();

  return (
    <>
      <BaseItemCard
        item={item}
        onClick={itemDetailsDialog.openDialog}
        isSelected={isSelected}
        onSelect={handleItemSelection}
        onRemove={handleItemRemoval}
        loading={removeItemData.loading || selectItemData.loading}
        actionDisabled={props.removeDisabled}
        actionHint={
          props.removeDisabled
            ? t("marketplace.autoAssignedNotRemovable")
            : undefined
        }
      />
      <ItemDetailsDialog
        open={itemDetailsDialog.open}
        onClose={itemDetailsDialog.closeDialog}
        onSelect={() => {
          handleItemSelection();
          itemDetailsDialog.closeDialog();
        }}
        onRemove={() => {
          handleItemRemoval();
          itemDetailsDialog.closeDialog();
        }}
        removeDisabled={props.removeDisabled}
        isSelected={isSelected}
        item={item}
      />
    </>
  );
};

export default ItemCard;
