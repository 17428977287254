import React, { useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BenefitUsageSummaryTable from "./BenefitUsageSummaryTable";
import BenefitExpensesTable from "./BenefitExpensesTable";
import { useParams } from "react-router-dom";
import AddExpense from "./AddExpense";
import HorizontalTabs from "../../../../../components/common/HorizontalTabs/HorizontalTabs";

enum BenefitUsageTabs {
  summary = "summary",
  expenses = "expenses",
}

const BenefitUsage = () => {
  const { t } = useTranslation();
  const { id: employeeId } = useParams<{ id: string }>();
  const [currentTab, setCurrentTab] = useState(BenefitUsageTabs.summary);
  const tabs = [
    {
      key: BenefitUsageTabs.summary,
      label: "benefitExpenses.summary",
    },
    {
      key: BenefitUsageTabs.expenses,
      label: "benefitExpenses.expenses",
    },
  ];
  const renderHeader = (
    reload: () => Promise<any>,
    xlsExportElement: React.ReactNode
  ) => (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      gridGap={20}
      pb={4}
    >
      {xlsExportElement}
      <AddExpense employeeId={employeeId} onComplete={reload} />
    </Box>
  );
  return (
    <div>
      <Box mb={3}>
        <Typography variant="h5">
          {t("sidebar.employeeBenefitUsage")}
        </Typography>
      </Box>
      <Box position="absolute">
        <HorizontalTabs
          activeKey={currentTab}
          onChange={setCurrentTab}
          tabs={tabs}
        />
      </Box>
      {currentTab === BenefitUsageTabs.summary && (
        <BenefitUsageSummaryTable
          employeeId={employeeId}
          renderHeader={renderHeader}
        />
      )}
      {currentTab === BenefitUsageTabs.expenses && (
        <BenefitExpensesTable
          employeeId={employeeId}
          renderHeader={renderHeader}
        />
      )}
    </div>
  );
};

export default BenefitUsage;
