import { Typography, Box } from "@material-ui/core";
import React, { useMemo } from "react";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function LogHeader() {
  const { t } = useTranslation();

  const defaultDate = useMemo(() => moment().toISOString(), []);

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5">{t("sidebar.log")}</Typography>
      <Box display="flex">
        <TableDatepicker defaultDate={defaultDate} />
      </Box>
    </Box>
  );
}
