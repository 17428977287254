import { MarketplaceItemsFilters } from "./Types";
import { MarketplaceItemsParams } from "../../../../../types/MarketplaceItems";
import { currencyConverters } from "../../../../../utils/Currency";
import { Currency } from "../../../../../types/Currency";

const covertToFractionUnit = (value?: number | null) => {
  if (value === null || value === undefined) {
    return undefined;
  }
  // This should be currency-independent
  return currencyConverters.toFractionalUnitAmount(Currency.EUR, value);
};

export const filtersToParams = (
  filters: MarketplaceItemsFilters
): MarketplaceItemsParams => {
  const { price, ...rest } = filters;
  return {
    ...rest,
    priceFrom: covertToFractionUnit(price?.from),
    priceTo: covertToFractionUnit(price?.to),
  };
};

const covertToMainUnit = (value?: number | null) => {
  if (value === null || value === undefined) {
    return undefined;
  }
  // This should be currency-independent
  return currencyConverters.toMainUnitAmount(Currency.EUR, value);
};

export const paramsToFilters = (
  params: MarketplaceItemsParams
): MarketplaceItemsFilters => {
  const { priceFrom, priceTo, ...rest } = params;
  return {
    ...rest,
    price: {
      from: covertToMainUnit(priceFrom),
      to: covertToMainUnit(priceTo),
    },
  };
};
