import React, { useMemo, useEffect } from "react";
import { MpControlProps, controlRegisterOptions } from "@mp-react/form";
import { Controller } from "react-hook-form";
import { Box, FormControlLabel, Typography } from "@material-ui/core";
import MuiSwitch from "@material-ui/core/Switch";
import { useDebouncedCallback } from "use-debounce";
import useStyles from "./Switch.styles";
import { useTranslation } from "react-i18next";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";

function SwitchView({
  control,
  onChange,
  value,
}: Pick<MpControlProps, "control" | "layout" | "locale"> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = React.useState(!!value);
  const debounced = useDebouncedCallback((val) => {
    onChange(val);
  }, 100);

  useEffect(() => {
    if (!!value !== checked) setChecked(value);
    // eslint-disable-next-line
  }, [value]);

  const isNewsSettings = useMemo(
    () =>
      control.key === "sendMethods.email" ||
      control.key === "sendMethods.appNotification",
    [control.key]
  );
  const isVisibleOnAppToggle = useMemo(
    () => control.key === "investmentVisibleOnApp",
    [control.key]
  );
  const activeLabel = useMemo(() => {
    if (isNewsSettings) return t("news_status.on");
    if (isVisibleOnAppToggle) return t("benefits.show_on_app");
    return t("status.active");
  }, [isNewsSettings, t, isVisibleOnAppToggle]);
  const inactiveLabel = useMemo(() => {
    if (isNewsSettings) return t("news_status.off");
    if (isVisibleOnAppToggle) return t("benefits.hide_on_app");
    return t("status.inactive");
  }, [isNewsSettings, t, isVisibleOnAppToggle]);

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <MuiSwitch
            name={control.key}
            color="primary"
            onChange={(e) => {
              setChecked(e.target.checked);
              debounced(e.target.checked);
            }}
            checked={checked}
          />
        }
        label={
          checked ? (
            <Typography variant="body2">{activeLabel}</Typography>
          ) : (
            <Typography variant="body2" className={classes.inactive}>
              {inactiveLabel}
            </Typography>
          )
        }
      />
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function Switch({
  control,
  layout,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      control={hookFormControl}
      defaultValue={""}
      render={({ onChange, value }) => (
        <SwitchView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          layout={layout}
        />
      )}
    />
  );
}
