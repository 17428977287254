import {
  BenefitBulkActionsRequest,
  BenefitDetailsResponse,
  TransformedBenefitResponse,
} from "./../types/Benefits";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BenefitPlan,
  CreateBenefitRequest,
  UpdateBenefitName,
  UpdateBenefitRequest,
  UpdateBenefitType,
  UseBenefits,
  UseBenefitStore,
} from "../types/Benefits";
import { mutateData } from "../api/api";
import { Endpoints } from "../api/constants";
import useSWR from "swr";
import { useHistory } from "react-router";
import create from "zustand";
import { MpBulkActionCallback, MpBulkActionMethods } from "@mp-react/table";
import { useLoading } from "../utils/Loading";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useBenefitUtils } from "../utils/Benefits";
import { Status } from "../types/Common";
import { mergeDateTime } from "../utils/Common";
import moment from "moment";
import { Row } from "react-table";
import fileDownloader from "js-file-download";
import { parse } from "querystring";
import { useCurrency } from "../utils/useCurrency";
import { MpAsyncGetMethod } from "@mp-react/table";
import { AxiosResponse } from "axios";
import { BenefitFilterNames } from "../constants/Benefits";
import { useMe } from "./Administrators";

export const useBenefits = (
  id?: string,
  query?: string,
  preventCall = false
): UseBenefits => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    transformBenefitData,
    parseStatusDates,
    parseRequestStatus,
    getDataForRequest,
  } = useBenefitUtils();
  const params = useParams() as any;
  const { getDefaultCurrencyFormat } = useCurrency();
  const { hasBenefitPermissions } = useMe();

  const benefitPlanUrl = useMemo(() => `${Endpoints.benefitPlans}/${id}`, [id]);
  const benefitPlansUrl = useMemo(() => {
    if (!params?.category) return Endpoints.benefitPlans;
    return `${Endpoints.benefitPlans}?filter[type]=${params.category}`;
  }, [params.category]);
  const {
    data: benefitPlanDetails,
    error: benefitPlanDetailsError,
    mutate: mutateBenefitPlanDetails,
  } = useSWR(
    hasBenefitPermissions && id && !preventCall ? benefitPlanUrl : null,
    {
      revalidateOnFocus: false,
    }
  );

  const benefitFilterQueryInitialSymbol = useMemo(
    () => (!params?.category ? "?" : "&"),
    [params?.category]
  );

  const benefitFilterQuery = useMemo(
    () => (!!query ? `${benefitFilterQueryInitialSymbol}${query}` : ""),
    [benefitFilterQueryInitialSymbol, query]
  );

  const {
    data: benefitPlans,
    error: benefitPlansError,
    mutate: mutateBenefitPlans,
  } = useSWR(
    hasBenefitPermissions && !id && !preventCall
      ? `${benefitPlansUrl}${benefitFilterQuery}`
      : null
  );

  const { stopLoading, startLoading, loading } = useLoading();

  const [benefitName, setBenefitName] = useState<string>(
    benefitPlanDetails?.name ?? ""
  );

  useEffect(() => {
    if (!!benefitPlanDetails?.name) setBenefitName(benefitPlanDetails?.name);
  }, [benefitPlanDetails?.name]);

  const searchParams = useMemo(
    () => parse(location?.search.replace("?", "")),
    [location?.search]
  );

  const apiLoading = useMemo(() => {
    if (!!id) return !benefitPlanDetails && !benefitPlanDetailsError;
    return !benefitPlans && !benefitPlansError;
  }, [
    benefitPlanDetails,
    benefitPlanDetailsError,
    benefitPlans,
    benefitPlansError,
    id,
  ]);

  const parseBenefitTableStatus = useCallback(
    (benefit: BenefitPlan): string => {
      const isInPast = (date?: string) => {
        if (!date) return false;
        return moment(date).isBefore();
      };

      const { deactivationDate, status } = benefit;
      const now = moment();

      if (!isInPast(deactivationDate)) {
        const end = moment(deactivationDate);
        const duration = moment.duration(now.diff(end));
        const days = Math.round(duration.asDays());
        return t("status.valid_till")?.replace(
          "{days}",
          (days < 0 ? days * -1 : days).toString()
        );
      }

      return t(`status.${status}`);
    },
    [t]
  );

  const parsedBenefitPlans = useMemo(() => {
    if (benefitPlans) {
      benefitPlans.data =
        benefitPlans?.data?.map((data: BenefitPlan) => ({
          ...data,
          inactive: data.status === "inactive",
          statusLabel: parseBenefitTableStatus(data),
        })) ?? [];
    }

    return benefitPlans ?? [];
  }, [benefitPlans, parseBenefitTableStatus]);

  const parsedBenefitTotals = useMemo(() => {
    const initialTotals = {
      type: "",
      name: "",
      employeeCount: "",
      investment: "",
      status: "",
    };
    if (!benefitPlans?.footer) return initialTotals;
    const { type, name, employeeCount, investment, benefitStatus } =
      benefitPlans.footer;
    return {
      type: t("totals.benefit_type", { count: Number(type) }),
      name: t("totals.benefit_plan", { count: Number(name) }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
      status: `${benefitStatus} ${t("status.inactive")}`,
      employeeCount: `${employeeCount} ${t("employees.employee_count")}`,
    };
  }, [benefitPlans, t, getDefaultCurrencyFormat]);

  const parsedBenefitPlan: TransformedBenefitResponse = useMemo(
    () => transformBenefitData(benefitPlanDetails),
    [benefitPlanDetails, transformBenefitData]
  );

  const parsedBenefitStatus = useMemo(
    () => parseStatusDates(benefitPlanDetails),
    [benefitPlanDetails, parseStatusDates]
  );

  const hasAllRequiredInfo = useMemo(() => {
    if (!parsedBenefitPlan || !benefitPlanDetails) return false;
    return true;
  }, [benefitPlanDetails, parsedBenefitPlan]);

  const hasFlexSettings = useMemo(() => {
    const hasVotingStartDate = !!parsedBenefitPlan?.votingStartDate;
    const hasVotingEndDate = !!parsedBenefitPlan?.votingEndDate;

    return hasVotingStartDate && hasVotingEndDate;
  }, [parsedBenefitPlan?.votingEndDate, parsedBenefitPlan?.votingStartDate]);

  const createBenefit = useCallback(
    async (data: CreateBenefitRequest, skipNavigation: boolean = false) => {
      try {
        startLoading();
        const res = await mutateData<BenefitDetailsResponse>(
          "post",
          Endpoints.createBenefitPlan,
          data
        );
        toast(t("common.added_succesfully"), { type: "success" });
        mutateBenefitPlans();
        if (!skipNavigation) {
          history.push(`/benefits/view/${res?.data?.id}`);
        }
        return res.data;
      } finally {
        stopLoading();
      }
    },
    [history, mutateBenefitPlans, startLoading, stopLoading, t]
  );

  const checkIfChoicesDeadlineUpdate = useCallback(
    (data: UpdateBenefitRequest | UpdateBenefitName | UpdateBenefitType) => {
      const noRedirectTabs = [
        "benefitsAssignGroups",
        "benefitsAssignEmployees",
        "innerEmployeeAssignBenefits",
        "innerGroupAssignBenefits",
        "assignGroups",
      ];

      return (
        "tab" in searchParams &&
        noRedirectTabs.includes(searchParams.tab as string)
      );
    },
    [searchParams]
  );

  const updateBenefit = useCallback(
    async (
      data:
        | UpdateBenefitRequest
        | UpdateBenefitName
        | UpdateBenefitType
        | BenefitDetailsResponse,
      cb?: () => void,
      noRedirect?: boolean
    ) => {
      startLoading();
      if (!(data as any)?.limitedQuantity) (data as any).limitedQuantity = null;
      else
        (data as any).limitedQuantity = Number((data as any).limitedQuantity);
      const benefitData = await mutateData("put", benefitPlanUrl, data)
        .then((res: any) => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutateBenefitPlanDetails();
          mutateBenefitPlans();
          const isChoicesDeadlineUpdate = checkIfChoicesDeadlineUpdate(data);
          if (
            !isChoicesDeadlineUpdate &&
            !(data as UpdateBenefitRequest)?.draft &&
            !noRedirect
          )
            history.push(`/benefits/all`);
          cb?.();
          return res?.data as BenefitDetailsResponse;
        })
        .finally(() => {
          stopLoading();
        });
      return benefitData;
    },
    [
      benefitPlanUrl,
      checkIfChoicesDeadlineUpdate,
      history,
      mutateBenefitPlanDetails,
      mutateBenefitPlans,
      startLoading,
      stopLoading,
      t,
    ]
  );

  const renameBenefit = useCallback(
    async (data: UpdateBenefitName) => {
      startLoading();
      const isSuccess = await mutateData("put", benefitPlanUrl, data)
        .then((res: any) => {
          toast(t("common.updated_succesfully"), { type: "success" });
          if (!!res?.data?.name) setBenefitName(res?.data?.name);
          mutateBenefitPlanDetails();
          return true;
        })
        .catch(() => {
          return false;
        })
        .finally(() => {
          stopLoading();
        });
      return isSuccess;
    },
    [benefitPlanUrl, mutateBenefitPlanDetails, startLoading, stopLoading, t]
  );

  const updateBenefitStatus = useCallback(
    (status: Status) => {
      startLoading();
      const parsedStatusDates = parseRequestStatus(status);
      const requestData = getDataForRequest(benefitPlanDetails);
      mutateData("put", benefitPlanUrl, {
        ...requestData,
        ...parsedStatusDates,
      })
        .then((res: any) => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutateBenefitPlanDetails();
        })
        .finally(() => {
          stopLoading();
        });
    },
    [
      benefitPlanDetails,
      benefitPlanUrl,
      getDataForRequest,
      mutateBenefitPlanDetails,
      parseRequestStatus,
      startLoading,
      stopLoading,
      t,
    ]
  );

  const copyBenefit = useCallback(() => {
    startLoading();
    mutateData("post", `${benefitPlanUrl}/copy`)
      .then((res: any) => {
        toast(t("common.added_succesfully"), { type: "success" });
        history.push(`/benefits/view/${res?.data?.id}`);
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, benefitPlanUrl, t, history, stopLoading]);

  const deleteBenefit = useCallback(() => {
    startLoading();
    mutateData("delete", benefitPlanUrl)
      .then((res: any) => {
        toast(t("common.deleted_succesfully"), { type: "success" });
        history.push("/benefits/all");
        mutateBenefitPlans();
      })
      .finally(() => {
        stopLoading();
      });
  }, [
    benefitPlanUrl,
    history,
    mutateBenefitPlans,
    startLoading,
    stopLoading,
    t,
  ]);

  const removeAllEmployees = useCallback(() => {
    if (!id) return;
    startLoading();
    mutateData("put", Endpoints.benefitRemoveAllEmployees.replace("{id}", id))
      .then((res: any) => {
        toast(t("common.deleted_succesfully"), { type: "success" });
        mutateBenefitPlans();
        mutateBenefitPlanDetails();
      })
      .finally(() => {
        stopLoading();
      });
  }, [
    id,
    mutateBenefitPlanDetails,
    mutateBenefitPlans,
    startLoading,
    stopLoading,
    t,
  ]);

  const getBenefitPlan = useCallback(async (id: string) => {
    const url = `${Endpoints.benefitPlans}/${id}`;
    return mutateData("get", url).then(
      (res) => res.data as BenefitDetailsResponse
    );
  }, []);

  const checkBenefitPlanSettings = useCallback(
    async (id: string) => {
      const benefitPlanDetails = await getBenefitPlan(id);
      const hasVotingStartDate = !!benefitPlanDetails?.votingStartDate;
      const hasVotingEndDate = !!benefitPlanDetails?.votingEndDate;
      return hasVotingStartDate && hasVotingEndDate;
    },
    [getBenefitPlan]
  );

  // BULK ACTIONS
  const setBulkAction = useCallback(
    (data: BenefitBulkActionsRequest[]) => {
      startLoading();
      mutateData("patch", Endpoints.bulkActionBenefit, data)
        .then((res: any) => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutateBenefitPlans();
        })
        .finally(() => {
          stopLoading();
        });
    },
    [mutateBenefitPlans, startLoading, stopLoading, t]
  );

  const deactivate: MpBulkActionCallback = useCallback(
    (selected, selectedDate?: string) => {
      const { selectedRows } = selected;
      const rows = selectedRows ?? selected;
      const date = selectedDate ?? moment().format();
      const newValue = (rows as Array<Row<{}>>)?.map((item) => {
        const { original } = item as any;
        return {
          id: original.id,
          benefitPlan: {
            id: original.id,
            name: original.name,
            deactivationDate: date,
          },
        };
      });
      if (!!newValue) setBulkAction(newValue);
    },
    [setBulkAction]
  );

  const publish: MpBulkActionCallback = useCallback(
    (selected, selectedDate?: string) => {
      const { selectedRows } = selected;
      const rows = selectedRows ?? selected;
      const date = selectedDate ?? moment().format();
      const newValue = (rows as Array<Row<{}>>)?.map((item) => {
        const { original } = item as any;
        return {
          id: original.id,
          benefitPlan: {
            id: original.id,
            name: original.name,
            publishDate: date,
          },
        };
      });
      if (!!newValue) setBulkAction(newValue);
    },
    [setBulkAction]
  );

  const changeBenefitType = useCallback(
    (selectedRows: Array<Row<{}>>, type: string) => {
      const newValue = selectedRows?.map((item) => {
        const { original } = item as any;
        return {
          id: original.id,
          benefitPlan: {
            id: original.id,
            name: original.name,
            type: type,
          },
        };
      });
      if (!!newValue) setBulkAction(newValue);
    },
    [setBulkAction]
  );

  const processedUrl = useMemo(() => {
    if (params.category !== undefined) {
      return `${Endpoints.benefitExportToExcel}?filter[type]=${
        params.category
      }${!!query ? `?${query}` : ""}`;
    }
    return `${Endpoints.benefitExportToExcel}${!!query ? `?${query}` : ""}`;
  }, [params.category, query]);

  const exportToExcel = useCallback(() => {
    const url = processedUrl;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    return mutateData("get", url, null, headers, "arraybuffer").then(
      (res: any) => {
        const now = moment().format("L");
        const disposition = res?.headers["content-disposition"];
        const headersFilename = disposition?.split("filename=")?.[1];
        const filename = headersFilename ?? `Benefits_${now}.xlsx`;
        fileDownloader(res.data, filename);
      }
    );
  }, [processedUrl]);

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      deactivate,
      publish,
    }),
    [deactivate, publish]
  );

  return {
    benefitPlans: parsedBenefitPlans,
    benefitPlanDetails,
    parsedBenefitPlan,
    parsedBenefitStatus,
    benefitPlansError,
    benefitPlanDetailsError,
    createBenefit,
    updateBenefit,
    deleteBenefit,
    copyBenefit,
    bulkMethods,
    loading,
    apiLoading,
    hasFlexSettings,
    hasAllRequiredInfo,
    updateBenefitStatus,
    removeAllEmployees,
    parsedBenefitTotals,
    publish,
    deactivate,
    changeBenefitType,
    renameBenefit,
    exportToExcel,
    benefitName,
    getBenefitPlan,
    checkBenefitPlanSettings,
  };
};

export const useBenefitStore = create<UseBenefitStore>((set) => ({
  status: { name: "inactive" },
  setStatus: (obj: Status) => set(() => ({ status: obj })),
  formIsResetting: false,
  resetForm: () => {
    set(() => ({ formIsResetting: true }));
    setTimeout(function () {
      set(() => ({ formIsResetting: false }));
    }, 1000);
  },
  publishForm: false,
  setPublishForm: (publish: boolean) => set(() => ({ publishForm: publish })),
  activationDate: null,
  setActivationDate: (dateToSet: string | null, type?: "date" | "time") => {
    if (type === "date" && !!dateToSet) {
      set((state: UseBenefitStore) => ({
        activationDate: mergeDateTime(dateToSet, state.activationDate ?? ""),
      }));
    } else if (type === "time" && !!dateToSet) {
      set((state: UseBenefitStore) => ({
        activationDate: mergeDateTime(state.activationDate ?? "", dateToSet),
      }));
    } else {
      set(() => ({ activationDate: dateToSet }));
    }
  },
  deactivationDate: null,
  publishDate: null,
  votingStartDate: null,
  votingEndDate: null,
  setDeactivationDate: (
    deactivationDate: string | null,
    type?: "date" | "time"
  ) => {
    if (type === "date" && !!deactivationDate) {
      set((state: UseBenefitStore) => ({
        deactivationDate: mergeDateTime(
          deactivationDate,
          state.deactivationDate ?? ""
        ),
      }));
    } else if (type === "time" && !!deactivationDate) {
      set((state: UseBenefitStore) => ({
        deactivationDate: mergeDateTime(
          state.deactivationDate ?? "",
          deactivationDate
        ),
      }));
    } else {
      set(() => ({ deactivationDate: deactivationDate }));
    }
  },
  setPublishDate: (publishDate: string | null, type?: "date" | "time") => {
    if (type === "date" && !!publishDate) {
      set((state: UseBenefitStore) => ({
        publishDate: mergeDateTime(publishDate, state.publishDate ?? ""),
      }));
    } else if (type === "time" && !!publishDate) {
      set((state: UseBenefitStore) => ({
        publishDate: mergeDateTime(state.publishDate ?? "", publishDate),
      }));
    } else set(() => ({ publishDate: publishDate }));
  },
  setVotingStartDate: (
    votingStartDate: string | null,
    type?: "date" | "time"
  ) => {
    if (type === "date" && !!votingStartDate) {
      set((state: UseBenefitStore) => ({
        votingStartDate: mergeDateTime(
          votingStartDate,
          state.votingStartDate ?? ""
        ),
      }));
    } else if (type === "time" && !!votingStartDate) {
      set((state: UseBenefitStore) => ({
        votingStartDate: mergeDateTime(
          state.votingStartDate ?? "",
          votingStartDate
        ),
      }));
    } else set(() => ({ votingStartDate: votingStartDate }));
  },
  setVotingEndDate: (votingEndDate: string | null, type?: "date" | "time") => {
    if (type === "date" && !!votingEndDate) {
      set((state: UseBenefitStore) => ({
        votingEndDate: mergeDateTime(votingEndDate, state.votingEndDate ?? ""),
      }));
    } else if (type === "time" && !!votingEndDate) {
      set((state: UseBenefitStore) => ({
        votingEndDate: mergeDateTime(state.votingEndDate ?? "", votingEndDate),
      }));
    } else set(() => ({ votingEndDate: votingEndDate }));
  },
}));

export const useBenefitAsyncMethods = (): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(() => `${Endpoints.benefitFilterValues}`, []);

  const getFilterItems = useCallback(
    (filterName: BenefitFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getBenefitPlanName = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitFilterNames.BENEFIT_PLAN_NAME),
    [getFilterItems]
  );

  const getBenefitPlanType = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitFilterNames.TYPE),
    [getFilterItems]
  );

  return {
    getBenefitPlanName,
    getBenefitPlanType,
  };
};
