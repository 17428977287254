import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: "0 10px",
    },
    draftButton: {
      margin: "0 10px",
      backgroundColor: Colors.Inactive,
    },
    changesChip: {
      marginLeft: 10,
      color: Colors.White,
      backgroundColor: Colors.StatusYellow,
    },
  })
);
