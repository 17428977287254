import { useLoading } from "./../utils/Loading";
import { Endpoints } from "./../api/constants";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useMemo, useCallback } from "react";
import {
  UseFlexBenefitBalance,
  AddFlexBenefitBalanceRequest,
  UpdateFlexBenefitBalanceRequest,
} from "./../types/FlexBenefitBalance";
import { mutateData } from "../api/api";
import { useTranslation } from "react-i18next";
import { getDateIsoFormat } from "../utils/Common";
import { useCurrency } from "../utils/useCurrency";

const useFlexBenefitBalance = (id?: string): UseFlexBenefitBalance => {
  const { t } = useTranslation();
  const { data: employeeBalance, error: employeeBalanceError } = useSWR(
    id ? `${Endpoints.employee}/${id}/balance` : null
  );
  const { stopLoading, startLoading, loading } = useLoading();
  const { getDefaultCurrencyFormat } = useCurrency();

  const apiLoading = useMemo(() => {
    return !employeeBalance && !employeeBalanceError;
  }, [employeeBalance, employeeBalanceError]);

  const parsedFlexBenefitBalanceTotals = useMemo(() => {
    const initialTotals = {
      allocated: "",
      reserved: "",
      additionalTotal: "",
      details: "",
    };
    if (!employeeBalance?.footer) return initialTotals;
    const { allocated, reserved } = employeeBalance.footer;
    return {
      allocated: `${getDefaultCurrencyFormat(Math.round(allocated))}`,
      reserved: `${getDefaultCurrencyFormat(Math.round(reserved))}`,
      additionalTotal: `${getDefaultCurrencyFormat(
        Math.ceil(Number(allocated) - Number(reserved))
      )}`,
      details: `${t("employees.allocated_reserved")}`,
    };
  }, [employeeBalance, getDefaultCurrencyFormat, t]);

  const addFlexBenefitBalance = useCallback(
    (data: AddFlexBenefitBalanceRequest) => {
      startLoading();
      mutateData("post", `${Endpoints.employee}/${id}/balance/add`, {
        ...data,
        date: getDateIsoFormat(data.date),
        amount: Number(data.allocated),
      })
        .then(() => {
          toast(t("common.added_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${id}/balance`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, id]
  );

  const updateFlexBenefitBalance = useCallback(
    (data: UpdateFlexBenefitBalanceRequest, balanceId: string) => {
      startLoading();
      mutateData("patch", `${Endpoints.employee}/${id}/balance/${balanceId}`, {
        ...data,
        date: getDateIsoFormat(data.date),
        amount: Number(data.allocated),
      })
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${id}/balance`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, id]
  );

  return {
    loading: loading || apiLoading,
    employeeBalance,
    employeeBalanceError,
    addFlexBenefitBalance,
    updateFlexBenefitBalance,
    parsedFlexBenefitBalanceTotals,
  };
};

export default useFlexBenefitBalance;
