import { useLoading } from "../utils/Loading";
import { Endpoints } from "../api/constants";
import { useMemo, useCallback } from "react";
import { UseBenefitTermsAcceptance } from "../types/BenefitTermsAcceptance";
import useSWR, { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { MpBulkActionCallback, MpBulkActionMethods } from "@mp-react/table";
import { toast } from "react-toastify";
import { MpAsyncGetMethod } from "@mp-react/table";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { AxiosResponse } from "axios";
import { BenefitTermsAcceptanceFilterNames } from "../constants/Benefits";
import { mutateData } from "../api/api";

export const useBenefitTermsAcceptance = (
  id?: string,
  query?: string
): UseBenefitTermsAcceptance => {
  const { t } = useTranslation();
  const { loading, startLoading, stopLoading } = useLoading();
  const url = useMemo(() => {
    if (id) {
      return !!query
        ? `${Endpoints.benefitTermsAcceptance}/${id}?${query}`
        : `${Endpoints.benefitTermsAcceptance}/${id}`;
    }
    return null;
  }, [id, query]);
  const { data: benefitTermsAcceptance, error: benefitTermsAcceptanceError } =
    useSWR(url);

  const apiLoading = useMemo(() => {
    return !benefitTermsAcceptance && !benefitTermsAcceptanceError;
  }, [benefitTermsAcceptance, benefitTermsAcceptanceError]);

  const parsedBenefitTermsAcceptanceList = useMemo(
    () =>
      benefitTermsAcceptance?.data?.map((data: any) => ({
        ...data,
        id: `${data.employeeId}${data.benefitPlanId}`,
      })),
    [benefitTermsAcceptance]
  );

  const parsedBenefitTermsAcceptanceTotals = useMemo(() => {
    const initialTotals = {
      employeeName: "",
    };
    if (!benefitTermsAcceptance?.footer) return initialTotals;
    const { employeeName } = benefitTermsAcceptance.footer;
    return {
      employeeName: t("totals.employeeName", { count: Number(employeeName) }),
    };
  }, [benefitTermsAcceptance, t]);

  const updateStatus = useCallback(
    (status: boolean, benefitPlanTCAcceptances) => {
      startLoading();
      const requestData = {
        status: status,
        benefitPlanTCAcceptances: benefitPlanTCAcceptances,
      };
      mutateData(
        "patch",
        `${Endpoints.termsAcceptance}/bulkAction`,
        requestData
      )
        .then(() => {
          toast(t("common.assigned_succesfully"), { type: "success" });
          mutate(`${Endpoints.benefitTermsAcceptance}/${id}`);
        })
        .finally(() => stopLoading());
    },
    [startLoading, stopLoading, t, id]
  );

  const statusAccepted: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const benefitPlanTCAcceptances = selectedRows?.map((item) => {
        const { original } = item as any;
        return {
          employeeId: original.employeeId,
          benefitPlanId: id,
        };
      });
      updateStatus(true, benefitPlanTCAcceptances);
    },
    [updateStatus, id]
  );

  const statusNotAccepted: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const benefitPlanTCAcceptances = selectedRows?.map((item) => {
        const { original } = item as any;
        return {
          employeeId: original.employeeId,
          benefitPlanId: id,
        };
      });
      updateStatus(false, benefitPlanTCAcceptances);
    },
    [updateStatus, id]
  );

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      statusAccepted,
      statusNotAccepted,
    }),
    [statusAccepted, statusNotAccepted]
  );

  return {
    loading: loading || apiLoading,
    benefitTermsAcceptance,
    benefitTermsAcceptanceError,
    parsedBenefitTermsAcceptanceList,
    parsedBenefitTermsAcceptanceTotals,
    bulkMethods,
  };
};

export const useBenefitTermsAcceptanceAsyncMethods = (
  id: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(() => `${Endpoints.termsFilterValues}`, []);

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: BenefitTermsAcceptanceFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const benefitPlanId = `${
        !!searchParam ? `&` : `?`
      }filter[benefitPlanId]=${id}`;
      const apiUrl = `${baseUrl}/${filterName}${searchParam}${benefitPlanId}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [id, baseUrl]
  );

  const getEmployeeNames = useCallback<MpAsyncGetMethod>(
    (args) =>
      getAsyncFilterItems(
        args,
        BenefitTermsAcceptanceFilterNames.EMPLOYEE_NAME
      ),
    [getAsyncFilterItems]
  );

  return {
    getEmployeeNames,
  };
};
