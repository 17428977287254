import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useSWR from "swr";
import { Endpoints } from "../api/constants";
import {
  Integration,
  IntegrationConnectionCreationRequest,
  IntegrationConnectionUpdateRequest,
  IntegrationType,
  IntegrationTypeCreationRequest,
  IntegrationTypesResponse,
  IntegrationTypeUpdateRequest,
} from "../types/Integrations";
import { useLoading } from "../utils/Loading";
import { mutateData } from "../api/api";
import { TableDataResponse } from "../types/Table";
import { useParentCompany } from "./ParentCompany";

export const useIntegrationTypes = () => {
  const { t } = useTranslation();
  const { data, error, mutate } = useSWR<IntegrationTypesResponse>(
    Endpoints.integrationTypes
  );

  const { loading, startLoading, stopLoading } = useLoading();

  const create = async (request: IntegrationTypeCreationRequest) => {
    try {
      startLoading();
      const result = await mutateData(
        "post",
        Endpoints.integrationTypes,
        request
      );
      toast.success(t("common.added_succesfully"));
      await mutate();
      return result.data;
    } finally {
      stopLoading();
    }
  };

  return {
    integrationTypes: data?.data,
    loading: !data && !error,
    create,
    creating: loading,
  };
};

export const useIntegrationType = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const { mutate: mutateList } = useSWR(Endpoints.integrationTypes);
  const { data, error, mutate } = useSWR<IntegrationType>(
    `${Endpoints.integrationTypes}/${id}`
  );

  const { loading, startLoading, stopLoading } = useLoading();

  const update = async (request: IntegrationTypeUpdateRequest) => {
    startLoading();
    try {
      const { data: updatedIntegrationType } = await mutateData(
        "put",
        `${Endpoints.integrationTypes}/${id}`,
        request
      );
      toast.success(t("common.updated_succesfully"));
      await mutate(updatedIntegrationType);
      return updatedIntegrationType;
    } finally {
      stopLoading();
    }
  };

  const remove = async () => {
    startLoading();
    try {
      const response = await await mutateData(
        "delete",
        `${Endpoints.integrationTypes}/${id}`
      );
      toast.success(t("common.deleted_succesfully"));
      await mutateList();
      return response.data;
    } finally {
      stopLoading();
    }
  };

  return {
    integrationType: data,
    loading: !data && !error,
    isActionInProgress: loading,
    update,
    remove,
  };
};

export const useIntegrations = () => {
  const { t } = useTranslation();
  const { parentCompany } = useParentCompany();
  const { data, error, mutate } = useSWR<TableDataResponse<Integration>>(
    Endpoints.integrations
  );

  const {
    loading: creatingConnection,
    startLoading: startCreation,
    stopLoading: stopCreation,
  } = useLoading();
  const createConnection = async (
    request: IntegrationConnectionCreationRequest
  ) => {
    try {
      startCreation();
      const mappedRequest = {
        parentCompanyId: parentCompany?.id,
        ...request,
      };
      const result = await mutateData(
        "post",
        Endpoints.integrations,
        mappedRequest
      );
      await mutate();
      toast.success(t("integrations.connectedSuccessfully"));
      return result.data;
    } finally {
      stopCreation();
    }
  };

  const {
    loading: updatingConnection,
    startLoading: startUpdate,
    stopLoading: stopUpdate,
  } = useLoading();
  const updateConnection = async (
    id: string,
    request: IntegrationConnectionUpdateRequest
  ) => {
    try {
      startUpdate();
      const result = await mutateData(
        "put",
        `${Endpoints.integrations}/${id}`,
        request
      );
      await mutate();
      toast.success(t("integrations.reconnectedSuccessfully"));
      return result.data;
    } finally {
      stopUpdate();
    }
  };

  const {
    loading: deletingConnection,
    startLoading: startDeletion,
    stopLoading: stopDeletion,
  } = useLoading();
  const deleteConnection = async (id: string) => {
    try {
      startDeletion();
      const result = await mutateData(
        "delete",
        `${Endpoints.integrations}/${id}`
      );
      await mutate();
      toast.success(t("integrations.disconnectedSuccessfully"));
      return result;
    } finally {
      stopDeletion();
    }
  };

  return {
    integrations: data?.data,
    loading: !data && !error,
    createConnection,
    creatingConnection,
    updateConnection,
    updatingConnection,
    deleteConnection,
    deletingConnection,
  };
};

export const useIntegration = (id: string) => {
  const { data, error } = useSWR<Integration>(
    Endpoints.clientAdmin.integrations.integration(id)
  );
  return { data, error };
};
