import { Grid, Box, IconButton, Collapse, Grow } from "@material-ui/core";
import useStyles from "./StatusDialogContent.styles";
import { useTranslation } from "react-i18next";
import { Control, Controller, FieldValues, ArrayField } from "react-hook-form";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cross.svg";
import StatusChipSelector from "../../../common/StatusChipSelector/StatusChipSelector";
import {
  browserLanguage,
  useDateFormat,
  useDatePlaceholder,
} from "../../../../utils/Common";
import moment from "moment";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { BenefitPlanContext } from "../../../../containers/Benefits/BenefitsInner/BenefitsInner";
import { useTableStore } from "../../../../state/Table";
import { InnerEmployeeContext } from "../../../../containers/Employees/EmployeesInner/EmployeesInner";
import { GroupContext } from "../../../../containers/Groups/GroupsInner/GroupsInner";
import { useBenefits } from "../../../../state/Benefits";
import {
  UpdateBenefitRequest,
  UpdateBenefitName,
  UpdateBenefitType,
  BenefitDetailsResponse,
} from "../../../../types/Benefits";
import Loader from "../../../common/Loader/Loader";
import { useLoading } from "../../../../utils/Loading";

interface Props {
  fields: Partial<ArrayField<Record<string, any>, "statuses.id">>[];
  control: Control<FieldValues>;
  remove: (index?: number) => void;
  defaultValues: Record<string, any>;
  disabled?: boolean;
}

interface IFlexBenefitContext {
  hasFlexSettings?: boolean;
  updateBenefit?: (
    data: UpdateBenefitRequest | UpdateBenefitName | UpdateBenefitType,
    cb?: (() => void) | undefined,
    noRedirect?: boolean | undefined
  ) => Promise<BenefitDetailsResponse>;
  benefitPlanDetails?: BenefitDetailsResponse;
  benefitPlanLoading?: boolean;
}

export const FlexBenefitContext = createContext<IFlexBenefitContext>({});

export default function StatusHistoryContent({
  fields,
  control,
  remove,
  defaultValues,
  disabled,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { datePlaceholder } = useDatePlaceholder();
  const { id: benefitPlanId } = useContext(BenefitPlanContext);
  const { id: employeeId } = useContext(InnerEmployeeContext);
  const groupData = useContext(GroupContext);
  const currentTableVariant = useTableStore((state) => state.variant);
  const { keyboardDatePickerFormat } = useDateFormat();

  //Fake Loader
  const {
    loading: fakeLoader,
    startLoading: startFakeLoader,
    stopLoading: stopFakeLoader,
  } = useLoading();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  //Flex benefit part
  const benefitIdInStatusHistory = useMemo(() => {
    if (
      currentTableVariant === "benefitsAssignGroups" ||
      currentTableVariant === "benefitsAssignEmployees"
    )
      return benefitPlanId;
    if (
      currentTableVariant === "innerEmployeeAssignBenefits" ||
      currentTableVariant === "innerGroupAssignBenefits"
    )
      return defaultValues.id;
    return "";
  }, [benefitPlanId, currentTableVariant, defaultValues.id]);

  const {
    hasFlexSettings,
    updateBenefit,
    benefitPlanDetails,
    loading: benefitPlanLoading,
  } = useBenefits(benefitIdInStatusHistory);

  const [collapseIndex, setCollapseIndex] = useState<number | null>(null);

  const handleRemoveField = useCallback(
    (index: number) => {
      setCollapseIndex(index);
      setTimeout(() => {
        setCollapseIndex(null);
        remove(index);
      }, 300);
    },
    [remove]
  );

  const checkIsInPast = useCallback((date?: string) => {
    if (!date) return false;
    const momentDate = moment(date);
    const isFromDateValid = momentDate.isValid();
    const isFromDateInPast =
      isFromDateValid && momentDate.endOf("day").isBefore();
    return isFromDateInPast;
  }, []);

  const getISOString = useCallback(
    (date?: string) => moment(date).endOf("day").toISOString(),
    []
  );

  const getEmployeeGroupId = useCallback(
    (fieldEmployeeGroupId: string) => {
      if (!!fieldEmployeeGroupId) return fieldEmployeeGroupId;
      if (currentTableVariant === "benefitsAssignGroups")
        return defaultValues.id;
      if (currentTableVariant === "innerGroupAssignBenefits")
        return groupData?.id ?? "";
      return "";
    },
    [currentTableVariant, defaultValues.id, groupData?.id]
  );

  const getBenefitPlanId = useCallback(
    (fieldBenefitPlanId: string) => {
      if (!!fieldBenefitPlanId) return fieldBenefitPlanId;
      return benefitIdInStatusHistory;
    },
    [benefitIdInStatusHistory]
  );

  const getEmployeeId = useCallback(
    (fieldEmployeeId: string) => {
      if (!!fieldEmployeeId) return fieldEmployeeId;
      if (currentTableVariant === "benefitsAssignEmployees")
        return defaultValues.id;
      if (currentTableVariant === "innerEmployeeAssignBenefits")
        return employeeId;
      return "";
    },
    [currentTableVariant, defaultValues.id, employeeId]
  );

  useLayoutEffect(() => {
    startFakeLoader();
    setTimeout(() => stopFakeLoader(), 500);
  }, [startFakeLoader, stopFakeLoader]);

  useEffect(() => {
    if (!benefitPlanLoading && !initialLoad) setInitialLoad(true);
  }, [benefitPlanLoading, initialLoad]);

  if (!initialLoad && (benefitPlanLoading || fakeLoader))
    return <Loader style={{ minHeight: 150 }} />;

  return (
    <FlexBenefitContext.Provider
      value={{
        hasFlexSettings,
        updateBenefit,
        benefitPlanDetails,
        benefitPlanLoading,
      }}
    >
      <Box className={classes.statusDialogContentWrapper}>
        {fields.map((field, index) => {
          const fieldIsInPast = checkIsInPast(field.fromDate);
          return (
            <Collapse
              in={collapseIndex !== index}
              timeout={{ exit: 300, enter: 0 }}
              key={field.id ?? `newField-${index}`}
            >
              <Grow in={true} timeout={{ exit: 0, enter: 300 }}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  classes={{
                    "spacing-xs-2": classes.gridSpacingFix,
                  }}
                >
                  <Grid item xs={3}>
                    <StatusChipSelector
                      value={field.status}
                      disabled={fieldIsInPast || disabled}
                      control={control}
                      index={index}
                    />
                  </Grid>
                  <Grid item xs>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      locale={browserLanguage}
                    >
                      <Box>
                        <Controller
                          name={`statuses[${index}].fromDate`}
                          control={control}
                          defaultValue={getISOString(field.fromDate)}
                          render={({ onChange, value }) => (
                            <KeyboardDatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              format={keyboardDatePickerFormat}
                              placeholder={datePlaceholder}
                              onChange={(e) => onChange(e?.toISOString())}
                              value={getISOString(value)}
                              margin="none"
                              InputLabelProps={{ shrink: true }}
                              required
                              fullWidth={true}
                              size="small"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              invalidDateMessage={t("error.12009")}
                              keyboardIcon={<CalendarIcon />}
                              disabled={checkIsInPast(value) || disabled}
                              disablePast
                              minDateMessage=""
                              PopoverProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                style: {
                                  marginTop: 10,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.keyboardDatepickerDisabled,
                                },
                              }}
                            />
                          )}
                        />
                      </Box>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={1} className={classes.closeBtn}>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveField(index)}
                      disabled={fieldIsInPast}
                      style={{
                        visibility:
                          fieldIsInPast || disabled ? "hidden" : "visible",
                      }}
                    >
                      <CloseIcon style={{ margin: 5 }} />
                    </IconButton>
                  </Grid>
                  <Controller
                    control={control}
                    name={`statuses[${index}].benefitPlanId`}
                    defaultValue={getBenefitPlanId(field.benefitPlanId)}
                    render={({ value }) => (
                      <input type="hidden" value={value} />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`statuses[${index}].employeeGroupId`}
                    defaultValue={getEmployeeGroupId(field.employeeGroupId)}
                    render={({ value }) => (
                      <input type="hidden" value={value} />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`statuses[${index}].employeeId`}
                    defaultValue={getEmployeeId(field.employeeId)}
                    render={({ value }) => (
                      <input type="hidden" value={value} />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`statuses[${index}].id`}
                    defaultValue={field.id}
                    render={({ value }) => (
                      <input type="hidden" value={value} />
                    )}
                  />
                </Grid>
              </Grow>
            </Collapse>
          );
        })}
      </Box>
    </FlexBenefitContext.Provider>
  );
}
