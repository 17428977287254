import { useCallback, useMemo } from "react";
import moment from "moment";
import { useDateFormat } from "./Common";

export const useFromToFilter = () => {
  const currentYear = useMemo(() => moment().year(), []);
  const currentMonthNum = useMemo(() => Number(moment().format("M")), []);
  const { localeLanguage } = useDateFormat();

  const isDisabled = useCallback((from: string | null, to: string | null) => {
    if (!from || !to) return false;
    return moment(from) > moment(to);
  }, []);

  const getDates = useCallback(
    (type: "from" | "to") => {
      const years = [];
      for (let i = currentYear; i >= 2016; i--) {
        years.push(i);
      }
      moment.locale(localeLanguage);
      const months = moment.monthsShort().reverse();
      return years
        .map((year) => {
          return months
            .map((month) => {
              const monthNum = Number(moment().month(month).format("M"));
              if (year === currentYear && monthNum > currentMonthNum)
                return {
                  shortName: "",
                  key: "",
                };
              const date =
                type === "from"
                  ? moment(`${month}, ${year}`, "MMM, YYYY").startOf("month")
                  : moment(`${month}, ${year}`, "MMM, YYYY").endOf("month");
              return {
                shortName: `${month}, ${year}`,
                key: date.format("YYYY-MM-DD").toString() ?? "",
              };
            })
            .filter((item) => !!item.key);
        })
        .flat();
    },
    [currentMonthNum, currentYear, localeLanguage]
  );

  return {
    getDates,
    isDisabled,
  };
};
