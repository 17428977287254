import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      borderRadius: "50%",
      "& path:first-child": {
        fill: "transparent",
      },
    },
    checked: {
      "& path": {
        fill: Colors.Default,
      },
      "&.Mui-disabled": {
        "& path": {
          fill: `${Colors.Disable} !important`,
        },
      },
      "&.MuiRadio-colorPrimary": {
        "& path": {
          fill: Colors.Primary,
        },
      },
      "&.MuiRadio-colorSecondary": {
        "& path": {
          fill: Colors.Secondary,
        },
      },
    },
    disabled: {
      "& path:nth-child(2)": {
        fill: Colors.Disable,
      },
    },
    root: {
      "& .MuiIconButton-label": {
        width: 18,
      },
    },
  })
);
