import { Colors } from "./../../../constants/Style";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    vAlignCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 20px 8px 20px !important",
      height: 50,
    },
    backItem: {
      display: "flex",
      alignItems: "center",
      padding: "12px 20px !important",
      "& .MuiTypography-root": {
        alignSelf: "normal",
      },
    },
    icon: {
      "& path:last-child": {
        stroke: Colors.Success,
      },
    },
    bold: {
      fontWeight: "bold",
      color: "black",
    },
  })
);
