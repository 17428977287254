import { Box, Button, MenuItem, Menu, Typography } from "@material-ui/core";
import { FromToFilterProps } from "@mp-react/table";
import React, { useCallback, useMemo, useState } from "react";
import useStyles from "./FromToFilter.styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow-down.svg";
import { DatepickerItemType } from "../../../../types/Common";
import { capitalizeFirst, useDateFormat } from "../../../../utils/Common";
import { useFromToFilter } from "../../../../utils/FromToFilter";
import { useTableStore } from "../../../../state/Table";

export default function FromToFilter({
  onFilter,
  onDateChange,
  dateFrom,
  dateTo,
}: FromToFilterProps) {
  const classes = useStyles();
  const { localeLanguage } = useDateFormat();
  const { getDates, isDisabled } = useFromToFilter();
  const { t } = useTranslation();
  const { setDatepickerValue, setDatepickerVariables } = useTableStore();
  const [fromAnchorEl, setFromAnchorEl] = useState<null | HTMLElement>(null);
  const [toAnchorEl, setToAnchorEl] = useState<null | HTMLElement>(null);
  const dateFromObj = useMemo(
    () => ({
      shortName: capitalizeFirst(
        moment(dateFrom ?? undefined)
          .locale(localeLanguage)
          .format("MMM, YYYY") ?? ""
      ),
      key: moment(dateFrom ?? undefined)
        .set("date", 1)
        .format("YYYY-MM-DD"),
    }),
    [dateFrom, localeLanguage]
  );
  const dateToObj = useMemo(
    () => ({
      shortName: capitalizeFirst(
        moment(dateTo ?? undefined)
          .locale(localeLanguage)
          .format("MMM, YYYY") ?? ""
      ),
      key: moment(dateTo ?? undefined).format("YYYY-MM-DD"),
    }),
    [dateTo, localeLanguage]
  );

  const handleDateFrom = useCallback(
    (item: DatepickerItemType) => (e: any) => {
      handleCloseFrom();
      onDateChange(item.key, "dateFrom", () => {});
    },
    [onDateChange]
  );

  const handleDateTo = useCallback(
    (item: DatepickerItemType) => (e: any) => {
      handleCloseTo();
      onDateChange(item.key, "dateTo", () => {});
    },
    [onDateChange]
  );

  const filterHandler = useCallback(() => {
    onFilter();
    setDatepickerValue(`${dateFromObj.shortName} - ${dateToObj.shortName}`);
    setDatepickerVariables({
      dateFrom: dateFromObj.key ?? "",
      dateTo: dateToObj.key ?? "",
    });
  }, [
    onFilter,
    setDatepickerValue,
    setDatepickerVariables,
    dateFromObj.key,
    dateFromObj.shortName,
    dateToObj.key,
    dateToObj.shortName,
  ]);

  const fromDates = useMemo(() => getDates("from"), [getDates]);
  const toDates = useMemo(() => getDates("to"), [getDates]);

  const handleCloseFrom = () => setFromAnchorEl(null);

  const handleClickFrom = (event: React.MouseEvent<HTMLButtonElement>) =>
    setFromAnchorEl(event.currentTarget);

  const handleCloseTo = () => setToAnchorEl(null);

  const handleClickTo = (event: React.MouseEvent<HTMLButtonElement>) =>
    setToAnchorEl(event.currentTarget);

  return (
    <Box className={classes.container} id="from-to-filter">
      <Box>
        <Button
          aria-controls="select-from-date"
          aria-haspopup="true"
          onClick={handleClickFrom}
          variant="outlined"
          className={classes.selectButton}
          endIcon={<ArrowDown />}
        >
          <Typography
            color="textSecondary"
            variant="body2"
            className={classes.capitalize}
          >
            {dateFromObj.shortName}
          </Typography>
        </Button>
        <Menu
          id="select-from-date"
          anchorEl={fromAnchorEl}
          keepMounted
          open={Boolean(fromAnchorEl)}
          onClose={handleCloseFrom}
          className={classes.menu}
        >
          {fromDates.map((item) => (
            <MenuItem
              key={item.key}
              value={item.key}
              onClick={handleDateFrom(item)}
              disabled={isDisabled(item.key, dateToObj.key)}
              className={classes.capitalize}
            >
              {item.shortName}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box>
        <Button
          aria-controls="select-to-date"
          aria-haspopup="true"
          onClick={handleClickTo}
          variant="outlined"
          className={classes.selectButton}
          endIcon={<ArrowDown />}
        >
          <Typography
            color="textSecondary"
            variant="body2"
            className={classes.capitalize}
          >
            {dateToObj.shortName}
          </Typography>
        </Button>
        <Menu
          id="select-to-date"
          anchorEl={toAnchorEl}
          keepMounted
          open={Boolean(toAnchorEl)}
          onClose={handleCloseTo}
          className={classes.menu}
        >
          {toDates.map((item) => (
            <MenuItem
              key={item.key}
              value={item.key}
              onClick={handleDateTo(item)}
              disabled={isDisabled(dateFromObj.key, item.key)}
              className={classes.capitalize}
            >
              {item.shortName}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={filterHandler}
      >
        {t("table.filter")}
      </Button>
    </Box>
  );
}
