import { List, ListProps } from "@material-ui/core";
import React from "react";
import useStyles from "./SidebarDark.styles";

export default function SidebarDark(props: ListProps) {
  const classes = useStyles();

  return (
    <List classes={{ root: classes.listRoot }} disablePadding {...props} />
  );
}
