import { createStyles, makeStyles, Theme } from "@material-ui/core";

const borderRadius = "15%";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "inline-block",
      position: "relative",
      height: 21,
      width: 28,
    },
    frame: {
      height: 21,
      width: 28,
      position: "absolute",
      top: 0,
      border: "1px solid rgb(0,0,0,.1)",
      borderRadius,
    },
    flag: {
      // Overriding default element styles of 3rd party component
      height: "100% !important",
      width: "100% !important",
      verticalAlign: "baseline !important",
      borderRadius,
    },
  })
);
