import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceByElement } from "../../../../../utils/Logs";

interface IProps {
  news: string;
}

export default function NewsCreated(props: IProps) {
  const { news } = props;
  const { t } = useTranslation();

  return useMemo(
    () =>
      replaceByElement(t("log.news_item_was_created"), "{name}", news ?? ""),
    [news, t]
  );
}
