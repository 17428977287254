import { useLoading } from "./../utils/Loading";
import { Endpoints } from "./../api/constants";
import { useMemo, useCallback } from "react";
import { UseEmployeeTermsAcceptance } from "./../types/EmployeeTermsAcceptance";
import useSWR, { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { MpBulkActionCallback, MpBulkActionMethods } from "@mp-react/table";
import { toast } from "react-toastify";
import { mutateData } from "../api/api";

export const useEmployeeTermsAcceptance = (
  id?: string,
  query?: string
): UseEmployeeTermsAcceptance => {
  const { t } = useTranslation();
  const { loading, stopLoading, startLoading } = useLoading();
  const url = useMemo(() => {
    if (id) {
      return !!query
        ? `${Endpoints.employeeTermsAcceptance}/${id}?${query}`
        : `${Endpoints.employeeTermsAcceptance}/${id}`;
    }
    return null;
  }, [id, query]);
  const { data: employeeTermsAcceptance, error: employeeTermsAcceptanceError } =
    useSWR(url);

  const apiLoading = useMemo(() => {
    return !employeeTermsAcceptance && !employeeTermsAcceptanceError;
  }, [employeeTermsAcceptance, employeeTermsAcceptanceError]);

  const parsedEmployeeTermsAcceptanceList = useMemo(
    () =>
      employeeTermsAcceptance?.data?.map((data: any) => ({
        ...data,
        id: `${data.employeeId}${data.benefitPlanId}`,
      })),
    [employeeTermsAcceptance]
  );

  const parsedEmployeeTermsAcceptanceTotals = useMemo(() => {
    const initialTotals = {
      employeeName: "",
      benefitPlanName: "",
      benefitPlanType: "",
    };
    if (!employeeTermsAcceptance?.footer) return initialTotals;
    const { employeeName, benefitPlanName, benefitPlanType } =
      employeeTermsAcceptance.footer;
    return {
      employeeName: `${employeeName} ${t(
        `employees.${Number(employeeName) > 1 ? "employees" : "employee"}`
      )}`,
      benefitPlanName: `${benefitPlanName} ${
        Number(benefitPlanName) > 1
          ? t("menu.benefits")
          : t("entity_type.benefitPlan")
      }`,
      benefitPlanType: `${benefitPlanType} ${t(
        `benefits.${
          Number(benefitPlanType) > 1 ? "benefit_types" : "benefit_type"
        }`
      )}`,
    };
  }, [employeeTermsAcceptance, t]);

  const updateStatus = useCallback(
    (status: boolean, benefitPlanTCAcceptances) => {
      startLoading();
      const requestData = {
        status: status,
        benefitPlanTCAcceptances: benefitPlanTCAcceptances,
      };
      mutateData(
        "patch",
        `${Endpoints.termsAcceptance}/bulkAction`,
        requestData
      )
        .then(() => {
          toast(t("common.assigned_succesfully"), { type: "success" });
          mutate(`${Endpoints.employeeTermsAcceptance}/${id}`);
        })
        .finally(() => stopLoading());
    },
    [startLoading, stopLoading, t, id]
  );

  const statusAccepted: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const benefitPlanTCAcceptances = selectedRows?.map((item) => {
        const { original } = item as any;
        return {
          employeeId: id,
          benefitPlanId: original.benefitPlanId,
        };
      });
      updateStatus(true, benefitPlanTCAcceptances);
    },
    [updateStatus, id]
  );

  const statusNotAccepted: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const benefitPlanTCAcceptances = selectedRows?.map((item) => {
        const { original } = item as any;
        return {
          employeeId: id,
          benefitPlanId: original.benefitPlanId,
        };
      });
      updateStatus(false, benefitPlanTCAcceptances);
    },
    [updateStatus, id]
  );

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      statusAccepted,
      statusNotAccepted,
    }),
    [statusAccepted, statusNotAccepted]
  );

  return {
    loading: loading || apiLoading,
    employeeTermsAcceptance,
    employeeTermsAcceptanceError,
    parsedEmployeeTermsAcceptanceList,
    parsedEmployeeTermsAcceptanceTotals,
    bulkMethods,
  };
};
