import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

const DIVIDER_WIDTH = "calc(100% - 30px)";

export default makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {
      width: "100%",
      position: "relative",
      "&::before": {
        content: "''",
        background: Colors.Divider,
        height: 1,
        position: "absolute",
        top: 0,
        width: DIVIDER_WIDTH,
        right: 0,
      },
    },
  })
);
