import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    menuList: {
      padding: 0,
    },
    descriptionText: {
      whiteSpace: "pre-wrap",
    },
    green: {
      color: Colors.Success,
    },
    red: {
      color: Colors.Error,
    },
  })
);
