import { useTranslation } from "react-i18next";
import { MpControlType, MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { TooltipControl } from "../../types/Common";
import { getTimePlaceholder, useDatePlaceholder } from "../../utils/Common";

export const useEmployeeForm = (): MpFormType => {
  const { t } = useTranslation();
  const { datePlaceholder } = useDatePlaceholder();

  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: MpControlType.text,
        key: "firstName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
        },
        label: t("form.first_name"),
        required: true,
        tooltip: "tooltip.add_employee_first_name",
      },
      {
        type: MpControlType.text,
        key: "lastName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
        },
        label: t("form.last_name"),
        required: true,
        tooltip: "tooltip.add_employee_last_name",
      },
      {
        type: "phone" as MpControlType,
        key: "phone",
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.mobile_number"),
        required: true,
        tooltip: "tooltip.add_employee_phone",
      },
      {
        type: MpControlType.text,
        key: "personalCode",
        validations: {
          minLength: 3,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.personal_code"),
        required: true,
        tooltip: "tooltip.add_employee_personal_code",
      },
      {
        type: "customDropdown" as any,
        key: "companyId",
        label: t("employees.company"),
        required: true,
        messages: {
          required: t("form.required"),
        },
        tooltip: "tooltip.add_employee_company",
      },
      {
        type: "mergedGroup" as any,
        label: t("employees.activate_on"),
        required: true,
        tooltip: "tooltip.employee_activate_employee_on",
        children: [
          {
            type: "date" as any,
            key: "startDate",
            required: true,
            placeholder: datePlaceholder,
            messages: {
              required: t("form.required"),
              pattern: t("errors.activation_date_missing"),
            },
            span: {
              sm: 6,
            },
          },
          {
            type: "time" as any,
            key: "startTime",
            required: true,
            placeholder: getTimePlaceholder(),
            messages: {
              required: t("form.required"),
              pattern: t("errors.activation_time_missing"),
            },
            span: {
              sm: 6,
            },
          },
        ],
      },
    ],
    [datePlaceholder, t]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
