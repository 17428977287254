import React, { useMemo } from "react";
import SingleFieldDialog from "../SingleFieldDialog/SingleFieldDialog";
import { useTranslation } from "react-i18next";
import { useBenefitTypes } from "../../../constants/Benefits";

interface IProps {
  slug: string;
  open: boolean;
  onCancelClick: () => void;
  onActionClick: (value: string) => void;
}

export function ActionDialog({
  slug,
  open,
  onCancelClick,
  onActionClick,
}: IProps) {
  const { t } = useTranslation();
  const benefitTypes = useBenefitTypes();
  const dialogs: { [key: string]: JSX.Element } = useMemo(
    () => ({
      changeBenefitType: (
        <SingleFieldDialog
          onCancelClick={onCancelClick}
          onActionClick={onActionClick}
          id="change-benefit-type-dialog"
          open={open}
          title={t("benefits.benefit_type")}
          actionLabel={t("common.confirm")}
          placeholder={`${t("benefits.benefit_name")}`}
          type="select"
          defaultValue={benefitTypes[0]?.value}
          options={benefitTypes}
          tooltip="tooltip.change_benefit_type"
        />
      ),
    }),
    [benefitTypes, onActionClick, onCancelClick, open, t]
  );
  return dialogs[slug];
}
