import { MpControlType, MpFormType } from "@mp-react/form";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { CustomFormControl } from "../../../types/Common";
import { getTimePlaceholder, useDatePlaceholder } from "../../../utils/Common";
import { FormEndAdornmentTypes } from "../../../constants/Form";

export const useEmployeeInformationInsideCompany = (
  readonly?: boolean
): MpFormType => {
  const { t } = useTranslation();
  const { datePlaceholder } = useDatePlaceholder();

  const controls = useMemo(
    (): CustomFormControl[] => [
      {
        type: "companyAndDepartmentDropdown" as any,
        key: "companyId",
        tooltip: "tooltip.employee_info_company",
        label: "",
      },
      {
        type: MpControlType.text,
        key: "jobTitle",
        placeholder: t("common.copywriter"),
        label: t("employees.job_title"),
        tooltip: "tooltip.employee_info_job_title",
        readonly: !!readonly,
      },
      {
        type: MpControlType.text,
        key: "employeeId",
        placeholder: t("employees.employee_id"),
        label: t("employees.employee_id"),
        tooltip: "tooltip.employee_info_employee_id",
        readonly: !!readonly,
      },
      {
        type: "dropdown" as any,
        key: "capacity",
        label: t("employees.capacity"),
        required: true,
        tooltip: "tooltip.employee_info_capacity",
        children: [
          {
            type: "select" as any,
            key: "fullTime",
            label: t("employees.full_time"),
          },
          {
            type: "select" as any,
            key: "partTime",
            label: t("employees.part_time"),
          },
          {
            type: "select" as any,
            key: "intern",
            label: t("employees.inter"),
          },
        ],
      },
      {
        type: "mergedGroup" as any,
        label: t("employees.start_date"),
        endAdornment: FormEndAdornmentTypes.Clear,
        children: [
          {
            type: "date" as any,
            key: "employmentDate",
            placeholder: datePlaceholder,
            span: {
              sm: 6,
            },
          },
          {
            type: "time" as any,
            key: "employmentTime",
            placeholder: getTimePlaceholder(),
            span: {
              sm: 6,
            },
          },
        ],
      },
      {
        type: "mergedGroup" as any,
        label: t("employees.expiration_date"),
        endAdornment: FormEndAdornmentTypes.Clear,
        children: [
          {
            type: "date" as any,
            key: "endDate",
            placeholder: datePlaceholder,
            span: {
              sm: 6,
            },
          },
          {
            type: "time" as any,
            key: "endTime",
            placeholder: getTimePlaceholder(),
            span: {
              sm: 6,
            },
          },
        ],
      },
    ],
    [t, readonly, datePlaceholder]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
