import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors, Shadows } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 1,
      top: "-10px !important",
    },
    popoverPaper: {
      boxShadow: Shadows.BulkAction,
      zIndex: 1,
      minWidth: 150,
    },
    unfilledIndicator: {
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        top: 0,
        right: "-8px",
        borderRadius: "50%",
        backgroundColor: Colors.Error,
        width: 8,
        height: 8,
      },
    },
    green: {
      color: Colors.Success,
    },
    activeItem: {
      fontWeight: "bold",
    },
  })
);
