import { Colors } from "./../../../constants/Style";
import { makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  implementationToggle: {
    marginLeft: 0,
  },
  statusToggleButton: {
    // transition: theme.transitions.create(["color"]),
    "&:first-child::before": {
      content: "''",
      width: 50,
      height: 28,
      position: "absolute",
      borderRadius: 30,
      transition: theme.transitions.create(["background-color", "transform"]),
      transform: "translateX(0px)",
    },
    "& .MuiToggleButton-label": {
      zIndex: 1,
    },
  },
  flexTransform: {
    "&:first-child::before": {
      transform: "translateX(60px)",
      backgroundColor: Colors.StatusYellow,
    },
  },
  onTransform: {
    "&:first-child::before": {
      transform: "translateX(120px)",
      backgroundColor: Colors.StatusGreen,
    },
  },
  offTransform: {
    "&:first-child::before": {
      transform: "translateX(0px)",
      backgroundColor: Colors.Default,
    },
  },
}));
