import React from "react";
import { useTranslation } from "react-i18next";
import AddExpenseDialog from "../../../../../components/benefitExpenses/AddExpenseDialog";
import AddNewButton from "../../../../../components/common/AddNewButton/AddNewButton";
import { useDialog } from "../../../../../utils/Dialog";

interface Props {
  employeeId: string;
  onComplete: () => void;
}

const AddExpense = ({ employeeId, onComplete }: Props) => {
  const { t } = useTranslation();
  const { open, openDialog, closeDialog } = useDialog();

  const handleCompletion = async () => {
    closeDialog();
    onComplete();
  };

  return (
    <div>
      <AddNewButton onClick={openDialog}>
        {t("benefitExpenses.addExpense")}
      </AddNewButton>
      <AddExpenseDialog
        employeeIds={[employeeId]}
        open={open}
        onCancel={closeDialog}
        onComplete={handleCompletion}
      />
    </div>
  );
};

export default AddExpense;
