import { useDefaultHighlights } from "./../../constants/Table";
import { MpColumn, MpTableType } from "@mp-react/table";
import { CustomRendererType } from "../../types/Table";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useEmployeeInnerFlexAllocationTableType = (): MpTableType => {
  const { t } = useTranslation();
  const highlights = useDefaultHighlights();
  const { keyWithSuffix } = useLocalStorage();

  const columns = useMemo(
    (): MpColumn[] => [
      {
        label: t("common.starts_from"),
        key: "startDate",
        sort: false,
        render: CustomRendererType.dateFormat as any,
      },
      {
        label: t("employees.accrual"),
        key: "accrual",
        sort: false,
        render: CustomRendererType.yesNo as any,
      },
      {
        label: t("employees.allocation_frequency"),
        sort: false,
        key: "allocationFrequency",
        render: CustomRendererType.allocationFrequency as any,
      },
      {
        label: t("employees.allocated_amount"),
        key: "allocatedAmount",
        render: CustomRendererType.currency as any,
        sort: false,
      },
      {
        label: "",
        key: "isActive",
        sort: false,
        render: CustomRendererType.statusPill as any,
        width: 106,
        fixedHeadWidth: true,
      },
    ],
    [t]
  );

  return {
    columns,
    rowIdKey: "id",
    filterVariant: "head",
    disablePagination: true,
    highlights: highlights,
    storageIdentifier: keyWithSuffix(StorageKeys.employeeAllocationsTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
