import { UseTabs } from "./../types/Common";
import { HeaderVariant, useTableStore } from "./../state/Table";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { parse } from "querystring";

export default function useTabs(initialTab: string): UseTabs {
  const history = useHistory();
  const location = useLocation();
  const setHeaderVariant = useTableStore((state) => state.setVariant);

  const params = useMemo(
    () => parse(location?.search.replace("?", "")),
    [location?.search]
  );

  const paramTab = useMemo(() => params?.tab as string, [params?.tab]);

  const [currentTab, setCurrentTab] = useState<string>(initialTab);

  useEffect(() => {
    if ("tab" in params) {
      if (paramTab !== currentTab) {
        setHeaderVariant(paramTab as HeaderVariant);
        setCurrentTab(paramTab);
      }
    }
  }, [currentTab, paramTab, params, setHeaderVariant]);

  const clickableBackTabs = useMemo(
    () => ["companySettingsCompanyGroups", "companySettingsCompanies"],
    []
  );

  const changeTab = useCallback(
    async (event: any, tab: string) => {
      const isTabAsBack = clickableBackTabs.includes(tab);
      if (params?.tab !== tab || isTabAsBack) {
        await history.push({
          pathname: location?.pathname,
          search: `tab=${tab}`,
        });
        if (paramTab === tab) {
          setHeaderVariant(tab as HeaderVariant);
          setCurrentTab(tab);
        }
      }
    },
    [
      clickableBackTabs,
      history,
      location?.pathname,
      paramTab,
      params?.tab,
      setHeaderVariant,
    ]
  );

  return {
    currentTab,
    changeTab,
  };
}
