import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    bottomBorders: {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
    borderRadius: {
      borderRadius: "8px",
    },
  })
);
