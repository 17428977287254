import moment from "moment";
import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isArray } from "lodash";

export const browserLanguage =
  (window.navigator as any)?.userLanguage || window.navigator.language;

export const formatDecimal = (amount: number) => {
  const decimalFormatterEN = new Intl.NumberFormat("en-EN", {
    style: "decimal",
    minimumFractionDigits: 0,
  });

  return decimalFormatterEN.format(amount).replaceAll(",", "’");
};

export const formatDecimalWithSpace = (amount: number) => {
  const decimalFormatterEN = new Intl.NumberFormat("en-EN", {
    style: "decimal",
    minimumFractionDigits: 0,
  });

  return decimalFormatterEN.format(amount).replaceAll(",", " ");
};

export const percentageDifferenceToPrev = (current: number, prev: number) => {
  if (current === 0 && prev === 0) return 0;
  if (prev === 0) return 100;

  return (((current - prev) / prev) * 100).toFixed();
};

export const percentageValue = (current: number, defaultValue: number) => {
  if (defaultValue === 0) return 0;

  return Number(((current / defaultValue) * 100).toFixed());
};

export const getChipInvestment = (investment: number) => {
  if (investment > -1) {
    return `+ ${formatDecimal(investment)}`;
  } else if (investment < 1) {
    return `- ${formatDecimal(Math.abs(investment))}`;
  }

  return null;
};

export const parseFormCheckboxes = (
  items: Record<string, boolean> | undefined
): string[] => {
  if (!items) return [];
  const entries = Object.entries(items);
  return entries
    .filter(([key, value]) => key !== "all" && value === true)
    .map(([key]) => key);
};

export const parseCheckboxDefaultValues = (
  items: { id: string }[]
): Record<string, boolean> => {
  return (
    items?.reduce((acc: any, companyGroup: any) => {
      const { id } = companyGroup;
      return { ...acc, [id]: true };
    }, {}) ?? {}
  );
};

export const mergeDateTime = (date: string, time: string) => {
  if (!date && !time) return null;
  if (!date || !moment(date).isValid()) return moment(time).format();
  if (!time || !moment(time).isValid()) return moment(date).format();

  return `${moment.parseZone(date).format().split("T")[0]}T${
    moment.parseZone(time).format().split("T")[1]
  }`;
};

export const getTimezoneFromDate = (date?: string | null) => {
  if (!date) return 0;
  return moment.parseZone(date).utcOffset() / 60;
};

export const getDateIsoFormat = (date?: string | null) => {
  if (!date) return 0;
  return moment(date)
    .utcOffset(0, true)
    .set({ hour: 0, minute: 0, seconds: 0 })
    .toISOString();
};

export const useDateFormat = (value?: string) => {
  const { i18n } = useTranslation();

  const localeLanguage = useMemo(
    () => (i18n.language ? i18n.language : "en"),
    [i18n.language]
  );

  const momentDate = useMemo(
    () => (!!value ? moment.parseZone(value).locale(browserLanguage) : null),
    [value]
  );

  const fullDate = useMemo(
    () =>
      !!momentDate ? momentDate.locale(browserLanguage).format("L") : null,
    [momentDate]
  );

  const shortMonthDate = useMemo(
    () =>
      !!momentDate ? momentDate.locale(browserLanguage).format("ll") : null,
    [momentDate]
  );
  const dateTime = useMemo(
    () =>
      !!momentDate
        ? `${momentDate.locale(browserLanguage).format("L")} ${momentDate
            .locale(browserLanguage)
            .format("HH:mm")}`
        : null,
    [momentDate]
  );
  const date = useMemo(
    () =>
      !!momentDate ? momentDate.locale(browserLanguage).format("L") : null,
    [momentDate]
  );
  const time = useMemo(
    () =>
      !!momentDate ? momentDate.locale(browserLanguage).format("HH:mm") : null,
    [momentDate]
  );

  const quarter = useMemo(
    () => (!!momentDate ? momentDate.locale(browserLanguage).quarter() : null),
    [momentDate]
  );

  const year = useMemo(
    () => (!!momentDate ? momentDate.locale(browserLanguage).year() : null),
    [momentDate]
  );

  const shortMonth = useMemo(() => {
    if (!!momentDate) {
      const month = momentDate.locale(browserLanguage).format("MMMM");
      return month[0].toUpperCase() + month?.substring(1);
    } else {
      return null;
    }
  }, [momentDate]);

  const keyboardDatePickerFormat = useMemo(
    () => moment.localeData(browserLanguage).longDateFormat("L"),
    []
  );

  const getDateWithTimezone = useCallback(
    (date?: string | null, tz?: number) => {
      if (!date) return null;
      const timezone = !!tz ? tz : 0;
      return moment(date).utcOffset(timezone).format();
    },
    []
  );

  return {
    fullDate,
    shortMonthDate,
    dateTime,
    date,
    time,
    shortMonth,
    localeLanguage,
    quarter,
    year,
    getDateWithTimezone,
    keyboardDatePickerFormat,
  };
};

export const useCheckboxes = (formState: UseFormMethods, key: string) => {
  const { watch, setValue, getValues } = formState;
  const watchAllCheckboxes = watch(key);
  const watchSelectAll = watch(`${key}.all`);

  const [selectedAll, setSelectedAll] = useState<boolean>(
    !!watchSelectAll ?? false
  );

  const selectAllHandler = useCallback(
    (selected: boolean) => {
      const formValues = getValues();
      const values = Object.keys(formValues[key] ?? {});
      values?.forEach((id: string) => {
        setValue(`${key}.${id}`, selected);
      });
      setSelectedAll(selected);
    },
    [getValues, key, setValue]
  );

  useEffect(() => {
    if (watchSelectAll) {
      selectAllHandler(true);
    } else if (watchSelectAll !== undefined && selectedAll) {
      selectAllHandler(false);
    }
  }, [selectAllHandler, selectedAll, watchSelectAll]);

  useEffect(() => {
    if (selectedAll) {
      if (Object.values(watchAllCheckboxes).some((val) => !val)) {
        setValue(`${key}.all`, false);
        setSelectedAll(false);
      }
    }
  }, [key, selectedAll, setValue, watchAllCheckboxes]);
};

export const getTimePlaceholder = () => "hh:mm";

export const useDatePlaceholder = () => {
  const { localeLanguage } = useDateFormat();

  const datePlaceholder = useMemo(() => {
    if (!!localeLanguage) {
      return moment.localeData(browserLanguage).longDateFormat("L");
    }
    return moment.localeData(browserLanguage).longDateFormat("L");
  }, [localeLanguage]);

  return { datePlaceholder };
};

export const truncate = (input: string, symbolLength?: number) => {
  const length = symbolLength ?? 16;
  if (input.length > length) {
    return input.substring(0, length) + "...";
  }
  return input;
};

export const replaceToUnderscoreCase = (text: string) => {
  if (!text || text === "") return "";
  return text
    .replace(/\.?([A-Z])/g, function (x, y) {
      return "_" + y.toLowerCase();
    })
    .replace(/^_/, "");
};

export const replaceToCamelCase = (text: string) => {
  return text
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const numberDecimalsFormatter = (
  num: number,
  fractionDigits: number
) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(fractionDigits).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(fractionDigits).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(fractionDigits).replace(/\.0$/, "") + "k";
  }
  return num;
};

export const objectLength = (obj: object) => {
  return !!obj ? Object.keys(obj)?.length : 0;
};

export const capitalizeFirst = (text: string) => {
  return !!text ? text[0].toUpperCase() + text?.substring(1) : "";
};

export const removeFalseyValuesFromObject = (obj: object) => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(([, value]) => !!value);
  const filteredObject = Object.fromEntries(filteredEntries);
  return filteredObject;
};

export const sanitizeFalseyValues = (
  data: Array<object> | object
): object | Array<object> => {
  if (isArray(data) && (data as Array<object>)?.length > 0) {
    const sanitizedData = (data as Array<object>).map((obj) =>
      removeFalseyValuesFromObject(obj)
    );
    return sanitizedData;
  }

  if (typeof data === "object") {
    return removeFalseyValuesFromObject(data);
  }

  return data;
};

export const isAfterTwoWeeks = (date: string) => {
  const futureDate = moment().startOf("day").add(14, "days");
  return moment(date).isAfter(futureDate);
};

export const isInPast = (date: string) => {
  return moment(date).isBefore();
};

export const isInFuture = (date: string) => {
  return moment(date).isAfter();
};

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: any) => void
): void {
  useEffect(() => {
    const listener = (event: any) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler]);
}

export const getDefaultCurrencyNumber = (value?: string | number) => {
  if (!value) return "";
  return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
