import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    input: {
      "& .MuiIconButton-root": {
        display: "none",
      },
      "& .MuiOutlinedInput-root.Mui-error": {
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.Error,
          },
        },
      },
    },
    readonly: {
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
        {
          borderColor: Colors.BorderOutlinedInput,
          borderLeft: "none",
        },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        background: "unset",
        overflow: "unset",
      },
    },
    tzButton: {
      position: "absolute",
      top: 0,
      right: 0,
      padding: "11px 15px",
    },
  })
);
