import { useCallback, useMemo } from "react";
import {
  Client,
  ClientAdministratorsFooter,
  ClientAdministratorsResponse,
  ClientGeneralSettingsResponse,
  ClientResponse,
  ClientsFilterNames,
  CreateClientRequest,
  ImportClientsResponse,
} from "./../types/Clients";
import { Endpoints } from "./../api/constants";
import useSWR, { mutate } from "swr";
import { mutateData } from "../api/api";
import { toast } from "react-toastify";
import { useLoading } from "../utils/Loading";
import { useTranslation } from "react-i18next";
import {
  Administrator,
  LocalPermissionsRequest,
  UsePermissionsParams,
} from "../types/Administrators";
import { useHistory } from "react-router-dom";
import { usePermissionUtils } from "../utils/Permissions";
import { MpAsyncGetMethod } from "@mp-react/table";
import { AdministratorFilterNames } from "../constants/Administrators";
import { AxiosResponse } from "axios";
import { useAdminUtils } from "../utils/Administrators";
import { MpAsyncGetMethodArguments } from "../types/Table";

export const useClients = (query?: string) => {
  const { t } = useTranslation();

  const {
    data: clientsData,
    error: clientsError,
    mutate: mutateClients,
  } = useSWR<ClientResponse, any>(
    `${Endpoints.parentCompany}${!!query ? `?${query}` : ""}`
  );

  const apiLoading = useMemo(
    () => !clientsError && !clientsData,
    [clientsData, clientsError]
  );

  const { stopLoading, startLoading, loading } = useLoading();

  const clients: Client[] = useMemo(() => {
    if (!clientsData?.data) return [];

    return clientsData.data.map((client) => ({
      ...client,
      inactive: client.status === "inactive",
    }));
  }, [clientsData?.data]);

  const pageSize = useMemo(
    () => clientsData?.pageSize ?? 0,
    [clientsData?.pageSize]
  );

  const count = useMemo(() => clientsData?.count ?? 0, [clientsData?.count]);

  const totals = useMemo(() => clients.length > 0, [clients.length]);

  const totalsData = useMemo(() => {
    return {};
  }, []);

  const createClient = useCallback(
    async (data: CreateClientRequest) => {
      data.adminPhone = `+${data.adminPhone}`;
      startLoading();
      const newClient = await mutateData(
        "post",
        Endpoints.createParentCompany,
        data
      )
        .then(() => {
          toast(t("common.added_succesfully"), { type: "success" });
          mutateClients();
        })
        .finally(() => {
          stopLoading();
        });
      return newClient;
    },
    [startLoading, stopLoading, t, mutateClients]
  );

  return {
    loading: apiLoading || loading,
    clients,
    count,
    pageSize,
    totals,
    totalsData,
    createClient,
  };
};

export const useClientsAsyncMethods = (): Record<string, MpAsyncGetMethod> => {
  const baseUrl = `${Endpoints.parentCompany}/filterValues`;

  const getFilterItems = useCallback(
    (filterName: ClientsFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: ClientsFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";

      const apiUrl = `${baseUrl}/${filterName}${searchParam}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getNames = useCallback<MpAsyncGetMethod>(
    (args) => getAsyncFilterItems(args, ClientsFilterNames.NAME),
    [getAsyncFilterItems]
  );

  const getCountries = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(ClientsFilterNames.COUNTRY),
    [getFilterItems]
  );

  return {
    getNames,
    getCountries,
  };
};

export const useClient = (clientId?: string) => {
  const { t } = useTranslation();
  const url = !!clientId ? `${Endpoints.parentCompany}/${clientId}` : null;
  const { data: clientResponse, error: clientError } = useSWR<
    ClientGeneralSettingsResponse,
    any
  >(url, {
    revalidateOnFocus: false,
  });

  const { loading, startLoading, stopLoading } = useLoading();

  const apiLoading = useMemo(
    () => !clientError && !clientResponse,
    [clientError, clientResponse]
  );

  const updateClient = useCallback(
    async (data: ClientGeneralSettingsResponse) => {
      if (!clientId && !url) {
        toast(t("errors.nothing_found"), { type: "error" });
        return;
      }
      startLoading();
      await mutateData("patch", url as string, data)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(url);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [clientId, startLoading, stopLoading, t, url]
  );

  const importClients = useCallback(
    async (file: File) => {
      startLoading();
      const formData = new FormData();
      formData.append("file", file);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const data = await mutateData(
        "post",
        `${url}/importEmployees`,
        formData,
        headers
      )
        .then((res) => {
          const response = res.data as ImportClientsResponse;
          if (response?.errors?.length === 0) {
            toast(t("common.added_succesfully"), { type: "success" });
            mutate(url);
          }

          return response;
        })
        .finally(() => {
          stopLoading();
        });
      return data;
    },
    [startLoading, stopLoading, t, url]
  );

  return {
    apiLoading,
    loading,
    updateClient,
    client: clientResponse,
    importClients,
  };
};

export const useClientAdministrators = (clientId: string, query?: string) => {
  const { t } = useTranslation();
  const { parseAdministratorRequest } = useAdminUtils();
  const { loading, startLoading, stopLoading } = useLoading();

  const url = !!clientId
    ? `${Endpoints.parentCompany}/${clientId}/admins${
        !!query ? `?${query}` : ""
      }`
    : null;
  const {
    data: clientAdministratorsResponse,
    error: clientAdministratorError,
  } = useSWR<ClientAdministratorsResponse, any>(url);

  const apiLoading = useMemo(
    () => !clientAdministratorsResponse && !clientAdministratorError,
    [clientAdministratorError, clientAdministratorsResponse]
  );

  const clientAdministratorsData = useMemo(
    () =>
      clientAdministratorsResponse?.data?.map((item) => ({
        ...item,
        inactive: item.status === "inactive",
      })),
    [clientAdministratorsResponse?.data]
  );

  const count = useMemo(
    () => clientAdministratorsResponse?.count ?? 0,
    [clientAdministratorsResponse?.count]
  );

  const pageSize = useMemo(
    () => clientAdministratorsResponse?.pageSize ?? 0,
    [clientAdministratorsResponse?.pageSize]
  );

  const footerData = useMemo((): ClientAdministratorsFooter => {
    if (!clientAdministratorsResponse?.footer)
      return {
        email: "",
        fullName: "",
        phone: "",
        status: "",
      };

    const footerResponse = clientAdministratorsResponse.footer;
    return {
      email: `${footerResponse.email} ${t("common.emails")}`,
      fullName: `${footerResponse.fullName} ${t("sidebar.administrators")}`,
      phone: `${footerResponse.phone} ${t("administrators.phone_numbers")}`,
      status: "",
    };
  }, [clientAdministratorsResponse?.footer, t]);

  const createClientsAdministrator = useCallback(
    async (data: Partial<Administrator>) => {
      startLoading();
      const parsedData = parseAdministratorRequest(data);
      await mutateData("post", `${url}/create`, parsedData)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(url);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [parseAdministratorRequest, startLoading, stopLoading, t, url]
  );

  return {
    loading,
    apiLoading,
    clientAdministratorsData,
    count,
    pageSize,
    footerData,
    createClientsAdministrator,
  };
};

export const useClientAdministratorsAsyncMethods = (
  clientId: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () =>
      !!clientId
        ? `${Endpoints.parentCompany}/${clientId}/admins/filterValues`
        : "",
    [clientId]
  );

  const getFilterItems = useCallback(
    (filterName: AdministratorFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getFullNames = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.FULL_NAMES),
    [getFilterItems]
  );

  const getEmail = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.EMAIL),
    [getFilterItems]
  );

  const getPhone = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.PHONE),
    [getFilterItems]
  );

  return {
    getFullNames,
    getEmail,
    getPhone,
  };
};

export const useClientAdministrator = (
  clientId?: string,
  administratorId?: string,
  disableAPI?: boolean
) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { parseAdministratorRequest } = useAdminUtils();
  const { loading, startLoading, stopLoading } = useLoading();
  const url = useMemo(
    () => `${Endpoints.parentCompany}/${clientId}/admins/${administratorId}`,
    [administratorId, clientId]
  );
  const { data: clientAdministratorData, error: clientAdministratorError } =
    useSWR(!!clientId && !disableAPI ? url : null);

  const apiLoading = useMemo(
    () => !clientAdministratorData && !clientAdministratorError,
    [clientAdministratorData, clientAdministratorError]
  );

  const updateAdministrator = useCallback(
    async (data: Partial<Administrator>, redirect?: boolean) => {
      const parsedData = parseAdministratorRequest(data);
      startLoading();
      await mutateData("patch", url, parsedData)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(url);
          if (redirect !== false) {
            history.push(`/clients/view/${clientId}?tab=clientAdministrators`);
          }
        })
        .finally(() => {
          stopLoading();
        });
    },
    [
      clientId,
      history,
      parseAdministratorRequest,
      startLoading,
      stopLoading,
      t,
      url,
    ]
  );

  const resendInvite = useCallback(() => {
    startLoading();
    mutateData(
      "post",
      `${Endpoints.parentCompany}/${clientId}/admins/${administratorId}/resendInvite`
    )
      .then(() => {
        toast(t("common.invitation_sent"), { type: "success" });
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, stopLoading, t, clientId, administratorId]);

  const deleteAdmin = useCallback(() => {
    startLoading();
    mutateData(
      "delete",
      `${Endpoints.deleteMelpAdmin}/${administratorId}?parentCompanyId=${clientId}`
    )
      .then(() => {
        toast(t("common.deleted_succesfully"), { type: "success" });
        history.push(`/clients/view/${clientId}?tab=clientAdministrators`);
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, administratorId, t, history, clientId, stopLoading]);

  const changeParentCompanyAdminPassword = useCallback(
    async (newPassword) => {
      startLoading();
      let sucess = false;
      await mutateData(
        "patch",
        `${Endpoints.parentCompany}/${clientId}/admins/${administratorId}/changePassword`,
        {
          newPassword,
        }
      )
        .then(() => {
          sucess = true;
          toast(t("common.updated_succesfully"), { type: "success" });
        })
        .finally(() => {
          stopLoading();
        });
      return sucess;
    },
    [startLoading, stopLoading, clientId, administratorId, t]
  );

  return {
    resendInvite,
    apiLoading,
    loading,
    updateAdministrator,
    clientAdministratorData,
    deleteAdmin,
    changeParentCompanyAdminPassword,
  };
};

export const useClientPermissions = ({
  clientId,
  adminId,
  selectedId,
  removeChanges,
}: UsePermissionsParams) => {
  const { startLoading, stopLoading, loading: apiLoading } = useLoading();
  const { t } = useTranslation();
  const { clientAdministratorData } = useClientAdministrator(clientId, adminId);

  const {
    wasChanged,
    parsePermissionEntry,
    getAllLocalPermissions,
    getCompanyGroupPermissions,
    getCompanyPermissions,
    getParsedPermissions,
  } = usePermissionUtils();

  const clientAdminIsRoot = !!clientAdministratorData?.root;

  const companyId = useMemo(() => {
    if (wasChanged) return false;
    if (selectedId.includes("company-"))
      return selectedId.replace("company-", "");
    return false;
  }, [selectedId, wasChanged]);

  const companyGroupId = useMemo(() => {
    if (wasChanged) return false;
    if (selectedId.includes("companyGroup-"))
      return selectedId.replace("companyGroup-", "");
    return false;
  }, [selectedId, wasChanged]);

  const basePermissionUrl = useMemo(
    () =>
      `${Endpoints.parentCompany}/${clientId}/admins/${adminId}/permissions`,
    [adminId, clientId]
  );

  const companyGroupUrl = useMemo(
    () =>
      companyGroupId
        ? `${basePermissionUrl}/companyGroup/${companyGroupId}`
        : null,
    [basePermissionUrl, companyGroupId]
  );

  const companyUrl = useMemo(() => {
    if (selectedId === "allLocalPermissions")
      return `${basePermissionUrl}/company/`;
    return companyId ? `${basePermissionUrl}/company/${companyId}` : null;
  }, [basePermissionUrl, companyId, selectedId]);

  const globalPermissionUrl = useMemo(
    () =>
      adminId && !companyGroupId && !companyId && selectedId
        ? `${basePermissionUrl}/global`
        : null,
    [adminId, basePermissionUrl, companyGroupId, companyId, selectedId]
  );

  const { data: globalPermissions, error: globalPermissionError } =
    useSWR(globalPermissionUrl);

  const { data: companyPermissions, error: companyError } = useSWR(companyUrl);

  const { data: companyGroupPermissions, error: companyGroupError } =
    useSWR(companyGroupUrl);

  const globalPermissionsLoading = useMemo(
    () => !globalPermissions && !globalPermissionError,
    [globalPermissionError, globalPermissions]
  );

  const companyPermissionsLoading = useMemo(
    () => !companyPermissions && !companyError,
    [companyError, companyPermissions]
  );

  const companyGroupPermissionsLoading = useMemo(
    () => !companyGroupPermissions && !companyGroupError,
    [companyGroupError, companyGroupPermissions]
  );

  const loading = useMemo(() => {
    if (!selectedId) return false;
    if (!!companyGroupId) return companyGroupPermissionsLoading;
    if (!!companyId) return companyPermissionsLoading;

    return globalPermissionsLoading;
  }, [
    companyGroupId,
    companyGroupPermissionsLoading,
    companyId,
    companyPermissionsLoading,
    globalPermissionsLoading,
    selectedId,
  ]);

  const parsedGlobalPermissions = useMemo(
    () => getParsedPermissions(globalPermissions, clientAdminIsRoot),
    [getParsedPermissions, globalPermissions, clientAdminIsRoot]
  );

  const parsedCompanyPermissions = useMemo(
    () => getParsedPermissions(companyPermissions, clientAdminIsRoot),
    [companyPermissions, getParsedPermissions, clientAdminIsRoot]
  );

  const parsedCompanyGroupPermissions = useMemo(
    () => getParsedPermissions(companyGroupPermissions, clientAdminIsRoot),
    [companyGroupPermissions, getParsedPermissions, clientAdminIsRoot]
  );

  const updateGlobalPermissions = useCallback(
    async (globalEntries: [string, any] | undefined) => {
      if (!globalEntries) return;
      const requestData = parsePermissionEntry([globalEntries]);
      if (requestData.length > 0) {
        await mutateData("patch", `${basePermissionUrl}/global`, {
          updatedModules: requestData,
        });
        mutate(`${basePermissionUrl}/global`);
      }
    },
    [basePermissionUrl, parsePermissionEntry]
  );

  const updateLocalPermissions = useCallback(
    async (requestData: LocalPermissionsRequest) => {
      if (requestData.updatedModules.length > 0) {
        await mutateData("patch", `${basePermissionUrl}/local`, requestData);
        mutate(`${basePermissionUrl}/local`);
      }
    },
    [basePermissionUrl]
  );

  const updatePermissions = useCallback(
    async (data: FormData) => {
      startLoading();
      const entries = Object.entries(data);
      const globalEntries = entries.find(
        ([key]) => key === "allGlobalPermissions"
      );
      await updateGlobalPermissions(globalEntries);

      const localEntries = entries.find(
        ([key]) => key === "allLocalPermissions"
      );
      const companyGroupEntries = entries
        .filter(([key]) => key.includes("companyGroup-"))
        .map(([key, value]) => [key.replace("companyGroup-", ""), value]);
      const companyEntries = entries
        .filter(([key]) => key.includes("company-"))
        .map(([key, value]) => [key.replace("company-", ""), value]);
      const localPermissions = getAllLocalPermissions(localEntries);
      const companyGroupPermissions =
        getCompanyGroupPermissions(companyGroupEntries);
      const companyPermissions = getCompanyPermissions(companyEntries);
      const requestData: LocalPermissionsRequest = {
        updatedModules: [
          ...companyPermissions,
          ...companyGroupPermissions,
          ...localPermissions,
        ],
      };
      await updateLocalPermissions(requestData);
      removeChanges();
      toast(t("common.updated_succesfully"), { type: "success" });
      mutate(Endpoints.me);
      stopLoading();
    },
    [
      startLoading,
      updateGlobalPermissions,
      getAllLocalPermissions,
      getCompanyGroupPermissions,
      getCompanyPermissions,
      updateLocalPermissions,
      removeChanges,
      t,
      stopLoading,
    ]
  );

  return {
    updatePermissions,
    globalPermissions,
    companyPermissions,
    companyGroupPermissions,
    loading: loading || apiLoading,
    companyGroupId,
    companyId,
    parsedGlobalPermissions,
    parsedCompanyPermissions,
    parsedCompanyGroupPermissions,
  };
};
