import {
  Box,
  Button,
  Paper,
  Popper,
  Typography,
  Grow,
  MenuList,
  MenuItem,
  Collapse,
  CircularProgress,
} from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { Languages } from "../../../types/Common";
import useStyles from "./UserDropdown.styles";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useHistory } from "react-router";
import { useAuth } from "../../../state/Auth";
import { useMe } from "../../../state/Administrators";
import { useOnClickOutside } from "../../../utils/Common";
import LanguageFlag from "../../common/LanguageFlag/LanguageFlag";
import { StorageKeys } from "../../../types/LocalStorage";

export default function UserDropdown() {
  const history = useHistory();
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const { logout } = useAuth();
  const { me } = useMe();
  const [open, setOpen] = useState(false);
  const [isLanguageView, setIsLanguageView] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const popperPaperRef = useRef(null);

  const parentCompany = useMemo(() => me?.parentCompany, [me?.parentCompany]);

  useEffect(() => {
    const localLang = localStorage.getItem(StorageKeys.lang);
    const defaultLang =
      parentCompany?.defaultLanguage.toLocaleLowerCase() ??
      me?.language.toLocaleLowerCase() ??
      "en";
    i18n.changeLanguage(localLang ?? defaultLang);
  }, [i18n, me?.language, parentCompany?.defaultLanguage]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleClose = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    },
    [anchorRef, setOpen]
  );

  const handleListKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Tab") {
        event.preventDefault();
        setOpen(false);
      }
    },
    [setOpen]
  );

  const handlePasswordChangeClick = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      handleClose(event);
      history.push("/change-password");
    },
    [handleClose, history]
  );

  const handleLogoutClick = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      handleClose(event);
      logout();
    },
    [handleClose, logout]
  );

  const openLanguageView = useCallback(
    () => setIsLanguageView(true),
    [setIsLanguageView]
  );
  const closeLanguageView = useCallback(
    () => setIsLanguageView(false),
    [setIsLanguageView]
  );

  useOnClickOutside(popperPaperRef, handleClose);

  const defaultView = useMemo(
    () => [
      <MenuItem
        classes={{ root: classes.vAlignCenter }}
        onClick={openLanguageView}
        key="current-language-button"
      >
        <Box display="flex" alignItems="center">
          <Box marginRight="11px" display="flex">
            <LanguageFlag languageCode={i18n.language} />
          </Box>
          <Typography variant="body2" color="textSecondary">
            {t(`original_language.${i18n.language.toUpperCase() as Languages}`)}
          </Typography>
        </Box>
        <ChevronRight />
      </MenuItem>,
      <MenuItem
        key="change-password-button"
        onClick={handlePasswordChangeClick}
        classes={{ root: classes.vAlignCenter }}
      >
        <Typography variant="body2" color="textSecondary">
          {t("actions.change_password")}
        </Typography>
        <ChevronRight />
      </MenuItem>,
      <MenuItem
        key="logout-button"
        onClick={handleLogoutClick}
        classes={{ root: classes.vAlignCenter }}
      >
        <Typography variant="body2" color="textSecondary">
          {t("common.logout")}
        </Typography>
      </MenuItem>,
    ],
    [
      classes.vAlignCenter,
      openLanguageView,
      t,
      i18n.language,
      handlePasswordChangeClick,
      handleLogoutClick,
    ]
  );

  const UserName = useMemo(() => {
    if (me?.firstName)
      return `${me?.firstName} ${me?.lastName[0]?.toUpperCase()}`;
    return (
      parentCompany?.name ?? <CircularProgress size={20} color="primary" />
    );
  }, [me?.firstName, me?.lastName, parentCompany?.name]);

  return (
    <Box>
      <Button
        classes={{ root: classes.button }}
        ref={anchorRef}
        aria-controls={open ? "user-dropdown" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Typography color="inherit" variant="subtitle1">
          <Box className={classes.buttonInner}>
            {UserName}
            <ChevronDown className={classes.buttonIcon} />
          </Box>
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper classes={{ root: classes.paperRoot }} ref={popperPaperRef}>
              <Box>
                <Collapse
                  in={!isLanguageView}
                  timeout={250}
                  mountOnEnter
                  unmountOnExit
                >
                  <MenuList
                    autoFocusItem={open}
                    id="user-dropdown"
                    onKeyDown={handleListKeyDown}
                    disablePadding
                  >
                    {defaultView}
                  </MenuList>
                </Collapse>
                <Collapse
                  in={isLanguageView}
                  timeout={250}
                  mountOnEnter
                  unmountOnExit
                >
                  <MenuList
                    autoFocusItem={open}
                    id="user-dropdown-language-change"
                    onKeyDown={handleListKeyDown}
                    disablePadding
                  >
                    <LanguageSwitcher
                      key="language-switcher-view"
                      handleBack={closeLanguageView}
                    />
                  </MenuList>
                </Collapse>
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
