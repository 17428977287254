import { FeatureKey } from "./Types";

export const features = [
  {
    key: FeatureKey.integrations,
    isEnabled: true,
  },
  {
    key: FeatureKey.users,
    isEnabled: false,
  },
  {
    key: FeatureKey.benefitUsage,
    isEnabled: true,
  },
  {
    key: FeatureKey.marketplace,
    isEnabled: true,
  },
  {
    key: FeatureKey.benefitTemplates,
    isEnabled: false,
  },
];
