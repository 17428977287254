import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { MpTable, MpRowActionParameters } from "@mp-react/table";
import useTableUtils from "../../../../../utils/Table";
import { rowMethods } from "../../../../../utils/TableMethods";
import { useBenefitsChoicesTableType } from "../../../../../configs/Tables/BenefitsChoicesTableType";
import {
  useInnerBenefitsChoices,
  useBenefitChoicesAsyncMethods,
} from "../../../../../state/InnerBenefitsChoices";
import { ReminderRequest } from "../../../../../types/NotificationService";
import SendReminderDialog from "../../../../../components/dialogs/SendReminderDialog/SendReminderDialog";
import { useChoicesNotifications } from "../../../../../utils/ChoicesNotifications";
import { useMe } from "../../../../../state/Administrators";
import useStyles from "../../../../../styles/Table.styles";

export default function BenefitsChoices() {
  const classes = useStyles();
  const { id } = useParams() as any;
  const {
    overridables,
    translations,
    overrideClasses,
    handleGetData,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
  } = useTableUtils("innerBenefitsChoices");
  const choicesTableType = useBenefitsChoicesTableType();
  const asyncGetMethods = useBenefitChoicesAsyncMethods(id);
  const {
    benefitsChoices,
    loading,
    updateImplementationDate,
    mappedBenefitsChoicesTableData,
    bulkMethods,
    parsedBenefitChoicesTotals,
  } = useInnerBenefitsChoices(id, tableQueryParam);
  const {
    sendReminders,
    successEmployeeId,
    handleCloseDialog,
    openDialog,
    getIdsForRequest,
    sendReminderAgain,
    loadingNotifications,
  } = useChoicesNotifications();
  const { canEditChoices } = useMe();

  const reminderDialogEmployees = useMemo(
    () => successEmployeeId ?? [],
    [successEmployeeId]
  );

  const bulkMethodsWithReminder = useMemo(
    () => ({ ...bulkMethods, sendReminders }),
    [bulkMethods, sendReminders]
  );

  const toggleImplementationDate = useCallback(
    (current: MpRowActionParameters) => {
      const { value, rowId } = current;
      const newValue = value ? value : null;
      updateImplementationDate([
        { id: rowId, flexImplementationDate: newValue },
      ]);
    },
    [updateImplementationDate]
  );

  const handleSubmit = useCallback(async () => {
    const employeeAndBenefitIds: ReminderRequest[] =
      getIdsForRequest(successEmployeeId);
    const result = await sendReminderAgain(employeeAndBenefitIds);
    if (result) handleCloseDialog();
  }, [
    getIdsForRequest,
    handleCloseDialog,
    sendReminderAgain,
    successEmployeeId,
  ]);

  return (
    <Box className={canEditChoices ? "" : classes.disableTable}>
      <MpTable
        {...choicesTableType}
        data={mappedBenefitsChoicesTableData}
        pageSize={benefitsChoices?.pageSize}
        onGetData={handleGetData}
        overridables={overridables}
        translations={translations}
        bulkMethods={bulkMethodsWithReminder}
        classes={overrideClasses}
        loading={loading}
        totalsData={parsedBenefitChoicesTotals as any}
        totals={mappedBenefitsChoicesTableData?.length > 0}
        rowMethods={{ ...rowMethods, toggleImplementationDate }}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
        asyncGetMethods={asyncGetMethods}
      />
      <SendReminderDialog
        open={openDialog}
        slug={"sendReminder"}
        onCancelClick={handleCloseDialog}
        onSubmit={handleSubmit}
        id="send-reminder-dialog"
        responseData={reminderDialogEmployees}
        showNames="employee"
        loading={loadingNotifications}
      />
    </Box>
  );
}
