import React, { ReactNode } from "react";
import {
  DialogActions,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import useStyles from "./ConfirmationDialog.styles";
import DialogExtended from "../../common/DialogExtended/DialogExtended";
import { Button } from "@melp-design/components";

interface Props extends Pick<DialogProps, "open"> {
  id: string;
  title?: ReactNode;
  content: ReactNode;
  onCancel: () => void;
  onConfirm: (close: () => void) => void;
  loading?: boolean;
  confirmLabel?: string;
  confirmColor?: string;
}

const ConfirmationDialog = ({
  id,
  title,
  content,
  onCancel,
  onConfirm,
  loading,
  confirmLabel,
  confirmColor,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const titleId = `${id}-title`;
  const contentId = `${id}-content`;

  const handleConfirmation = () => {
    onConfirm(onCancel);
  };

  return (
    <DialogExtended
      classes={{ paper: classes.root }}
      aria-labelledby={titleId}
      aria-describedby={contentId}
      onClose={onCancel}
      onEnterPress={() => !loading && handleConfirmation()}
      {...rest}
    >
      <DialogTitle id={titleId}>
        <WarningRounded fontSize="large" className={classes.icon} />
        {title ?? t("common.confirmationModalTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={contentId}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text" disabled={loading}>
          {t("common.cancel")}
        </Button>
        <Button
          onClick={handleConfirmation}
          variant="contained"
          color="primary"
          disabled={loading}
          style={{
            backgroundColor: confirmColor,
          }}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            confirmLabel ?? t("common.confirm")
          )}
        </Button>
      </DialogActions>
    </DialogExtended>
  );
};

export type ConfirmationDialogProps = Props;
export default ConfirmationDialog;
