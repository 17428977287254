import React, { useMemo } from "react";
import { MpControlProps, controlRegisterOptions } from "@mp-react/form";
import { Controller } from "react-hook-form";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormGroup,
  Box,
} from "@material-ui/core";
import useStyles from "./EmployeeCategories.styles";
import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "../../../assets/icons/arrow-right.svg";
import { useTranslation } from "react-i18next";
import { CustomEmployeeCategory } from "../../../types/CustomEmployeeCategories";
import { useEmployeeInnerCategories } from "../../../state/CustomEmployeeCategories";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import { useMe } from "../../../state/Administrators";

function EmployeeCategoriesDropdownView({
  control,
  variant,
  onChange,
  value,
  inputRef,
  name,
  item,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
  name: string;
  inputRef: React.MutableRefObject<any>;
  item: CustomEmployeeCategory;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const classes = useStyles();
  const placeholder = useMemo(() => control?.placeholder, [control]);
  const id = useMemo(() => control?.key, [control]);
  const { t } = useTranslation();
  const { canEditCustomEmployeeCategory } = useMe();

  const hasMenuOptions = useMemo(
    () => item?.values && item?.values?.length > 0,
    [item?.values]
  );

  const ManageCategoriesOption = useMemo(() => {
    if (hasMenuOptions) return null;
    if (!canEditCustomEmployeeCategory) return null;
    return (
      <MenuItem
        component={Link}
        to={"/more/company-settings?tab=companySettingsCustomEmployeeCategory"}
        className={classes.link}
      >
        {t("form.manage")}
        <LinkIcon />
      </MenuItem>
    );
  }, [classes.link, hasMenuOptions, t, canEditCustomEmployeeCategory]);

  const NoDataOption = useMemo(() => {
    if (hasMenuOptions) return null;
    return (
      <MenuItem disabled value="">
        {t("common.no_items")}
      </MenuItem>
    );
  }, [hasMenuOptions, t]);

  const Options = useMemo(() => {
    if (hasMenuOptions)
      return item?.values?.map((value) => (
        <MenuItem key={value.id} value={value.id ?? ""}>
          {value.name}
        </MenuItem>
      ));

    return null;
  }, [hasMenuOptions, item?.values]);

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
      className={classes.categoryDropdownContainer}
    >
      <InputLabel className={classes.label} required={control.required}>
        {item.name}
      </InputLabel>
      <FormControl
        id="employee-categories-dropdown"
        variant={variant ?? "outlined"}
        fullWidth={true}
        required={control.required}
        className={classes.formControl}
      >
        {placeholder && (
          <InputLabel className={classes.placeholder} id={id}>
            {placeholder} {item.name.toLowerCase()}
          </InputLabel>
        )}
        <Select
          value={value ?? ""}
          onChange={onChange}
          inputRef={inputRef}
          labelId={placeholder && id}
          name={name}
        >
          {Options}
          {NoDataOption}
          {ManageCategoriesOption}
        </Select>
      </FormControl>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} marginTop="22px" />
    </Box>
  );
}

export default function EmployeeCategories({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  const { employeeInnerCategories } = useEmployeeInnerCategories();

  return (
    <FormGroup>
      {employeeInnerCategories?.map((item: CustomEmployeeCategory) => (
        <Controller
          key={item.id}
          name={`${control.key}.${item.id}` ?? ""}
          rules={rules}
          control={hookFormControl}
          defaultValue={null}
          render={({ onChange, value, name, ref }) => (
            <EmployeeCategoriesDropdownView
              name={name}
              inputRef={ref}
              onChange={onChange}
              value={value}
              control={control}
              locale={locale}
              size={size}
              layout={layout}
              variant={variant}
              error={error}
              defaultMessages={defaultMessages}
              item={item}
            />
          )}
        />
      ))}
    </FormGroup>
  );
}
