import React from "react";
import { Card as MuiCard, CardProps, makeStyles } from "@material-ui/core";
import { Effects } from "../../style/Effects";
import { Colors } from "../../style/Colors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      boxShadow: Effects.smallElementsShadow,
    },
  },
  highlighted: {
    borderColor: Colors.primary,
    backgroundColor: Colors.primaryLight,
  },
}));

interface Props extends CardProps {
  highlighted?: boolean;
}

const Card = ({ variant = "outlined", highlighted, ...props }: Props) => {
  const classes = useStyles();
  return (
    <MuiCard
      {...props}
      variant={variant}
      classes={{
        root: clsx(classes.root, highlighted && classes.highlighted),
      }}
    />
  );
};

export default Card;
