import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      padding: "8px 0",
      "& .MuiInputBase-root": {
        height: 44,
      },
    },
    link: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    label: {
      top: "50%",
      left: "-50%",
      transform: "translate(0, -50%)",
      maxWidth: "calc(50% - 16px)",
      position: "absolute",
    },
    placeholder: {
      maxWidth: "calc(100% - 45px)",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre",
      transform: "translate(14px, 19px) scale(1)",
      "&.Mui-focused": {
        transform: "translate(14px, 19px) scale(1)",
        color: "#929AA6",
      },
      "&.MuiFormLabel-filled": {
        display: "none",
      },
    },
    categoryDropdownContainer: {
      width: "100%",
    },
  })
);
