import React from "react";
import {
  Badge,
  Box,
  ClickAwayListener,
  Grow,
  makeStyles,
  Paper,
  Popper,
} from "@material-ui/core";
import { Button } from "@melp-design/components";
import clsx from "clsx";
import { NumberRange } from "../../types/Common";
import { Colors } from "@melp-design/style";

const useStyles = makeStyles((theme) => ({
  filterButton: {
    color: Colors.grey,
  },
  active: {
    color: theme.palette.text.primary,
  },
  filterContainer: {
    minWidth: 100,
    maxWidth: 300,
  },
  filterBadge: {
    right: 3,
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}));

const isNumberRange = (value?: any | null): value is NumberRange => {
  if (!value) {
    return false;
  }
  // is object
  if (typeof value === "object" && !Array.isArray(value)) {
    // is empty object
    if (!Object.keys(value).length) {
      return false;
    }
    return "from" in value || "to" in value;
  }
  return false;
};

type FilterValue = string | string[] | number | NumberRange | boolean;

interface FilterButtonRenderProps<T> {
  applyFilter: (value?: T) => void;
  value?: T;
  clearFilter: () => void;
}

interface Props<T> {
  /**
   * Text to display in button
   */
  label: string;
  /**
   * Filter value
   */
  value?: T;
  /**
   * Callback to execute when filter value changes
   */
  onChange?: (value?: T) => void;
  /**
   * Render filter content. If not set, component assumes that this is a
   * flag-type filter, i.e., clicking on filter button leads to setting
   * filter value to true and clicking on it again - to undefined.
   */
  children?: (props: FilterButtonRenderProps<T>) => React.ReactNode;
  /**
   * Disables react portal to allow displaying popper within modal.
   */
  disablePortal?: boolean;
}

const FilterButton = <T extends FilterValue>({
  value,
  onChange,
  label,
  children,
  disablePortal,
}: Props<T>) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActive = (() => {
    if (value === undefined || value === null) {
      return false;
    }
    if (isNumberRange(value)) {
      const { from, to } = value;
      const fromIsDefined = from !== null && from !== undefined;
      const toIsDefined = to !== null && to !== undefined;
      return fromIsDefined || toIsDefined;
    }
    if (typeof value === "boolean") {
      return value;
    }
    return typeof value === "number" || !!value.length;
  })();

  const appliedValuesCount = Array.isArray(value) ? value.length : 1;

  const handleApplyFilter = (newValue?: T) => {
    onChange?.(newValue);
    handleClose();
  };

  const handleClearFilter = () => {
    onChange?.(undefined);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (children) {
      setAnchorEl(event.currentTarget);
    } else {
      handleApplyFilter(!value ? (true as T) : undefined);
    }
  };

  const renderProps = {
    value,
    applyFilter: handleApplyFilter,
    clearFilter: handleClearFilter,
  };

  return (
    <>
      <Badge
        badgeContent={appliedValuesCount}
        invisible={!isActive}
        color="primary"
        classes={{ badge: classes.filterBadge }}
      >
        <Button
          variant="outlined"
          size="small"
          className={clsx(classes.filterButton, isActive && classes.active)}
          onClick={handleClick}
        >
          {label}
        </Button>
      </Badge>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        className={classes.popper}
        disablePortal={disablePortal}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Box mt={1} mr={1}>
              <Paper elevation={8}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div className={classes.filterContainer}>
                    {children?.(renderProps)}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Box>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default FilterButton;
