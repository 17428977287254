import { useTranslation } from "react-i18next";
import { MpControlType, MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { TooltipControl } from "../../types/Common";

export const useAdministratorsProgrammaticHrForm = (): MpFormType => {
  const { t } = useTranslation();

  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: MpControlType.text,
        key: "firstName",
        validations: {
          minLength: 2,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.first_name"),
        required: true,
        tooltip: "tooltip.administrator_first_name",
      },
    ],
    [t]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
