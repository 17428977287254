import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  datepicker: {
    "& .MuiPaper-root": {
      border: "1px solid rgba(0, 32, 64, 0.2)",
      borderRadius: "8px",
      width: "max-content",
    },
    "& .MuiBox-root": {
      boxSizing: "content-box",
    },
  },
  container: {
    padding: "8px 10px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "8px 8px 0 0",
  },
  button: {
    height: 36,
  },
  select: {
    height: 36,
    width: 114,
  },
});
