import React, { useMemo } from "react";
import { HeaderVariant, useTableStore } from "../../../state/Table";
import {
  BenefitsHeader,
  ChoicesHeader,
  EmployeeHeader,
  GroupsHeader,
  InnerEmployeeChoicesHeader,
  InnerEmployeeFlexAllocations,
  InnerEmployeeFlexBalance,
  InnerEmployeeAssignBenefits,
  InnerEmployeeLog,
  InnerEmployeeTermsAcceptance,
  NewsHeader,
  TermsAcceptanceHeader,
  DashboardHeader,
  Log,
  InnerGroupAssignEmployees,
  InnerBenefitsChoicesHeader,
  InnerBenefitTermsAcceptance,
  InnerEmployeeAssignGroupsHeaders,
  MarketplaceOrdersHeader,
} from "./variants";
import { Box } from "@material-ui/core";
import BenefitsAssignEmployeeHeader from "./variants/BenefitsAssignEmployeeHeader";
import AssignGroupsHeader from "./variants/AssignGroupsHeader";
import GroupsAssignBenefitsHeader from "./variants/GroupsAssignBenefitsHeader";
import ClientsHeader from "./variants/ClientsHeader";
import UsersHeader from "./variants/UsersHeader";
import { noBreadcrumbs } from "../../../constants/ParentCompany";
import IntegrationsHeader from "./variants/IntegrationsHeader";
import { useMe } from "../../../state/Administrators";

interface IProps {
  children?: React.ReactNode;
  selectedRowIds: string[];
}

export default function HeaderVariantMap({ children, selectedRowIds }: IProps) {
  const headerVariant =
    useTableStore((state) => state.variant as HeaderVariant) ?? "benefits";
  const { isMelpAdmin } = useMe();

  const header = useMemo(
    () => ({
      "": null,
      benefits: <BenefitsHeader />,
      choices: <ChoicesHeader />,
      employees: <EmployeeHeader />,
      groups: <GroupsHeader />,
      innerEmployeeChoices: <InnerEmployeeChoicesHeader />,
      innerEmployeeFlexAllocation: <InnerEmployeeFlexAllocations />,
      benefitsAssignGroups: <AssignGroupsHeader />,
      innerEmployeeFlexBalance: <InnerEmployeeFlexBalance />,
      benefitsAssignEmployees: <BenefitsAssignEmployeeHeader />,
      innerEmployeeAssignBenefits: <InnerEmployeeAssignBenefits />,
      innerEmployeeLog: <InnerEmployeeLog />,
      innerEmployeeTermsAcceptance: <InnerEmployeeTermsAcceptance />,
      innerEmployeeAssignGroups: <InnerEmployeeAssignGroupsHeaders />,
      news: <NewsHeader />,
      termsAcceptance: <TermsAcceptanceHeader />,
      dashboard: <DashboardHeader />,
      compareMode: null,
      log: <Log />,
      innerGroupAssignEmployees: <InnerGroupAssignEmployees />,
      innerBenefitsChoices: <InnerBenefitsChoicesHeader />,
      innerGroupAssignBenefits: <GroupsAssignBenefitsHeader />,
      innerBenefitTermsAcceptance: <InnerBenefitTermsAcceptance />,
      clients: <ClientsHeader />,
      integrations: <IntegrationsHeader />,
      users: <UsersHeader />,
      common: null,
      companySettingsAdministrators: null,
      companySettingsCompanies: null,
      companySettingsCompanyGroups: null,
      employeeCategories: null,
      clientAdministrators: null,
      recipients: null,
      marketplaceOrders: (
        <MarketplaceOrdersHeader selectedRowIds={selectedRowIds} />
      ),
    }),
    [selectedRowIds]
  );

  const Breadcrumbs = useMemo(() => {
    if (noBreadcrumbs.includes(headerVariant) || isMelpAdmin) return null;
    return <Box width={1}>{children}</Box>;
  }, [children, headerVariant, isMelpAdmin]);

  const headerVariantWidth = useMemo(
    () => (!!Breadcrumbs ? "unset" : 1),
    [Breadcrumbs]
  );

  const spacing = useMemo(() => (!!Breadcrumbs ? 10 : 0), [Breadcrumbs]);

  if (!headerVariant) return null;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      {Breadcrumbs}
      <Box
        display="flex"
        alignItems="center"
        width={headerVariantWidth}
        ml={spacing}
      >
        {header[headerVariant as HeaderVariant]}
      </Box>
    </Box>
  );
}
