import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    emptyImageBox: {
      border: `1px solid ${Colors.BorderDefault}`,
      borderRadius: 8,
      cursor: "pointer",
    },
    img: {
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      flex: 1,
      height: "100%",
      borderRadius: 8,
    },
    clearIcon: {
      position: "absolute",
      top: "-12px",
      right: "-38px",
    },
  })
);
