import { MpColumn, MpTableType } from "@mp-react/table";
import { CustomRendererType } from "../../types/Table";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useTableFilterTypes } from "../../utils/Table";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useEmployeeInnerLogTableType = (): MpTableType => {
  const { t } = useTranslation();
  const { keyWithSuffix } = useLocalStorage();

  const { getSearchSelectFilter, dateRangeFilter } = useTableFilterTypes();

  const doneByNameFilter = useMemo(
    () => getSearchSelectFilter("getDoneByName"),
    [getSearchSelectFilter]
  );

  const columns = useMemo(
    (): MpColumn[] => [
      {
        label: t("common.date"),
        key: "createdAt",
        render: CustomRendererType.dateTime as any,
        filter: dateRangeFilter,
        sort: false,
        // width: 160,
        // fixedHeadWidth: true,
      },
      {
        label: t("common.done_by"),
        key: "doneByName",
        render: CustomRendererType.empty as any,
        filter: doneByNameFilter,
        width: 186,
        fixedHeadWidth: true,
      },
      {
        label: t("common.action"),
        render: CustomRendererType.log as any,
        key: "action",
        sort: false,
        width: 590,
        fixedHeadWidth: true,
      },
    ],
    [dateRangeFilter, doneByNameFilter, t]
  );

  return {
    columns,
    rowIdKey: "id",
    filterVariant: "head",
    disablePagination: true,
    storageIdentifier: keyWithSuffix(StorageKeys.employeeLogsTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
