import { MarketplaceItem } from "../types/MarketplaceItems";
import { useTranslation } from "react-i18next";

export const useItemTranslation = (item: MarketplaceItem) => {
  const { i18n } = useTranslation();
  const { translations } = item;
  if (!translations.length) {
    return { title: item.name, description: "" };
  }
  const findTranslationByLang = (lang: string) =>
    translations.find(({ language }) => language === lang);
  const currentLanguage = i18n.language.toUpperCase();
  const userLangTranslation = findTranslationByLang(currentLanguage);
  if (userLangTranslation) {
    return userLangTranslation;
  }
  const enTranslation = findTranslationByLang("EN");
  return enTranslation ?? translations[0];
};
