import React from "react";
import {
  CardActions as MuiCardActions,
  CardActionsProps,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 15px 15px",
  },
}));

interface Props extends CardActionsProps {}

const CardActions = (props: Props) => {
  const classes = useStyles();
  return <MuiCardActions {...props} classes={{ root: classes.root }} />;
};

export default CardActions;
