import { Endpoints } from "../api/constants";
import {
  MarketplaceItemsResponse,
  BenefitMarketplaceItemsResponse,
} from "../types/MarketplaceItems";
import {
  convertToQueryParams,
  convertToFilterQueryParams,
} from "../utils/Filters";
import { mutateData } from "../api/api";
import {
  MarketplaceItemsParams,
  MarketplaceItemsFilterParams,
} from "../types/MarketplaceItems";
import { useMutation, useFilterValues } from "../utils/Api";
import {
  MarketplaceItemsWithSelectionResponse,
  AutoAssignmentFilterResponse,
} from "../types/MarketplaceItems";
import useSWR from "swr";

export const useLazyBenefitMarketplaceItems = (benefitId?: string) => {
  const fetchItems = async (params?: MarketplaceItemsParams) => {
    const query = convertToQueryParams(params);
    const { root, benefitItems } = Endpoints.clientAdmin.v2.marketplaceItems;
    const url = benefitId ? benefitItems(benefitId) : root;
    const response = await mutateData<BenefitMarketplaceItemsResponse>(
      "get",
      `${url}${query}`
    );
    return response.data;
  };
  return { fetchItems };
};

export const useLazyBenefitSelectedMarketplaceItems = (benefitId?: string) => {
  const fetchItems = async (params?: MarketplaceItemsParams) => {
    const query = convertToQueryParams(params);
    if (!benefitId) {
      throw new Error("Benefit id is missing");
    }
    const response = await mutateData<MarketplaceItemsResponse>(
      "get",
      `${
        Endpoints.clientAdmin.v2.benefitPlans.byId(benefitId).marketplaceItems
          .root
      }${query}`
    );
    return response.data;
  };
  return { fetchItems };
};

export const useBenefitMarketplaceItemActions = (
  benefitId: string,
  itemId: string
) => {
  const url = Endpoints.clientAdmin.benefitPlans
    .byId(benefitId)
    .marketplaceItems.byId(itemId).root;
  const [selectItem, selectItemData] = useMutation(url, "post");
  const [removeItem, removeItemData] = useMutation(url, "delete");

  return {
    selectItem,
    selectItemData,
    removeItem,
    removeItemData,
  };
};

type SupportedFilterNames =
  | "name"
  | "sku"
  | "currency"
  | "benefitPlanTypes"
  | "categories"
  | "supplier";

const getOptions = (lookupValue?: string) => {
  return { isPaused: !lookupValue || lookupValue.length < 3 };
};

export const useBenefitSelectedMarketplaceItemsFilterValues = (
  benefitId: string,
  filterName: SupportedFilterNames,
  lookupValue?: string
) => {
  const url =
    Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
      .filterValues;
  return useFilterValues(url, filterName, lookupValue, getOptions(lookupValue));
};

export const useMarketplaceItemsFilterValues = (
  filterName: SupportedFilterNames,
  lookupValue?: string
) => {
  const url = Endpoints.clientAdmin.marketplaceItems.filterValues;
  return useFilterValues(url, filterName, lookupValue, getOptions(lookupValue));
};

export const useBenefitBulkLink = (benefitId: string) => {
  const url =
    Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
      .bulkLink;
  const execute = async (params: MarketplaceItemsFilterParams) => {
    const query = convertToFilterQueryParams(params);
    const response = await mutateData("post", `${url}${query}`);
    return response.data;
  };
  return { execute };
};

export const useBenefitBulkUnlink = (benefitId: string) => {
  const url =
    Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
      .bulkUnlink;
  const execute = async (params: MarketplaceItemsFilterParams) => {
    const query = convertToFilterQueryParams(params);
    const response = await mutateData<boolean>("post", `${url}${query}`);
    return response.data;
  };
  return { execute };
};

export const useLazyMarketplaceItems = () => {
  const fetchItems = async (params?: MarketplaceItemsParams) => {
    const query = convertToQueryParams(params);
    const response = await mutateData<MarketplaceItemsWithSelectionResponse>(
      "get",
      `${Endpoints.clientAdmin.v2.marketplaceItems.root}${query}`
    );
    return response.data;
  };
  return { fetchItems };
};

export const useLazySelectedMarketplaceItems = () => {
  const fetchItems = async (params?: MarketplaceItemsParams) => {
    const query = convertToQueryParams(params);
    const response = await mutateData<MarketplaceItemsResponse>(
      "get",
      `${Endpoints.clientAdmin.v2.marketplaceItems.selection.root}${query}`
    );
    return response.data;
  };
  return { fetchItems };
};

export const useMarketplaceItemSelectionActions = (itemId: string) => {
  const url =
    Endpoints.clientAdmin.marketplaceItems.selection.marketplaceItem(itemId);
  const [selectItem, selectItemData] = useMutation(url, "post");
  const [removeItem, removeItemData] = useMutation(url, "delete");

  return {
    selectItem,
    selectItemData,
    removeItem,
    removeItemData,
  };
};

export const useSelectionsActions = () => {
  const bulkSelection = async (
    params: MarketplaceItemsFilterParams,
    method: "post" | "delete"
  ) => {
    const url = Endpoints.clientAdmin.marketplaceItems.selection.bulk;
    const query = convertToFilterQueryParams(params);
    const response = await mutateData(method, `${url}${query}`);
    return response.data;
  };

  const bulkSelectionAdd = async (params: MarketplaceItemsFilterParams) => {
    return bulkSelection(params, "post");
  };

  const bulkSelectionRemove = async (params: MarketplaceItemsFilterParams) => {
    return bulkSelection(params, "delete");
  };

  const linkSelections = async (benefitId: string) => {
    const response = await mutateData(
      "post",
      Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
        .bulkLinkSelection
    );
    return response.data;
  };
  return { bulkSelectionAdd, bulkSelectionRemove, linkSelections };
};

export const useAssignmentFilter = (benefitId: string) => {
  const url =
    Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
      .assignmentFilter.root;
  const assignmentFilter = useSWR<AutoAssignmentFilterResponse | null>(url, {
    onErrorRetry: (error, key, config, revalidate, { retryCount = 0 }) => {
      // Ignore the error when assignment filter was not found
      if (error.response.status === 404) return;

      // Retry otherwise (if configured)
      if (!config.errorRetryCount) return;
      if (retryCount >= config.errorRetryCount) return;
      setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
    },
  });
  const getOptions = (data?: AutoAssignmentFilterResponse | null) => ({
    onSuccess: () => assignmentFilter.mutate(data),
  });
  const [create, createData] = useMutation(url, "post", getOptions());
  const [update, updateData] = useMutation(url, "put", getOptions());
  const [remove, removeData] = useMutation(url, "delete", getOptions(null));
  return {
    data: assignmentFilter.data,
    loading: !assignmentFilter.data && !assignmentFilter.error,
    create,
    update,
    remove,
    actionInProgress:
      createData.loading || updateData.loading || removeData.loading,
  };
};
