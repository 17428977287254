import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthChallenges } from "../constants/Auth";
import {
  ChangePasswordRequest,
  LoginRequest,
  ResetPasswordRequest,
  SendResetEmailRequest,
  UseAuth,
} from "../types/Auth";
import { instance } from "../api/api";
import { usePublicRoutes } from "../constants/Routes";
import { cache } from "swr";
import { parse } from "querystring";

const initialAuthValues = {
  login: (data: LoginRequest) => {},
  logout: () => {},
  completeNewPassword: (password: string) => {},
  changePassword: (data: ChangePasswordRequest) => {
    return false as any;
  },
  isLoggedIn: false,
  user: null,
  isLoading: false,
  hasToken: false,
  token: "",
  confirmSignIn: (code: string) => {},
  sendForgotPasswordEmail: (data: SendResetEmailRequest) => {},
  resetPassword: (data: ResetPasswordRequest) => {},
  setAuthState: (newState: any) => {},
};

const authContext = createContext(initialAuthValues);

const useProvideAuth = (): UseAuth => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const publicRoutes = usePublicRoutes();
  const searchQuery = useMemo(
    () => parse(location?.search?.replace("?", "")),
    [location?.search]
  );

  const [state, setState] = useState({
    user: null,
    isLoggedIn: false,
    isLoading: false,
    token: "",
  });

  const auth = useMemo(() => Auth, []);

  const hasToken = useMemo(() => !!state.token, [state.token]);

  const handleLoading = useCallback(
    (loading: boolean) =>
      setState((prevState) => ({ ...prevState, isLoading: loading })),
    []
  );

  const handleAuthErrors = useCallback(
    (e: any) => {
      handleLoading(false);
      const searchQueryKeys = Object.keys(searchQuery);
      const isNotAuthorizedException = e.code === "NotAuthorizedException";
      if (
        searchQueryKeys?.includes("email") &&
        searchQueryKeys?.includes("password") &&
        isNotAuthorizedException
      ) {
        toast(t("errors.invite_expired"), { type: "error" });
      } else toast(e.message, { type: "error" });
      // if (!!e?.code) {
      //   toast(t(`errors.${e.code}`), { type: "error" });
      // }
    },
    [handleLoading, searchQuery, t]
  );

  const getUserToken = useCallback((user: CognitoUser): string => {
    if (!!user) {
      return user?.getSession((err: Error, res: any) => {
        if (!err) return res.accessToken.jwtToken;
        return "";
      }) as unknown as string;
    }

    return "";
  }, []);

  const confirmSignIn = useCallback(
    (code: string) =>
      auth
        .confirmSignIn(state.user, code)
        .then((user) =>
          setState((prevState) => ({ ...prevState, user, isLoggedIn: true }))
        )
        .catch((e) => {
          toast(t(`errors.${e.code}`), { type: "error" });
        }),
    [auth, state.user, t]
  );

  const login = useCallback(
    ({ email, password, redirectTo }: LoginRequest) => {
      handleLoading(true);
      cache.clear();
      auth
        .signIn(email.trim().toLowerCase(), password)
        .then((user) => {
          setState((prevState) => ({ ...prevState, user }));
          return user;
        })
        .then((user) => {
          if (user.challengeName === AuthChallenges.newPasswordRequired) {
            toast(t("errors.please_set_new_password"), { type: "warning" });
            history.push("/new-password");
          } else if (user.challengeName === AuthChallenges.smsMFA) {
            history.push({
              pathname: "/two-factor",
              state: { from: redirectTo },
            });
          } else {
            setState((prevState) => ({ ...prevState, isLoggedIn: true }));
          }
        })
        .finally(() => {
          handleLoading(false);
        })
        .catch(handleAuthErrors);
    },
    [auth, handleAuthErrors, handleLoading, history, t]
  );

  const logout = useCallback(() => {
    handleLoading(true);
    auth
      .signOut()
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          user: null,
          isLoggedIn: false,
          token: "",
        }));
        history.push("/login");
      })
      .finally(() => handleLoading(false))
      .catch(handleAuthErrors);
  }, [auth, handleAuthErrors, handleLoading, history]);

  const completeNewPassword = useCallback(
    (password: string) => {
      handleLoading(true);
      auth
        .completeNewPassword(state.user, password)
        .then((user) => {
          if (user.challengeName === AuthChallenges.smsMFA) {
            history.push("/two-factor");
          }
          setState((prevState) => ({ ...prevState, user }));
          handleLoading(false);
          toast(t("login.new_password_set"), { type: "success" });
        })
        .catch(handleAuthErrors);
    },
    [auth, handleAuthErrors, handleLoading, history, state.user, t]
  );

  const changePassword = useCallback(
    async ({ oldPassword, newPassword }: ChangePasswordRequest) => {
      handleLoading(true);
      const { user } = state;
      let sucess = false;
      await auth
        .changePassword(user, oldPassword, newPassword)
        .then(() => {
          sucess = true;
          toast(t("success.password_changed_succesfully"), { type: "success" });
        })
        .catch(handleAuthErrors)
        .finally(() => handleLoading(false));

      return sucess;
    },
    [auth, handleAuthErrors, handleLoading, state, t]
  );

  const sendForgotPasswordEmail = useCallback(
    ({ email }: SendResetEmailRequest) => {
      handleLoading(true);
      auth
        .forgotPassword(email.trim().toLowerCase())
        .then(() => {
          toast(t("success.reset_code_sent"), { type: "success" });
          history.push("/reset-password");
        })
        .catch(handleAuthErrors)
        .finally(() => handleLoading(false));
    },
    [auth, handleAuthErrors, handleLoading, history, t]
  );

  const resetPassword = useCallback(
    ({ code, email, newPassword, repeatPassword }: ResetPasswordRequest) => {
      if (repeatPassword !== newPassword) {
        toast(t("errors.passwords_do_not_match"), { type: "error" });
        return;
      }
      handleLoading(true);
      auth
        .forgotPasswordSubmit(email.trim().toLowerCase(), code, newPassword)
        .then(() => {
          toast(t("success.password_changed_succesfully"), { type: "success" });
          history.push("/login");
        })
        .catch(handleAuthErrors)
        .finally(() => handleLoading(false));
    },
    [auth, handleAuthErrors, handleLoading, history, t]
  );

  const refreshUser = useCallback(async () => {
    await auth
      .currentAuthenticatedUser()
      .then((user) => {
        const token = getUserToken(user);
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setState((prevState) => ({
          ...prevState,
          user,
          isLoggedIn: true,
          token,
        }));
        handleLoading(false);
      })
      .catch((e) => {
        const currentLocation = `${location.pathname}${location.search}`;
        if (!publicRoutes.includes(location.pathname))
          history.push({
            pathname: "/login",
            state: { from: currentLocation },
          });
        handleAuthErrors(e);
      });
  }, [
    auth,
    getUserToken,
    handleAuthErrors,
    handleLoading,
    history,
    location,
    publicRoutes,
  ]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const errorStatus = error?.response?.status;
      const originalRequest = error.config;
      const noAccess =
        (errorStatus === 401 || errorStatus === 403) && !originalRequest._retry;
      if (noAccess) {
        originalRequest._retry = true;
        await refreshUser();
        return instance(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return {
    ...state,
    setAuthState: (newState) =>
      setState((prevState) => ({ ...prevState, ...newState })),
    login,
    logout,
    completeNewPassword,
    hasToken,
    changePassword,
    confirmSignIn,
    sendForgotPasswordEmail,
    resetPassword,
  };
};

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = (): UseAuth => {
  return useContext(authContext);
};
