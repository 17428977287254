import React, { useCallback, useEffect, useMemo } from "react";
import {
  MpControlProps,
  controlRegisterOptions,
  useErrorMessages,
} from "@mp-react/form";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Controller } from "react-hook-form";
import { Box } from "@material-ui/core";
import { useBenefitStore } from "../../../state/Benefits";
import { useEmployeeStore } from "../../../state/Employees";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useTooltip from "../../../utils/Tooltip";
import moment from "moment";
import { browserLanguage, useDateFormat } from "../../../utils/Common";
import { CustomFormControl } from "../../../types/Common";

const inputLabelProps = { shrink: true };

function DateView({
  control,
  size,
  layout,
  variant,
  error,
  locale,
  defaultMessages,
  onChange,
  value,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const { keyboardDatePickerFormat } = useDateFormat();
  const controlKey = useMemo(() => control?.key, [control]);
  const disablePast = useMemo(
    () => !!(control as CustomFormControl)?.disablePast,
    [control]
  );
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);

  const { setStartDate, setEndDate, endDate, startDate } = useEmployeeStore(
    (state) => state
  );
  const {
    activationDate,
    setActivationDate,
    setDeactivationDate,
    setPublishDate,
    publishDate,
    deactivationDate,
    setVotingEndDate,
    setVotingStartDate,
    votingEndDate,
    votingStartDate,
  } = useBenefitStore();

  const handleChange = useCallback(
    (date: any) => {
      const dateString = moment(date).toISOString(true);
      onChange(dateString);
      switch (controlKey) {
        case "publishDate":
          if (moment(deactivationDate).diff(date) < 0)
            setDeactivationDate(dateString);
          setPublishDate(dateString, "date");
          break;
        case "activationDate":
          if (moment(deactivationDate).diff(date) < 0) {
            setDeactivationDate(dateString);
          }
          setActivationDate(dateString, "date");
          break;
        case "deactivationDate":
          setDeactivationDate(dateString, "date");
          break;
        case "startDate":
          if (moment(endDate).diff(date) < 0) setEndDate(dateString);
          setStartDate(dateString, "date");
          break;
        case "endDate":
          setEndDate(dateString, "date");
          break;
        case "votingStartDate":
          if (moment(votingEndDate).diff(dateString) < 0)
            setVotingEndDate(dateString);
          setVotingStartDate(dateString, "date");
          break;
        case "votingEndDate":
          setVotingEndDate(dateString, "date");
          break;
      }
    },
    [
      onChange,
      controlKey,
      deactivationDate,
      setDeactivationDate,
      setPublishDate,
      endDate,
      setEndDate,
      setStartDate,
      votingEndDate,
      setVotingEndDate,
      setVotingStartDate,
      setActivationDate,
    ]
  );

  const minDate = useMemo(() => {
    switch (controlKey) {
      case "deactivationDate":
        const latestMoment = moment.max(
          moment(publishDate ?? "1900-01-01"),
          moment(activationDate ?? "1900-01-01")
        );
        return latestMoment.locale(browserLanguage);
      case "votingEndDate":
        return moment(
          !!votingStartDate ? votingStartDate : "1900-01-01"
        ).locale(browserLanguage);
      case "endDate":
        return moment(!!startDate ? startDate : "1900-01-01").locale(
          browserLanguage
        );
      default:
        return moment("1900-01-01").locale(browserLanguage);
    }
  }, [activationDate, controlKey, publishDate, startDate, votingStartDate]);

  const dateValue = useMemo(() => {
    if (!value) return value;
    return moment.parseZone(value);
  }, [value]);

  useEffect(() => {
    moment.locale(browserLanguage);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={browserLanguage}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <DatePicker
          value={dateValue}
          onChange={handleChange}
          error={!!error}
          helperText={firstMessage}
          name={control.key}
          required={control.required}
          placeholder={control.placeholder}
          label={layout === "separated" ? "" : control.label}
          size={size}
          fullWidth={true}
          InputLabelProps={inputLabelProps}
          inputVariant={variant}
          variant="inline"
          autoOk={true}
          format={keyboardDatePickerFormat}
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
          minDate={minDate}
          disablePast={disablePast}
        />
        <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
      </Box>
    </MuiPickersUtilsProvider>
  );
}

export default function Date({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <DateView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
