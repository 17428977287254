import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "@melp-design/style";
import { getBorder } from "../../../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    hiddenColumn: {
      "& .MuiTableCell-body:last-child": {
        background: Colors.lightBackgroundGrey,
        width: 1,
      },
      "& .MuiTableCell-body:last-child, .MuiTableCell-footer:last-child": {
        borderLeft: getBorder(Colors.elementsGrey),
      },
    },
    disableTable: {
      "& .MuiTableCell-root": {
        pointerEvents: "none",
      },
    },
  })
);
