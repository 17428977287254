import { Grid, Typography, Box, Chip, Switch } from "@material-ui/core";
import useStyles from "./CurrentStatusContent.styles";
import React, { useMemo } from "react";
import { Control, Controller, FieldValues, ArrayField } from "react-hook-form";
// import { getChipInvestment } from "../../../utils/Common";
import { useStatusStyle } from "../../../utils/Style/Style";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  investmentAmount: number;
  field: Partial<ArrayField<Record<string, any>, "departments.id">>;
  name: string;
  control: Control<FieldValues>;
  handleStatusArrayChange: (props: any, e: any) => void;
}

export default function CurrentStatusContent({
  field,
  name,
  control,
  investmentAmount,
  handleStatusArrayChange,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { statusClassName } = useStatusStyle({ status: field.status });
  // getChipInvestment(investmentAmount)
  const chipContent = useMemo(() => t("common.changed"), [t]);

  return (
    <Grid container key={field.id} className={classes.dividerBottom}>
      <Grid item xs={2}>
        <Chip
          component={Box}
          label={field.status}
          className={`${classes.chip} ${statusClassName}`}
        />
      </Grid>
      <Grid item xs={6} style={{ maxWidth: 255 }}>
        <Typography variant="body2">
          for group{" "}
          <NavLink to={`/groups/view/${field.employeeGroupId}`}>
            {field.employeeGroup.name}
          </NavLink>
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
        <Controller
          name={`${name}.employeeGroupId`}
          control={control}
          defaultValue={field.employeeGroupId}
          render={(props) => (
            <input type="hidden" value={field.employeeGroupId} />
          )}
        />
        <Controller
          name={`${name}.included`}
          control={control}
          defaultValue={true}
          render={(props) => (
            <>
              <Typography variant="body2">
                {props.value ? "Included" : "Excluded"}
              </Typography>
              <Switch
                classes={{ root: classes.switchRoot }}
                color="primary"
                checked={props.value}
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  handleStatusArrayChange(props, e);
                }}
              />
              {!props.value && (
                <Chip label={chipContent} className={classes.groupsChip} />
              )}
            </>
          )}
        />
      </Grid>
    </Grid>
  );
}
