import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";

interface IProps {
  marginRight?: string;
  marginLeft?: string;
  listLength: number;
  loading: boolean;
  exportToExcel: () => void;
}

export default function DownloadExcelButton(props: IProps) {
  const { exportToExcel, loading, listLength, marginRight, marginLeft } = props;
  const { t } = useTranslation();
  const handleDownload = useCallback(() => {
    exportToExcel();
  }, [exportToExcel]);

  return loading ? (
    <Box px="20px" display="flex">
      <CircularProgress size={20} />
    </Box>
  ) : (
    <Tooltip title={t("dashboard.download_xls") || "XLS"}>
      {/* Wrapped in span to avoid warning regarding tooltip on disabled button */}
      <span>
        <IconButton
          onClick={handleDownload}
          style={{ marginRight, marginLeft }}
          disabled={!listLength}
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
