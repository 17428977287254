import { Button, Popover, Box, MenuItem, Typography } from "@material-ui/core";
import React, { useState, useRef, useCallback } from "react";
import useStyles from "./AddBenefits.styles";
import { useTranslation } from "react-i18next";
import AddBenefitDialog from "../../../components/dialogs/AddBenefitDialog/AddBenefitDialog";
import { ReactComponent as AddIcon } from "../../../assets/icons/add-icon.svg";
import { useBenefits } from "../../../state/Benefits";
import { useBenefitTypes } from "../../../constants/Benefits";
import { useMe } from "../../../state/Administrators";
import { useDialog } from "../../../utils/Dialog";
import { CreateBenefitRequest } from "../../../types/Benefits";

interface DropdownButtonProps {
  label: string;
}

export default function AddBenefits({ label }: DropdownButtonProps) {
  const classes = useStyles();
  const { canEditBenefits } = useMe();
  const { t } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);
  const creationDialog = useDialog();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const { createBenefit } = useBenefits();
  const options = useBenefitTypes();

  const handleToggle = useCallback(() => {
    setOpenPopover((prevOpen) => !prevOpen);
  }, [setOpenPopover]);

  const handleClose = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      setOpenPopover(false);
    },
    [anchorRef, setOpenPopover]
  );

  const handleOpenDialog = (item: string) => {
    setSelectedValue(item);
    creationDialog.openDialog();
  };

  const handleActionClick = (requestData: CreateBenefitRequest) => {
    createBenefit(requestData);
    creationDialog.closeDialog();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        endIcon={<AddIcon />}
        ref={anchorRef}
        aria-controls={openPopover ? "add-benefit" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={!canEditBenefits}
        className={classes.button}
      >
        {label}
      </Button>
      <Popover
        open={openPopover}
        anchorEl={anchorRef.current}
        PaperProps={{
          style: { width: 226 },
          className: classes.popoverPaper,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom" as "bottom",
          horizontal: "right" as "right",
        }}
        transformOrigin={{
          vertical: "top" as "top",
          horizontal: "right" as "right",
        }}
      >
        <Box>
          {options.map((item, i) => (
            <MenuItem
              className={classes.itemContainer}
              key={i}
              onClick={() => handleOpenDialog(item.value)}
            >
              <Typography variant="body2">{t(item.name)}</Typography>
            </MenuItem>
          ))}
        </Box>
      </Popover>
      <AddBenefitDialog
        onCancelClick={creationDialog.closeDialog}
        onActionClick={handleActionClick}
        id={`add_${selectedValue}`}
        open={creationDialog.open}
        title={t("buttons.new_benefit")}
        options={options}
        defaultValue={selectedValue}
      />
    </>
  );
}
