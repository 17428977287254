import { Box, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  booleanDecimalPointToNumber,
  useErrorMessages,
  MpControlProps,
  controlRegisterOptions,
  NumberInput,
} from "@mp-react/form";
import { useDebouncedCallback } from "use-debounce";
import { useCurrency } from "../../../utils/useCurrency";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";

const inputLabelProps = { shrink: true };

export function NumberView({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  value,
  onChange,
}: MpControlProps & {
  value: any;
  onChange: (...event: any[]) => void;
}) {
  const max = useMemo(
    () => control?.validations?.max,
    [control?.validations?.max]
  );
  const canBeNegative = useMemo(() => !!control?.negative, [control?.negative]);
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const { getDefaultCurrencyNumber } = useCurrency();
  const inputProps = useMemo(
    () => ({
      decimalPoint: booleanDecimalPointToNumber(control.decimalPoint),
      negative: control.negative,
    }),
    [control]
  );
  const { endAdornment, startAdornment } = control;
  const InputProps = useMemo(
    () => ({
      inputComponent: NumberInput,
      endAdornment,
      startAdornment,
    }),
    [endAdornment, startAdornment]
  );
  const [inputValue, setInputValue] = React.useState<number | null>(
    Math.ceil(value) ?? ""
  );
  const debounced = useDebouncedCallback((val) => {
    onChange(val);
  }, 300);

  useEffect(() => {
    if (value !== null && value !== undefined && value !== inputValue)
      setInputValue(value);
    // eslint-disable-next-line
  }, [value]);

  const formatInputValue = useMemo(() => {
    if (inputValue === 0) return 0;
    if (inputValue === null) return "";
    return getDefaultCurrencyNumber(inputValue);
  }, [getDefaultCurrencyNumber, inputValue]);

  const handleChange = useCallback(
    (e) => {
      if (canBeNegative && e.target.value === "-") {
        setInputValue(e.target.value);
        debounced(e.target.value);
      } else if (
        e.target.value === "" ||
        (e.target.value === "-" && !canBeNegative)
      ) {
        setInputValue(null);
        debounced(null);
      } else if (!!max && e.target.value > max) {
        setInputValue(max);
        debounced(max);
      } else {
        setInputValue(parseInt(e.target.value));
        debounced(parseInt(e.target.value));
      }
    },
    [canBeNegative, debounced, max]
  );

  return (
    <Box position="relative">
      <TextField
        error={!!error}
        helperText={firstMessage}
        name={control.key}
        required={control.required}
        placeholder={control.placeholder}
        label={layout === "separated" ? "" : control.label}
        size={size}
        fullWidth={true}
        InputLabelProps={inputLabelProps}
        value={formatInputValue}
        onChange={handleChange}
        inputProps={inputProps}
        InputProps={InputProps as any}
        variant={variant}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
      />
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function Number(props: MpControlProps) {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <NumberView {...props} onChange={onChange} value={value} />
      )}
    />
  );
}
