import { useLoading } from "../utils/Loading";
import {
  OrdersResponse,
  OrderUpdateRequestBody,
  OrderResponse,
  OrderStatus,
} from "../types/Orders";
import { mutateData } from "../api/api";
import { Endpoints } from "../api/constants";
import useSWR from "swr";
import { useFilterValues } from "../utils/Table";
import { useCallback } from "react";
import { useMutation, useXLSExport } from "../utils/Api";
import { OrderUpdateResponse } from "../types/Orders";

export const useOrders = (query?: string) => {
  const queryParams = !!query ? `?${query}` : "";
  const url = `${Endpoints.melpAdmin.marketplaceOrders.root}${queryParams}`;
  const { data, error, mutate } = useSWR<OrdersResponse>(url);

  const [bulkUpdate, bulkUpdateData] = useMutation<{
    ids: string[];
    status: OrderStatus;
  }>(Endpoints.melpAdmin.marketplaceOrders.bulkUpdate, "patch", {
    onSuccess: () => mutate(),
  });

  return {
    orders: data?.data,
    pageSize: data?.pageSize,
    count: data?.count,
    error,
    bulkUpdate,
    bulkUpdateData,
  };
};

export const useOrdersXLSExport = (query?: string) => {
  return useXLSExport(
    `${Endpoints.melpAdmin.marketplaceOrders.exportToExcel}${query}`
  );
};

export const useOrder = (id: string) => {
  const url = Endpoints.melpAdmin.marketplaceOrders.order(id);
  const { data, error, mutate } = useSWR<OrderResponse>(url);

  const updateState = useLoading();
  const update = async (requestData: OrderUpdateRequestBody) => {
    updateState.startLoading();
    try {
      const response = await mutateData<OrderUpdateResponse>(
        "patch",
        url,
        requestData
      );
      if (data) {
        mutate({ ...data, ...response.data });
      }
      return response.data;
    } finally {
      updateState.stopLoading();
    }
  };

  return {
    order: data,
    loading: !data && !error,
    update,
    updating: updateState.loading,
  };
};

export const useOrdersAsyncMethods = () => {
  const baseUrl = Endpoints.melpAdmin.marketplaceOrders.filterValues;
  const { getAsyncFilterItems } = useFilterValues(baseUrl);

  const getOrderNrs = useCallback(
    (args) => getAsyncFilterItems(args, "orderNr"),
    [getAsyncFilterItems]
  );

  const getClientNames = useCallback(
    (args) => getAsyncFilterItems(args, "parentCompanyName"),
    [getAsyncFilterItems]
  );

  const getCompanyNames = useCallback(
    (args) => getAsyncFilterItems(args, "companyName"),
    [getAsyncFilterItems]
  );

  const getItemSKUs = useCallback(
    (args) => getAsyncFilterItems(args, "itemSku"),
    [getAsyncFilterItems]
  );

  const getItemNames = useCallback(
    (args) => getAsyncFilterItems(args, "itemName"),
    [getAsyncFilterItems]
  );

  return {
    getOrderNrs,
    getClientNames,
    getCompanyNames,
    getItemSKUs,
    getItemNames,
  };
};
