import { Typography } from "@material-ui/core";
import { useRendererOptions } from "@mp-react/table";
import { useMemo } from "react";
import { CellProps } from "react-table";
import { useCurrency } from "../../../utils/useCurrency";

export default function Currency(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { getDefaultCurrencyFormat } = useCurrency();

  const currencyValue = useMemo(() => {
    const parsedValue = Math.round(value);
    return !!parsedValue ? getDefaultCurrencyFormat(parsedValue) : "-";
  }, [getDefaultCurrencyFormat, value]);

  return (
    <Typography variant="body2" align="right">
      {currencyValue}
    </Typography>
  );
}
