import {
  CheckAssignBaseResponse,
  Investment,
  InvestmentTypes,
} from "./../types/Common";
import { useInvestmentCalculationsStore } from "./InvestmentCalculations";
import { useLoading } from "./../utils/Loading";
import { mutateData } from "./../api/api";
import {
  BenefitsAssignGroupsTable,
  BenefitsAssignGroupsTableItem,
  BenefitStatus,
  ToggleAssignmentStatus,
} from "./../types/Benefits";
import { useCallback, useMemo, useState } from "react";
import {
  AssignToEmployeeGroupRequest,
  UseBenefitsEmployeeGroups,
} from "../types/Benefits";
import { MpRowActionMethods, MpRowActionParameters } from "@mp-react/table";
import { useTranslation } from "react-i18next";
import { Endpoints } from "../api/constants";
import useSWR, { mutate } from "swr";
import moment from "moment";
import { toast } from "react-toastify";
import { useDialog } from "../utils/Dialog";
import { useBenefits } from "./Benefits";
import { useCurrency } from "../utils/useCurrency";
import { MpAsyncGetMethod } from "@mp-react/table";
import { BenefitAssignGroupsFilterNames } from "../constants/Benefits";
import { AxiosResponse } from "axios";
import useTableUtils from "../utils/Table";
import { getFetchUrlWithId } from "./../utils/FetchUrl";

export const useBenefitsEmployeeGroups = (
  benefitPlanId?: string,
  query?: string
): UseBenefitsEmployeeGroups => {
  const { t } = useTranslation();
  const { increaseChangeCount, resetAllChanges, statuses, resetStatusChanges } =
    useInvestmentCalculationsStore();
  const setInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.setInvestments, [])
  );

  const { isRowInvestmentAdded } = useTableUtils();
  const [selectedRowData, setSelectedRowData] = useState<
    BenefitsAssignGroupsTableItem | undefined
  >(undefined);
  const { startLoading, stopLoading, loading } = useLoading();
  const {
    closeDialog: closeFlexForm,
    open: isFlexFormOpen,
    openDialog: openFlexForm,
  } = useDialog();
  const { hasFlexSettings } = useBenefits(benefitPlanId);
  const { getDefaultCurrencyFormat } = useCurrency();

  const [changeResponse, setChangeResponse] =
    useState<CheckAssignBaseResponse>();

  const url = useMemo(() => {
    const endpoint = `${Endpoints.benefitPlans}/${benefitPlanId}/employeeGroupAssignments`;
    return getFetchUrlWithId(endpoint, query, benefitPlanId);
  }, [benefitPlanId, query]);

  const {
    data: benefitAssignGroups,
    error: benefitAssignGroupsError,
    mutate: mutateBenefitAssignGroups,
  } = useSWR<BenefitsAssignGroupsTable, any>(url);

  const apiLoading = useMemo(
    () => !benefitAssignGroups && !benefitAssignGroupsError,
    [benefitAssignGroups, benefitAssignGroupsError]
  );

  const benefitAssignGroupsData: BenefitsAssignGroupsTableItem[] = useMemo(
    () =>
      benefitAssignGroups?.data?.map((row) => ({
        ...row,
        inactive: row.currentStatus === "off",
      })) ?? [],
    [benefitAssignGroups]
  );

  const parsedBenefitAssignGroupsTotals = useMemo(() => {
    const initialTotals = {
      name: "",
      investment: "",
      currentStatus: "",
      status: "",
    };
    if (!benefitAssignGroups?.footer) return initialTotals;
    const { name, investment, currentStatus } = benefitAssignGroups.footer;
    return {
      name: t("totals.group", { count: Number(name) }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
      currentStatus: `${currentStatus} ${t("status.off")}`,
      status: "",
    };
  }, [benefitAssignGroups, t, getDefaultCurrencyFormat]);

  const mapRequestData = useCallback(
    (date?: string): AssignToEmployeeGroupRequest[] => {
      const statusEntries: [string, ToggleAssignmentStatus][] = Object.entries(
        statuses?.currentStatus ?? {}
      );
      const fromDate = date ?? moment().toISOString();
      return statusEntries.map(([employeeGroupId, statusObject]) => {
        const statusEntryObj: AssignToEmployeeGroupRequest = {
          benefitPlanId: benefitPlanId as string,
          employeeGroupId,
          fromDate,
          toDelete: false,
          status: statusObject?.value ?? "on",
        };
        const isToday = moment(fromDate).isSame(moment(), "day");
        if (!isToday) {
          const dateStatus = statusObject?.currentRowStatuses?.find((status) =>
            moment(status.fromDate).isSame(moment(fromDate), "day")
          );
          if (!!dateStatus) statusEntryObj.id = dateStatus?.id;
        }
        if (isToday && !!statusObject.assignmentId)
          statusEntryObj.id = statusObject.assignmentId;
        return statusEntryObj;
      });
    },
    [benefitPlanId, statuses]
  );

  const assignEmployeeGroups = useCallback(
    async (date?: string, data?: AssignToEmployeeGroupRequest[]) => {
      startLoading();
      const requestData = data ?? mapRequestData(date);
      await mutateData("post", Endpoints.assignEmployeeGroups, requestData)
        .then(async () => {
          if (!!url) {
            await mutate(url);
          } else {
            await mutateBenefitAssignGroups();
          }
          resetAllChanges();
          resetStatusChanges();
          toast(t("common.assigned_succesfully"), { type: "success" });
        })
        .then(
          async () =>
            !!benefitPlanId &&
            (await mutate(`${Endpoints.benefitPlans}/${benefitPlanId}`))
        )
        .finally(() => stopLoading());
    },
    [
      startLoading,
      mapRequestData,
      url,
      resetAllChanges,
      resetStatusChanges,
      t,
      mutateBenefitAssignGroups,
      benefitPlanId,
      stopLoading,
    ]
  );

  const getSelectedRowData = useCallback(
    (id: string) => {
      const data =
        benefitAssignGroupsData?.find((item) => item.id === id) ??
        ({} as BenefitsAssignGroupsTableItem);
      return data;
    },
    [benefitAssignGroupsData]
  );

  const checkBenefitToGroupChanges = useCallback(
    async ({
      newStatus,
      employeeGroupId,
      benefitPlanId,
    }: {
      newStatus: "on" | "off" | "flex";
      employeeGroupId: string;
      benefitPlanId: string;
    }): Promise<CheckAssignBaseResponse> => {
      return mutateData(
        "post",
        Endpoints.checkBenefitPlanToEmployeeGroupChanges,
        {
          benefitPlanId,
          employeeGroupId,
          newStatus,
        }
      ).then((res) => res.data as CheckAssignBaseResponse);
    },
    []
  );

  /**** ROW METHODS ***/
  const toggleStatus = useCallback(
    async (current: MpRowActionParameters) => {
      const { value: newStatus, rowId: employeeGroupId } = current;
      const selectedRowData = getSelectedRowData(employeeGroupId as string);
      const initialStatus = selectedRowData?.currentStatus as BenefitStatus;
      if (initialStatus === newStatus || !newStatus || !employeeGroupId) return;

      setSelectedRowData(selectedRowData);

      const assignmentData = await checkBenefitToGroupChanges({
        benefitPlanId: benefitPlanId as string,
        newStatus: newStatus as "flex" | "on" | "off",
        employeeGroupId: employeeGroupId as string,
      });
      setChangeResponse(assignmentData);

      if (newStatus === "flex" && !hasFlexSettings) {
        openFlexForm();
        return;
      }

      const investmentData: CheckAssignBaseResponse & Investment = {
        id: employeeGroupId as string,
        totalInvestmentAmount: assignmentData.totalInvestmentAmount,
        investmentAmountChange: assignmentData.investmentAmountChange,
        added: isRowInvestmentAdded(initialStatus, newStatus as BenefitStatus),
        investment: assignmentData.investmentAmountChange,
        name: selectedRowData.name,
        type: InvestmentTypes.groups,
        employeeCount: selectedRowData.employeeCount,
      };
      setInvestments(investmentData);
      increaseChangeCount(employeeGroupId);
    },
    [
      benefitPlanId,
      checkBenefitToGroupChanges,
      getSelectedRowData,
      hasFlexSettings,
      increaseChangeCount,
      isRowInvestmentAdded,
      openFlexForm,
      setInvestments,
    ]
  );

  const rowMethods: MpRowActionMethods = {
    toggleStatus,
  };

  return {
    benefitAssignGroups,
    benefitAssignGroupsData,
    assignEmployeeGroups,
    rowMethods,
    loading: (benefitPlanId && apiLoading) || loading,
    parsedBenefitAssignGroupsTotals,
    closeFlexForm,
    isFlexFormOpen,
    selectedRowData,
    changeResponse,
    checkBenefitToGroupChanges,
  };
};

export const useBenefitAssignGroupsAsyncMethods = (
  benefitPlanId?: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () =>
      !!benefitPlanId
        ? `${Endpoints.benefitPlans}/${benefitPlanId}/employeeGroupAssignments/filterValues`
        : "",
    [benefitPlanId]
  );

  const getFilterItems = useCallback(
    (filterName: BenefitAssignGroupsFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getEmployeeGroupName = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitAssignGroupsFilterNames.EMPLOYEE_GROUP_NAME),
    [getFilterItems]
  );

  return {
    getEmployeeGroupName,
  };
};
