import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceByElement } from "../../../../../utils/Logs";
import { Log } from "../../../../../types/Logs";

export default function FailedToSendEmail(props: Partial<Log>) {
  const { employeeId, employee } = props;
  const { t } = useTranslation();

  const employeeName = useMemo(
    () => employee ?? employeeId ?? "",
    [employee, employeeId]
  );

  return useMemo(
    () =>
      replaceByElement(
        t("log.failed_email_employee_missing_email"),
        "{employee}",
        employeeName
      ),
    [employeeName, t]
  );
}
