import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateBenefitExpense } from "../../state/BenefitExpenses";
import { BenefitExpenseFormData } from "../../types/BenefitExpenses";
import ExpenseDialog from "./ExpenseDialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onComplete?: () => void;
  employeeIds: string[];
}

const AddExpenseDialog = ({
  employeeIds,
  open,
  onCancel,
  onComplete,
}: Props) => {
  const { t } = useTranslation();
  const { create, creating } = useCreateBenefitExpense();

  const handleSubmit = async (data: BenefitExpenseFormData) => {
    await create({
      ...data,
      employeeIds,
    });
    onComplete?.();
    toast.success(t("common.added_succesfully"));
  };

  return (
    <ExpenseDialog
      employeeIds={employeeIds}
      open={open}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      loading={creating}
      title={t("benefitExpenses.newExpense")}
    />
  );
};

export default AddExpenseDialog;
