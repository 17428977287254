import { Box, BoxProps, Typography } from "@material-ui/core";
import React from "react";

export default function InnerWithTabsTitle({ children, ...rest }: BoxProps) {
  return (
    <Box paddingLeft="30px" marginBottom="11px" {...rest}>
      <Typography variant="subtitle1">{children}</Typography>
    </Box>
  );
}
