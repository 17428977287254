import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: 30,
    },
    dialogContentRoot: {
      padding: 0,
      marginTop: 30,
      overflow: "visible",
      "& .MuiInputLabel-asterisk": {
        whiteSpace: "nowrap",
      },
    },
    dialogActionsRoot: {
      padding: 0,
      marginTop: 40,
      "&.MuiDialogActions-spacing > :not(:first-child)": {
        marginLeft: 20,
      },
    },
    additionalError: {
      marginTop: 15,
    },
    loader: {
      position: "absolute",
    },
    inputsDisable: {
      "& .MuiGrid-container": {
        "& > .MuiGrid-item.MuiGrid-grid-xs-12": {
          "& .MuiFormControl-root .MuiInputBase-root": {
            background: "rgba(244, 248, 252, 1)",
            pointerEvents: "none",
            "& input": {
              color: "rgba(105, 113, 125, 1)",
            },
          },
        },
      },
    },
  })
);
