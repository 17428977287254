import { Box, Typography, Chip, IconButton, Tooltip } from "@material-ui/core";
import React, { useMemo } from "react";
import FooterTooltipContent from "../FooterTooltipContent/FooterTooltipContent";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import useStyles from "./InvestmentTooltip.styles";
import { useTranslation } from "react-i18next";
import { useInvestmentCalculationsStore } from "../../../state/InvestmentCalculations";
import { useCurrency } from "../../../utils/useCurrency";

export default function InvestmentTooltip() {
  const classes = useStyles();
  const { t } = useTranslation();
  const totalInvestmentChange = useInvestmentCalculationsStore(
    (state) => state.totalInvestmentChange
  );
  const totalInvestment = useInvestmentCalculationsStore(
    (state) => state.totalInvestment
  );
  const { getDefaultCurrencyFormat } = useCurrency();

  const formattedTotalInvestment = useMemo(
    () => getDefaultCurrencyFormat(totalInvestment),
    [getDefaultCurrencyFormat, totalInvestment]
  );

  const formattedTotalInvestmentChange = useMemo(() => {
    const symbol = totalInvestmentChange < 0 ? "-" : "+";
    const investmentAbs = Math.abs(totalInvestmentChange);
    const parsedInvestmentAbs =
      investmentAbs % 1 === 0 ? investmentAbs : investmentAbs.toFixed(2);
    const formattedCurrency = getDefaultCurrencyFormat(parsedInvestmentAbs);
    return `${symbol} ${formattedCurrency}`;
  }, [getDefaultCurrencyFormat, totalInvestmentChange]);

  return (
    <Box display="flex" alignItems="center">
      <Typography component={Box} paddingRight={1}>
        {t("common.current_investment")}:
      </Typography>
      <Typography component={Box} paddingRight={2} variant="subtitle1">
        {formattedTotalInvestment}
      </Typography>
      <Chip
        component={Box}
        label={formattedTotalInvestmentChange}
        className={classes.chip}
      />
      <Tooltip
        title={<FooterTooltipContent />}
        classes={{ tooltip: classes.infoTooltip }}
      >
        <IconButton className={classes.infoButton}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
