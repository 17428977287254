import { Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Log } from "../../../../../types/Logs";

export default function BenefitAssignedToGroup(props: Partial<Log>) {
  const { doneByName, employeeGroup, benefitPlan, benefitAssignmentStatus } =
    props;
  const { t } = useTranslation();

  const splitText = useMemo(
    () => t("log.assigned_benefit_to_group").split(" "),
    [t]
  );
  const benefitStatus = useMemo(
    () =>
      !!benefitAssignmentStatus
        ? `"${benefitAssignmentStatus?.toUpperCase()}"`
        : "",
    [benefitAssignmentStatus]
  );

  return useMemo(() => {
    return (
      <>
        {splitText?.map((text) => {
          switch (text) {
            case "{employee}":
              return (
                <Typography
                  variant="subtitle2"
                  key={`logText-${Math.random() * 1000}`}
                >
                  {doneByName}{" "}
                </Typography>
              );
            case "{benefitName}":
              return (
                <Typography
                  variant="subtitle2"
                  key={`logText-${Math.random() * 1000}`}
                >
                  {`${benefitPlan} ${benefitStatus} `}
                </Typography>
              );
            case "{group}":
              return (
                <Typography
                  variant="subtitle2"
                  key={`logText-${Math.random() * 1000}`}
                >
                  {employeeGroup}{" "}
                </Typography>
              );
            default:
              return `${text} `;
          }
        })}
      </>
    );
  }, [benefitPlan, benefitStatus, doneByName, employeeGroup, splitText]);
}
