import { Box } from "@material-ui/core";
import { MpTableHeadIndicatorProps } from "@mp-react/table";
import useStyles from "./HeadIndicator.styles";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { useCallback } from "react";
import { Clear } from "@material-ui/icons";

export default function HeadIndicator({
  canSort,
  isExtended,
  filterSet,
  hasClear,
  isHoveredCell,
  clearFilters,
}: MpTableHeadIndicatorProps) {
  const classes = useStyles();

  const handleClearClick = useCallback(
    (e: React.MouseEvent<SVGElement>) => clearFilters && clearFilters(e),
    [clearFilters]
  );

  return !!canSort || !!isExtended ? (
    <Box className={classes.iconWrapper}>
      {filterSet ? (
        <>
          <FilterIcon className={classes.filterIcon} />
          {hasClear && isHoveredCell && (
            <Clear className={classes.clearIcon} onClick={handleClearClick} />
          )}
        </>
      ) : (
        <ChevronDown className={classes.chevronDown} />
      )}
    </Box>
  ) : null;
}
