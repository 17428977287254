import {
  NewsDetailsResponse,
  NewsTranslations,
  UpdateNews,
  UpdateNewsTranslations,
} from "../types/News";
import { Status } from "../types/Common";
import moment from "moment";
import { StatusAction } from "../types/Status";

export const parseFormData = (
  newsDetails: NewsDetailsResponse,
  formData: any
): UpdateNews => {
  const values = { ...newsDetails } as UpdateNews;

  const sendMethodsKeys = Object.keys(formData.sendMethods);
  values.sendMethods = sendMethodsKeys?.filter(
    (key) => formData.sendMethods[key]
  );

  const languages = Object.keys(formData.translations);
  const translationValues = Object.values(formData.translations);

  languages.forEach((lang, i) => {
    const currentLang = formData.translations[lang] as UpdateNewsTranslations;
    currentLang.language = languages[i];
  });
  values.translations = (translationValues as UpdateNewsTranslations[])?.filter(
    (item) => item.title
  );
  return values;
};

export const parseResponseData = (newsDetails: NewsDetailsResponse): any => {
  const values = { ...newsDetails } as any;

  if (newsDetails) {
    values.sendMethods = {};
    newsDetails?.sendMethods?.forEach((method) => {
      values.sendMethods[method] = true;
    });

    //translations
    values.translations = {};
    (newsDetails?.translations as NewsTranslations[])?.forEach(
      (item, index) => {
        const language = item.language;
        values.translations[language] = item;
      }
    );
  }
  return values;
};

export const parseStatusDates = (newsDetails: NewsDetailsResponse): Status => {
  if (!newsDetails) return { name: "active" };

  const { publishDate, inactiveDate, draft } = newsDetails;

  if (draft) return { name: "draft" };

  const isInPast = (date: string) => {
    return moment(date).isBefore();
  };

  const getPublishDateStatus = () => {
    if (isInPast(publishDate)) {
      return {
        name: "active" as StatusAction,
      };
    }

    return {
      name: "will_be_active_on" as StatusAction,
      date: publishDate,
    };
  };

  const getDeactivationDateStatus = () => {
    if (!!inactiveDate && isInPast(inactiveDate)) {
      return {
        name: "inactive" as StatusAction,
      };
    }

    return {
      name: "will_be_inactive_on" as StatusAction,
      date: !!inactiveDate ? inactiveDate : undefined,
    };
  };

  if (!!publishDate && !!inactiveDate) {
    const publishDateBeforeInactiveDate =
      moment(publishDate).isBefore(inactiveDate);
    const passedInactiveDate = isInPast(inactiveDate);
    const passedPublishDate = isInPast(publishDate);

    if (!passedInactiveDate && !passedPublishDate) {
      return {
        name: "will_be_active_on" as StatusAction,
        date: publishDate,
      };
    }

    if (
      publishDateBeforeInactiveDate &&
      passedInactiveDate &&
      passedPublishDate
    ) {
      return {
        name: "inactive" as StatusAction,
      };
    }

    if (
      publishDateBeforeInactiveDate &&
      passedPublishDate &&
      !passedInactiveDate
    ) {
      return {
        name: "will_be_inactive_on" as StatusAction,
        date: inactiveDate,
      };
    }

    if (
      !publishDateBeforeInactiveDate &&
      passedPublishDate &&
      passedInactiveDate
    ) {
      return {
        name: "active" as StatusAction,
      };
    }

    if (
      !publishDateBeforeInactiveDate &&
      !passedPublishDate &&
      passedInactiveDate
    ) {
      return {
        name: "will_be_active_on" as StatusAction,
        date: publishDate,
      };
    }
  }

  if (!!publishDate) {
    const status = getPublishDateStatus();
    return status;
  }

  if (!!inactiveDate) {
    const inactiveStatus = getDeactivationDateStatus();
    return inactiveStatus;
  }

  return {
    name: "inactive",
  };
};
