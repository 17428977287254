import React from "react";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import useStyles from "./AddFormButton.styles";

interface IProps {
  label: string;
  onClick: () => void;
}
export default function AddFormButton(props: IProps) {
  const { onClick, label } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} />
      <Grid item xs={12} sm={8}>
        <Box paddingTop={1}>
          <Button color="primary" onClick={onClick} className={classes.button}>
            <Typography variant="body2">+ {label}</Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
