import React from "react";
import { Box } from "@material-ui/core";
import useTableUtils from "../../../../utils/Table";
import DownloadExcelButton from "../../../table/DownloadExcelButton/DownloadExcelButton";
import { useOrdersXLSExport, useOrders } from "../../../../state/Orders";

interface Props {
  selectedRowIds: string[];
}

export default function BenefitsHeader({ selectedRowIds }: Props) {
  const { tableQueryParam } = useTableUtils("marketplaceOrders");

  const { count } = useOrders(tableQueryParam);

  const getXLSExportQuery = () => {
    if (!tableQueryParam && !selectedRowIds.length) {
      return "";
    }

    const selectedOrdersQuery = selectedRowIds
      .map((selectedRowId) => `filter[orderId]=${selectedRowId}`)
      .join("&");

    if (!tableQueryParam) {
      return `?${selectedOrdersQuery}`;
    }

    return `?${tableQueryParam}${
      selectedOrdersQuery ? `&${selectedOrdersQuery}` : ""
    }`;
  };

  const { exportToXLS, exporting } = useOrdersXLSExport(getXLSExportQuery());

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <DownloadExcelButton
        listLength={count ?? 0}
        loading={exporting}
        exportToExcel={exportToXLS}
      />
    </Box>
  );
}
