import { ComponentType, useCallback, useMemo } from "react";
import { MpControlProps, MpFormOverridablesMap } from "@mp-react/form";
import Date from "../components/form/Date/Date";
import TypeDate from "../components/form/TypeDate/TypeDate";
import Time from "../components/form/Time/Time";
import Dropdown from "../components/form/Dropdown/Dropdown";
import ImageUpload from "../components/form/ImageUpload/ImageUpload";
import Attachment from "../components/form/Attachment/Attachment";
import ToggleGroup from "../components/form/ToggleGroup/ToggleGroup";
import Checkbox from "../components/form/Checkbox/Checkbox";
import Switch from "../components/form/Switch/Switch";
import Number from "../components/form/Number/Number";
import Phone from "../components/form/Phone/Phone";
import NumberWithComma from "../components/form/NumberWithComma/NumberWithComma";
import Text from "../components/form/Text/Text";
import Multiline from "../components/form/Multiline/Multiline";
import Password from "../components/form/Passsword/Password";
import CompanyAndDepartmentDropdown from "../components/form/CompanyAndDepartment/CompanyAndDepartmentDropdown";
import CustomDropdown from "../components/form/CustomDropdown/CustomDropdown";
import EmployeeCategories from "../components/form/EmployeeCategories/EmployeeCategories";
import { UseFormUtils, CustomControlType } from "../types/Form";
import MergedGroup from "../components/form/MergedGroup/MergedGroup";
import Group from "../components/form/Group/Group";
import MultiAutocompleteControl from "../components/form/MultiAutocomplete/MultiAutocomplete";
import SelectControl from "../components/form/Select/Select";
import CurrencyControl from "../components/form/Currency/Currency";
import RichTextEditorControl from "../components/form/RichTextEditor/RichTextEditorControl";
import MultiNumberControl from "../components/form/MultiNumber/MultiNumber";

export const customControlMap: Record<
  keyof typeof CustomControlType,
  ComponentType<MpControlProps>
> = {
  date: Date,
  time: Time,
  dropdown: Dropdown,
  image: ImageUpload,
  attachment: Attachment,
  toggleGroup: ToggleGroup,
  checkbox: Checkbox,
  switch: Switch,
  number: Number,
  phone: Phone,
  text: Text,
  multiline: Multiline,
  password: Password,
  mergedGroup: MergedGroup,
  companyAndDepartmentDropdown: CompanyAndDepartmentDropdown,
  customDropdown: CustomDropdown,
  employeeCategoriesDropdown: EmployeeCategories,
  numberWithComma: NumberWithComma,
  group: Group,
  typeDate: TypeDate,
  multiAutocomplete: MultiAutocompleteControl,
  select: SelectControl,
  currency: CurrencyControl,
  richText: RichTextEditorControl,
  multiNumber: MultiNumberControl,
};

export const useFormUtils = (): UseFormUtils => {
  const getFormFieldsName = useCallback((name: string, language?: string) => {
    switch (name) {
      case "faq":
        return `translations.${language}.faq`;
      case "links":
        return `translations.${language}.links`;
      case "categories":
        return "categories";
      case "translationCategories":
        return `translations.${language}.categories`;
      default:
        return "";
    }
  }, []);

  const getFormKeys = useCallback((name: string) => {
    switch (name) {
      case "faq":
        return ["question", "answer"];
      case "links":
        return ["name", "url"];
      case "categories":
        return ["name", "limitAmount", "compensationPercent"];
      case "translationCategories":
        return ["name", "limitAmount", "compensationPercent"];
      default:
        return [];
    }
  }, []);

  const overridables = useMemo(() => {
    const obj: Partial<MpFormOverridablesMap> = {
      controls: customControlMap,
    };
    return obj;
  }, []);

  return {
    overridables,
    getFormFieldsName,
    getFormKeys,
  };
};

export const useFormValidation = () => {
  const passwordPattern = useMemo(
    () =>
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&\\*\\-\\.\\,\\[\\]\\{\\}\\(\\)\\\"\\/\\\\\\<\\>\\'\\:\\;\\|\\_\\~\\`]).{6,}$",
    []
  );

  return {
    passwordPattern,
  };
};
