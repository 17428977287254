import { Box, CircularProgress } from "@material-ui/core";
import { MpTableBreadcrumb } from "@mp-react/table";
import React, { useMemo } from "react";
import useStyles from "./TableBreadcrumbs.styles";
import { MpTableGlobalFilterProps } from "@mp-react/table/build/types/Overridables";
import { MpBreadcrumbFilterData } from "@mp-react/table/build/types/Filter";
import { objectLength } from "../../../utils/Common";

interface IProps extends MpTableGlobalFilterProps {
  loading: boolean;
  data: MpBreadcrumbFilterData;
}
export default function TableBreadcrumbs(props: IProps) {
  const { loading, onGlobalFilter, globalFilters, translations, data } = props;
  const classes = useStyles();
  const parsedData = useMemo(
    () => (!!objectLength(data) ? data : { no_items: [] }),
    [data]
  );

  if (loading) return <CircularProgress size={20} />;

  return (
    <Box className={classes.breadcrumbs}>
      <MpTableBreadcrumb
        onGlobalFilter={onGlobalFilter}
        translations={translations}
        globalFilters={globalFilters}
        data={parsedData}
      />
    </Box>
  );
}
