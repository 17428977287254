import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: "32px 45px 40px 45px",
      textAlign: "center",
    },
    dialogContentRoot: {
      padding: "0px 62px",
      marginTop: 15,
      "& .MuiDialogActions-root": {
        justifyContent: "center",
      },
      "& .MuiDialogActions-spacing > :not(:first-child)": {
        marginLeft: 20,
      },
    },
    dialogActionsRoot: {
      padding: 0,
      marginTop: 23,
    },
    loader: {
      position: "absolute",
    },
  })
);
