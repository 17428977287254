import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionOptions,
} from "../constants/Administrators";
import { useAuth } from "./Auth";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoading } from "../utils/Loading";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import useSWR, { mutate } from "swr";
import { Endpoints } from "../api/constants";
import {
  UseAdministrators,
  Administrator,
  AdministratorListItem,
  Me,
  Permission,
  UseMe,
} from "../types/Administrators";
import { mutateData } from "../api/api";
import { toast } from "react-toastify";
import { PermissionLevels } from "../constants/Administrators";
import { MpAsyncGetMethod } from "@mp-react/table";
import { AxiosResponse } from "axios";
import {
  AdministratorFilterNames,
  AdministratorRoles,
} from "../constants/Administrators";
import { useAdminUtils } from "../utils/Administrators";

const initalMeValues: UseMe = {
  loading: false,
  globalPermissions: [],
  localPermissions: [],
  getPermissionsByModule: () => [],
  checkIfCanEdit: () => false,
  checkIfCanView: () => false,
  checkPermissionByCompanyId: () => PermissionOptions.None,
  canEditGroups: false,
  canViewGroups: false,
  canEditAdministrators: false,
  canViewAdministrators: false,
  canViewCompanySettings: false,
  canEditGeneralSettings: false,
  canEditCustomEmployeeCategory: false,
  canEditCompanies: false,
  canEditCompanyGroups: false,
  canEditChoices: false,
  canViewChoices: false,
  canEditLogs: false,
  canViewLogs: false,
  canEditDashboard: false,
  canViewDashboard: false,
  canEditEmployees: false,
  canViewEmployees: false,
  canEditBenefits: false,
  canViewBenefits: false,
  canEditNews: false,
  canViewNews: false,
  hasBenefitPermissions: false,
  hasEmployeePermissions: false,
  hasDashboardPermissions: false,
  hasLogPermissions: false,
  hasChoicesPermissions: false,
  hasAdministratorPermissions: false,
  hasGroupPermissions: false,
  hasNewsPermissions: false,
  hasOrganisationsPermissions: false,
  isRoot: false,
  hasNoCompanySettingsAccess: false,
  isMelpAdmin: false,
  isHrAdmin: false,
};

const meContext = createContext<UseMe>(initalMeValues);

export const useProvideMe = (): UseMe => {
  const { isLoggedIn, hasToken } = useAuth();
  const { data: me, error: meError } = useSWR<Me, any>(
    isLoggedIn && hasToken ? Endpoints.me : null
  );

  const apiLoading = useMemo(() => !me && !meError, [me, meError]);

  const isRoot = useMemo(() => me?.root, [me?.root]);
  const isMelpAdmin = useMemo(
    () => me?.role === AdministratorRoles.MELP_ADMIN,
    [me?.role]
  );
  const isHrAdmin = useMemo(
    () => me?.role === AdministratorRoles.HR_ADMIN,
    [me?.role]
  );

  const globalPermissions = useMemo<Permission[]>(() => {
    if (!me?.permissions) return [];
    return me?.permissions.filter(
      (permission) => permission.level === PermissionLevels.Global
    );
  }, [me?.permissions]);

  const localPermissions = useMemo<Permission[]>(() => {
    if (!me?.permissions) return [];
    return me?.permissions.filter(
      (permission) => permission.level === PermissionLevels.Local
    );
  }, [me?.permissions]);

  const getPermissionsByModule = useCallback(
    (
      module:
        | LocalPermissionModules
        | GlobalPermissionModules
        | Array<LocalPermissionModules | GlobalPermissionModules>
    ): Permission[] => {
      const modules = Array.isArray(module) ? module : [module];
      if (!me?.permissions) return [];
      return me?.permissions.filter((permission) =>
        modules?.includes(permission.module)
      );
    },
    [me?.permissions]
  );

  const checkIfCanEdit = useCallback(
    (module: LocalPermissionModules | GlobalPermissionModules) => {
      if (isRoot) return true;
      const permissions = getPermissionsByModule(module);
      if (permissions.length === 0) return false;
      return permissions.some((permission) => permission.permission === "edit");
    },
    [getPermissionsByModule, isRoot]
  );

  const checkIfCanView = useCallback(
    (module: LocalPermissionModules | GlobalPermissionModules) => {
      if (isRoot) return true;
      const permissions = getPermissionsByModule(module);
      if (permissions.length === 0) return false;
      return permissions.some((permission) => permission.permission === "view");
    },
    [getPermissionsByModule, isRoot]
  );

  const checkIfHasNoAccess = useCallback(
    (module: LocalPermissionModules | GlobalPermissionModules) => {
      if (isRoot) return false;
      const permissions = getPermissionsByModule(module);
      if (permissions.length === 0) return true;
      return permissions.some((permission) => permission.permission === "none");
    },
    [getPermissionsByModule, isRoot]
  );

  const checkPermissionByCompanyId = useCallback(
    (
      module: LocalPermissionModules | GlobalPermissionModules,
      id: string
    ): PermissionOptions => {
      const permissions = getPermissionsByModule(module);
      if (permissions.length === 0) return PermissionOptions.None;
      const companyPermission = permissions.find(
        (permission) => permission.companyId === id
      );
      if (!companyPermission) return PermissionOptions.None;

      return companyPermission.permission;
    },
    [getPermissionsByModule]
  );

  const hasNoAdminAccess = useMemo(
    () => checkIfHasNoAccess(GlobalPermissionModules.Administrators),
    [checkIfHasNoAccess]
  );

  const hasNoOrganisationsAccess = useMemo(
    () => checkIfHasNoAccess(GlobalPermissionModules.OrganisationStructure),
    [checkIfHasNoAccess]
  );

  const hasNoCompanySettingsAccess = useMemo(
    () => hasNoOrganisationsAccess && hasNoAdminAccess,
    [hasNoOrganisationsAccess, hasNoAdminAccess]
  );

  const canEditNews = useMemo(
    () => checkIfCanEdit(LocalPermissionModules.News),
    [checkIfCanEdit]
  );

  const canViewNews = useMemo(
    () => checkIfCanView(LocalPermissionModules.News),
    [checkIfCanView]
  );

  const hasNewsPermissions = useMemo(
    () => canEditNews || canViewNews,
    [canEditNews, canViewNews]
  );

  const canEditBenefits = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.Benefits),
    [checkIfCanEdit]
  );

  const canViewBenefits = useMemo(
    () => checkIfCanView(GlobalPermissionModules.Benefits),
    [checkIfCanView]
  );

  const hasBenefitPermissions = useMemo(
    () => canEditBenefits || canViewBenefits,
    [canEditBenefits, canViewBenefits]
  );

  const canEditEmployees = useMemo(
    () => checkIfCanEdit(LocalPermissionModules.Employees),
    [checkIfCanEdit]
  );

  const canViewEmployees = useMemo(
    () => checkIfCanView(LocalPermissionModules.Employees),
    [checkIfCanView]
  );

  const hasEmployeePermissions = useMemo(
    () => canEditEmployees || canViewEmployees,
    [canEditEmployees, canViewEmployees]
  );

  const canEditDashboard = useMemo(
    () => checkIfCanEdit(LocalPermissionModules.Dashboard),
    [checkIfCanEdit]
  );

  const canViewDashboard = useMemo(
    () => checkIfCanView(LocalPermissionModules.Dashboard),
    [checkIfCanView]
  );

  const hasDashboardPermissions = useMemo(
    () => canEditDashboard || canViewDashboard,
    [canEditDashboard, canViewDashboard]
  );

  const canEditLogs = useMemo(
    () => checkIfCanEdit(LocalPermissionModules.History),
    [checkIfCanEdit]
  );

  const canViewLogs = useMemo(
    () => checkIfCanView(LocalPermissionModules.History),
    [checkIfCanView]
  );

  const hasLogPermissions = useMemo(
    () => canEditLogs || canViewLogs,
    [canEditLogs, canViewLogs]
  );

  const canEditChoices = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.FlexBenefit),
    [checkIfCanEdit]
  );

  const canViewChoices = useMemo(
    () => checkIfCanView(GlobalPermissionModules.FlexBenefit),
    [checkIfCanView]
  );

  const hasChoicesPermissions = useMemo(
    () => canEditChoices || canViewChoices,
    [canEditChoices, canViewChoices]
  );

  const canEditAdministrators = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.Administrators),
    [checkIfCanEdit]
  );

  const canEditGeneralSettings = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanEdit]
  );

  const canEditCustomEmployeeCategory = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanEdit]
  );

  const canEditCompanies = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanEdit]
  );

  const canEditCompanyGroups = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanEdit]
  );

  const canViewCompanySettings = useMemo(
    () => checkIfCanView(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanView]
  );

  const hasOrganisationsPermissions = useMemo(
    () => canViewCompanySettings || canEditCustomEmployeeCategory,
    [canEditCustomEmployeeCategory, canViewCompanySettings]
  );

  const canViewAdministrators = useMemo(
    () => checkIfCanView(GlobalPermissionModules.Administrators),
    [checkIfCanView]
  );

  const hasAdministratorPermissions = useMemo(
    () => canEditAdministrators || canViewAdministrators,
    [canEditAdministrators, canViewAdministrators]
  );

  const canEditGroups = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.EmployeeGroups),
    [checkIfCanEdit]
  );

  const canViewGroups = useMemo(
    () => checkIfCanView(GlobalPermissionModules.EmployeeGroups),
    [checkIfCanView]
  );

  const hasGroupPermissions = useMemo(
    () => canEditGroups || canViewGroups,
    [canEditGroups, canViewGroups]
  );

  return {
    me,
    loading: apiLoading,
    globalPermissions,
    localPermissions,
    getPermissionsByModule,
    checkIfCanEdit,
    checkIfCanView,
    checkPermissionByCompanyId,
    canEditGroups,
    canViewGroups,
    canEditAdministrators,
    canViewAdministrators,
    canViewCompanySettings,
    canEditGeneralSettings,
    canEditCustomEmployeeCategory,
    canEditCompanies,
    canEditCompanyGroups,
    canEditChoices,
    canViewChoices,
    canEditLogs,
    canViewLogs,
    canEditDashboard,
    canViewDashboard,
    canEditEmployees,
    canViewEmployees,
    canEditBenefits,
    canViewBenefits,
    canEditNews,
    canViewNews,
    hasBenefitPermissions,
    hasEmployeePermissions,
    hasDashboardPermissions,
    hasLogPermissions,
    hasChoicesPermissions,
    hasAdministratorPermissions,
    hasGroupPermissions,
    hasNewsPermissions,
    hasOrganisationsPermissions,
    isRoot,
    hasNoCompanySettingsAccess,
    isMelpAdmin,
    isHrAdmin,
  };
};

export const MeProvider = ({ children }: PropsWithChildren<{}>) => {
  const me = useProvideMe();
  return <meContext.Provider value={me}>{children}</meContext.Provider>;
};

export const useMe = (): UseMe => {
  return useContext(meContext);
};

const useAdministrators = (
  id?: string,
  query?: string,
  disableAPI?: boolean
): UseAdministrators => {
  const { t } = useTranslation();
  const history = useHistory();
  const { parseAdministratorRequest } = useAdminUtils();
  const { data: administratorList, error: administratorListError } = useSWR(
    !disableAPI && !id
      ? `${Endpoints.admin}${!!query ? `?${query}` : ""}`
      : null
  );
  const { data: administrator, error: administratorError } = useSWR(
    !disableAPI && !!id ? `${Endpoints.admin}/${id}` : null
  );

  const { stopLoading, startLoading, loading } = useLoading();

  const parsedAdministratorList = useMemo(
    () =>
      administratorList?.data?.map((data: AdministratorListItem) => ({
        ...data,
        inactive: data.status === "inactive",
      })) ?? [],
    [administratorList]
  );

  const parsedAdministratorTotals = useMemo(() => {
    const initialTotals = {
      fullName: "",
      email: "",
      phone: "",
      status: "",
    };
    if (!administratorList?.footer) return initialTotals;
    const { fullName, email, phone, status } = administratorList.footer;
    return {
      fullName: t("totals.administrator", { count: Number(fullName) }),
      email: t("totals.email", { count: Number(email) }),
      phone: t("totals.phone_number", { count: Number(phone) }),
      status: t("totals.inactive", {
        count: Number(status),
      }),
    };
  }, [administratorList, t]);

  const apiLoading = useMemo(() => {
    if (!!id) return !administrator && !administratorError;
    return !administratorList && !administratorListError;
  }, [
    administrator,
    administratorError,
    administratorList,
    administratorListError,
    id,
  ]);

  const isProgrammaticHr = useMemo(
    () => administrator?.role === "hrProgrammatic",
    [administrator?.role]
  );

  const createAdministrator = useCallback(
    async (data: Partial<Administrator>) => {
      startLoading();
      const parsedData = parseAdministratorRequest(data);
      await mutateData("post", `${Endpoints.createAdmin}`, parsedData)
        .then((res) => {
          if (!!res?.data?.id) {
            history.push(
              `/administrators/${res.data.id}?tab=administratorsPermissions`
            );
          }
          toast(t("common.added_succesfully"), { type: "success" });
          mutate(Endpoints.admin);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, parseAdministratorRequest, startLoading, stopLoading, t]
  );

  const updateAdministrator = useCallback(
    async (data: Partial<Administrator>, redirect?: boolean) => {
      const parsedData = parseAdministratorRequest(data);
      await mutateData("patch", `${Endpoints.admin}/${id}`, parsedData)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(Endpoints.admin);
          mutate(Endpoints.me);
          mutate(`${Endpoints.admin}/${id}`);
          if (redirect !== false) {
            history.push(
              "/more/company-settings?tab=companySettingsAdministrators"
            );
          }
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, id, parseAdministratorRequest, stopLoading, t]
  );

  const createAdministratorIntegration = useCallback(
    async (data: Partial<Administrator>) => {
      startLoading();
      const firstName = data?.firstName ?? "";
      if (!!firstName) {
        startLoading();
        const resData = await mutateData("post", Endpoints.createProgrammatic, {
          firstName,
        })
          .then((res) => {
            toast(t("common.added_succesfully"), { type: "success" });
            mutate(Endpoints.admin);
            return res.data;
          })
          .finally(() => {
            stopLoading();
          });
        return resData;
      }
      return {};
    },
    [startLoading, stopLoading, t]
  );

  const resendInvite = useCallback(
    (id: string) => {
      startLoading();
      mutateData("post", `${Endpoints.admin}/${id}/resendInvite`)
        .then(() => {
          toast(t("common.invitation_sent"), { type: "success" });
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t]
  );

  const deleteAdmin = useCallback(() => {
    startLoading();
    mutateData("delete", `${Endpoints.deleteHrAdmin}/${id}`)
      .then(() => {
        toast(t("common.deleted_succesfully"), { type: "success" });
        history.push(
          "/more/company-settings?tab=companySettingsAdministrators"
        );
      })
      .finally(() => {
        stopLoading();
      });
  }, [history, id, startLoading, stopLoading, t]);

  const changeAdminPassword = useCallback(
    async (id, newPassword) => {
      startLoading();
      let sucess = false;
      await mutateData("patch", `${Endpoints.admin}/${id}/changePassword`, {
        newPassword,
      })
        .then(() => {
          sucess = true;
          toast(t("common.updated_succesfully"), { type: "success" });
        })
        .finally(() => {
          stopLoading();
        });

      return sucess;
    },
    [startLoading, stopLoading, t]
  );

  return {
    resendInvite,
    createAdministrator,
    updateAdministrator,
    administrator,
    administratorList,
    loading: loading || apiLoading,
    parsedAdministratorList,
    parsedAdministratorTotals,
    createAdministratorIntegration,
    isProgrammaticHr,
    deleteAdmin,
    changeAdminPassword,
  };
};

export default useAdministrators;

export const useAdministratorsAsyncMethods = (): Record<
  string,
  MpAsyncGetMethod
> => {
  const baseUrl = useMemo(() => `${Endpoints.adminFilterValues}`, []);

  const getFilterItems = useCallback(
    (filterName: AdministratorFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getFullNames = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.FULL_NAMES),
    [getFilterItems]
  );

  const getEmail = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.EMAIL),
    [getFilterItems]
  );

  const getPhone = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.PHONE),
    [getFilterItems]
  );

  return {
    getFullNames,
    getEmail,
    getPhone,
  };
};
