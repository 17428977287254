import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

interface IProps {
  doneByName: string;
  benefitPlan: string;
}

export default function UserSelectedFlexChoice(props: IProps) {
  const { doneByName, benefitPlan } = props;
  const { t } = useTranslation();

  return useMemo(() => {
    return (
      <>
        {t("log.flex_benefit_was_selected")
          .split(" ")
          ?.map((text) => {
            switch (text) {
              case "{benefitName}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${benefitPlan} `}
                  </Typography>
                );
              case "{employee}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${doneByName} `}
                  </Typography>
                );
              default:
                return `${text} `;
            }
          })}
      </>
    );
  }, [benefitPlan, doneByName, t]);
}
