import { Box, BoxProps } from "@material-ui/core";
import { Datepicker } from "@mp-react/table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTableStore } from "../../../state/Table";
import MpDatepicker from "./Datepicker/Datepicker";
import useStyles from "./TableDatepicker.styles";
import FromToFilter from "./FromToFilter/FromToFilter";
import useDatepicker from "../../../utils/Datepicker";
import moment from "moment";
import { capitalizeFirst, useDateFormat } from "../../../utils/Common";
import useTableUtils from "../../../utils/Table";

interface IProps extends BoxProps {
  defaultDate?: string;
}

export default function TableDatepicker({ defaultDate, ...rest }: IProps) {
  const classes = useStyles();
  const { localeLanguage } = useDateFormat();
  const { setDatepickerVariables, setDatepickerValue, datepickerValue } =
    useTableStore();
  const { setDatepickerValues } = useDatepicker();
  const { translations } = useTableUtils();
  const [locale, setLocale] = useState<string>(localeLanguage);

  const shortMonth = useMemo(() => {
    if (!!defaultDate) {
      const monthNum = Number(moment(defaultDate).month());
      const month = moment(defaultDate)
        .month(monthNum)
        .locale(localeLanguage)
        .format("MMM");
      const year = moment(defaultDate ?? "")
        .locale(localeLanguage)
        .format("YYYY");
      return `${month[0].toUpperCase() + month?.substring(1)}, ${year}`;
    }
    return "";
  }, [defaultDate, localeLanguage]);

  const handleDateChange = useCallback(
    (date: Datepicker | null) => {
      const datepickerObject = date ?? {
        dateFrom: "all",
        dateTo: "all",
      };
      setDatepickerVariables(datepickerObject);
    },
    [setDatepickerVariables]
  );

  const translateDateValue = useCallback(
    (value: string) => {
      const splitValue = value.split(" - ");
      const translated = splitValue?.map((date: string) => {
        const month = date.split(", ")?.[0];
        const year = date.split(", ")?.[1];
        const monthNum =
          Number(moment().locale(locale).month(month).format("M")) - 1;
        return capitalizeFirst(
          moment(year)
            .locale(localeLanguage)
            .month(monthNum)
            .format("MMM, YYYY")
        );
      });
      if (translated?.length > 1) return translated.join(" - ");
      else return translated[0];
    },
    [locale, localeLanguage]
  );

  useEffect(
    () => {
      if (!!datepickerValue)
        setDatepickerValue(translateDateValue(datepickerValue));
      setLocale(localeLanguage);
    },
    // eslint-disable-next-line
    [localeLanguage]
  );

  useEffect(() => {
    if (!!defaultDate) {
      setDatepickerValues(shortMonth, "month", handleDateChange);
      setDatepickerValue(shortMonth);
    }
    // eslint-disable-next-line
  }, [defaultDate]);

  return (
    <Box className={classes.datepicker} {...rest}>
      <MpDatepicker
        onGetData={handleDateChange}
        FromToFilter={FromToFilter}
        translations={translations}
      />
    </Box>
  );
}
