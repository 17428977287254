export enum HierarchyLevels {
  COMPANY_AND_DEPARTMENT = "companyAndCompanyDepartment",
  FULL_HIERARCHY = "fullHierarchy",
  COMPANY_AND_COMPANY_GROUPS = "companyAndCompanyGroups",
}

export const noBreadcrumbs = [
  "innerEmployeeChoices",
  "innerEmployeeFlexAllocation",
  "innerEmployeeFlexBalance",
  "innerEmployeeAssignBenefits",
  "innerEmployeeLog",
  "innerEmployeeAssignGroups",
  "log",
  "clients",
  "users",
  "common",
];
