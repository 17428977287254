import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Info } from "../../icons";
import { Colors } from "../../style";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.elementsGrey}`,
    borderRadius: 6,
    padding: 10,
  },
  message: {
    color: Colors.grey,
    padding: 0,
    fontSize: 14,
    lineHeight: 1.4,
  },
  icon: {
    padding: 0,
    marginRight: 12,
    "& > svg": {
      height: 20,
      width: "auto",
    },
  },
}));

const iconMapping = {
  success: <Info />,
  info: <Info />,
  warning: <Info />,
  error: <Info />,
};

interface Props {
  severity?: "success" | "info" | "warning" | "error";
  /**
   * Content to display inside alert panel.
   */
  children: ReactNode;
}

const Alert = ({ severity = "info", ...props }: Props) => {
  const classes = useStyles();
  return (
    <MuiAlert
      {...props}
      severity={severity}
      iconMapping={iconMapping}
      classes={{ ...classes }}
    />
  );
};

export default Alert;
