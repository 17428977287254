import { MpTableType } from "@mp-react/table";
import clsx from "clsx";
import useStyles from "./CustomColumnArro.styles";
import { ReactComponent as PointingArrow } from "../../../assets/icons/pointing-arrow.svg";
import { usePointingArrowPosition } from "../../../constants/Table";
import { useTableStore } from "../../../state/Table";

interface IProps {
  tableType: MpTableType;
  className?: string;
}

export default function CustomColumnArrow(props: IProps) {
  const { tableType, className } = props;
  const classes = useStyles();

  const isOpenCustomColumnDialog = useTableStore(
    (state) => state.isOpenCustomColumnDialog
  );

  const customColumnArrowPos = usePointingArrowPosition(tableType);
  const customColumnArrowStyle = customColumnArrowPos
    ? { left: customColumnArrowPos }
    : undefined;

  if (!isOpenCustomColumnDialog) return null;

  return (
    <PointingArrow
      className={clsx([classes.pointingArrow, className])}
      style={customColumnArrowStyle}
    />
  );
}
