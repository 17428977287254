import { Box, Typography } from "@material-ui/core";
import useStyles from "./Datepicker.styles";
import React, { useCallback, useState } from "react";
import { FromToFilterProps, MpTableTranslations } from "@mp-react/table";
import Check from "./icons/Check";
import { useTranslation } from "react-i18next";

interface Props {
  onSelectAll: () => void;
  date: string | null;
  translations?: MpTableTranslations;
  FromToFilter?: React.ComponentType<FromToFilterProps>;
  filterProps: FromToFilterProps;
}

export default function DatepickerHeader({
  date,
  onSelectAll,
  translations,
  FromToFilter,
  filterProps,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isFromTo, setIsFromTo] = useState<boolean>(false);

  const switchView = useCallback(() => {
    setIsFromTo(true);
    //update popper position
    window.scrollTo(window.scrollX, window.scrollY + 1);
    window.scrollTo(window.scrollX, window.scrollY - 1);
  }, []);

  if (isFromTo && !!FromToFilter) return <FromToFilter {...filterProps} />;

  return (
    <Box className={classes.header}>
      {!date && <Check className={classes.iconContainer} />}
      <Typography
        onClick={onSelectAll}
        classes={{ root: classes.headerAction }}
        variant="body2"
      >
        {t("common.all_time")}
      </Typography>
      {!!FromToFilter && (
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            switchView();
          }}
          classes={{ root: classes.headerAction }}
          variant="body2"
        >{`${translations?.from ?? "From"} - ${
          translations?.to ?? "To"
        }`}</Typography>
      )}
    </Box>
  );
}
