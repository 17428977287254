import React from "react";
import {
  CardMedia as MuiCardMedia,
  CardMediaProps,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 90,
    width: "100%",
  },
}));

interface Props extends CardMediaProps {}

const CardMedia = (props: Props) => {
  const classes = useStyles();
  return <MuiCardMedia {...props} classes={{ root: classes.root }} />;
};

export default CardMedia;
