import { AppBar, Box, Toolbar } from "@material-ui/core";
import React, { useMemo } from "react";
import { useMe } from "../../../state/Administrators";
import { useAuth } from "../../../state/Auth";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import HeaderButton from "../HeaderButton/HeaderButton";
import Logo from "../Logo/Logo";
import MenuMap from "../MenuMap/MenuMap";
import UserDropdown from "../UserDropdown/UserDropdown";
import useStyles from "./Header.styles";

export default function Header() {
  const classes = useStyles();
  const { isLoggedIn, hasToken } = useAuth();
  const { me } = useMe();

  const isHrAdmin = useMemo(() => me?.role === "hrAdmin", [me?.role]);
  const show = useMemo(() => isLoggedIn && hasToken, [hasToken, isLoggedIn]);

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <Box className={classes.itemContainer}>
          <Box className={classes.vAlignCenter}>
            <Logo />
            {show && (
              <>
                {isHrAdmin && <HeaderButton />}
                <MenuMap />
              </>
            )}
          </Box>
          {show && (
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {isHrAdmin && <GlobalSearch />}
              <UserDropdown />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
