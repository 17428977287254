import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: 30,
      paddingTop: 28,
    },
    dialogContentRoot: {
      padding: 0,
      marginTop: 30,
      textAlign: "left",
    },
    dialogActionsRoot: {
      padding: 0,
      marginTop: 30,
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
    },
  })
);
