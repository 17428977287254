import React, { useMemo } from "react";
import { MpControlProps, useErrorMessages } from "@mp-react/form";
import {
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  InputLabel,
  FormHelperText,
  Box,
} from "@material-ui/core";
import useStyles from "./DepartmentDropdownView.styles";
import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/arrow-right.svg";
import { useTranslation } from "react-i18next";
import { useHierarchy } from "../../../../state/Hierarchy";
import { HierarchyLevels } from "../../../../constants/ParentCompany";
import {
  DepartmentResponse,
  HierarchyRequest,
} from "../../../../types/ParentCompany";
import useTooltip from "../../../../utils/Tooltip";
import FormTooltip from "../../../common/FormTooltip/FormTooltip";
import {
  LocalPermissionModules,
  PermissionOptions,
} from "../../../../constants/Administrators";
import { useMe } from "../../../../state/Administrators";
import { Company } from "../../../../types/Companies";

export default function DepartmentDropdownView({
  control,
  variant,
  onChange,
  value,
  inputRef,
  name,
  companyId,
  defaultMessages,
  error,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
  name: string;
  inputRef: React.MutableRefObject<any>;
  companyId: string;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const classes = useStyles();
  const { t } = useTranslation();
  const { canEditEmployees, canEditCompanies } = useMe();

  const hierarchyRequest = useMemo<HierarchyRequest>(
    () => ({
      level: HierarchyLevels.COMPANY_AND_DEPARTMENT,
      module: LocalPermissionModules.Employees,
      permissions: canEditEmployees
        ? PermissionOptions.Edit
        : PermissionOptions.View,
    }),
    [canEditEmployees]
  );

  const { hierarchy, loading } = useHierarchy(hierarchyRequest);

  const [firstMessage] = useErrorMessages(control, defaultMessages, error);

  const departments = useMemo(() => {
    if (companyId) {
      return (
        ((hierarchy?.companies as Company[])?.find(
          (company) => companyId === company.id
        )?.departments as DepartmentResponse[]) ?? []
      );
    }
  }, [hierarchy, companyId]);

  const ManageOption = useMemo(() => {
    if (!canEditCompanies) return null;
    return (
      <MenuItem
        component={Link}
        to={`/more/company-settings?tab=companySettingsCompanies&view=edit&id=${companyId}`}
        className={classes.link}
      >
        {t("form.manage")}
        <LinkIcon />
      </MenuItem>
    );
  }, [canEditCompanies, classes.link, companyId, t]);

  const SelectComponent = useMemo(() => {
    if (companyId && departments) {
      return (
        <Select
          value={value ?? "empty"}
          onChange={onChange}
          inputRef={inputRef}
          name={name}
        >
          <MenuItem key={"empty"} value={"empty"}>
            {"-"}
          </MenuItem>
          {departments?.map((department: DepartmentResponse) => {
            return (
              <MenuItem key={department.id} value={department.id}>
                {department.name}
              </MenuItem>
            );
          })}
          {ManageOption}
        </Select>
      );
    }
  }, [companyId, departments, value, onChange, inputRef, name, ManageOption]);

  if (loading && !companyId) return <CircularProgress size={20} />;

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <InputLabel
        className={`${classes.label} ${error && classes.error}`}
        required={false}
      >
        {t("companies.departments")}
      </InputLabel>
      <FormControl
        id="department-dropdown"
        variant={variant ?? "outlined"}
        fullWidth={true}
        required={false}
        className={classes.formControl}
      >
        {SelectComponent}
        <FormHelperText margin="dense" variant="outlined" error={!!error}>
          {firstMessage}
        </FormHelperText>
      </FormControl>
      <FormTooltip
        tooltip={tooltip}
        anchorEl={anchorEl}
        height={"auto"}
        marginTop="22px"
      />
    </Box>
  );
}
