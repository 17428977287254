import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 473,
      "& .MuiGrid-container": {
        alignItems: "center",
        "& .MuiGrid-grid-sm-4 .MuiBox-root": {
          paddingTop: 0,
        },
      },
    },
    dialogContentRoot: {
      padding: 0,
      marginTop: 30,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    dialogActionsRoot: {
      paddingBottom: 30,
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 0,
      display: "block",
      "&.MuiDialogActions-spacing > :not(:first-child)": {
        marginLeft: 0,
      },
    },
    formTitles: {
      paddingBottom: 4,
      paddingLeft: 30,
      paddingRight: 30,
    },
    addStatusBtn: {
      padding: 5,
      height: "unset",
    },
    progressIndicator: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -10,
    },
  })
);
