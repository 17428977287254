import { Typography } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";

export default function Groups(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { value } = useRendererOptions(props);

  const text = useMemo(() => {
    if (!value) {
      return t("common.dash");
    } else if (value?.length > 1) {
      return value.length;
    } else {
      return value;
    }
  }, [value, t]);

  return <Typography variant="body2">{text}</Typography>;
}
