import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      "& .MuiBreadcrumbs-ol": {
        flexWrap: "nowrap",
        "& > .MuiBreadcrumbs-li": {
          textAlign: "center",
          lineHeight: "20px",
          "& > a": {
            lineHeight: "20px",
          },
        },
      },
    },
  })
);
