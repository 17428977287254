import { mutateData } from "../api/api";
import { useLoading } from "./Loading";
import useSWR from "swr";
import fileDownloader from "js-file-download";

type SupportedHttpMethods = "post" | "patch" | "put" | "delete";

interface MutationOptions<T> {
  onSuccess: (response: T) => void;
}

/**
 * A hook for calling endpoints, which performs data mutation. It also tracks
 * a loading state. Mutations is invoked on demand.
 *
 * @param url Url to call
 * @param type HTTP method to use for mutation
 * @returns Tuple with mutation invoker and state data.
 */
export const useMutation = <D = any, T = any>(
  url: string,
  method: SupportedHttpMethods,
  options?: MutationOptions<T>
) => {
  const mutationState = useLoading();
  const mutate = async (data?: D, query: string = "") => {
    mutationState.startLoading();
    try {
      const response = await mutateData<T>(method, url + query, data);
      options?.onSuccess(response.data);
      return response.data;
    } finally {
      mutationState.stopLoading();
    }
  };
  return [mutate, { loading: mutationState.loading }] as [
    typeof mutate,
    { loading: boolean }
  ];
};

export const useFilterValues = (
  url: string,
  filterName: string,
  lookupValue?: string,
  options?: {
    /**
     * Does not fetch data until isPaused is set to true
     */
    isPaused?: boolean;
  }
) => {
  const isPaused = options?.isPaused ?? false;
  const query = lookupValue ? `?lookupValue=${lookupValue}` : "";
  const key = `${url}/${filterName}${query}`;
  const { data, error } = useSWR<string[]>(key, {
    isPaused: () => isPaused,
  });
  return {
    values: data,
    loading: !data && !error && !isPaused,
  };
};

export const useXLSExport = (url: string) => {
  const loadingState = useLoading();

  const exportToXLS = async () => {
    loadingState.startLoading();
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    try {
      const response = await mutateData(
        "get",
        url,
        null,
        headers,
        "arraybuffer"
      );
      const disposition = response?.headers["content-disposition"];
      const headersFilename = disposition?.split("filename=")?.[1] ?? "export";
      fileDownloader(response.data, headersFilename);
      return;
    } finally {
      loadingState.stopLoading();
    }
  };

  return { exportToXLS, exporting: loadingState.loading };
};
