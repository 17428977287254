import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceByElement } from "../../../../../utils/Logs";
import { Log } from "../../../../../types/Logs";

export default function AdminCreated(props: Partial<Log>) {
  const { admin, adminEmail } = props;
  const { t } = useTranslation();

  const adminName = useMemo(
    () => admin ?? adminEmail ?? "",
    [admin, adminEmail]
  );

  return useMemo(
    () =>
      replaceByElement(
        t("log.system_administrator_was_created"),
        "{name}",
        adminName
      ),
    [adminName, t]
  );
}
