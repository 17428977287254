import { useDefaultHighlights } from "../../constants/Table";
import { CustomRendererType } from "../../types/Table";
import { useTranslation } from "react-i18next";
import { MpActions, MpColumn, MpTableType } from "@mp-react/table";
import { useMemo } from "react";
import { Colors } from "../../constants/Style";
import { useMe } from "../../state/Administrators";
import { useTableFilterTypes } from "../../utils/Table";
import { useTableStore } from "../../state/Table";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";
import { useEmployeeInnerCategories } from "../../state/CustomEmployeeCategories";

export const useBenefitsAssignEmployeesTableType = (): MpTableType => {
  const { canEditEmployees } = useMe();
  const { t } = useTranslation();
  const highlights = useDefaultHighlights();
  const { keyWithSuffix } = useLocalStorage();

  const {
    getSearchSelectFilter,
    getFilterNameSearchSelectFilter,
    getSelectFilter,
    numberRangeFilter,
  } = useTableFilterTypes();

  const { loading: loadingCategories } = useEmployeeInnerCategories();

  const customColumnValue = useTableStore((state) => state.customColumn);

  const fullNameFilter = useMemo(
    () => getSearchSelectFilter("getFullNames"),
    [getSearchSelectFilter]
  );

  const employeeGroupFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getEmployeeGroups"),
    [getFilterNameSearchSelectFilter]
  );

  const currentStatusFilter = useMemo(
    () =>
      getSelectFilter("currentStatus", [
        {
          label: t("status.status_off"),
          value: "off",
        },
        {
          label: t("status.status_flex"),
          value: "flex",
        },
        {
          label: t("status.status_on"),
          value: "on",
        },
      ]),
    [getSelectFilter, t]
  );

  const customColumnFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getCustomColumnCategories"),
    [getFilterNameSearchSelectFilter]
  );

  const fullNameColumn = useMemo(
    () => ({
      label: t("common.full_name"),
      key: "fullName",
      filter: fullNameFilter,
      width: 175,
      fixedHeadWidth: true,
    }),
    [fullNameFilter, t]
  );

  const customCategoryColumn = useMemo(
    () => ({
      label: customColumnValue ?? "",
      key: "customColumn",
      filter: customColumnFilter,
      width: 90,
      fixedHeadWidth: true,
    }),
    [customColumnFilter, customColumnValue]
  );

  const restColumns = useMemo(
    () => [
      {
        label: t("entity_type.employeeGroup"),
        key: "employeeGroup",
        render: CustomRendererType.employeeGroup as any,
        filter: employeeGroupFilter,
        width: 160,
        fixedHeadWidth: true,
      },
      {
        label: t("common.investment"),
        key: "investment",
        sort: false,
        render: CustomRendererType.currency as any,
        filter: numberRangeFilter,
        width: 110,
        fixedHeadWidth: true,
      },
      {
        label: t("table.previous_status"),
        key: "statuses",
        sort: false,
        render: CustomRendererType.entries as any,
        width: 165,
        fixedHeadWidth: true,
      },
      {
        label: t("common.current_status"),
        key: "currentStatus",
        filter: currentStatusFilter,
        sort: false,
        totals: false,
        render: CustomRendererType.statusToggle as any,
        action: {
          slug: "toggleStatus",
          defaultValue: "on",
          items: [
            {
              label: t("status.off"),
              value: "off",
              color: Colors.Default,
              textColor: Colors.TextSecondary,
            },
            {
              label: t("status.flex"),
              value: "flex",
              color: Colors.StatusYellow,
              textColor: Colors.White,
            },
            {
              label: t("status.on"),
              value: "on",
              color: Colors.StatusGreen,
              textColor: Colors.White,
            },
          ],
        },
        width: 200,
        fixedHeadWidth: true,
      },
    ],
    [currentStatusFilter, employeeGroupFilter, numberRangeFilter, t]
  );

  const columns: MpColumn[] = useMemo(
    () =>
      !customColumnValue && !loadingCategories
        ? [fullNameColumn, ...restColumns]
        : [fullNameColumn, customCategoryColumn, ...restColumns],
    [
      customColumnValue,
      fullNameColumn,
      customCategoryColumn,
      restColumns,
      loadingCategories,
    ]
  );

  const bulkActions: MpActions = useMemo(
    () => [
      {
        slug: "statusOn",
        label: `${t("actions.mark_selected_status_as_on")}`,
      },
      {
        slug: "statusFlex",
        label: `${t("actions.mark_selected_status_as_flex")}`,
      },
      {
        slug: "statusOff",
        label: `${t("actions.mark_selected_status_as_off")}`,
      },
    ],
    [t]
  );

  return {
    columns: columns,
    rowIdKey: "id",
    filterVariant: "head",
    bulkActions: bulkActions,
    disablePagination: true,
    totals: true,
    stickyTotals: true,
    enableGlobalActions: true,
    selection: canEditEmployees ? "multiple" : undefined,
    highlights: highlights,
    storageIdentifier: keyWithSuffix(StorageKeys.benefitAssignEmployeesTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
