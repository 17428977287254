import { MpControlType, MpFormType } from "@mp-react/form";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { CustomFormControl } from "../../../types/Common";
import { getTimePlaceholder, useDatePlaceholder } from "../../../utils/Common";
import { useMe } from "../../../state/Administrators";

export const useEmployeeInformationContact = (
  readonly?: boolean
): MpFormType => {
  const { t } = useTranslation();
  const { datePlaceholder } = useDatePlaceholder();
  const { me } = useMe();

  const supportedLanguages = useMemo(() => {
    const languageMap = me?.parentCompany?.supportedLanguages;
    return (
      languageMap?.map((language) => ({
        type: "select" as any,
        key: language,
        label: t(`language.${language}`),
      })) ?? []
    );
  }, [me?.parentCompany?.supportedLanguages, t]);

  const controls = useMemo(
    (): CustomFormControl[] => [
      {
        type: MpControlType.text,
        key: "firstName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.first_name"),
        placeholder: t("form.first_name"),
        required: true,
        tooltip: "tooltip.employee_contacts_first_name",
        readonly: !!readonly,
      },
      {
        type: MpControlType.text,
        key: "lastName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.last_name"),
        placeholder: t("form.last_name"),
        required: true,
        tooltip: "tooltip.employee_contacts_last_name",
        readonly: !!readonly,
      },
      {
        type: MpControlType.text,
        key: "email",
        label: t("form.work_email"),
        placeholder: t("form.work_email"),
        tooltip: "tooltip.employee_contacts_work_email",
        readonly: !!readonly,
      },
      {
        type: "typeDate" as any,
        label: t("employees.date_of_birth"),
        key: "dateOfBirth",
        placeholder: datePlaceholder,
        tooltip: "tooltip.employee_contacts_date_of_birth",
        readonly: !!readonly,
      },
      {
        type: MpControlType.multiline,
        key: "internalComment",
        label: t("common.internal_comment"),
        placeholder: t("common.comments"),
        tooltip: "tooltip.employee_contacts_internal_comment",
        readonly: !!readonly,
      },
      {
        type: "dropdown" as any,
        key: "language",
        label: t("form.preferred_language"),
        tooltip: "tooltip.employee_contacts_language",
        children: supportedLanguages,
      },
      {
        type: "mergedGroup" as any,
        label: t("employees.activate_on"),
        required: true,
        tooltip: "tooltip.employee_activate_employee_on",
        children: [
          {
            type: "date" as any,
            key: "startDate",
            required: true,
            placeholder: datePlaceholder,
            messages: {
              required: t("form.required"),
              pattern: t("errors.activation_date_missing"),
            },
            span: {
              sm: 6,
            },
          },
          {
            type: "time" as any,
            key: "startTime",
            required: true,
            placeholder: getTimePlaceholder(),
            messages: {
              required: t("form.required"),
              pattern: t("errors.activation_time_missing"),
            },
            span: {
              sm: 6,
            },
          },
        ],
      },
    ],
    [t, readonly, datePlaceholder, supportedLanguages]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
