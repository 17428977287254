import { MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { TooltipControl } from "../../types/Common";

export const useChangeOrganizationUnit = (): MpFormType => {
  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: "companyAndDepartmentDropdown" as any,
        key: "companyId",
        tooltip: "tooltip.change_org_units_company",
        label: "",
      },
    ],
    []
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
