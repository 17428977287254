import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./UserAlreadyExistsDialog.styles";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps extends DialogProps {
  onCancelClick: () => void;
  id: string;
}

const UserAlreadyExistsDialog = ({ onCancelClick, id, ...rest }: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={onCancelClick}
      onEnterPress={onCancelClick}
      {...rest}
    >
      <Typography variant="h5">{t("employees.user_exist")}</Typography>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Typography variant="body2" color="textSecondary">
          {t("employees.contact_melp")}
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={onCancelClick} variant="text">
          {t("common.cancel")}
        </Button>
        <Button onClick={onCancelClick} variant="contained" color="primary">
          {t("common.continue")}
        </Button>
      </DialogActions>
    </DialogExtended>
  );
};

export default UserAlreadyExistsDialog;
