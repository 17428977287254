import { useMemo, useCallback } from "react";
import { Box, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { useUsers } from "../../../../state/Users";
import useTableUtils from "../../../../utils/Table";
import { useTranslation } from "react-i18next";

export default function UsersHeader() {
  const { t } = useTranslation();
  const { tableQueryParam } = useTableUtils("users");
  const { exportToExcel, loading, users } = useUsers(tableQueryParam);

  const tableDataCount = useMemo(() => users?.count, [users?.count]);

  const handleDownload = useCallback(() => {
    exportToExcel();
  }, [exportToExcel]);

  const DownloadButton = useMemo(() => {
    if (loading)
      return (
        <Box px="20px" display="flex">
          <CircularProgress size={20} />
        </Box>
      );
    return (
      <Tooltip title={t("dashboard.download_xls") || "XLS"}>
        <IconButton
          onClick={handleDownload}
          style={{ marginLeft: "8px" }}
          disabled={!tableDataCount}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    );
  }, [handleDownload, loading, t, tableDataCount]);

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="flex-end"
      minHeight="44px"
    >
      {DownloadButton}
    </Box>
  );
}
