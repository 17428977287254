import React from "react";
import CountryFlag from "../CountryFlag/CountryFlag";
import { languageToCountry } from "../../../utils/Language";

interface Props {
  /**
   * ISO 639-1 language code
   */
  languageCode: string;
}

const LanguageFlag = ({ languageCode }: Props) => {
  const lowerCaseCode = languageCode.toLowerCase();
  const customLanguagesMap: Record<string, string | undefined> = {
    en: "WORLD",
    au: "AU",
  };
  const customValue = customLanguagesMap[lowerCaseCode];
  const countryCode = customValue ?? languageToCountry(lowerCaseCode);
  return <CountryFlag countryCode={countryCode} />;
};

export default LanguageFlag;
