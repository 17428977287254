import React from "react";
import {
  CardActionArea as MuiCardActionArea,
  CardActionAreaProps,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

interface Props extends CardActionAreaProps {}

const CardActionArea = (props: Props) => {
  const classes = useStyles();
  return (
    <MuiCardActionArea
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  );
};

export default CardActionArea;
