import { Colors } from "./../../../constants/Style";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      minHeight: "unset",
      maxWidth: "unset",
      padding: "14px 25px 13px 20px",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.short,
      }),
      minWidth: "100%",
      "&::after": {
        content: "''",
        width: "calc(100% - 20px)",
        height: 1,
        background: "rgba(0, 0, 0, 0.1)",
        position: "absolute",
        bottom: 0,
        right: 0,
      },
      "&:last-child::after": {
        width: 0,
        height: 0,
      },
      "&:hover": {
        backgroundColor: Colors.Hover,
      },
      "& .MuiTypography-colorTextSecondary": {
        textAlign: "left",
        maxWidth: "calc(100% - 10px)",
      },
    },
    tabBorderTop: {
      "&:first-child::before": {
        content: "''",
        width: "calc(100% - 20px)",
        height: 1,
        background: "rgba(0, 0, 0, 0.1)",
        position: "absolute",
        top: 0,
        right: 0,
      },
    },
    selectedTab: {
      "& p": {
        fontWeight: "bold",
        color: theme.palette.text.primary,
      },
      "& svg": {
        "& path": {
          stroke: "#000",
        },
      },
    },
    hiddenTab: {
      height: 1,
      minHeight: 1,
      padding: 0,
      background: "white",
      marginTop: "-1px",
      "& svg": {
        display: "none",
      },
    },
    tabWrapper: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "center",
      textTransform: "initial",
      "& svg": {
        marginBottom: "0 !important",
      },
    },
  })
);
