import { Box, BoxProps, Typography } from "@material-ui/core";
import { useMemo } from "react";
import useStyles from "./FormSufleris.styles";

interface IProps extends BoxProps {
  sufleris: string;
  multiline?: boolean;
}

export default function FormSufleris({ sufleris, multiline }: IProps) {
  const classes = useStyles();
  const justify = useMemo(
    () =>
      !multiline && sufleris && sufleris.length < 40 ? "center" : "initial",
    [multiline, sufleris]
  );

  const TextSplitByLine = useMemo(() => {
    return sufleris?.split("\n")?.map((line: string) => {
      const text = line === "" ? "\n" : line;
      return (
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ whiteSpace: "pre-line" }}
        >
          {text}
        </Typography>
      );
    });
  }, [sufleris]);

  if (!sufleris) return null;

  return (
    <Box
      position="absolute"
      top={0}
      height={1}
      left={"calc(100% + 40px)"}
      width={230}
      overflow="auto"
      className={classes.scrollbar}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={justify}
        height={1}
      >
        {TextSplitByLine}
      </Box>
    </Box>
  );
}
