import { useLoading } from "./../utils/Loading";
import { Endpoints } from "./../api/constants";
import useSWR, { mutate } from "swr";
import { useMemo, useCallback } from "react";
import {
  UseEmployeeChoices,
  EmployeeBenefitPlanChoice,
} from "./../types/EmployeeChoices";
import moment from "moment";
import { mutateData } from "../api/api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  MpBulkActionCallback,
  MpBulkActionMethods,
  MpRowActionParameters,
  MpRowActionMethods,
  MpAsyncGetMethod,
} from "@mp-react/table";
import { Row } from "react-table";
import { browserLanguage } from "../utils/Common";
import { useCurrency } from "../utils/useCurrency";
import { AxiosResponse } from "axios";
import { ChoicesFilterNames } from "../constants/Choices";
import fileDownloader from "js-file-download";

const useEmployeeChoices = (
  id?: string,
  query?: string
): UseEmployeeChoices => {
  const { t } = useTranslation();
  const now = useMemo(() => moment().format(), []);
  const queryParams = useMemo(() => (!!query ? `?${query}` : ""), [query]);
  const { data: employeeChoices, error: employeeChoicesError } = useSWR(
    id ? `${Endpoints.employee}/${id}/benefitPlanChoices${queryParams}` : null
  );
  const { stopLoading, startLoading, loading } = useLoading();
  const { getDefaultCurrencyFormat } = useCurrency();

  const apiLoading = useMemo(() => {
    return !employeeChoices && !employeeChoicesError;
  }, [employeeChoices, employeeChoicesError]);

  const parsedEmployeeChoices = useMemo(
    () =>
      employeeChoices?.data?.map((data: EmployeeBenefitPlanChoice) => ({
        ...data,
        inactive: moment().diff(moment(data.votingEndDate)) > 0,
      })) ?? [],
    [employeeChoices?.data]
  );

  const parsedEmployeeChoicesTotals = useMemo(() => {
    const initialTotals = {
      benefitPlanName: "",
      benefitPlanType: "",
      flexImplementationDate: "",
      flexStatus: "",
      investment: "",
      employeeName: "",
    };
    if (!employeeChoices?.footer) return initialTotals;
    const {
      employeeName,
      benefitPlanName,
      benefitPlanType,
      flexImplementationDate,
      flexStatus,
      investment,
    } = employeeChoices.footer;
    return {
      employeeName: t("totals.employee", { count: Number(employeeName) }),
      benefitPlanName: t("totals.benefit_plan", {
        count: Number(benefitPlanName),
      }),
      benefitPlanType: t("totals.benefit_type", {
        count: Number(benefitPlanType),
      }),
      flexImplementationDate: t("totals.implemented", {
        count: Number(flexImplementationDate),
      }),
      implementationStatus: t("totals.implemented", {
        count: Number(flexImplementationDate),
      }),
      flexStatus: t("totals.not_selected", {
        count: Number(flexStatus),
      }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
    };
  }, [employeeChoices, t, getDefaultCurrencyFormat]);

  const toggleImplementationDate = useCallback(
    (current: MpRowActionParameters) => {
      const { value, rowId } = current;
      const newValue = value ? value : null;
      startLoading();
      mutateData("patch", `${Endpoints.setImplementationDates}`, [
        {
          id: rowId,
          flexImplementationDate: newValue,
        },
      ])
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.setImplementationDates}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t]
  );

  const getImlementationValue = useCallback(
    (selectedRows: Row<{}>[], type: "date" | "null") => {
      const implementationValue = selectedRows
        ?.filter((item) => {
          const { original } = item as any;
          return original.flexStatus === "selected";
        })
        .map((item) => {
          const { original } = item as any;
          return {
            id: original.id as string,
            flexImplementationDate: type === "date" ? now : null,
          };
        });
      return implementationValue;
    },
    [now]
  );

  const implementationDone: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const implementationValue = getImlementationValue(
        selectedRows as Row<{}>[],
        "date"
      );
      if (implementationValue?.length !== 0) {
        startLoading();
        mutateData(
          "patch",
          `${Endpoints.setImplementationDates}`,
          implementationValue
        )
          .then(() => {
            toast(t("common.updated_succesfully"), { type: "success" });
            mutate(`${Endpoints.employee}/${id}/benefitPlanChoices`);
          })
          .finally(() => {
            stopLoading();
          });
      } else {
        toast(t("choices.implementation_toast"), { type: "warning" });
      }
    },
    [startLoading, stopLoading, id, t, getImlementationValue]
  );

  const implementationPending: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const implementationValue = getImlementationValue(
        selectedRows as Row<{}>[],
        "null"
      );
      if (implementationValue?.length !== 0) {
        startLoading();
        mutateData(
          "patch",
          `${Endpoints.setImplementationDates}`,
          implementationValue
        )
          .then(() => {
            toast(t("common.updated_succesfully"), { type: "success" });
            mutate(`${Endpoints.employee}/${id}/benefitPlanChoices`);
          })
          .finally(() => {
            stopLoading();
          });
      } else {
        toast(t("choices.implementation_toast"), { type: "warning" });
      }
    },
    [startLoading, stopLoading, id, t, getImlementationValue]
  );

  const getStatusValue = useCallback(
    (
      selectedRows: Row<{}>[],
      type: "notSelected" | "selected" | "declined"
    ) => {
      const statusValue = selectedRows.map((item) => {
        const { original } = item as any;
        return {
          id: original.id as string,
          choiceStatus: type,
        };
      });
      return statusValue;
    },
    []
  );

  const statusSelected: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "selected"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${id}/benefitPlanChoices`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue, id]
  );

  const statusDeclined: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "declined"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${id}/benefitPlanChoices`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue, id]
  );

  const statusNotSelected: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "notSelected"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${id}/benefitPlanChoices`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue, id]
  );

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      implementationDone,
      implementationPending,
      statusSelected,
      statusDeclined,
      statusNotSelected,
    }),
    [
      implementationDone,
      implementationPending,
      statusSelected,
      statusDeclined,
      statusNotSelected,
    ]
  );

  const rowMethods: MpRowActionMethods = useMemo(
    () => ({
      toggleImplementationDate,
    }),
    [toggleImplementationDate]
  );

  const exportToExcel = useCallback(() => {
    startLoading();
    const url = `${Endpoints.employee}/${id}/benefitPlanChoices/exportToExcel${
      !!query ? `?${query}` : ""
    }`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    return mutateData("get", url, null, headers, "arraybuffer")
      .then((res: any) => {
        const now = moment().locale(browserLanguage).format("L");
        const disposition = res?.headers["content-disposition"];
        const headersFilename = disposition?.split("filename=")?.[1];
        const filename = headersFilename ?? `Employee_choices_${now}.xlsx`;
        fileDownloader(res.data, filename);
      })
      .finally(() => {
        stopLoading();
      });
  }, [query, startLoading, stopLoading, id]);

  return {
    loading: loading || apiLoading,
    employeeChoices,
    employeeChoicesError,
    parsedEmployeeChoices,
    bulkMethods,
    rowMethods,
    parsedEmployeeChoicesTotals,
    exportToExcel,
  };
};

export default useEmployeeChoices;

export const useEmployeesChoicesAsyncMethods = (
  id: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () => `${Endpoints.employee}/${id}/benefitPlanChoices/filterValues`,
    [id]
  );

  const getFilterItems = useCallback(
    (filterName: ChoicesFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getBenefitPlanType = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(ChoicesFilterNames.BENEFIT_PLAN_TYPE),
    [getFilterItems]
  );

  const getBenefitPlanName = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(ChoicesFilterNames.BENEFIT_PLAN_NAME),
    [getFilterItems]
  );

  return {
    getBenefitPlanType,
    getBenefitPlanName,
  };
};
