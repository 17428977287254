import { AdminRoles } from "./Administrators";
import { MpControl } from "@mp-react/form";
import { StatusAction } from "./Status";
import { BenefitTypes } from "./Benefits";
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionLevels,
} from "../constants/Administrators";

export interface LinkToObject {
  pathname: string;
  search?: string;
  hash?: string;
  state?: Object;
}

export type LinkModule =
  | LocalPermissionModules
  | GlobalPermissionModules
  | Array<LocalPermissionModules | GlobalPermissionModules>;

export interface LinkItem {
  to: string | LinkToObject;
  title: string;
  badge?: string;
  links?: LinkItem[];
  roles: AdminRoles[];
  module?: LinkModule;
  permissionLevel?: PermissionLevels;
  matchAllModules?: boolean;
}

export interface RouteItem {
  component: React.FC;
  path: string;
  routes?: RouteItem[];
  public?: boolean;
  roles?: AdminRoles[];
  module?:
    | LocalPermissionModules
    | GlobalPermissionModules
    | Array<LocalPermissionModules | GlobalPermissionModules>;
  permissionLevel?: PermissionLevels;
}

export type Languages =
  | "EN"
  | "RU"
  | "PL"
  | "LT"
  | "LV"
  | "ET"
  | "NO"
  | "FR"
  | "SV"
  | "TK"
  | "BE"
  | "PT"
  | "BG"
  | "FI"
  | "KA"
  | "EL"
  | "DE"
  | "IT"
  | "SL"
  | "TR"
  | "UK"
  | "JA"
  | "ID"
  | "GA"
  | "RO"
  | "KO"
  | "SK"
  | "UZ"
  | "AU"
  | "NONE";

export interface Tab {
  component: React.FC;
  key: string | number;
  translationKey?: string;
  module?: LocalPermissionModules | GlobalPermissionModules;
}

export interface FormTab {
  key: string;
  label: string;
}

export interface FormActions {
  key: string | number;
  value: string;
  index: number;
  language: string;
}

export interface Status {
  name: StatusAction;
  date?: string;
  tz?: number;
}

export interface Timezone {
  value: number;
  label: string;
}

export interface SelectOption {
  name: string;
  value: string;
}

export interface TooltipConfig {
  text: string;
  width?: number;
}

export interface TooltipControl extends MpControl {
  tooltip?: string | TooltipConfig;
  sufleris?: string;
  children?: TooltipControl[];
}

export interface CustomFormControl extends TooltipControl {
  disabled?: boolean;
  readonly?: boolean;
  children?: CustomFormControl[];
  disablePast?: boolean;
  props?: Record<string, any>;
}

export interface UseTabs {
  currentTab: string;
  changeTab: (event: any, tab: string) => void;
}

export interface UseLoading {
  loading: boolean;
  stopLoading: () => void;
  startLoading: () => void;
}

export interface UseDialog {
  open: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

export interface DatepickerItemType {
  shortName: string;
  key: string;
}

export type SupportedLanguages =
  | "EN"
  | "LT"
  | "BE"
  | "BG"
  | "ET"
  | "FI"
  | "KA"
  | "EL"
  | "DE"
  | "SV"
  | "IT"
  | "SL"
  | "SK"
  | "LV"
  | "TR"
  | "UK"
  | "UZ"
  | "TK"
  | "JA"
  | "ID"
  | "PL"
  | "RU"
  | "NO"
  | "GA"
  | "KO"
  | "PT"
  | "RO";

export type DefaultCurrency = "EUR" | "USD" | "RUB" | "PLN";

export interface Conflict {
  id: string;
  fromDate: string;
  toDate: string;
  status: "off" | "on" | "flex";
  benefitPlanId: string;
  employeeGroupId: string;
  employeeGroup: {
    id: string;
    name: string;
    creationDate: string;
  };
}

export interface CheckAssignBaseResponse {
  totalInvestmentAmount: number;
  investmentAmountChange: number;
}

export interface CheckAssignConflictsResponse extends CheckAssignBaseResponse {
  conflicts: Conflict[];
}

export enum InvestmentTypes {
  employee = "employee",
  benefits = "benefits",
  groups = "groups",
}

export interface Investment {
  id: string;
  name: string;
  added: boolean;
  type: InvestmentTypes;
  benefitType?: BenefitTypes;
  employeeCount?: number;
  investment: number;
}

export interface LocationState {
  from?: string;
}

export enum BasicActivityStatus {
  active = "active",
  inactive = "inactive",
}

export interface Range<T> {
  from?: T | null;
  to?: T | null;
}

export type NumberRange = Range<number>;

/**
 * Type for extracting array element type.
 */
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
