import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { BorderRadius, Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    active: {
      position: "relative",
      "&::before": {
        content: "''",
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.6)",
        mixBlendMode: "overlay",
        borderRadius: BorderRadius.MainRadius,
      },
    },
    menuButton: {
      color: Colors.White,
      width: 58,
      borderRadius: BorderRadius.MainRadius,
      transition: theme.transitions.create("background-color"),
      "&:hover": {
        backgroundColor: Colors.HoverPrimary,
      },
    },
  })
);
