import Radio, { RadioProps } from "@material-ui/core/Radio";
import React from "react";
import useStyles from "./Radio.styles";
import { ReactComponent as ActiveRadio } from "../../../assets/icons/active-radio.svg";
import { ReactComponent as InactiveRadio } from "../../../assets/icons/inactive-radio.svg";

export default function MelpRadio(props: RadioProps) {
  const classes = useStyles();
  return (
    <Radio
      {...props}
      classes={{
        checked: classes.checked,
        disabled: classes.disabled,
        root: classes.root,
      }}
      checkedIcon={<ActiveRadio />}
      icon={<InactiveRadio className={classes.icon} />}
    />
  );
}
