import { useInvestmentCalculationsStore } from "./InvestmentCalculations";
import { useLoading } from "./../utils/Loading";
import { EmployeeAssignBenefitsTableItem } from "./../types/Employees";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR, { mutate } from "swr";
import { Endpoints } from "../api/constants";
import { EmployeeAssignBenefitsTable } from "../types/Employees";
import {
  AssignEmployeesRequest,
  ToggleAssignmentStatus,
} from "../types/Benefits";
import moment from "moment";
import { mutateData } from "../api/api";
import { toast } from "react-toastify";
import { useCurrency } from "../utils/useCurrency";
import { CheckAssignConflictsResponse } from "../types/Common";
import { MpAsyncGetMethod } from "@mp-react/table";
import { AxiosResponse } from "axios";
import { BenefitPlanAssignmentFilterNames } from "../constants/Employees";
import { useTableStore } from "./Table";
import { uniq } from "lodash";

export const useEmployeeAssignBenefits = (
  employeeId?: string,
  query?: string
) => {
  const { t } = useTranslation();
  const { statuses, resetStatusChanges, resetAllChanges } =
    useInvestmentCalculationsStore();
  const { startLoading, stopLoading, loading } = useLoading();
  const { getDefaultCurrencyFormat } = useCurrency();
  const searchQuery = useTableStore((state) => state.searchQuery);

  const url = useMemo(() => {
    if (!employeeId) return null;
    const endpoint = Endpoints.employeeBenefitPlanAssignments.replace(
      "{id}",
      employeeId
    );
    return `${endpoint}${!!query ? `?${query}` : ""}`;
  }, [employeeId, query]);
  const {
    data: assignBenefitsTable,
    error: assignBenefitsError,
    mutate: mutateAssignBenefitsTable,
  } = useSWR<EmployeeAssignBenefitsTable, any>(url);

  const filterBySearch = useCallback(
    (data: EmployeeAssignBenefitsTableItem[]) => {
      if (!searchQuery) return data;
      const search = searchQuery.toLowerCase();
      return data.filter((item) => {
        const localizedType = t(
          `menu.${item.type
            ?.replace(/([a-z0-9])([A-Z])/g, "$1_$2")
            .toLowerCase()}`
        ).toLowerCase();
        const name = item.name?.toLowerCase();
        return (
          localizedType?.includes(search) ||
          name?.includes(search) ||
          item.investment?.toString()?.includes(search)
        );
      });
    },
    [searchQuery, t]
  );

  const assignBenefitsData: EmployeeAssignBenefitsTableItem[] = useMemo(() => {
    const data =
      assignBenefitsTable?.data?.map((item) => ({
        ...item,
        inactive: item.currentStatus === "off",
        fullName: item.name,
      })) ?? [];
    if (data.length > 0) return filterBySearch(data);
    return [];
  }, [assignBenefitsTable?.data, filterBySearch]);

  const apiLoading = useMemo(
    () => !assignBenefitsTable && !assignBenefitsError,
    [assignBenefitsTable, assignBenefitsError]
  );

  const filteredFooter = useMemo(() => {
    if (!assignBenefitsData?.length || !searchQuery) return null;
    const names = assignBenefitsData?.map((item) => item.name);
    const name = uniq(names).length;
    const types = assignBenefitsData?.map((item) => item.type);
    const type = uniq(types).length;
    const investments = assignBenefitsData?.map((item) => item.investment);
    const investment = !!investments?.length
      ? investments.reduce((prev, curr) => Number(prev) + Number(curr))
      : 0;

    return {
      name: t("totals.benefit_plan", { count: Number(name) }),
      type: t("totals.benefit_type", { count: Number(type) }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
    };
  }, [assignBenefitsData, getDefaultCurrencyFormat, searchQuery, t]);

  const parsedEmployeeAssignBenefitsTotals = useMemo(() => {
    const initialTotals = {
      name: "",
      type: "",
      investment: "",
      currentStatus: "",
    };
    if (!assignBenefitsTable?.footer) return initialTotals;
    if (!!searchQuery && !!filteredFooter) return filteredFooter;
    const { name, investment, type, currentStatus } =
      assignBenefitsTable.footer;
    return {
      name: t("totals.benefit_plan", { count: Number(name) }),
      type: t("totals.benefit_type", { count: Number(type) }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
      currentStatus: `${currentStatus} ${t("status.off")}`,
    };
  }, [
    assignBenefitsTable,
    searchQuery,
    filteredFooter,
    t,
    getDefaultCurrencyFormat,
  ]);

  const mapAssignBenefitsRequest = useCallback(
    (date?: string): AssignEmployeesRequest[] => {
      const statusEntries: [string, ToggleAssignmentStatus][] = Object.entries(
        statuses?.currentStatus
      );
      const fromDate = date ?? moment().toISOString();
      return statusEntries.map(([benefitPlanId, statusObject]) => {
        const statusEntryObj: AssignEmployeesRequest = {
          benefitPlanId,
          fromDate,
          toDelete: false,
          employeeId: employeeId as string,
          status: statusObject?.value ?? "on",
        };
        const isToday = moment(fromDate).isSame(moment(), "day");
        if (!isToday) {
          const dateStatus = statusObject?.currentRowStatuses?.find((status) =>
            moment(status.fromDate).isSame(moment(fromDate), "day")
          );
          if (!!dateStatus) statusEntryObj.id = dateStatus?.id;
        }
        if (isToday && !!statusObject.assignmentId)
          statusEntryObj.id = statusObject.assignmentId;
        return statusEntryObj;
      });
    },
    [employeeId, statuses?.currentStatus]
  );

  const assignBenefits = useCallback(
    async (data: AssignEmployeesRequest[]) => {
      startLoading();
      await mutateData("post", Endpoints.assignEmployees, data)
        .then(() => {
          toast(t("common.assigned_succesfully"), { type: "success" });
          mutate(url);
        })
        .then(() => {
          resetStatusChanges();
          resetAllChanges();
        })
        .finally(() => stopLoading());
    },
    [startLoading, t, url, resetStatusChanges, resetAllChanges, stopLoading]
  );

  const checkAssignEmployeeChanges = useCallback(
    async ({
      newStatus,
      employeeId,
      benefitPlanId,
    }: {
      newStatus: "on" | "off" | "flex";
      employeeId: string;
      benefitPlanId: string;
    }): Promise<CheckAssignConflictsResponse> => {
      return mutateData("post", Endpoints.checkEmployeeToBenefitPlan, {
        benefitPlanId,
        employeeId,
        newStatus,
      }).then((res) => res.data as CheckAssignConflictsResponse);
    },
    []
  );

  return {
    loading: (!!employeeId && apiLoading) || loading,
    assignBenefitsData,
    assignBenefits,
    assignBenefitsTable,
    mapAssignBenefitsRequest,
    parsedEmployeeAssignBenefitsTotals,
    checkAssignEmployeeChanges,
    mutateAssignBenefitsTable,
  };
};

export const useEmployeeAssignBenefitsAsyncMethods = (
  employeeId: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () =>
      Endpoints.employeeBenefitPlanAssignmentsFilterValues.replace(
        "{id}",
        employeeId
      ),
    [employeeId]
  );

  const getFilterItems = useCallback(
    (filterName: BenefitPlanAssignmentFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getBenefitPlanName = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitPlanAssignmentFilterNames.NAME),
    [getFilterItems]
  );

  const getBenefitPlanType = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(BenefitPlanAssignmentFilterNames.TYPE),
    [getFilterItems]
  );

  return {
    getBenefitPlanName,
    getBenefitPlanType,
  };
};
