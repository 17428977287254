import { CheckAssignBaseResponse } from "./../types/Common";
import { useInvestmentCalculationsStore } from "./InvestmentCalculations";
import { GroupsAssignBenefitsTable } from "./../types/Groups";
import { useCallback, useMemo } from "react";
import useSWR, { mutate } from "swr";
import { Endpoints } from "../api/constants";
import { useTranslation } from "react-i18next";
import { useLoading } from "../utils/Loading";
import moment from "moment";
import { mutateData } from "../api/api";
import { toast } from "react-toastify";
import {
  AssignToEmployeeGroupRequest,
  ToggleAssignmentStatus,
} from "../types/Benefits";
import { useCurrency } from "../utils/useCurrency";
import { MpAsyncGetMethod } from "@mp-react/table";
import { AxiosResponse } from "axios";
import { CompanyGroupBenefitAssignmentFilterNames } from "../constants/CompanyGroups";

export const useGroupsAssignBenefits = (
  employeeGroupId?: string,
  query?: string
) => {
  const { t } = useTranslation();
  const { statuses, resetStatusChanges, resetAllChanges } =
    useInvestmentCalculationsStore();
  const { startLoading, stopLoading, loading } = useLoading();
  const { getDefaultCurrencyFormat } = useCurrency();

  const url = useMemo(() => {
    if (!!employeeGroupId) {
      return !!query
        ? `${Endpoints.employeeGroup}/${employeeGroupId}/benefitPlanAssignments?${query}`
        : `${Endpoints.employeeGroup}/${employeeGroupId}/benefitPlanAssignments`;
    }
    return null;
  }, [employeeGroupId, query]);

  const { data: groupsAssignBenefitsTable, error: groupAssignBenefitsError } =
    useSWR<GroupsAssignBenefitsTable, any>(url);

  const apiLoading = useMemo(
    () => !groupsAssignBenefitsTable && !groupAssignBenefitsError,
    [groupAssignBenefitsError, groupsAssignBenefitsTable]
  );

  const groupsAssignBenefitsData = useMemo(
    () =>
      groupsAssignBenefitsTable?.data?.map((item) => ({
        ...item,
        inactive: item.currentStatus === "off",
        fullName: item.name,
      })) ?? [],
    [groupsAssignBenefitsTable?.data]
  );

  const parsedGroupAssignBenefitsTotals = useMemo(() => {
    const initialTotals = {
      name: "",
      type: "",
      investment: "",
      currentStatus: "",
    };
    if (!groupsAssignBenefitsTable?.footer) return initialTotals;
    const { name, investment, type, currentStatus } =
      groupsAssignBenefitsTable.footer;
    return {
      name: `${name} ${t(`benefits.${Number(name) > 1 ? "plans" : "plan"}`)}`,
      type: `${type} ${t(
        `benefits.${Number(type) > 1 ? "benefit_types" : "benefit_type"}`
      )}`,
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
      currentStatus: `${currentStatus} ${t("status.off")}`,
    };
  }, [groupsAssignBenefitsTable, t, getDefaultCurrencyFormat]);

  const mapGroupAssignBenefitsRequest = useCallback(
    (date?: string): AssignToEmployeeGroupRequest[] => {
      const statusEntries: [string, ToggleAssignmentStatus][] = Object.entries(
        statuses?.currentStatus
      );
      const fromDate = date ?? moment().toISOString();
      return statusEntries.map(([benefitPlanId, statusObject]) => {
        const statusEntryObj: AssignToEmployeeGroupRequest = {
          benefitPlanId,
          fromDate,
          toDelete: false,
          employeeGroupId: employeeGroupId as string,
          status: statusObject?.value ?? "on",
        };
        const isToday = moment(fromDate).isSame(moment(), "day");
        if (!isToday) {
          const dateStatus = statusObject?.currentRowStatuses?.find((status) =>
            moment(status.fromDate).isSame(moment(fromDate), "day")
          );
          if (!!dateStatus) statusEntryObj.id = dateStatus?.id;
        }
        if (isToday && !!statusObject.assignmentId)
          statusEntryObj.id = statusObject.assignmentId;
        return statusEntryObj;
      });
    },
    [employeeGroupId, statuses?.currentStatus]
  );

  const assignBenefitsToGroup = useCallback(
    async (data: AssignToEmployeeGroupRequest[]) => {
      startLoading();
      await mutateData("post", Endpoints.assignEmployeeGroups, data)
        .then(() => {
          toast(t("common.assigned_succesfully"), { type: "success" });
          mutate(url);
        })
        .then(() => {
          resetStatusChanges();
          resetAllChanges();
        })
        .finally(() => stopLoading());
    },
    [resetStatusChanges, resetAllChanges, startLoading, stopLoading, t, url]
  );

  const checkGroupToBenefitChanges = useCallback(
    async ({
      newStatus,
      employeeGroupId,
      benefitPlanId,
    }: {
      newStatus: "on" | "off" | "flex";
      employeeGroupId: string;
      benefitPlanId: string;
    }): Promise<CheckAssignBaseResponse> => {
      return mutateData("post", Endpoints.checkEmployeeGroupToBenefitPlan, {
        benefitPlanId,
        employeeGroupId,
        newStatus,
      }).then((res) => res.data as CheckAssignBaseResponse);
    },
    []
  );

  return {
    loading: (!!employeeGroupId && apiLoading) || loading,
    groupsAssignBenefitsData,
    groupsAssignBenefitsTable,
    mapGroupAssignBenefitsRequest,
    assignBenefitsToGroup,
    parsedGroupAssignBenefitsTotals,
    checkGroupToBenefitChanges,
  };
};

export const useEmployeeGroupsBenefitAssignmentAsyncMethods = (
  groupId: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () =>
      Endpoints.employeeGroupsBenefitPlanAssignmentsFilterValues.replace(
        "{id}",
        groupId
      ),
    [groupId]
  );

  const getFilterItems = useCallback(
    (filterName: CompanyGroupBenefitAssignmentFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getBenefitPlanName = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(CompanyGroupBenefitAssignmentFilterNames.NAME),
    [getFilterItems]
  );

  const getBenefitPlanType = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(CompanyGroupBenefitAssignmentFilterNames.TYPE),
    [getFilterItems]
  );

  return {
    getBenefitPlanName,
    getBenefitPlanType,
  };
};
