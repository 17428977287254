import { mutateData } from "./../api/api";
import { toast } from "react-toastify";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR, { mutate } from "swr";
import create from "zustand";
import { Endpoints } from "../api/constants";
import {
  Recipient,
  RecipientsRequestItem,
  RecipientsResponse,
  UseNewsRecipientsStore,
  AssignRecipientsResponse,
} from "../types/Recipients";
import { useLoading } from "../utils/Loading";
import { MpAsyncGetMethod } from "@mp-react/table";
import { AxiosResponse } from "axios";
import { RecipientsFilterNames } from "../constants/Recipients";

export const useNewsRecipients = (id?: string, query?: string) => {
  const { t } = useTranslation();
  const { loading, startLoading, stopLoading } = useLoading();

  const resetChanges = useNewsRecipientsStore((state) => state.resetChanges);
  const recipientsRequest = useNewsRecipientsStore(
    (state) => state.recipientRequest
  );
  const url = !!id
    ? `${Endpoints.recipients}/${id}/recipients${!!query ? `?${query}` : ""}`
    : null;
  const { data: recipientsResponse, error: recipientsError } = useSWR<
    RecipientsResponse,
    any
  >(url);

  const apiLoading = useMemo<boolean>(
    () => !recipientsError && !recipientsResponse,
    [recipientsError, recipientsResponse]
  );

  const pageSize = useMemo<number>(
    () => recipientsResponse?.pageSize ?? 0,
    [recipientsResponse?.pageSize]
  );

  const count = useMemo<number>(
    () => recipientsResponse?.count ?? 0,
    [recipientsResponse?.count]
  );

  const recipients = useMemo<Recipient[]>(() => {
    if (!recipientsResponse) return [];

    return recipientsResponse?.data.map((recipient) => ({
      ...recipient,
      inactive: recipient.status === "off",
    }));
  }, [recipientsResponse]);

  const resetTable = useCallback(() => {
    resetChanges();
  }, [resetChanges]);

  const updateRecipients = useCallback(async () => {
    startLoading();
    const updateRecipientsUrl = !!id
      ? Endpoints.assignRecipients.replace("{id}", id)
      : "";
    await mutateData<AssignRecipientsResponse>(
      "post",
      updateRecipientsUrl,
      recipientsRequest
    )
      .then((res) => {
        const warnings = res.data.warnings;
        if (!!warnings?.length) {
          warnings.forEach((warning) => {
            toast.warning(warning.message);
          });
        } else if (!!res.data?.result)
          toast.success(t("common.updated_succesfully"));
        mutate(url);
      })
      .then(() => resetTable())
      .finally(() => stopLoading());
  }, [recipientsRequest, resetTable, startLoading, stopLoading, t, url, id]);

  return {
    recipients,
    apiLoading,
    count,
    pageSize,
    resetTable,
    updateRecipients,
    loading,
  };
};

export const useNewsRecipientsStore = create<UseNewsRecipientsStore>((set) => ({
  recipientsData: null,
  setRecipientsData: (data) => set(() => ({ recipientsData: data })),
  changedRowIds: [],
  changedRowCount: 0,
  recipientRequest: [],
  setRecipientRequest: ({ row }) => {
    if (!row) return;

    const { row: dataRow, rowId, value: statusBoolean } = row;
    const recipient = dataRow?.original as Recipient;
    if (!recipient) return;

    const employeeGroupId = rowId as string;
    const companyId = recipient.companyId;
    const companyGroupId = recipient.companyGroupId;
    const status = statusBoolean ? "on" : "off";
    const recipientRequestItem: RecipientsRequestItem = {
      companyGroupId,
      companyId,
      employeeGroupId,
      status,
    };

    set(({ recipientRequest }) => {
      const existingUnit = recipientRequest?.find((recipient) => {
        const isSameEmployeeGroup =
          recipient.employeeGroupId === employeeGroupId;
        const isSameCompany =
          !!recipient.companyId || !!companyId
            ? recipient.companyId === companyId
            : true;
        const isSameCompanyGroup =
          !!recipient.companyGroupId || !!companyGroupId
            ? recipient.companyGroupId === companyGroupId
            : true;
        return isSameEmployeeGroup && isSameCompany && isSameCompanyGroup;
      });
      const isChanged = !!existingUnit;
      if (isChanged) {
        const filteredRecipientRequest = recipientRequest.filter(
          (recipient) => existingUnit !== recipient
        );
        return {
          changedRowCount: filteredRecipientRequest.length,
          recipientRequest: filteredRecipientRequest,
        };
      }

      const newChangedRows = [...recipientRequest, recipientRequestItem];
      return {
        recipientRequest: newChangedRows,
        changedRowCount: newChangedRows.length,
      };
    });
  },
  resetChanges: () =>
    set(() => {
      return {
        recipientRequest: [],
        changedRowIds: [],
        changedRowCount: 0,
      };
    }),
  setAllRecipients: (recipients) =>
    set(() => {
      const recipientsIds = recipients?.map((item) => item.employeeGroupId);
      const recipientsCount = recipientsIds?.length;
      return {
        recipientRequest: recipients,
        changedRowIds: recipientsIds,
        changedRowCount: recipientsCount,
      };
    }),
}));

export const useNewsRecipientsAsyncMethods = (
  newsId: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () => `${Endpoints.recipients}/${newsId}/recipients/filterValues`,
    [newsId]
  );

  const getFilterItems = useCallback(
    (filterName: RecipientsFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getNames = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(RecipientsFilterNames.NAME),
    [getFilterItems]
  );

  return {
    getNames,
  };
};
