import { useTranslation } from "react-i18next";
import { useLoading } from "../utils/Loading";
import { Endpoints } from "../api/constants";
import useSWR from "swr";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { mutateData } from "../api/api";
import { toast } from "react-toastify";
import { browserLanguage } from "../utils/Common";
import {
  CreateNews,
  UpdateNews,
  UseNews,
  News,
  NewsDetailsResponse,
} from "../types/News";
import { useHistory } from "react-router";
import { parseResponseData, parseStatusDates } from "../utils/News";
import { AxiosResponse } from "axios";
import { useMe } from "./Administrators";
import fileDownloader from "js-file-download";

const useNews = (id?: string, query?: string): UseNews => {
  const { hasNewsPermissions } = useMe();
  const {
    data: newsList,
    error: newsListError,
    mutate: mutateNewsList,
  } = useSWR(
    hasNewsPermissions && !id
      ? `${Endpoints.newsList}${!!query ? `?${query}` : ""}`
      : null
  );
  const {
    data: newsDetails,
    error: newsDetailsError,
    mutate: mutateNewsDetails,
  } = useSWR(hasNewsPermissions && id ? `${Endpoints.newsList}/${id}` : null, {
    revalidateOnFocus: false,
  });
  const { startLoading, stopLoading, loading } = useLoading();
  const { t } = useTranslation();
  const history = useHistory();

  const apiLoading = useMemo(() => {
    if (!!id) return !newsDetails && !newsDetailsError;
    return !newsList && !newsListError;
  }, [id, newsList, newsListError, newsDetails, newsDetailsError]);

  const parsedNewsList = useMemo(
    () =>
      newsList?.data?.map((data: News) => ({
        ...data,
        inactive: data.status === "inactive",
      })) ?? [],
    [newsList]
  );

  const parsedNewsDetails = useMemo(
    () => parseResponseData(newsDetails),
    [newsDetails]
  );

  const parsedNewsStatus = useMemo(
    () => parseStatusDates(newsDetails),
    [newsDetails]
  );

  const parsedNewsTotals = useMemo(() => {
    return newsList
      ? {
          name: t("totals.news", { count: Number(newsList.footer?.name) }),
          sentTo: t("totals.sent_to", {
            count: Number(newsList.footer?.sentTo),
          }),
          seen: t("totals.seen_on_app", {
            count: Number(newsList.footer?.seen),
          }),
        }
      : null;
  }, [newsList, t]);

  const createNews = useCallback(
    (data: CreateNews) => {
      startLoading();
      mutateData("post", Endpoints.createNews, data)
        .then((res: AxiosResponse<NewsDetailsResponse>) => {
          toast(t("common.added_succesfully"), { type: "success" });
          mutateNewsList();
          mutateNewsDetails();
          const newsId = res.data.id;
          history.push(`/news/view/${newsId}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, mutateNewsDetails, mutateNewsList, startLoading, stopLoading, t]
  );

  const updateNews = useCallback(
    async (data: UpdateNews) => {
      startLoading();
      const resData = await mutateData(
        "patch",
        `${Endpoints.newsList}/${id}`,
        data
      )
        .then((res: AxiosResponse<NewsDetailsResponse>) => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutateNewsList();
          mutateNewsDetails();
          return res.data;
        })
        .finally(() => {
          stopLoading();
        });
      return resData;
    },
    [id, mutateNewsDetails, mutateNewsList, startLoading, stopLoading, t]
  );

  const deleteNews = useCallback(() => {
    startLoading();
    mutateData("delete", `${Endpoints.newsList}/${id}`)
      .then((res: any) => {
        toast(t("common.deleted_succesfully"), { type: "success" });
        mutateNewsList();
        history.push("/news");
      })
      .finally(() => {
        stopLoading();
      });
  }, [history, id, mutateNewsList, startLoading, stopLoading, t]);

  const copyNews = useCallback(
    (name: string) => {
      startLoading();
      mutateData("post", `${Endpoints.newsList}/${id}/copy?name=${name}`)
        .then((res: any) => {
          toast(t("common.added_succesfully"), { type: "success" });
          mutateNewsList();
          history.push(`/news/view/${res?.data?.id}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, id, mutateNewsList, startLoading, stopLoading, t]
  );

  const exportToExcel = useCallback(() => {
    startLoading();
    const url = `${Endpoints.newsExportToExcel}${!!query ? `?${query}` : ""}`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    return mutateData("get", url, null, headers, "arraybuffer")
      .then((res: any) => {
        const now = moment().locale(browserLanguage).format("L");
        const disposition = res?.headers["content-disposition"];
        const headersFilename = disposition?.split("filename=")?.[1];
        const filename = headersFilename ?? `News_${now}.xlsx`;
        fileDownloader(res.data, filename);
      })
      .finally(() => {
        stopLoading();
      });
  }, [query, startLoading, stopLoading]);

  return {
    newsList: parsedNewsList,
    newsTotals: parsedNewsTotals,
    newsDetails,
    parsedNewsDetails,
    newsDetailsError,
    apiLoading,
    loading,
    createNews,
    updateNews,
    deleteNews,
    copyNews,
    status: parsedNewsStatus,
    exportToExcel,
  };
};

export default useNews;
