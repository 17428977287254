import { useCallback } from "react";
import { Administrator } from "../types/Administrators";

export const useAdminUtils = () => {
  const parseAdministratorRequest = useCallback(
    (data: Partial<Administrator>): Partial<Administrator> => {
      const phone =
        (data.phone?.charAt(0) ?? "+") === "+" ? data.phone : `+${data.phone}`;
      const email = data?.email?.toLowerCase();
      return {
        ...data,
        email,
        phone,
      };
    },
    []
  );

  return { parseAdministratorRequest };
};
