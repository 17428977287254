import React, { useCallback, useMemo } from "react";
import { MenuList, MenuItem, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./FooterTooltipContent.styles";
import { truncate } from "../../../utils/Common";
import { useCurrency } from "../../../utils/useCurrency";
import { useInvestmentCalculationsStore } from "../../../state/InvestmentCalculations";
import { BenefitTypes } from "../../../types/Benefits";
import { InvestmentTypes } from "../../../types/Common";

interface PartialInvestmentObject {
  name: string;
  added: boolean;
  type: InvestmentTypes;
  benefitType?: BenefitTypes | undefined;
  employeeCount?: number;
}

export default function FooterTooltipContent() {
  const { t } = useTranslation();
  const classes = useStyles();

  const totalInvestment = useInvestmentCalculationsStore(
    (state) => state.totalInvestment
  );
  const calculatedInvestment = useInvestmentCalculationsStore(
    (state) => state.calculatedInvestment
  );
  const calculatedInvestmentPercentage = useInvestmentCalculationsStore(
    (state) => state.calculatedInvestmentPercentage
  );
  const investments = useInvestmentCalculationsStore(
    (state) => state.investments
  );

  const { getDefaultCurrencyFormat } = useCurrency();

  const formattedTotalInvestment = useMemo(
    () => getDefaultCurrencyFormat(totalInvestment),
    [getDefaultCurrencyFormat, totalInvestment]
  );

  const formattedCalculatedInvestment = useMemo(() => {
    const parsedInvestment =
      calculatedInvestment % 1 === 0
        ? calculatedInvestment
        : calculatedInvestment.toFixed(2);
    const formattedInvestment = getDefaultCurrencyFormat(parsedInvestment);
    return formattedInvestment;
  }, [getDefaultCurrencyFormat, calculatedInvestment]);

  const getInvestmentString = useCallback(
    (investment: number) => {
      const investmentAbs = Math.abs(investment);
      const parsedInvestmentAbs =
        investmentAbs % 1 === 0 ? investmentAbs : investmentAbs.toFixed(2);
      const formattedInvestment = getDefaultCurrencyFormat(parsedInvestmentAbs);
      const symbol = investment < 0 ? "-" : "+";
      const investmentString = `${symbol} ${formattedInvestment}`;
      return investmentString;
    },
    [getDefaultCurrencyFormat]
  );

  const getInvestmentClass = useCallback(
    (investment: number) => (investment < 0 ? classes.red : classes.green),
    [classes.green, classes.red]
  );

  const getInvestmentNameComponent = useCallback(
    (investment: PartialInvestmentObject): JSX.Element => {
      const { type, name, added } = investment;
      // TODO: Implement later
      const benefitType = t(
        `menu.${(investment?.benefitType ?? "")
          .replace(/([a-z0-9])([A-Z])/g, "$1_$2")
          .toLowerCase()}`
      );
      // const employeeCount = investment?.employeeCount ?? 0;

      let investmentNameString = "";

      const addedStringEmployee = added
        ? t("assign_employees.employee_was_added")
        : t("assign_employees.employee_was_removed");

      switch (type) {
        case InvestmentTypes.employee:
          investmentNameString = addedStringEmployee?.replace("{name}", name);
          break;
        case InvestmentTypes.benefits:
          investmentNameString = t(
            added
              ? "tooltip.benefit_plan_added"
              : "tooltip.benefit_plan_removed"
          )
            ?.replace("{benefitType}", benefitType)
            ?.replace("{benefitName}", truncate(name, 25));
          break;
        case InvestmentTypes.groups:
          investmentNameString = t(
            added ? "tooltip.group_added" : "tooltip.group_removed"
          )?.replace("{name}", truncate(name, 25));
          break;
        default:
          investmentNameString = name;
          break;
      }

      return (
        <Typography color="textSecondary" className={classes.descriptionText}>
          {investmentNameString}
        </Typography>
      );
    },
    [classes.descriptionText, t]
  );

  const TooltipContent = useMemo(
    () =>
      investments?.map(({ id, investment, ...rest }) => {
        const investmentString = getInvestmentString(investment);
        const investmentClass = getInvestmentClass(investment);
        const investmentNameComponent = getInvestmentNameComponent(rest);

        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            key={`tooltip-${id}`}
            marginTop="5px"
          >
            <Box paddingRight="20">{investmentNameComponent}</Box>
            <Typography color="textPrimary" className={investmentClass}>
              {investmentString}
            </Typography>
          </Box>
        );
      }),
    [
      getInvestmentClass,
      getInvestmentNameComponent,
      getInvestmentString,
      investments,
    ]
  );

  return (
    <Box>
      <MenuList className={classes.menuList}>
        <MenuItem>
          <Typography variant="subtitle1" color="textPrimary">
            {t("benefits.expected_changes")}
          </Typography>
        </MenuItem>
        <MenuItem>
          <Box width={1}>
            <Box display="flex" justifyContent="space-between">
              <Typography color="textSecondary">
                {t("benefits.current_cost")}
              </Typography>
              <Typography color="textPrimary">
                {formattedTotalInvestment}
              </Typography>
            </Box>
            {TooltipContent}
          </Box>
        </MenuItem>
        <MenuItem>
          <Box width={1} display="flex" justifyContent="space-between">
            <Typography variant="subtitle1" color="textPrimary">
              {`${t("benefits.new_cost")}: ${calculatedInvestmentPercentage}`}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {formattedCalculatedInvestment}
            </Typography>
          </Box>
        </MenuItem>
      </MenuList>
    </Box>
  );
}
