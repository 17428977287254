import React from "react";
import {
  CardContent as MuiCardContent,
  CardContentProps,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 15px 0",
    flexGrow: 1,
  },
}));

interface Props extends CardContentProps {}

const CardContent = (props: Props) => {
  const classes = useStyles();
  return (
    <MuiCardContent
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  );
};

export default CardContent;
