import { Box } from "@material-ui/core";
import React from "react";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";

export default function AssignGroupsHeader() {
  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      <TableDatepicker ml="20px" />
    </Box>
  );
}
