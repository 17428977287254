import { Box } from "@material-ui/core";
import React from "react";
import { useTermsAcceptance } from "../../../../state/TermsAcceptance";
import AddBenefits from "../../../../components/common/AddBenefits/AddBenefits";
// import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import DownloadExcelButton from "../../../table/DownloadExcelButton/DownloadExcelButton";
import useTableUtils from "../../../../utils/Table";

export default function TermsAcceptanceHeader() {
  const { tableQueryParam } = useTableUtils("termsAcceptance");
  const { loading, termsAcceptance, exportToExcel } =
    useTermsAcceptance(tableQueryParam);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <DownloadExcelButton
        listLength={termsAcceptance?.count}
        loading={loading}
        exportToExcel={exportToExcel}
        marginRight="20px"
      />
      {/* <TableDatepicker marginRight="20px" /> */}
      <AddBenefits label="Add benefit" />
    </Box>
  );
}
