import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogProps,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import useStyles from "./FormDialog.styles";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MpForm, MpFormType } from "@mp-react/form";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useFormUtils } from "../../../utils/Form";
import clsx from "clsx";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps<D> extends DialogProps {
  actionLabel: string | JSX.Element;
  onCancelClick: () => void;
  onSubmitForm: SubmitHandler<D>;
  id: string;
  title: string;
  form: MpFormType | ((methods: ReturnType<typeof useForm>) => MpFormType);
  loading: boolean;
  additionalError?: string;
  defaultValues?: Record<string, any>;
  subtitle?: string | React.ReactNode;
  disabled?: boolean;
  altCancelText?: string;
}

const FormDialog = <D extends {}>({
  actionLabel,
  onCancelClick,
  onSubmitForm,
  id,
  title,
  form,
  loading,
  additionalError,
  defaultValues,
  subtitle,
  disabled,
  altCancelText,
  ...rest
}: IProps<D>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { overridables } = useFormUtils();
  const useFormMethods = useForm();
  const { handleSubmit, clearErrors, reset, formState } = useFormMethods;

  const hasChanges = useMemo(() => formState.isDirty, [formState.isDirty]);

  useEffect(() => {
    if (!!defaultValues) reset(defaultValues);
  }, [defaultValues, reset]);

  const handleError: SubmitErrorHandler<Record<string, any>> =
    useCallback(() => {}, []);

  const handleCancelClick = useCallback(() => {
    clearErrors();
    onCancelClick();
  }, [clearErrors, onCancelClick]);

  const handleClose = useCallback(
    (e) => {
      if (hasChanges) {
        if (window.confirm(t("errors.user_leaving_edited_page"))) {
          handleCancelClick();
        }
      } else {
        handleCancelClick();
      }
    },
    [hasChanges, handleCancelClick, t]
  );

  const submitData = useCallback(() => {
    handleSubmit(onSubmitForm, handleError)();
  }, [handleSubmit, onSubmitForm, handleError]);

  const ButtonLabel = useMemo(() => {
    if (loading)
      return (
        <>
          <CircularProgress size={20} classes={{ root: classes.loader }} />
          {actionLabel}
        </>
      );
    return actionLabel;
  }, [loading, classes.loader, actionLabel]);

  return (
    <FormProvider {...useFormMethods}>
      <DialogExtended
        classes={{ paperWidthSm: classes.root }}
        aria-labelledby={id}
        onClose={handleClose}
        {...rest}
      >
        <Typography gutterBottom={!!subtitle} variant="h5" id={id}>
          {title}
        </Typography>
        {!!subtitle && (
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        )}
        <DialogContent
          classes={{
            root: clsx(classes.dialogContentRoot, {
              [classes.inputsDisable]: disabled,
            }),
          }}
        >
          <MpForm
            overridables={overridables}
            {...(typeof form === "function" ? form(useFormMethods) : form)}
            useFormMethods={useFormMethods as any}
          />
          {!!additionalError && (
            <FormHelperText className={classes.additionalError} error>
              {additionalError}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActionsRoot }}>
          <Button onClick={handleClose} variant="text">
            {altCancelText ?? t("common.cancel")}
          </Button>
          <Button
            onClick={submitData}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {ButtonLabel}
          </Button>
        </DialogActions>
      </DialogExtended>
    </FormProvider>
  );
};

export default FormDialog;
