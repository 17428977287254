import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./StatusDialog.styles";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import StatusDialogContent from "./StatusDialogContent/StatusDialogContent";
import {
  AssignToEmployeeGroupRequest,
  AssignEmployeesRequest,
} from "../../../types/Benefits";
import { v4 } from "uuid";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps extends DialogProps {
  actionLabel: string | JSX.Element;
  onCancelClick: () => void;
  onSubmitForm: SubmitHandler<{
    statuses: Array<AssignToEmployeeGroupRequest | AssignEmployeesRequest>;
  }>;
  id: string;
  title: string;
  defaultValues: Record<string, any>;
  disabled?: boolean;
  loading?: boolean;
}

const StatusDialog = ({
  actionLabel,
  onCancelClick,
  onSubmitForm,
  id,
  title,
  defaultValues,
  disabled,
  loading,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const useFormMethods = useForm({
    defaultValues,
  });
  const { control, handleSubmit, reset, clearErrors } = useFormMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "statuses",
    keyName: "statuses.id",
  });

  useEffect(() => {
    if (!!defaultValues) reset(defaultValues);
  }, [defaultValues, reset]);

  const handleError: SubmitErrorHandler<Record<string, any>> =
    useCallback(() => {}, []);

  const handleClose = useCallback(() => {
    clearErrors();
    onCancelClick();
    if (!!defaultValues) reset(defaultValues);
  }, [clearErrors, defaultValues, onCancelClick, reset]);

  const submitData = useCallback(() => {
    handleSubmit(onSubmitForm, handleError)();
  }, [handleSubmit, onSubmitForm, handleError]);

  const addFormHandler = useCallback(
    () =>
      append({
        status: "on",
        id: `newItem-${v4()}`,
      }),
    [append]
  );

  return (
    <FormProvider {...useFormMethods}>
      <DialogExtended
        classes={{ paperWidthSm: classes.root }}
        aria-labelledby={id}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        onEnterPress={() => !disabled && submitData()}
        {...rest}
      >
        <Box px="30px" pt="30px">
          <Typography variant="h5" id={id}>
            {title}
          </Typography>
        </Box>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <Grid container spacing={2} className={classes.formTitles}>
            <Grid item xs={3} style={{ maxWidth: 80 }}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("common.status")}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("common.starts_from")}
              </Typography>
            </Grid>
          </Grid>
          <StatusDialogContent
            fields={fields}
            remove={remove}
            control={control}
            defaultValues={defaultValues}
            disabled={disabled}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActionsRoot }}>
          <Box paddingTop={2}>
            <Button
              color="primary"
              onClick={addFormHandler}
              className={classes.addStatusBtn}
              disabled={disabled}
            >
              <Typography variant="body2">
                + {t("buttons.add_status")}
              </Typography>
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            marginTop="20px"
          >
            <Button onClick={handleClose} variant="text">
              {t("common.cancel")}
            </Button>
            <Box position="relative">
              <Button
                onClick={submitData}
                variant="contained"
                color="secondary"
                disabled={disabled}
                style={{
                  marginLeft: 20,
                  color: loading ? "transparent" : "white",
                }}
              >
                {actionLabel}
              </Button>
              {loading && (
                <CircularProgress
                  size={20}
                  className={classes.progressIndicator}
                />
              )}
            </Box>
          </Box>
        </DialogActions>
      </DialogExtended>
    </FormProvider>
  );
};

export default StatusDialog;
