import { MpBreadcrumbFilterData } from "@mp-react/table";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PermissionLevels } from "../../../constants/Administrators";
import SidebarDark from "../../common/SidebarDark/SidebarDark";
import PermissionListItem from "./PermissionListItem";
import useStyles from "./PermissionList.styles";

interface Props {
  tree?: MpBreadcrumbFilterData;
  allSelectionId?: string;
  onClick: (level: PermissionLevels, id: string) => void;
  level: PermissionLevels;
  selectedId?: string;
  disabled: boolean;
}

export default function PermissionList({
  tree,
  allSelectionId,
  level,
  onClick,
  selectedId,
  disabled,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const ungroupedCompanies = useMemo(
    () =>
      (tree?.company_groups as MpBreadcrumbFilterData[])?.filter(
        (company) => company.id === "ungrouped"
      )?.[0],
    [tree?.company_groups]
  );

  const filteredOutUngroupedCompanies = useMemo(
    () =>
      (tree?.company_groups as MpBreadcrumbFilterData[])?.filter(
        (company) => company.id !== "ungrouped"
      ),
    [tree?.company_groups]
  );

  const handleListItemClick = useCallback(
    (id: string) => onClick(level, id),
    [level, onClick]
  );

  const UngroupedCompaniesList = useMemo(() => {
    return (
      !!ungroupedCompanies && (
        <SidebarDark>
          {(ungroupedCompanies.companies as MpBreadcrumbFilterData[]).map(
            (company: MpBreadcrumbFilterData) => (
              <PermissionListItem
                id={company.id as string}
                key={company.id}
                label={company.name as string}
                onClick={() =>
                  handleListItemClick(`company-${company.id}` ?? "")
                }
                disabled={disabled}
                isActive={selectedId === `company-${company.id}`}
                className={classes.companyListItemRoot}
              />
            )
          )}
        </SidebarDark>
      )
    );
  }, [
    classes.companyListItemRoot,
    disabled,
    handleListItemClick,
    selectedId,
    ungroupedCompanies,
  ]);

  const itemMap = useCallback(
    (items: MpBreadcrumbFilterData[]) => {
      return items.map((item) => {
        return (
          <PermissionListItem
            id={item.id as string}
            key={item.id}
            label={item.name as string}
            onClick={() => handleListItemClick(`companyGroup-${item.id}` ?? "")}
            isActive={selectedId === `companyGroup-${item.id}`}
            disabled={disabled}
          >
            {!!item?.companies && (
              <SidebarDark>
                {(item.companies as MpBreadcrumbFilterData[]).map(
                  (company: MpBreadcrumbFilterData) => (
                    <PermissionListItem
                      id={company.id as string}
                      key={company.id}
                      label={company.name as string}
                      onClick={() =>
                        handleListItemClick(`company-${company.id}` ?? "")
                      }
                      disabled={disabled}
                      isActive={selectedId === `company-${company.id}`}
                      className={classes.companyListItemRoot}
                    />
                  )
                )}
              </SidebarDark>
            )}
          </PermissionListItem>
        );
      });
    },
    [classes.companyListItemRoot, disabled, handleListItemClick, selectedId]
  );

  return (
    <SidebarDark>
      {!!allSelectionId && (
        <PermissionListItem
          onClick={handleListItemClick}
          id={allSelectionId}
          label={t("common.all")}
          isActive={selectedId === allSelectionId}
          disabled={disabled}
          className={classes.selectedWithoutBg}
        >
          {UngroupedCompaniesList}
        </PermissionListItem>
      )}
      {!!tree?.company_groups &&
        itemMap(filteredOutUngroupedCompanies as MpBreadcrumbFilterData[])}
    </SidebarDark>
  );
}
