import React, {
  createContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react";
import TabView from "../../../components/common/TabView/TabView";
import SidebarLayout from "../../../components/layouts/Sidebar/SidebarLayout";
import SidebarContainer from "../../../components/layouts/Sidebar/SidebarContainer/SidebarContainer";
import ContentContainer from "../../../components/layouts/Sidebar/SidebarContent/SidebarContent";
import {
  useEmployeeTabs,
  useEmployeeSidebarActions,
} from "../../../constants/Employees";
import SidebarInner from "../../../components/layouts/Sidebar/SidebarInner/SidebarInner";
import Title from "../../../components/sidebar/Title/Title";
import Tabs from "../../../components/sidebar/Tabs/Tabs";
import Tab from "../../../components/sidebar/Tab/Tab";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Actions from "../../../components/sidebar/Actions/Actions";
import useTabs from "../../../utils/Tabs";
import { useEmployees, useEmployeeStore } from "../../../state/Employees";
import { Redirect, useParams } from "react-router-dom";
import { EmployeeResponse } from "../../../types/Employees";
import Loader from "../../../components/common/Loader/Loader";
import FormDialog from "../../../components/dialogs/FormDialog/FormDialog";
import { UnpackNestedValue } from "react-hook-form";
import { useChangeOrganizationUnit } from "../../../configs/Forms/ChangeOrganizationUnitForm";
import { ChangeOrgUnitRequest } from "../../../types/Employees";
import useEmployeesBulkactions from "../../../state/EmployeesBulkActions";
import { useMe } from "../../../state/Administrators";
import { StatusActionKey } from "../../../types/Status";
import useStatus from "../../../utils/Status";
import useNotificationService from "../../../state/NotificationService";
import { DeleteEmployeeDialog } from "../../../components/employees/DeleteEmployeeDialog/DeleteEmployeeDialog";
import { PermissionOptions } from "../../../constants/Administrators";

export const InnerEmployeeContext = createContext<Partial<EmployeeResponse>>(
  {}
);
interface IProps {}

export default function EmployeesInner(props: IProps) {
  const { id } = useParams() as any;
  const { actions, getStatus } = useStatus();
  const { me } = useMe();
  const tabs = useEmployeeTabs();
  const sidebarActions = useEmployeeSidebarActions();
  const { employee, loading, parsedEmployeeStatus, employeeError } =
    useEmployees(id);
  const { t } = useTranslation();
  const changeOrgUnitForm = useChangeOrganizationUnit();
  const {
    changeOrgUnitAction,
    activateOnSpecificDate,
    deactivateOnSpecificDate,
    activateAction,
    deactivateAction,
    deleteInnerEmployee,
    loading: loadingEmployeesBulkactions,
  } = useEmployeesBulkactions(id);
  const [openOrgUnitDialog, setOpenOrgUnitDialog] = useState(false);
  const [openDeleteDialog, setDeleteDialog] = useState(false);
  const { sendInvitation } = useNotificationService();
  const { setStatus, status } = useEmployeeStore((state) => state);

  const { changeTab, currentTab } = useTabs(tabs[0].key as string);

  const publishActions = useMemo(
    () => actions.employee[status.name] ?? [],
    [actions.employee, status.name]
  );

  const allActions = useMemo(
    () => publishActions.concat(sidebarActions),
    [sidebarActions, publishActions]
  );

  useEffect(() => {
    setStatus(parsedEmployeeStatus);
    setDeleteDialog(false);
  }, [employee?.status, setStatus, parsedEmployeeStatus]);

  const inviteAgain = useCallback(() => {
    sendInvitation([id] as string[]);
  }, [sendInvitation, id]);

  const handleSidebarAction = useCallback(
    (action: StatusActionKey, date?: string) => {
      const statusAction = getStatus(action);
      if (statusAction) {
        if (statusAction === "active") {
          activateAction();
        } else if (statusAction === "inactive") {
          deactivateAction();
        } else if (statusAction === "will_be_active_on") {
          activateOnSpecificDate(date as string, [id] as string[]);
        } else if (statusAction === "will_be_inactive_on") {
          deactivateOnSpecificDate(date as string, [id] as string[]);
        }
      } else if (action === "change_org_unit") {
        setOpenOrgUnitDialog(true);
      } else if (action === "invite_again") {
        inviteAgain();
      } else if (action === "delete") {
        setDeleteDialog(true);
      }
    },
    [
      getStatus,
      inviteAgain,
      activateAction,
      deactivateAction,
      activateOnSpecificDate,
      deactivateOnSpecificDate,
      id,
    ]
  );

  const handleCloseOrgUnitDialog = useCallback(
    () => setOpenOrgUnitDialog(false),
    []
  );

  const handleChangeOrgUnit = useCallback(
    (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      changeOrgUnitAction(data as ChangeOrgUnitRequest, [id] as string[]);
      handleCloseOrgUnitDialog();
    },
    [id, handleCloseOrgUnitDialog, changeOrgUnitAction]
  );

  const handleCloseDeleteDialog = useCallback(() => setDeleteDialog(false), []);

  const handleDeleteDeactivateSubmit = useCallback(
    (value: string) => {
      if (value === "delete") {
        deleteInnerEmployee();
      } else if (value === "deactivate") {
        deactivateAction();
      }
      handleCloseDeleteDialog();
    },
    [handleCloseDeleteDialog, deactivateAction, deleteInnerEmployee]
  );

  if (!!employeeError) return <Redirect to="/" />;

  if (loading) return <Loader />;

  return (
    <>
      <SidebarLayout>
        <InnerEmployeeContext.Provider value={employee}>
          <SidebarContainer>
            <SidebarInner>
              <Title
                firstName={employee?.firstName}
                lastName={employee?.lastName}
                subtitle={employee.id}
                avatar={true}
              />
              <Tabs onChange={changeTab} value={currentTab}>
                {tabs.map((tab) => (
                  <Tab
                    key={`tab-${tab.key}`}
                    label={
                      <Typography variant="body2" color="textSecondary">
                        {t(`sidebar.${tab?.translationKey ?? tab.key}`)}
                      </Typography>
                    }
                    value={tab.key}
                    module={tab?.module}
                  />
                ))}
              </Tabs>
              {(employee?.permission === PermissionOptions.Edit ||
                me?.root === true) && (
                <Actions
                  onActionClick={handleSidebarAction}
                  actions={allActions}
                  disablePast={true}
                />
              )}
            </SidebarInner>
          </SidebarContainer>
          <ContentContainer>
            {tabs.map((tab) => (
              <TabView key={tab.key} index={tab.key} value={currentTab}>
                <tab.component />
              </TabView>
            ))}
          </ContentContainer>
        </InnerEmployeeContext.Provider>
      </SidebarLayout>
      <FormDialog
        onCancelClick={handleCloseOrgUnitDialog}
        onSubmitForm={handleChangeOrgUnit}
        id="change-organization-unit"
        open={openOrgUnitDialog}
        title={`${t("actions.change_org_unit")}`}
        actionLabel={t("common.confirm")}
        form={changeOrgUnitForm}
        loading={loadingEmployeesBulkactions}
      />
      <DeleteEmployeeDialog
        id="delete-deactivate-employee-dialog"
        open={openDeleteDialog}
        onCancelClick={handleCloseDeleteDialog}
        firstName={employee?.firstName}
        onActionClick={handleDeleteDeactivateSubmit}
      />
    </>
  );
}
