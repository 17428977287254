import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceByElement } from "../../../../../utils/Logs";
import { Log } from "../../../../../types/Logs";

export default function InvitationReminder(props: Partial<Log>) {
  const { employee, employeeId, employeeEmail } = props;
  const { t } = useTranslation();

  const parsedText = useMemo(
    () => t("log.invitation_sent_to")?.replace("{email}", employeeEmail ?? ""),
    [employeeEmail, t]
  );

  const employeeName = useMemo(
    () => employee ?? employeeId ?? "",
    [employee, employeeId]
  );

  return useMemo(
    () => replaceByElement(parsedText, "{name}", employeeName),
    [employeeName, parsedText]
  );
}
