import { Box, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import {
  useErrorMessages,
  MpControlProps,
  controlRegisterOptions,
  NumberInput,
} from "@mp-react/form";
import { useCurrency } from "../../../utils/useCurrency";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import { CustomFormControl } from "../../../types/Common";

const isDefined = (v?: number): v is number => !!(v ?? false);
const inputLabelProps = { shrink: true };

export function Currency({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  value,
  onChange,
}: MpControlProps & {
  value: number;
  onChange: (value: number | null) => void;
}) {
  const max = control?.validations?.max;
  const canBeNegative = !!control?.negative;
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const { getDefaultCurrencyNumber, currency } = useCurrency();
  const inputProps = useMemo(
    () => ({
      negative: control.negative,
      decimalPoint: 2,
    }),
    [control]
  );
  const { endAdornment = currency, startAdornment } = control;
  const InputProps = useMemo(
    () => ({
      inputComponent: NumberInput,
      endAdornment,
      startAdornment,
    }),
    [endAdornment, startAdornment]
  );

  const toInputValue = useCallback(
    (stateValue?: number) => {
      if (stateValue === null || stateValue === undefined) {
        return "";
      }
      if (stateValue === 0) {
        return "0";
      }
      return getDefaultCurrencyNumber(stateValue);
    },
    [getDefaultCurrencyNumber]
  );

  const formatInputValue = (valueToFormat: string) => {
    return getDefaultCurrencyNumber(valueToFormat.replace(",", "."));
  };

  const [inputValue, setInputValue] = React.useState(toInputValue(value));

  useEffect(() => {
    const formattedValue = toInputValue(value);
    if (formattedValue !== inputValue) {
      setInputValue(formattedValue);
    }
    // ignore inputValue changes to perform only one-way synchronization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toInputValue, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let valueToSet = e.target.value;
    const isMinus = valueToSet === "-";
    if (isMinus && !canBeNegative) {
      return;
    }
    const endsWithDecimalSeparator = [".", ","].includes(valueToSet.slice(-1));
    // Value can be parsed to number
    if (!endsWithDecimalSeparator && !isMinus) {
      const valueAsNumber = parseFloat(valueToSet);
      if (isDefined(max) && valueAsNumber > max) {
        valueToSet = String(max);
      }
      onChange(valueToSet === "" ? null : parseFloat(valueToSet));
    }
    const newInputValue = formatInputValue(valueToSet);
    if (inputValue !== newInputValue) {
      setInputValue(newInputValue);
    }
  };

  const handleBlur = () => {
    if (inputValue === "-") {
      setInputValue("");
    }
    if (inputValue.slice(-1) === ".") {
      setInputValue(inputValue.slice(0, -1));
    }
  };

  return (
    <Box position="relative">
      <TextField
        error={!!error}
        helperText={firstMessage}
        name={control.key}
        required={control.required}
        placeholder={control.placeholder}
        label={layout === "separated" ? "" : control.label}
        size={size}
        fullWidth={true}
        InputLabelProps={inputLabelProps}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={inputProps}
        InputProps={InputProps as any}
        variant={variant}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        disabled={(control as CustomFormControl)?.disabled}
      />
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function CurrencyControl(props: MpControlProps) {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <Currency {...props} onChange={onChange} value={value} />
      )}
    />
  );
}
