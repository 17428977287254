import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingRight: 20,
      "& .MuiSvgIcon-root": {
        opacity: 1,
        transition: theme.transitions.create("opacity", { duration: 200 }),
      },
      "& .MuiOutlinedInput-root": {
        height: 44,
      },
    },
    input: {
      fontSize: 20,
      fontWeight: 700,
    },
    focused: {
      "& .MuiSvgIcon-root": {
        opacity: 0,
      },
    },
  })
);
