import {
  getName,
  getNames,
  alpha2ToAlpha3,
  alpha3ToAlpha2,
} from "i18n-iso-countries";
import i18n from "../i18n";

const getNameForCurrentLocale = (countryCode: string) => {
  const currentLocale = i18n.language;
  return getName(countryCode, currentLocale);
};

const getAllForCurrentLocale = () => {
  const currentLocale = i18n.language;
  const all = getNames(currentLocale);
  return Object.keys(all).reduce((acc, key) => {
    acc.push({ key: alpha2ToAlpha3(key), label: all[key] });
    return acc;
  }, [] as { key: string; label: string }[]);
};

const countries = {
  getName: getNameForCurrentLocale,
  getAll: getAllForCurrentLocale,
  alpha2ToAlpha3,
  alpha3ToAlpha2,
};

export default countries;
