import { Box } from "@material-ui/core";
import React from "react";
// import TableDatepicker from "../../TableDatepicker/TableDatepicker";

export default function InnerBenefitsChoicesHeader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      {/* <TableDatepicker /> */}
    </Box>
  );
}
