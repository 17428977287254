import { useDefaultHighlights } from "../../constants/Table";
import { CustomRendererType } from "../../types/Table";
import { useTranslation } from "react-i18next";
import { MpColumn, MpTableType, MpActions } from "@mp-react/table";
import { useMemo } from "react";
import { useTableFilterTypes } from "../../utils/Table";
import { useTableStore } from "../../state/Table";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useGroupsAssignEmployeesTableType = (): MpTableType => {
  const { t } = useTranslation();
  const highlights = useDefaultHighlights();
  const { keyWithSuffix } = useLocalStorage();

  const customColumnValue = useTableStore((state) => state.customColumn);

  const {
    getSearchSelectFilter,
    getFilterNameSearchSelectFilter,
    getSelectFilter,
    numberRangeFilter,
  } = useTableFilterTypes();

  const fullNameFilter = useMemo(
    () => getSearchSelectFilter("getFullNames"),
    [getSearchSelectFilter]
  );

  const jobTitleFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getJobTitles"),
    [getFilterNameSearchSelectFilter]
  );

  const includedStatusFilter = useMemo(
    () =>
      getSelectFilter("included", [
        {
          label: t("assign_employees.included"),
          value: "1",
        },
        {
          label: t("assign_employees.excluded"),
          value: "0",
        },
      ]),
    [getSelectFilter, t]
  );

  const customColumnFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getCustomColumnCategories"),
    [getFilterNameSearchSelectFilter]
  );

  const fullNameColumn = useMemo(
    () => ({
      label: t("common.full_name"),
      key: "fullName",
      filter: fullNameFilter,
      width: 266,
      fixedHeadWidth: true,
    }),
    [fullNameFilter, t]
  );

  const customCategoryColumn = useMemo(
    () => ({
      label: customColumnValue ?? "",
      key: "customColumn",
      filter: customColumnFilter,
      width: 124,
      fixedHeadWidth: true,
    }),
    [customColumnFilter, customColumnValue]
  );

  const restColumns = useMemo(
    () => [
      {
        label: t("employees.job_title"),
        key: "jobTitle",
        filter: jobTitleFilter,
        width: 240,
        fixedHeadWidth: true,
      },
      {
        label: t("common.investment"),
        key: "investment",
        render: CustomRendererType.currency as any,
        sort: false,
        width: 120,
        fixedHeadWidth: true,
        filter: numberRangeFilter,
      },
      {
        label: t("assign_employees.included"),
        key: "included",
        render: CustomRendererType.includedExcludedSwitch as any,
        sort: false,
        filter: includedStatusFilter,
        action: {
          slug: "toggleIncludedExcluded",
        },
        width: 162,
        fixedHeadWidth: true,
      },
    ],
    [includedStatusFilter, jobTitleFilter, t, numberRangeFilter]
  );

  const columns: MpColumn[] = useMemo(
    () =>
      !!customColumnValue
        ? [fullNameColumn, customCategoryColumn, ...restColumns]
        : [fullNameColumn, ...restColumns],
    [customColumnValue, fullNameColumn, customCategoryColumn, restColumns]
  );

  const bulkActions: MpActions = useMemo(
    () => [
      {
        slug: "statusExcluded",
        label: t("status.mark_selections_as_excluded"),
      },
      {
        slug: "statusIncluded",
        label: t("status.mark_selections_as_included"),
      },
    ],
    [t]
  );

  return {
    columns,
    rowIdKey: "id",
    filterVariant: "head",
    disablePagination: true,
    bulkActions: bulkActions,
    totals: true,
    stickyTotals: true,
    enableGlobalActions: true,
    selection: "multiple",
    highlights: highlights,
    storageIdentifier: keyWithSuffix(StorageKeys.groupsAssignEmployeesTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
