const DEFAULT_PREFIX = "admin/v1";
const DEFAULT_V2_PREFIX = "admin/v2";
const MELP_ADMIN_PREFIX = "melp/v1";
const MELP_ADMIN_V2_PREFIX = "melp/v2";

const PARENT_COMPANIES_PREFIX = `${DEFAULT_PREFIX}/parentCompanies`;

const PARENT_COMPANY_ENDPOINTS = {
  parentCompany: PARENT_COMPANIES_PREFIX,
  getParentCompanyHierarchy: `${PARENT_COMPANIES_PREFIX}/hierarchy`,
  getParentCompanyHierarchyById: `${PARENT_COMPANIES_PREFIX}/{id}/hierarchy`,
  getOwnCompany: `${PARENT_COMPANIES_PREFIX}/own`,
  createParentCompany: `${PARENT_COMPANIES_PREFIX}/create`,
  updateDefaultLanguage: `${PARENT_COMPANIES_PREFIX}/settings`,
};

const USERS_PREFIX = `${DEFAULT_PREFIX}/users`;

const USERS_ENDPOINTS = {
  usersList: USERS_PREFIX,
  usersFilterValues: `${USERS_PREFIX}/filterValues`,
  usersExportToExcel: `${USERS_PREFIX}/exportToExcel`,
};

const COMPANY_GROUP_PREFIX = `${DEFAULT_PREFIX}/companyGroup`;

const COMPANY_GROUP_ENDPOINTS = {
  companyGroup: COMPANY_GROUP_PREFIX,
  createCompanyGroup: `${COMPANY_GROUP_PREFIX}/create`,
  deleteCompanyGroup: `${COMPANY_GROUP_PREFIX}/delete`,
  companyGroupFilterValues: `${COMPANY_GROUP_PREFIX}/filterValues`,
};

const BENEFIT_PLAN_PREFIX = `${DEFAULT_PREFIX}/benefitPlans`;

const BENEFIT_PLAN_ENDPOINTS = {
  benefitPlans: BENEFIT_PLAN_PREFIX,
  createBenefitPlan: `${BENEFIT_PLAN_PREFIX}/create`,
  bulkActionBenefit: `${BENEFIT_PLAN_PREFIX}/bulkAction`,
  benefitExportToExcel: `${BENEFIT_PLAN_PREFIX}/exportToExcel`,
  benefitFilterValues: `${BENEFIT_PLAN_PREFIX}/filterValues`,
  benefitRemoveAllEmployees: `${BENEFIT_PLAN_PREFIX}/{id}/removeAllEmployees`,
};

const EMPLOYEES_PREFIX = `${DEFAULT_PREFIX}/employee`;

const EMPLOYEE_ENDPOINTS = {
  employee: EMPLOYEES_PREFIX,
  employeeFilterValues: `${EMPLOYEES_PREFIX}/filterValues`,
  createEmployee: `${EMPLOYEES_PREFIX}/create`,
  bulkActionEmployee: `${EMPLOYEES_PREFIX}/bulkAction`,
  employeesExportToExcel: `${EMPLOYEES_PREFIX}/exportToExcel`,
  employeeBenefitPlanAssignments: `${EMPLOYEES_PREFIX}/{id}/BenefitPlanAssignments`,
  employeeBenefitPlanAssignmentsFilterValues: `${EMPLOYEES_PREFIX}/{id}/BenefitPlanAssignments/filterValues`,
};

const FLEX_BENEFIT_ALLOCATION_PREFIX = `${DEFAULT_PREFIX}/flexBenefitAllocation`;

const FLEX_BENEFIT_ALLOCATION_ENDPOINTS = {
  createFlexBenefitAllocation: `${FLEX_BENEFIT_ALLOCATION_PREFIX}/create`,
};

const START_GUIDE_PREFIX = `${DEFAULT_PREFIX}/startGuide`;

const START_GUIDE_ENDPOINTS = {
  actionNeeded: `${START_GUIDE_PREFIX}/actionNeeded`,
  sixSteps: `${START_GUIDE_PREFIX}/sixSteps`,
};

const COMPANY_PREFIX = `${DEFAULT_PREFIX}/companies`;

const COMPANY_ENDPOINTS = {
  company: COMPANY_PREFIX,
  createCompany: `${COMPANY_PREFIX}/create`,
  companyFilterValues: `${COMPANY_PREFIX}/filterValues`,
};

const FILE_PREFIX = `v1/files`;

const FILE_ENDPOINTS = {
  files: FILE_PREFIX,
  uploadFile: `${FILE_PREFIX}/upload`,
};

const ADMIN_PREFIX = `${DEFAULT_PREFIX}/admins`;

const ADMIN_ENDPOINTS = {
  admin: ADMIN_PREFIX,
  me: `${ADMIN_PREFIX}/me`,
  createAdmin: `${ADMIN_PREFIX}/create`,
  adminFilterValues: `${ADMIN_PREFIX}/filterValues`,
  createProgrammatic: `${ADMIN_PREFIX}/createProgrammatic`,
  deleteHrAdmin: `${ADMIN_PREFIX}/hr`,
  deleteMelpAdmin: `${ADMIN_PREFIX}/melp`,
};

const PERMISSION_PREFIX = `${DEFAULT_PREFIX}/permissions`;

const PERMISSION_ENDPOINTS = {
  localPermissions: `${PERMISSION_PREFIX}/local`,
  globalPermissions: `${PERMISSION_PREFIX}/global`,
  companyGroupPermissions: `${PERMISSION_PREFIX}/companyGroup`,
  companyPermissions: `${PERMISSION_PREFIX}/company`,
};

const BENEFIT_PLAN_CHOICE_PREFIX = `${DEFAULT_PREFIX}/benefitPlanChoices`;

const BENEFIT_PLAN_CHOICE_ENDPOINTS = {
  benefitChoices: BENEFIT_PLAN_CHOICE_PREFIX,
  setImplementationDates: `${BENEFIT_PLAN_CHOICE_PREFIX}/setImplementationDates`,
  setChoiceStatus: `${BENEFIT_PLAN_CHOICE_PREFIX}/setChoiceStatus`,
  choicesExportToExcel: `${BENEFIT_PLAN_CHOICE_PREFIX}/exportToExcel`,
  choicesFilterValues: `${BENEFIT_PLAN_CHOICE_PREFIX}/filterValues`,
};

const EMPLOYEE_CATEGORIES_PREFIX = `${DEFAULT_PREFIX}/employeeCategories`;

const EMPLOYEE_CATEGORIES_ENDPOINTS = {
  employeeCategories: EMPLOYEE_CATEGORIES_PREFIX,
  createEmployeeCategories: `${EMPLOYEE_CATEGORIES_PREFIX}/create`,
  employeeInnerCategories: `${EMPLOYEE_CATEGORIES_PREFIX}/employeeInner`,
};

const EMPLOYEE_GROUPS_PREFIX = `${DEFAULT_PREFIX}/employeeGroups`;

const EMPLOYEE_GROUPS_ENDPOINTS = {
  employeeGroup: EMPLOYEE_GROUPS_PREFIX,
  employeeGroupsList: `${EMPLOYEE_GROUPS_PREFIX}/list`,
  employeeGroupsFilterValues: `${EMPLOYEE_GROUPS_PREFIX}/filterValues`,
  employeeGroupsBenefitPlanAssignmentsFilterValues: `${EMPLOYEE_GROUPS_PREFIX}/{id}/benefitPlanAssignments/filterValues`,
  employeeGroupsExportToExcel: `${EMPLOYEE_GROUPS_PREFIX}/exportToExcel`,
  employeeGroupsCopy: `${EMPLOYEE_GROUPS_PREFIX}/{id}/copy`,
};

const NOTIFICATION_SERVICE_PREFIX = `${DEFAULT_PREFIX}/notification`;

const NOTIFICATION_SERVICE_EMPLOYEE_GROUPS_ENDPOINTS = {
  notificationInvitation: `${NOTIFICATION_SERVICE_PREFIX}/invitation`,
  choicesReminder: `${NOTIFICATION_SERVICE_PREFIX}/choicesReminder`,
  termsReminder: `${NOTIFICATION_SERVICE_PREFIX}/termsAndConditionsReminder`,
};

const NEWS_PREFIX = `${DEFAULT_PREFIX}/news`;

const NEWS_ENDPOINTS = {
  newsList: NEWS_PREFIX,
  createNews: `${NEWS_PREFIX}/create`,
  deleteNews: `${NEWS_PREFIX}/delete`,
  recipients: `${NEWS_PREFIX}`,
  assignRecipients: `${NEWS_PREFIX}/{id}/assign/recipients`,
  newsExportToExcel: `${NEWS_PREFIX}/exportToExcel`,
};

const GLOBAL_SEARCH_ENDPOINTS = {
  globalSearch: `${DEFAULT_PREFIX}/search`,
};

const BENEFIT_PLAN_ASSIGNMENTS_PREFIX = `${DEFAULT_PREFIX}/benefitPlanAssignments`;

const BENEFIT_PLAN_ASSIGNMENTS_ENDPOINTS = {
  assignEmployees: `${BENEFIT_PLAN_ASSIGNMENTS_PREFIX}/assignToEmployees`,
  assignEmployeeGroups: `${BENEFIT_PLAN_ASSIGNMENTS_PREFIX}/assignToEmployeeGroups`,
  checkBenefitPlanToEmployeeChanges: `${BENEFIT_PLAN_ASSIGNMENTS_PREFIX}/checkBenefitPlanToEmployeeChanges`,
  checkBenefitPlanToEmployeeGroupChanges: `${BENEFIT_PLAN_ASSIGNMENTS_PREFIX}/checkBenefitPlanToEmployeeGroupChanges`,
  checkEmployeeToBenefitPlan: `${BENEFIT_PLAN_ASSIGNMENTS_PREFIX}/checkEmployeeToBenefitPlanChanges`,
  checkEmployeeGroupToBenefitPlan: `${BENEFIT_PLAN_ASSIGNMENTS_PREFIX}/checkEmployeeGroupToBenefitPlanChanges`,
};

const TERMS_ACCEPTANCE_PREFIX = `${DEFAULT_PREFIX}/termsAcceptance`;

const TERMS_ACCEPTANCE_ENDPOINTS = {
  termsAcceptance: TERMS_ACCEPTANCE_PREFIX,
  employeeTermsAcceptance: `${TERMS_ACCEPTANCE_PREFIX}/employee`,
  benefitTermsAcceptance: `${TERMS_ACCEPTANCE_PREFIX}/benefit`,
  termsFilterValues: `${TERMS_ACCEPTANCE_PREFIX}/filterValues`,
  termsAcceptanceFilterValues: `${TERMS_ACCEPTANCE_PREFIX}/filterValues`,
  termsAcceptanceExportToExcel: `${TERMS_ACCEPTANCE_PREFIX}/exportToExcel`,
};

const ACTION_LOG_PREFIX = `${DEFAULT_PREFIX}/actionLog`;

const ACTION_LOG_ENDPOINTS = {
  actionLog: ACTION_LOG_PREFIX,
  actionLogFilterValues: `${ACTION_LOG_PREFIX}/filterValues`,
};

const ANALYTICS_INVESTMENT_PREFIX = `${DEFAULT_PREFIX}/analytics/investment`;
const ANALYTICS_COMPARE_MODE_PREFIX = `${DEFAULT_PREFIX}/analytics/compareMode`;

const ANALYTICS_ENDPOINTS = {
  monthlyInvestments: `${ANALYTICS_INVESTMENT_PREFIX}/monthlyInvestments`,
  benefitTypesInvestments: `${ANALYTICS_INVESTMENT_PREFIX}/benefitTypes`,
  aggregatedInvestments: `${ANALYTICS_INVESTMENT_PREFIX}/aggregated`,
  compareModeValues: `${ANALYTICS_COMPARE_MODE_PREFIX}/values`,
  compareModeTable: `${ANALYTICS_COMPARE_MODE_PREFIX}/table`,
  compareModeExcel: `${ANALYTICS_COMPARE_MODE_PREFIX}/excel`,
};

const INTEGRATIONS_ENDPOINTS = {
  integrationTypes: `${DEFAULT_PREFIX}/integrationTypes`,
  integrations: `${DEFAULT_PREFIX}/integrations`,
};

const BENEFIT_TEMPLATES = {
  benefitTemplates: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate`,
  benefitTemplatesFilterValues: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate/filterValues`,
  deleteBenefitTemplate: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate/delete`,
  createBenefitTemplate: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate/create`,
};

const BENEFIT_EXPENSES = {
  benefitExpenses: `${DEFAULT_PREFIX}/benefitPlanExpenses`,
  employeesBenefitsForExpenses: `${DEFAULT_PREFIX}/benefitPlanExpenses/benefits`,
};

const MARKETPLACE_SUPPLIERS = {
  marketplaceSuppliers: `${MELP_ADMIN_PREFIX}/marketplaceSupplier`,
  marketplaceSupplier: (id: string) =>
    `${MELP_ADMIN_PREFIX}/marketplaceSupplier/${id}`,
  marketplaceSupplierItems: (id: string) =>
    `${MELP_ADMIN_PREFIX}/marketplaceSupplier/${id}/items`,
  marketplaceSuppliersStatistics: `${MELP_ADMIN_PREFIX}/marketplaceSupplier/statistics`,
  marketplaceSuppliersFilterValues: `${MELP_ADMIN_PREFIX}/marketplaceSupplier/filterValues`,
};

export const Endpoints = {
  ...PARENT_COMPANY_ENDPOINTS,
  ...COMPANY_GROUP_ENDPOINTS,
  ...BENEFIT_PLAN_ENDPOINTS,
  ...EMPLOYEE_ENDPOINTS,
  ...START_GUIDE_ENDPOINTS,
  ...COMPANY_ENDPOINTS,
  ...FILE_ENDPOINTS,
  ...FLEX_BENEFIT_ALLOCATION_ENDPOINTS,
  ...ADMIN_ENDPOINTS,
  ...PERMISSION_ENDPOINTS,
  ...BENEFIT_PLAN_CHOICE_ENDPOINTS,
  ...EMPLOYEE_CATEGORIES_ENDPOINTS,
  ...EMPLOYEE_GROUPS_ENDPOINTS,
  ...NOTIFICATION_SERVICE_EMPLOYEE_GROUPS_ENDPOINTS,
  ...NEWS_ENDPOINTS,
  ...GLOBAL_SEARCH_ENDPOINTS,
  ...BENEFIT_PLAN_ASSIGNMENTS_ENDPOINTS,
  ...TERMS_ACCEPTANCE_ENDPOINTS,
  ...ACTION_LOG_ENDPOINTS,
  ...ANALYTICS_ENDPOINTS,
  ...INTEGRATIONS_ENDPOINTS,
  ...USERS_ENDPOINTS,
  ...BENEFIT_TEMPLATES,
  ...BENEFIT_EXPENSES,
  ...MARKETPLACE_SUPPLIERS,
  clientAdmin: {
    companies: {
      byId: (companyId: string) => ({
        contacts: {
          root: `${DEFAULT_PREFIX}/companies/${companyId}/contacts`,
        },
      }),
      contacts: {
        byId: (contactId: string) => ({
          root: `${DEFAULT_PREFIX}/companies/contacts/${contactId}`,
        }),
      },
    },
    marketplaceItems: {
      root: `${DEFAULT_PREFIX}/marketplaceItems`,
      filterValues: `${DEFAULT_PREFIX}/marketplaceItems/filterValues`,
      benefitItems: (benefitId: string) =>
        `${DEFAULT_PREFIX}/marketplaceItems/benefitPlan/${benefitId}`,
      requestItem: `${DEFAULT_PREFIX}/marketplaceItems/request-item-per-email`,
      selection: {
        root: `${DEFAULT_PREFIX}/marketplaceItems/selection`,
        marketplaceItem: (marketplaceItemId: string) =>
          `${DEFAULT_PREFIX}/marketplaceItems/selection/${marketplaceItemId}`,
        bulk: `${DEFAULT_PREFIX}/marketplaceItems/selection/bulk`,
      },
    },
    marketplaceOrders: {
      root: `${DEFAULT_PREFIX}/marketplaceOrders`,
      exportToExcel: `${DEFAULT_PREFIX}/marketplaceOrders/exportToExcel`,
      filterValues: `${DEFAULT_PREFIX}/marketplaceOrders/filterValues`,
    },
    benefitPlans: {
      root: `${DEFAULT_PREFIX}/benefitPlans`,
      byId: (benefitPlanId: string) => ({
        root: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}`,
        marketplaceItems: {
          root: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems`,
          filterValues: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/filterValues`,
          byId: (marketplaceItemId: string) => ({
            root: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/${marketplaceItemId}`,
          }),
          bulkLink: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/link/bulk`,
          bulkUnlink: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/unlink/bulk`,
          bulkLinkSelection: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/link/selection`,
          assignmentFilter: {
            root: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/assignmentFilter`,
          },
        },
      }),
    },
    integrations: {
      integration: (id: string) => `${DEFAULT_PREFIX}/integrations/${id}`,
    },
    clientIntegrationLogs: {
      root: (clientIntegrationTypeId: string) =>
        `${DEFAULT_PREFIX}/client-integration-logs/${clientIntegrationTypeId}`,
    },
    clientIntegrationLogItems: {
      root: (processId: string) =>
        `${DEFAULT_PREFIX}/client-integration-log-items/${processId}`,
      filterValues: `${DEFAULT_PREFIX}/client-integration-log-items/filterValues`,
    },
    benefitPlanTemplates: {
      root: `${DEFAULT_PREFIX}/benefitPlanTemplate`,
      filterValues: `${DEFAULT_PREFIX}/benefitPlanTemplate/filterValues`,
    },
    v2: {
      marketplaceItems: {
        root: `${DEFAULT_V2_PREFIX}/marketplaceItems`,
        benefitItems: (benefitId: string) =>
          `${DEFAULT_V2_PREFIX}/marketplaceItems/benefitPlan/${benefitId}`,
        selection: {
          root: `${DEFAULT_V2_PREFIX}/marketplaceItems/selection`,
        },
      },
      benefitPlans: {
        byId: (benefitPlanId: string) => ({
          marketplaceItems: {
            root: `${DEFAULT_V2_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems`,
          },
        }),
      },
    },
  },
  melpAdmin: {
    marketplaceOrders: {
      root: `${MELP_ADMIN_PREFIX}/marketplaceOrders`,
      order: (id: string) => `${MELP_ADMIN_PREFIX}/marketplaceOrders/${id}`,
      filterValues: `${MELP_ADMIN_PREFIX}/marketplaceOrders/filterValues`,
      bulkUpdate: `${MELP_ADMIN_PREFIX}/marketplaceOrders/bulkAction`,
      exportToExcel: `${MELP_ADMIN_PREFIX}/marketplaceOrders/exportToExcel`,
    },
    benefitPlanTemplates: {
      copy: (templateId: string) =>
        `${MELP_ADMIN_PREFIX}/benefitPlanTemplate/${templateId}/copy`,
    },
    marketplaceItems: {
      root: `${MELP_ADMIN_PREFIX}/marketplaceItems`,
      byId: (itemId: string) => ({
        root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}`,
        translations: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/translations`,
        prices: {
          root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/prices`,
          byId: (priceId: string) => ({
            root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/prices/${priceId}`,
            translations: {
              root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/prices/${priceId}/translations`,
              byId: (translationId: string) => ({
                root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/prices/${priceId}/translations/${translationId}`,
              }),
            },
          }),
        },
      }),
      filterValues: `${MELP_ADMIN_PREFIX}/marketplaceItems/filterValues`,
      translations: {
        byId: (translationId: string) => ({
          root: `${MELP_ADMIN_PREFIX}/marketplaceItems/translations/${translationId}`,
        }),
      },
    },
    v2: {
      marketplaceItems: {
        byId: (itemId: string) => ({
          root: `${MELP_ADMIN_V2_PREFIX}/marketplaceItems/${itemId}`,
        }),
      },
    },
  },
};
