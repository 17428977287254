import React from "react";
import { Button, Typography } from "@melp-design/components";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { useDialog } from "../../../../../utils/Dialog";
import DialogExtended from "../../../../../components/common/DialogExtended/DialogExtended";
import { Close } from "@melp-design/icons";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { TextField, IconButton } from "@material-ui/core";
import { useItemRequest } from "../../../../../state/MarketplaceItems";

const useStyles = makeStyles((theme) => ({
  requestButton: {
    minWidth: 120,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "end",
    padding: "20px 20px 5px",
  },
  dialogContent: {
    padding: "0 30px",
    "& > *": {
      marginBottom: 20,
    },
  },
  dialogActions: {
    display: "flex",
    padding: "5px 30px 20px",
  },
}));

interface Props {}

const ItemRequest = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const requestDialog = useDialog();
  const { handleSubmit, control, errors } = useForm();
  const [request, requestData] = useItemRequest();
  const onSubmit = async (data: { messageText: string }) => {
    await request({ text: data.messageText });
    toast.success(t("marketplace.messageSentSuccessfully"));
    requestDialog.closeDialog();
  };
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={classes.requestButton}
        onClick={requestDialog.openDialog}
      >
        {t("marketplace.requestItem")}
      </Button>
      <DialogExtended open={requestDialog.open}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onReset={requestDialog.closeDialog}
        >
          <DialogTitle className={classes.dialogTitle}>
            <IconButton type="reset">
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="p1">
              {t("marketplace.itemRequestExplanation")}
            </Typography>
            <Controller
              name="messageText"
              control={control}
              defaultValue=""
              rules={{ required: `${t("form.required") ?? ""}` }}
              render={({ onChange, value }, { invalid }) => {
                return (
                  <TextField
                    onChange={onChange}
                    value={value}
                    multiline
                    minRows={5}
                    maxRows={10}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={t("marketplace.typeMessage")}
                    error={invalid}
                    helperText={errors.messageText?.message}
                  />
                );
              }}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button variant="outlined" type="reset">
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              loading={requestData.loading}
            >
              {t("marketplace.sendRequest")}
            </Button>
          </DialogActions>
        </form>
      </DialogExtended>
    </>
  );
};

export default ItemRequest;
