import { MpControlType, MpFormType } from "@mp-react/form";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { TooltipControl } from "../../../types/Common";
import { CustomControlType } from "../../../types/Form";

export const useEmployeeInformationAuthentication = (): MpFormType => {
  const { t } = useTranslation();
  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: CustomControlType.password,
        key: "personalCode",
        validations: {
          minLength: 3,
        },
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.personal_code"),
        placeholder: t("form.personal_code"),
        required: true,
        tooltip: "tooltip.employee_auth_details_personal_no",
      },
      {
        type: "phone" as MpControlType,
        key: "phone",
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("form.mobile_number"),
        required: true,
        tooltip: "tooltip.employee_auth_details_phone",
      },
    ],
    [t]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
