import React from "react";
import { Box, Button } from "@material-ui/core";
import useStyles from "./EmployeeInformationFooterButtons.styles";
import { useTranslation } from "react-i18next";

interface IProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export default function EmployeeFooterButtons(props: IProps) {
  const { onCancel, onConfirm } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Button className={classes.cancelButton} onClick={onCancel}>
        {t("common.cancel")}
      </Button>
      <Button
        type="submit"
        name="confirmChanges"
        variant="contained"
        color="secondary"
        onClick={onConfirm}
      >
        {t("common.confirm_changes")}
      </Button>
    </Box>
  );
}
