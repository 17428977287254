import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: 30,
    },
    dialogContentRoot: {
      padding: 0,
      marginTop: 30,
    },
    dialogActionsRoot: {
      padding: 0,
      marginTop: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    cancelButton: {
      marginRight: 20,
    },
  })
);
