import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import { languageRequest } from "./utils/Language";
import { StorageKeys } from "./types/LocalStorage";
import { registerLocale } from "i18n-iso-countries";

const backendOptions = {
  crossDomain: true,
  allowMultiLoading: true,
  loadPath: "{{lng}}|{{ns}}",
  request: languageRequest,
};

i18n
  .use(initReactI18next)
  .use(backend)
  .init({
    backend: backendOptions,
    compatibilityJSON: "v3",
    debug: false,
    lng: localStorage.getItem(StorageKeys.lang) || "en",
    fallbackLng: false,
    ns: ["translations"],
    defaultNS: "translations",
  })
  .then(() => {
    registerLocale(require("i18n-iso-countries/langs/en.json"));
    registerLocale(require("i18n-iso-countries/langs/ru.json"));
    registerLocale(require("i18n-iso-countries/langs/pl.json"));
    registerLocale(require("i18n-iso-countries/langs/lt.json"));
    registerLocale(require("i18n-iso-countries/langs/lv.json"));
    registerLocale(require("i18n-iso-countries/langs/bg.json"));
  });

export default i18n;
