import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    clearIcon: {},
    clearButton: {
      height: 40,
      width: 40,
      marginLeft: 5,
    },
  })
);
