import { useDefaultHighlights } from "./../../constants/Table";
import { MpColumn, MpTableType } from "@mp-react/table";
import { CustomRendererType } from "../../types/Table";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { MpActions } from "@mp-react/table";
import { useMe } from "../../state/Administrators";
import { useTableFilterTypes } from "../../utils/Table";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useEmployeeInnerChoicesTableType = (): MpTableType => {
  const { canEditChoices } = useMe();
  const { t } = useTranslation();
  const highlights = useDefaultHighlights();
  const { keyWithSuffix } = useLocalStorage();

  const {
    dateRangeFilter,
    getSelectFilter,
    getFilterNameSearchSelectFilter,
    numberRangeFilter,
  } = useTableFilterTypes();

  const benefitPlanTypeFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getBenefitPlanType"),
    [getFilterNameSearchSelectFilter]
  );

  const benefitPlanNameFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getBenefitPlanName"),
    [getFilterNameSearchSelectFilter]
  );

  const flexStatusFilter = useMemo(
    () =>
      getSelectFilter("flexStatus", [
        {
          label: t("status.selected"),
          value: "selected",
        },
        {
          label: t("status.not_Selected"),
          value: "notSelected",
        },
        {
          label: t("status.declined"),
          value: "declined",
        },
      ]),
    [getSelectFilter, t]
  );

  const implementationStatusFilter = useMemo(
    () =>
      getSelectFilter("implementationStatus", [
        {
          label: t("table.done"),
          value: "done",
        },
        {
          label: t("table.pending"),
          value: "pending",
        },
      ]),
    [getSelectFilter, t]
  );

  const columns = useMemo(
    (): MpColumn[] => [
      {
        label: t("common.time_remaining"),
        key: "timeRemaining",
        totals: false,
        sort: false,
        render: CustomRendererType.timeRemaining as any,
        width: 87,
        fixedHeadWidth: true,
      },
      {
        label: t("common.plan_name"),
        key: "benefitPlanName",
        filter: benefitPlanNameFilter,
        width: 184,
        fixedHeadWidth: true,
      },
      {
        label: t("benefits.benefit_type"),
        key: "benefitPlanType",
        render: CustomRendererType.benefitTypeTranslation as any,
        filter: benefitPlanTypeFilter,
        width: 130,
        fixedHeadWidth: true,
      },
      {
        label: `${t("common.investment").slice(0, 6)}.`,
        key: "investment",
        render: CustomRendererType.currency as any,
        sort: false,
        width: 80,
        fixedHeadWidth: true,
        filter: numberRangeFilter,
      },
      {
        label: t("choices.deadline"),
        key: "votingEndDate",
        totals: false,
        sort: false,
        render: CustomRendererType.dateFormat as any,
        filter: dateRangeFilter,
        width: 116,
        fixedHeadWidth: true,
      },
      {
        label: t("common.status"),
        key: "flexStatus",
        sort: false,
        render: CustomRendererType.statusPill as any,
        filter: flexStatusFilter,
        width: 150,
        fixedHeadWidth: true,
      },
      {
        label: t("choices.implementation"),
        key: "implementationStatus",
        render: CustomRendererType.implementation as any,
        sort: false,
        action: {
          slug: "toggleImplementationDate",
        },
        filter: implementationStatusFilter,
        width: 158,
        fixedHeadWidth: true,
      },
    ],
    [
      benefitPlanNameFilter,
      benefitPlanTypeFilter,
      dateRangeFilter,
      flexStatusFilter,
      implementationStatusFilter,
      t,
      numberRangeFilter,
    ]
  );

  const bulkActions: MpActions = useMemo(
    () => [
      {
        slug: "implementationDone",
        label: t("actions.mark_implementation_as_done"),
      },
      {
        slug: "implementationPending",
        label: t("actions.mark_implementation_as_pending"),
      },
      {
        slug: "statusSelected",
        label: t("actions.status_selected"),
      },
      {
        slug: "statusDeclined",
        label: t("actions.status_declined"),
      },
      {
        slug: "statusNotSelected",
        label: t("actions.status_not_selected"),
      },
      {
        slug: "sendReminders",
        label: t("actions.send_reminder"),
      },
    ],
    [t]
  );

  return {
    columns,
    rowIdKey: "id",
    selection: canEditChoices ? "multiple" : undefined,
    filterVariant: "head",
    disablePagination: true,
    bulkActions: bulkActions,
    totals: true,
    stickyTotals: true,
    highlights: highlights,
    storageIdentifier: keyWithSuffix(StorageKeys.employeeChoicesTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
