import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  MenuItem,
  TextField,
  Select,
  Typography as MuiTypography,
  Box,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./AddBenefitDialog.styles";
import { SelectOption } from "../../../types/Common";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useTooltip from "../../../utils/Tooltip";
import DialogExtended from "../../common/DialogExtended/DialogExtended";
import { useDialog } from "../../../utils/Dialog";
import { CreateBenefitRequest } from "../../../types/Benefits";
import TemplateDialog from "./TemplateDialog";
import { useFeatureToggle } from "../../../utils/FeatureToggle/index";
import { FeatureKey } from "../../../utils/FeatureToggle/Types";
import { Typography } from "@melp-design/components";

interface IProps extends DialogProps {
  onCancelClick: () => void;
  onActionClick: (data: CreateBenefitRequest) => void | Promise<any>;
  id: string;
  title: string;
  defaultValue?: string;
  options?: SelectOption[];
}

const AddBenefitDialog = ({
  onCancelClick,
  onActionClick,
  id,
  title,
  defaultValue,
  options,
  ...rest
}: IProps) => {
  const { isFeatureEnabled } = useFeatureToggle(FeatureKey.benefitTemplates);
  const { t } = useTranslation();
  const classes = useStyles();
  const [benefitType, setBenefitType] = useState<string>("");
  const [planName, setPlanName] = useState<string>("");
  const { openTooltip, closeTooltip, anchorEl } = useTooltip();

  const templateDialog = useDialog();

  useEffect(() => {
    setBenefitType(defaultValue ?? "");
    setPlanName("");
  }, [defaultValue]);

  const handleTextFieldChange = useCallback(
    (e: any) => setPlanName(e.target.value),
    []
  );

  const handleSelectChange = useCallback(
    (e: any) => setBenefitType(e.target.value),
    []
  );

  const handleEnterPress = useCallback(() => {
    if (!!benefitType && !!planName) {
      templateDialog.openDialog();
    }
  }, [benefitType, planName, templateDialog]);

  const handleFlowCancel = () => {
    if (templateDialog.open) {
      templateDialog.closeDialog();
    }
    onCancelClick();
  };

  const handleCreation = async (templateId?: string) => {
    const response = await onActionClick({
      type: benefitType,
      name: planName,
      templateId,
    });
    templateDialog.closeDialog();
    setBenefitType(defaultValue ?? "");
    setPlanName("");
    return response;
  };

  const handleActionClick = () => {
    if (isFeatureEnabled()) {
      templateDialog.openDialog();
    } else {
      handleCreation();
    }
  };

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={handleFlowCancel}
      onEnterPress={handleEnterPress}
      {...rest}
    >
      <MuiTypography variant="h5" id={id}>
        {title}
      </MuiTypography>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Box
          position="relative"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          <Select
            variant="outlined"
            key="benefitType"
            value={benefitType}
            onChange={handleSelectChange}
            fullWidth
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return (
                  <Typography variant="p1" style={{ color: "#9aa1ac" }}>
                    {t("benefits.benefitCategoryPlaceholder")}
                  </Typography>
                );
              }
              const selectedOption = options?.find(
                (option) => option.value === selected
              );
              return selectedOption ? t(selectedOption.name) : "";
            }}
          >
            {options?.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {t(item.name)}
              </MenuItem>
            ))}
          </Select>
          <FormTooltip tooltip="tooltip.benefit_type" anchorEl={anchorEl} />
        </Box>
        <Box
          position="relative"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          <TextField
            variant="outlined"
            key="benefit"
            placeholder={t("benefits.benefitNamePlaceholder")}
            fullWidth
            margin="dense"
            required
            value={planName}
            onChange={handleTextFieldChange}
          />
          <FormTooltip
            tooltip="tooltip.benefit_plan_name"
            anchorEl={anchorEl}
          />
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={handleFlowCancel} variant="text">
          {t("common.cancel")}
        </Button>
        <Button
          onClick={handleActionClick}
          variant="contained"
          color="primary"
          disabled={!planName}
        >
          {isFeatureEnabled()
            ? t("benefits.nextStep")
            : t("common.create_draft")}
        </Button>
        <TemplateDialog
          open={templateDialog.open && rest.open}
          onClose={handleFlowCancel}
          onCreate={handleCreation}
        />
      </DialogActions>
    </DialogExtended>
  );
};

export default AddBenefitDialog;
