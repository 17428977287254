import { UploadedFile, UseFiles } from "./../types/Files";
import { mutateData } from "./../api/api";
import { useCallback, useMemo } from "react";
import { useLoading } from "./../utils/Loading";
import useSWR from "swr";
import { Endpoints } from "../api/constants";
import { AxiosResponse } from "axios";
import { Attachments } from "../types/Benefits";

export const getUploadedFile = async (id: string) => {
  const file = await mutateData("get", `${Endpoints.files}/${id}`).then(
    (res) => {
      return res.data;
    }
  );
  return file;
};

export const useFiles = (id?: string | Attachments): UseFiles => {
  const { data: file, error: fileError } = useSWR(
    !!id
      ? `${Endpoints.files}/${typeof id === "object" ? id?.fileId : id}`
      : null
  );
  const { startLoading, stopLoading, loading } = useLoading();

  const apiLoading = useMemo(() => {
    if (!id) return false;

    return !file && !fileError;
  }, [file, fileError, id]);

  const uploadFile = useCallback(
    async (file: File) => {
      startLoading();
      const formData = new FormData();
      formData.append("file", file);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const uploadedFile: AxiosResponse<any> = await mutateData(
        "post",
        Endpoints.uploadFile,
        formData,
        headers
      )
        .then((res) => {
          return res as AxiosResponse<any>;
        })
        .finally(() => {
          stopLoading();
        });
      return uploadedFile.data as UploadedFile;
    },
    [startLoading, stopLoading]
  );

  return {
    file,
    uploadFile,
    loading: apiLoading || loading,
  };
};
