import { isArray, isString } from "lodash";
import axios, { AxiosPromise } from "axios";
import { toast } from "react-toastify";
import { ErrorCodes } from "../constants/Errors";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const fetcher = (url: string) => {
  const instanceUrl = !!instance?.defaults?.headers?.common["Authorization"]
    ? url
    : "";
  return instance.get(instanceUrl).then((res) => res.data);
};

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status !== 401 && error?.response?.status !== 403) {
      if (!!error?.response?.data?.message) {
        const message = error.response.data.message;
        const subCode = error.response.data.subCode;
        if (isArray(message)) {
          if (subCode !== ErrorCodes.BreadcrumbsNotExist)
            message.forEach((message) =>
              toast(message, {
                type: "error",
              })
            );
        } else if (isString(message)) {
          const isAssignmentFilterNotFoundError =
            error.response.status === 404 &&
            error.response.config?.url?.includes(
              "marketplaceItems/assignmentFilter"
            );
          if (!isAssignmentFilterNotFoundError) {
            toast(message, {
              type: "error",
            });
          }
        }
      } else {
        toast("Something went wrong!", {
          type: "error",
        });
      }
    }
    return Promise.reject(error);
  }
);

export const mutateData = <T = any>(
  type: "patch" | "delete" | "post" | "put" | "get",
  url: string,
  data?: any,
  headers?: {
    "Content-Type"?: string;
    "Access-Control-Allow-Origin"?: string;
  },
  responseType?: any
) => {
  return instance({
    method: type,
    url: url,
    data,
    headers,
    responseType: responseType,
  }) as AxiosPromise<T>;
};
