import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "@melp-design/style";

const width = 18;

export default makeStyles({
  iconWrapper: {
    display: "inline-flex",
    maxHeight: "1em",
    justifyContent: "center",
    maxWidth: width,
    width: width,
    minWidth: width,
    alignItems: "center",
  },
  chevronDown: {
    "& path": {
      fill: Colors.grey,
    },
  },
  clearIcon: {
    background: Colors.grey,
    color: Colors.white,
    width: 19,
    height: 19,
    borderRadius: "3px",
    position: "absolute",
    left: 0,
  },
  filterIcon: {
    "& path": {
      stroke: Colors.grey,
    },
  },
});
