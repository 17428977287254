import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import useStyles from "./Checkbox.styles";
import React from "react";
import { ReactComponent as CheckboxIcon } from "../../../assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/checked-checkbox.svg";
import { ReactComponent as IndeterminateIcon } from "../../../assets/icons/indeterminate-checkbox.svg";

export default function MelpCheckbox(props: CheckboxProps) {
  const classes = useStyles();
  return (
    <Checkbox
      {...props}
      classes={{
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      checkedIcon={<CheckedIcon />}
      icon={<CheckboxIcon className={classes.icon} />}
      indeterminateIcon={<IndeterminateIcon />}
    />
  );
}
