import { Box } from "@material-ui/core";
import useStyles from "./FlexBenefitAllocations.styles";
import { MpTable } from "@mp-react/table";
import React, { useCallback, useMemo, useState } from "react";
import { useEmployeeInnerFlexAllocationTableType } from "../../../../../configs/Tables/EmployeeInnerFlexAllocationsTableType";
import { rowMethods } from "../../../../../utils/TableMethods";
import useTableUtils from "../../../../../utils/Table";
import FormDialog from "../../../../../components/dialogs/FormDialog/FormDialog";
import { useTranslation } from "react-i18next";
import { UnpackNestedValue } from "react-hook-form";
import { useFlexBenefitAllocationsForm } from "../../../../../configs/Forms/AddFlexBenefitAllocationsForms";
import { Row, UseRowSelectRowProps } from "react-table";
import useFlexBenefitAllocations from "../../../../../state/FlexBenefitAllocation";
import { useParams } from "react-router-dom";
import { UpdateFlexBenefitAllocationRequest } from "../../../../../types/FlexBenefitAllocation";
import { useMe } from "../../../../../state/Administrators";
import clsx from "clsx";

export default function FlexBenefitAllocations() {
  const { canEditChoices } = useMe();
  const classes = useStyles();
  const { id } = useParams() as any;
  const {
    overridables,
    overrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
  } = useTableUtils("innerEmployeeFlexAllocation");
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<string>("");
  const FlexBenefitAllocationsTableType =
    useEmployeeInnerFlexAllocationTableType();
  const flexBenefitAllocationsForm = useFlexBenefitAllocationsForm();
  const { parsedEmployeeAllocationList, updateEmployeeAllocation, loading } =
    useFlexBenefitAllocations(id);

  const selectedRowData = useMemo(
    () => parsedEmployeeAllocationList.find((item) => item.id === selectedRow),
    [parsedEmployeeAllocationList, selectedRow]
  );

  const noData = useMemo(
    () => parsedEmployeeAllocationList.length > 0,
    [parsedEmployeeAllocationList]
  );

  const handleOpenDialog = useCallback(() => setOpen(true), []);
  const handleCloseDialog = useCallback(() => setOpen(false), []);
  const handleEdit = useCallback(
    (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      handleCloseDialog();
      updateEmployeeAllocation(
        data as UpdateFlexBenefitAllocationRequest,
        selectedRowData?.id as string
      );
    },
    [handleCloseDialog, selectedRowData?.id, updateEmployeeAllocation]
  );

  const handleRowClick = useCallback(
    (current: Row<{}> & UseRowSelectRowProps<{}>) => {
      if (canEditChoices) {
        setSelectedRow(current.id);
        const data = current.original as any;
        if (data.isPast && !data.isActive) return;
        handleOpenDialog();
      }
    },
    [canEditChoices, handleOpenDialog]
  );

  return (
    <Box
      className={clsx({
        [classes.hiddenColumn]: noData,
        [classes.disableTable]: !canEditChoices,
      })}
    >
      <MpTable
        {...FlexBenefitAllocationsTableType}
        data={parsedEmployeeAllocationList}
        onGetData={handleGetData}
        overridables={overridables}
        classes={overrideClasses}
        pageSize={20}
        disablePagination={true}
        loading={loading}
        rowMethods={rowMethods}
        translations={translations}
        enableGlobalActions={true}
        onRowClick={handleRowClick as any}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
      />
      <FormDialog
        onCancelClick={handleCloseDialog}
        onSubmitForm={handleEdit}
        id="edit-flex-benefit-allocation"
        open={open}
        title={t("employees.edit_flex_benefit_allocation")}
        actionLabel={t("common.confirm")}
        form={flexBenefitAllocationsForm}
        defaultValues={selectedRowData}
        loading={loading}
      />
    </Box>
  );
}
