import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      marginRight: 20,
      backgroundColor: Colors.White,
      color: Colors.TextSecondary,
    },
  })
);
