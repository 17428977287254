import React, { useMemo } from "react";
import {
  MpControlProps,
  controlRegisterOptions,
  ValidationKeys,
} from "@mp-react/form";
import { Controller } from "react-hook-form";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import useStyles from "./Dropdown.styles";
import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "../../../assets/icons/arrow-right.svg";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import { FormHelperText } from "@material-ui/core";
import { CustomFormControl } from "../../../types/Common";

function DropdownView({
  control,
  variant,
  onChange,
  value,
  inputRef,
  name,
  error,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
  name: string;
  inputRef: React.MutableRefObject<any>;
}) {
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const classes = useStyles();
  const selectOptions = useMemo(() => control?.children, [control]);
  const placeholder = useMemo(() => control?.placeholder, [control]);
  const id = useMemo(() => control?.key, [control]);
  const selected = useMemo(
    () => selectOptions && selectOptions[0]?.key,
    [selectOptions]
  );
  const disabled = (control as CustomFormControl).readonly;

  const handleValue = useMemo(() => {
    if (!value) {
      if (placeholder) {
        return "";
      } else {
        // Force first option selection
        onChange(selected);
        return selected;
      }
    }

    return value;
  }, [value, placeholder, onChange, selected]);

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <FormControl
        id="dropdown"
        variant={variant ?? "outlined"}
        fullWidth={true}
        required={control.required}
        className={classes.formControl}
        error={!!error}
        disabled={disabled}
      >
        {placeholder && (
          <InputLabel className={classes.placeholder} id={id}>
            {placeholder}
          </InputLabel>
        )}
        <Select
          value={handleValue}
          onChange={onChange}
          labelId={placeholder && id}
          inputRef={inputRef}
          name={name}
          disabled={disabled}
        >
          {selectOptions?.map((option: any) => (
            <MenuItem key={option.key} value={option.key ?? ""}>
              {option.type === "link" ? (
                <Link to="/" className={classes.link}>
                  {option.label} <LinkIcon />{" "}
                </Link>
              ) : (
                option.label
              )}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {error?.type
            ? control?.messages?.[error.type as ValidationKeys]
            : undefined}
        </FormHelperText>
      </FormControl>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

/**
 * @deprecated use Select component instead
 */
export default function Dropdown({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      control={hookFormControl}
      defaultValue={null}
      render={({ onChange, value, name, ref }) => (
        <DropdownView
          name={name}
          inputRef={ref}
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
