import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Log } from "../../../../../types/Logs";
import { Typography } from "@material-ui/core";

export default function FlexRemovedFromUser(props: Partial<Log>) {
  const { employeeId, employee, benefitPlan, benefitPlanType } = props;
  const { t } = useTranslation();

  const employeeName = useMemo(
    () => employee ?? employeeId ?? "",
    [employee, employeeId]
  );

  return useMemo(() => {
    return (
      <>
        {t("log.flex_removed_from_user")
          .split(" ")
          ?.map((text) => {
            switch (text) {
              case "{benefitName}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${benefitPlan} `}
                  </Typography>
                );
              case "{benefitType}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${benefitPlanType} `}
                  </Typography>
                );
              case "{employee}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {employeeName}
                  </Typography>
                );
              default:
                return `${text} `;
            }
          })}
      </>
    );
  }, [benefitPlan, benefitPlanType, employeeName, t]);
}
