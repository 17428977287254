export enum EntityTypes {
  benefitPlan = "benefitPlan",
  employee = "employee",
  admin = "admin",
  news = "news",
  employeeGroup = "employeeGroup",
}

export interface GlobalSearchItem {
  entityType: EntityTypes;
  entityId: string;
  description: string;
  benefitPlanType: string;
}

export interface GlobalSearchResponse {
  searchedResults: GlobalSearchItem[];
}

export interface UseGlobalSearch {
  loading: boolean;
  results?: GlobalSearchItem[];
  search: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  searchQuery: string;
  searchInputValue: string;
  clearSearch: () => void;
  handleSearchItemClick: (entityType: EntityTypes, entityId: string) => void;
}
