import { useBenefitsEmployeeGroups } from "./BenefitsAssignGroups";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useInvestmentCalculationsStore } from "./InvestmentCalculations";
import { Datepicker, MpTableDataArguments } from "@mp-react/table";
import create from "zustand";
import { createContext, useCallback, useMemo } from "react";
import {
  MpBulkActionCallback,
  MpBulkActionMethods,
  MpBulkActionParameters,
} from "@mp-react/table";
import { bulkMethods as mainBulkMethods } from "../utils/TableMethods";
import { ITableContext, TableVariables } from "../types/Table";
import { useBenefits } from "./Benefits";
import { toast } from "react-toastify";
import { useBenefitsAssignEmployees } from "./BenefitsAssignEmployees";
import {
  CheckAssignBaseResponse,
  Investment,
  InvestmentTypes,
} from "../types/Common";
import { BenefitStatus } from "../types/Benefits";
import useTableUtils from "../utils/Table";

export type HeaderVariant =
  | ""
  | "choices"
  | "benefits"
  | "employees"
  | "groups"
  | "innerEmployeeChoices"
  | "innerEmployeeFlexAllocation"
  | "innerEmployeeFlexBalance"
  | "innerEmployeeAssignBenefits"
  | "innerEmployeeAssignGroups"
  | "innerEmployeeLog"
  | "innerEmployeeTermsAcceptance"
  | "benefitsAssignEmployees"
  | "news"
  | "termsAcceptance"
  | "log"
  | "benefitsAssignGroups"
  | "dashboard"
  | "innerGroupAssignEmployees"
  | "innerBenefitsChoices"
  | "innerGroupAssignBenefits"
  | "innerBenefitTermsAcceptance"
  | "integrations"
  | "clients"
  | "compareMode"
  | "common"
  | "users"
  | "companySettingsAdministrators"
  | "companySettingsCompanies"
  | "companySettingsCompanyGroups"
  | "employeeCategories"
  | "clientAdministrators"
  | "marketplaceOrders"
  | "recipients";

type UseTableStore = {
  variant: HeaderVariant;
  setVariant: (variant: HeaderVariant) => void;
  searchQuery: string;
  datepickerValue: string;
  variables: TableVariables;
  customColumn: string | null;
  isOpenCustomColumnDialog: boolean;
  setVariables: (newVariables: MpTableDataArguments) => void;
  setDatepickerVariables: (dates: Datepicker) => void;
  setSearch: (searchQuery: string) => void;
  setDatepickerValue: (date: string) => void;
  setCustomColumn: (customColumn: string | null) => void;
  setIsOpenCustomColumnDialog: (open: boolean) => void;
};

export const useTable = (): any => {
  const { t } = useTranslation();
  const { id: benefitPlanId } = useParams<{ id: string }>();
  const { increaseChangeCount, setStatus } = useInvestmentCalculationsStore();
  const statuses = useInvestmentCalculationsStore((state) => state.statuses);
  const setInvestments = useInvestmentCalculationsStore(
    (state) => state.setInvestments
  );
  const { hasFlexSettings } = useBenefits(benefitPlanId);
  const { checkAssignEmployeeChanges } =
    useBenefitsAssignEmployees(benefitPlanId);
  const { checkBenefitToGroupChanges } =
    useBenefitsEmployeeGroups(benefitPlanId);
  const { isRowInvestmentAdded } = useTableUtils();

  const getSelectedStatus = useCallback(
    (id: string) => {
      return statuses?.currentStatus?.[id]?.value;
    },
    [statuses?.currentStatus]
  );

  const changeStatus = useCallback(
    async (newStatus: BenefitStatus, selected: MpBulkActionParameters) => {
      if (newStatus === "flex" && !hasFlexSettings) {
        toast(t("errors.flex_settings_not_set_for_this_benefit"), {
          type: "error",
        });
        return;
      }

      const { selectedRows } = selected;
      let assignmentErrors = 0;
      await Promise.all(
        (selectedRows ?? [])?.map(async (item) => {
          const { id, original } = item as any;
          const initialStatus = original?.currentStatus as BenefitStatus;
          const selectedStatus = getSelectedStatus(id);
          if (
            !selectedStatus &&
            (item.original as any)?.currentStatus === newStatus
          )
            return;

          const isAssignEmployeesTable = !!(item?.original as any)?.fullName;
          if (isAssignEmployeesTable) {
            const assignmentData = await checkAssignEmployeeChanges({
              benefitPlanId: benefitPlanId as string,
              newStatus: newStatus as "flex" | "on" | "off",
              employeeId: id as string,
            });

            if (
              !!assignmentData?.conflicts &&
              assignmentData.conflicts.length > 0
            ) {
              assignmentErrors++;
              return;
            }

            setStatus({ currentStatus: { [id]: { value: newStatus } } });
            increaseChangeCount(id);
            const investmentData: CheckAssignBaseResponse & Investment = {
              id: id,
              totalInvestmentAmount: assignmentData.totalInvestmentAmount,
              investmentAmountChange: assignmentData.investmentAmountChange,
              added: isRowInvestmentAdded(initialStatus, newStatus),
              investment: assignmentData.investmentAmountChange,
              name: (item?.original as any)?.fullName,
              type: InvestmentTypes.employee,
            };
            setInvestments(investmentData);
            return assignmentData;
          } else {
            const assignmentData = await checkBenefitToGroupChanges({
              benefitPlanId: benefitPlanId as string,
              newStatus: newStatus as "flex" | "on" | "off",
              employeeGroupId: id as string,
            });

            setStatus({ currentStatus: { [id]: { value: newStatus } } });
            increaseChangeCount(id);
            const investmentData: CheckAssignBaseResponse & Investment = {
              id: id,
              totalInvestmentAmount: assignmentData.totalInvestmentAmount,
              investmentAmountChange: assignmentData.investmentAmountChange,
              added: isRowInvestmentAdded(initialStatus, newStatus),
              investment: assignmentData.investmentAmountChange,
              name: (item?.original as any)?.name,
              type: InvestmentTypes.groups,
            };
            setInvestments(investmentData);
            return assignmentData;
          }
        })
      );

      if (assignmentErrors > 0) {
        toast(t("errors.could_not_make_some_assignments"), {
          type: "error",
        });
      }
    },
    [
      benefitPlanId,
      checkAssignEmployeeChanges,
      checkBenefitToGroupChanges,
      getSelectedStatus,
      hasFlexSettings,
      increaseChangeCount,
      isRowInvestmentAdded,
      setInvestments,
      setStatus,
      t,
    ]
  );

  const statusOn: MpBulkActionCallback = useCallback(
    (selected) => {
      changeStatus(BenefitStatus.on, selected);
    },
    [changeStatus]
  );

  const statusFlex: MpBulkActionCallback = useCallback(
    (selected) => {
      changeStatus(BenefitStatus.flex, selected);
    },
    [changeStatus]
  );

  const statusOff: MpBulkActionCallback = useCallback(
    (selected) => {
      changeStatus(BenefitStatus.off, selected);
    },
    [changeStatus]
  );

  const tableBulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      statusOn,
      statusFlex,
      statusOff,
    }),
    [statusFlex, statusOff, statusOn]
  );

  const bulkMethods = useMemo(
    () => ({ ...mainBulkMethods, ...tableBulkMethods }),
    [tableBulkMethods]
  );

  return {
    bulkMethods,
  };
};

export const useTableStore = create<UseTableStore>((set) => ({
  variant: "",
  setVariant: (variant: HeaderVariant) => set(() => ({ variant })),
  searchQuery: "",
  variables: { columns: [] },
  datepickerValue: "",
  setVariables: (newVariables: MpTableDataArguments) =>
    set(({ variables }) => ({ variables: { ...variables, ...newVariables } })),
  setDatepickerVariables: (dates: Datepicker) =>
    set(({ variables }) => ({ variables: { ...variables, ...dates } })),
  setSearch: (searchQuery: string) =>
    set(() => ({
      searchQuery,
    })),
  setDatepickerValue: (datepickerValue: string) =>
    set(() => ({
      datepickerValue,
    })),
  customColumn: null,
  setCustomColumn: (customColumn: string | null) => {
    set(() => ({ customColumn }));
  },
  isOpenCustomColumnDialog: false,
  setIsOpenCustomColumnDialog: (open: boolean) =>
    set(() => ({ isOpenCustomColumnDialog: open })),
}));

export const TableContext = createContext<ITableContext>({});
