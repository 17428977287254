import { useMe } from "./Administrators";
import { useLoading } from "../utils/Loading";
import { Endpoints } from "../api/constants";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useMemo, useCallback, useState } from "react";
import {
  UseEmployees,
  CreateEmployeeRequest,
  Employee,
  UpdateInnerEmployeeRequest,
  UseEmployeeStore,
  EmployeeResponse,
} from "../types/Employees";
import moment from "moment";
import { SimulationResponse } from "../types/NotificationService";
import { mutateData } from "../api/api";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useEmployeesUtils } from "../utils/Employees";
import create from "zustand";
import { Status } from "../types/Common";
import {
  mergeDateTime,
  browserLanguage,
  getTimezoneFromDate,
} from "../utils/Common";
import { useCurrency } from "../utils/useCurrency";
import { MpAsyncGetMethod } from "@mp-react/table";
import {
  EmployeeFilterNames,
  useDefaultStartTime,
} from "../constants/Employees";
import { MpAsyncGetMethodArguments } from "../types/Table";
import fileDownloader from "js-file-download";

export const useEmployees = (
  id?: string,
  query?: string,
  preventCall = false
): UseEmployees => {
  const { hasEmployeePermissions } = useMe();
  const { parseEmployeeResponseData, parseStatusDates } = useEmployeesUtils();
  const [userExist, setUserExist] = useState<boolean>(false);
  const { t } = useTranslation();
  const defaultStartDate = useDefaultStartTime();
  const {
    data: employeeList,
    error: employeeListError,
    mutate: mutateEmployeeTableData,
  } = useSWR(
    hasEmployeePermissions && !id && !preventCall
      ? `${Endpoints.employee}${!!query ? `?${query}` : ""}`
      : null
  );
  const { data: employee, error: employeeError } = useSWR(
    hasEmployeePermissions && id && !preventCall
      ? `${Endpoints.employee}/${id}`
      : null,
    {
      revalidateOnFocus: false,
    }
  );
  const { getDefaultCurrencyFormat } = useCurrency();

  const { stopLoading, startLoading, loading } = useLoading();

  const parsedEmployeeList = useMemo(
    () =>
      employeeList?.data?.map((data: Employee) => ({
        ...data,
        inactive: data.employmentStatus === "ended",
        customColumn: data?.customColumn?.name ?? "-",
      })) ?? [],
    [employeeList]
  );

  const parsedEmployeeTotals = useMemo(() => {
    const initialTotals = {
      fullName: "",
      employeeGroup: "",
      employeeGroups: "",
      jobTitle: "",
      investment: "",
      employmentStatus: "",
    };
    if (!employeeList?.footer) return initialTotals;
    const {
      fullName,
      employeeGroups,
      jobTitle,
      investment,
      employmentStatus,
      customColumn,
    } = employeeList.footer;
    return {
      fullName: t("totals.employee", { count: Number(fullName) }),
      employeeGroup: t("totals.employee_group", {
        count: Number(employeeGroups),
      }),
      jobTitle: t("totals.job_title", { count: Number(jobTitle) }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
      employmentStatus: t("totals.inactive", {
        count: Number(employmentStatus),
      }),
      employeeGroups: "",
      customColumn: t("totals.custom_category", {
        count: Number(customColumn ?? 0),
      }),
    };
  }, [employeeList, t, getDefaultCurrencyFormat]);

  const parsedEmployeeData = useMemo<any>(
    () => parseEmployeeResponseData(employee as EmployeeResponse),
    [employee, parseEmployeeResponseData]
  );

  const parsedEmployeeStatus = useMemo(
    () =>
      parseStatusDates(
        parsedEmployeeData?.startDate,
        parsedEmployeeData?.endDate,
        parsedEmployeeData?.state,
        parsedEmployeeData?.endDateTZ,
        parsedEmployeeData?.startDateTZ
      ),
    [
      parseStatusDates,
      parsedEmployeeData?.endDate,
      parsedEmployeeData?.endDateTZ,
      parsedEmployeeData?.startDate,
      parsedEmployeeData?.startDateTZ,
      parsedEmployeeData?.state,
    ]
  );

  const apiLoading = useMemo(() => {
    if (!!id) return !employee && !employeeError;
    return !employeeList && !employeeListError;
  }, [id, employee, employeeError, employeeList, employeeListError]);

  const checkIfUserExist = useCallback((response: any) => {
    if (response.error === "DuplicateError") {
      return setUserExist(true);
    } else {
      return setUserExist(false);
    }
  }, []);

  const createEmployee = useCallback(
    async (data: CreateEmployeeRequest) => {
      startLoading();
      const dataEntries = Object.entries(data);
      const dataFiltered = dataEntries?.filter(
        ([key]) => !key.includes("mergeGroup")
      );
      const parsedData = { ...Object.fromEntries(dataFiltered) };
      const startDate = mergeDateTime(
        parsedData.startDate,
        parsedData.startTime
      );
      const startDateTZ = getTimezoneFromDate(startDate);
      const phone = `+${parsedData.phone}`;
      const employmentDate = defaultStartDate ?? moment().format();
      delete parsedData.startTime;
      return await mutateData("post", Endpoints.createEmployee, {
        ...parsedData,
        startDate: startDate ?? defaultStartDate,
        startDateTZ,
        phone,
        employmentDate: employmentDate,
        employmentDateTZ: getTimezoneFromDate(employmentDate),
      })
        .then((res) => {
          mutate(Endpoints.employee);
          toast(t("common.added_succesfully"), { type: "success" });
          return res.data as AxiosResponse<any>;
        })
        .catch((err) => checkIfUserExist(err.response.data))
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, defaultStartDate, t, checkIfUserExist, stopLoading]
  );

  const updateInnerEmployee = useCallback(
    (data: UpdateInnerEmployeeRequest) => {
      const phone = data.phone.startsWith("+") ? data.phone : `+${data.phone}`;
      startLoading();
      mutateData("patch", `${Endpoints.employee}/${id}`, {
        ...data,
        capacity: !data.capacity ? "full-time" : data.capacity,
        phone: phone,
      })
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${id}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, id]
  );

  const assignGroupSimulation = useCallback(
    async (
      assignEmployeeGroupIds: string[],
      removeEmployeeGroupIds: string[]
    ) => {
      startLoading();
      const responceData = await mutateData(
        "patch",
        `${Endpoints.employee}/${id}/assignGroup/simulation`,
        {
          assignEmployeeGroupIds: assignEmployeeGroupIds,
          removeEmployeeGroupIds: removeEmployeeGroupIds,
        }
      )
        .then((res: any) => {
          mutate(`${Endpoints.employee}/${id}`);
          return res?.data as SimulationResponse[];
        })
        .finally(() => {
          stopLoading();
        });
      return responceData;
    },
    [startLoading, stopLoading, id]
  );

  const exportToExcel = useCallback(() => {
    startLoading();
    const url = `${Endpoints.employeesExportToExcel}${
      !!query ? `?${query}` : ""
    }`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    return mutateData("get", url, null, headers, "arraybuffer")
      .then((res: any) => {
        const now = moment().locale(browserLanguage).format("L");
        const disposition = res?.headers["content-disposition"];
        const headersFilename = disposition?.split("filename=")?.[1];
        const filename = headersFilename ?? `Employees_${now}.xlsx`;
        fileDownloader(res.data, filename);
      })
      .finally(() => {
        stopLoading();
      });
  }, [query, startLoading, stopLoading]);

  return {
    loading: loading || apiLoading,
    employee: parsedEmployeeData,
    employeeError,
    employeeList,
    employeeListError,
    createEmployee,
    checkIfUserExist,
    userExist,
    parsedEmployeeList,
    updateInnerEmployee,
    parsedEmployeeStatus,
    parsedEmployeeTotals,
    assignGroupSimulation,
    exportToExcel,
    mutateEmployeeTableData,
  };
};

export const useEmployeeStore = create<UseEmployeeStore>((set) => ({
  status: { name: "inactive" },
  setStatus: (obj: Status) => set(() => ({ status: obj })),
  startDate: null,
  endDate: null,
  setStartDate: (startDate: string | null, type?: "date" | "time") => {
    if (type === "date" && !!startDate) {
      set((state: UseEmployeeStore) => ({
        startDate: mergeDateTime(startDate, state.startDate ?? ""),
      }));
    } else if (type === "time" && !!startDate) {
      set((state: UseEmployeeStore) => ({
        startDate: mergeDateTime(state.startDate ?? "", startDate),
      }));
    } else set(() => ({ startDate: startDate }));
  },
  setEndDate: (endDate: string | null, type?: "date" | "time") => {
    if (type === "date" && !!endDate) {
      set((state: UseEmployeeStore) => ({
        endDate: mergeDateTime(endDate, state.endDate ?? ""),
      }));
    } else if (type === "time" && !!endDate) {
      set((state: UseEmployeeStore) => ({
        endDate: mergeDateTime(state.endDate ?? "", endDate),
      }));
    } else set(() => ({ endDate: endDate }));
  },
}));

export const useEmployeeAsyncMethods = (
  query?: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(() => `${Endpoints.employeeFilterValues}`, []);

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: EmployeeFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : ``;
      const queryParams = `${
        !!query && !!searchParam ? `&${query}` : !!query ? `?${query}` : ""
      }`;
      const apiUrl = `${baseUrl}/${filterName}${searchParam}${queryParams}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, query]
  );

  const getFilterItems = useCallback(
    (filterName: EmployeeFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}${!!query ? `?${query}` : ``}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl, query]
  );

  const getFullNames = useCallback<MpAsyncGetMethod>(
    (args) => getAsyncFilterItems(args, EmployeeFilterNames.FULL_NAME),
    [getAsyncFilterItems]
  );

  const getEmployeeGroups = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(EmployeeFilterNames.EMPLOYEE_GROUP),
    [getFilterItems]
  );

  const getJobTitles = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(EmployeeFilterNames.JOB_TITLE),
    [getFilterItems]
  );

  const getCustomColumnCategories = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(EmployeeFilterNames.CUSTOM_COLUMN),
    [getFilterItems]
  );

  return {
    getFullNames,
    getEmployeeGroups,
    getJobTitles,
    getCustomColumnCategories,
  };
};
