import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  CircularProgress,
  // Chip,
  // IconButton,
  // Tooltip,
  // Typography,
} from "@material-ui/core";
import useStyles from "./SettingsFooter.styles";
import { useTranslation } from "react-i18next";
// import useStatus from "../../../utils/Status";
// import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import ActionsPopper from "../../common/ActionsPopper/ActionsPopper";
// import FooterTooltipContent from "../../common/FooterTooltipContent/FooterTooltipContent";
// import { FooterTooltipHistory } from "../../../types/Common";
// import { formatDecimal } from "../../../utils/Common";
import { Control, useWatch } from "react-hook-form";
import { BenefitDetailsResponse } from "../../../types/Benefits";

interface IProps {
  control?: Control<Partial<BenefitDetailsResponse>>;
  currentInvestment: number;
  onCancel: () => void;
  onSaveDraft?: () => void;
  onPublish: (action: string, date?: string) => void;
  status: string;
  loading: boolean;
  isDraft?: boolean;
}

export default function SettingsFooter(props: IProps) {
  const {
    // currentInvestment,
    control,
    onCancel,
    onPublish,
    onSaveDraft,
    status,
    loading,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const popperActions = useMemo(
    () =>
      status === "will_be_inactive_on"
        ? ["save_deactivate", "save_deactivate_on_date"]
        : ["save_publish", "save_publish_on", "save_publish_on_date"],
    [status]
  );
  const saveDraftButtonTitle = props.isDraft
    ? t("buttons.save_as_draft")
    : t("common.save");

  // const newInvestment = useWatch({
  //   control,
  //   name: "investmentAmount",
  //   defaultValue: "default",
  // });

  // const parsedNewInvestment = useMemo(() => parseInt(newInvestment), [
  //   newInvestment,
  // ]);

  const publishFromValue = useWatch({
    control,
    name: "publishDate",
    defaultValue: "default",
  });

  // const difference = useMemo(() => parsedNewInvestment - currentInvestment, [
  //   currentInvestment,
  //   parsedNewInvestment,
  // ]);

  // const toolTipText: FooterTooltipHistory[] = useMemo(() => {
  //   if (parsedNewInvestment === currentInvestment || !currentInvestment)
  //     return [];

  //   return [
  //     {
  //       label: `${t("benefits.investment_amount_was")} ${t(
  //         `benefits.${
  //           parsedNewInvestment < currentInvestment ? "reduced" : "increased"
  //         }`
  //       )}`,
  //       cost: difference,
  //     },
  //   ];
  // }, [currentInvestment, difference, parsedNewInvestment, t]);

  // const chipLabel = useMemo(
  //   () =>
  //     difference > -1
  //       ? `+ ${formatDecimal(difference)}`
  //       : `- ${formatDecimal(Math.abs(difference))}`,
  //   [difference]
  // );

  // const parsedCurrentInvestment = useMemo(
  //   () => formatDecimal(currentInvestment),
  //   [currentInvestment]
  // );

  const handleOpenActions = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseActions = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleActionChange = useCallback(
    (action: string, date?: string) => {
      handleCloseActions();
      onPublish(action, date);
    },
    [handleCloseActions, onPublish]
  );

  const SaveDraftButton = useMemo(() => {
    if (onSaveDraft) {
      return (
        <Button
          type="submit"
          name="draft"
          variant="contained"
          disableElevation
          className={classes.draftButton}
          onClick={onSaveDraft}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : saveDraftButtonTitle}
        </Button>
      );
    }
  }, [onSaveDraft, classes.draftButton, saveDraftButtonTitle, loading]);

  return (
    <Box position="relative" width="100%" display="flex" alignItems="center">
      {/* <Box display="flex" alignItems="center">
        <Typography component={Box} paddingRight={1}>
          {t("common.current_investment")}:
        </Typography>
        <Typography component={Box} paddingRight={2} variant="subtitle1">
          {parsedCurrentInvestment}
        </Typography>
        <Chip component={Box} label={chipLabel} className={classes.chip} />
        <Tooltip
          title={
            <FooterTooltipContent
              currentCost={currentInvestment}
              newCost={parsedNewInvestment}
              history={toolTipText}
            />
          }
          classes={{ tooltip: classes.infoTooltip }}
        >
          <IconButton className={classes.infoButton}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box> */}
      <Box className={classes.buttonsContainer}>
        <Button
          disableElevation
          className={classes.button}
          onClick={onCancel}
          disabled={loading}
        >
          {t("common.cancel")}
        </Button>
        {SaveDraftButton}
        <Button
          name="publish"
          color="secondary"
          className={classes.button}
          variant="contained"
          disableElevation
          onClick={handleOpenActions}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : t("buttons.publish")}
        </Button>
      </Box>
      <ActionsPopper
        open={open && !loading}
        anchorEl={anchorEl}
        onClose={handleCloseActions}
        placement="bottom-start"
        actions={popperActions}
        onActionClick={handleActionChange}
        values={publishFromValue}
      />
    </Box>
  );
}
