import React, { PropsWithChildren } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./SidebarLayout.styles";

export default function SidebarLayout({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
  return (
    <Grid
      spacing={4}
      justifyContent="flex-start"
      classes={{ "spacing-xs-4": classes.spacing }}
      container
    >
      {children}
    </Grid>
  );
}
