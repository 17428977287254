import React, { useState, useCallback } from "react";
import SingleFieldDialog from "../../../dialogs/SingleFieldDialog/SingleFieldDialog";
import { useTranslation } from "react-i18next";
import AddNewButton from "../../../common/AddNewButton/AddNewButton";
import { Box } from "@material-ui/core";
import useEmployeeAssignGroups from "../../../../state/InnerEmployeeAssignGroup";
import { useMe } from "../../../../state/Administrators";
import { useParams } from "react-router-dom";

export default function InnerEmployeeAssignGroupsHeaders() {
  const { t } = useTranslation();
  const { canEditGroups } = useMe();
  const { id } = useParams() as any;
  const { createEmployeeGroup } = useEmployeeAssignGroups(id);
  const [open, setOpen] = useState<boolean>(false);

  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleOpenDialog = useCallback(() => setOpen(true), []);

  const handleAddDialog = useCallback(
    (value: string) => {
      handleCloseDialog();
      createEmployeeGroup(value);
    },
    [createEmployeeGroup, handleCloseDialog]
  );

  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      <AddNewButton disabled={!canEditGroups} onClick={handleOpenDialog}>
        {t("buttons.add_employee_group")}
      </AddNewButton>
      <SingleFieldDialog
        onCancelClick={handleCloseDialog}
        onActionClick={handleAddDialog}
        id="add-employee-group-dialog"
        open={open}
        title={t("groups.create_employee_group")}
        actionLabel={t("groups.create_group")}
        placeholder={t("groups.group_name")}
        tooltip="tooltip.create_employee_group_name"
      />
    </Box>
  );
}
