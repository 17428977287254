import React, { useCallback } from "react";
import { Tab, Tabs, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./HorizontalTabs.styles";
import clsx from "clsx";

export interface TabConfig<T> {
  key: T;
  /**
   * Plain string or translation resource key
   */
  label: string;
}

interface Props<T> {
  activeKey: T;
  onChange: (activeKey: T) => void;
  tabs: TabConfig<T>[];
}

const HorizontalTabs = <T extends string>({
  activeKey,
  onChange,
  tabs,
}: Props<T>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = useCallback((_, newKey) => onChange(newKey), [onChange]);

  const activeTabIndex = tabs.findIndex((tab) => tab.key === activeKey);

  return (
    <Box className={classes.tabsWrapper}>
      <Tabs
        value={activeKey}
        onChange={handleChange}
        TabIndicatorProps={{ children: <span /> }}
        classes={{ root: classes.tabs, indicator: classes.indicator }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.key}
            value={tab.key}
            label={
              <Typography variant="body1" color="textSecondary">
                {t(tab.label)}
              </Typography>
            }
            className={clsx({
              [classes.tabsDivider]: ![
                activeTabIndex - 1,
                activeTabIndex,
              ].includes(index),
            })}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default HorizontalTabs;
