import { IconButton } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Home } from "../../../assets/icons/home.svg";

import useStyles from "./HeaderButton.styles";

export default function HeaderButton() {
  const classes = useStyles();

  return (
    <NavLink to="/" exact activeClassName={classes.active}>
      <IconButton className={classes.menuButton} aria-label="menu">
        <Home color="inherit" />
      </IconButton>
    </NavLink>
  );
}
