import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Box } from "@material-ui/core";
import {
  FormProvider,
  SubmitErrorHandler,
  UnpackNestedValue,
  useForm,
} from "react-hook-form";
import FormTabs from "../../../../../components/common/FormTabs/FormTabs";
import StatusButton from "../../../../../components/common/StatusButton/StatusButton";
import Toolbar from "../../../../../components/layouts/Toolbar/Toolbar";
import FormFooter from "../../../../../components/layouts/FormFooter/FormFooter";
import Form from "../../../../../components/layouts/Form/Form";
import { useBenefitFormTabs } from "../../../../../constants/Benefits";
import { useBenefits, useBenefitStore } from "../../../../../state/Benefits";
import { BenefitPlanContext } from "../../BenefitsInner";
import { StatusActionKey } from "../../../../../types/Status";
import SettingsFooter from "../../../../../components/benefits/SettingsFooter/SettingsFooter";
import FormTabView from "../../../../../components/common/FormTabView/FormTabView";
import { useBenefitUtils } from "../../../../../utils/Benefits";
import moment from "moment";
import Loader from "../../../../../components/common/Loader/Loader";
import {
  TransformedBenefitResponse,
  UpdateBenefitRequest,
} from "../../../../../types/Benefits";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";
import useStyles from "../../../../../styles/Form.styles";
import { useMe } from "../../../../../state/Administrators";
import { browserLanguage, mergeDateTime } from "../../../../../utils/Common";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog/ConfirmationDialog";
import { useDialog } from "../../../../../utils/Dialog";

const Settings = React.lazy(() => import("./views/Settings/Settings"));
const Content = React.lazy(() => import("./views/Content/Content"));

export default function BenefitSettings() {
  const { canEditBenefits } = useMe();
  const classes = useStyles();
  const { t } = useTranslation();
  const benefitData = useContext(BenefitPlanContext);
  const { updateBenefit, apiLoading, loading } = useBenefits(benefitData?.id);
  const { transformBenefitData } = useBenefitUtils();

  const useFormMethods = useForm({
    defaultValues: benefitData,
  });
  const {
    handleSubmit,
    reset,
    formState,
    control,
    register,
    getValues,
    setValue,
    setError,
  } = useFormMethods;
  const formTabs = useBenefitFormTabs();
  const {
    getDataForRequest,
    parseStatusDates,
    loading: utilsLoading,
  } = useBenefitUtils();
  const [tabValue, setTabValue] = useState(0);
  const status = useMemo(
    () => parseStatusDates(benefitData as TransformedBenefitResponse),
    [benefitData, parseStatusDates]
  );
  const {
    status: publishStatus,
    publishForm,
    setPublishForm,
    resetForm,
    setActivationDate,
    setDeactivationDate,
    setPublishDate,
    setVotingEndDate,
    setVotingStartDate,
    publishDate,
    activationDate,
  } = useBenefitStore();

  const hasChanges = useMemo(() => formState.isDirty, [formState.isDirty]);
  const initialInvestment = useMemo(
    () => benefitData.investmentAmount ?? 0,
    [benefitData.investmentAmount]
  );

  const hasInvestment = useMemo(() => {
    if (initialInvestment === 0) return true;
    return !!initialInvestment;
  }, [initialInvestment]);

  const unfilledTabs: string[] = useMemo(() => {
    const hasUnfilledSettings =
      Object.keys(formState.errors).filter((item) => item !== "translations")
        .length > 0;
    const translations = formState.errors?.translations ?? {};
    return Object.keys(translations).concat(
      hasUnfilledSettings ? ["settings"] : []
    );
  }, [formState.errors]);

  const footerTransform = useMemo(
    () => `translateY(${hasChanges ? "0" : "70px"})`,
    [hasChanges]
  );

  const resetStateDates = useCallback(() => {
    setDeactivationDate(null);
    setActivationDate(null);
    setPublishDate(null);
    setVotingEndDate(null);
    setVotingStartDate(null);
  }, [
    setActivationDate,
    setDeactivationDate,
    setPublishDate,
    setVotingEndDate,
    setVotingStartDate,
  ]);

  const handleError: SubmitErrorHandler<Record<string, any>> = useCallback(
    (errors, e) => {
      toast(t("errors.check_validation"), { type: "error" });
    },
    [t]
  );

  const formatDateTime = useCallback((date: any, time: any) => {
    const votingDate = !!date
      ? moment(date).locale(browserLanguage).format("L")
      : "";
    const votingTime = !!time
      ? moment(time).locale(browserLanguage).format("LT")
      : "";
    return moment(`${votingDate}${!!votingTime ? ` ${votingTime}` : ""}`);
  }, []);

  const validateFormData = useCallback(
    (data: UnpackNestedValue<{}>, type: "publish" | "draft") => {
      const {
        activationDate,
        activationTime,
        deactivationDate,
        publishDate,
        deactivationTime,
        publishTime,
        votingEndDate,
        votingStartDate,
        investmentAmount,
      } = data as any;
      const isDraft = type === "draft";

      if (investmentAmount === null) {
        setError("investmentAmount", {
          type: "required",
        });
        return true;
      }

      if (!publishDate && !isDraft) {
        toast(t("errors.publish_date_missing"), {
          type: "error",
        });
        setError("publishDate", {
          type: "pattern",
        });
        return true;
      }

      if (!deactivationDate && !isDraft) {
        toast(t("errors.deactivation_date_missing"), {
          type: "error",
        });
        setError("deactivationDate", {
          type: "pattern",
        });
        return true;
      }

      if (!!publishDate && !publishTime) {
        toast(t("errors.publish_time_missing"), {
          type: "error",
        });
        setError("publishTime", {
          type: "pattern",
        });
        return true;
      }

      if (!!deactivationDate && !deactivationTime) {
        toast(t("errors.deactivation_time_missing"), {
          type: "error",
        });
        setError("deactivationTime", {
          type: "pattern",
        });
        return true;
      }

      if (!!deactivationDate) {
        const deactivationFullDate = formatDateTime(
          deactivationDate,
          deactivationTime
        );

        if (!!publishDate) {
          const publishFullDate = formatDateTime(publishDate, publishTime);
          if (moment(deactivationFullDate).isBefore(publishFullDate)) {
            toast(t("errors.deactivation_date_before_publish_date"), {
              type: "error",
            });
            setError("deactivationDate", {
              type: "minLength",
            });
            return true;
          }
        }

        if (!!activationDate) {
          const activationFullDate = formatDateTime(
            activationDate,
            activationTime
          );
          if (moment(deactivationFullDate).isBefore(activationFullDate)) {
            toast(t("errors.deactivation_date_before_activation_date"), {
              type: "error",
            });
            setError("deactivationDate", {
              type: "maxLength",
            });
            return true;
          }
        }
      }

      if (!!deactivationDate && !!publishDate) {
        const deactivationFullDate = formatDateTime(
          deactivationDate,
          deactivationTime
        );
        const publishFullDate = formatDateTime(publishDate, publishTime);
        if (moment(deactivationFullDate).isBefore(publishFullDate)) {
          toast(t("errors.deactivation_date_before_publish_date"), {
            type: "error",
          });
          setError("deactivationDate", {
            type: "minLength",
          });
          return true;
        }
      }

      if (!!votingStartDate && !!votingEndDate) {
        if (moment(votingEndDate).isBefore(votingStartDate)) {
          toast(t("errors.voting_end_date_before_voting_start_date"), {
            type: "error",
          });
          setError("votingEndDate", {
            type: "pattern",
          });
          return true;
        }
      }

      return false;
    },
    [formatDateTime, setError, t]
  );

  const fillMissingRequestValues = useCallback(
    (data: Partial<UpdateBenefitRequest>, isDraft: boolean) => {
      data.name = benefitData?.name;
      data.type = benefitData?.type;
      data.draft = isDraft;
      return data;
    },
    [benefitData?.name, benefitData?.type]
  );

  const onSubmit = useCallback(
    async (
      data: UnpackNestedValue<{}>,
      noRedirect?: boolean,
      type?: "draft" | "publish" | "deactivate"
    ) => {
      const isInvalid = validateFormData(data, "publish");
      if (isInvalid) return;
      const filledData = fillMissingRequestValues(data, false);
      const parseRequestData = getDataForRequest(filledData);
      if (!noRedirect) {
        reset(data, { isDirty: false });
      }
      const responseBenefitPlanDetails = await updateBenefit(
        parseRequestData,
        () => {
          resetStateDates();
        },
        noRedirect
      );
      const transformedResponseData = transformBenefitData(
        responseBenefitPlanDetails
      );
      reset(transformedResponseData, { isDirty: false });
    },
    [
      validateFormData,
      fillMissingRequestValues,
      getDataForRequest,
      reset,
      updateBenefit,
      transformBenefitData,
      resetStateDates,
    ]
  );

  const handleDatesBeforeSubmit = useCallback(
    (type: "publish" | "deactivate", date?: string) => {
      const publishNow = moment().format();
      const deactivateNow = moment().format();

      if (type === "publish") {
        setValue("publishDate", date ?? publishNow);
        setValue("publishTime", date ?? publishNow);
      } else if (type === "deactivate") {
        setValue("deactivationDate", date ?? deactivateNow);
        setValue("deactivationTime", date ?? deactivateNow);
      }
    },
    [setValue]
  );

  const checkTermsAndConditions = useCallback(async () => {
    if (!getValues("termsConditionsRequiredFrom"))
      register("termsConditionsFileId", { required: false });
    else register("termsConditionsFileId", { required: true });

    return;
  }, [getValues, register]);

  const { open, openDialog, closeDialog } = useDialog();
  const submitRef = useRef<() => void>();
  const handleConfirmation = useCallback(
    (close: () => void) => {
      submitRef.current?.();
      close();
    },
    [submitRef]
  );

  const handlePublish = useCallback(
    (type: "publish" | "deactivate", date?: string, noRedirect?: boolean) => {
      checkTermsAndConditions();
      handleDatesBeforeSubmit(type, date);
      const submit = handleSubmit(
        (data) => onSubmit(data, noRedirect),
        handleError
      );
      if (moment(activationDate).isBefore(moment(publishDate))) {
        submitRef.current = submit;
        openDialog();
      } else {
        submit();
      }
    },
    [
      checkTermsAndConditions,
      handleDatesBeforeSubmit,
      handleSubmit,
      handleError,
      activationDate,
      publishDate,
      onSubmit,
      openDialog,
    ]
  );

  const publishAction = useCallback(
    (action: string, date?) => {
      if (action === "save_deactivate") {
        handlePublish("deactivate");
      } else if (action === "save_deactivate_on_date") {
        handlePublish("deactivate", date);
      } else {
        let parsedDate = date;
        const timeValue = getValues("publishTime") as string;
        const dateValue = getValues("publishDate") as string;
        if (action === "save_publish_on")
          parsedDate = mergeDateTime(dateValue, timeValue);
        handlePublish("publish", parsedDate, false);
      }
    },
    [getValues, handlePublish]
  );

  const handleChangeTab = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setTabValue(newValue);
    },
    []
  );

  const handleChangeAction = useCallback((newValue: any) => {
    setTabValue(Number(newValue));
  }, []);

  const handleStatusChange = useCallback(
    (action: StatusActionKey, date?: string) => {
      switch (action) {
        case "save_publish_on_date":
          handlePublish("publish", date);
          break;
        case "save_deactivate_on_date":
          handlePublish("deactivate", date);
          break;
        case "save_publish":
          handlePublish("publish");
          break;
        case "save_deactivate":
          handlePublish("deactivate");
          break;
        case "save_publish_on":
          const timeValue = getValues("publishTime") as string;
          const dateValue = getValues("publishDate") as string;
          const publishDateTime = mergeDateTime(dateValue, timeValue) ?? "";
          handlePublish("publish", publishDateTime);
          break;
      }
    },
    [getValues, handlePublish]
  );

  const handleCancel = useCallback(() => {
    resetForm();
    reset(benefitData ?? {});
    resetStateDates();
  }, [benefitData, reset, resetForm, resetStateDates]);

  const handleSaveDraft = useCallback(async () => {
    if (benefitData.draft) {
      const formData = getValues();
      const isInvalid = validateFormData(formData, "draft");
      if (isInvalid) return;
      const filledValues = fillMissingRequestValues(
        formData as UpdateBenefitRequest,
        true
      );
      const requestData = getDataForRequest(filledValues);
      const responseData = await updateBenefit(requestData);
      const transformedResponseData = transformBenefitData(responseData);
      reset(transformedResponseData, { isDirty: false });
    } else {
      handlePublish("publish", publishDate as string, true);
    }
  }, [
    benefitData.draft,
    fillMissingRequestValues,
    getDataForRequest,
    getValues,
    handlePublish,
    publishDate,
    reset,
    transformBenefitData,
    updateBenefit,
    validateFormData,
  ]);

  useEffect(() => {
    if (publishForm) {
      if (publishStatus.name === "will_be_inactive_on")
        handlePublish("deactivate", publishStatus.date);
      if (publishStatus.name === "will_be_active_on")
        handlePublish("publish", publishStatus.date);
      if (publishStatus.name === "active") handlePublish("publish");
      if (publishStatus.name === "inactive") handlePublish("deactivate");

      setPublishForm(false);
    }
  }, [
    handlePublish,
    publishForm,
    publishStatus.date,
    publishStatus.name,
    setPublishForm,
  ]);

  useEffect(() => {
    if (!!benefitData?.publishDate) setPublishDate(benefitData.publishDate);

    if (!!benefitData?.activationDate)
      setActivationDate(benefitData.activationDate);

    if (!!benefitData?.deactivationDate)
      setDeactivationDate(benefitData.deactivationDate);

    if (!!benefitData?.votingStartDate)
      setVotingStartDate(benefitData.votingStartDate);

    if (!!benefitData?.votingEndDate)
      setVotingEndDate(benefitData.votingEndDate);

    reset(benefitData);
  }, [
    benefitData,
    reset,
    setActivationDate,
    setDeactivationDate,
    setPublishDate,
    setVotingEndDate,
    setVotingStartDate,
  ]);

  if (apiLoading) return <Loader />;

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        noValidate
      >
        <Prompt
          when={hasChanges}
          message={t("errors.user_leaving_edited_page")}
        />
        <Box display="flex">
          <Form maxWidth={780} width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormTabs
                value={tabValue}
                handleChange={handleChangeTab}
                onActionChange={handleChangeAction}
                moreButton={formTabs.length > 3}
                tabs={formTabs}
                unfilledTabs={unfilledTabs}
              />
              <Toolbar
                height={70}
                paddingBottom="30px"
                className={canEditBenefits ? "" : classes.disableFormFields}
              >
                <StatusButton
                  disabled={!hasInvestment}
                  status={status}
                  onActionChange={handleStatusChange}
                  id="benefits"
                  values={publishDate ?? ""}
                />
              </Toolbar>
            </Box>
            <Box>
              {formTabs.map((item, i) => (
                <FormTabView key={item.key} index={i} value={tabValue}>
                  <Suspense fallback={<Loader />}>
                    {item.key === "settings" ? (
                      <Settings useFormMethods={useFormMethods} />
                    ) : (
                      <div
                        className={
                          canEditBenefits ? "" : classes.disableFormFields
                        }
                      >
                        <Content
                          language={item.key}
                          useFormMethods={useFormMethods}
                        />
                      </div>
                    )}
                  </Suspense>
                </FormTabView>
              ))}
            </Box>
          </Form>
        </Box>

        <FormFooter
          containerProps={{
            style: {
              transform: footerTransform,
            },
          }}
        >
          <SettingsFooter
            control={control}
            currentInvestment={initialInvestment}
            onCancel={handleCancel}
            onPublish={publishAction}
            onSaveDraft={handleSaveDraft}
            status={status.name ?? ""}
            loading={loading || utilsLoading || !canEditBenefits}
            isDraft={benefitData.draft}
          />
        </FormFooter>

        <ConfirmationDialog
          id="latePublishConfirmation"
          content={t("benefits.publishedLaterThanActiveWarning")}
          onConfirm={handleConfirmation}
          onCancel={closeDialog}
          open={open}
          confirmLabel={t("common.confirm")}
        />
      </form>
    </FormProvider>
  );
}
