import React, { useCallback, useMemo, useRef } from "react";
import {
  MpControlProps,
  controlRegisterOptions,
  ValidationKeys,
} from "@mp-react/form";
import { Controller } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./Attachment.styles";
import { useFiles } from "../../../state/Files";
import FileField from "./FileField/FileField";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import { CustomFormControl } from "../../../types/Common";

function AttachmentView({
  onChange,
  size,
  control,
  value,
  error,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { uploadFile, loading } = useFiles();
  const hiddenFileInput = useRef(null);
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  const disabled = useMemo(
    () =>
      !!(control as CustomFormControl)?.readonly ||
      !!(control as CustomFormControl)?.disabled,
    [control]
  );
  const filesValue = useMemo(() => {
    if (!value) return null;
    if (typeof value === "string") return [value];
    return value;
  }, [value]);

  const maxFileAmount = useMemo(
    () => control?.format ?? null,
    [control?.format]
  );

  const showAddButton = useMemo(
    () =>
      filesValue === null ||
      maxFileAmount === null ||
      (filesValue as string[])?.length < Number(maxFileAmount),
    [maxFileAmount, filesValue]
  );

  const fileFieldMargin = useMemo(
    () =>
      ((filesValue as string[])?.length === 1 && showAddButton) ||
      (filesValue as string[])?.length > 1,
    [showAddButton, filesValue]
  );

  const handleBrowse = useCallback((e: any) => {
    const el = hiddenFileInput.current as any;
    el.click();
  }, []);

  const handleUploadFile = useCallback(
    async (e: any) => {
      e.stopPropagation();
      const { files } = e.target;
      if (files?.length > 0) {
        const file = files[0];
        if (control.key === "csvFile") {
          onChange(file);
          (hiddenFileInput.current as any).value = "";
          return;
        }
        const uploadedFile = await uploadFile(file);
        const uploadedFileId = uploadedFile?.id;
        if (uploadedFileId) {
          const currentFiles: string[] = filesValue ? [...filesValue] : [];
          const uploadedFileIds = [...currentFiles, uploadedFileId];
          onChange(uploadedFileIds);
          (hiddenFileInput.current as any).value = "";
        }
      }
    },
    [control.key, uploadFile, onChange, filesValue]
  );

  const removeFileHandler = useCallback(
    (fileId?: string) => {
      if (!fileId) {
        onChange();
        closeTooltip();
        return;
      }
      const uploadedFiles = [...filesValue];
      const updatedFiles = uploadedFiles.filter((id) => id !== fileId);
      onChange(updatedFiles);
      closeTooltip();
    },
    [filesValue, onChange, closeTooltip]
  );

  const FileFields = useMemo(() => {
    if (!filesValue) return null;
    if (typeof filesValue === "object" && control.key === "csvFile") {
      return (
        <Box
          marginBottom={fileFieldMargin ? 2 : 0}
          key={`file${filesValue.size}`}
        >
          <FileField
            size="small"
            removeFileHandler={() => removeFileHandler()}
            name={filesValue.name}
          />
        </Box>
      );
    }
    return (filesValue as string[])?.map((id, i) => (
      <Box marginBottom={fileFieldMargin ? 2 : 0} key={`file${i}`}>
        <FileField
          value={id}
          size={size}
          removeFileHandler={() => removeFileHandler(id)}
        />
      </Box>
    ));
  }, [filesValue, control.key, fileFieldMargin, removeFileHandler, size]);

  const fileValidation = useMemo(() => {
    if (control.key === "logoId") {
      return { accept: "image/png, image/jpeg", label: "jpeg, png" };
    }
    if (control.key === "termsConditionsFileId") {
      return { accept: "application/pdf", label: "pdf" };
    }
    if (control.key === "csvFile") {
      return { accept: ".csv", label: "csv" };
    }
    return { accept: "", label: null };
  }, [control.key]);

  return (
    <Box
      position="relative"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      {FileFields}
      {loading && <CircularProgress size={40} />}
      {showAddButton && !loading && (
        <>
          <Button
            onClick={handleBrowse}
            color="primary"
            className={classes.button}
            disabled={disabled}
          >
            <Typography variant="body2">
              + {t("buttons.add_attachment")}
            </Typography>
          </Button>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: 11 }}
            >
              {t("common.max_upload_size")} 25Mb
            </Typography>
            {!!fileValidation.label && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: 11 }}
              >
                {t("common.accepted_formats")} {fileValidation.label}
              </Typography>
            )}
          </Box>
        </>
      )}
      <input
        accept={fileValidation.accept}
        type="file"
        ref={hiddenFileInput}
        onChange={handleUploadFile}
        style={{ display: "none" }}
      />
      <Box mt="4px" ml="14px" mr="14px">
        <FormHelperText error={!!error?.type}>
          {error?.type
            ? control?.messages?.[error.type as ValidationKeys]
            : undefined}
        </FormHelperText>
      </Box>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}

export default function Attachment({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <AttachmentView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
