import React from "react";
// import EmployeeGroup from "../../../../components/employees/AssignBenefitsEmployeeGroups/AssignBenefitsEmployeeGroups";
import AddBenefits from "../../../../components/common/AddBenefits/AddBenefits";
import { Box } from "@material-ui/core";
import TableSearch from "../../TableSearch/TableSearch";
import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import { useTranslation } from "react-i18next";

export default function InnerEmployeeAssignBenefitsHeaders() {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <TableSearch />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {/* TODO: remove if InnerEmployeeAssignBenefits > EmployeeGroup is not needed anymore */}
        {/* 
          For this moment commented EmployeeGroup and added new "Assign groups" table.
          Since there is a lot of inventment and status change bugs from BE.
          For more information - ask Daugilas
        */}
        {/* <EmployeeGroup /> */}
        <TableDatepicker ml="20px" mr="20px" />
        <AddBenefits label={t("employees.add_benefits")} />
      </Box>
    </Box>
  );
}
