import React from "react";
import { Fade, Box } from "@material-ui/core";

interface IProps {
  children?: React.ReactNode;
  index: string | number;
  value: string | number;
}

export default function TabView(props: IProps) {
  const { children, value, index } = props;

  if (value !== index) return null;

  return (
    <Fade in={value === index} timeout={180}>
      <Box>{children}</Box>
    </Fade>
  );
}
