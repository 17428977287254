import { Box, ListItem, ListItemProps, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./SidebarItemDark.styles";

interface Props extends ListItemProps {
  label: string;
  icon?: React.ReactNode;
}

export default function SidebarItemDark({
  label,
  children,
  icon,
  ...rest
}: Props) {
  const classes = useStyles();

  return (
    <ListItem
      classes={{ root: classes.listItemRoot }}
      button
      disableRipple
      {...(rest as any)}
    >
      <Box className={classes.labelWrapper}>
        <Typography variant="body2">{label}</Typography>
        {icon && icon}
      </Box>
      {children}
    </ListItem>
  );
}
