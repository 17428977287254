import React, { useMemo, useState } from "react";
import {
  TextField,
  TextFieldProps,
  Box,
  Chip,
  makeStyles,
} from "@material-ui/core";
import { MpControlProps, controlRegisterOptions } from "@mp-react/form";
import { Controller } from "react-hook-form";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useTooltip from "../../../utils/Tooltip";
import { CustomFormControl } from "../../../types/Common";
import { isDefined } from "../../../utils/isDefined";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    display: "flex",
    flexWrap: "wrap",
    gap: 5,
    padding: 8,
  },
  input: {
    width: 100,
    flexGrow: 1,
    padding: "7px 6px 6px 6px",
  },
  tagLabel: {
    fontWeight: "normal",
  },
}));

interface Props
  extends Pick<
    TextFieldProps,
    "id" | "onMouseEnter" | "onMouseLeave" | "disabled"
  > {
  value?: number[];
  onChange?: (value: number[]) => void;
  min?: number;
  max?: number;
  decimalPlaces?: number;
}

const MultiNumber = ({
  value,
  onChange,
  min,
  max,
  decimalPlaces,
  ...props
}: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const values = value ?? [];
  return (
    <TextField
      {...props}
      value={inputValue}
      onChange={(e) => {
        const newInputValue = e.target.value;
        const decimals = newInputValue.split(/[.,]/)[1] ?? "";
        if (isDefined(decimalPlaces) && decimals.length > decimalPlaces) {
          return;
        }
        setInputValue(newInputValue);
      }}
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      inputProps={{
        min,
        max,
        step: isDefined(decimalPlaces)
          ? Math.pow(10, -1 * decimalPlaces).toFixed(decimalPlaces)
          : undefined,
      }}
      InputProps={{
        startAdornment: (
          <>
            {values.map((item) => {
              return (
                <Chip
                  key={item}
                  label={
                    isDefined(decimalPlaces)
                      ? item.toFixed(decimalPlaces)
                      : item
                  }
                  onDelete={() => {
                    const filteredItems = values.filter((v) => v !== item);
                    onChange?.(filteredItems);
                  }}
                  classes={{ label: classes.tagLabel }}
                  disabled={props.disabled}
                />
              );
            })}
          </>
        ),
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          let valueToAdd = parseFloat(inputValue);
          if (Number.isNaN(valueToAdd)) {
            return;
          }
          if (isDefined(min) && valueToAdd < min) {
            valueToAdd = min;
          }
          if (isDefined(max) && valueToAdd > max) {
            valueToAdd = max;
          }
          if (!values.includes(valueToAdd)) {
            const newInputValue = [...values, valueToAdd];
            onChange?.(newInputValue);
          }
          setInputValue("");
        }
      }}
    />
  );
};

const MultiNumberFormElement = ({
  value,
  onChange,
  ...props
}: MpControlProps & Props) => {
  const control = props.control as CustomFormControl;
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);
  return (
    <Box position="relative">
      <MultiNumber
        {...control.props}
        value={value}
        onChange={onChange}
        disabled={control.disabled}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        min={control.validations?.min}
        max={control.validations?.max}
      />
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
};

const MultiNumberControl = (props: MpControlProps) => {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <MultiNumberFormElement {...props} onChange={onChange} value={value} />
      )}
    />
  );
};

export default MultiNumberControl;
