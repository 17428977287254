import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  MenuItem,
  Select,
  Typography,
  Box,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./CustomizeColumnDialog.styles";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useTooltip from "../../../utils/Tooltip";
import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "../../../assets/icons/arrow-right.svg";
import { useMe } from "../../../state/Administrators";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps extends DialogProps {
  actionLabel: string;
  onCancelClick: () => void;
  handleSubmit: () => void;
  handleChange: (value: string) => void;
  title: string;
  options: string[];
  value: any;
}

const CustomizeColumnDialog = ({
  actionLabel,
  onCancelClick,
  handleSubmit,
  handleChange,
  title,
  options,
  value,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openTooltip, closeTooltip, anchorEl } = useTooltip();
  const { canEditCustomEmployeeCategory } = useMe();

  const handleSelectChange = useCallback(
    (e: any) => {
      handleChange(e.target.value);
      closeTooltip();
    },
    [closeTooltip, handleChange]
  );

  const handleClose = useCallback(() => {
    onCancelClick();
    closeTooltip();
  }, [closeTooltip, onCancelClick]);

  const LinkContent = useMemo(() => {
    if (canEditCustomEmployeeCategory) {
      return (
        <MenuItem
          component={Link}
          to={
            "/more/company-settings?tab=companySettingsCustomEmployeeCategory"
          }
          className={classes.link}
        >
          {t("form.manage")}
          <LinkIcon />
        </MenuItem>
      );
    }
  }, [canEditCustomEmployeeCategory, classes.link, t]);

  const Options = useMemo(
    () =>
      options?.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      )),
    [options]
  );

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      onClose={handleClose}
      onEnterPress={handleSubmit}
      {...rest}
    >
      <Typography variant="h5">{title}</Typography>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Box
          position="relative"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          <Select
            variant="outlined"
            value={value}
            onChange={handleSelectChange}
            fullWidth
          >
            {Options}
            {LinkContent}
          </Select>
          <FormTooltip
            tooltip="tooltip.choose_custom_column"
            anchorEl={anchorEl}
          />
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={onCancelClick} variant="text">
          {t("common.cancel")}
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {actionLabel}
        </Button>
      </DialogActions>
    </DialogExtended>
  );
};

export default CustomizeColumnDialog;
