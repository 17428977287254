import React, { ReactNode, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { Colors } from "../../constants/Style";

const normalizeSearchValue = (value: string) => {
  return value
    .toLowerCase()
    .replaceAll(" ", "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

const useStyles = makeStyles((theme) => ({
  optionsContainer: {
    maxHeight: 260,
    overflow: "scroll",
    paddingBottom: theme.spacing(1),
  },
  filterOption: {
    width: "100%",
    margin: 0,
    padding: "5px 0",
    transition: "background 100ms ease-in-out",
    "&:hover": {
      background: theme.palette.grey[100],
    },
  },
  activeOption: {
    background: Colors.LightPrimary,
  },
  label: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: theme.spacing(1, 1, 1, 2),
  },
  labelMultipleMode: {
    paddingLeft: 0,
  },
  notDisplayed: {
    display: "none",
  },
  optionsSearchContainer: {
    padding: theme.spacing(1),
  },
  optionsSearchField: {
    width: "100%",
  },
  filterActions: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  filterAction: {
    height: 40,
  },
}));

interface LabeledOption {
  key: string;
  label: string;
}

interface Props<T> {
  value?: T;
  onApplyFilter: (value: T) => void;
  onClearFilter: () => void;
  options?: LabeledOption[] | string[];
  loadingOptions?: boolean;
  /**
   * Skips local options search and relies on externally provided options.
   * To attach to search events please see onSearch property.
   */
  asyncSearch?: boolean;
  /**
   * Is called when search box is idling for 300ms. It's only invoked, if
   * asyncSearch is set to true.
   */
  onSearch?: (request: string) => void;
  /**
   * Minimal amount of symbols to perform search.
   */
  searchMinLength?: number;
  /**
   * Indicates whether multiple options can be selected
   */
  multiple?: boolean;
}

const SelectFilter = <T extends string | string[]>({
  value,
  options,
  multiple = false,
  searchMinLength,
  asyncSearch = false,
  ...props
}: Props<T>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    (typeof value === "string" ? [value] : value) ?? []
  );

  const [searchText, setSearchText] = useState("");
  const handleSearch = useDebouncedCallback((request: string) => {
    if (request.length > 2) {
      return props.onSearch?.(request);
    }
  }, 300);
  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    if (asyncSearch) {
      handleSearch(newValue);
    }
  };

  const renderInDefaultContainer = (node: ReactNode) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      p={2}
      minHeight={150}
    >
      {node}
    </Box>
  );

  const renderOptions = () => {
    if (props.loadingOptions) {
      return renderInDefaultContainer(<CircularProgress />);
    }
    if (searchMinLength !== undefined && searchText.length < searchMinLength) {
      return renderInDefaultContainer(
        <Typography>{t("common.start_search")}</Typography>
      );
    }
    if (!options?.length) {
      return renderInDefaultContainer(
        <Typography>{t("common.noFilterOptions")}</Typography>
      );
    }
    const labeledOptions = options.map((option) => {
      if (typeof option === "string") {
        return { key: option, label: option };
      }
      return option;
    });
    const filteredOptions = asyncSearch
      ? labeledOptions
      : labeledOptions.filter((option) =>
          normalizeSearchValue(option.label).includes(
            normalizeSearchValue(searchText)
          )
        );
    if (!filteredOptions.length) {
      return renderInDefaultContainer(
        <Typography>{t("common.noFilterOptions")}</Typography>
      );
    }
    return filteredOptions.map(({ key, label }) => {
      return (
        <FormControlLabel
          key={key}
          label={label}
          className={clsx(
            classes.filterOption,
            selectedKeys.includes(key) && classes.activeOption
          )}
          classes={{
            label: clsx(classes.label, multiple && classes.labelMultipleMode),
          }}
          control={
            <Checkbox
              color="primary"
              className={clsx(!multiple && classes.notDisplayed)}
              checked={selectedKeys.includes(key)}
              name={key}
              onChange={(e) => {
                const { checked, name } = e.target;
                if (multiple) {
                  setSelectedKeys((currentKeys) => {
                    if (checked) {
                      return [...currentKeys, name];
                    } else {
                      return currentKeys.filter(
                        (currentKey) => currentKey !== name
                      );
                    }
                  });
                } else {
                  props.onApplyFilter(name as T);
                }
              }}
            />
          }
        />
      );
    });
  };

  return (
    <>
      <div className={classes.optionsSearchContainer}>
        <TextField
          variant="outlined"
          size="small"
          className={classes.optionsSearchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchText && (
                  <IconButton onClick={() => setSearchText("")}>
                    <Cross />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          placeholder={t("common.searchOptionsPlaceholder")}
          autoFocus
          value={searchText}
          onChange={handleSearchTextChange}
        />
      </div>
      <div className={classes.optionsContainer}>{renderOptions()}</div>
      <div className={classes.filterActions}>
        {!!value?.length && (
          <Button
            size="small"
            className={classes.filterAction}
            onClick={props.onClearFilter}
          >
            {t("table.clear")}
          </Button>
        )}
        {multiple && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.filterAction}
            onClick={() => props.onApplyFilter(selectedKeys as T)}
            disabled={!selectedKeys.length}
          >
            {t("common.applyFilter")}
          </Button>
        )}
      </div>
    </>
  );
};

export default SelectFilter;
