import { Grid, GridProps, Paper } from "@material-ui/core";
import React from "react";

export default function InnerWithTabsContainer(props: GridProps) {
  return (
    <Paper>
      <Grid container {...props} />
    </Paper>
  );
}
