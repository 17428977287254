import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { Typography } from "@material-ui/core";
import { useRendererOptions } from "@mp-react/table";
import { useDateFormat } from "../../../utils/Common";
import { useTranslation } from "react-i18next";

export default function DateFormat(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { value } = useRendererOptions(props);
  const { fullDate } = useDateFormat(value);

  const date = useMemo(() => {
    if (!fullDate) {
      return t("common.dash");
    } else {
      return fullDate;
    }
  }, [fullDate, t]);

  return (
    <Typography variant="body2" align="center">
      {date}
    </Typography>
  );
}
