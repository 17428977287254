import { BorderRadius, Colors } from "./../../../constants/Style";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .react-tel-input": {
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        height: 44,
        "& .form-control": {
          paddingLeft: 14,
          paddingTop: 12,
          paddingBottom: 13,
          paddingRight: 40,
          height: "100%",
          width: "100%",
          borderRadius: BorderRadius.MainRadius,
          "&:active": {
            borderWidth: 1,
            borderColor: Colors.Primary,
          },
          "&:focus": {
            borderWidth: 1,
            borderColor: Colors.Primary,
          },
          "&:hover": {
            borderColor: "#ccd2d9",
          },
        },
        "& .flag-dropdown": {
          background: "none",
          border: "none",
          right: 0,
          pointerEvents: "none",
          "& .selected-flag": {
            "&:hover": {
              background: "none",
              border: "none",
            },
          },
          "& .arrow": {
            display: "none",
          },
        },
      },
    },
    error: {
      "& .react-tel-input .form-control": {
        borderColor: Colors.Error,
      },
    },
  })
);
