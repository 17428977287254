import React, { useCallback, useContext } from "react";
import { Box } from "@material-ui/core";
import { MpTable } from "@mp-react/table";
import { useAssignGroupsTableType } from "../../../../../configs/Tables/AssignGroupsTableType";
import useTableUtils from "../../../../../utils/Table";
import FormFooter from "../../../../../components/layouts/FormFooter/FormFooter";
import { TableContext, useTable } from "../../../../../state/Table";
import AssignGroupsFooter from "../../../../../components/benefits/AssignGroupsFooter/AssignGroupsFooter";
import {
  useBenefitsEmployeeGroups,
  useBenefitAssignGroupsAsyncMethods,
} from "../../../../../state/BenefitsAssignGroups";
import { useTranslation } from "react-i18next";
import FormDialog from "../../../../../components/dialogs/FormDialog/FormDialog";
import { useChoicesDeadlineForm } from "../../../../../configs/Forms/BenefitsForms/Settings/ChoicesDeadlineForm";
import { BenefitPlanContext } from "../../BenefitsInner";
import { useBenefits, useBenefitStore } from "../../../../../state/Benefits";
import {
  UpdateBenefitRequest,
  BenefitStatus,
} from "../../../../../types/Benefits";
import moment from "moment";
import { toast } from "react-toastify";
import useStyles from "./AssignGroups.styles";
import { useInvestmentCalculationsStore } from "../../../../../state/InvestmentCalculations";
import {
  CheckAssignBaseResponse,
  Investment,
  InvestmentTypes,
} from "../../../../../types/Common";
import { useMe } from "../../../../../state/Administrators";
import clsx from "clsx";
import { useBenefitUtils } from "../../../../../utils/Benefits";

export default function AssignGroups() {
  const { canEditBenefits, canEditGroups } = useMe();
  const classes = useStyles();
  const assignGroupsTableType = useAssignGroupsTableType();
  const { t } = useTranslation();
  const {
    overridables,
    overrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
    isRowInvestmentAdded,
  } = useTableUtils("benefitsAssignGroups");
  const { bulkMethods } = useTable();
  const { id, name } = useContext(BenefitPlanContext);
  const {
    benefitAssignGroupsData,
    rowMethods,
    assignEmployeeGroups,
    loading,
    parsedBenefitAssignGroupsTotals,
    isFlexFormOpen,
    closeFlexForm,
    selectedRowData,
    changeResponse,
  } = useBenefitsEmployeeGroups(id, tableQueryParam);
  const { updateBenefit, loading: loadingBenefits } = useBenefits(id);
  const flexForm = useChoicesDeadlineForm(true);
  const asyncGetMethods = useBenefitAssignGroupsAsyncMethods(id);
  const { parseChoicesDateForRequest } = useBenefitUtils();

  const { votingStartDate, votingEndDate } = useBenefitStore(
    useCallback(({ votingEndDate, votingStartDate }) => {
      return { votingEndDate, votingStartDate };
    }, [])
  );
  const resetAllChanges = useInvestmentCalculationsStore(
    useCallback((state) => state.resetAllChanges, [])
  );
  const setStatus = useInvestmentCalculationsStore(
    useCallback((state) => state.setStatus, [])
  );
  const increaseChangeCount = useInvestmentCalculationsStore(
    useCallback((state) => state.increaseChangeCount, [])
  );
  const setInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.setInvestments, [])
  );
  const removeChange = useInvestmentCalculationsStore(
    useCallback((state) => state.removeChange, [])
  );
  const resetInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.resetInvestments, [])
  );

  const handleCancel = useCallback(() => {
    resetAllChanges();
    setStatus(null);
    resetInvestments();
  }, [resetAllChanges, resetInvestments, setStatus]);

  const handleFlexSettingSubmit = useCallback(
    (data: Partial<UpdateBenefitRequest>) => {
      if (!!votingStartDate && !!votingEndDate) {
        if (moment(votingEndDate).isBefore(votingStartDate)) {
          toast(t("errors.voting_end_date_before_voting_start_date"), {
            type: "error",
          });
          return;
        }
        data.votingStartDate = votingStartDate;
        data.votingEndDate = votingEndDate;
      }

      const parsedData = parseChoicesDateForRequest(data);
      const initialStatus = selectedRowData?.currentStatus as BenefitStatus;

      updateBenefit(parsedData as UpdateBenefitRequest, () => {
        closeFlexForm();
        increaseChangeCount(selectedRowData?.id ?? "");
        const statusValues = {
          currentStatus: { [selectedRowData?.id ?? ""]: { value: "flex" } },
        };
        setStatus(statusValues);
        if (!changeResponse) return;
        const investmentData: CheckAssignBaseResponse & Investment = {
          id: selectedRowData?.id ?? "",
          totalInvestmentAmount: changeResponse?.totalInvestmentAmount,
          investmentAmountChange: changeResponse?.investmentAmountChange,
          added: isRowInvestmentAdded(initialStatus, BenefitStatus.flex),
          investment: changeResponse?.investmentAmountChange,
          name: selectedRowData?.name ?? "",
          type: InvestmentTypes.groups,
        };
        setInvestments(investmentData);
      });
    },
    [
      changeResponse,
      closeFlexForm,
      increaseChangeCount,
      isRowInvestmentAdded,
      parseChoicesDateForRequest,
      selectedRowData?.currentStatus,
      selectedRowData?.id,
      selectedRowData?.name,
      setInvestments,
      setStatus,
      t,
      updateBenefit,
      votingEndDate,
      votingStartDate,
    ]
  );

  const handleFlexFormCancel = useCallback(() => {
    closeFlexForm();
    setStatus(null, selectedRowData?.id);
    removeChange(selectedRowData?.id as string);
  }, [closeFlexForm, removeChange, selectedRowData?.id, setStatus]);

  return (
    <TableContext.Provider value={{ id, tableQueryParam }}>
      <Box
        className={clsx({
          [classes.table]: true,
          [classes.disableTable]: !canEditBenefits || !canEditGroups,
        })}
      >
        <MpTable
          {...assignGroupsTableType}
          data={benefitAssignGroupsData}
          onGetData={handleGetData}
          bulkMethods={bulkMethods}
          overridables={overridables}
          totalsData={parsedBenefitAssignGroupsTotals}
          totals={benefitAssignGroupsData?.length > 0}
          classes={overrideClasses}
          loading={loading}
          rowMethods={rowMethods}
          translations={translations}
          loadingComponent={loadingComponent}
          emptyChildren={noDataComponent}
          asyncGetMethods={asyncGetMethods}
        />
        <FormFooter withChanges>
          <AssignGroupsFooter
            onCancel={handleCancel}
            onPublish={assignEmployeeGroups}
          />
        </FormFooter>
        <FormDialog
          onCancelClick={handleFlexFormCancel}
          onSubmitForm={handleFlexSettingSubmit}
          id="change-choices-deadline-form"
          open={isFlexFormOpen}
          title={t(
            "assign_employees.benefit_choices_deadline_not_set"
          )?.replace("{name}", name ?? "")}
          actionLabel={t("common.confirm")}
          form={flexForm}
          subtitle={t("assign_employees.fill_in_fields_to_change_status")}
          loading={loadingBenefits}
        />
      </Box>
    </TableContext.Provider>
  );
}
