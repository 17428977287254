import { Typography } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function StringArrray(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { value } = useRendererOptions(props);
  const joinedValue = useMemo(
    () => value?.slice(0, 2)?.join(", ") ?? "",
    [value]
  );
  const parsedValue = useMemo(
    () =>
      `${joinedValue}${
        value?.length > 2
          ? `... + ${value?.length - 2} ${t("sidebar.more")}`
          : ""
      }`,
    [joinedValue, t, value?.length]
  );

  return <Typography variant="body2">{parsedValue}</Typography>;
}
