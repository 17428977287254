import React, { useCallback, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./DeleteEmployeeDialog.styles";
import MelpRadio from "../../common/Radio/Radio";
import DialogExtended from "../../common/DialogExtended/DialogExtended";
import clsx from "clsx";

interface IProps extends DialogProps {
  onCancelClick: () => void;
  id: string;
  firstName: string;
  onActionClick: (value: string) => void;
}

export const DeleteEmployeeDialog = ({
  onCancelClick,
  id,
  firstName,
  onActionClick,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState<string>("deactivate");

  const handleChange = useCallback(
    (e: any) => {
      setSelectedValue(e.target.value);
    },
    [setSelectedValue]
  );

  const isDeleteSelected = selectedValue === "delete";

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={onCancelClick}
      onEnterPress={() => onActionClick(selectedValue)}
      {...rest}
    >
      <Typography variant="h5">
        {t("employees.sure_to_delete", { employee: firstName })}
      </Typography>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <RadioGroup value={selectedValue} onChange={handleChange}>
          <Grid container>
            <Grid item xs>
              <MelpRadio color="primary" value="delete" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="subtitle1">{t("actions.delete")}</Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ whiteSpace: "break-spaces" }}
              >
                {t("employees.delete_user_info")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 30 }}>
            <Grid item xs>
              <MelpRadio color="primary" value="deactivate" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="subtitle1">
                {t("employees.deactivate_user")}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t("employees.deactivate_user_info")}
              </Typography>
            </Grid>
          </Grid>
        </RadioGroup>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          onClick={onCancelClick}
          variant="text"
          style={{ marginRight: 12 }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          onClick={() => onActionClick(selectedValue)}
          variant="contained"
          color="primary"
          className={clsx({
            [classes.deleteButton]: isDeleteSelected,
          })}
        >
          {isDeleteSelected
            ? t("actions.delete")
            : t("employees.deactivate_user")}
        </Button>
      </DialogActions>
    </DialogExtended>
  );
};
