import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    "@keyframes bounce": {
      "0%": { transform: "translateY(0)" },
      "50%": { transform: "translateY(-15px)" },
      "100%": { transform: "translateY(0)" },
    },
    pointingArrow: {
      width: 30,
      height: 30,
      position: "absolute",
      top: "150px",
      left: "280px",
      zIndex: 9999,
      animation: "$bounce 700ms ease infinite",
    },
  })
);
