import React, { useMemo } from "react";
import { MpControlProps, controlRegisterOptions } from "@mp-react/form";
import { Controller } from "react-hook-form";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import useStyles from "./Checkbox.styles";
import MuiCheckbox from "../../common/Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";

function CheckboxView({
  control,
  onChange,
  value,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ root: classes.root }}
      control={
        <MuiCheckbox
          onChange={(e) => onChange(e.target.checked)}
          checked={value}
          color="primary"
        />
      }
      label={<Typography variant="body2">{control.label}</Typography>}
      onChange={onChange}
    />
  );
}

export default function Checkbox({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const { t } = useTranslation();
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  const { tooltip, openTooltip, closeTooltip, anchorEl } = useTooltip(control);

  if (control.children && control.children?.length === 0)
    return (
      <Box paddingTop="8px">
        <Typography variant="body2">{t("common.no_items")}</Typography>
      </Box>
    );

  return (
    <Box
      position="relative"
      pt="2px"
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <FormGroup>
        {control.children?.map((child) => (
          <Controller
            name={`${control.key}.${child.key}` ?? ""}
            rules={rules}
            control={hookFormControl}
            key={child.key}
            defaultValue={false}
            render={({ onChange, value }) => (
              <CheckboxView
                onChange={onChange}
                value={value}
                control={child}
                locale={locale}
                size={size}
                layout={layout}
                variant={variant}
                error={error}
                defaultMessages={defaultMessages}
              />
            )}
          />
        ))}
      </FormGroup>
      <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
    </Box>
  );
}
