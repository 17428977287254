import moment from "moment";

import { useCallback, useEffect, useMemo } from "react";
import {
  Datepicker,
  DatepickerListItem,
  Months,
  useStoredState,
} from "@mp-react/table";
import { useLocation } from "react-router";
import { browserLanguage, capitalizeFirst } from "./Common";
import { useDateFormat } from "./Common";
import { useTranslation } from "react-i18next";

export const getMonths = (year: number, t: any, lang: string): Months => {
  const curYear = moment().format("YYYY");
  const isCurrentYear = Number(curYear) === year;
  const curMonth = Number(moment().format("M"));
  moment.locale(lang);
  const months: Months = {
    q1: getQuarterMonths(1, year),
    q2: getQuarterMonths(2, year),
    q3: getQuarterMonths(3, year),
    q4: getQuarterMonths(4, year),
  };
  let parsedMonths = {};
  Object.entries(months)?.forEach(([key, val]) => {
    const qMonths: DatepickerListItem[] = val?.map(
      (monthObj: DatepickerListItem) => {
        if (
          Number(moment().month(monthObj.value).format("M")) > curMonth &&
          isCurrentYear
        )
          return null;
        return monthObj;
      }
    );

    const filteredQMonths = qMonths?.filter((item) => !!item);
    if (filteredQMonths?.length > 0) {
      parsedMonths = {
        ...parsedMonths,
        [key]: filteredQMonths,
      };
    }
  });
  return parsedMonths as Months;
};

const getQuarterMonths = (quarter: number, year: number) => {
  const quarters = 12 / 4;
  const from = quarters * quarter - quarters;
  const to = quarters * quarter;
  let months: DatepickerListItem[] = [];
  for (let i = from; i < to; i++) {
    months = [
      ...months,
      {
        key: "month",
        label: capitalizeFirst(moment.months(i)),
        value: `${capitalizeFirst(moment.monthsShort(i))}, ${year}`,
      },
    ];
  }
  return months.reverse();
};

export const getQuarters = (year: number, months: Months) => {
  const curQuarter = Number(moment().format("Q"));
  const isCurrentYear = Number(moment().format("YYYY")) === year;
  let quarters: any[] = [];
  for (let i = 4; i >= 1; i--) {
    const monthItems = months[`q${i}` as keyof Months];
    if ((i <= curQuarter || !isCurrentYear) && !!monthItems) {
      quarters = [
        ...quarters,
        {
          key: "quarter",
          label: `Q${i}`,
          value: `Q${i}, ${year}`,
          items: monthItems,
        },
      ];
    }
  }
  return quarters;
};

export const useDatepickerData = () => {
  const { t } = useTranslation();
  let year = Number(moment().format("YYYY"));
  const { localeLanguage } = useDateFormat();

  const years = useMemo((): DatepickerListItem[] => {
    const items = [];

    while (year > 2015) {
      const stringifiedYear = year.toString();
      const months = getMonths(year, t, localeLanguage);
      const quarters = getQuarters(year, months);
      items.push({
        key: "year",
        label: stringifiedYear,
        value: stringifiedYear,
        items: quarters,
      });
      year--;
    }

    return items;
  }, [localeLanguage, t, year]);

  return years;
};

export default function useDatepicker() {
  const location = useLocation();
  const { localeLanguage } = useDateFormat();
  const [date, setDate] = useStoredState<string | null>("datepicker", null);
  const [dateFilter, setDateFilter] = useStoredState<Datepicker | null>(
    "datepickerISO",
    null
  );
  const [dateFrom, setDateFrom] = useStoredState<string | null>(
    "datepickerDateFrom",
    null
  );
  const [dateTo, setDateTo] = useStoredState<string | null>(
    "datepickerDateTo",
    null
  );

  const parseQuarter = useCallback((quarterString: string): Datepicker => {
    const quarterNum: number = Number(quarterString.charAt(1));
    const year: string = quarterString.substr(3);
    const date = moment(year).quarter(quarterNum);
    return {
      dateFrom: date
        .startOf("quarter")
        .locale(browserLanguage)
        .format("YYYY-MM-DD"),
      dateTo: date
        .endOf("quarter")
        .locale(browserLanguage)
        .format("YYYY-MM-DD"),
    };
  }, []);

  const parseYear = useCallback((yearString: string): Datepicker => {
    const date = moment(yearString);
    return {
      dateFrom: date
        .startOf("year")
        .locale(browserLanguage)
        .format("YYYY-MM-DD"),
      dateTo: date.endOf("year").locale(browserLanguage).format("YYYY-MM-DD"),
    };
  }, []);

  const parseMonth = useCallback(
    (monthString: string): Datepicker => {
      const [monthName, year] = monthString.split(", ");
      const date = moment(year).locale(localeLanguage).month(monthName);

      return {
        dateFrom: date.startOf("month").format("YYYY-MM-DD"),
        dateTo: date.endOf("month").format("YYYY-MM-DD"),
      };
    },
    [localeLanguage]
  );

  const parseDate = useCallback(
    (dateString: string | null) =>
      !!dateString &&
      moment(dateString).locale(browserLanguage).format("YYYY MMM"),
    []
  );

  const setDatepickerValues = useCallback(
    (
      value: string,
      key: string,
      callback: (data: Datepicker | null) => void
    ) => {
      if (key === "dateFrom") {
        const dateFromLabel = parseDate(value);
        const dateToLabel = parseDate(dateTo) ?? "";
        const label = `${dateFromLabel} - ${dateToLabel}`;
        callback({
          dateFrom: dateFrom ?? "",
          dateTo: dateTo ?? "",
        });
        setDate(label);
      } else if (key === "dateTo") {
        callback({
          dateFrom: dateFrom ?? "",
          dateTo: dateTo ?? "",
        });
        const dateFromLabel = parseDate(dateFrom) ?? "";
        const dateToLabel = parseDate(value);
        const label = `${dateFromLabel} - ${dateToLabel}`;
        setDate(label);
      } else {
        setDate(value);
      }

      let filter: Datepicker | null = null;
      switch (key) {
        case "month":
          filter = parseMonth(value);
          break;
        case "quarter":
          filter = parseQuarter(value);
          break;
        case "year":
          filter = parseYear(value);
          break;
        case "dateFrom":
          setDateFrom(value);
          break;
        case "dateTo":
          setDateTo(value);
      }
      if (!!filter) {
        callback(filter);
        setDateFilter(filter);
        setDate(value);
      }
    },
    [
      dateFrom,
      dateTo,
      parseDate,
      parseMonth,
      parseQuarter,
      parseYear,
      setDate,
      setDateFilter,
      setDateFrom,
      setDateTo,
    ]
  );

  const handleFilterClick = useCallback(() => {
    if (!!dateFrom && !!dateTo)
      setDateFilter({
        dateFrom,
        dateTo,
      });
  }, [dateFrom, dateTo, setDateFilter]);

  const clearSelected = useCallback(
    (callback: (data: Datepicker | null) => void) => {
      setDate(null);
      setDateFilter(null);
      callback(null);
    },
    [setDate, setDateFilter]
  );

  const clearDatepicker = useCallback(() => {
    localStorage.removeItem("datepicker");
    localStorage.removeItem("datepickerDateFrom");
    localStorage.removeItem("datepickerDateTo");
    localStorage.removeItem("datepickerISO");
    setDate(null);
    setDateFilter(null);
    setDateFrom(null);
    setDateTo(null);
  }, [setDate, setDateFilter, setDateFrom, setDateTo]);

  useEffect(() => {
    clearDatepicker();
  }, [clearDatepicker, location]);

  return {
    date,
    setDatepickerValues,
    dateFilter,
    clearSelected,
    handleFilterClick,
    dateFrom,
    dateTo,
  };
}
