import { Box, IconButton, Tooltip } from "@material-ui/core";
import React, { useCallback } from "react";
import AddBenefits from "../../../../components/common/AddBenefits/AddBenefits";
// import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { useChoices } from "../../../../state/Choices";
import useTableUtils from "../../../../utils/Table";

export default function ChoicesHeader() {
  const { t } = useTranslation();
  const { tableQueryParam } = useTableUtils("choices");
  const { exportToExcel } = useChoices(tableQueryParam);

  const handleDownload = useCallback(() => {
    exportToExcel();
  }, [exportToExcel]);

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Tooltip title={t("dashboard.download_xls") || "XLS"}>
        <IconButton onClick={handleDownload} style={{ marginRight: "20px" }}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      {/* <TableDatepicker marginRight="20px" /> */}
      <AddBenefits label={t("employees.add_benefits")} />
    </Box>
  );
}
