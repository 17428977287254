import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "white",
      borderRadius: "8px",
      marginTop: 0,
      marginBottom: 0,
    },
    searchButton: {
      pointerEvents: "none",
      height: 38,
      size: 38,
      padding: "7px",
    },
    clearButton: {
      height: 38,
      size: 38,
    },
  })
);
