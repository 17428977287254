import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    dateInput: {
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: 0,
      },
    },
    readonly: {
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
        {
          borderColor: Colors.BorderOutlinedInput,
        },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        background: "unset",
      },
    },
  })
);
