import {
  Box,
  Divider,
  InputBase,
  TextFieldProps,
  Typography,
  Popover,
  MenuItem,
} from "@material-ui/core";
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MpFilterProps } from "@mp-react/table/build/types/Filter";
import { MetaContext, useFilterOptions } from "@mp-react/table";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { Timezone } from "../../../../types/Common";
import { useTimezones } from "../../../../constants/Timezones";
import moment from "moment";
import useStyles from "./DateTimeRange.styles";
import {
  useDatePlaceholder,
  getTimePlaceholder,
  browserLanguage,
  useDateFormat,
} from "../../../../utils/Common";

function InputBaseWithProps({
  InputProps,
  margin,
  onKeyDown,
  onKeyUp,
  helperText,
  ...props
}: TextFieldProps) {
  return <InputBase {...InputProps} {...props} />;
}

export default function DateTimeRange({
  value,
  setValue,
  doFiltering,
  column,
}: MpFilterProps) {
  const { mpColumn } = column;
  const classes = useStyles();
  const timezones = useTimezones();
  const filter = useFilterOptions(mpColumn);
  const { translations } = useContext(MetaContext);
  const now = useMemo(() => moment(), []);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [selectedZone, setSelectedZone] = useState<Timezone>({
    value: 2,
    label: "GMT+2",
  });
  const { datePlaceholder } = useDatePlaceholder();
  const { keyboardDatePickerFormat } = useDateFormat();

  const handleChangeDateMin = useCallback(
    (date: MaterialUiPickersDate) => {
      const evVal = date;
      if (evVal != null) {
        setValue((val: any) => ({ ...(val ?? {}), dateMin: evVal }));
      } else {
        setValue((val: any) =>
          val?.date.max != null && val?.date.max !== ""
            ? { dateMax: val?.date.max }
            : null
        );
      }
    },
    [setValue]
  );

  const handleChangeTimeMin = useCallback(
    (time: MaterialUiPickersDate) => {
      const evVal = time;
      if (evVal != null) {
        setValue((val: any) => ({ ...(val ?? {}), timeMin: evVal }));
      } else {
        setValue((val: any) =>
          val?.time.max != null && val?.time.max !== ""
            ? { timeMax: val?.time.max }
            : null
        );
      }
    },
    [setValue]
  );

  const handleChangeDateMax = useCallback(
    (date: MaterialUiPickersDate) => {
      const evVal = date;
      if (evVal != null) {
        setValue((val: any) => ({ ...(val ?? {}), dateMax: evVal }));
      } else {
        setValue((val: any) =>
          val?.date.min != null && val?.date.min !== ""
            ? { dateMin: val?.date.min }
            : null
        );
      }
    },
    [setValue]
  );

  const handleChangeTimeMax = useCallback(
    (time: MaterialUiPickersDate) => {
      const evVal = time;
      if (evVal != null) {
        setValue((val: any) => ({ ...(val ?? {}), timeMax: evVal }));
      } else {
        setValue((val: any) =>
          val?.time.min != null && val?.time.min !== ""
            ? { timeMin: val?.time.min }
            : null
        );
      }
    },
    [setValue]
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering]
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleTimezoneChange = useCallback((timezone: Timezone) => {
    setSelectedZone(timezone);
    setOpen(false);
  }, []);

  const valueDateMin = useMemo(
    () => moment(value?.dateMin).locale(browserLanguage),
    [value?.dateMin]
  );

  const valueDateMax = useMemo(
    () => moment(value?.dateMax).locale(browserLanguage),
    [value?.dateMax]
  );

  return (
    <Box className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={browserLanguage}>
        <Box display="flex" flexDirection="column">
          <Box
            paddingLeft={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2">{filter?.label ?? ""}</Typography>
            <Box paddingX={2} paddingY={1.5}>
              <Typography
                variant="body2"
                color="textSecondary"
                onClick={handleClear}
                className={classes.emptyAnchor}
              >
                {translations?.clear ?? "Clear"}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box display="flex">
            <Box className={classes.keyboardDatePickerContainer}>
              <KeyboardDatePicker
                autoOk={true}
                disableToolbar
                variant="inline"
                format={keyboardDatePickerFormat}
                label="Date picker inline"
                placeholder={datePlaceholder}
                value={valueDateMin ?? now}
                onChange={handleChangeDateMin}
                TextFieldComponent={InputBaseWithProps}
                fullWidth
                keyboardIcon={<CalendarIcon />}
                className={classes.keyboardDatePicker}
              />
            </Box>
            <Box maxWidth={130} className={classes.keyboardTimePickerContainer}>
              <KeyboardTimePicker
                ampm={false}
                value={value?.timeMin ?? now}
                placeholder={getTimePlaceholder()}
                fullWidth={true}
                InputLabelProps={{ shrink: true }}
                variant="inline"
                inputVariant="outlined"
                onChange={handleChangeTimeMin}
                onOpen={() => {
                  handleToggle();
                }}
                PopoverProps={{
                  style: { display: "none" },
                }}
                size="small"
                keyboardIcon={
                  <Typography ref={anchorRef} variant="body2">
                    {selectedZone.label}
                  </Typography>
                }
                className={classes.keyboardTimePicker}
              />
            </Box>
          </Box>
          <Divider />
          <Box display="flex">
            <Box className={classes.keyboardDatePickerContainer}>
              <KeyboardDatePicker
                autoOk={true}
                disableToolbar
                variant="inline"
                format={keyboardDatePickerFormat}
                label="Date picker inline"
                placeholder={datePlaceholder}
                value={valueDateMax ?? now}
                onChange={handleChangeDateMax}
                TextFieldComponent={InputBaseWithProps}
                keyboardIcon={<CalendarIcon />}
                fullWidth
                className={classes.keyboardDatePicker}
              />
            </Box>
            <Box maxWidth={130} className={classes.keyboardTimePickerContainer}>
              <KeyboardTimePicker
                ampm={false}
                value={value?.timeMax ?? now}
                placeholder={getTimePlaceholder()}
                fullWidth={true}
                InputLabelProps={{ shrink: true }}
                variant="inline"
                inputVariant="outlined"
                onChange={handleChangeTimeMax}
                PopoverProps={{
                  style: { display: "none" },
                }}
                onOpen={() => {
                  handleToggle();
                }}
                size="small"
                keyboardIcon={
                  <Typography ref={anchorRef} variant="body2">
                    {selectedZone.label}
                  </Typography>
                }
                className={classes.keyboardTimePicker}
              />
            </Box>
          </Box>
        </Box>
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          PaperProps={{
            style: { width: 100, maxHeight: 270 },
          }}
          onClose={handleToggle}
          anchorOrigin={{
            vertical: "bottom" as "bottom",
            horizontal: "right" as "right",
          }}
          transformOrigin={{
            vertical: "top" as "top",
            horizontal: "right" as "right",
          }}
        >
          <Box>
            {timezones?.map((item, i) => (
              <MenuItem
                key={i}
                selected={item.value === selectedZone.value}
                onClick={() => handleTimezoneChange(item)}
              >
                <Typography variant="body2">{item.label}</Typography>
              </MenuItem>
            ))}
          </Box>
        </Popover>
      </MuiPickersUtilsProvider>
    </Box>
  );
}
