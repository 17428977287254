import React from "react";
import { Fade, Box } from "@material-ui/core";

interface IProps {
  children?: React.ReactNode;
  index: string | number;
  value: string | number;
}

export default function FormTabView(props: IProps) {
  const { children, value, index } = props;

  return (
    <Box hidden={value !== index}>
      <Fade in={value === index} timeout={180}>
        <Box>{children}</Box>
      </Fade>
    </Box>
  );
}
