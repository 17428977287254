import {
  Typography,
  Switch,
  Box,
  Chip,
  Fade,
  Popper,
  useMediaQuery,
} from "@material-ui/core";
import { CellProps } from "react-table";
import {
  handleRowAction,
  useRendererOptions,
  getColumnAction,
  RtMpColumnInstance,
  MetaContext,
  MpRowActionMethods,
} from "@mp-react/table";
import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { useInvestmentCalculationsStore } from "../../../state/InvestmentCalculations";

export default function IncludedExcludedSwitch(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { /*data,*/ column, row } = props;
  const { rowMethods } = useContext(MetaContext);
  const showChip = useMediaQuery("(min-width:1460px)");
  const [chipContent, setChipContent] = useState<string | null>(null);
  // const rowIndex = useMemo(() => row.index, [row.index]);
  // const [fadeIn, setFadeIn] = useState<boolean>(false);
  const action = useMemo(
    () => getColumnAction(column as RtMpColumnInstance),
    [column]
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { t } = useTranslation();

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (open ? row.id : undefined), [open, row.id]);

  const { changedRowIds } = useInvestmentCalculationsStore();

  const toggle = useInvestmentCalculationsStore(
    useCallback(
      (state) => state?.statuses?.[column.id]?.[row.id]?.value ?? value,
      [column.id, row.id, value]
    )
  );

  const setStatus = useInvestmentCalculationsStore(
    useCallback((state) => state.setStatus, [])
  );

  const removeChange = useInvestmentCalculationsStore(
    useCallback((state) => state.removeChange, [])
  );

  // const investmentAmount = useMemo(() => (data[rowIndex] as any)?.investment, [
  //   data,
  //   rowIndex,
  // ]);

  const text = useMemo(() => {
    if (!!toggle) {
      return t("assign_employees.included");
    } else {
      return t("assign_employees.excluded");
    }
  }, [toggle, t]);

  const fadeIn = useMemo(() => value !== toggle, [value, toggle]);

  const setNewValue = useCallback(
    (newValue: boolean | null) => {
      const statusValues = { [column.id]: { [row.id]: { value: newValue } } };
      setStatus(statusValues);
    },
    [column.id, row.id, setStatus]
  );

  const handleToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked;
      setNewValue(newValue);
      // if (switchStatus) {
      //   setChipContent(`- ${investmentAmount}`);
      // } else {
      //   setChipContent(`+ ${investmentAmount || 0}`);
      // }
      setChipContent(t("common.changed"));
      handleRowAction({
        methods: rowMethods as MpRowActionMethods,
        slug: action.slug,
        value: newValue as boolean,
        row: row,
        rowId: row.id,
      });
    },
    [row, t, rowMethods, action.slug, setNewValue]
  );

  const handleAnchor = useCallback((e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  useEffect(() => {
    if (value === toggle && changedRowIds.includes(row.id)) {
      removeChange(row.id);
    }
  }, [removeChange, row.id, toggle, value, changedRowIds]);

  const chip = useMemo(() => {
    if (!showChip) return null;

    return (
      <Popper
        id={id}
        open={open && fadeIn}
        anchorEl={anchorEl}
        placement="right-end"
        style={{ zIndex: 1 }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box>
              <Chip
                style={{
                  marginLeft: 30,
                  marginBottom: 10,
                  textTransform: "capitalize",
                }}
                label={chipContent}
                color="primary"
              />
            </Box>
          </Fade>
        )}
      </Popper>
    );
  }, [anchorEl, chipContent, fadeIn, id, open, showChip]);

  return (
    <Box
      position="relative"
      aria-describedby={id}
      onClick={handleAnchor}
      padding="14px 12px"
    >
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Typography variant="body2">{text}</Typography>
        <Switch color="primary" onChange={handleToggle} checked={toggle} />
      </Box>
      {chip}
    </Box>
  );
}
