import { Colors } from "./../../../constants/Style";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 0,
    },
    icon: {
      "& path": {
        stroke: Colors.Primary,
      },
    },
  })
);
