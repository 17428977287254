import { Colors } from "./../../../constants/Style";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      transition: theme.transitions.create("width"),
      "& .MuiOutlinedInput-root": {
        background: Colors.White,
      },
    },
    result: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    resultTextSpacing: {
      paddingLeft: 5,
    },
  })
);
