import { Box, BoxProps, CircularProgress } from "@material-ui/core";
import React from "react";
import useStyles from "./Loader.styles";

export default function Loader(props: BoxProps) {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper} {...props}>
      <CircularProgress color="primary" disableShrink />
    </Box>
  );
}
