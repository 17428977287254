import { Box, Chip, Fade, Icon, Popper, RootRef } from "@material-ui/core";
import { CellProps } from "react-table";
import {
  getColumnAction,
  handleRowAction,
  MetaContext,
  MpRowActionItem,
  MpRowActionMethods,
  RtMpColumnInstance,
  useRendererOptions,
} from "@mp-react/table";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useCallback } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { ToggleButtonGroup } from "@material-ui/lab";
import useStyles from "./CustomRenderers.styles";
import clsx from "clsx";
import { useInvestmentCalculationsStore } from "../../../state/InvestmentCalculations";
import { useCurrency } from "../../../utils/useCurrency";
import { BenefitsAssignGroupsTableItem } from "../../../types/Benefits";
import moment from "moment";

export default function StatusToggle(props: CellProps<{}>) {
  const classes = useStyles();
  const { getDefaultCurrencyNumber } = useCurrency();
  const { value } = useRendererOptions(props);
  const { column, row } = props;
  const { rowMethods, size } = useContext(MetaContext);
  const action = useMemo(
    () => getColumnAction(column as RtMpColumnInstance),
    [column]
  );
  const { items } = action;

  const containerRef = useRef<HTMLElement | null>();

  const toggle = useInvestmentCalculationsStore(
    useCallback(
      (state) => state.statuses?.[column.id]?.[row.id]?.value ?? value,
      [column.id, row.id, value]
    )
  );
  const setStatus = useInvestmentCalculationsStore(
    useCallback((state) => state.setStatus, [])
  );
  const removeChange = useInvestmentCalculationsStore(
    useCallback((state) => state.removeChange, [])
  );
  const investmentState = useInvestmentCalculationsStore(
    useCallback(
      (state) =>
        state.investments.find((investment) => investment.id === row.id)
          ?.investment,
      [row.id]
    )
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (open ? row.id : undefined), [open, row.id]);

  const fadeIn = useMemo(() => value !== toggle, [value, toggle]);

  const currentInvesment = useMemo(() => {
    if ((investmentState !== 0 && !investmentState) || toggle === "flex")
      return "";
    const investmentAbs = Math.abs(investmentState)?.toFixed();
    const formattedInvestment = getDefaultCurrencyNumber(investmentAbs);
    const symbol = investmentState < 0 ? "-" : "+";
    return `${symbol} ${!!formattedInvestment ? formattedInvestment : 0}`;
  }, [getDefaultCurrencyNumber, investmentState, toggle]);

  useEffect(() => {
    if (value === toggle) {
      removeChange(row.id);
    }
  }, [removeChange, row.id, toggle, value]);

  const setNewValue = useCallback(
    (newValue: string | null) => {
      const { statuses } = row.original as BenefitsAssignGroupsTableItem;
      const todaysStatus = statuses.find((status) =>
        moment(status?.fromDate).isSame(moment(), "day")
      );
      const assignmentId = todaysStatus?.id ?? null;
      const statusValues = {
        [column.id]: {
          [row.id]: {
            value: newValue,
            assignmentId: assignmentId,
            currentRowStatuses: statuses,
          },
        },
      };
      setStatus(statusValues);
    },
    [column.id, row.id, row.original, setStatus]
  );

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
      setNewValue(newValue);
      handleRowAction({
        methods: rowMethods as MpRowActionMethods,
        slug: action.slug,
        value: newValue as string,
        row: row,
        rowId: row.id,
      });
    },
    [setNewValue, rowMethods, action.slug, row]
  );

  const stopPropagation = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
    },
    []
  );

  const mapToggles = useMemo(
    () =>
      (items ? items : []).map((item: MpRowActionItem) => {
        const { value, icon, label, color, textColor } = item;
        const inner = icon ? <Icon>{icon}</Icon> : label;
        const maxHeight = size === "small" ? 24 : undefined;

        const style =
          toggle === value
            ? {
                color: textColor,
                backgroundColor: color,
                maxHeight,
              }
            : {
                maxHeight,
              };

        const toggleButtonClass = clsx({
          [classes.statusToggleButton]: true,
          [classes.offTransform]: toggle === "off",
          [classes.flexTransform]: toggle === "flex",
          [classes.onTransform]: toggle === "on",
        });

        return (
          <ToggleButton
            key={`toggleButton-${row.id}-${value}`}
            size={size}
            value={value}
            aria-label="toggle-action"
            style={style}
            className={toggleButtonClass}
          >
            {inner}
          </ToggleButton>
        );
      }),
    [
      items,
      size,
      toggle,
      classes.statusToggleButton,
      classes.offTransform,
      classes.flexTransform,
      classes.onTransform,
      row.id,
    ]
  );

  const chip = useMemo(
    () => (
      <Popper
        id={id}
        open={open && fadeIn}
        anchorEl={anchorEl}
        placement="right-end"
        style={{ zIndex: 1 }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box>
              <Chip
                style={{
                  marginLeft: 10,
                  marginBottom: 13,
                  textTransform: "capitalize",
                }}
                label={currentInvesment}
                color="primary"
              />
            </Box>
          </Fade>
        )}
      </Popper>
    ),
    [anchorEl, currentInvesment, fadeIn, id, open]
  );

  useEffect(() => {
    if (!!containerRef?.current) {
      setAnchorEl(containerRef.current);
    }
  }, []);

  return (
    <>
      <RootRef rootRef={containerRef}>
        <Box
          position="relative"
          height="100%"
          aria-describedby={id}
          paddingX="19px"
          paddingY="13px"
          onClick={stopPropagation}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ToggleButtonGroup
            value={toggle}
            exclusive
            onChange={handleChange}
            aria-label="row-toggle"
          >
            {mapToggles}
          </ToggleButtonGroup>
        </Box>
      </RootRef>

      {!!currentInvesment && chip}
    </>
  );
}
