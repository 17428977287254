import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./SendReminderDialog.styles";
import { ReminderStatusesResponse } from "../../../types/NotificationService";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps extends DialogProps {
  onCancelClick: (slug: string) => void;
  onSubmit: () => void;
  id: string;
  slug: string;
  responseData: ReminderStatusesResponse[];
  showNames: "employee" | "benefit";
  loading?: boolean;
}

const SendReminderDialog = ({
  onCancelClick,
  onSubmit,
  id,
  slug,
  responseData,
  showNames,
  loading,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCancelClick = useCallback(
    (slug: string) => {
      onCancelClick?.(slug);
    },
    [onCancelClick]
  );

  const employeesNames = useMemo(() => {
    const newArray = responseData?.map((item: any) => {
      if (showNames === "employee") {
        return item.employeeName;
      } else {
        return item.benefitName;
      }
    });
    return Array.from(new Set(newArray)).join(", ");
  }, [responseData, showNames]);

  const SubmitButtonLabel = useMemo(() => {
    if (loading)
      return (
        <>
          <CircularProgress size={20} classes={{ root: classes.loader }} />
          {t("common.send_anyway")}
        </>
      );
    return t("common.send_anyway");
  }, [loading, classes.loader, t]);

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={() => handleCancelClick(slug)}
      onEnterPress={() => !loading && onSubmit()}
      {...rest}
    >
      <Typography variant="h5">{t("choices.send_reminders")}</Typography>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Typography variant="body2" color="textSecondary">
          {t("choices.reminder_sent_in_last_hours")?.replace(
            "{name}",
            employeesNames
          )}
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={() => onCancelClick?.(slug)} variant="text">
          {t("common.cancel")}
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {SubmitButtonLabel}
        </Button>
      </DialogActions>
    </DialogExtended>
  );
};

export default SendReminderDialog;
