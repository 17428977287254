import { useLoading } from "./Loading";
import {
  Attachments,
  CategoriesRequest,
  CategoriesResponse,
  TransformedBenefitResponse,
  TranslationsRequest,
  UpdateBenefitRequest,
} from "../types/Benefits";
import { useCallback, useMemo } from "react";
import { BenefitDetailsResponse, UseBenefitUtils } from "../types/Benefits";
import {
  mergeDateTime,
  percentageValue,
  useDateFormat,
  getTimezoneFromDate,
} from "./Common";
import { nanoid } from "nanoid";
import moment from "moment";
import { Status } from "../types/Common";
import { StatusAction } from "../types/Status";
import { FIFTEEN_DAYS_IN_HOURS } from "../constants/Common";

export const useBenefitUtils = (): UseBenefitUtils => {
  const { loading, startLoading, stopLoading } = useLoading();
  const { getDateWithTimezone } = useDateFormat();

  const defaultStartTime = useMemo(
    () => moment().set({ hour: 9, minute: 0, seconds: 0 }).format(),
    []
  );

  const defaultEndTime = useMemo(
    () => moment().set({ hour: 23, minute: 59, seconds: 0 }).format(),
    []
  );

  const isInPast = useCallback((date: string) => {
    return moment(date).isBefore();
  }, []);

  const isDiff = useCallback((date: string) => {
    const deactivationDate = moment(date);
    return moment().diff(deactivationDate, "hours") > -FIFTEEN_DAYS_IN_HOURS;
  }, []);

  const getValidDate = useCallback(
    (date: string) => {
      if (date && !isInPast(date)) return date;

      return null;
    },
    [isInPast]
  );

  // todo: revise this function in scope of MIN-411
  const transformBenefitData = useCallback(
    (benefitPlanData: BenefitDetailsResponse): TransformedBenefitResponse => {
      if (!benefitPlanData) return benefitPlanData;
      startLoading();
      const benefitObj: any = { ...benefitPlanData };
      const isJustCreated =
        benefitPlanData.draft && !benefitPlanData.publishDate;
      if (benefitPlanData) {
        benefitObj.employerInvestmentAmount =
          benefitPlanData.investmentAmount -
          benefitPlanData.employeeInvestmentAmount;
        benefitObj.employeeInvestmentPercent =
          benefitPlanData.employeeInvestmentAmount === 0
            ? 0
            : Number(
                percentageValue(
                  benefitPlanData.employeeInvestmentAmount,
                  benefitPlanData.investmentAmount
                )
              );
        benefitObj.employerInvestmentPercent =
          !!benefitPlanData.investmentAmount
            ? 100 - benefitObj.employeeInvestmentPercent
            : 0;
        //date & time
        if (isJustCreated) {
          benefitObj.deactivationDate = getValidDate(
            benefitPlanData.deactivationDate
          );
          benefitObj.publishDate = getValidDate(benefitPlanData.publishDate);
        }
        /*** Activation datetime ***/
        const parsedActivationDateTime = getDateWithTimezone(
          benefitObj.activationDate,
          benefitObj.activationDateTZ
        );
        benefitObj.activationDate = parsedActivationDateTime;
        benefitObj.activationTime =
          parsedActivationDateTime ?? defaultStartTime;
        /*** Deactivation datetime ***/
        const parsedDeactivationDateTime = getDateWithTimezone(
          benefitObj.deactivationDate,
          benefitObj.deactivationDateTZ
        );
        benefitObj.deactivationDate = parsedDeactivationDateTime;
        benefitObj.deactivationTime =
          parsedDeactivationDateTime ?? defaultEndTime;
        /*** Publish datetime ***/
        const parsedPublishDateTime = getDateWithTimezone(
          benefitObj.publishDate,
          benefitObj.publishDateTZ
        );
        benefitObj.publishDate = parsedPublishDateTime;
        benefitObj.publishTime = parsedPublishDateTime ?? defaultStartTime;
        /*** Voting datetime ***/
        benefitObj.votingEndDate = getDateWithTimezone(
          benefitPlanData.votingEndDate,
          benefitPlanData.votingEndDateTZ
        );
        benefitObj.votingEndTime = benefitObj.votingEndDate;
        benefitObj.votingStartDate = getDateWithTimezone(
          benefitPlanData.votingStartDate,
          benefitPlanData.votingStartDateTZ
        );
        benefitObj.votingStartTime = benefitObj.votingStartDate;
        //sort categories
        (benefitObj?.categories as CategoriesResponse[])?.sort(function (a, b) {
          return a.index - b.index;
        });
        //translations
        benefitPlanData.translations?.forEach((item, index) => {
          const language = item.language;
          delete benefitObj.translations[index];
          benefitObj.translations[language] = item;
          //attachments
          benefitObj.translations[language].attachments =
            item?.attachments?.map((file) => file.fileId);
        });
      }
      stopLoading();
      return benefitObj;
    },
    [
      defaultEndTime,
      defaultStartTime,
      getDateWithTimezone,
      getValidDate,
      startLoading,
      stopLoading,
    ]
  );

  const parseChoicesDateForRequest = useCallback(
    (formData: Partial<UpdateBenefitRequest>) => {
      // Create a copy of formData to avoid trasnformations
      const data: Partial<UpdateBenefitRequest> = { ...formData };

      // Remove mergeGroup properties
      const dataEntries = Object.entries(data);
      const dataFiltered = dataEntries?.filter(
        ([key]) => !key.includes("mergeGroup")
      );
      const parsedData = { ...Object.fromEntries(dataFiltered) };

      // Merge date and time
      const votingEndDateWithTime = mergeDateTime(
        parsedData.votingEndDate as string,
        parsedData.votingEndTime as string
      );
      const votingStartDateWithTime = mergeDateTime(
        parsedData.votingStartDate as string,
        parsedData.votingStartTime as string
      );

      // Parse timezone
      const votingStartDateTZ = getTimezoneFromDate(votingStartDateWithTime);
      const votingEndDateTZ = getTimezoneFromDate(votingEndDateWithTime);

      // Remove non request values
      delete parsedData.votingStartTime;
      delete parsedData.votingEndTime;

      return {
        ...parsedData,
        votingStartDateTZ,
        votingEndDateTZ,
        votingStartDate: votingStartDateWithTime,
        votingEndDate: votingEndDateWithTime,
      };
    },
    []
  );

  // todo: revise this function in scope of MIN-411
  const getDataForRequest = useCallback(
    (formData: Partial<UpdateBenefitRequest>) => {
      const data: any = { ...formData };
      if (formData) {
        /** DATETIME **/

        data.activationDate = mergeDateTime(
          data.activationDate,
          data.activationTime
        );
        data.activationDateTZ = getTimezoneFromDate(data.activationDate);

        data.deactivationDate = mergeDateTime(
          data.deactivationDate,
          data.deactivationTime
        );
        data.deactivationDateTZ = getTimezoneFromDate(data.deactivationDate);

        data.publishDate = mergeDateTime(data.publishDate, data.publishTime);
        data.publishDateTZ = getTimezoneFromDate(data.publishDate);

        // Remove time fields (irrelevant for the backend) to reduce the noise.
        // This is a temp fix and should be resolved in scope of MIN-411
        delete data.activationTime;
        delete data.deactivationTime;
        delete data.publishTime;

        // Get and set voting start and end dates
        data.votingStartDate = mergeDateTime(
          data.votingStartDate,
          data.votingStartTime
        );
        data.votingEndDate = mergeDateTime(
          data.votingEndDate,
          data.votingEndTime
        );

        // Get and set voting date timezones
        data.votingStartDateTZ = getTimezoneFromDate(data.votingStartDate);
        data.votingEndDateTZ = getTimezoneFromDate(data.votingEndDate);

        /** termsFile **/
        if (!!data.termsConditionsRequiredFrom) {
          data.termsConditionsFileId =
            typeof data.termsConditionsFileId === "string"
              ? data.termsConditionsFileId
              : data.termsConditionsFileId?.[0];
          data.termsConditionsRequiredFrom = moment(
            data.termsConditionsRequiredFrom
          ).toISOString(true);
        } else {
          data.termsConditionsFileId = null;
          data.termsConditionsRequiredFrom = null;
        }

        /** CATEGORIES **/
        data.categories =
          data?.categories?.filter((cat: CategoriesRequest) => cat.name) ?? [];
        data?.categories?.forEach(
          (category: CategoriesRequest, index: number) => {
            if (!category.id) category.id = nanoid();
            category.index = index;
            category.compensationPercent = category.compensationPercent ?? 0;
            category.limitAmount = category.limitAmount ?? 0;
          }
        );
        /*****/

        /** TRANSLATIONS **/
        const languages = Object.keys(data?.translations);

        if (languages) {
          const translationsData = Object.values(data?.translations);
          for (let i = 0; i < languages.length; i++) {
            const current = translationsData[i] as TranslationsRequest;
            current.language = languages[i];
            current.description = current.description ?? "";
            current.id = current.id ?? "";
            //attachments
            current.attachments = !!current.attachments
              ? (
                  current.attachments as unknown as string[] | Attachments[]
                )?.map(
                  (id) =>
                    [
                      {
                        name: "name",
                        fileId: typeof id === "object" ? id?.fileId : id,
                        id: null,
                      },
                    ][0]
                )
              : [];
            //faq
            current.faq = current.faq?.filter(
              (item) => item.answer && item.question
            );
            //categories
            current.categories = current.categories?.filter((cat) => cat.name);
            current.categories?.forEach((category, index) => {
              category.id = data.categories[index]?.id; //link to main category
              category.index = data.categories[index]?.index;
            });
            //links
            current.links = current.links
              ? current.links?.filter((link) => link.name && link.url)
              : [];
          }

          //fill translations without title
          data.translations = (translationsData as TranslationsRequest[])?.map(
            (item) => ({
              ...item,
              title: !!item?.title
                ? item?.title
                : !!data?.translations["EN"]?.title
                ? data?.translations["EN"]?.title
                : "",
            })
          );
        }
        /******/
      }
      return data;
    },
    []
  );

  const parseStatusDates = useCallback(
    (benefitPlan: TransformedBenefitResponse): Status => {
      if (!benefitPlan) return { name: "active" };

      const { publishDate, deactivationDate, status, draft } = benefitPlan;
      const isActive = status === "active";

      if (draft) return { name: "draft" };

      if (!!status) {
        if (isActive) {
          if (!!deactivationDate && isDiff(deactivationDate)) {
            return {
              name: "will_be_inactive_on",
              date: deactivationDate,
            };
          }
          // old condition (might be needed to go back)
          // if (!!deactivationDate && !isInPast(deactivationDate)) {
          //   return {
          //     name: "will_be_inactive_on",
          //     date: deactivationDate,
          //   };
          // }

          return {
            name: "active",
          };
        }

        if (!isActive) {
          if (!!publishDate && !isInPast(publishDate)) {
            return {
              name: "will_be_active_on",
              date: publishDate,
            };
          }

          return {
            name: "inactive",
          };
        }
      }

      return {
        name: status as StatusAction,
      };
    },
    [isInPast, isDiff]
  );

  const parseRequestStatus = useCallback((status: Status) => {
    const statusName = status.name;
    const statusDate = !!status?.date
      ? moment.parseZone(status?.date).toISOString(true)
      : moment().toISOString(true);
    const timezone = getTimezoneFromDate(statusDate);

    switch (statusName) {
      case "will_be_active_on":
        return { publishDate: statusDate, publishDateTZ: timezone };
      case "inactive":
        return { deactivationDate: statusDate, deactivationDateTZ: timezone };
      case "active":
        return { publishDate: statusDate, publishDateTZ: timezone };
      case "will_be_inactive_on":
        return { deactivationDate: statusDate, deactivationDateTZ: timezone };
    }

    return {
      publishDate: null,
      deactivationDate: null,
    };
  }, []);

  return {
    transformBenefitData,
    getDataForRequest,
    parseStatusDates,
    parseRequestStatus,
    loading,
    parseChoicesDateForRequest,
  };
};
