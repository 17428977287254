import { Box } from "@material-ui/core";
import { MpTable, useClearFilters } from "@mp-react/table";
import React, { useCallback, useEffect, useMemo } from "react";
import { useGroupsAssignEmployeesTableType } from "../../../../../configs/Tables/GroupsInnerAssignEmployees";
import { rowMethods } from "../../../../../utils/TableMethods";
import useTableUtils from "../../../../../utils/Table";
import FormFooter from "../../../../../components/layouts/FormFooter/FormFooter";
import AssignEmployeesFooterButtons from "../../../../../components/groups/AssignEmployeesFooterButtons/AssignEmployeesFooterButtons";
import { MpRowActionParameters } from "@mp-react/table";
import {
  useGroupsAssignEmployeesAsyncMethods,
  useInnerGroupAssignEmployee,
} from "../../../../../state/InnerGroupAssignEmployee";
import { useHistory, useParams } from "react-router";
import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, UseRowSelectRowProps } from "react-table";
import { useInvestmentCalculationsStore } from "../../../../../state/InvestmentCalculations";
import { useMe } from "../../../../../state/Administrators";
import useStyles from "../../../../../styles/Table.styles";
import { useTableStore } from "../../../../../state/Table";
import CustomColumnArrow from "../../../../../components/table/CustomColumnArrow/CustomColumnArrow";

export default function AssignEmployees() {
  const history = useHistory();
  const classes = useStyles();
  const { canEditEmployees } = useMe();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const {
    overridables,
    assignEmployeesOverrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
    customColumnQueryParam,
  } = useTableUtils("innerGroupAssignEmployees");
  const AssignEmployeesTableType = useGroupsAssignEmployeesTableType();
  const { changeCount, resetAllChanges, setStatus, increaseChangeCount } =
    useInvestmentCalculationsStore();
  const {
    currentInvestment,
    newInvestment,
    tooltipText,
    computedAssignEmployeesData,
    defaultInvestment,
    bulkMethods,
    addSwitchValue,
    clearSwitchValues,
    loading,
    updateAssignEmployees,
    parsedGroupsAssignEmployeesTotals,
  } = useInnerGroupAssignEmployee(id, tableQueryParam);
  const asyncGetMethods = useGroupsAssignEmployeesAsyncMethods(
    customColumnQueryParam,
    id
  );
  const { clearFiltersEvent, clearFilters } = useClearFilters();
  const customColumn = useTableStore((state) => state.customColumn);

  const footerTransform = useMemo(
    () => `translateY(${changeCount > 0 ? "0" : "70px"})`,
    [changeCount]
  );

  const handleCancelFooter = useCallback(() => {
    resetAllChanges();
    setStatus(null);
    currentInvestment(null);
    clearSwitchValues();
  }, [resetAllChanges, setStatus, currentInvestment, clearSwitchValues]);

  const handlePublishFooter = useCallback(
    (date?: string) => updateAssignEmployees(),
    [updateAssignEmployees]
  );

  const toggleIncludedExcluded = useCallback(
    (current: MpRowActionParameters) => {
      const { value, rowId, row } = current;
      currentInvestment(value, row?.original, rowId);
      increaseChangeCount(rowId);
      setStatus({ included: { [rowId as string]: { value: value } } });
      addSwitchValue(rowId as string, value as unknown as boolean);
    },
    [addSwitchValue, currentInvestment, increaseChangeCount, setStatus]
  );

  const handleRowClick = useCallback(
    (current?: Row<{}> & UseRowSelectRowProps<{}>) => {
      history.push(`/employees/view/${current?.id}`);
    },
    [history]
  );

  useEffect(() => {
    if (!!customColumn) clearFilters(["customColumn"]);
  }, [clearFilters, customColumn]);

  return (
    <Box
      position="relative"
      className={canEditEmployees ? "" : classes.disableTable}
    >
      <Prompt
        when={changeCount > 0}
        message={t("errors.user_leaving_edited_page")}
      />
      <CustomColumnArrow tableType={AssignEmployeesTableType} />
      <MpTable
        {...AssignEmployeesTableType}
        data={computedAssignEmployeesData}
        onGetData={handleGetData}
        bulkMethods={bulkMethods}
        overridables={overridables}
        disablePagination={true}
        totalsData={parsedGroupsAssignEmployeesTotals}
        totals={computedAssignEmployeesData?.length > 0}
        classes={assignEmployeesOverrideClasses}
        loading={loading}
        enableGlobalActions={true}
        rowMethods={{ ...rowMethods, toggleIncludedExcluded }}
        translations={translations}
        onRowClick={handleRowClick}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
        asyncGetMethods={asyncGetMethods}
        clearFiltersEvent={clearFiltersEvent}
      />
      <FormFooter
        containerProps={{
          style: {
            transform: footerTransform,
          },
        }}
      >
        <AssignEmployeesFooterButtons
          onCancel={handleCancelFooter}
          onPublish={handlePublishFooter}
          currentInvestment={defaultInvestment}
          newInvestment={defaultInvestment + newInvestment}
          difference={newInvestment}
          tooltipText={tooltipText}
          changes={changeCount}
        />
      </FormFooter>
    </Box>
  );
}
