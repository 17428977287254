import {
  PermissionOptions,
  GlobalPermissionModules,
} from "../../constants/Administrators";
import { useTranslation } from "react-i18next";
import { MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { TooltipControl } from "../../types/Common";

export const useGlobalPermissionsForm = (): MpFormType => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const entries = Object.entries(PermissionOptions);
    return entries.map(([, value]) => ({
      type: "select" as any,
      key: value,
      label: t(`actions.${value}`),
    }));
  }, [t]);

  const controls = useMemo((): TooltipControl[] => {
    const values = Object.values(GlobalPermissionModules);
    return values.map((value) => ({
      type: "dropdown" as any,
      key: value,
      label: t(`permissions.${value}`),
      children: options,
      tooltip: "tooltip.global_permission_dropdown",
    }));
  }, [options, t]);

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
