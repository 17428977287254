import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    tabsWrapper: {
      position: "relative",
      backgroundColor: Colors.TabsBackground,
      borderRadius: 8,
      padding: "2px",
      width: "fit-content",
    },
    tabs: {
      position: "relative",
      minHeight: "unset",
      "& .MuiTab-root": {
        minHeight: "unset",
        minWidth: "unset",
        padding: "8px 26px",
        textTransform: "none",
        color: Colors.TextPrimary,
        opacity: 1,
        borderRadius: 8,
        "&:last-child": {
          borderRight: "none",
        },
      },
      "& p": {
        lineHeight: "23px",
      },
      "& .Mui-selected": {
        color: Colors.TextPrimary,
        zIndex: 1,
        "& p": {
          fontWeight: 700,
          color: Colors.TextPrimary,
        },
      },
    },
    tabsDivider: {
      "&:not(:last-child)": {
        position: "relative",
        "&::after": {
          content: "''",
          width: 1,
          height: 16,
          background: Colors.TabsBorder,
          position: "absolute",
          bottom: 14,
          top: 14,
          right: 0,
        },
      },
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: "100%",
      "& > span": {
        width: "100%",
        height: "100%",
        borderRadius: 8,
        zIndex: 0,
        backgroundColor: Colors.White,
      },
    },
  })
);
