import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  MpControlProps,
  controlRegisterOptions,
  useErrorMessages,
} from "@mp-react/form";
import MomentUtils from "@date-io/moment";
import { Controller } from "react-hook-form";
import { Box } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import useStyles from "./TypeDate.styles";
import { browserLanguage, useDateFormat } from "../../../utils/Common";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { CustomFormControl } from "../../../types/Common";
import clsx from "clsx";

const inputLabelProps = { shrink: true };

function TypeDateView({
  control,
  size,
  layout,
  variant,
  error,
  locale,
  defaultMessages,
  onChange,
  value,
}: Pick<
  MpControlProps,
  | "control"
  | "size"
  | "layout"
  | "variant"
  | "error"
  | "defaultMessages"
  | "locale"
> & {
  onChange: (...event: any[]) => void;
  value: any;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const [hasError, setHasError] = useState<boolean>(false);
  const { tooltip, closeTooltip, openTooltip, anchorEl } = useTooltip(control);
  const { keyboardDatePickerFormat } = useDateFormat();

  const readonly = useMemo(
    () => !!(control as CustomFormControl)?.readonly,
    [control]
  );
  const disabled = useMemo(
    () =>
      !!(control as CustomFormControl)?.readonly ||
      !!(control as CustomFormControl)?.disabled,
    [control]
  );

  useEffect(() => {
    if (moment(value).isAfter()) {
      setHasError(true);
    } else if (value === null) {
      setHasError(false);
    } else if (moment(value).isValid()) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  }, [value]);

  const errorText = useMemo(
    () => (firstMessage ?? hasError ? t("form.pattern") : undefined),
    [firstMessage, hasError, t]
  );

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (moment(date).isValid()) onChange(moment(date).format("YYYY-MM-DD"));
    },
    [onChange]
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={browserLanguage}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        position="relative"
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        className={classes.dateInput}
      >
        <KeyboardDatePicker
          value={value}
          error={!!error || hasError}
          name={control.key}
          onChange={handleDateChange}
          placeholder={control.placeholder}
          fullWidth={true}
          InputLabelProps={inputLabelProps}
          variant="inline"
          inputVariant={variant}
          size={size}
          format={keyboardDatePickerFormat}
          keyboardIcon={false}
          label={layout === "separated" ? "" : control.label}
          PopoverProps={{
            style: { display: "none" },
          }}
          InputAdornmentProps={{
            style: { display: "none" },
          }}
          disableFuture
          helperText={errorText}
          className={clsx({
            [classes.readonly]: readonly,
          })}
          disabled={disabled}
        />
        <FormTooltip tooltip={tooltip} anchorEl={anchorEl} />
      </Box>
    </MuiPickersUtilsProvider>
  );
}

export default function TypeDate({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  hookFormControl,
  locale,
}: MpControlProps) {
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <TypeDateView
          onChange={onChange}
          value={value}
          control={control}
          locale={locale}
          size={size}
          layout={layout}
          variant={variant}
          error={error}
          defaultMessages={defaultMessages}
        />
      )}
    />
  );
}
