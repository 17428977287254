import { useMemo } from "react";
import { LinkItem } from "../types/Common";
import {
  GlobalPermissionModules,
  LocalPermissionModules,
} from "./Administrators";

export const useLinks = (hasNoCompanySettingsAccess: boolean) =>
  useMemo(
    () =>
      [
        {
          title: "menu.analytics",
          to: "/analytics/dashboard",
          roles: ["hrAdmin"],
          module: LocalPermissionModules.Dashboard,
          links: [
            {
              title: "permissions.dashboardModule",
              to: "/analytics/dashboard",
              roles: ["hrAdmin"],
              module: LocalPermissionModules.Dashboard,
            },
            {
              title: "menu.compare_mode",
              to: "/analytics/compare",
              roles: ["hrAdmin"],
              module: LocalPermissionModules.Dashboard,
            },
          ],
        },
        {
          title: "menu.benefits",
          to: "/benefits/all",
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.Benefits,
          links: [
            {
              title: "menu.all_benefits",
              to: "/benefits/all",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.insurances",
              to: "/benefits/insurances",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.wellness",
              to: "/benefits/wellness",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.perks",
              to: "/benefits/perks",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.pensions",
              to: "/benefits/pensions",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.time_off",
              to: "/benefits/timeOff",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.education",
              to: "/benefits/education",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.general_employee_welfare",
              to: "/benefits/generalEmployeeWelfare",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.company_discounts",
              to: "/benefits/companyDiscounts",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.partner_discounts",
              to: "/benefits/partnerDiscounts",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
          ],
        },
        {
          title: "menu.marketplace",
          to: "/marketplace",
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.Benefits,
          links: [
            {
              title: "menu.marketplaceDeals",
              to: "/marketplace/deals",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
            {
              title: "menu.marketplaceOrders",
              to: "/marketplace/orders",
              roles: ["hrAdmin"],
              module: GlobalPermissionModules.Benefits,
            },
          ],
        },
        {
          title: "sidebar.choices",
          to: "/choices",
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.FlexBenefit,
        },
        {
          title: "permissions.employeeModule",
          to: "/employees",
          roles: ["hrAdmin"],
          module: LocalPermissionModules.Employees,
        },
        {
          title: "menu.groups",
          to: "/groups",
          roles: ["hrAdmin"],
          module: GlobalPermissionModules.EmployeeGroups,
        },
        {
          title: "permissions.newsModule",
          to: "/news",
          roles: ["hrAdmin"],
          module: LocalPermissionModules.News,
        },
        {
          title: "sidebar.more",
          to: hasNoCompanySettingsAccess
            ? "/more/terms-acceptance"
            : "/more/company-settings",
          roles: ["hrAdmin"],
          module: [
            GlobalPermissionModules.Administrators,
            GlobalPermissionModules.OrganisationStructure,
            LocalPermissionModules.Employees,
          ],
          links: [
            {
              title: "sidebar.termsAcceptance",
              to: "/more/terms-acceptance",
              roles: ["hrAdmin"],
              module: [
                LocalPermissionModules.Employees,
                GlobalPermissionModules.Benefits,
              ],
              matchAllModules: true,
            },
            {
              title: "sidebar.log",
              to: "/more/log",
              roles: ["hrAdmin"],
              module: LocalPermissionModules.History,
            },
            {
              title: "sidebar.companySettings",
              to: "/more/company-settings",
              roles: ["hrAdmin"],
              module: [
                GlobalPermissionModules.Administrators,
                GlobalPermissionModules.OrganisationStructure,
              ],
            },
          ],
        },
        {
          title: "menu.clients",
          to: "/clients",
          roles: ["melpAdmin"],
        },
        {
          title: "menu.users",
          to: "/users",
          roles: ["melpAdmin"],
        },
        {
          title: "menu.templates",
          to: "/benefit-templates",
          roles: ["melpAdmin"],
        },
        {
          title: "menu.integrations",
          to: "/integration-types",
          roles: ["melpAdmin"],
        },
        {
          title: "menu.marketplace",
          to: "/admin/marketplace/suppliers",
          roles: ["melpAdmin"],
          links: [
            {
              title: "menu.suppliers",
              to: "/admin/marketplace/suppliers",
              roles: ["melpAdmin"],
            },
            {
              title: "menu.items",
              to: "/admin/marketplace/items",
              roles: ["melpAdmin"],
            },
            {
              title: "menu.orders",
              to: "/admin/marketplace/orders",
              roles: ["melpAdmin"],
            },
          ],
        },
      ] as LinkItem[],
    [hasNoCompanySettingsAccess]
  );
