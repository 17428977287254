import { MpControlType, MpFormType } from "@mp-react/form";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { CustomFormControl } from "../../../../types/Common";
import {
  useDatePlaceholder,
  getTimePlaceholder,
} from "../../../../utils/Common";
import { FormEndAdornmentTypes } from "../../../../constants/Form";

export const useChoicesDeadlineForm = (
  required?: boolean,
  readonly?: boolean
): MpFormType => {
  const { t } = useTranslation();
  const { datePlaceholder } = useDatePlaceholder();

  const controls = useMemo(
    (): CustomFormControl[] => [
      {
        type: "mergedGroup" as any,
        label: t("form.voting_starts"),
        required: !!required,
        tooltip: "tooltip.benefit_settings_voting_starts",
        endAdornment: FormEndAdornmentTypes.Clear,
        children: [
          {
            type: "date" as any,
            key: "votingStartDate",
            required: !!required,
            placeholder: datePlaceholder,
            span: {
              sm: 6,
            },
          },
          {
            type: "time" as any,
            key: "votingStartTime",
            required: !!required,
            placeholder: getTimePlaceholder(),
            span: {
              sm: 6,
            },
            readonly: !!readonly,
          },
        ],
      },
      {
        type: "mergedGroup" as any,
        label: t("form.voting_ends"),
        required: !!required,
        tooltip: "tooltip.benefit_settings_voting_ends",
        endAdornment: FormEndAdornmentTypes.Clear,
        readonly: !!readonly,
        children: [
          {
            type: "date" as any,
            key: "votingEndDate",
            required: !!required,
            placeholder: datePlaceholder,
            span: {
              sm: 6,
            },
            messages: {
              pattern: t("errors.voting_end_date_before_voting_start_date"),
            },
          },
          {
            type: "time" as any,
            key: "votingEndTime",
            required: !!required,
            placeholder: getTimePlaceholder(),
            span: {
              sm: 6,
            },
            readonly: !!readonly,
          },
        ],
      },
      {
        type: MpControlType.number,
        label: t("form.limited_qty"),
        key: "limitedQuantity",
        placeholder: "0",
        tooltip: "tooltip.benefit_settings_limited_qty",
        validations: {
          min: 1,
        },
      },
    ],
    [t, required, datePlaceholder, readonly]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
