import React, { useMemo, useCallback, useState } from "react";
import { Box, Button, Chip } from "@material-ui/core";
import useStyles from "./AssignGroupsFooterButtons.style";
import { useTranslation } from "react-i18next";
import ActionsPopper from "../../common/ActionsPopper/ActionsPopper";
import useStatus from "../../../utils/Status";
import moment from "moment";

interface IProps {
  onCancel: () => void;
  onPublish: (date: string) => void;
  changes?: number;
}

export default function AssignGroupsFooterButtons(props: IProps) {
  const { onCancel, onPublish, changes } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { actions } = useStatus();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const popperActions = useMemo(() => {
    return actions.employee?.table ?? [];
  }, [actions.employee?.table]);

  const handleOpenActions = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseActions = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleActionChange = useCallback(
    (action: string, date?: string) => {
      const now = moment().toISOString();
      const parsedDate =
        !!date && action === "on_specific_date"
          ? moment(date)
              .set({ hours: 12, minutes: 0, seconds: 0 })
              .utc(true)
              .toISOString()
          : now;
      onPublish(parsedDate);
      onCancel();
      handleCloseActions();
    },
    [onPublish, onCancel, handleCloseActions]
  );

  const ChangeChip = useMemo(() => {
    if (changes && changes > 0)
      return (
        <Chip
          label={`${changes} ${t("common.changed")}`}
          className={classes.changesChip}
        />
      );
    return null;
  }, [changes, classes.changesChip, t]);

  const Actions = useMemo(() => {
    if (!popperActions) return null;
    return (
      <ActionsPopper
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseActions}
        placement="bottom-start"
        actions={popperActions}
        onActionClick={handleActionChange}
        hideTimePicker={true}
      />
    );
  }, [anchorEl, handleActionChange, handleCloseActions, open, popperActions]);

  return (
    <Box position="relative" width="100%" display="flex" alignItems="center">
      <Box className={classes.buttonsContainer}>
        <Button disableElevation className={classes.button} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
        <Button
          name="confirm-changes"
          color="secondary"
          className={classes.button}
          variant="contained"
          disableElevation
          onClick={handleOpenActions}
        >
          {t("common.confirm_changes")}
        </Button>
        {ChangeChip}
      </Box>
      {Actions}
    </Box>
  );
}
