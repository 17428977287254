import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useLoading } from "./../utils/Loading";
import {
  LocalPermissionsRequest,
  UsePermissionsParams,
} from "./../types/Administrators";
import { useCallback, useMemo } from "react";
import useSWR, { mutate } from "swr";
import { Endpoints } from "../api/constants";
import { mutateData } from "../api/api";
import { usePermissionUtils } from "../utils/Permissions";
import { useMe } from "./Administrators";

export const usePermissions = ({
  adminId,
  selectedId,
  removeChanges,
}: UsePermissionsParams) => {
  const { startLoading, stopLoading, loading: apiLoading } = useLoading();
  const { t } = useTranslation();
  const { isRoot, me } = useMe();

  const {
    wasChanged,
    parsePermissionEntry,
    getAllLocalPermissions,
    getCompanyGroupPermissions,
    getCompanyPermissions,
    getParsedPermissions,
  } = usePermissionUtils();

  const isRootAndMe = useMemo(
    () => me?.id === adminId && !!isRoot,
    [adminId, me?.id, isRoot]
  );

  const companyId = useMemo(() => {
    if (wasChanged) return false;
    if (selectedId.includes("company-"))
      return selectedId.replace("company-", "");
    return false;
  }, [selectedId, wasChanged]);

  const companyGroupId = useMemo(() => {
    if (wasChanged) return false;
    if (selectedId.includes("companyGroup-"))
      return selectedId.replace("companyGroup-", "");
    return false;
  }, [selectedId, wasChanged]);

  const companyGroupUrl = useMemo(
    () =>
      companyGroupId
        ? `${Endpoints.companyGroupPermissions}/${adminId}/${companyGroupId}`
        : null,
    [adminId, companyGroupId]
  );

  const companyUrl = useMemo(() => {
    if (selectedId === "allLocalPermissions")
      return `${Endpoints.companyPermissions}/${adminId}`;

    return companyId
      ? `${Endpoints.companyPermissions}/${adminId}/${companyId}`
      : null;
  }, [adminId, companyId, selectedId]);

  const globalPermissionUrl = useMemo(
    () =>
      adminId && !companyGroupId && !companyId && selectedId
        ? `${Endpoints.globalPermissions}/${adminId}`
        : null,
    [adminId, companyGroupId, companyId, selectedId]
  );

  const { data: globalPermissions, error: globalPermissionError } =
    useSWR(globalPermissionUrl);

  const { data: companyPermissions, error: companyError } = useSWR(companyUrl);

  const { data: companyGroupPermissions, error: companyGroupError } =
    useSWR(companyGroupUrl);

  const globalPermissionsLoading = useMemo(
    () => !globalPermissions && !globalPermissionError,
    [globalPermissionError, globalPermissions]
  );

  const companyPermissionsLoading = useMemo(
    () => !companyPermissions && !companyError,
    [companyError, companyPermissions]
  );

  const companyGroupPermissionsLoading = useMemo(
    () => !companyGroupPermissions && !companyGroupError,
    [companyGroupError, companyGroupPermissions]
  );

  const loading = useMemo(() => {
    if (!selectedId) return false;
    if (!!companyGroupId) return companyGroupPermissionsLoading;
    if (!!companyId) return companyPermissionsLoading;

    return globalPermissionsLoading;
  }, [
    companyGroupId,
    companyGroupPermissionsLoading,
    companyId,
    companyPermissionsLoading,
    globalPermissionsLoading,
    selectedId,
  ]);

  const parsedGlobalPermissions = useMemo(
    () => getParsedPermissions(globalPermissions, isRootAndMe),
    [getParsedPermissions, globalPermissions, isRootAndMe]
  );

  const parsedCompanyPermissions = useMemo(
    () => getParsedPermissions(companyPermissions, isRootAndMe),
    [companyPermissions, getParsedPermissions, isRootAndMe]
  );

  const parsedCompanyGroupPermissions = useMemo(
    () => getParsedPermissions(companyGroupPermissions, isRootAndMe),
    [companyGroupPermissions, getParsedPermissions, isRootAndMe]
  );

  const updateGlobalPermissions = useCallback(
    async (globalEntries: [string, any] | undefined) => {
      if (!globalEntries) return;
      const requestData = parsePermissionEntry([globalEntries]);
      if (requestData.length > 0) {
        await mutateData("patch", `${Endpoints.globalPermissions}/${adminId}`, {
          updatedModules: requestData,
        });
        mutate(`${Endpoints.globalPermissions}/${adminId}`);
      }
    },
    [adminId, parsePermissionEntry]
  );

  const updateLocalPermissions = useCallback(
    async (requestData: LocalPermissionsRequest) => {
      if (requestData.updatedModules.length > 0) {
        await mutateData(
          "patch",
          `${Endpoints.localPermissions}/${adminId}`,
          requestData
        );
        mutate(`${Endpoints.localPermissions}/${adminId}`);
      }
    },
    [adminId]
  );

  const updatePermissions = useCallback(
    async (data: FormData) => {
      startLoading();
      const entries = Object.entries(data);
      const globalEntries = entries.find(
        ([key]) => key === "allGlobalPermissions"
      );
      await updateGlobalPermissions(globalEntries);

      const localEntries = entries.find(
        ([key]) => key === "allLocalPermissions"
      );
      const companyGroupEntries = entries
        .filter(([key]) => key.includes("companyGroup-"))
        .map(([key, value]) => [key.replace("companyGroup-", ""), value]);
      const companyEntries = entries
        .filter(([key]) => key.includes("company-"))
        .map(([key, value]) => [key.replace("company-", ""), value]);
      const localPermissions = getAllLocalPermissions(localEntries);
      const companyGroupPermissions =
        getCompanyGroupPermissions(companyGroupEntries);
      const companyPermissions = getCompanyPermissions(companyEntries);
      const requestData: LocalPermissionsRequest = {
        updatedModules: [
          ...companyPermissions,
          ...companyGroupPermissions,
          ...localPermissions,
        ],
      };
      await updateLocalPermissions(requestData);
      removeChanges();
      toast(t("common.updated_succesfully"), { type: "success" });
      stopLoading();
      mutate(Endpoints.me);
    },
    [
      startLoading,
      updateGlobalPermissions,
      getAllLocalPermissions,
      getCompanyGroupPermissions,
      getCompanyPermissions,
      updateLocalPermissions,
      removeChanges,
      t,
      stopLoading,
    ]
  );

  return {
    updatePermissions,
    globalPermissions,
    companyPermissions,
    companyGroupPermissions,
    loading: loading || apiLoading,
    companyGroupId,
    companyId,
    parsedGlobalPermissions,
    parsedCompanyPermissions,
    parsedCompanyGroupPermissions,
  };
};
