import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@melp-design/components";
import { useTranslation } from "react-i18next";
import { Search } from "@melp-design/icons";
import { Colors } from "@melp-design/style";

const useStyles = makeStyles((theme) => ({
  noDataMessageContainer: {
    height: 200,
    display: "grid",
    gridTemplateRows: "repeat(2, 1fr)",
    padding: 30,
    justifyItems: "center",
    rowGap: 5,
  },
  noDataIcon: {
    height: 40,
    width: "auto",
    alignSelf: "end",
    color: Colors.grey,
  },
  noDataMessage: {
    alignSelf: "start",
  },
}));

interface Props {
  /**
   * Indicates that filters are applied and leads to a different message
   * displayed.
   */
  filtersApplied?: boolean;
}

/**
 * Component should be used in list-like UI elements, when there is no data to
 * display.
 */
const NoData = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const noDataMessage = props.filtersApplied
    ? t("common.noDataForFilters")
    : t("common.no_data_to_show");
  return (
    <div className={classes.noDataMessageContainer}>
      <Search className={classes.noDataIcon} />
      <Typography
        variant="p1"
        color="textSecondary"
        align="center"
        className={classes.noDataMessage}
      >
        {noDataMessage}
      </Typography>
    </div>
  );
};

export default NoData;
