import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperProps,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import useStyles from "./Datepicker.styles";
import DatepickerItem from "./DatepickerItem";
import DatepickerHeader from "./DatepickerHeader";
import {
  Datepicker as DatepickerType,
  DatepickerListItem as DatepickerListItemType,
  FromToFilterProps,
  MpTableTranslations,
} from "@mp-react/table";

import DatepickerArrow from "./DatepickerArrow";
import useDatepicker, { useDatepickerData } from "../../../../utils/Datepicker";
import { useTableStore } from "../../../../state/Table";
import { useTranslation } from "react-i18next";

interface Props {
  items?: DatepickerListItemType[];
  placement?: PopperProps["placement"];
  translations?: MpTableTranslations;
  FromToFilter?: React.ComponentType<FromToFilterProps>;
  onGetData: (data: DatepickerType | null) => void;
}

export default function Datepicker({
  items,
  placement,
  translations,
  FromToFilter,
  onGetData,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    setDatepickerValues,
    clearSelected,
    handleFilterClick,
    dateFrom,
    dateTo,
  } = useDatepicker();
  const defaultData = useDatepickerData();
  const { setDatepickerValue, datepickerValue } = useTableStore();

  const data = useMemo(() => items ?? defaultData, [defaultData, items]);

  const date = useMemo(
    () => (!!datepickerValue ? datepickerValue : null),
    [datepickerValue]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const id = useMemo(() => (open ? "datepicker-popper" : undefined), [open]);
  const filterProps = useMemo(
    (): FromToFilterProps => ({
      onFilter: () => {
        handleFilterClick();
        setAnchorEl(null);
      },
      onDateChange: setDatepickerValues,
      dateFrom: dateFrom,
      dateTo: dateTo,
      onGetData: onGetData,
    }),
    [dateFrom, dateTo, handleFilterClick, onGetData, setDatepickerValues]
  );

  const togglePopper = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleDatepickerClick = useCallback(
    (value: string, key: string) => {
      setAnchorEl(null);
      setDatepickerValues(value, key, onGetData);
      setDatepickerValue(value);
    },
    [onGetData, setDatepickerValue, setDatepickerValues]
  );

  const handleSelectAll = useCallback(() => {
    clearSelected(onGetData);
    setAnchorEl(null);
    setDatepickerValue("");
  }, [clearSelected, onGetData, setDatepickerValue]);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box>
        <Paper
          className={clsx({
            [classes.toggle]: true,
            [classes.selected]: !!date,
          })}
          aria-describedby={id}
          onClick={togglePopper}
        >
          {date ?? t("common.all_time")}
          <DatepickerArrow anchorEl={anchorEl} />
        </Paper>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          style={{ zIndex: 1299 }}
          placement={placement ?? "bottom-end"}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={4} className={classes.paper}>
                <DatepickerHeader
                  translations={translations}
                  date={date}
                  onSelectAll={handleSelectAll}
                  FromToFilter={FromToFilter}
                  filterProps={filterProps}
                />
                <DatepickerItem
                  date={date}
                  data={data}
                  onClick={handleDatepickerClick}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
