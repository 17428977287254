import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    leftField: {
      "& fieldset": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    rightField: {
      "& fieldset": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: "1px solid transparent",
      },
    },
  })
);
