import { Typography, Box } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import TableDatepicker from "../../TableDatepicker/TableDatepicker";
import useTableUtils from "../../../../utils/Table";
import useEmployeeChoices from "../../../../state/InnerEmployeeChoices";
import DownloadExcelButton from "../../../table/DownloadExcelButton/DownloadExcelButton";

export default function InnerEmployeeChoicesHeaders() {
  const { t } = useTranslation();
  const { tableQueryParam } = useTableUtils("innerEmployeeChoices");
  const { id } = useParams() as any;
  const { employeeChoices, loading, exportToExcel } = useEmployeeChoices(
    id,
    tableQueryParam
  );

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5">{t("employees.employee_choices")}</Typography>
      {/* <TableDatepicker /> */}
      <DownloadExcelButton
        listLength={employeeChoices?.count}
        loading={loading}
        exportToExcel={exportToExcel}
      />
    </Box>
  );
}
