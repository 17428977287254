import { TooltipControl, TooltipConfig } from "../types/Common";
import React, { useCallback, useMemo, useState } from "react";

interface UseTooltip {
  tooltip: string | TooltipConfig;
  showTooltip: boolean;
  anchorEl: HTMLElement | null;
  setShowTooltip: (show: boolean) => void;
  toggleTooltip: (event: React.MouseEvent<HTMLElement>) => void;
  openTooltip: (event: React.MouseEvent<HTMLElement>) => void;
  setTooltip: (content: string) => void;
  closeTooltip: () => void;
}

export default function useTooltip(control?: TooltipControl): UseTooltip {
  const tooltipControl = useMemo(
    () => (control as TooltipControl)?.tooltip,
    [control]
  );
  const [showTooltip, setShow] = useState<boolean>(false);
  const [tooltipContent, setTooltipContent] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const setShowTooltip = useCallback((show: boolean) => {
    setShow(show);
  }, []);

  const toggleTooltip = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const closeTooltip = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openTooltip = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const setTooltip = useCallback((content: string) => {
    setTooltipContent(content);
  }, []);

  return {
    tooltip: tooltipControl ?? tooltipContent,
    showTooltip,
    setShowTooltip,
    setTooltip,
    toggleTooltip,
    anchorEl,
    closeTooltip,
    openTooltip,
  };
}
