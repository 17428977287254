import { Box, Button } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PermissionOptions } from "../../../constants/Administrators";
import ActionsPopper from "../../common/ActionsPopper/ActionsPopper";

interface Props {
  onChangeAll: (permission: string) => void;
  isSelected: boolean;
}

export default function PermissionsActionHeader({
  onChangeAll,
  isSelected,
}: Props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const id = useMemo(() => (open ? "permissions-popper" : undefined), [open]);
  const actions = useMemo(() => {
    const entries = Object.entries(PermissionOptions);
    return entries.map(([, value]) => value);
  }, []);

  const closePopper = useCallback(() => setAnchorEl(null), []);

  const openPopper = useCallback(
    (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget),
    [setAnchorEl]
  );

  const handleSetAllClick = useCallback(
    (action: string) => {
      closePopper();
      onChangeAll(action);
    },
    [closePopper, onChangeAll]
  );

  return (
    <Box
      marginBottom="30px"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Button
        onClick={openPopper}
        aria-describedby={id}
        variant="contained"
        color="primary"
        disabled={!isSelected}
      >
        {t("permissions.set_all_to")} ...
      </Button>
      <ActionsPopper
        onActionClick={handleSetAllClick}
        onClose={closePopper}
        actions={actions}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ marginTop: 10 }}
      />
    </Box>
  );
}
