import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  container: {
    padding: "8px 10px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "8px 8px 0 0",
  },
  button: {
    height: 36,
  },
  selectButton: {
    height: 36,
    marginRight: "10px",
    "&.MuiButton-outlined, .MuiButton-root": {
      padding: 0,
    },
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 10,
      paddingRight: 5,
    },
    "& .MuiButton-endIcon": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  menu: {
    height: 350,
    "& .MuiMenu-paper": {
      marginTop: "60px",
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
});
