import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors, Shadows } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      position: "absolute",
      right: 30,
    },
    button: {
      margin: "0 10px",
    },
    draftButton: {
      margin: "0 10px",
      backgroundColor: Colors.Inactive,
    },
    chip: {
      color: Colors.White,
      backgroundColor: Colors.Dark,
    },
    changesChip: {
      color: Colors.White,
      backgroundColor: Colors.StatusYellow,
    },
    infoButton: {
      "&:hover": {
        backgroundColor: "unset",
        "& path:first-child": {
          fill: Colors.Primary,
        },
      },
    },
    infoTooltip: {
      backgroundColor: Colors.White,
      maxWidth: 460,
      width: 460,
      boxShadow: Shadows.BulkAction,
      padding: 0,
    },
  })
);
