import { useMemo, useCallback } from "react";
import { Endpoints } from "../api/constants";
import useSWR, { mutate } from "swr";
import { Choice, UseChoices } from "../types/Choices";
import { useLoading } from "../utils/Loading";
import { mutateData } from "../api/api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  MpRowActionParameters,
  MpRowActionMethods,
  MpBulkActionMethods,
  MpBulkActionCallback,
} from "@mp-react/table";
import moment from "moment";
import { Row } from "react-table";
import fileDownloader from "js-file-download";
import { useCurrency } from "../utils/useCurrency";
import { MpAsyncGetMethod } from "@mp-react/table";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { AxiosResponse } from "axios";
import { ChoicesFilterNames } from "../constants/Choices";

export const useChoices = (query?: string): UseChoices => {
  const { stopLoading, startLoading, loading } = useLoading();
  const now = useMemo(() => moment().format(), []);
  const { t } = useTranslation();
  const { data: choices, error } = useSWR(
    `${Endpoints.benefitChoices}${!!query ? `?${query}` : ""}`
  );
  const { getDefaultCurrencyFormat } = useCurrency();

  const apiLoading = useMemo(() => {
    return !choices && !error;
  }, [error, choices]);

  const parsedChoicesList = useMemo(
    () =>
      choices?.data?.map((data: Choice) => ({
        ...data,
        inactive: moment().diff(moment(data.votingEndDate)) > 0,
      })),
    [choices]
  );

  const parsedChoicesTotals = useMemo(() => {
    const initialTotals = {
      benefitPlanName: "",
      benefitPlanType: "",
      flexImplementationDate: "",
      flexStatus: "",
      investment: "",
      employeeName: "",
    };
    if (!choices?.footer) return initialTotals;
    const {
      employeeName,
      benefitPlanName,
      benefitPlanType,
      flexImplementationDate,
      flexStatus,
      investment,
    } = choices.footer;
    return {
      employeeName: t("totals.employee", { count: Number(employeeName) }),
      benefitPlanName: t("totals.benefit_plan", {
        count: Number(benefitPlanName),
      }),
      benefitPlanType: t("totals.benefit_type", {
        count: Number(benefitPlanType),
      }),
      flexImplementationDate: t("totals.implemented", {
        count: Number(flexImplementationDate),
      }),
      flexStatus: t("totals.not_selected", {
        count: Number(flexStatus),
      }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
    };
  }, [choices, t, getDefaultCurrencyFormat]);

  const getImlementationValue = useCallback(
    (selectedRows: Row<{}>[], type: "date" | "null") => {
      const implementationValue = selectedRows
        ?.filter((item) => {
          const { original } = item as any;
          return original.flexStatus === "selected";
        })
        .map((item) => {
          const { original } = item as any;
          return {
            id: original.id as string,
            flexImplementationDate: type === "date" ? now : null,
          };
        });
      return implementationValue;
    },
    [now]
  );

  const toggleImplementationDate = useCallback(
    (current: MpRowActionParameters) => {
      const { value, rowId } = current;
      const newValue = value ? value : null;
      startLoading();
      mutateData("patch", `${Endpoints.setImplementationDates}`, [
        {
          id: rowId,
          flexImplementationDate: moment(newValue).toISOString(),
        },
      ])
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t]
  );

  const implementationDone: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const implementationValue = getImlementationValue(
        selectedRows as Row<{}>[],
        "date"
      );
      if (implementationValue?.length !== 0) {
        startLoading();
        mutateData(
          "patch",
          `${Endpoints.setImplementationDates}`,
          implementationValue
        )
          .then(() => {
            toast(t("common.updated_succesfully"), { type: "success" });
            mutate(Endpoints.benefitChoices);
          })
          .finally(() => {
            stopLoading();
          });
      } else {
        toast(t("choices.implementation_toast"), { type: "warning" });
      }
    },
    [startLoading, stopLoading, t, getImlementationValue]
  );

  const implementationPending: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const implementationValue = getImlementationValue(
        selectedRows as Row<{}>[],
        "null"
      );
      if (implementationValue?.length !== 0) {
        startLoading();
        mutateData(
          "patch",
          `${Endpoints.setImplementationDates}`,
          implementationValue
        )
          .then(() => {
            toast(t("common.updated_succesfully"), { type: "success" });
            mutate(Endpoints.benefitChoices);
          })
          .finally(() => {
            stopLoading();
          });
      } else {
        toast(t("choices.implementation_toast"), { type: "warning" });
      }
    },
    [startLoading, stopLoading, t, getImlementationValue]
  );

  const getStatusValue = useCallback(
    (
      selectedRows: Row<{}>[],
      type: "notSelected" | "selected" | "declined"
    ) => {
      const statusValue = selectedRows.map((item) => {
        const { original } = item as any;
        return {
          id: original.id as string,
          choiceStatus: type,
        };
      });
      return statusValue;
    },
    []
  );

  const statusSelected: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "selected"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(Endpoints.benefitChoices);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue]
  );

  const statusDeclined: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "declined"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(Endpoints.benefitChoices);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue]
  );

  const statusNotSelected: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "notSelected"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(Endpoints.benefitChoices);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue]
  );

  const exportToExcel = useCallback(() => {
    const url = `${Endpoints.choicesExportToExcel}${
      !!query ? `?${query}` : ""
    }`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    return mutateData("get", url, null, headers, "arraybuffer").then(
      (res: any) => {
        const now = moment().format("L");
        const disposition = res?.headers["content-disposition"];
        const headersFilename = disposition?.split("filename=")?.[1];
        const filename = headersFilename ?? `Benefit choices_${now}.xlsx`;
        fileDownloader(res.data, filename);
      }
    );
  }, [query]);

  const rowMethods: MpRowActionMethods = useMemo(
    () => ({
      toggleImplementationDate,
    }),
    [toggleImplementationDate]
  );

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      implementationDone,
      implementationPending,
      statusSelected,
      statusDeclined,
      statusNotSelected,
    }),
    [
      implementationDone,
      implementationPending,
      statusSelected,
      statusDeclined,
      statusNotSelected,
    ]
  );

  return {
    loading: loading || apiLoading,
    choices,
    choicesError: error,
    rowMethods,
    parsedChoicesList,
    bulkMethods,
    parsedChoicesTotals,
    exportToExcel,
  };
};

export const useChoicesAsyncMethods = (): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(() => `${Endpoints.choicesFilterValues}`, []);

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: ChoicesFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const apiUrl = `${baseUrl}/${filterName}${searchParam}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getFilterItems = useCallback(
    (filterName: ChoicesFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getEmployeeNames = useCallback<MpAsyncGetMethod>(
    (args) => getAsyncFilterItems(args, ChoicesFilterNames.EMPLOYEE_NAME),
    [getAsyncFilterItems]
  );

  const getBenefitPlanType = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(ChoicesFilterNames.BENEFIT_PLAN_TYPE),
    [getFilterItems]
  );

  const getBenefitPlanName = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(ChoicesFilterNames.BENEFIT_PLAN_NAME),
    [getFilterItems]
  );

  return {
    getEmployeeNames,
    getBenefitPlanType,
    getBenefitPlanName,
  };
};
