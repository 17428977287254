import { Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import { Row, UseRowSelectRowProps } from "react-table";
import { useGroupsAssignBenefitsTable } from "../../../../../configs/Tables/GroupsAssignBenefitsTableType";
import {
  useEmployeeGroupsBenefitAssignmentAsyncMethods,
  useGroupsAssignBenefits,
} from "../../../../../state/GroupsAssignBenefits";
import { GroupsAssignBenefitsTableItem } from "../../../../../types/Groups";
import useTableUtils from "../../../../../utils/Table";
import React, { useCallback, useMemo, useState } from "react";
import { MpRowActionParameters, MpTable } from "@mp-react/table";
import FormFooter from "../../../../../components/layouts/FormFooter/FormFooter";
import AssignBenefitsFooterButtons from "../../../../../components/employees/AssignBenefitsFooterButtons/AssignBenefitsFooterButtons";
import { useTranslation } from "react-i18next";
import FormDialog from "../../../../../components/dialogs/FormDialog/FormDialog";
import {
  BenefitStatus,
  BenefitTypes,
  UpdateBenefitRequest,
} from "../../../../../types/Benefits";
import moment from "moment";
import { toast } from "react-toastify";
import { useBenefits, useBenefitStore } from "../../../../../state/Benefits";
import { useChoicesDeadlineForm } from "../../../../../configs/Forms/BenefitsForms/Settings/ChoicesDeadlineForm";
import { useDialog } from "../../../../../utils/Dialog";
import { useInvestmentCalculationsStore } from "../../../../../state/InvestmentCalculations";
import {
  CheckAssignBaseResponse,
  Investment,
  InvestmentTypes,
} from "../../../../../types/Common";
import useStyles from "../../../../../styles/Table.styles";
import { useMe } from "../../../../../state/Administrators";
import { useBenefitUtils } from "../../../../../utils/Benefits";
import { TableContext } from "../../../../../state/Table";

export default function AssignBenefits() {
  const classes = useStyles();
  const { canEditBenefits, canEditGroups, hasBenefitPermissions } = useMe();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const {
    overridables,
    overrideClasses,
    handleGetData,
    translations,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
    isRowInvestmentAdded,
  } = useTableUtils("innerGroupAssignBenefits");
  const {
    loading,
    groupsAssignBenefitsData,
    assignBenefitsToGroup,
    mapGroupAssignBenefitsRequest,
    parsedGroupAssignBenefitsTotals,
    checkGroupToBenefitChanges,
  } = useGroupsAssignBenefits(id, tableQueryParam);
  const GroupsAssignBenefitsTableType = useGroupsAssignBenefitsTable();
  const {
    closeDialog: closeFlexForm,
    open: isFlexFormOpen,
    openDialog: openFlexForm,
  } = useDialog();
  const flexForm = useChoicesDeadlineForm(true);
  const asyncGetMethods = useEmployeeGroupsBenefitAssignmentAsyncMethods(id);

  const [selectedRow, setSelectedRow] = useState<string>("");

  const [changeResponse, setChangeResponse] =
    useState<CheckAssignBaseResponse>();

  const {
    checkBenefitPlanSettings,
    updateBenefit,
    loading: loadingBenefits,
  } = useBenefits(selectedRow);
  const { parseChoicesDateForRequest } = useBenefitUtils();

  const { votingStartDate, votingEndDate } = useBenefitStore(
    useCallback(({ votingEndDate, votingStartDate }) => {
      return { votingEndDate, votingStartDate };
    }, [])
  );
  const resetAllChanges = useInvestmentCalculationsStore(
    useCallback((state) => state.resetAllChanges, [])
  );
  const setStatus = useInvestmentCalculationsStore(
    useCallback((state) => state.setStatus, [])
  );
  const increaseChangeCount = useInvestmentCalculationsStore(
    useCallback((state) => state.increaseChangeCount, [])
  );
  const setInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.setInvestments, [])
  );
  const removeChange = useInvestmentCalculationsStore(
    useCallback((state) => state.removeChange, [])
  );
  const resetInvestments = useInvestmentCalculationsStore(
    useCallback((state) => state.resetInvestments, [])
  );

  const getSelectedRowData = useCallback(
    (selectedRow: string) => {
      const data =
        groupsAssignBenefitsData?.find((item) => item.id === selectedRow) ??
        ({} as GroupsAssignBenefitsTableItem);
      return data;
    },
    [groupsAssignBenefitsData]
  );

  const selectedRowData = useMemo(
    () => getSelectedRowData(selectedRow),
    [getSelectedRowData, selectedRow]
  );
  const initialStatus = useMemo(
    () => selectedRowData?.currentStatus as BenefitStatus,
    [selectedRowData?.currentStatus]
  );

  const handleRowClick = useCallback(
    (current?: Row<{}> & UseRowSelectRowProps<{}>) => {
      if (hasBenefitPermissions) history.push(`/benefits/view/${current?.id}`);
    },
    [hasBenefitPermissions, history]
  );

  const toggleStatus = useCallback(
    async (current: MpRowActionParameters) => {
      const { value: newStatus, rowId: benefitPlanId } = current;
      const selectedRowData = getSelectedRowData(benefitPlanId as string);
      const initialStatus = selectedRowData?.currentStatus as BenefitStatus;
      if (initialStatus === newStatus || !newStatus || !benefitPlanId) return;

      setSelectedRow(benefitPlanId as string);

      const assignmentData = await checkGroupToBenefitChanges({
        benefitPlanId: benefitPlanId as string,
        newStatus: newStatus as "flex" | "on" | "off",
        employeeGroupId: id as string,
      });
      setChangeResponse(assignmentData);

      if (newStatus === "flex") {
        const hasFlexSettings = await checkBenefitPlanSettings(
          benefitPlanId as string
        );
        if (!hasFlexSettings) {
          openFlexForm();
          return;
        }
      }

      const investmentData: CheckAssignBaseResponse & Investment = {
        id: benefitPlanId as string,
        totalInvestmentAmount: assignmentData.totalInvestmentAmount,
        investmentAmountChange: assignmentData.investmentAmountChange,
        added: isRowInvestmentAdded(initialStatus, newStatus as BenefitStatus),
        investment: assignmentData.investmentAmountChange,
        name: selectedRowData.name,
        type: InvestmentTypes.benefits,
        employeeCount: selectedRowData.employeeCount,
        benefitType: selectedRowData?.type as BenefitTypes,
      };
      setInvestments(investmentData);
      increaseChangeCount(benefitPlanId);
    },
    [
      checkBenefitPlanSettings,
      checkGroupToBenefitChanges,
      getSelectedRowData,
      id,
      increaseChangeCount,
      isRowInvestmentAdded,
      openFlexForm,
      setInvestments,
    ]
  );

  const handleCancelFooter = useCallback(() => {
    resetAllChanges();
    setStatus(null);
    resetInvestments();
  }, [resetAllChanges, resetInvestments, setStatus]);

  const handlePublishFooter = useCallback(
    (date?: string) => {
      const requestData = mapGroupAssignBenefitsRequest(date);
      assignBenefitsToGroup(requestData);
    },
    [assignBenefitsToGroup, mapGroupAssignBenefitsRequest]
  );

  const handleFlexFormCancel = useCallback(() => {
    closeFlexForm();
    setStatus(null, selectedRow);
    removeChange(selectedRow);
  }, [closeFlexForm, removeChange, selectedRow, setStatus]);

  const handleFlexSettingSubmit = useCallback(
    (data: Partial<UpdateBenefitRequest>) => {
      if (!!votingStartDate && !!votingEndDate) {
        if (moment(votingEndDate).isBefore(votingStartDate)) {
          toast(t("errors.voting_end_date_before_voting_start_date"), {
            type: "error",
          });
          return;
        }
        data.votingStartDate = votingStartDate;
        data.votingEndDate = votingEndDate;
      }
      const parsedData = parseChoicesDateForRequest(data);

      updateBenefit(parsedData as UpdateBenefitRequest, () => {
        closeFlexForm();
        increaseChangeCount(selectedRowData?.id ?? "");
        const statusValues = {
          currentStatus: { [selectedRowData?.id ?? ""]: { value: "flex" } },
        };
        setStatus(statusValues);
        if (!changeResponse) return;
        const investmentData: CheckAssignBaseResponse & Investment = {
          id: selectedRowData?.id ?? "",
          totalInvestmentAmount: changeResponse?.totalInvestmentAmount,
          investmentAmountChange: changeResponse?.investmentAmountChange,
          added: isRowInvestmentAdded(initialStatus, BenefitStatus.flex),
          investment: changeResponse?.investmentAmountChange,
          name: selectedRowData?.name ?? "",
          type: InvestmentTypes.benefits,
          benefitType: selectedRowData?.type as BenefitTypes,
        };
        setInvestments(investmentData);
      });
    },
    [
      changeResponse,
      closeFlexForm,
      increaseChangeCount,
      initialStatus,
      isRowInvestmentAdded,
      parseChoicesDateForRequest,
      selectedRowData?.id,
      selectedRowData?.name,
      selectedRowData?.type,
      setInvestments,
      setStatus,
      t,
      updateBenefit,
      votingEndDate,
      votingStartDate,
    ]
  );

  return (
    <TableContext.Provider value={{ id, tableQueryParam }}>
      <Box
        className={canEditBenefits && canEditGroups ? "" : classes.disableTable}
      >
        <MpTable
          {...GroupsAssignBenefitsTableType}
          data={groupsAssignBenefitsData}
          onGetData={handleGetData}
          overridables={overridables}
          totals={groupsAssignBenefitsData?.length > 0}
          totalsData={parsedGroupAssignBenefitsTotals}
          stickyTotals={true}
          disablePagination={true}
          classes={overrideClasses}
          loading={loading}
          enableGlobalActions={true}
          rowMethods={{ toggleStatus }}
          translations={translations}
          onRowClick={handleRowClick}
          loadingComponent={loadingComponent}
          emptyChildren={noDataComponent}
          asyncGetMethods={asyncGetMethods}
        />
        <FormDialog
          onCancelClick={handleFlexFormCancel}
          onSubmitForm={handleFlexSettingSubmit}
          id="change-choices-deadline-form"
          open={isFlexFormOpen}
          title={t(
            "assign_employees.benefit_choices_deadline_not_set"
          )?.replace("{name}", selectedRowData?.fullName)}
          actionLabel={t("common.confirm")}
          form={flexForm}
          subtitle={t("assign_employees.fill_in_fields_to_change_status")}
          loading={loadingBenefits}
        />
        <FormFooter withChanges>
          <AssignBenefitsFooterButtons
            onCancel={handleCancelFooter}
            onPublish={handlePublishFooter}
          />
        </FormFooter>
      </Box>
    </TableContext.Provider>
  );
}
