import { IconButton } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { FormEndAdornmentProps } from "../../../../types/Form";
import useStyles from "./ClearEndAdornment.styles";

export default function ClearEndAdornment({
  control,
  ...rest
}: FormEndAdornmentProps) {
  const classes = useStyles();
  const formContext = useFormContext();

  if (!formContext)
    throw new Error(
      "Please provide a form context in order to use end adornments"
    );

  const formFieldControls = useMemo(() => {
    const controls = [control];
    if (!!control.children && control.children.length > 0) {
      control.children.forEach((childField) => controls.push(childField));
    }
    return controls.filter(({ key }) => !!key);
  }, [control]);

  const getEmptyValueByType = useCallback((type: string) => {
    switch (type) {
      case "date":
        return null;
      case "time":
        return null;
      case "select":
        return "";
      case "dropdown":
        return "";
      case "password":
        return "";
      case "phone":
        return "";
      case "customDropdown":
        return;
      case "number":
        return "";
      case "text":
        return "";
      case "multiline":
        return "";
      case "switch":
        return false;
      default:
        return null;
    }
  }, []);

  const clearFormFieldKeys = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      formFieldControls.forEach(({ key, type }) => {
        const emptyValue = getEmptyValueByType(type);
        !!key && formContext.setValue(key, emptyValue, { shouldDirty: true });
      });
    },
    [formContext, formFieldControls, getEmptyValueByType]
  );

  return (
    <IconButton
      className={classes.clearButton}
      onClick={clearFormFieldKeys}
      {...rest}
    >
      <CrossIcon className={classes.clearIcon} />
    </IconButton>
  );
}
