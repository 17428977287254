import { useTranslation } from "react-i18next";
import { MpControlType, MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { TooltipControl } from "../../types/Common";
import { useCountryCodes } from "../../constants/Countries";

export const useCreateClientForm = (): MpFormType => {
  const { t } = useTranslation();
  const countries = useCountryCodes();

  const options = useMemo(() => {
    return countries.map((country) => ({
      type: "select" as any,
      key: country.alpha3,
      label: country.name,
    }));
  }, [countries]);

  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: "dropdown" as any,
        key: "companyCountry",
        label: t("clients.country"),
        required: true,
        children: options,
        tooltip: "tooltip.add_client_country",
      },
      {
        type: MpControlType.text,
        key: "companyName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
          pattern: t("form.pattern"),
        },
        label: t("companies.company_name"),
        required: true,
        tooltip: "tooltip.add_client_company_name",
      },
      {
        type: MpControlType.text,
        key: "companyCode",
        messages: {
          required: t("form.required"),
        },
        label: t("companies.company_code"),
        required: true,
        tooltip: "tooltip.clients_company_code",
        placeholder: t("companies.company_code"),
      },
      {
        type: MpControlType.text,
        key: "adminEmail",
        validations: {
          minLength: 2,
          pattern:
            "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])",
        },
        messages: {
          required: t("form.required"),
          pattern: t("form.pattern"),
        },
        label: t("client.admin_email"),
        required: true,
        tooltip: "tooltip.add_client_email",
      },
      {
        type: MpControlType.text,
        key: "adminFirstName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
        },
        label: t("client.admin_first_name"),
        required: true,
        tooltip: "tooltip.add_client_first_name",
      },
      {
        type: MpControlType.text,
        key: "adminLastName",
        validations: {
          minLength: 1,
        },
        messages: {
          required: t("form.required"),
        },
        label: t("client.admin_last_name"),
        required: true,
        tooltip: "tooltip.add_client_last_name",
      },
      {
        type: "phone" as MpControlType,
        key: "adminPhone",
        messages: {
          required: t("form.required"),
          minLength: t("form.min_length"),
        },
        label: t("client.admin_phone"),
        required: true,
        tooltip: "tooltip.add_client_phone",
      },
      {
        type: MpControlType.multiline,
        key: "internalComment",
        label: t("common.internal_comment"),
        placeholder: t("common.comments"),
        tooltip: "tooltip.add_client_internal_comment",
      },
    ],
    [options, t]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
