import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& .MuiTableCell-root.MuiTableCell-body": {
        "&:nth-child(3)": {
          width: "10px",
          textAlign: "right",
        },
      },
    },
    disableTable: {
      "& .MuiTableCell-root.MuiTableCell-body:last-child:not(:first-child)": {
        pointerEvents: "none",
      },
    },
  })
);
