import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DialogExtended from "../../../components/common/DialogExtended/DialogExtended";
import { IconButton, Divider, Tooltip } from "@material-ui/core";
import { Close, Benefits, Location, Categories } from "@melp-design/icons";
import {
  Typography,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@melp-design/components";
import { Colors, Effects } from "@melp-design/style";
import { Colors as OldColors } from "../../../constants/Style";
import {
  BenefitTemplate,
  BenefitTemplatesFilters,
} from "../../../types/BenefitTemplates";
import { useBenefitTypes } from "../../../constants/Benefits";
import CountryFlag from "../../../components/common/CountryFlag/CountryFlag";
import { useBenefitTemplates } from "../../../state/BenefitTemplates.clientAdmin";
import { Alert } from "@material-ui/lab";
import countries from "../../../utils/Countries";
import FilterButton from "../../filters/FilterButton";
import SelectFilter from "../../filters/SelectFilter";
import { useSupportedLanguages } from "../../../constants/Companies";
import NoData from "../../common/NoData/NoData";
import { isDefined } from "../../../utils/isDefined";
import Loader from "../../common/Loader/Loader";
import { useLoading } from "../../../utils/Loading";
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: "1050px",
    width: "100%",
    borderRadius: 20,
  },
  dialogTitle: {
    padding: "30px 50px 30px 30px",
  },
  dialogContent: {
    display: "flex",
    padding: 0,
    overflow: "auto",
    minHeight: "480px",
  },
  dialogActions: {
    padding: "15px 30px",
    "& > :not(:first-child)": {
      marginLeft: 25,
    },
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    top: "20px",
  },
  listContainer: {
    flex: 8,
    padding: "30px",
    background: Colors.lightBackgroundGrey,
    overflow: "auto",
  },
  list: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 180px)",
    gap: "30px",
  },
  sidebar: {
    flex: 4,
    borderLeft: `1px solid ${OldColors.Divider}`,
    boxShadow: Effects.blockShadow,
    background: theme.palette.common.white,
    padding: "30px",
    overflow: "auto",
    position: "relative",
    // todo: fix issues with fixed position when scrolling
    // "&::after": {
    //   content: '""',
    //   position: "absolute",
    //   width: "100%",
    //   bottom: 0,
    //   left: 0,
    //   background:
    //     "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
    //   height: "50px",
    // },
  },
  sidebarDivider: {
    margin: "20px 0",
  },
  dialogAction: {
    minWidth: 190,
  },
  infoItem: {
    marginBottom: 40,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  infoItemTitle: {
    marginBottom: 10,
    display: "flex",
    columnGap: 5,
    "& svg": {
      color: Colors.grey,
      height: 20,
    },
  },
  countries: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: 15,
    rowGap: 15,
  },
  country: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  templateImage: {
    backgroundColor: Colors.elementsGrey,
  },
  filtersContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 20,
  },
  filtersTitle: {
    marginRight: 10,
    "&::after": {
      content: "':'",
    },
  },
  filtersButtons: {
    display: "flex",
    gap: 10,
  },
  cardContent: {
    paddingBottom: 15,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onCreate: (templateId?: string) => Promise<any>;
}

const TemplateDialog = (props: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState<BenefitTemplate>();

  const [filters, setFilters] = useState<BenefitTemplatesFilters>({});
  useEffect(() => {
    setSelectedTemplate(undefined);
  }, [filters]);

  const categories = useBenefitTypes();

  const { data: templates, error } = useBenefitTemplates(filters);

  const renderInfoItem = (
    icon: React.ReactNode,
    title: string,
    content: React.ReactNode
  ) => {
    return (
      <div className={classes.infoItem}>
        <div className={classes.infoItemTitle}>
          {icon}
          <Typography variant="h3">{title}</Typography>
        </div>
        {content}
      </div>
    );
  };

  const resolveTranslation = (template: BenefitTemplate) => {
    const { translations } = template;
    if (!translations.length) {
      return { title: template.name, description: "" };
    }
    const findTranslationByLang = (lang: string) =>
      template.translations.find(({ language }) => language === lang);
    const currentLanguage = i18n.language.toUpperCase();
    const userLangTranslation = findTranslationByLang(currentLanguage);
    if (userLangTranslation) {
      return userLangTranslation;
    }
    const defaultTranslation = findTranslationByLang(template.defaultLanguage);
    return defaultTranslation ?? template.translations[0];
  };

  const selectedTemplateTranslation = selectedTemplate
    ? resolveTranslation(selectedTemplate)
    : undefined;

  const countriesOptions = countries.getAll();
  const benefitCategoriesOptions = categories.map(({ value, name }) => ({
    key: value,
    label: t(name),
  }));
  const languageCodes = useSupportedLanguages();
  const languageOptions = languageCodes.map((languageCode) => ({
    key: languageCode,
    label: t(`language.${languageCode}`),
  }));

  const activeFilterExists = !!Object.keys(filters).some((key) =>
    isDefined(filters[key as keyof BenefitTemplatesFilters])
  );

  const createWithoutTemplateState = useLoading();
  const createWithTemplateState = useLoading();

  return (
    <>
      <DialogExtended
        open={props.open}
        onClose={props.onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <IconButton className={classes.closeButton} onClick={props.onClose}>
            <Close />
          </IconButton>
          <Typography variant="h1">
            {t("benefits.createUsingTemplateDialogTitle")}
          </Typography>
          <div className={classes.filtersContainer}>
            <Typography
              variant="p2"
              color="textSecondary"
              className={classes.filtersTitle}
            >
              {t("benefits.filters")}
            </Typography>
            <div className={classes.filtersButtons}>
              <FilterButton
                label="Country"
                value={filters.supportedCountries}
                onChange={(value) =>
                  setFilters((current) => ({
                    ...current,
                    supportedCountries: value,
                  }))
                }
                disablePortal
              >
                {({ value, applyFilter, clearFilter }) => (
                  <SelectFilter
                    multiple
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    options={countriesOptions}
                  />
                )}
              </FilterButton>
              <FilterButton
                label="Benefit category"
                value={filters.type}
                onChange={(value) =>
                  setFilters((current) => ({
                    ...current,
                    type: value,
                  }))
                }
                disablePortal
              >
                {({ value, applyFilter, clearFilter }) => (
                  <SelectFilter
                    multiple
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    options={benefitCategoriesOptions}
                  />
                )}
              </FilterButton>
              <FilterButton
                label="Language"
                value={filters.supportedLanguages}
                onChange={(value) =>
                  setFilters((current) => ({
                    ...current,
                    supportedLanguages: value,
                  }))
                }
                disablePortal
              >
                {({ value, applyFilter, clearFilter }) => (
                  <SelectFilter
                    multiple
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    options={languageOptions}
                  />
                )}
              </FilterButton>
              {activeFilterExists && (
                <Tooltip title={t("common.clearFilters") ?? ""}>
                  <Button size="small" iconOnly onClick={() => setFilters({})}>
                    <Close color={Colors.red} />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <div className={classes.listContainer}>
            {!templates && !error && <Loader />}
            {!templates && error && (
              <Alert severity="error">{t("errors.something_went_wrong")}</Alert>
            )}
            {templates && !templates.length && (
              <NoData filtersApplied={activeFilterExists} />
            )}
            {templates && !!templates.length && (
              <div className={classes.list}>
                {templates.map((template) => (
                  <Card
                    key={template.id}
                    highlighted={selectedTemplate?.id === template.id}
                  >
                    <CardActionArea
                      onClick={() => setSelectedTemplate(template)}
                    >
                      <CardMedia
                        image={template.thumbnail.signedUrl}
                        className={classes.templateImage}
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography variant="h3" align="center">
                          {resolveTranslation(template).title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </div>
            )}
          </div>
          <div className={classes.sidebar}>
            {!selectedTemplate && (
              <>
                <Typography variant="h2">
                  {t("benefits.aboutTemplatesTitle")}
                </Typography>
                <Divider className={classes.sidebarDivider} />
                <Typography variant="p1" color="textSecondary">
                  {t("benefits.templatesDescription")}
                </Typography>
              </>
            )}
            {!!selectedTemplate && selectedTemplateTranslation && (
              <>
                <Typography variant="h2">
                  {selectedTemplateTranslation.title}
                </Typography>
                <Divider className={classes.sidebarDivider} />
                {selectedTemplateTranslation.description && (
                  <Typography
                    variant="p1"
                    color="textSecondary"
                    className={classes.infoItem}
                  >
                    {selectedTemplateTranslation.description}
                  </Typography>
                )}
                {renderInfoItem(
                  <Benefits />,
                  t("benefits.benefitCategory"),
                  <Typography variant="p1" color="textSecondary">
                    {t(
                      categories.find(
                        (category) => category.value === selectedTemplate.type
                      )?.name ?? ""
                    )}
                  </Typography>
                )}
                {renderInfoItem(
                  <Location />,
                  t("benefits.country"),
                  <div className={classes.countries}>
                    {selectedTemplate.supportedCountries.map(
                      (supportedCountry) => {
                        return (
                          <div className={classes.country}>
                            <CountryFlag countryCode={supportedCountry} />
                            <Typography variant="p1" color="textSecondary">
                              {countries.getName(supportedCountry)}
                            </Typography>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {renderInfoItem(
                  <Categories />,
                  t("benefits.languages"),
                  <Typography variant="p1" color="textSecondary">
                    {selectedTemplate.supportedLanguages
                      .map((language) => t(`language.${language}`))
                      .join(", ")}
                  </Typography>
                )}
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.dialogAction}
            onClick={async () => {
              createWithoutTemplateState.startLoading();
              try {
                await props.onCreate();
              } finally {
                createWithoutTemplateState.stopLoading();
              }
            }}
            loading={createWithoutTemplateState.loading}
          >
            {t("benefits.createWithoutTemplate")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.dialogAction}
            onClick={async () => {
              if (selectedTemplate) {
                createWithTemplateState.startLoading();
                try {
                  await props.onCreate(selectedTemplate.id);
                } finally {
                  createWithTemplateState.stopLoading();
                }
              }
            }}
            disabled={!selectedTemplate}
            loading={createWithTemplateState.loading}
          >
            {t("benefits.useTemplate")}
          </Button>
        </DialogActions>
      </DialogExtended>
    </>
  );
};

export default TemplateDialog;
