import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Typography,
  Box,
  Chip,
} from "@material-ui/core";
import useStyles from "./CurrentStatusDialog.styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import CurrentStatusContent from "../../employees/AssignBenefitsCurrentStatusContent/CurrentStatusContent";
import { useStatusStyle } from "../../../utils/Style/Style";
import { CheckAssignConflictsResponse } from "../../../types/Common";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps extends DialogProps {
  actionLabel: string;
  onCancelClick: () => void;
  onSubmitForm: SubmitHandler<{}>;
  onGroupsChange: (isChanged: boolean) => void;
  id: string;
  title: string;
  statusValue: string;
  statusBoolean: boolean;
  changeResponse?: CheckAssignConflictsResponse;
  employeeName: string;
  benefitName: string;
}

interface Status {
  name: string;
  value: boolean;
  id?: string;
}

const CurrentStatusDialog = ({
  actionLabel,
  onCancelClick,
  onSubmitForm,
  onGroupsChange,
  id,
  title,
  statusValue,
  statusBoolean,
  changeResponse,
  employeeName,
  benefitName,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const useFormMethods = useForm({});
  const { control, handleSubmit, reset, clearErrors } = useFormMethods;
  const { fields } = useFieldArray({
    control,
    name: "conflicts",
    keyName: "conflicts.id",
  });
  const [status, setStatus] = useState<Status[]>([]);
  const { statusClassName } = useStatusStyle({ status: statusValue });

  useEffect(() => {
    if (!!changeResponse) reset(changeResponse);
  }, [changeResponse, reset]);

  const fillStatuses = useCallback(() => {
    const newArray = fields.map((field, index) => {
      return {
        id: field.id,
        name: `conflicts[${index}].included`,
        value: field.included,
      };
    });
    setStatus(newArray);
  }, [fields]);

  useEffect(
    () => fillStatuses(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleError: SubmitErrorHandler<Record<string, any>> =
    useCallback(() => {}, []);

  const handleClose = useCallback(() => {
    clearErrors();
    onCancelClick();
  }, [clearErrors, onCancelClick]);

  const isGroupChange = useMemo(() => {
    if (status.length === 0) return false;
    return Object.values(status).every((item) => item.value === statusBoolean);
  }, [status, statusBoolean]);

  const submitData = useCallback(() => {
    isGroupChange && handleSubmit(onSubmitForm, handleError)();
    onGroupsChange(isGroupChange);
  }, [handleSubmit, onSubmitForm, handleError, isGroupChange, onGroupsChange]);

  const handleStatusArrayChange = useCallback((props: any, e: any) => {
    e.stopPropagation();
    setStatus((prevState) => [
      ...prevState.filter((state) => state.name !== props.name),
      {
        name: props.name,
        value: e.target.checked,
      },
    ]);
  }, []);

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={handleClose}
      onEnterPress={submitData}
      {...rest}
    >
      <Typography variant="h5" id={id}>
        {title}
      </Typography>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Grid container className={classes.dividerBottom}>
          <Grid item xs={8} style={{ maxWidth: 345 }}>
            <Typography variant="subtitle2" color="textSecondary">
              "{benefitName}" {t("common.is")}:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" color="textSecondary">
              {employeeName} {t("common.is")}:
            </Typography>
          </Grid>
        </Grid>
        <Box>
          {fields.map((field, index) => (
            <CurrentStatusContent
              investmentAmount={changeResponse?.investmentAmountChange ?? 0}
              field={field}
              name={`conflicts[${index}]`}
              control={control}
              key={field.id}
              handleStatusArrayChange={handleStatusArrayChange}
            />
          ))}
        </Box>
        <Grid container className={classes.dividerBottom}>
          <Grid item xs={2}>
            <Chip
              component={Box}
              label={statusValue}
              className={`${classes.chip} ${statusClassName}`}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body2">
              {t("employees.for_employees")?.replace(
                "{employee}",
                employeeName
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={handleClose} variant="text">
          {t("common.cancel")}
        </Button>
        <Button onClick={submitData} variant="contained" color="secondary">
          {actionLabel}
        </Button>
      </DialogActions>
    </DialogExtended>
  );
};

export default CurrentStatusDialog;
