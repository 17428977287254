import { Grid, Box } from "@material-ui/core";
import React, { PropsWithChildren } from "react";

export default function InnerWithTabsLeft({ children }: PropsWithChildren<{}>) {
  return (
    <Grid xs={8} item component={Box} maxWidth="640px !important" height={605}>
      <Box
        marginTop="25px"
        paddingLeft="30px"
        paddingRight="30px"
        marginBottom="11px"
      >
        {children}
      </Box>
    </Grid>
  );
}
