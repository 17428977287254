import { MarketplaceItemCategory } from "../types/MarketplaceItems";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const translationNamespace = "marketplaceItems";
const labelPrefix = "itemCategory";
const availableCategoriesKeys = [
  MarketplaceItemCategory.EDUCATION,
  MarketplaceItemCategory.ELECTRONICS,
  MarketplaceItemCategory.ENTERTAINMENT,
  MarketplaceItemCategory.EVENTS_AND_CONFERENCES,
  MarketplaceItemCategory.FOOD_AND_GROCERY,
  MarketplaceItemCategory.FURNITURE_AND_HOME_DECOR,
  MarketplaceItemCategory.HEALTH_AND_BEAUTY,
  MarketplaceItemCategory.INSURANCE,
  MarketplaceItemCategory.LEISURE,
  MarketplaceItemCategory.MEDICAL,
  MarketplaceItemCategory.ONLINE_SUPERMARKETS,
  MarketplaceItemCategory.SPORT_ACTIVITIES,
  MarketplaceItemCategory.SPORTS_ACCESSORIES,
  MarketplaceItemCategory.TRAVELLING_AND_TRANSPORTATION,
];

export const useMarketplaceItemCategories = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      availableCategoriesKeys.map((key) => ({
        key,
        label: t(`${translationNamespace}.${labelPrefix}-${key}`),
      })),
    [t]
  );
};
