import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  MenuItem,
  TextField,
  Select,
  Typography,
  Box,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./SingleFieldDIalog.styles";
import { SelectOption } from "../../../types/Common";
import useTooltip from "../../../utils/Tooltip";
import FormTooltip from "../../common/FormTooltip/FormTooltip";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps extends DialogProps {
  actionLabel: string;
  onCancelClick: () => void;
  onActionClick: (value: string) => void;
  id: string;
  title: string;
  placeholder?: string;
  defaultValue?: string;
  type?: "select" | "input";
  options?: SelectOption[];
  additionalActions?: JSX.Element[];
  tooltip?: string;
}

const SingleFieldDialog = ({
  actionLabel,
  onCancelClick,
  onActionClick,
  id,
  title,
  placeholder,
  defaultValue,
  type,
  options,
  additionalActions,
  tooltip,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState<string>("");
  const { openTooltip, closeTooltip, anchorEl } = useTooltip();

  useEffect(() => {
    setValue(defaultValue ?? "");
  }, [defaultValue]);

  const handleTextFieldChange = useCallback(
    (e: any) => setValue(e.target.value),
    []
  );

  const handleCancel = useCallback(() => {
    onCancelClick();
    setValue("");
  }, [onCancelClick]);

  const handleSubmit = useCallback(() => {
    onActionClick(value);
    setValue("");
  }, [onActionClick, value]);

  const Field = useMemo(
    () =>
      type === "select" ? (
        <Select
          variant="outlined"
          value={value}
          onChange={handleTextFieldChange}
          fullWidth
        >
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {t(option.name)}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField
          variant="outlined"
          value={value}
          onChange={handleTextFieldChange}
          placeholder={placeholder}
          fullWidth
        />
      ),
    [handleTextFieldChange, options, placeholder, t, type, value]
  );

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={onCancelClick}
      onEnterPress={() => !!value && handleSubmit()}
      {...rest}
    >
      <Typography variant="h5" id={id}>
        {title}
      </Typography>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Box
          position="relative"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          {Field}
          <FormTooltip tooltip={tooltip ?? ""} anchorEl={anchorEl} />
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Box>{additionalActions}</Box>
        <Box>
          <Button
            classes={{ root: classes.cancelButton }}
            onClick={handleCancel}
            variant="text"
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!value}
          >
            {actionLabel}
          </Button>
        </Box>
      </DialogActions>
    </DialogExtended>
  );
};

export default SingleFieldDialog;
