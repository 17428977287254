import { useTranslation } from "react-i18next";
import { MpControlType, MpFormType } from "@mp-react/form";
import { useMemo } from "react";
import { CustomFormControl } from "../../types/Common";
import { useDatePlaceholder } from "../../utils/Common";
import { useCurrency } from "../../utils/useCurrency";

export const useFlexBenefitAllocationsForm = (): MpFormType => {
  const { t } = useTranslation();
  const { datePlaceholder } = useDatePlaceholder();
  const { currency } = useCurrency();

  const controls = useMemo(
    (): CustomFormControl[] => [
      {
        type: "date" as any,
        key: "startDate",
        messages: {
          required: t("form.required"),
        },
        placeholder: datePlaceholder,
        label: t("employees.limits_starts_from"),
        required: true,
        tooltip: "tooltip.add_flex_benefit_allocation_limits_starts_from",
        disablePast: true,
      },
      {
        type: MpControlType.switch,
        key: "accrual",
        label: t("employees.accrual"),
        tooltip: "tooltip.add_flex_benefit_allocation_accrual",
      },
      {
        type: "dropdown" as any,
        key: "allocationFrequency",
        messages: {
          required: t("form.required"),
        },
        tooltip: "tooltip.add_flex_benefit_allocation_allocation_frequency",
        label: t("employees.allocation_frequency"),
        placeholder: t("employees.select"),
        required: true,
        children: [
          {
            type: "select" as any,
            key: "monthly",
            label: t("common.monthly"),
          },
          {
            type: "select" as any,
            key: "quarterly",
            label: t("employees.quarterly"),
          },
          {
            type: "select" as any,
            key: "annually",
            label: t("employees.annually"),
          },
        ],
      },
      {
        type: MpControlType.number,
        key: "allocatedAmount",
        placeholder: "0",
        tooltip: "tooltip.add_flex_benefit_allocation_allocated_amount",
        validations: {
          min: 0,
        },
        messages: {
          required: t("form.required"),
          minLength: t("employees.amount_cant_be_zero"),
        },
        label: t("employees.allocated_amount"),
        endAdornment: currency,
        required: true,
      },
    ],
    [t, datePlaceholder, currency]
  );

  return {
    controls,
    size: "small",
    layout: "separated",
    variant: "outlined",
  };
};
