import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { TabUnselected } from "@material-ui/icons";
import React, { useCallback, useContext, useMemo, useEffect } from "react";
import useStyles from "./SelectionFilter.styles";
import {
  MetaContext,
  MpFilterProps,
  useFilterItems,
  useFilterOptions,
} from "@mp-react/table";

const empty: any[] = [];

export default function SelectionFilter({
  column,
  value = empty,
  setValue,
}: MpFilterProps) {
  const { mpColumn } = column;
  const { translations } = useContext(MetaContext);
  const classes = useStyles();
  const filter = useFilterOptions(mpColumn);
  const {
    loading,
    items: asyncItems,
    getItems,
  } = useFilterItems(column, filter);
  const items = useMemo(() => {
    if (!!asyncItems) {
      return asyncItems;
    }

    if (!filter?.items) return [];

    return filter?.items;
  }, [asyncItems, filter?.items]);

  const itemValues = useMemo(() => {
    if (!!asyncItems) {
      return asyncItems;
    }
    return items?.map((i) => i?.value ?? []);
  }, [asyncItems, items]);

  const allSelected = useMemo(
    () =>
      JSON.stringify(
        value?.filter((val: string) => val !== "all_selected")?.sort()
      ) === JSON.stringify(itemValues?.sort()),
    [itemValues, value]
  );

  const handleChange = useCallback(
    (event: any) => {
      if (event.target.checked) {
        if (event.target.name === "all") {
          setValue([...itemValues, "all_selected"]);
        } else if (!value?.includes(event.target.name)) {
          const newValues = [...(value ?? []), event.target.name];
          const allSelected =
            JSON.stringify(newValues?.sort()) ===
            JSON.stringify(itemValues?.sort());
          if (allSelected) setValue([...newValues, "all_selected"]);
          else setValue(newValues);
        }
      } else {
        if (event.target.name === "all") {
          setValue(null);
        } else {
          setValue(
            value?.filter(
              (val: any) => val !== event.target.name && val !== "all_selected"
            )
          );
        }
      }
    },
    [value, itemValues, setValue]
  );

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <Box>
      {!loading && (items?.length ?? 0) > 0 && (
        <Box
          paddingY="10px"
          maxHeight={310}
          minWidth={220}
          overflow="auto"
          className={classes.selectWrapper}
        >
          <FormControl
            component="fieldset"
            color="primary"
            className={classes.fullWidth}
          >
            <FormGroup className={classes.fullWidth}>
              <FormControlLabel
                key="all-items"
                onChange={handleChange}
                name="all"
                className={`${classes.listItem} ${
                  allSelected && classes.activeRow
                }`}
                control={
                  <Checkbox
                    color="primary"
                    checked={allSelected}
                    className={classes.checkbox}
                  />
                }
                label={`${translations?.all ?? "All"} (${itemValues?.length})`}
              />
              {items?.map((item, i) => (
                <FormControlLabel
                  key={i}
                  onChange={handleChange}
                  name={`${item?.value ?? item}`}
                  className={`${classes.listItem} ${
                    !!value?.includes(`${item?.value ?? item}`) &&
                    classes.activeRow
                  }`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={!!value?.includes(`${item?.value ?? item}`)}
                      className={classes.checkbox}
                    />
                  }
                  label={`${item?.label ?? item}`}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
      {loading && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (items?.length ?? 0) < 1 && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <TabUnselected fontSize="large" color="disabled" />
        </Box>
      )}
    </Box>
  );
}
