import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "@melp-design/style";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 500,
      padding: 10,
    },
    title: {},
    icon: {
      color: Colors.yellow,
      verticalAlign: "bottom",
      marginRight: 10,
    },
  })
);
