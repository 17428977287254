import { Box } from "@material-ui/core";
import { MpTable } from "@mp-react/table";
import { useParams } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import { useEmployeeInnerTermsAcceptanceType } from "../../../../../configs/Tables/EmployeeInnerTermsAcceptance";
import { rowMethods } from "../../../../../utils/TableMethods";
import useTableUtils from "../../../../../utils/Table";
import { useEmployeeTermsAcceptance } from "../../../../../state/InnerEmployeeTermsAcceptance";
import { Row, UseRowSelectRowProps } from "react-table";
import { useHistory } from "react-router";
import { useTermsAcceptanceNotifications } from "../../../../../utils/TermsAcceptanceNotifications";
import SendReminderDialog from "../../../../../components/dialogs/SendReminderDialog/SendReminderDialog";
import { ReminderRequest } from "../../../../../types/NotificationService";
import { useTermsAcceptanceAsyncMethods } from "../../../../../state/TermsAcceptance";

export default function TermsAcceptance() {
  const {
    overridables,
    overrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
  } = useTableUtils("innerEmployeeTermsAcceptance");
  const TermsAcceptanceTableType = useEmployeeInnerTermsAcceptanceType();
  const { id } = useParams() as any;
  const {
    parsedEmployeeTermsAcceptanceList,
    employeeTermsAcceptance,
    loading,
    parsedEmployeeTermsAcceptanceTotals,
    bulkMethods,
  } = useEmployeeTermsAcceptance(id, tableQueryParam);
  const history = useHistory();
  const {
    sendReminder,
    successEmployeeId,
    handleCloseDialog,
    openDialog,
    getIdsForRequest,
    sendReminderAgain,
    loadingNotifications,
  } = useTermsAcceptanceNotifications(id, undefined, "employee");
  const asyncGetMethods = useTermsAcceptanceAsyncMethods(id);

  const reminderDialogEmployees = useMemo(
    () => successEmployeeId ?? [],
    [successEmployeeId]
  );

  const bulkMethodsWithReminder = useMemo(
    () => ({ ...bulkMethods, sendReminder }),
    [bulkMethods, sendReminder]
  );

  const handleRowClick = useCallback(
    (current: (Row<{}> & UseRowSelectRowProps<{}>) | undefined) => {
      const { original } = current as any;
      history.push(
        `/benefits/view/${original.benefitPlanId}?tab=benefitsTermsAcceptance`,
        original.benefitPlanId
      );
    },
    [history]
  );

  const handleSubmit = useCallback(async () => {
    const employeeAndBenefitIds: ReminderRequest[] =
      getIdsForRequest(successEmployeeId);
    const result = await sendReminderAgain(employeeAndBenefitIds);
    if (result) handleCloseDialog();
  }, [
    getIdsForRequest,
    handleCloseDialog,
    sendReminderAgain,
    successEmployeeId,
  ]);

  return (
    <Box>
      <MpTable
        {...TermsAcceptanceTableType}
        data={parsedEmployeeTermsAcceptanceList}
        onGetData={handleGetData}
        bulkMethods={bulkMethodsWithReminder}
        overridables={overridables}
        totalsData={parsedEmployeeTermsAcceptanceTotals}
        totals={parsedEmployeeTermsAcceptanceList?.length > 0}
        stickyTotals={true}
        disablePagination={true}
        classes={overrideClasses}
        pageSize={employeeTermsAcceptance?.pageSize ?? 20}
        loading={loading}
        enableGlobalActions={true}
        rowMethods={rowMethods}
        translations={translations}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
        onRowClick={handleRowClick}
        asyncGetMethods={asyncGetMethods}
      />
      <SendReminderDialog
        open={openDialog}
        slug={"sendReminder"}
        onCancelClick={handleCloseDialog}
        onSubmit={handleSubmit}
        id="send-reminder-dialog"
        responseData={reminderDialogEmployees}
        showNames="benefit"
        loading={loadingNotifications}
      />
    </Box>
  );
}
