import {
  Button,
  DialogActions,
  DialogProps,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./AlertDialog.styles";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps extends DialogProps {
  onSubmit: () => void;
  id: string;
  title: string;
  subtitle?: string;
}

const AlertDialog = ({ onSubmit, title, id, subtitle, ...rest }: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={onSubmit}
      onEnterPress={onSubmit}
      {...rest}
    >
      <Typography variant="h5">{title}</Typography>
      {!!subtitle && <Typography color="textSecondary">{subtitle}</Typography>}
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          classes={{ root: classes.button }}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          {t("buttons.ok")}
        </Button>
      </DialogActions>
    </DialogExtended>
  );
};

export default AlertDialog;
