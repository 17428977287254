import { Box } from "@material-ui/core";
import { MpTable, MpBulkActionCallback } from "@mp-react/table";
import React, { useCallback, useMemo } from "react";
import { useEmployeeInnerChoicesTableType } from "../../../../../configs/Tables/EmployeeInnerChoicesTableType";
import useTableUtils from "../../../../../utils/Table";
import useEmployeeChoices, {
  useEmployeesChoicesAsyncMethods,
} from "../../../../../state/InnerEmployeeChoices";
import { useParams } from "react-router-dom";
import useNotificationService from "../../../../../state/NotificationService";
import { ReminderRequest } from "../../../../../types/NotificationService";
import SendReminderDialog from "../../../../../components/dialogs/SendReminderDialog/SendReminderDialog";
import { useChoicesNotifications } from "../../../../../utils/ChoicesNotifications";
import useStyles from "../../../../../styles/Table.styles";
import { useMe } from "../../../../../state/Administrators";

export default function EmployeeChoices() {
  const { canEditChoices } = useMe();
  const classes = useStyles();
  const { id } = useParams() as any;
  const {
    overridables,
    overrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
  } = useTableUtils("innerEmployeeChoices");
  const ChoicesTableType = useEmployeeInnerChoicesTableType();
  const {
    parsedEmployeeChoices,
    loading,
    employeeChoices,
    rowMethods,
    bulkMethods,
    parsedEmployeeChoicesTotals,
  } = useEmployeeChoices(id, tableQueryParam);
  const { sendInvitation } = useNotificationService();
  const {
    sendReminders,
    successEmployeeId,
    handleCloseDialog,
    openDialog,
    getIdsForRequest,
    sendReminderAgain,
    loadingNotifications,
  } = useChoicesNotifications(id);
  const asyncGetMethods = useEmployeesChoicesAsyncMethods(id);

  const reminderDialogEmployees = useMemo(
    () => successEmployeeId ?? [],
    [successEmployeeId]
  );

  const inviteAgain: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const employeeIds = selectedRows?.map((item) => {
        const { original } = item as any;
        return original.id;
      });
      sendInvitation(employeeIds as string[]);
    },
    [sendInvitation]
  );

  const bulkMethodsWithReminder = useMemo(
    () => ({ ...bulkMethods, inviteAgain, sendReminders }),
    [bulkMethods, sendReminders, inviteAgain]
  );

  const handleSubmit = useCallback(async () => {
    const employeeAndBenefitIds: ReminderRequest[] =
      getIdsForRequest(successEmployeeId);
    const result = await sendReminderAgain(employeeAndBenefitIds);
    if (result) handleCloseDialog();
  }, [
    getIdsForRequest,
    handleCloseDialog,
    sendReminderAgain,
    successEmployeeId,
  ]);

  return (
    <Box className={canEditChoices ? "" : classes.disableTable}>
      <MpTable
        {...ChoicesTableType}
        data={parsedEmployeeChoices}
        onGetData={handleGetData}
        bulkMethods={bulkMethodsWithReminder}
        overridables={overridables}
        totalsData={parsedEmployeeChoicesTotals}
        totals={parsedEmployeeChoices?.length > 0}
        stickyTotals={true}
        disablePagination={true}
        classes={overrideClasses}
        pageSize={employeeChoices?.pageSize}
        loading={loading}
        enableGlobalActions={true}
        rowMethods={rowMethods}
        translations={translations}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
        asyncGetMethods={asyncGetMethods}
      />
      <SendReminderDialog
        open={openDialog}
        slug={"sendReminder"}
        onCancelClick={handleCloseDialog}
        onSubmit={handleSubmit}
        id="send-reminder-dialog"
        responseData={reminderDialogEmployees}
        showNames="benefit"
        loading={loadingNotifications}
      />
    </Box>
  );
}
