import { Box, Button, Divider } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { MpTableHeadFilterProps, MpTableFilter } from "@mp-react/table";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import useStyles from "./HeadFilter.styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export default function MpTableHeadFilter({
  column,
  canSort,
  isSortedDesc,
  doSorting,
  doFiltering,
  value: filterValue,
  setValue: setFilterValue,
  overridables,
}: MpTableHeadFilterProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState<any | undefined>(filterValue);

  const hasSortTranslation = useMemo(() => t("sort.az") !== "sort.az", [t]);
  const A = useMemo(
    () => (hasSortTranslation ? t("sort.az")?.[0] : "A"),
    [hasSortTranslation, t]
  );
  const Z = useMemo(
    () => (hasSortTranslation ? t("sort.az")?.[1] : "Z"),
    [hasSortTranslation, t]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setFilterValue(value);
      if (Array.isArray(value) && value?.includes("all_selected"))
        doFiltering(null);
      else doFiltering(value);
    },
    [setFilterValue, value, doFiltering]
  );

  const filteredCount = useMemo(() => {
    const filteredValues = Array.isArray(value)
      ? value?.filter((val) => val !== "all_selected")
      : [];
    return filteredValues?.length > 0 ? `(${filteredValues.length})` : null;
  }, [value]);

  return (
    <Box width={"100%"}>
      <form onSubmit={handleSubmit}>
        <MpTableFilter
          column={column}
          value={value}
          setValue={setValue}
          doFiltering={doFiltering}
          overridables={overridables}
        />
        <Divider />
        <Box display="flex" justifyContent="center" height="100%">
          {canSort && (
            <Box
              width="50%"
              onClick={doSorting}
              className={clsx(classes.text, {
                [classes.textHighlighted]: isSortedDesc != null,
              })}
            >
              {isSortedDesc !== true ? (
                <>
                  {A} <ArrowRight className={classes.arrowIcon} /> {Z}
                </>
              ) : (
                <>
                  {Z} <ArrowRight className={classes.arrowIcon} /> {A}
                </>
              )}
            </Box>
          )}
          <Box flexGrow={1} width="50%">
            <Button
              color="primary"
              type="submit"
              fullWidth={true}
              className={classes.filterBtn}
            >
              {t("table.filter")}
              {filteredCount}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
