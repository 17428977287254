import React, { useState } from "react";
import FilterButton from "../../../../../components/filters/FilterButton";
import SelectFilter from "../../../../../components/filters/SelectFilter";
import CurrencyRangeFilter from "../../../../../components/filters/CurrencyRangeFilter";
import { MarketplaceItemsFilters } from "./Types";
import { MarketplaceItemsSort } from "../../../../../types/MarketplaceItems";
import { useTranslation } from "react-i18next";
import { useMarketplaceItemsFilterValues } from "../../../../../state/MarketplaceItems.clientAdmin";
import { useCountryCodes } from "../../../../../constants/Countries";
import { useBenefitTypes } from "../../../../../constants/Benefits";
import { useMarketplaceItemCategories } from "../../../../../constants/MarketplaceItems";
import { SortOrder } from "../../../../../components/filters/Types";

const extractSortValue = (
  name: string,
  sort?: MarketplaceItemsSort
): SortOrder | undefined => {
  if (!sort) {
    return undefined;
  }
  const startsWithMinus = sort.startsWith("-");
  const extractedName = startsWithMinus ? sort.slice(1) : sort;
  if (extractedName !== name) {
    return undefined;
  }
  return startsWithMinus ? "desc" : "asc";
};

interface Props {
  filters: MarketplaceItemsFilters;
  onFilterChange: <K extends keyof MarketplaceItemsFilters>(
    key: K,
    newValue: MarketplaceItemsFilters[K]
  ) => void;
  sort?: MarketplaceItemsSort;
  onSort?: (sort: MarketplaceItemsSort) => void;
  hideBenefitCategories?: boolean;
}

const ItemsFilters = ({ filters, onFilterChange, ...props }: Props) => {
  const { t } = useTranslation();

  const [supplierSearchText, setSupplierSearchText] = useState("");
  const supplierFilterValues = useMarketplaceItemsFilterValues(
    "supplier",
    supplierSearchText
  );

  const countries = useCountryCodes();
  const countriesOptions = countries.map(({ name, alpha3 }) => ({
    key: alpha3,
    label: name,
  }));

  const benefitCategories = useBenefitTypes();
  const benefitCategoriesOptions = benefitCategories.map(({ value, name }) => ({
    key: value,
    label: t(name),
  }));

  const itemCategories = useMarketplaceItemCategories();

  return (
    <>
      <FilterButton
        label={t("marketplace.filtersSupplier")}
        value={filters.supplier}
        onChange={(newValue) => onFilterChange("supplier", newValue)}
      >
        {({ value, applyFilter, clearFilter }) => (
          <SelectFilter
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            options={supplierFilterValues.values}
            loadingOptions={supplierFilterValues.loading}
            asyncSearch
            onSearch={setSupplierSearchText}
            searchMinLength={3}
          />
        )}
      </FilterButton>
      <FilterButton
        label={t("marketplace.filtersCountry")}
        value={filters.countries}
        onChange={(newValue) => onFilterChange("countries", newValue)}
      >
        {({ value, applyFilter, clearFilter }) => (
          <SelectFilter
            multiple
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            options={countriesOptions}
          />
        )}
      </FilterButton>
      {!props.hideBenefitCategories && (
        <FilterButton
          label={t("marketplace.filtersBenefitCategory")}
          value={filters.benefitPlanTypes}
          onChange={(newValue) => {
            onFilterChange("benefitPlanTypes", newValue);
          }}
        >
          {({ value, applyFilter, clearFilter }) => (
            <SelectFilter
              multiple
              value={value}
              onApplyFilter={applyFilter}
              onClearFilter={clearFilter}
              options={benefitCategoriesOptions}
            />
          )}
        </FilterButton>
      )}
      <FilterButton
        label={t("marketplace.filtersItemCategory")}
        value={filters.categories}
        onChange={(newValue) => {
          onFilterChange("categories", newValue);
        }}
      >
        {({ value, applyFilter, clearFilter }) => (
          <SelectFilter
            multiple
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            options={itemCategories}
          />
        )}
      </FilterButton>
      <FilterButton
        label={t("marketplace.filtersPriceRange")}
        value={filters.price}
        onChange={(newValue) => onFilterChange("price", newValue)}
      >
        {({ value, applyFilter, clearFilter }) => (
          <CurrencyRangeFilter
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            sortValue={extractSortValue("price", props.sort)}
            onSort={(order) =>
              props.onSort?.(order === "asc" ? "price" : "-price")
            }
          />
        )}
      </FilterButton>
    </>
  );
};

export default ItemsFilters;
