import { DefaultCurrency, SupportedLanguages } from "./Common";

export interface ClientItem {
  id: string;
  name: string;
  country: string;
  internalComment: string;
  status: "active" | "inactive";
  benefitPlanCount: number;
  employeeCount: number;
  flexBenefitChoiceCount: number;
  employeeGroupCount: number;
  newsCount: number;
}

export interface ClientResponse {
  data: ClientItem[];
  count: number;
  pageSize: number;
  footer: {};
}

export interface Client extends ClientItem {
  inactive: boolean;
}

export interface CreateClientRequest {
  companyName: string;
  companyCountry: string;
  companyCode: string;
  adminEmail: string;
  adminPhone: string;
  adminFirstName: string;
  adminLastName: string;
  internalComment: string;
}

export interface ClientGeneralSettingsResponse {
  id: string;
  name: string;
  companyCode: string;
  country: string;
  internalComment: string;
  defaultCurrency: DefaultCurrency;
  defaultLanguage: SupportedLanguages;
  creationDate: string;
  deactivationDate: string | null;
  supportedLanguages: SupportedLanguages[];
  status: "active" | "inactive";
}

export interface ClientAdministrator {
  id: string;
  fullName: string;
  email: string;
  phone: string;
  status: "active" | "inactive";
  statusCode: "ACTIVE" | "INACTIVE";
  parentCompanyId: string;
}

export interface ClientAdministratorsFooter {
  fullName: string;
  phone: string;
  email: string;
  status: string;
}

export interface ClientAdministratorsResponse {
  data: ClientAdministrator[];
  count: number;
  pageSize: number;
  footer: ClientAdministratorsFooter;
}

export interface ImportClientError {
  rowNumber: number;
  fieldName: string;
  message: string;
}

export interface ImportClientsResponse {
  totalEntryCount: number;
  importedCount: number;
  duplicateCount: number;
  failedCount: number;
  errors: ImportClientError[];
}

export enum ClientsFilterNames {
  COUNTRY = "country",
  NAME = "name",
}
