import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    popoverPaper: {
      marginTop: 13,
      padding: "10px 0px",
    },
    itemContainer: {
      padding: "14px 20px",
    },
    button: {
      width: "max-content",
    },
  })
);
