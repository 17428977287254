import { useMemo, useCallback } from "react";
import { Endpoints } from "../api/constants";
import useSWR, { mutate } from "swr";
import {
  UseBenefitsChoices,
  UpdateImplementationDate,
} from "../types/Benefits";
import { useLoading } from "../utils/Loading";
import { mutateData } from "../api/api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import { MpBulkActionCallback, MpBulkActionMethods } from "@mp-react/table";
import { Row } from "react-table";
import { useCurrency } from "../utils/useCurrency";
import { MpAsyncGetMethod } from "@mp-react/table";
import { BenefitChoisesFilterNames } from "../constants/Benefits";
import { MpAsyncGetMethodArguments } from "../types/Table";
import { AxiosResponse } from "axios";

export const useInnerBenefitsChoices = (
  id?: string,
  query?: string
): UseBenefitsChoices => {
  const { stopLoading, startLoading, loading } = useLoading();
  const { t } = useTranslation();
  const now = useMemo(() => moment().format(), []);
  const url = useMemo(() => {
    if (id) {
      return !!query
        ? `${Endpoints.benefitPlans}/${id}/benefitPlanChoices?${query}`
        : `${Endpoints.benefitPlans}/${id}/benefitPlanChoices`;
    }
    return null;
  }, [id, query]);
  const { data: benefitsChoices, error } = useSWR(url);
  const { getDefaultCurrencyFormat } = useCurrency();

  const apiLoading = useMemo(() => {
    return !benefitsChoices && !error;
  }, [error, benefitsChoices]);

  const mappedBenefitsChoicesTableData = useMemo(
    () =>
      benefitsChoices?.data?.map((data: any) => ({
        ...data,
        inactive: moment().diff(moment(data.votingEndDate)) > 0,
      })),
    [benefitsChoices]
  );

  const parsedBenefitChoicesTotals = useMemo(() => {
    const initialTotals = {
      flexImplementationDate: "",
      flexStatus: "",
      investment: "",
      employeeName: "",
    };
    if (!benefitsChoices?.footer) return initialTotals;
    const { employeeName, flexImplementationDate, flexStatus, investment } =
      benefitsChoices.footer;
    return {
      employeeName: t("totals.employee", { count: Number(employeeName) }),
      flexImplementationDate: t("totals.implemented", {
        count: Number(flexImplementationDate),
      }),
      flexStatus: t("totals.not_selected", {
        count: Number(flexStatus),
      }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
    };
  }, [benefitsChoices, t, getDefaultCurrencyFormat]);

  const updateImplementationDate = useCallback(
    (data: UpdateImplementationDate[]) => {
      startLoading();
      mutateData("patch", Endpoints.setImplementationDates, data)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.benefitPlans}/${id}/benefitPlanChoices`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, id]
  );

  const getImlementationValue = useCallback(
    (selectedRows: Row<{}>[], type: "date" | "null") => {
      const newValue = selectedRows
        ?.filter((item) => {
          const { original } = item as any;
          return original.flexStatus === "selected";
        })
        .map((item) => {
          const { original } = item as any;
          return {
            id: original.id as string,
            flexImplementationDate: type === "date" ? now : null,
          };
        });
      return newValue;
    },
    [now]
  );

  const done: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const newValue = getImlementationValue(selectedRows as Row<{}>[], "date");
      if (newValue?.length !== 0) {
        startLoading();
        mutateData("patch", Endpoints.setImplementationDates, newValue)
          .then(() => {
            toast(t("common.updated_succesfully"), { type: "success" });
            mutate(`${Endpoints.benefitPlans}/${id}/benefitPlanChoices`);
          })
          .finally(() => {
            stopLoading();
          });
      }
    },
    [startLoading, stopLoading, t, getImlementationValue, id]
  );

  const pending: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const newValue = getImlementationValue(selectedRows as Row<{}>[], "null");
      if (newValue?.length !== 0) {
        startLoading();
        mutateData("patch", Endpoints.setImplementationDates, newValue)
          .then(() => {
            toast(t("common.updated_succesfully"), { type: "success" });
            mutate(`${Endpoints.benefitPlans}/${id}/benefitPlanChoices`);
          })
          .finally(() => {
            stopLoading();
          });
      }
    },
    [startLoading, stopLoading, t, getImlementationValue, id]
  );

  const getStatusValue = useCallback(
    (
      selectedRows: Row<{}>[],
      type: "notSelected" | "selected" | "declined"
    ) => {
      const statusValue = selectedRows.map((item) => {
        const { original } = item as any;
        return {
          id: original.id as string,
          choiceStatus: type,
        };
      });
      return statusValue;
    },
    []
  );

  const statusSelected: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "selected"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.benefitPlans}/${id}/benefitPlanChoices`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue, id]
  );

  const statusDeclined: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "declined"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.benefitPlans}/${id}/benefitPlanChoices`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue, id]
  );

  const statusNotSelected: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const choicesValue = getStatusValue(
        selectedRows as Row<{}>[],
        "notSelected"
      );
      startLoading();
      mutateData("patch", `${Endpoints.setChoiceStatus}`, choicesValue)
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.benefitPlans}/${id}/benefitPlanChoices`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, getStatusValue, id]
  );

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      done,
      pending,
      statusSelected,
      statusDeclined,
      statusNotSelected,
    }),
    [done, pending, statusSelected, statusDeclined, statusNotSelected]
  );

  return {
    benefitsChoices,
    loading: loading || apiLoading,
    benefitsChoicesError: error,
    updateImplementationDate,
    mappedBenefitsChoicesTableData,
    bulkMethods,
    parsedBenefitChoicesTotals,
  };
};

export const useBenefitChoicesAsyncMethods = (
  benefitPlanId?: string
): Record<string, MpAsyncGetMethod> => {
  const baseUrl = useMemo(
    () =>
      !!benefitPlanId
        ? `${Endpoints.benefitPlans}/${benefitPlanId}/benefitPlanChoices/filterValues`
        : null,
    [benefitPlanId]
  );

  const getAsyncFilterItems = useCallback(
    (
      args: MpAsyncGetMethodArguments | undefined,
      filterName: BenefitChoisesFilterNames
    ) => {
      const lookupValue = args?.search;
      if ((lookupValue?.length ?? 0) < 3)
        return new Promise((res) => setTimeout(res, 1000, []));

      const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
      const apiUrl = `${baseUrl}/${filterName}${searchParam}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getFullNames = useCallback<MpAsyncGetMethod>(
    (args) =>
      getAsyncFilterItems(args, BenefitChoisesFilterNames.EMPLOYEE_NAME),
    [getAsyncFilterItems]
  );

  return {
    getFullNames,
  };
};
