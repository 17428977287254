import { TabsProps, Tabs as MUITabs } from "@material-ui/core";
import React from "react";

interface TabsPropsFixed extends Omit<TabsProps, "onChange"> {
  onChange?: (event: any, value: any) => void;
}

const Tabs = (props: TabsPropsFixed) => {
  return (
    <MUITabs
      orientation="vertical"
      TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
      {...props}
    />
  );
};

export default Tabs;
