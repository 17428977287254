import React, { useState } from "react";
import { SortOrder } from "./Types";
import { CurrencyInput } from "../inputs";
import { useTranslation } from "react-i18next";
import { Button, Box, Typography } from "@material-ui/core";
import { isDefined } from "../../utils/isDefined";
import SortButton from "./SortButton";
import { NumberRange } from "../../types/Common";

interface Props {
  value?: NumberRange;
  onApplyFilter: (value?: NumberRange) => void;
  onClearFilter: () => void;
  sortValue?: SortOrder;
  onSort?: (order: SortOrder) => void;
}

const CurrencyRangeFilter = ({
  value,
  onApplyFilter,
  onClearFilter,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const [localRangeValue, setLocalRangeValue] = useState(value);

  const handleApplyFilter = () => {
    if (!isDefined(localRangeValue?.from) && !isDefined(localRangeValue?.to)) {
      onApplyFilter(undefined);
    } else {
      onApplyFilter(localRangeValue);
    }
  };

  return (
    <Box display="flex" flexDirection="column" p={1} style={{ gap: "8px" }}>
      <CurrencyInput
        value={localRangeValue?.from}
        onChange={(newValue) => {
          setLocalRangeValue((currentValue) => ({
            ...currentValue,
            from: newValue,
          }));
        }}
        placeholder={t("table.from")}
        fullWidth={true}
        size="small"
      />
      <CurrencyInput
        value={localRangeValue?.to}
        onChange={(newValue) => {
          setLocalRangeValue((currentValue) => ({
            ...currentValue,
            to: newValue,
          }));
        }}
        placeholder={t("table.to")}
        fullWidth={true}
        size="small"
      />
      <Box display="flex" justifyContent="flex-end" style={{ gap: "8px" }}>
        {props.onSort && (
          <SortButton
            type="numeric"
            value={props.sortValue}
            onChange={props.onSort}
          />
        )}
        <Typography></Typography>
        {value && (
          <Button size="small" onClick={onClearFilter}>
            {t("table.clear")}
          </Button>
        )}
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!localRangeValue}
          onClick={handleApplyFilter}
        >
          {t("common.applyFilter")}
        </Button>
      </Box>
    </Box>
  );
};

export default CurrencyRangeFilter;
