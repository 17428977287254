import { Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  doneByName: string;
  employeeGroup: string;
}

export default function EmployeeGroupCreated(props: IProps) {
  const { doneByName, employeeGroup } = props;
  const { t } = useTranslation();

  return useMemo(() => {
    return (
      <>
        {t("log.employee_created_group")
          .split(" ")
          ?.map((text) => {
            switch (text) {
              case "{employee}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${doneByName} `}
                  </Typography>
                );
              case "{group}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${employeeGroup ?? ""} `}
                  </Typography>
                );
              default:
                return `${text} `;
            }
          })}
      </>
    );
  }, [doneByName, employeeGroup, t]);
}
