import { ThemeOptions } from "@material-ui/core";
import { ReactComponent as ArrowDropDownIcon } from "../../assets/icons/arrow-down.svg";
import {
  Colors,
  Shadows,
  BorderRadius,
  ShadowOpacities,
  getBorder,
} from "../../constants/Style";
import { Colors as MelpColors } from "@melp-design/style";
import { createTheme } from "@material-ui/core/styles";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
//eslint-disable-next-line
import type from "@material-ui/lab/themeAugmentation";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CustomType = {
  MuiPickersDay: {
    daySelected: {
      borderRadius: BorderRadius.MainRadius;
    };
    current: {
      background: Colors.LightPrimary;
      borderRadius: BorderRadius.MainRadius;
      color: Colors.TextPrimary;
    };
  };
  MuiPickersCalendarHeader: {
    transitionContainer: {
      "& .MuiTypography-body1": {
        fontWeight: 700;
        fontSize: 16;
        lineHeight: "25.2px";
      };
    };
  };
};

declare module "@material-ui/core/styles/overrides" {
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
  export interface ComponentNameToClassKey extends CustomType {}
}

export const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: Colors.Primary,
    borderRadius: "20px",
  },
};

export default createTheme({
  palette: {
    primary: {
      main: Colors.Primary,
      dark: Colors.Dark,
    },
    secondary: {
      main: Colors.Secondary,
    },
    error: {
      main: Colors.Error,
      contrastText: Colors.White,
    },
    warning: {
      main: Colors.Warning,
      contrastText: Colors.White,
    },
    success: {
      main: Colors.Success,
      contrastText: Colors.White,
    },
    info: {
      main: Colors.Primary,
      contrastText: Colors.White,
    },
    type: "light",
    background: {
      default: MelpColors.screenBackgroundGrey,
    },
    text: {
      primary: Colors.TextPrimary,
      secondary: Colors.TextSecondary,
    },
    divider: Colors.Divider,
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: 62,
      lineHeight: "70.4px",
    },
    h2: {
      fontWeight: 700,
      fontSize: 46,
      lineHeight: "52.8px",
    },
    h3: {
      fontWeight: 700,
      fontSize: 34,
      lineHeight: "39.6px",
    },
    h4: {
      fontWeight: 700,
      fontSize: 26,
      lineHeight: "35px",
    },
    h5: {
      fontWeight: 700,
      fontSize: 22,
      lineHeight: "33.6px",
    },
    h6: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: "28px",
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "25.2px",
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "22.4px",
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "25.2px",
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "22.4px",
    },
    button: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "none",
    },
    caption: {
      fontWeight: 700,
      fontSize: 11,
      lineHeight: "18.2px",
      letterSpacing: "1px",
    },
    overline: {
      fontWeight: 700,
      fontSize: 10,
      lineHeight: "16.8px",
    },
  },
  shape: {
    borderRadius: BorderRadius.MainRadius,
  },
  shadows: [
    "none",
    "none",
    `0px  1px  5px  0px rgba(0,0,0,${ShadowOpacities.Dark}),0px  2px  2px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 3px  1px -2px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  1px  8px  0px rgba(0,0,0,${ShadowOpacities.Dark}),0px  3px  4px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 3px  3px -2px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  2px  4px -1px rgba(0,0,0,${ShadowOpacities.Dark}),0px  4px  5px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 1px 10px  0px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  3px  5px -1px rgba(0,0,0,${ShadowOpacities.Dark}),0px  5px  8px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 1px 14px  0px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  3px  5px -1px rgba(0,0,0,${ShadowOpacities.Dark}),0px  6px 10px 0px rgba(0,0,0,${ShadowOpacities.Medium}),0px 1px 18px  0px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  4px  5px -2px rgba(0,0,0,${ShadowOpacities.Dark}),0px  7px 10px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 2px 16px  1px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  5px  5px -3px rgba(0,0,0,${ShadowOpacities.Dark}),0px  8px 10px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 3px 14px  2px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  5px  6px -3px rgba(0,0,0,${ShadowOpacities.Dark}),0px  9px 12px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 3px 16px  2px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  6px  6px -3px rgba(0,0,0,${ShadowOpacities.Dark}),0px 10px 14px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 4px 18px  3px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  6px  7px -4px rgba(0,0,0,${ShadowOpacities.Dark}),0px 11px 15px 1px rgba(0,0,0,${ShadowOpacities.Medium}),0px 4px 20px  3px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  7px  8px -4px rgba(0,0,0,${ShadowOpacities.Dark}),0px 12px 17px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 5px 22px  4px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  7px  8px -4px rgba(0,0,0,${ShadowOpacities.Dark}),0px 13px 19px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 5px 24px  4px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  7px  9px -4px rgba(0,0,0,${ShadowOpacities.Dark}),0px 14px 21px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 5px 26px  4px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  8px  9px -5px rgba(0,0,0,${ShadowOpacities.Dark}),0px 15px 22px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 6px 28px  5px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  8px 10px -5px rgba(0,0,0,${ShadowOpacities.Dark}),0px 16px 24px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 6px 30px  5px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  8px 11px -5px rgba(0,0,0,${ShadowOpacities.Dark}),0px 17px 26px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 6px 32px  5px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  9px 11px -5px rgba(0,0,0,${ShadowOpacities.Dark}),0px 18px 28px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 7px 34px  6px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px  9px 12px -6px rgba(0,0,0,${ShadowOpacities.Dark}),0px 19px 29px 2px rgba(0,0,0,${ShadowOpacities.Medium}),0px 7px 36px  6px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px 10px 13px -6px rgba(0,0,0,${ShadowOpacities.Dark}),0px 20px 31px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 8px 38px  7px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px 10px 13px -6px rgba(0,0,0,${ShadowOpacities.Dark}),0px 21px 33px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 8px 40px  7px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px 10px 14px -6px rgba(0,0,0,${ShadowOpacities.Dark}),0px 22px 35px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 8px 42px  7px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px 11px 14px -7px rgba(0,0,0,${ShadowOpacities.Dark}),0px 23px 36px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 9px 44px  8px rgba(0,0,0,${ShadowOpacities.Light})`,
    `0px 11px 15px -7px rgba(0,0,0,${ShadowOpacities.Dark}),0px 24px 38px 3px rgba(0,0,0,${ShadowOpacities.Medium}),0px 9px 46px  8px rgba(0,0,0,${ShadowOpacities.Light})`,
  ],
  overrides: {
    MuiMenuItem: {
      root: {
        padding: "16px 20px !important",
        overflow: "unset",
        fontSize: 14,
        "&:not(:last-child)::after": {
          content: "''",
          width: "calc(100% - 20px)",
          height: 1,
          background: "rgba(0, 0, 0, 0.1)",
          position: "absolute",
          bottom: 0,
          right: 0,
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: 30,
        "&:first-child": {
          paddingTop: 33,
        },
        "&:last-child": {
          paddingBottom: 40,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingTop: 13,
      },
    },
    MuiToolbar: {
      root: {
        background: Colors.DarkGrad,
      },
    },
    MuiDivider: {
      inset: {
        marginLeft: 20,
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "separate",
        borderSpacing: 0,

        "& > *:not(:first-child) > .MuiTableRow-root:last-child > .MuiTableCell-root":
          {
            borderBottom: getBorder(MelpColors.elementsGrey),
          },

        "& > *:last-child": {
          "& > .MuiTableRow-root:last-child": {
            "& > .MuiTableCell-root": {
              "&:first-child": {
                borderBottomLeftRadius: 20,
              },
              "&:last-child": {
                borderBottomRightRadius: 20,
              },
            },
          },
        },
      },
    },
    MuiTableHead: {},
    MuiTableBody: {
      root: {
        background: MelpColors.white,
      },
    },
    MuiTableRow: {
      root: {
        position: "relative",

        "&:last-child": {
          "&::after": {
            opacity: 0,
            bottom: 0, // to prevent the table from overflowing (Y axis)
          },
        },

        "&::before": {
          content: '""',
          display: "table-cell",
          position: "absolute",
          left: 5,
          right: 5,
          top: 6,
          bottom: 5,
          background: MelpColors.lightBackgroundGrey,
          borderRadius: 10,
          opacity: 0,
          transition: "opacity 300ms ease-in-out",
        },

        "&::after": {
          content: '""',
          display: "table-cell",
          position: "absolute",
          left: 20,
          right: 20,
          bottom: -1,
          height: 1,
          background: MelpColors.elementsGrey,
        },
      },

      hover: {
        "&:hover": {
          // force override default MUI value
          backgroundColor: "transparent !important",
          "&::before": {
            opacity: 1,
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 15,
        fontSize: 14,
        lineHeight: "1.4",
        color: MelpColors.black,
        position: "relative",

        borderBottom: "none",
        borderTop: "none",

        "&:first-child": {
          paddingLeft: 20,
        },

        "&:last-child": {
          paddingRight: 20,
        },
      },

      body: {
        color: MelpColors.black,

        "&:first-child": {
          borderLeft: getBorder(MelpColors.elementsGrey),
        },

        "&:last-child": {
          borderRight: getBorder(MelpColors.elementsGrey),
        },

        ".MuiTableRow-root:first-child > &": {
          borderTop: getBorder(MelpColors.elementsGrey),

          "&:first-child": {
            borderTopLeftRadius: 20,
          },
          "&:last-child": {
            borderTopRightRadius: 20,
          },
        },
      },

      head: {
        paddingBottom: 18,
        fontSize: 12,
        color: MelpColors.grey,
        background: "none",
        border: "none",
        overflow: "hidden",
      },

      footer: {
        fontWeight: 700,
        fontSize: 12,
        color: MelpColors.grey,

        "&:first-child": {
          borderLeft: getBorder(MelpColors.elementsGrey),
        },

        "&:last-child": {
          borderRight: getBorder(MelpColors.elementsGrey),
        },
      },
    },
    MuiTableFooter: {
      root: {
        backgroundColor: Colors.White,
      },
    },
    MuiTablePagination: {
      toolbar: {
        background: "none",
      },
    },
    MuiChip: {
      root: {
        height: "30px!important",
        borderRadius: 18,
      },
      label: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "20px",
        paddingTop: 5,
        paddingBottom: 6,
      },
      colorPrimary: {
        color: Colors.White,
        backgroundColor: Colors.Dark,
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
      elevation8: {
        boxShadow: Shadows.BulkAction,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        color: Colors.TextElement,
        height: 44,
        "&$disabled": {
          color: Colors.Default,
        },
      },
      contained: {
        padding: "11px 20px",
        boxShadow: "none",
        color: Colors.TextElement,
        backgroundColor: Colors.Default,
        "&$disabled": {
          color: Colors.TextElement,
          backgroundColor: Colors.Default,
          "& .MuiButton-iconSizeMedium svg path": {
            stroke: Colors.TextElement,
          },
        },
        "&$containedSecondary": {
          color: Colors.White,
        },
      },
      text: {
        padding: "11px 20px",
      },
      outlined: {
        padding: "10px 19px",
        backgroundColor: Colors.White,
        border: getBorder(Colors.BorderDefault),
        "&$disabled": {
          border: getBorder(Colors.BorderDisabledLight),
        },
      },
    },
    MuiIconButton: {
      root: {
        color: Colors.TextElement,
        "&$disabled": {
          color: Colors.Disable,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: Colors.Default,
        backgroundColor: "transparent",
        "&$disabled": {
          "&$colorPrimary": {
            color: Colors.Disable,
          },
          "&$colorSecondary": {
            color: Colors.Disable,
          },
        },
      },
      indeterminate: {
        color: Colors.Primary,
      },
    },
    MuiRadio: {
      root: {
        color: Colors.Default,
        borderRadius: "50%",
        width: 18,
        height: 18,
      },
    },
    MuiSwitch: {
      root: {
        width: 35,
        height: 21,
        padding: 0,
        display: "flex",
        margin: "1px 9px",
      },
      switchBase: {
        color: `${Colors.White} !important`,
        padding: 2,
        backgroundColor: "inherit",
        "&$checked": {
          transform: "translateX(14px)",
          "& + $track": {
            opacity: 1,
          },
        },
        "&$disabled + $track": {
          backgroundColor: `${Colors.Disable} !important`,
          opacity: 1,
        },
      },
      colorSecondary: {
        "&$disabled": {
          color: `${Colors.Default} !important`,
        },
      },
      thumb: {
        width: 17,
        height: 17,
        boxShadow: Shadows.ThumbShadow,
      },
      track: {
        borderRadius: 32.55,
        opacity: 1,
        backgroundColor: Colors.Default,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: Colors.BorderDefaultLight,
        "&:hover": {
          backgroundColor: Colors.BorderDefault,
        },
        "&$focused": {
          backgroundColor: Colors.BorderDefaultLight,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$disabled": {
          background: Colors.Inactive,
          overflow: "hidden",
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.BorderOutlinedInput,
          },
        },
      },
      notchedOutline: {
        borderColor: Colors.BorderOutlinedInput,
        borderWidth: "1px !important",
      },
      adornedEnd: {
        color: Colors.LightGray,
        "& .MuiIconButton-root": {
          marginRight: "-12px",
        },
      },
      inputMarginDense: {
        paddingTop: 13,
        paddingBottom: 14,
      },
      multiline: {
        "&$marginDense": {
          paddingTop: 12,
          paddingBottom: 18,
        },
      },
      input: {
        color: Colors.TextPrimary,
        padding: "18.5px 14px",
        "&::placeholder": {
          color: Colors.TextElement,
          opacity: 1,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "22.4px",
      },
    },
    MuiInputAdornment: {
      root: {
        "& .MuiSvgIcon-root": {
          fill: Colors.TextElement,
        },
      },
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiInputLabel: {
      root: {
        color: Colors.TextPrimary,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "22.4px",
      },
      filled: {
        color: Colors.TextSecondary,
        transform: "translate(12px, 17px) scale(1)",
        "&$shrink": {
          transform: "translate(12px, 6px) scale(0.75)",
        },
      },
      formControl: {
        color: Colors.TextElement,
        transform: "translate(0px, 20px) scale(1)",
      },
      outlined: {
        transform: "translate(14px, 17px) scale(1)",
        "&$shrink": {
          transform: "translate(14px, -9px) scale(0.75)",
        },
      },
      asterisk: {
        color: Colors.Error,
      },
    },
    MuiSelect: {
      selectMenu: {
        "&:focus": {
          backgroundColor: "unset",
          borderRadius: BorderRadius.MainRadius,
        },
      },
      iconOutlined: {
        color: Colors.TextElement,
        right: 10,
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
    MuiToggleButton: {
      root: {
        border: "none",
        height: 28,
        width: 50,
        color: Colors.LightGray,
      },
      label: {
        fontSize: 14,
        lineHeight: "22px",
      },
    },
    MuiToggleButtonGroup: {
      groupedHorizontal: {
        marginRight: 5,
        borderRadius: 30,
        "&:not(:last-child)": {
          borderTopRightRadius: 30,
          borderBottomRightRadius: 30,
        },
        "&:not(:first-child)": {
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
        },
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    MuiDialog: {
      root: {
        "& .MuiOutlinedInput-notchedOutline": {
          top: "-4px !important",
        },
      },
    },
    MuiPopover: {
      paper: scrollBarStyle,
    },
    MuiAutocomplete: {
      popupIndicator: {
        right: 10,
      },
      clearIndicator: {
        right: 24,
      },
      paper: {
        boxShadow: `0px 2px 5px rgba(0, 0, 0, 0.3)`,
      },
      tag: {
        "& > .MuiChip-label": {
          paddingTop: 0,
          paddingBottom: 0,
          fontWeight: "normal",
        },
      },
    },
  },
  props: {
    MuiSelect: {
      IconComponent: ArrowDropDownIcon,
    },
  },
}) as ThemeOptions;
