import { Box } from "@material-ui/core";
import React from "react";

export default function TermsAcceptanceHeader() {
  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      {/* <Typography>Terms Acceptance</Typography> */}
    </Box>
  );
}
