import React from "react";
import { makeStyles } from "@material-ui/core";
import { Colors } from "../../style/Colors";
import clsx from "clsx";

interface StyleParams {
  color: string;
  textColor: string;
}

const useStyles = makeStyles((theme) => ({
  root: (params: StyleParams) => ({
    padding: "5px 10px",
    border: `1px solid ${Colors.elementsGrey}`,
    borderRadius: 8,
    fontFamily: "Inter",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    color: params.textColor,
    backgroundColor: params.color,
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.short,
    }),
  }),
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.lightBackgroundGrey,
    },
  },
}));

interface Props {
  /**
   * Text to display
   */
  children: React.ReactNode;
  /**
   * Background color
   */
  color?: keyof typeof Colors;
  /**
   * Text color
   */
  textColor?: keyof typeof Colors;
  /**
   * Callback to execute when element is clicked
   */
  onClick?: () => void;
  /**
   * CSS class name
   */
  className?: string;
}

const Label = ({ onClick, ...props }: Props) => {
  const color = props.color ? Colors[props.color] : Colors.white;
  const textColor = props.textColor ? Colors[props.textColor] : Colors.black;
  const classes = useStyles({ color, textColor });
  return (
    <span
      className={clsx(
        classes.root,
        onClick && classes.clickable,
        props.className
      )}
      onClick={onClick}
      role={onClick && "button"}
    >
      {props.children}
    </span>
  );
};

export default Label;
