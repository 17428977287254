import { useDefaultHighlights } from "./../../constants/Table";
import { CustomRendererType } from "./../../types/Table";
import { useTableFilterTypes } from "./../../utils/Table";
import {
  MpColumn,
  // MpFilterType,
  MpTableType,
  // MpColumnFormat,
  MpActions,
} from "@mp-react/table";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Colors } from "../../constants/Style";
import { useMe } from "../../state/Administrators";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useAssignGroupsTableType = (): MpTableType => {
  const { canEditBenefits } = useMe();
  const { t } = useTranslation();
  const highlights = useDefaultHighlights();
  const { keyWithSuffix } = useLocalStorage();

  const {
    getFilterNameSearchSelectFilter,
    getSelectFilter,
    countRangeFilter,
    numberRangeFilter,
  } = useTableFilterTypes();

  const currentStatusFilter = useMemo(
    () =>
      getSelectFilter("currentStatus", [
        {
          label: t("status.status_off"),
          value: "off",
        },
        {
          label: t("status.status_flex"),
          value: "flex",
        },
        {
          label: t("status.status_on"),
          value: "on",
        },
      ]),
    [getSelectFilter, t]
  );

  const employeeGroupNameFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getEmployeeGroupName"),
    [getFilterNameSearchSelectFilter]
  );

  const columns: MpColumn[] = useMemo(
    () => [
      {
        label: t("groups.group_name"),
        key: "name",
        filter: employeeGroupNameFilter,
        width: 244,
        fixedHeadWidth: true,
      },
      {
        label: t("permissions.employeeModule"),
        key: "employeeCount",
        sort: false,
        filter: countRangeFilter,
        width: 136,
        fixedHeadWidth: true,
      },
      {
        label: t("common.investment"),
        key: "investment",
        sort: false,
        render: CustomRendererType.currency as any,
        width: 136,
        fixedHeadWidth: true,
        filter: numberRangeFilter,
      },
      {
        label: t("table.previous_status"),
        key: "statuses",
        sort: false,
        render: CustomRendererType.entries as any,
        width: 165,
        fixedHeadWidth: true,
      },
      {
        label: t("common.current_status"),
        key: "currentStatus",
        render: "statusToggle" as any,
        totals: false,
        action: {
          slug: "toggleStatus",
          items: [
            {
              label: t("status.off"),
              value: "off",
              color: Colors.Default,
              textColor: Colors.TextSecondary,
            },
            {
              label: t("status.flex"),
              value: "flex",
              color: Colors.StatusYellow,
              textColor: Colors.White,
            },
            {
              label: t("status.on"),
              value: "on",
              color: Colors.StatusGreen,
              textColor: Colors.White,
            },
          ],
        },
        filter: currentStatusFilter,
        sort: false,
        width: 223,
        fixedHeadWidth: true,
      },
    ],
    [
      countRangeFilter,
      currentStatusFilter,
      employeeGroupNameFilter,
      numberRangeFilter,
      t,
    ]
  );
  const bulkActions: MpActions = useMemo(
    () => [
      {
        slug: "statusOn",
        label: t("actions.mark_selected_status_as_on"),
      },
      {
        slug: "statusFlex",
        label: t("actions.mark_selected_status_as_flex"),
      },
      {
        slug: "statusOff",
        label: t("actions.mark_selected_status_as_off"),
      },
    ],
    [t]
  );

  return {
    columns,
    rowIdKey: "id",
    selection: canEditBenefits ? "multiple" : undefined,
    filterVariant: "head",
    disablePagination: true,
    bulkActions: bulkActions,
    totals: true,
    stickyTotals: true,
    enableGlobalActions: true,
    highlights: highlights,
    storageIdentifier: keyWithSuffix(StorageKeys.assignGroupsTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
