import { Container } from "@material-ui/core";
import React, { PropsWithChildren, ReactChild } from "react";
import Header from "../../header/Header/Header";
import useStyles from "./Base.styles";

export default function Base({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
  return (
    <div>
      <Header />
      <Container
        maxWidth="lg"
        disableGutters
        classes={{ root: classes.container, maxWidthLg: classes.maxWidthLg }}
      >
        {children as ReactChild}
      </Container>
    </div>
  );
}
