import {
  List,
  ListItem,
  MenuList,
  MenuItem,
  Typography,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { useLinks } from "../../../constants/Links";
import useStyles from "./MenuMap.styles";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { useMe } from "../../../state/Administrators";
import { useMemo } from "react";
import { LinkToObject, LinkItem } from "../../../types/Common";
import clsx from "clsx";
import { Permission } from "../../../types/Administrators";
import { PermissionOptions } from "../../../constants/Administrators";

export default function MenuMap() {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    me,
    getPermissionsByModule,
    isRoot,
    hasNoCompanySettingsAccess,
    isMelpAdmin,
  } = useMe();
  const links: LinkItem[] = useLinks(hasNoCompanySettingsAccess);
  const [openId, setOpenId] = useState<string | null>(null);

  const currentRole = useMemo(() => me?.role, [me?.role]);

  const checkIfActive = useCallback(
    (to: string | LinkToObject) => {
      if (typeof to === "string") {
        const trimLink = to.split("/")[1];
        return location.pathname.includes(trimLink);
      }

      return true;
    },
    [location.pathname]
  );

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
      event.stopPropagation();
      setOpenId(id);
    },
    [setOpenId]
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      event.stopPropagation();
      setOpenId(null);
    },
    [setOpenId]
  );

  const handleListKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Tab") {
        event.preventDefault();
        setOpenId(null);
      }
    },
    [setOpenId]
  );

  const checkIfPermissionsAllowed = useCallback(
    (permissions: Permission[], matchAll?: boolean) => {
      if (matchAll) {
        const noPermissions = permissions.some(
          (permission: Permission) =>
            permission.permission === PermissionOptions.None
        );
        return !noPermissions;
      }
      return permissions.some(
        (permission: Permission) =>
          permission.permission === "view" || permission.permission === "edit"
      );
    },
    []
  );

  const linksMap = useMemo(() => {
    if (!me) return null;
    if (!isRoot && !me.permissions?.length && !isMelpAdmin) return null;

    const linksWithCorrectRole = links.filter(
      (link: LinkItem) => !!currentRole && link.roles.includes(currentRole)
    );

    return linksWithCorrectRole.map((link, index) => {
      const hasCorrectRole = !!currentRole && link.roles.includes(currentRole);

      const permissions = !!link?.module
        ? getPermissionsByModule(link.module)
        : [];

      const permissionsAllowed = checkIfPermissionsAllowed(
        permissions,
        link.matchAllModules
      );

      if (!isRoot)
        if (!hasCorrectRole || (!!link?.module && !permissionsAllowed))
          return null;

      return (
        <ListItem
          key={`${link}-${index}`}
          button={true}
          aria-describedby={link.title}
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (link.title === openId) {
              handleClose(e);
              return;
            }
            handleOpen(e, link.title);
          }}
          aria-controls={openId === link.title ? link.title : undefined}
          aria-haspopup="true"
        >
          {!link.links ? (
            <NavLink
              to={link.to}
              activeClassName={classes.active}
              className={classes.linkItem}
              isActive={(match, location) => {
                if (typeof link.to === "string") {
                  const trimLink = link.to.split("/")[1];
                  return location.pathname.includes(trimLink);
                }

                return true;
              }}
            >
              <Typography variant="subtitle1" color="inherit">
                {t(link.title)}
              </Typography>
            </NavLink>
          ) : (
            <Box
              className={clsx(classes.linkItem, {
                [classes.active]: checkIfActive(link.to),
              })}
            >
              <Typography variant="subtitle1" color="inherit">
                {t(link.title)}
              </Typography>
              <ChevronDown className={classes.buttonIcon} />
            </Box>
          )}

          {!!link.links && (
            <ClickAwayListener onClickAway={handleClose}>
              <Grow in={openId === link.title}>
                <Paper classes={{ root: classes.menuRoot }}>
                  <MenuList
                    id={link.title}
                    onKeyDown={handleListKeyDown}
                    disablePadding={true}
                  >
                    {link.links?.map((sublink, index) => {
                      const hasCorrectRole =
                        !!currentRole && sublink.roles.includes(currentRole);

                      const permissions = !!sublink?.module
                        ? getPermissionsByModule(sublink.module)
                        : [];

                      const permissionsAllowed = checkIfPermissionsAllowed(
                        permissions,
                        sublink.matchAllModules
                      );

                      if (!isRoot) {
                        if (
                          !hasCorrectRole ||
                          (!!sublink?.module && !permissionsAllowed)
                        )
                          return null;
                      }

                      return (
                        <MenuItem
                          button={true}
                          onClick={handleClose}
                          className={classes.listItem}
                          key={`${sublink}-${index}`}
                          component={NavLink}
                          to={sublink.to}
                          activeClassName={classes.activeChild}
                        >
                          <Typography color="textSecondary" variant="body2">
                            {t(sublink.title)}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          )}
        </ListItem>
      );
    });
  }, [
    me,
    isRoot,
    isMelpAdmin,
    links,
    currentRole,
    getPermissionsByModule,
    checkIfPermissionsAllowed,
    openId,
    classes.active,
    classes.linkItem,
    classes.buttonIcon,
    classes.menuRoot,
    classes.listItem,
    classes.activeChild,
    t,
    checkIfActive,
    handleClose,
    handleListKeyDown,
    handleOpen,
  ]);

  return (
    <List classes={{ root: classes.listRoot }} disablePadding={true}>
      {linksMap}
    </List>
  );
}
