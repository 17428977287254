import { Box, BoxProps, Grid, GridProps } from "@material-ui/core";
import React from "react";

const SidebarContent = ({ children, ...rest }: BoxProps & GridProps) => {
  return (
    <Grid component={Box} maxWidth={960} flex={1} item {...rest}>
      {children}
    </Grid>
  );
};

export default SidebarContent;
