import { Typography } from "@material-ui/core";
import { useRendererOptions } from "@mp-react/table";
import React from "react";
import { CellProps } from "react-table";
import { useDateFormat } from "../../../utils/Common";

export default function ShortMonthDate(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const { shortMonthDate } = useDateFormat(value);

  return <Typography variant="body2">{shortMonthDate}</Typography>;
}
