import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      position: "absolute",
      height: "calc(100% + 28px)",
      width: "calc(100% + 40px)",
      top: "-14px",
      left: "-21px",
    },
    wrapper: {
      borderRadius: "8px",
      height: "100%",
    },
  })
);
