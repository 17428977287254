import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { browserLanguage } from "../../../../../utils/Common";
import { useCurrency } from "../../../../../utils/useCurrency";
import { replaceByElement } from "../../../../../utils/Logs";
import { Log } from "../../../../../types/Logs";

export default function EmployeeFlexLimitAllocationModified(
  props: Partial<Log>
) {
  const { employee, employeeId, allocatedAmount, allocationStartDate } = props;
  const { t } = useTranslation();
  const { currency } = useCurrency();

  const parsedText = useMemo(
    () =>
      t("log.flex_allocation_has_been_changed")
        ?.replace("{amount}", `${allocatedAmount ?? 0}${currency}`)
        ?.replace(
          "{date}",
          moment(allocationStartDate).locale(browserLanguage).format("L")
        ),
    [allocatedAmount, allocationStartDate, t, currency]
  );

  const employeeName = useMemo(
    () => (employee ? `${employee} ` : `${employeeId} `),
    [employee, employeeId]
  );

  return useMemo(
    () => replaceByElement(parsedText, "{employee}", employeeName),
    [employeeName, parsedText]
  );
}
