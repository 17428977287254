import React from "react";
import FormDialog from "../dialogs/FormDialog/FormDialog";
import { useEmployeesBenefitsForExpenses } from "../../state/BenefitExpenses";
import { MpFormType, MpControlType } from "@mp-react/form";
import { useTranslation } from "react-i18next";
import { BenefitExpenseFormData } from "../../types/BenefitExpenses";
import { CircularProgress } from "@material-ui/core";

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: (data: BenefitExpenseFormData) => void;
  loading: boolean;
  title: string;
  employeeIds: string[];
  initialData?: Partial<BenefitExpenseFormData>;
}

const ExpenseDialog = ({
  employeeIds,
  open,
  onCancel,
  onSubmit,
  loading,
  title,
  initialData,
}: Props) => {
  const { t } = useTranslation();
  const { benefits } = useEmployeesBenefitsForExpenses(employeeIds);

  const addBenefitTemplateForm: MpFormType = {
    controls: [
      {
        key: "benefitPlanId",
        type: "dropdown" as MpControlType,
        children: benefits?.map((benefit) => ({
          type: "select" as MpControlType,
          key: benefit.id,
          label: benefit.name,
        })),
        label: t("benefitExpenses.benefitTitle"),
        messages: {
          required: t("form.required"),
        },
        required: true,
      },
      {
        key: "expense",
        type: "currency" as MpControlType,
        label: t("benefitExpenses.expenseAmount"),
        messages: {
          required: t("form.required"),
        },
        required: true,
      },
      {
        key: "description",
        type: MpControlType.text,
        label: t("benefitExpenses.expenseDescription"),
        messages: {
          required: t("form.required"),
        },
        required: true,
      },
    ],
    size: "small",
    layout: "separated",
    variant: "outlined",
  };

  const actionLabel = loading ? (
    <CircularProgress size={20} />
  ) : (
    t("benefitExpenses.confirmExpense")
  );

  return (
    <FormDialog
      onCancelClick={onCancel}
      onSubmitForm={onSubmit}
      id="benefit-expense-dialog"
      open={open}
      title={title}
      actionLabel={actionLabel}
      form={addBenefitTemplateForm}
      defaultValues={initialData}
      loading={loading}
    />
  );
};

export default ExpenseDialog;
