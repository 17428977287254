import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    inactive: {
      color: Colors.LightGray,
    },
    formControlLabel: {
      paddingTop: "8px",
    },
  })
);
