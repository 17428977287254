import useSWR from "swr";
import { Endpoints } from "../api/constants";
import {
  BenefitTemplate,
  BenefitTemplatesFilters,
} from "../types/BenefitTemplates";
import { convertToFilterQueryParams } from "../utils/Filters";

export const useBenefitTemplates = (filters: BenefitTemplatesFilters) => {
  const query = convertToFilterQueryParams(filters);
  const { data, error } = useSWR<BenefitTemplate[]>(
    `${Endpoints.clientAdmin.benefitPlanTemplates.root}${query}`
  );
  return { data, error };
};

// export const useBenefitTemplatesAsyncMethods = () => {
//   const baseUrl = Endpoints.benefitTemplatesFilterValues;

//   const getAsyncFilterItems = useCallback(
//     (
//       args: MpAsyncGetMethodArguments | undefined,
//       filterName: BenefitTemplateFilterNames
//     ) => {
//       const lookupValue = args?.search;
//       if ((lookupValue?.length ?? 0) < 3)
//         return new Promise((res) => setTimeout(res, 1000, []));

//       const searchParam = lookupValue ? `?lookupValue=${lookupValue}` : "";
//       const apiUrl = `${baseUrl}/${filterName}${searchParam}`;
//       return mutateData("get", apiUrl).then(
//         (res: AxiosResponse<string[]>) => res.data
//       );
//     },
//     [baseUrl]
//   );

//   const getFilterItems = useCallback(
//     (filterName: BenefitTemplateFilterNames) => {
//       const apiUrl = `${baseUrl}/${filterName}`;
//       return mutateData("get", apiUrl).then(
//         (res: AxiosResponse<string[]>) => res.data
//       );
//     },
//     [baseUrl]
//   );

//   const getBenefitTemplatesNames = useCallback<MpAsyncGetMethod>(
//     (args) => {
//       return getAsyncFilterItems(args, BenefitTemplateFilterNames.name);
//     },
//     [getAsyncFilterItems]
//   );

//   const getBenefitCategories = useCallback<MpAsyncGetMethod>(
//     () => getFilterItems(BenefitTemplateFilterNames.type),
//     [getFilterItems]
//   );

//   return {
//     getBenefitTemplatesNames,
//     getBenefitCategories,
//   };
// };
