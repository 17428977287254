import { getCalculatedInvestmentPercent } from "./../utils/InvestmentCalculations";
import { CheckAssignBaseResponse, Investment } from "./../types/Common";
import create from "zustand";

type InvestmentCalculationsStore = {
  changeCount: number;
  changedRowIds: string[];
  increaseChangeCount: (rowId: string | number | undefined) => void;
  removeChange: (rowId: string) => void;
  statuses: any;
  setStatus: (obj: any, columnId?: any) => void;
  resetStatusChanges: () => void;
  resetAllChanges: () => void;
  totalInvestment: number;
  totalInvestmentChange: number;
  calculatedInvestment: number;
  calculatedInvestmentPercentage: string;
  investments: Investment[];
  setTotalInvestment: (totalInvestment: number) => void;
  setTotalInvestmentChange: (investmentChange: number) => void;
  setCalculatedInvestment: () => void;
  setCalculatedInvestmentPercentage: () => void;
  setInvestments: (investment: CheckAssignBaseResponse & Investment) => void;
  resetInvestments: () => void;
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
};

export const useInvestmentCalculationsStore =
  create<InvestmentCalculationsStore>((set) => ({
    loading: false,
    changeCount: 0,
    changedRowIds: [],

    startLoading: () => set((state) => ({ loading: true })),
    stopLoading: () => set((state) => ({ loading: false })),

    increaseChangeCount: (rowId: string | number | undefined) => {
      if (!rowId) return;
      set((state) => {
        const idExists = state.changedRowIds.includes(rowId as string);
        if (idExists) return { ...state };
        const newChangedRowIds = [...state.changedRowIds, rowId];
        const newChangeCount = newChangedRowIds.length;
        return {
          changeCount: newChangeCount,
          changedRowIds: newChangedRowIds as string[],
        };
      });
    },

    removeChange: (rowId: string) => {
      if (!rowId) return;
      set((state) => {
        const {
          changedRowIds,
          investments,
          setTotalInvestmentChange,
          setCalculatedInvestment,
          setCalculatedInvestmentPercentage,
        } = state;

        const filteredRowIds = changedRowIds.filter(
          (changedId) => changedId !== rowId
        );
        const filteredInvestments = investments.filter(
          (investment) => investment.id !== rowId
        );
        const changedInvestment = investments.find(
          (investment) => investment.id === rowId
        );
        const newChangeRowIds = [...filteredRowIds];
        const newChangeCount = filteredRowIds.length;
        const newInvestments = [...filteredInvestments];

        setTotalInvestmentChange(
          !!changedInvestment?.investment
            ? changedInvestment.investment * -1
            : 0
        );
        setCalculatedInvestment();
        setCalculatedInvestmentPercentage();

        return {
          changeCount: newChangeCount,
          changedRowIds: newChangeRowIds,
          investments: newInvestments,
        };
      });
    },

    resetAllChanges: () =>
      set(() => {
        return {
          changedRowIds: [],
          changeCount: 0,
        };
      }),

    statuses: {},

    resetStatusChanges: () => set(() => ({ statuses: {} })),

    setStatus: (newValue: any, columnId?: any) => {
      if (newValue === null) {
        set((state) => {
          if (!!columnId) {
            const statusClone = state.statuses;
            if (!!statusClone?.["currentStatus"]?.[columnId])
              delete statusClone["currentStatus"][columnId];
            return { statuses: { ...statusClone } };
          }
          return { statuses: {} };
        });
      } else {
        const columnId = Object.keys(newValue)?.[0];
        if (!!columnId)
          set((state) => ({
            statuses: !!state?.statuses?.[columnId]
              ? {
                  [columnId]: {
                    ...state.statuses[columnId],
                    ...newValue[columnId],
                  },
                }
              : {
                  ...state.statuses,
                  ...newValue,
                },
          }));
      }
    },

    totalInvestment: 0,
    totalInvestmentChange: 0,
    calculatedInvestment: 0,
    calculatedInvestmentPercentage: "",
    investments: [],

    setTotalInvestment: (totalInvestment: number) =>
      set(() => ({
        totalInvestment,
      })),

    setTotalInvestmentChange: (investmentChange: number) =>
      set(({ totalInvestmentChange }) => ({
        totalInvestmentChange: totalInvestmentChange + investmentChange,
      })),

    setCalculatedInvestment: () =>
      set((state) => {
        const { totalInvestment, totalInvestmentChange } = state;
        const calculatedInvestment = totalInvestment + totalInvestmentChange;
        return { calculatedInvestment };
      }),

    setCalculatedInvestmentPercentage: () =>
      set((state) => {
        const { totalInvestment, totalInvestmentChange } = state;
        const calculatedPercentage =
          (totalInvestmentChange / totalInvestment) * 100;
        const ceiledPercentage = Math.ceil(calculatedPercentage);
        return {
          calculatedInvestmentPercentage: `${
            !!ceiledPercentage ? ceiledPercentage : 0
          }%`,
        };
      }),

    setInvestments: (investment: CheckAssignBaseResponse & Investment) => {
      const { investmentAmountChange, totalInvestmentAmount, ...rest } =
        investment;
      set((state) => {
        const { investments } = state;

        let filteredInvestments = investments;
        if (
          !!filteredInvestments.find((investment) => investment.id === rest.id)
        )
          filteredInvestments = filteredInvestments.filter(
            (investment) => investment.id !== rest.id
          );

        const totalInvestmentChangeCalculated =
          filteredInvestments.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.investment;
          }, 0) + investmentAmountChange;

        const percentageCalculated = getCalculatedInvestmentPercent(
          totalInvestmentAmount,
          totalInvestmentChangeCalculated
        );

        return {
          totalInvestment: totalInvestmentAmount,
          totalInvestmentChange: totalInvestmentChangeCalculated,
          calculatedInvestment:
            totalInvestmentAmount + totalInvestmentChangeCalculated,
          investments: [...filteredInvestments, { ...rest }],
          calculatedInvestmentPercentage: percentageCalculated,
        } as any;
      });
    },

    resetInvestments: () =>
      set(() => ({
        totalInvestment: 0,
        totalInvestmentChange: 0,
        calculatedInvestment: 0,
        calculatedInvestmentPercentage: "",
        investments: [],
      })),
  }));
