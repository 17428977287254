import { useLoading } from "../utils/Loading";
import { useTranslation } from "react-i18next";
import { Endpoints } from "../api/constants";
import useSWR, { mutate } from "swr";
import { useCallback, useMemo } from "react";
import {
  UseEmployeeGroups,
  UseEmployeeGroupStore,
} from "./../types/EmployeeGroups";
import { mutateData } from "../api/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { browserLanguage } from "../utils/Common";
import { useCurrency } from "../utils/useCurrency";
import create from "zustand";
import { useMe } from "./Administrators";
import { MpAsyncGetMethod } from "@mp-react/table";
import { AxiosResponse } from "axios";
import { GroupsFilterNames } from "../constants/Groups";
import moment from "moment";
import fileDownloader from "js-file-download";

const useEmployeeGroups = (
  groupId?: string,
  list?: boolean,
  query?: string
): UseEmployeeGroups => {
  const { hasGroupPermissions } = useMe();
  const history = useHistory();
  const { t } = useTranslation();
  const { data: employeeGroupsList, error: employeeGroupsListError } = useSWR(
    !groupId && list && hasGroupPermissions
      ? Endpoints.employeeGroupsList
      : null
  );
  const { data: employeeGroups, error: employeeGroupsError } = useSWR(
    !groupId && !list && hasGroupPermissions
      ? `${Endpoints.employeeGroup}${!!query ? `?${query}` : ""}`
      : null
  );
  const { stopLoading, startLoading, loading } = useLoading();
  const { getDefaultCurrencyFormat } = useCurrency();

  const apiLoading = useMemo(() => {
    if (list) return !employeeGroupsList && !employeeGroupsListError;
    return !employeeGroups && !employeeGroupsError;
  }, [
    employeeGroups,
    employeeGroupsError,
    employeeGroupsList,
    employeeGroupsListError,
    list,
  ]);

  const parsedEmployeeGroups = useMemo(
    () =>
      employeeGroups?.data?.map((data: any) => ({
        ...data,
        benefitsCount: data.benefitPlanIds?.length,
        inactive: Number(data.employeeCount) === 0,
        benefitTypes: data.benefitPlans,
      })),
    [employeeGroups?.data]
  );

  const parsedEmployeeGroupsTotals = useMemo(() => {
    const initialTotals = {
      name: "",
      investment: "",
    };
    if (!employeeGroups?.footer) return initialTotals;
    const { name, investment } = employeeGroups.footer;
    return {
      name: t("totals.employee_group", {
        count: Number(name),
      }),
      investment: `${getDefaultCurrencyFormat(Math.round(Number(investment)))}`,
    };
  }, [employeeGroups, t, getDefaultCurrencyFormat]);

  const createEmployeeGroup = useCallback(
    (name: string) => {
      startLoading();
      mutateData("post", Endpoints.employeeGroup, {
        name: name,
      })
        .then((res: any) => {
          toast(t("common.added_succesfully"), { type: "success" });
          mutate(Endpoints.employeeGroup);
          history.push(`/groups/view/${res?.data?.id}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, history]
  );

  const addEmployeeGroups = useCallback(
    async (
      employeeGroupId: string,
      employeeIds: string[],
      mutateUrl?: string
    ) => {
      startLoading();
      return await mutateData(
        "patch",
        `${Endpoints.employeeGroup}/${employeeGroupId}/assignEmployee`,
        {
          assignToGroup: employeeIds,
        }
      )
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(Endpoints.employee);
          return true;
        })
        .catch(() => false)
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t]
  );

  const addRemoveGroupstoEmployee = useCallback(
    async (
      employeeGroupId: string,
      employeeIds: string,
      type: "assignToGroup" | "removeFromGroup"
    ) => {
      await mutateData(
        "patch",
        `${Endpoints.employeeGroup}/${employeeGroupId}/assignEmployee`,
        {
          [type]: [employeeIds],
        }
      );
    },
    []
  );

  const deleteEmployeeGroup = useCallback(
    (employeeGroupId: string) => {
      startLoading();
      mutateData("delete", `${Endpoints.employeeGroup}/${employeeGroupId}`)
        .then(() => {
          toast(t("common.deleted_succesfully"), { type: "success" });
          mutate(Endpoints.employeeGroup);
          history.push(`/groups`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, startLoading, stopLoading, t]
  );

  const updateEmployeeGroup = useCallback(
    async (employeeGroupId: string, name: string) => {
      startLoading();
      const isSuccess = await mutateData(
        "patch",
        `${Endpoints.employeeGroup}/${employeeGroupId}`,
        {
          name: name,
        }
      )
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.employeeGroup}/${employeeGroupId}`);
          mutate(Endpoints.employeeGroup);
          return true;
        })
        .catch(() => {
          return false;
        })
        .finally(() => {
          stopLoading();
        });
      return isSuccess;
    },
    [startLoading, stopLoading, t]
  );

  const removeEmployeeFromGroups = useCallback(
    async (employeeGroupId: string, employeeIds: string[]) => {
      return await mutateData(
        "patch",
        `${Endpoints.employeeGroup}/${employeeGroupId}/assignEmployee`,
        {
          removeFromGroup: employeeIds,
        }
      )
        .then((res) => true)
        .catch(() => false);
    },
    []
  );

  const exportToExcel = useCallback(() => {
    startLoading();
    const url = `${Endpoints.employeeGroupsExportToExcel}${
      !!query ? `?${query}` : ""
    }`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    };
    return mutateData("get", url, null, headers, "arraybuffer")
      .then((res: any) => {
        const now = moment().locale(browserLanguage).format("L");
        const disposition = res?.headers["content-disposition"];
        const headersFilename = disposition?.split("filename=")?.[1];
        const filename = headersFilename ?? `Groups_${now}.xlsx`;
        fileDownloader(res.data, filename);
      })
      .finally(() => {
        stopLoading();
      });
  }, [query, startLoading, stopLoading]);

  const copyEmployeeGroup = useCallback(
    (id: string, name: string) => {
      startLoading();
      mutateData("post", Endpoints.employeeGroupsCopy.replace("{id}", id), {
        name: name,
      })
        .then((res) => {
          toast(t("common.added_succesfully"), { type: "success" });
          mutate(Endpoints.employeeGroup);
          history.push(`/groups/view/${res?.data?.id}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, startLoading, stopLoading, t]
  );

  return {
    loading: loading || apiLoading,
    employeeGroupsList,
    employeeGroupsListError,
    addEmployeeGroups,
    parsedEmployeeGroups,
    employeeGroupsError,
    createEmployeeGroup,
    employeeGroups,
    removeEmployeeFromGroups,
    deleteEmployeeGroup,
    parsedEmployeeGroupsTotals,
    updateEmployeeGroup,
    addRemoveGroupstoEmployee,
    exportToExcel,
    copyEmployeeGroup,
  };
};

export default useEmployeeGroups;

// TODO: remove useEmployeeGroupStore if InnerEmployeeAssignBenefits > EmployeeGroup is not needed anymore
export const useEmployeeGroupStore = create<UseEmployeeGroupStore>((set) => ({
  assignEmployeeGroupIds: [],
  setAssignEmployeeGroupIds: (obj: string[]) =>
    set(() => ({ assignEmployeeGroupIds: obj })),
  removeEmployeeGroupIds: [],
  setRemoveEmployeeGroupIds: (obj: string[]) =>
    set(() => ({ removeEmployeeGroupIds: obj })),
  reset: false,
  setReset: (obj: boolean) => {
    set(() => ({ reset: obj }));
  },
}));

export const useEmployeeGroupsAsyncMethods = (): Record<
  string,
  MpAsyncGetMethod
> => {
  const baseUrl = useMemo(() => `${Endpoints.employeeGroupsFilterValues}`, []);

  const getFilterItems = useCallback(
    (filterName: GroupsFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return mutateData("get", apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data
      );
    },
    [baseUrl]
  );

  const getGroupNames = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(GroupsFilterNames.GROUP_NAME),
    [getFilterItems]
  );

  const getBenefitPlans = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(GroupsFilterNames.BENEFIT_PLANS),
    [getFilterItems]
  );

  return {
    getGroupNames,
    getBenefitPlans,
  };
};
