import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    timeField: {
      "& .MuiIconButton-root": {
        display: "none",
      },
    },
    tzButton: {
      position: "absolute",
      top: 0,
      right: 0,
      padding: "11px 15px",
    },
  })
);
