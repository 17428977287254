import { HierarchyLevels } from "./../constants/ParentCompany";
import { HeaderVariant } from "../state/Table";
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionOptions,
} from "../constants/Administrators";
import {
  FullHierarchyResponse,
  HierarchyRequest,
  HierarchyResponse,
  HierarchyResponseItem,
} from "../types/ParentCompany";
import { useCallback, useMemo } from "react";
import { isArray } from "lodash";
import { useTranslation } from "react-i18next";
import { useMe } from "../state/Administrators";
import { Company } from "../types/Companies";

export const useHierarchyUtils = (
  data: HierarchyResponse,
  level: HierarchyLevels
) => {
  const { t } = useTranslation();

  const removeSupportedLanguages = useCallback(
    (data: HierarchyResponse): HierarchyResponse => {
      return data?.map((item: HierarchyResponseItem) => {
        const entries = Object.entries(item);
        const arrayEntry = entries.filter(
          ([key, value]) => isArray(value) && key !== "supportedLanguages"
        )[0] ?? [undefined, undefined];
        const [key, value] = arrayEntry;
        if (key === undefined)
          return {
            ...item,
            supportedLanguages: undefined,
          };
        return {
          ...item,
          supportedLanguages: undefined,
          [key]: removeSupportedLanguages(value ?? []),
        };
      });
    },
    []
  );

  const parsedUngroupedCompanies = useMemo(() => {
    if (!data || !data?.length) return [];
    return data?.map((item) => ({
      ...item,
      name: item?.name ?? t("companies.ungrouped"),
    }));
  }, [data, t]);

  const removeEmptyDepartments = useCallback((data: Company[]) => {
    const dataCopy = [...data];
    dataCopy?.forEach((company) => {
      if (!company?.departments?.length) delete company?.departments;
    });
    return dataCopy;
  }, []);

  const parseCompanyAndCompanyGroups = useCallback(
    (data: FullHierarchyResponse[]) => {
      if (!data?.length) return {};
      if (data?.length === 1 && data[0].id === "ungrouped")
        return {
          companies: removeEmptyDepartments(data[0].companies),
        };
      else {
        const parsedCompanyGroups = data?.map((group) => ({
          ...group,
          companies: removeEmptyDepartments(group?.companies),
        }));
        return {
          company_groups: parsedCompanyGroups,
        };
      }
    },
    [removeEmptyDepartments]
  );

  const hierarchy = useMemo(() => {
    if (!data) return {};
    const parsedData = removeSupportedLanguages(parsedUngroupedCompanies);
    switch (level) {
      case HierarchyLevels.COMPANY_AND_COMPANY_GROUPS:
        return {
          company_groups: parsedData,
        };
      case HierarchyLevels.COMPANY_AND_DEPARTMENT:
        return {
          companies: parsedData,
        };
      default:
        return {
          company_groups: parsedData,
        };
    }
  }, [data, level, parsedUngroupedCompanies, removeSupportedLanguages]);

  const breadcrumbsHierarchy = useMemo(() => {
    if (!data) return {};
    const parsedData = removeSupportedLanguages(parsedUngroupedCompanies);
    switch (level) {
      case HierarchyLevels.COMPANY_AND_COMPANY_GROUPS:
        return parseCompanyAndCompanyGroups(
          parsedData as FullHierarchyResponse[]
        );
      case HierarchyLevels.COMPANY_AND_DEPARTMENT:
        return {
          companies: removeEmptyDepartments(parsedData),
        };
      default:
        return parseCompanyAndCompanyGroups(
          parsedData as FullHierarchyResponse[]
        );
    }
  }, [
    data,
    level,
    parseCompanyAndCompanyGroups,
    parsedUngroupedCompanies,
    removeEmptyDepartments,
    removeSupportedLanguages,
  ]);

  return {
    parsedUngroupedCompanies,
    hierarchy,
    breadcrumbsHierarchy,
  };
};

export const useHierarchyByHeaderVariant = () => {
  const { checkIfCanView, checkIfCanEdit } = useMe();
  const getPermissionType = useCallback(
    (module: LocalPermissionModules | GlobalPermissionModules | null) => {
      if (!module) return PermissionOptions.None;
      if (checkIfCanEdit(module)) return PermissionOptions.Edit;
      if (checkIfCanView(module)) return PermissionOptions.View;
      return PermissionOptions.None;
    },
    [checkIfCanEdit, checkIfCanView]
  );

  const getHierarchyRequestByHeaderVariant = useCallback(
    (variant: HeaderVariant): HierarchyRequest => {
      const hierarchyModulesByVariant: Record<
        HeaderVariant,
        GlobalPermissionModules | LocalPermissionModules | null
      > = {
        "": null,
        benefits: GlobalPermissionModules.Benefits,
        choices: GlobalPermissionModules.FlexBenefit,
        employees: LocalPermissionModules.Employees,
        groups: GlobalPermissionModules.EmployeeGroups,
        innerEmployeeChoices: LocalPermissionModules.Employees,
        innerEmployeeFlexAllocation: LocalPermissionModules.Employees,
        benefitsAssignGroups: GlobalPermissionModules.Benefits,
        innerEmployeeFlexBalance: LocalPermissionModules.Employees,
        benefitsAssignEmployees: GlobalPermissionModules.Benefits,
        innerEmployeeAssignBenefits: LocalPermissionModules.Employees,
        innerEmployeeAssignGroups: LocalPermissionModules.Employees,
        innerEmployeeLog: LocalPermissionModules.Employees,
        innerEmployeeTermsAcceptance: LocalPermissionModules.Employees,
        news: LocalPermissionModules.History,
        termsAcceptance: GlobalPermissionModules.Benefits,
        dashboard: LocalPermissionModules.Dashboard,
        compareMode: LocalPermissionModules.Dashboard,
        innerGroupAssignEmployees: GlobalPermissionModules.EmployeeGroups,
        innerBenefitsChoices: GlobalPermissionModules.Benefits,
        innerGroupAssignBenefits: GlobalPermissionModules.EmployeeGroups,
        innerBenefitTermsAcceptance: GlobalPermissionModules.Benefits,
        log: LocalPermissionModules.History,
        clients: null,
        integrations: null,
        users: null,
        common: null,
        companySettingsAdministrators: null,
        companySettingsCompanies: null,
        companySettingsCompanyGroups: null,
        employeeCategories: null,
        clientAdministrators: null,
        recipients: null,
        marketplaceOrders: null,
      };

      const hierarchyModule = hierarchyModulesByVariant[variant];
      const hierarchyPermissions = getPermissionType(hierarchyModule);
      const hierarchyLevel = HierarchyLevels.FULL_HIERARCHY;

      return {
        level: hierarchyLevel,
        module: hierarchyModule,
        permissions: hierarchyPermissions,
      };
    },
    [getPermissionType]
  );

  return {
    getHierarchyRequestByHeaderVariant,
  };
};
