import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    disableScrollbar: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      overflowX: "hidden",
    },
    sidebar: {
      maxWidth: 300,
    },
  })
);
