import React, { PropsWithChildren } from "react";
import SidebarContainer from "../../Sidebar/SidebarContainer/SidebarContainer";
import SidebarInner from "../../Sidebar/SidebarInner/SidebarInner";

export default function InnerWithTabsRight({
  children,
}: PropsWithChildren<{}>) {
  return (
    <SidebarContainer
      marginTop={0}
      bgcolor="#D8E0E7"
      maxHeight="605px"
      maxWidth="320px !important"
      overflow="auto"
      xs={4}
      style={{
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      }}
    >
      <SidebarInner
        paddingTop="25px"
        bgcolor="#D8E0E7 !important"
        position="relative"
      >
        {children}
      </SidebarInner>
    </SidebarContainer>
  );
}
