import { useLoading } from "./../utils/Loading";
import { Endpoints } from "./../api/constants";
import { useCallback, useMemo } from "react";
import {
  UseFlexBenefitAllocation,
  FlexBenefitAllocationRequest,
  FlexBenefitAllocationResponse,
  UpdateFlexBenefitAllocationRequest,
} from "./../types/FlexBenefitAllocation";
import { mutateData } from "../api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useSWR, { mutate } from "swr";
import moment from "moment";
import { getDateIsoFormat } from "../utils/Common";

const useFlexBenefitAllocations = (id?: string): UseFlexBenefitAllocation => {
  const { t } = useTranslation();
  const { stopLoading, startLoading, loading } = useLoading();
  const { data: employeeAllocations, error: employeeAllocationsError } = useSWR(
    id ? `${Endpoints.employee}/${id}/allocations` : null
  );

  const apiLoading = useMemo(() => {
    return !employeeAllocations && !employeeAllocationsError;
  }, [employeeAllocations, employeeAllocationsError]);

  const createFlexBenefitAllocation = useCallback(
    (data: FlexBenefitAllocationRequest, employeeIds: string[]) => {
      startLoading();
      const startDate = getDateIsoFormat(data.startDate);
      mutateData("post", Endpoints.createFlexBenefitAllocation, {
        allocation: {
          ...data,
          startDate,
          allocatedAmount: Number(data.allocatedAmount),
          accrual: !data.accrual ? false : data.accrual,
        },
        employeeIds: employeeIds,
      })
        .then(() => {
          toast(t("common.added_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${employeeIds}/allocations`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t]
  );

  const parsedEmployeeAllocationList = useMemo(() => {
    const latestDate = employeeAllocations?.data?.find((allo: any) =>
      moment(allo.startDate).isBefore()
    )?.startDate;

    return (
      employeeAllocations?.data?.map((data: FlexBenefitAllocationResponse) => ({
        ...data,
        inactive:
          data.startDate !== latestDate && !moment(data.startDate).isAfter(),
        isActive: data.startDate === latestDate ? "Active" : null,
        isPast: moment(data.startDate).isBefore(),
        allocatedAmount: Math.ceil(data.allocatedAmount),
      })) ?? []
    );
  }, [employeeAllocations]);

  const updateEmployeeAllocation = useCallback(
    (data: UpdateFlexBenefitAllocationRequest, allocationId: string) => {
      startLoading();
      const startDate = getDateIsoFormat(data.startDate);
      mutateData(
        "put",
        `${Endpoints.employee}/${id}/allocations/${allocationId}`,
        {
          employeeId: id,
          allocation: {
            ...data,
            startDate,
          },
        }
      )
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${id}/allocations`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, id]
  );

  return {
    loading: loading || apiLoading,
    createFlexBenefitAllocation,
    employeeAllocations,
    employeeAllocationsError,
    parsedEmployeeAllocationList,
    updateEmployeeAllocation,
  };
};

export default useFlexBenefitAllocations;
