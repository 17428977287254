import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors, getBorder } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 650,
      padding: 30,
      "& .MuiGrid-container": {
        alignItems: "center",
        "& .MuiGrid-grid-sm-4 .MuiBox-root": {
          paddingTop: 0,
        },
      },
    },
    dialogContentRoot: {
      padding: 0,
      marginTop: 23,
      overflow: "hidden",
      borderTop: getBorder(Colors.Divider),
    },
    dividerBottom: {
      borderBottom: getBorder(Colors.Divider),
      padding: "12px 0px",
    },
    chip: {
      height: 28,
      borderRadius: 20,
      minWidth: 60,
      textTransform: "uppercase",
    },
    dialogActionsRoot: {
      padding: 0,
      marginTop: 30,
      "&.MuiDialogActions-spacing > :not(:first-child)": {
        marginLeft: 20,
      },
    },
  })
);
