import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    closeBtn: {
      minWidth: 46,
      "& .MuiIconButton-root:hover": {
        "& path": {
          stroke: Colors.Error,
        },
      },
    },
    statusDialogContentWrapper: {
      overflowY: "auto",
      paddingLeft: 30,
      paddingRight: 30,
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "20px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "20px",
      },
    },
    gridSpacingFix: {
      margin: 0,
      marginLeft: -8,
      marginRigth: -8,
      "& > .MuiGrid-item": {
        paddingBottom: 6,
        "&:first-child": {
          maxWidth: "80px",
        },
      },
    },
    keyboardDatepickerDisabled: {
      color: "rgba(105, 113, 125, 1)",
    },
  })
);
