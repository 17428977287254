import { Box, Button, Typography } from "@material-ui/core";
import { MpForm } from "@mp-react/form";
import { MpBreadcrumbFilterData } from "@mp-react/table";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useParams } from "react-router-dom";
import PermissionList from "../../../../components/administrators/PermissionList/PermissionList";
import PermissionsActionHeader from "../../../../components/administrators/PermissionsActionHeader/PermissionsActionHeader";
import Loader from "../../../../components/common/Loader/Loader";
import FormFooter from "../../../../components/layouts/FormFooter/FormFooter";
import InnerWithTabsContainer from "../../../../components/layouts/InnerWithTabs/InnerWithTabsContainer/InnerWithTabsContainer";
import InnerWithTabsLeft from "../../../../components/layouts/InnerWithTabs/InnerWithTabsLeft/InnerWithTabsLeft";
import InnerWithTabsRight from "../../../../components/layouts/InnerWithTabs/InnerWithTabsRight/InnerWithTabsRight";
import InnerWithTabsTitle from "../../../../components/layouts/InnerWithTabs/InnerWithTabsTitle/InnerWithTabsTitle";
import { PermissionLevels } from "../../../../constants/Administrators";
import { HierarchyLevels } from "../../../../constants/ParentCompany";
import { useClientPermissions } from "../../../../state/Clients";
import { useAdminHierarchy } from "../../../../state/Hierarchy";
import { AdminHierarchyRequest } from "../../../../types/ParentCompany";
import { useFormUtils } from "../../../../utils/Form";
import { usePermissionUtils } from "../../../../utils/Permissions";

const Permissions = () => {
  const params: { adminId: string; clientId: string } = useParams();
  const administratorId = useMemo(() => params?.adminId, [params?.adminId]);
  const clientId = useMemo(() => params?.clientId, [params?.clientId]);

  const hierachyRequest = useMemo<AdminHierarchyRequest>(
    () => ({
      level: HierarchyLevels.COMPANY_AND_COMPANY_GROUPS,
      parentCompanyId: clientId,
    }),
    [clientId]
  );

  const { hierarchy, loading: hierarchyLoading } =
    useAdminHierarchy(hierachyRequest);

  const { t } = useTranslation();
  const {
    form,
    formTitle,
    handlePermissionClick,
    selectedId,
    formMethods,
    handleSubmit,
    removeChanges,
    hasChanges,
    permissionData,
    handleSetAllPermissions,
    getActualFormValues,
    formChanges,
    setDefaultFormValues,
  } = usePermissionUtils();
  const {
    updatePermissions,
    loading,
    parsedCompanyGroupPermissions,
    parsedCompanyPermissions,
    parsedGlobalPermissions,
  } = useClientPermissions({
    clientId,
    adminId: administratorId,
    selectedId,
    removeChanges,
  });
  const { reset } = formMethods;

  const defaultValues = useMemo(() => {
    if (loading || !selectedId) return null;
    const isCompany =
      selectedId.includes("company-") || selectedId === "allLocalPermissions";
    const isCompanyGroup = selectedId.includes("companyGroup-");
    if (isCompany && !!parsedCompanyPermissions) {
      return parsedCompanyPermissions;
    }

    if (isCompanyGroup && !!parsedCompanyGroupPermissions) {
      return parsedCompanyGroupPermissions;
    }

    if (!!parsedGlobalPermissions && !isCompany && !isCompanyGroup) {
      return parsedGlobalPermissions;
    }
  }, [
    loading,
    parsedCompanyGroupPermissions,
    parsedCompanyPermissions,
    parsedGlobalPermissions,
    selectedId,
  ]);

  const formValues = useMemo(() => {
    if (!defaultValues) return null;
    return getActualFormValues({ ...defaultValues }, formChanges, selectedId);
  }, [defaultValues, formChanges, getActualFormValues, selectedId]);

  useEffect(() => {
    if (loading || !selectedId || !defaultValues || !formValues) return;
    reset(formValues, { isDirty: false });
    setDefaultFormValues(defaultValues);
    //eslint-disable-next-line
  }, [defaultValues, loading, selectedId, formValues]);

  const footerTransform = useMemo(
    () => `translateY(${hasChanges ? "0" : "70px"})`,
    [hasChanges]
  );

  const { overridables } = useFormUtils();

  const submitData = useCallback(
    () =>
      handleSubmit(() => {
        reset(defaultValues, { isDirty: false });
        updatePermissions(permissionData as FormData);
      })(),
    [handleSubmit, reset, defaultValues, updatePermissions, permissionData]
  );

  const LocalPermissions = useMemo(
    () =>
      !hierarchyLoading ? (
        <PermissionList
          tree={hierarchy as MpBreadcrumbFilterData}
          allSelectionId="allLocalPermissions"
          level={PermissionLevels.Local}
          onClick={handlePermissionClick}
          selectedId={selectedId}
          disabled={loading}
        />
      ) : (
        <Loader minHeight="20px" />
      ),
    [handlePermissionClick, hierarchy, hierarchyLoading, loading, selectedId]
  );

  const Form = useMemo(() => {
    if (loading) return <Loader />;

    if (!selectedId) return null;

    return (
      <MpForm
        overridables={overridables}
        {...form}
        useFormMethods={formMethods}
      />
    );
  }, [form, formMethods, loading, overridables, selectedId]);

  return (
    <Box>
      <Prompt
        when={hasChanges}
        message={t("errors.user_leaving_edited_page")}
      />
      <PermissionsActionHeader
        onChangeAll={handleSetAllPermissions}
        isSelected={!!selectedId}
      />
      <InnerWithTabsContainer>
        <InnerWithTabsRight>
          <InnerWithTabsTitle>
            {t("administrators.global_permissions")}
          </InnerWithTabsTitle>
          <PermissionList
            allSelectionId="allGlobalPermissions"
            level={PermissionLevels.Global}
            onClick={handlePermissionClick}
            selectedId={selectedId}
            disabled={loading}
          />
          <InnerWithTabsTitle marginTop="25px">
            {t("administrators.local_permissions")}
          </InnerWithTabsTitle>
          {LocalPermissions}
        </InnerWithTabsRight>
        <InnerWithTabsLeft>
          <Box marginBottom="15px">
            <Typography variant="subtitle1">{formTitle}</Typography>
          </Box>
          {Form}
        </InnerWithTabsLeft>
      </InnerWithTabsContainer>
      <FormFooter
        justifyContent="flex-end"
        spacing={0}
        containerProps={{
          style: {
            transform: footerTransform,
          },
        }}
      >
        <Box marginRight="20px">
          <Button onClick={removeChanges} variant="text">
            {t("common.cancel")}
          </Button>
        </Box>
        <Button variant="contained" color="primary" onClick={submitData}>
          {t("common.confirm_changes")}
        </Button>
      </FormFooter>
    </Box>
  );
};

export default Permissions;
