import React, { useMemo, useState } from "react";
import { MpColumn, MpTable, MpColumnFormat } from "@mp-react/table";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ExpenseDialog from "../../../../../components/benefitExpenses/ExpenseDialog";
import DownloadExcelButton from "../../../../../components/table/DownloadExcelButton/DownloadExcelButton";
import { useDefaultHighlights } from "../../../../../constants/Table";
import {
  useBenefitExpenses,
  useBenefitExpensesAsyncMethods,
  useBenefitExpensesXLSExport,
  useExpenseUpdate,
} from "../../../../../state/BenefitExpenses";
import {
  BenefitExpense,
  BenefitExpenseFormData,
} from "../../../../../types/BenefitExpenses";
import { CustomRendererType } from "../../../../../types/Table";
import { useDialog } from "../../../../../utils/Dialog";
import useTableUtils, { useTableFilterTypes } from "../../../../../utils/Table";
import { useCurrency } from "../../../../../utils/useCurrency";
import {
  BenefitExpensesItem,
  BenefitStatus,
} from "../../../../../types/BenefitExpenses";
import AlertDialog from "../../../../../components/dialogs/AlertDialog/AlertDialog";
import clsx from "clsx";
import { makeStyles, Tooltip } from "@material-ui/core";
import { ExpenseStatus } from "../../../../../types/BenefitExpenses";

const useStyles = makeStyles((theme) => ({
  rejected: {
    color: theme.palette.error.main,
  },
  accepted: {
    color: theme.palette.success.main,
  },
  row: {
    verticalAlign: "initial",
    "& td": {
      whiteSpace: "break-spaces",
    },
  },
}));

interface Props {
  employeeId: string;
  renderHeader: (
    reload: () => Promise<any>,
    xlsExportElement: React.ReactNode
  ) => React.ReactNode;
}

const BenefitExpensesTable = ({ employeeId, renderHeader }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const highlights = useDefaultHighlights();

  const {
    getSearchSelectFilter,
    getCurrencyRangeFilter,
    getSelectFilter,
    dateRangeFilter,
  } = useTableFilterTypes();

  const benefitTitleFilter = useMemo(
    () => getSearchSelectFilter("getBenefitTitles"),
    [getSearchSelectFilter]
  );

  const assignmentStatusFilter = useMemo(
    () =>
      getSelectFilter("assignmentStatus", [
        { value: "on", label: t("status.on") },
        { value: "flex", label: t("status.flex") },
        { value: "off", label: t("status.off") },
      ]),
    [getSelectFilter, t]
  );

  const expenseStatusLabelsMap: Record<ExpenseStatus, string> = useMemo(
    () => ({
      Reserved: t("benefitExpenses.expenseStatus-reserved"),
      Accepted: t("benefitExpenses.expenseStatus-accepted"),
      Rejected: t("benefitExpenses.expenseStatus-rejected"),
    }),
    [t]
  );
  const expenseStatusFilter = useMemo(
    () =>
      getSelectFilter(
        "status",
        Object.keys(expenseStatusLabelsMap).map((value) => ({
          value,
          label: expenseStatusLabelsMap[value as ExpenseStatus],
        }))
      ),
    [expenseStatusLabelsMap, getSelectFilter]
  );

  const asyncGetMethods = useBenefitExpensesAsyncMethods(employeeId);

  const {
    handleGetData,
    overridables,
    overrideClasses,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
    translations,
  } = useTableUtils("common");

  const columns = useMemo(() => {
    return [
      {
        key: "creationDate",
        label: t("benefitExpenses.expenseDate"),
        render: CustomRendererType.dateFormat,
        filter: dateRangeFilter,
      },
      {
        key: "assignmentStatus",
        label: t("benefitExpenses.benefitType"),
        render: CustomRendererType.benefitAssignmentStatus,
        filter: assignmentStatusFilter,
        sort: false,
      },
      {
        key: "name",
        label: t("benefitExpenses.benefitTitle"),
        filter: benefitTitleFilter,
        sort: false,
      },
      {
        key: "description",
        label: t("benefitExpenses.expenseDescription"),
        sort: false,
      },
      {
        key: "expense",
        label: t("benefitExpenses.expense"),
        render: CustomRendererType.currencyWithFraction,
        filter: getCurrencyRangeFilter(),
        totals: true,
      },
      {
        key: "status",
        label: t("common.status"),
        format: {
          type: MpColumnFormat.map,
          valueMap: expenseStatusLabelsMap,
        },
        filter: expenseStatusFilter,
        sort: false,
        totals: false,
      },
    ] as MpColumn[];
  }, [
    assignmentStatusFilter,
    benefitTitleFilter,
    dateRangeFilter,
    expenseStatusFilter,
    expenseStatusLabelsMap,
    getCurrencyRangeFilter,
    t,
  ]);

  const { expenses, loading, reload } = useBenefitExpenses(
    employeeId,
    tableQueryParam
  );

  const { exportToXLS, exporting } = useBenefitExpensesXLSExport(
    employeeId,
    tableQueryParam
  );

  const xlsExportElement = (
    <DownloadExcelButton
      listLength={expenses?.count ?? 0}
      loading={exporting}
      exportToExcel={exportToXLS}
    />
  );

  const { open, openDialog, closeDialog } = useDialog();
  const marketplaceExpenseAlertDialog = useDialog();
  const [currentExpense, setCurrentExpense] = useState<BenefitExpense>();
  const { update, updating } = useExpenseUpdate();

  const handleExpenseUpdate = async (data: BenefitExpenseFormData) => {
    if (!currentExpense?.id) {
      return;
    }
    await update(currentExpense.id, { ...data, employeeIds: [employeeId] });
    await reload();
    closeDialog();
    toast.success(t("common.updated_succesfully"));
  };

  const { getDefaultCurrencyFormat } = useCurrency();
  const totalsData = {
    expense: getDefaultCurrencyFormat(expenses?.footer?.expenses) ?? "",
  };

  const renderExpenseStatus = (
    statusValue?: ExpenseStatus | null,
    comment?: string | null
  ) => {
    if (!statusValue) {
      return "-";
    }
    return (
      <Tooltip title={statusValue === "Rejected" && comment ? comment : ""}>
        <span
          className={clsx({
            [classes.rejected]: statusValue === "Rejected",
            [classes.accepted]: statusValue === "Accepted",
          })}
        >
          {expenseStatusLabelsMap[statusValue]}
        </span>
      </Tooltip>
    );
  };

  const tableData = expenses?.data?.map((expense) => ({
    ...expense,
    description: expense.marketplaceOrderNr
      ? t("benefitExpenses.marketplaceExpenseDescription", {
          itemId: expense.marketplaceOrderNr,
          interpolation: {
            escapeValue: false,
          },
        })
      : expense.description,
    status: renderExpenseStatus(expense.status, expense.commentForEmployee),
    inactive: expense.order === BenefitStatus.inactive,
  }));

  return (
    <>
      {renderHeader(reload, xlsExportElement)}
      <MpTable
        columns={columns}
        rowIdKey="id"
        highlights={highlights}
        data={tableData}
        pageSize={expenses?.pageSize}
        totalsData={totalsData}
        loading={loading}
        onGetData={handleGetData}
        overridables={overridables}
        classes={{
          ...overrideClasses,
          row: clsx(overrideClasses.row, classes.row),
        }}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
        disablePagination
        enableGlobalActions
        totals={!!expenses?.data.length}
        stickyTotals
        asyncGetMethods={asyncGetMethods}
        translations={translations}
        onRowClick={(row) => {
          if (row) {
            const rowData = row.original as BenefitExpensesItem;
            if (rowData.marketplaceOrderNr) {
              marketplaceExpenseAlertDialog.openDialog();
              return;
            }
            setCurrentExpense({
              ...rowData,
              expense: Number(rowData.expense),
            });
            openDialog();
          }
        }}
      />
      <ExpenseDialog
        employeeIds={[employeeId]}
        initialData={currentExpense}
        open={open}
        onCancel={closeDialog}
        onSubmit={handleExpenseUpdate}
        loading={updating}
        title={t("benefitExpenses.editExpense")}
      />
      <AlertDialog
        open={marketplaceExpenseAlertDialog.open}
        id="expense-alert"
        title={t("benefitExpenses.marketplaceExpenseNotEditable")}
        onSubmit={marketplaceExpenseAlertDialog.closeDialog}
      />
    </>
  );
};

export default BenefitExpensesTable;
