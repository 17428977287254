import {
  Button,
  DialogActions,
  DialogProps,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useStyles from "./SubmitActionDialog.styles";
import DialogExtended from "../../common/DialogExtended/DialogExtended";

interface IProps extends DialogProps {
  actionLabel: string;
  onCancelClick: () => void;
  onSubmit: () => void;
  id: string;
  title: string;
  subtitle?: string;
  subtitleClassName?: string;
  error?: boolean;
  disableCancel?: boolean;
}

const SubmitActionDialog = ({
  actionLabel,
  onCancelClick,
  onSubmit,
  id,
  title,
  subtitle,
  subtitleClassName,
  error,
  disableCancel,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DialogExtended
      classes={{ paperWidthSm: classes.root }}
      aria-labelledby={id}
      onClose={onCancelClick}
      onEnterPress={onSubmit}
      {...rest}
    >
      <Typography variant="h5">{title}</Typography>
      {!!subtitle && (
        <Typography
          color="textSecondary"
          className={clsx(classes.subtitle, subtitleClassName)}
        >
          {subtitle}
        </Typography>
      )}
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        {!disableCancel && (
          <Button onClick={onCancelClick} variant="text">
            {t("common.cancel")}
          </Button>
        )}
        <Button
          className={clsx({
            [classes.errorButton]: error,
          })}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          {actionLabel}
        </Button>
      </DialogActions>
    </DialogExtended>
  );
};

export default SubmitActionDialog;
