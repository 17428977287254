import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import { useDateFormat } from "../../../utils/Common";
import moment from "moment";

export default function DateTime(props: CellProps<{}>) {
  const { value } = useRendererOptions(props);
  const formattedValue = useMemo(() => moment(value).format(), [value]);
  const { date, time } = useDateFormat(formattedValue);

  return (
    <>
      <Typography component="span" variant="body2">
        {date ?? ""}
      </Typography>
      <Typography
        component="span"
        variant="body2"
        color="textSecondary"
        style={{ paddingLeft: 10 }}
      >
        {time ?? ""}
      </Typography>
    </>
  );
}
