import { useCallback, useState } from "react";
import { UseDialog } from "../types/Common";

export const useDialog = (defaultValue?: boolean): UseDialog => {
  const [open, setOpen] = useState<boolean>(defaultValue ?? false);

  const closeDialog = useCallback(() => setOpen(false), []);

  const openDialog = useCallback(() => setOpen(true), []);

  return {
    open,
    closeDialog,
    openDialog,
  };
};
