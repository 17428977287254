import { useDefaultHighlights } from "../../constants/Table";
import { MpColumn, MpTableType } from "@mp-react/table";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { CustomRendererType } from "../../types/Table";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useAssignGroupsTable = (): MpTableType => {
  const { t } = useTranslation();
  const highlights = useDefaultHighlights();
  const { keyWithSuffix } = useLocalStorage();

  const columns = useMemo(
    (): MpColumn[] => [
      {
        label: t("entity_type.employeeGroup"),
        key: "name",
        sort: false,
        width: 429,
        fixedHeadWidth: true,
      },
      {
        label: t("table.employee_abbrevation"),
        key: "employeeCount",
        sort: false,
        width: 141,
        fixedHeadWidth: true,
      },
      {
        label: t("menu.benefits"),
        key: "benefitPlansCount",
        sort: false,
        width: 141,
        fixedHeadWidth: true,
      },
      {
        label: t("assign_employees.included"),
        key: "included",
        sort: false,
        render: CustomRendererType.includedExcludedSwitch as any,
        action: {
          slug: "toggleIncludedExcluded",
        },
        width: 233,
        fixedHeadWidth: true,
      },
    ],
    [t]
  );

  return {
    columns,
    rowIdKey: "id",
    filterVariant: "head",
    totals: true,
    stickyTotals: true,
    highlights: highlights,
    storageIdentifier: keyWithSuffix(StorageKeys.employeeAssignGroupsTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
