import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: 10,
    },
    button: {
      padding: "15px 20px 21px 20px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      position: "relative",
      "&::before": {
        content: "''",
        width: "calc(100% - 20px)",
        height: 1,
        background: "rgba(0, 0, 0, 0.1)",
        position: "absolute",
        top: 0,
        right: 0,
      },
    },
  })
);
