import { FeatureKey, FeatureToggleManager, Env } from "./Types";

const FEATURES_KEY = "mfts";
const ENV_KEY = "mft-env";

interface FeatureToggleConfig {
  key: FeatureKey;
  isEnabled: boolean;
}

export class LocalStorageFTManager implements FeatureToggleManager {
  constructor(private features: FeatureToggleConfig[]) {}

  getEnabledFeatureKeys() {
    return JSON.parse(
      localStorage.getItem(FEATURES_KEY) ?? "[]"
    ) as FeatureKey[];
  }

  private setEnabledKeys(keys: FeatureKey[]) {
    localStorage.setItem(FEATURES_KEY, JSON.stringify(keys));
  }

  isFeatureEnabled = (key: FeatureKey) => {
    const env = this.getEnv();
    if ([Env.development, Env.uat].includes(env)) {
      return true;
    }
    const defaultConfig = this.features.find((feature) => feature.key === key);
    const enabledKeys = this.getEnabledFeatureKeys();
    return (enabledKeys?.includes(key) || defaultConfig?.isEnabled) ?? false;
  };

  enableFeature = (key: FeatureKey) => {
    const currentKeys = this.getEnabledFeatureKeys();
    if (!currentKeys.includes(key)) {
      this.setEnabledKeys([...currentKeys, key]);
    }
  };

  disableFeature = (key: FeatureKey) => {
    const currentKeys = this.getEnabledFeatureKeys();
    if (currentKeys.includes(key)) {
      const newKeys = currentKeys.filter((currentKey) => currentKey === key);
      this.setEnabledKeys(newKeys);
    }
  };

  availableFeatures = () => {
    return this.features.map((feature) => feature.key);
  };

  private getEnv = () => {
    const persistedEnv = localStorage.getItem(ENV_KEY);
    if (!persistedEnv) {
      if (process.env.NODE_ENV === "development") {
        return Env.development;
      }
      if (process.env.REACT_APP_ENV_NAME === "uat") {
        return Env.uat;
      }
      return Env.production;
    }
    return persistedEnv as Env;
  };

  setEnv = (envKey: Env) => {
    localStorage.setItem(ENV_KEY, envKey);
  };

  disableAll = () => {
    localStorage.setItem(FEATURES_KEY, "[]");
  };
}
