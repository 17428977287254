import { useCallback } from "react";
import { CustomColumn } from "../types/Employees";
import { EmployeeGroupsAssignEmployeeDataItem } from "../types/EmployeeGroups";
import { useTableStore } from "../state/Table";
import { uniq } from "lodash";

export const useTableSearch = () => {
  const searchQuery = useTableStore((state) => state.searchQuery);
  const search = searchQuery.toLowerCase();

  const filteredGroupsBySearch = useCallback(
    (employeeGroup: string[]) => {
      const groups = employeeGroup?.filter((group: string) =>
        group.toLowerCase().includes(search)
      );
      return groups?.length;
    },
    [search]
  );

  const parseCustomColumn = useCallback(
    (customColumn?: CustomColumn | string) =>
      typeof customColumn === "string"
        ? customColumn
        : (customColumn as CustomColumn)?.name,
    []
  );

  const textIncludesSearch = useCallback(
    (text?: string) => text?.toLowerCase()?.includes(search),
    [search]
  );

  const someIncludesSearch = useCallback(
    (values: string[]) => values.some((item) => textIncludesSearch(item)),
    [textIncludesSearch]
  );

  const filterGroupsAssignEmployeeTable = useCallback(
    (data: EmployeeGroupsAssignEmployeeDataItem[]) => {
      if (!searchQuery || !data?.length) return data;

      return data.filter((item) => {
        const { employeeGroup, fullName, jobTitle, customColumn } = item;
        const hasFilteredGroup = filteredGroupsBySearch(employeeGroup);
        const parsedCustomColumn = parseCustomColumn(customColumn);
        const investment = Math.round(item.investment)?.toString();

        const parsedValues = [
          fullName,
          jobTitle,
          parsedCustomColumn,
          investment,
        ];

        return someIncludesSearch(parsedValues) || hasFilteredGroup;
      });
    },
    [filteredGroupsBySearch, parseCustomColumn, searchQuery, someIncludesSearch]
  );

  //   FOOTER

  const countUniqItems = useCallback(
    (
      data: EmployeeGroupsAssignEmployeeDataItem[],
      type: keyof EmployeeGroupsAssignEmployeeDataItem
    ) => {
      const uniqItems = uniq(data?.map((item) => item[type]))?.filter(
        (item) => !!item
      );
      return uniqItems?.length;
    },
    []
  );

  const countUniqGroups = useCallback(
    (data: EmployeeGroupsAssignEmployeeDataItem[]) => {
      const uniqGroups = uniq(
        data
          ?.map((item) => item.employeeGroup)
          ?.reduce((prev, curr) => prev.concat(curr))
      );
      return uniqGroups?.length;
    },
    []
  );

  const sumInvestments = useCallback(
    (data: EmployeeGroupsAssignEmployeeDataItem[]) => {
      const investments = data.map((item) => item.investment);
      return !!investments?.length
        ? investments.reduce((prev, curr) => Number(prev) + Number(curr))
        : 0;
    },
    []
  );

  const filterGroupsAssignEmployeeTableFooter = useCallback(
    (data: EmployeeGroupsAssignEmployeeDataItem[]) => {
      if (!data?.length || !searchQuery) return null;

      const fullName = countUniqItems(data, "fullName");
      const jobTitle = countUniqItems(data, "jobTitle");
      const customColumn = countUniqItems(data, "customColumn");
      const investment = sumInvestments(data);
      const excluded = data.filter((item) => !item.included).length;
      const employeeGroup = countUniqGroups(data);

      return {
        fullName,
        excluded,
        employeeGroup,
        investment,
        jobTitle,
        customColumn,
      };
    },
    [countUniqGroups, countUniqItems, searchQuery, sumInvestments]
  );

  return {
    filterGroupsAssignEmployeeTable,
    filterGroupsAssignEmployeeTableFooter,
  };
};
