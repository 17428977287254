import { MpColumn, MpTableType, MpActions } from "@mp-react/table";
import { CustomRendererType } from "../../types/Table";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useMe } from "../../state/Administrators";
import { useTableFilterTypes } from "../../utils/Table";
import { useLocalStorage } from "../../utils/LocalStorage";
import { StorageKeys } from "../../types/LocalStorage";

export const useEmployeeInnerTermsAcceptanceType = (): MpTableType => {
  const { canEditBenefits } = useMe();
  const { t } = useTranslation();
  const { keyWithSuffix } = useLocalStorage();

  const { dateRangeFilter, getSelectFilter, getFilterNameSearchSelectFilter } =
    useTableFilterTypes();

  const benefitPlanTypeFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getBenefitPlanType"),
    [getFilterNameSearchSelectFilter]
  );

  const benefitPlanNameFilter = useMemo(
    () => getFilterNameSearchSelectFilter("getBenefitPlanName"),
    [getFilterNameSearchSelectFilter]
  );

  const acceptanceStatusFilter = useMemo(
    () =>
      getSelectFilter("acceptanceStatus", [
        {
          value: "accepted",
          label: t("common.accepted"),
        },
        {
          value: "noAccepted",
          label: t("common.not_accepted"),
        },
      ]),
    [getSelectFilter, t]
  );

  const columns = useMemo(
    (): MpColumn[] => [
      {
        label: t("benefits.benefit_name"),
        key: "benefitPlanName",
        filter: benefitPlanNameFilter,
        width: 230,
        fixedHeadWidth: true,
      },
      {
        label: t("benefits.benefit_type"),
        key: "benefitPlanType",
        render: CustomRendererType.benefitTypeTranslation as any,
        filter: benefitPlanTypeFilter,
        width: 144,
        fixedHeadWidth: true,
      },
      {
        label: t("benefits.request_date"),
        key: "requestDate",
        totals: false,
        render: CustomRendererType.dateFormat as any,
        filter: dateRangeFilter,
        sort: false,
        width: 151,
        fixedHeadWidth: true,
      },
      {
        label: t("benefits.accept_Date"),
        key: "acceptanceDate",
        totals: false,
        render: CustomRendererType.dateFormat as any,
        filter: dateRangeFilter,
        sort: false,
        width: 147,
        fixedHeadWidth: true,
      },
      {
        label: t("common.status"),
        key: "acceptanceStatus",
        totals: false,
        render: CustomRendererType.checked as any,
        filter: acceptanceStatusFilter,
        sort: false,
        width: 129,
        fixedHeadWidth: true,
      },
      {
        label: t("benefits.pdf"),
        key: "termsConditionsFileId",
        totals: false,
        sort: false,
        render: CustomRendererType.pdf as any,
        width: 94,
        fixedHeadWidth: true,
      },
    ],
    [
      acceptanceStatusFilter,
      benefitPlanNameFilter,
      benefitPlanTypeFilter,
      dateRangeFilter,
      t,
    ]
  );

  const bulkActions: MpActions = useMemo(
    () => [
      {
        slug: "statusAccepted",
        label: t("actions.status_accepted"),
      },
      {
        slug: "statusNotAccepted",
        label: t("actions.status_not_accepted"),
      },
      {
        slug: "sendReminder",
        label: t("actions.send_reminder"),
      },
    ],
    [t]
  );

  return {
    columns,
    rowIdKey: "id",
    selection: canEditBenefits ? "multiple" : undefined,
    filterVariant: "head",
    disablePagination: true,
    bulkActions: bulkActions,
    totals: true,
    stickyTotals: true,
    storageIdentifier: keyWithSuffix(StorageKeys.employeeTermsTable),
    cacheFilters: true,
    cacheSorting: true,
    clearColumnFilters: true,
  };
};
