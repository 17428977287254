import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { BorderRadius, Colors, Shadows } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {
      display: "flex",
      alignItems: "center",
      "& > .MuiListItem-gutters": {
        paddingLeft: 1,
        paddingRight: 1,
      },
    },
    menuRoot: {
      position: "absolute",
      top: 60,
      left: 0,
      zIndex: 2,
      paddingTop: 8,
      paddingBottom: 9,
      minWidth: 220,
      boxShadow: Shadows.PaperElevation8,
    },
    linkItem: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2.37)}px`,
      width: "max-content",
      height: 44,
      display: "flex",
      alignItems: "center",
      color: Colors.White,
      transition: theme.transitions.create("background-color"),
      borderRadius: BorderRadius.MainRadius,
      "& ~ .MuiTouchRipple-root": {
        margin: "8px 1px",
        borderRadius: BorderRadius.MainRadius,
      },
      "&:hover": {
        backgroundColor: Colors.HoverPrimary,
      },
    },
    active: {
      position: "relative",
      "&::before": {
        content: "''",
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.6)",
        mixBlendMode: "overlay",
        borderRadius: BorderRadius.MainRadius,
      },
    },
    activeChild: {
      "& .MuiTypography-root": {
        fontWeight: "bold",
      },
    },
    buttonIcon: {
      marginLeft: theme.spacing(1),
    },
    listItem: {
      padding: "14px 20px",
    },
  })
);
