import { useCallback, useState, useMemo } from "react";
import { MpBulkActionCallback } from "@mp-react/table";
import {
  ReminderRequest,
  ReminderResponse,
  ReminderStatusesResponse,
} from "../types/NotificationService";
import { Row } from "react-table";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useNotificationService from "../state/NotificationService";
import { useDialog } from "./Dialog";

export const useTermsAcceptanceNotifications = (
  employeeId?: string,
  benefitPlanId?: string,
  tableType?: "more" | "employee" | "benefit"
) => {
  const { t } = useTranslation();
  const { sendTermsAcceptanceReminder } = useNotificationService();
  const {
    closeDialog,
    open: openDialog,
    openDialog: handleOpenDialog,
  } = useDialog();
  const [successEmployeeId, setSuccessEmployeeId] =
    useState<ReminderStatusesResponse[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const statusesError = useMemo(
    () => [
      "employeeNotFound",
      "benefitNotFound",
      "noEmailAndUser",
      "missingBenefitPlanTranslation",
      "missingPermissions",
      "termsAlreadyAccepted",
      "employeeIsInactive",
    ],
    []
  );

  const statusesWarning = useMemo(
    () => ["noEmail", "noUser", "userMissingToken"],
    []
  );

  const handleCloseDialog = useCallback(() => {
    setSuccessEmployeeId([]);
    closeDialog();
  }, [setSuccessEmployeeId, closeDialog]);

  const getEmployeeAndBenefitIds = useCallback(
    (selectedRows: Row<{}>[] | undefined) => {
      const employeeAndBenefitIds: ReminderRequest[] =
        selectedRows?.map((item) => {
          const { original } = item as any;
          return {
            employeeId: employeeId ? employeeId : original.employeeId,
            benefitPlanId: benefitPlanId
              ? benefitPlanId
              : original.benefitPlanId,
          };
        }) ?? [];
      return employeeAndBenefitIds;
    },
    [employeeId, benefitPlanId]
  );

  const checkReminderStatus = useCallback(
    (reminderStatusesResponse: ReminderStatusesResponse[]) => {
      reminderStatusesResponse?.forEach((item: any) => {
        if (statusesError.includes(item.status)) {
          if (item.status === "benefitNotFound") {
            toast(
              t("choices.benefit_not_found")?.replace(
                "{benefitName}",
                item.benefitName ? item.benefitName : item.benefitId
              ),
              { type: "error" }
            );
          } else if (item.status === "missingBenefitPlanTranslation") {
            toast(
              t("choices.missing_benefit_translation")?.replace(
                "{benefitName}",
                item.benefitName ? item.benefitName : item.benefitId
              ),
              { type: "error" }
            );
          } else {
            toast(
              t(`notification.${item.status}`)?.replace(
                "{name}",
                item.employeeName ? item.employeeName : item.employeeId
              ),
              { type: "error" }
            );
          }
        }

        if (statusesWarning.includes(item.status)) {
          toast(
            t(`terms.${item.status}ReminderSent`)?.replace(
              "{name}",
              item.employeeName ? item.employeeName : item.employeeId
            ),
            { type: "warning" }
          );
        }

        if (item.status === "succeeded") {
          toast(`${t("common.reminder_sent_successfully")}`, {
            type: "success",
          });
        }
      });
    },
    [statusesError, statusesWarning, t]
  );

  const sendReminder: MpBulkActionCallback = useCallback(
    async (selected) => {
      const { selectedRows } = selected;
      const employeeAndBenefitIds: ReminderRequest[] =
        getEmployeeAndBenefitIds(selectedRows);

      const responseData = await sendTermsAcceptanceReminder(
        employeeAndBenefitIds as ReminderRequest[],
        false
      );

      const reminderStatusesResponse = (
        responseData as unknown as ReminderResponse
      )?.reminderStatuses;

      checkReminderStatus(reminderStatusesResponse);

      const reminderSentEmployees = reminderStatusesResponse?.filter(
        (item: ReminderStatusesResponse) => {
          return item.status === "reminderAlreadySentInLast24Hours";
        }
      );

      if (reminderSentEmployees?.length > 0) {
        handleOpenDialog();
        setSuccessEmployeeId(reminderSentEmployees);
      }
    },
    [
      sendTermsAcceptanceReminder,
      handleOpenDialog,
      checkReminderStatus,
      getEmployeeAndBenefitIds,
    ]
  );

  const sendReminderAgain = useCallback(
    async (employeeAndBenefitIds: ReminderRequest[]) => {
      setLoading(true);
      if (employeeAndBenefitIds.length > 0) {
        const responseData = await sendTermsAcceptanceReminder(
          employeeAndBenefitIds as ReminderRequest[],
          true
        );
        const reminderStatusesResponse = (
          responseData as unknown as ReminderResponse
        )?.reminderStatuses;
        checkReminderStatus(reminderStatusesResponse);
        setLoading(false);
        return true;
      }
      setLoading(false);
      return false;
    },
    [checkReminderStatus, sendTermsAcceptanceReminder]
  );

  const getIdsForRequest = useCallback(
    (successIds?: ReminderStatusesResponse[]) => {
      return (
        successIds?.map((item) => {
          return {
            employeeId: item?.employeeId,
            benefitPlanId: item?.benefitId,
          };
        }) ?? []
      );
    },
    []
  );

  return {
    sendReminder,
    successEmployeeId,
    handleCloseDialog,
    openDialog,
    handleOpenDialog,
    checkReminderStatus,
    sendReminderAgain,
    loadingNotifications: loading,
    setLoading,
    getIdsForRequest,
  };
};
