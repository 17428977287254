import { Box } from "@material-ui/core";
import { MpTable } from "@mp-react/table";
import React, { useCallback, useMemo } from "react";
import useTableUtils from "../../../../../utils/Table";
import useStyles from "../../../../../styles/Table.styles";
import { rowMethods } from "../../../../../utils/TableMethods";
import { useAssignGroupsTable } from "../../../../../configs/Tables/EmployeeInnerAssignGroupsTableType";
import useEmployeeAssignGroups from "../../../../../state/InnerEmployeeAssignGroup";
import { useParams, Prompt } from "react-router-dom";
import { MpRowActionParameters } from "@mp-react/table";
import FormFooter from "../../../../../components/layouts/FormFooter/FormFooter";
import { useInvestmentCalculationsStore } from "../../../../../state/InvestmentCalculations";
import AssignGroupsFooterButtons from "../../../../../components/employees/AssignGroupsFooterButtons/AssignGroupsFooterButtons";
import { useTranslation } from "react-i18next";
import { useMe } from "../../../../../state/Administrators";

export default function AssignGroups() {
  const { id } = useParams() as any;
  const classes = useStyles();
  const { canEditGroups } = useMe();
  const {
    overridables,
    overrideClasses,
    translations,
    handleGetData,
    loadingComponent,
    noDataComponent,
  } = useTableUtils("innerEmployeeAssignGroups");
  const { t } = useTranslation();
  const AssignGroupsTableType = useAssignGroupsTable();
  const {
    parsedEmployeeAssignGroups,
    loading,
    parsedEmployeeGroupsTotals,
    clearSwitchValues,
    updateAssignGroups,
    addSwitchValue,
  } = useEmployeeAssignGroups(id);
  const { changeCount, resetAllChanges, increaseChangeCount, setStatus } =
    useInvestmentCalculationsStore();

  const footerTransform = useMemo(
    () => `translateY(${changeCount > 0 ? "0" : "70px"})`,
    [changeCount]
  );

  const toggleIncludedExcluded = useCallback(
    (current: MpRowActionParameters) => {
      const { value, rowId } = current;
      addSwitchValue(rowId as string, value as unknown as boolean);
      setStatus({ included: { [rowId as string]: { value: value } } });
      increaseChangeCount(rowId);
    },
    [addSwitchValue, increaseChangeCount, setStatus]
  );

  const handleCancelFooter = useCallback(() => {
    resetAllChanges();
    clearSwitchValues();
    setStatus(null);
  }, [resetAllChanges, clearSwitchValues, setStatus]);

  const handlePublishFooter = useCallback(
    (date: string) => updateAssignGroups(date),
    [updateAssignGroups]
  );

  return (
    <Box className={canEditGroups ? "" : classes.disableTable}>
      <Prompt
        when={changeCount > 0}
        message={t("errors.user_leaving_edited_page")}
      />
      <MpTable
        {...AssignGroupsTableType}
        data={parsedEmployeeAssignGroups}
        onGetData={handleGetData}
        overridables={overridables}
        totalsData={parsedEmployeeGroupsTotals}
        totals={parsedEmployeeAssignGroups?.length > 0}
        stickyTotals={true}
        disablePagination={true}
        classes={overrideClasses}
        loading={loading && !parsedEmployeeAssignGroups}
        enableGlobalActions={true}
        rowMethods={{ ...rowMethods, toggleIncludedExcluded }}
        translations={translations}
        loadingComponent={loadingComponent}
        emptyChildren={noDataComponent}
      />
      <FormFooter
        containerProps={{
          style: {
            transform: footerTransform,
          },
        }}
      >
        <AssignGroupsFooterButtons
          onCancel={handleCancelFooter}
          onPublish={handlePublishFooter}
          changes={changeCount}
        />
      </FormFooter>
    </Box>
  );
}
