import React from "react";
import NoData from "../../../components/common/NoData/NoData";
import Loader from "../../../components/common/Loader/Loader";
import { isDefined } from "../../../utils/isDefined";
import { makeStyles } from "@material-ui/core";
import Pagination, { PaginationProps } from "../Pagination/Pagination";
import clsx from "clsx";

import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // TableFooter,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    paddingTop: 20,
  },
  inactiveRowCell: {
    opacity: "0.5 !important",
    "& *": {
      opacity: "0.5 !important",
    },
  },
}));
export interface ColumnType<T> {
  /**
   * Unique key of the column
   */
  key: string;
  /**
   * A renderer for the table cell
   *
   * @param value A value which was obtained from the data item using attribute "key"
   * @param record Row data
   *
   * @returns React node to display
   */
  render?: (value: any, record: T) => React.ReactNode;
  /**
   * A text to display for this column in the table header
   */
  title: React.ReactNode;
}

export interface TableProps<T> {
  /**
   * Table columns configuration.
   */
  columns: ColumnType<T>[];
  /**
   * Data to display in table.
   */
  data?: T[];
  /**
   * A function to indicate whether row should be displayed as inactive.
   */
  isInactive?: (record: T) => boolean;
  /**
   * Indicates whether table should be in loading state.
   */
  loading?: boolean;
  /**
   * Pagination configuration.
   */
  pagination?: PaginationProps;
  /**
   * Row identifier. Required to avoid React warnings.
   */
  rowKey: string;
}

const Table = <T extends {}>({ isInactive, ...props }: TableProps<T>) => {
  const classes = useStyles();

  if (props.loading) {
    return <Loader />;
  }

  if (!props.data?.length) {
    return <NoData />;
  }

  return (
    <>
      <MuiTable>
        <TableHead>
          <TableRow>
            {props.columns.map((column) => (
              <TableCell key={column.key}>{column.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(props.data as Record<string, any>[]).map((record) => {
            const inactive = !!isInactive?.(record as T);
            return (
              <TableRow key={record[props.rowKey]} hover>
                {props.columns.map((column) => {
                  const value = record[column.key];
                  return (
                    <TableCell
                      key={column.key}
                      className={clsx(inactive && classes.inactiveRowCell)}
                    >
                      {column.render
                        ? column.render(value, record as T)
                        : isDefined(value)
                        ? String(value)
                        : ""}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        {/* <TableFooter>
          <TableRow>
            <TableCell>5 deserts</TableCell>
            <TableCell align="right">1324</TableCell>
            <TableCell align="right">51.7</TableCell>
            <TableCell align="right">203</TableCell>
            <TableCell align="right">22.5</TableCell>
          </TableRow>
        </TableFooter> */}
      </MuiTable>
      {props.pagination && props.pagination.count > props.pagination.pageSize && (
        <div className={classes.paginationContainer}>
          <Pagination {...props.pagination} />
        </div>
      )}
    </>
  );
};

export default Table;
