import { Timezone } from "../types/Common";
import { useMemo } from "react";

export const useTimezones = (): Timezone[] =>
  useMemo(
    () => [
      { value: -11, label: "GMT-11" },
      { value: -10, label: "GMT-10" },
      { value: -9, label: "GMT-9" },
      { value: -8, label: "GMT-8" },
      { value: -7, label: "GMT-7" },
      { value: -6, label: "GMT-6" },
      { value: -5, label: "GMT-5" },
      { value: -4, label: "GMT-4" },
      { value: -3, label: "GMT-3" },
      { value: -2, label: "GMT-2" },
      { value: -1, label: "GMT-1" },
      { value: 0, label: "GMT+0" },
      { value: 1, label: "GMT+1" },
      { value: 2, label: "GMT+2" },
      { value: 3, label: "GMT+3" },
      { value: 4, label: "GMT+4" },
      { value: 5, label: "GMT+5" },
      { value: 6, label: "GMT+6" },
      { value: 7, label: "GMT+7" },
      { value: 8, label: "GMT+8" },
      { value: 9, label: "GMT+9" },
      { value: 10, label: "GMT+10" },
      { value: 11, label: "GMT+11" },
      { value: 12, label: "GMT+12" },
    ],
    []
  );
