import { useLoading } from "./../utils/Loading";
import { useTranslation } from "react-i18next";
import { Endpoints } from "./../api/constants";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useMemo, useCallback } from "react";
import {
  UseEmployeesBulkActions,
  ChangeOrgUnitRequest,
} from "./../types/Employees";
import { mutateData } from "../api/api";
import { MpBulkActionCallback, MpBulkActionMethods } from "@mp-react/table";
import { useHistory } from "react-router";
import moment from "moment";
import { getTimezoneFromDate } from "../utils/Common";
import { useEmployees } from "./Employees";

const useEmployeesBulkActions = (
  id?: string,
  query?: string,
  preventCall = false
): UseEmployeesBulkActions => {
  const { t } = useTranslation();
  const now = useMemo(() => moment(), []);
  const history = useHistory();
  const { mutateEmployeeTableData } = useEmployees(id, query, preventCall);

  const { stopLoading, startLoading, loading } = useLoading();

  const statusToastText = useCallback(
    (selectedRows: any) =>
      t("common.statuses_updated", { count: selectedRows?.length }),
    [t]
  );

  const deactivate: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const employeeIds = selectedRows?.map((item) => {
        const { original } = item as any;
        return original.id;
      });
      startLoading();
      mutateData("patch", Endpoints.bulkActionEmployee, {
        employee: { endDate: now },
        employeeIds: employeeIds,
      })
        .then(() => {
          toast(statusToastText(selectedRows), { type: "success" });
          mutateEmployeeTableData();
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, now, statusToastText, mutateEmployeeTableData, stopLoading]
  );

  const activate: MpBulkActionCallback = useCallback(
    (selected) => {
      const { selectedRows } = selected;
      const employeeIds = selectedRows?.map((item) => {
        const { original } = item as any;
        return original.id;
      });
      startLoading();
      mutateData("patch", Endpoints.bulkActionEmployee, {
        employee: { startDate: now, endDate: null },
        employeeIds: employeeIds,
      })
        .then(() => {
          toast(statusToastText(selectedRows), { type: "success" });
          mutateEmployeeTableData();
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, now, statusToastText, mutateEmployeeTableData, stopLoading]
  );

  const deactivateAction = useCallback(() => {
    startLoading();
    mutateData("patch", Endpoints.bulkActionEmployee, {
      employee: { endDate: now },
      employeeIds: [id],
    })
      .then(() => {
        toast(t("common.updated_succesfully"), { type: "success" });
        mutate(`${Endpoints.employee}/${id}`);
        mutateEmployeeTableData();
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, now, id, t, mutateEmployeeTableData, stopLoading]);

  const activateAction = useCallback(() => {
    startLoading();
    mutateData("patch", Endpoints.bulkActionEmployee, {
      employee: { startDate: now, endDate: null },
      employeeIds: [id],
    })
      .then(() => {
        toast(t("common.updated_succesfully"), { type: "success" });
        mutate(`${Endpoints.employee}/${id}`);
        mutateEmployeeTableData();
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, now, id, t, mutateEmployeeTableData, stopLoading]);

  const deactivateOnSpecificDate = useCallback(
    (date: string, selectedRowIds: string[]) => {
      startLoading();
      mutateData("patch", Endpoints.bulkActionEmployee, {
        employee: { endDate: date, endDateTZ: getTimezoneFromDate(date) },
        employeeIds: selectedRowIds,
      })
        .then(() => {
          toast(statusToastText(selectedRowIds), { type: "success" });
          mutateEmployeeTableData();
          mutate(`${Endpoints.employee}/${id}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, statusToastText, mutateEmployeeTableData, id, stopLoading]
  );

  const activateOnSpecificDate = useCallback(
    (date: string, selectedRowIds: string[]) => {
      startLoading();
      mutateData("patch", Endpoints.bulkActionEmployee, {
        employee: { startDate: date, startDateTZ: getTimezoneFromDate(date) },
        employeeIds: selectedRowIds,
      })
        .then(() => {
          toast(statusToastText(selectedRowIds), { type: "success" });
          mutateEmployeeTableData();
          mutate(`${Endpoints.employee}/${id}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, statusToastText, mutateEmployeeTableData, id, stopLoading]
  );

  const changeHireDateAction = useCallback(
    (data: Record<"hireDate", string>, employeeIds: string[]) => {
      startLoading();
      mutateData("patch", Endpoints.bulkActionEmployee, {
        employee: data,
        employeeIds: employeeIds,
      })
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutateEmployeeTableData();
        })
        .finally(() => {
          stopLoading();
        });
    },
    [mutateEmployeeTableData, startLoading, stopLoading, t]
  );

  const changeOrgUnitAction = useCallback(
    (data: ChangeOrgUnitRequest, employeeIds: string[]) => {
      startLoading();
      mutateData("patch", Endpoints.bulkActionEmployee, {
        employee: data,
        employeeIds: employeeIds,
      })
        .then(() => {
          toast(t("common.updated_succesfully"), { type: "success" });
          mutate(`${Endpoints.employee}/${id}`);
          mutateEmployeeTableData();
        })
        .finally(() => {
          stopLoading();
        });
    },
    [id, mutateEmployeeTableData, startLoading, stopLoading, t]
  );

  const deleteInnerEmployee = useCallback(() => {
    startLoading();
    mutateData("delete", `${Endpoints.employee}/${id}`)
      .then(() => {
        toast(t("common.deleted_succesfully"), { type: "success" });
        history.push("/employees");
      })
      .finally(() => {
        stopLoading();
      });
  }, [startLoading, stopLoading, t, id, history]);

  const bulkMethods: MpBulkActionMethods = useMemo(
    () => ({
      deactivate,
      activate,
    }),
    [deactivate, activate]
  );

  return {
    loading: loading,
    bulkMethods,
    changeHireDateAction,
    deactivateOnSpecificDate,
    activateOnSpecificDate,
    changeOrgUnitAction,
    activateAction,
    deactivateAction,
    deleteInnerEmployee,
  };
};

export default useEmployeesBulkActions;
