import { Box, Button, Grid } from "@material-ui/core";
import { MpForm } from "@mp-react/form";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import StatusButton from "../../../../../../../components/common/StatusButton/StatusButton";
import FormPaper from "../../../../../../../components/layouts/FormPaper/FormPaper";
import FormFooter from "../../../../../../../components/layouts/FormFooter/FormFooter";
import Title from "../../../../../../../components/sidebar/Title/Title";
import { useAdministratorForm } from "../../../../../../../configs/Forms/AdministratorForm";
import useAdministrators, {
  useMe,
} from "../../../../../../../state/Administrators";
import { Administrator } from "../../../../../../../types/Administrators";
import { Status } from "../../../../../../../types/Common";
import { StatusActionKey } from "../../../../../../../types/Status";
import { useFormUtils } from "../../../../../../../utils/Form";
import useStatus from "../../../../../../../utils/Status";
import useFormStyles from "../../../../../../../styles/Form.styles";
import clsx from "clsx";
import { useAdministratorsProgrammaticHrForm } from "../../../../../../../configs/Forms/AdministratorsProgrammaticHrForm";

const Information = () => {
  const { canEditAdministrators } = useMe();
  const formClasses = useFormStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params: { id: string } = useParams();
  const administratorId = useMemo(() => params?.id, [params?.id]);
  const { updateAdministrator, administrator, isProgrammaticHr } =
    useAdministrators(administratorId);

  const { overridables } = useFormUtils();
  const formMethods = useForm({
    defaultValues: administrator as FieldValues,
  });

  const administratorsMainForm = useAdministratorForm(true);
  const administratorsProgrammaticHrForm =
    useAdministratorsProgrammaticHrForm();

  const administratorsForm = useMemo(
    () =>
      isProgrammaticHr
        ? administratorsProgrammaticHrForm
        : administratorsMainForm,
    [administratorsMainForm, administratorsProgrammaticHrForm, isProgrammaticHr]
  );

  const { handleSubmit, formState, reset } = formMethods;

  const { getStatus } = useStatus();
  const [statusValue, setStatusValue] = useState<Status>({
    name: administrator?.status ?? "active",
  });

  useEffect(() => {
    if (!!administrator) {
      reset(administrator);
      setStatusValue({
        name: administrator.status,
      });
    }
  }, [administrator, reset]);

  const statusChanged = useMemo(
    () => statusValue.name !== administrator?.status,
    [administrator?.status, statusValue.name]
  );

  const hasChanges: boolean = useMemo(
    () => formState.isDirty || statusChanged,
    [formState.isDirty, statusChanged]
  );

  const footerTransform = useMemo(
    () => `translateY(${hasChanges ? "0" : "70px"})`,
    [hasChanges]
  );

  const removeFooter = useCallback(() => {
    reset(administrator, { dirtyFields: false });
    history.push({
      pathname: location?.pathname,
      search: `tab=administratorsPermissions`,
    });
  }, [administrator, history, location?.pathname, reset]);

  const submitData = useCallback(
    () =>
      handleSubmit(async (data: Partial<Administrator>) => {
        reset(administrator, { dirtyFields: false });
        setStatusValue({
          name: administrator.status,
        });
        updateAdministrator({
          ...data,
          status: statusValue.name as "active" | "inactive",
        });
      })(),
    [administrator, handleSubmit, reset, statusValue.name, updateAdministrator]
  );

  const handleStatusChange = useCallback(
    (action: StatusActionKey, date?: string) => {
      const status = getStatus(action);
      if (status) {
        setStatusValue({
          name: status,
          date: date,
        });
      }
    },
    [getStatus]
  );

  const AbbrevatedApiKey = useMemo(() => {
    if (!isProgrammaticHr) return null;

    return (
      <Grid container spacing={2} style={{ height: 60, alignItems: "center" }}>
        <Grid item sm={4} xs={12}>
          {t("administrators.api_key")}
        </Grid>
        <Grid item sm={8} xs={12} style={{ paddingLeft: 12 }}>
          {administrator?.apiKeyAbbreviated}
        </Grid>
      </Grid>
    );
  }, [administrator?.apiKeyAbbreviated, isProgrammaticHr, t]);

  return (
    <>
      <Prompt
        when={hasChanges}
        message={t("errors.user_leaving_edited_page")}
      />
      <Box
        display="flex"
        className={clsx({
          [formClasses.disableFormFields]: !canEditAdministrators,
        })}
      >
        <FormPaper marginTop={0} marginRight="30px" width="100%">
          <Title
            marginBottom="35px"
            title={t("common.contact_details")}
            padding="0px !important"
          />
          <MpForm
            overridables={overridables}
            useFormMethods={formMethods}
            {...administratorsForm}
          />
          {AbbrevatedApiKey}
        </FormPaper>
        <StatusButton
          status={statusValue}
          onActionChange={handleStatusChange}
          id="administrators"
        />
      </Box>

      <FormFooter
        justifyContent="flex-end"
        containerProps={{
          style: {
            transform: footerTransform,
          },
        }}
        maxWidth="600px"
        marginLeft={0}
        paddingRight="20px"
      >
        <Box marginRight="10px">
          <Button onClick={removeFooter} variant="text">
            {t("common.cancel")}
          </Button>
        </Box>
        <Button variant="contained" color="primary" onClick={submitData}>
          {t("common.confirm_changes")}
        </Button>
      </FormFooter>
    </>
  );
};

export default Information;
