import React from "react";
import { makeStyles } from "@material-ui/core";
import { MarketplaceItemsFilters } from "./Types";
import ItemsFilters from "./ItemsFilters";
import { MarketplaceItemsSort } from "../../../../../types/MarketplaceItems";
import Filters from "../../../../../components/filters/Filters";

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    padding: theme.spacing(2.5, 3),
    display: "flex",
    justifyContent: "end",
  },
}));

interface Props {
  filters: MarketplaceItemsFilters;
  onFiltersChange: (newFilters: MarketplaceItemsFilters) => void;
  sort?: MarketplaceItemsSort;
  onSort: (sort: MarketplaceItemsSort) => void;
  hideBenefitCategories?: boolean;
  renderAdditionalActions: () => React.ReactNode;
}

const ItemsFiltersPanel = ({
  renderAdditionalActions,
  filters,
  onFiltersChange,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.filtersContainer}>
      <Filters value={filters} onChange={onFiltersChange}>
        {({ value, setFilter }) => (
          <>
            <ItemsFilters
              filters={value}
              onFilterChange={setFilter}
              {...rest}
            />
          </>
        )}
      </Filters>
      {renderAdditionalActions()}
    </div>
  );
};

export default ItemsFiltersPanel;
