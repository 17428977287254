import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { makeStyles } from "@material-ui/core";
import { getBorder, Colors } from "../../../constants/Style";
import clsx from "clsx";

Quill.register("modules/emoji", Emoji);

const quillModules = {
  toolbar: {
    container: [
      [{ size: ["huge", "large", false, "small"] }],
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "emoji"],
      ["clean"],
    ],
    handlers: {
      emoji: function () {},
    },
  },
  "emoji-toolbar": true,
  "emoji-shortname": true,
  clipboard: {
    matchVisual: false, // Do not add an empty line before list
  },
};

const isQuillEmpty = (quill: ReactQuill.UnprivilegedEditor) => {
  if ((quill.getContents()["ops"] || []).length !== 1) {
    return false;
  }
  return quill.getText().trim().length === 0;
};

const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    "& .ql-toolbar.ql-snow": {
      border: getBorder(Colors.BorderDefault),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,
    },
    "& .ql-container.ql-snow": {
      border: getBorder(Colors.BorderDefault),
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,
      fontFamily: '"Inter", sans-serif',
    },
    "& .ql-editor": {
      minHeight: 100,
      maxHeight: 300,
    },
  },
  displayMode: {
    "& .ql-toolbar.ql-snow": {
      display: "none",
    },
    "& .ql-container.ql-snow": {
      border: "none",
      background: "none",
    },
    "& .ql-editor": {
      padding: 0,
      minHeight: "unset",
      maxHeight: "unset",
    },
  },
}));

interface Props {
  /**
   * Input value. Should be HTML string.
   */
  value?: string;
  /**
   * Input value change event callback.
   */
  onChange?: (value: string) => void;
  /**
   * Input placeholder.
   */
  placeholder?: string;
  /**
   * CSS class added to the root element.
   */
  className?: string;
  /**
   * ID for HTML element.
   */
  id?: string;
  /**
   * Indicates whether "display mode" is active. It removes toolbar and editing
   * capabilities. Only formatted text is displayed.
   */
  displayMode?: boolean;
  /**
   * Indicates whether input is disabled.
   */
  disabled?: boolean;
}

/**
 * Input for creating a rich text. It is based on Quill rich text editor.
 */
const RichTextEditor = ({
  className,
  displayMode = false,
  disabled = false,
  onChange = () => {},
  ...props
}: Props) => {
  const classes = useStyles();
  const handleChange = (
    value: string,
    delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    if (isQuillEmpty(editor)) {
      onChange("");
    } else {
      onChange(value);
    }
  };
  return (
    <ReactQuill
      theme="snow"
      modules={quillModules}
      className={clsx(
        classes.richTextEditor,
        displayMode && classes.displayMode,
        className
      )}
      readOnly={disabled || displayMode}
      onChange={handleChange}
      {...props}
    />
  );
};

export default RichTextEditor;
