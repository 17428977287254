import { Typography } from "@material-ui/core";
import { CellProps } from "react-table";
import { useRendererOptions } from "@mp-react/table";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";

export default function Terms(props: CellProps<{}>) {
  const { t } = useTranslation();
  const { value } = useRendererOptions(props);

  const title = useMemo(() => {
    if (value === "accepted") {
      return t("common.accepted");
    } else if (value === "notAccepted") {
      return t("common.not_accepted");
    }

    return "-";
  }, [t, value]);

  return <Typography variant="body2">{title}</Typography>;
}
