import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { UnpackNestedValue } from "react-hook-form";
import AddNewButton from "../../../common/AddNewButton/AddNewButton";
import { useNewsForm } from "../../../../configs/Forms/AddNewsForm";
import FormDialog from "../../../../components/dialogs/FormDialog/FormDialog";
import useNews from "../../../../state/News";
import { AddNewsForm } from "../../../../types/News";
import { Box } from "@material-ui/core";
import { useMe } from "../../../../state/Administrators";
import DownloadExcelButton from "../../../table/DownloadExcelButton/DownloadExcelButton";
import useTableUtils from "../../../../utils/Table";

export default function NewsHeader() {
  const { canEditNews } = useMe();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const newsForm = useNewsForm();
  const { tableQueryParam } = useTableUtils("news");
  const { createNews, loading, newsList, exportToExcel } = useNews(
    undefined,
    tableQueryParam
  );

  const handleOpenDialog = useCallback(() => setOpen(true), []);

  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleAdd = useCallback(
    (
      data: UnpackNestedValue<{}>,
      e?: React.BaseSyntheticEvent<object, any, any>
    ) => {
      handleCloseDialog();
      if ("name" in data) {
        createNews({
          name: (data as AddNewsForm).name,
        });
      }
    },
    [createNews, handleCloseDialog]
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <DownloadExcelButton
        listLength={newsList.length}
        loading={loading}
        exportToExcel={exportToExcel}
        marginRight={"20px"}
      />
      <AddNewButton disabled={!canEditNews} onClick={handleOpenDialog}>
        {t("news.add_news")}
      </AddNewButton>
      <FormDialog
        onCancelClick={handleCloseDialog}
        onSubmitForm={handleAdd}
        id="create-news-dialog"
        open={open}
        title={t("news.create_news")}
        actionLabel={t("common.create_draft")}
        form={newsForm}
        loading={loading}
      />
    </Box>
  );
}
