import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 450,
      padding: 30,
    },
    dialogContentRoot: {
      padding: 0,
      marginTop: 30,
      "& > div:not(:last-child)": {
        marginBottom: 8,
      },
      "& .MuiOutlinedInput-input": {
        padding: "11px 14px",
      },
    },
    dialogActionsRoot: {
      padding: 0,
      marginTop: 30,
    },
  })
);
