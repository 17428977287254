import { Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Log } from "../../../../../types/Logs";

export default function BenefitRemovedFromUser(props: Partial<Log>) {
  const { employeeId, employee, benefitPlan } = props;
  const { t } = useTranslation();

  return useMemo(() => {
    return (
      <>
        {t("log.benefit_detached_from")
          .split(" ")
          ?.map((text) => {
            switch (text) {
              case "{benefitName}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {`${benefitPlan} `}
                  </Typography>
                );
              case "{employee}":
                return (
                  <Typography
                    variant="subtitle2"
                    key={`logText-${Math.random() * 1000}`}
                  >
                    {employee ? `${employee}  ` : `${employeeId} `}
                  </Typography>
                );
              default:
                return `${text} `;
            }
          })}
      </>
    );
  }, [benefitPlan, employee, employeeId, t]);
}
