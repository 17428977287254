import { MenuItem, Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { LanguageMap } from "../../../constants/Languages";
import { Languages } from "../../../types/Common";
import useStyles from "./LanguageSwitcher.styles";
import { ReactComponent as ChevronLeft } from "../../../assets/icons/chevron-left.svg";
import { ReactComponent as Checkmark } from "../../../assets/icons/checkmark.svg";
import clsx from "clsx";
import useAdministrators, { useMe } from "../../../state/Administrators";
import { useClientAdministrator } from "../../../state/Clients";
import LanguageFlag from "../../common/LanguageFlag/LanguageFlag";
import { StorageKeys } from "../../../types/LocalStorage";

interface IProps {
  handleBack: () => void;
}

export default function LanguageSwitcher(props: IProps) {
  const { me, isMelpAdmin, isHrAdmin } = useMe();
  const { updateAdministrator } = useAdministrators(me?.id, undefined, true);
  const { updateAdministrator: updateClientAdministrator } =
    useClientAdministrator(me?.parentCompanyId, me?.id, true);
  const { handleBack } = props;
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
    localStorage.setItem(StorageKeys.lang, language);
    if (isHrAdmin) {
      updateAdministrator({ language: language.toUpperCase() }, false);
    }
    if (isMelpAdmin) {
      updateClientAdministrator({ language: language.toUpperCase() }, false);
    }
    handleBack();
  };

  const languageMap = LanguageMap.map((langKey, index) => (
    <MenuItem
      classes={{ root: classes.vAlignCenter }}
      onClick={() => changeLanguage(langKey.toLowerCase())}
      key={`${langKey}-${index}`}
    >
      <Box display="flex" alignItems="center">
        <Box marginRight="11px" display="flex">
          <LanguageFlag languageCode={langKey} />
        </Box>
        <Typography
          variant="body2"
          color="textSecondary"
          className={clsx({
            [classes.bold]: i18n.language.toUpperCase() === langKey,
          })}
        >
          {t(`original_language.${langKey as Languages}`)}
        </Typography>
      </Box>
      {i18n.language.toUpperCase() === langKey && (
        <Checkmark className={classes.icon} />
      )}
    </MenuItem>
  ));

  return (
    <>
      <MenuItem onClick={handleBack} className={classes.backItem}>
        <Box marginRight="11px">
          <ChevronLeft />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {t("common.back")}
        </Typography>
      </MenuItem>
      {languageMap}
    </>
  );
}
