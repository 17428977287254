import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      "& rect": {
        fill: "transparent",
      },
    },
    checked: {
      "& rect": {
        fill: Colors.Default,
      },
      "&.MuiCheckbox-colorPrimary": {
        "& rect": {
          fill: theme.palette.primary.main,
        },
      },
      "&.MuiCheckbox-colorSecondary": {
        "& rect": {
          fill: theme.palette.secondary.main,
        },
      },
      "&.Mui-disabled": {
        "& rect": {
          fill: `${Colors.Disable} !important`,
        },
      },
      "&.MuiCheckbox-indeterminate": {
        "& rect": {
          fill: `${"transparent"} !important`,
        },
      },
    },
    disabled: {
      "& rect": {
        fill: Colors.Disable,
      },
    },
  })
);
